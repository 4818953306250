import React, {memo} from 'react';

import FeatureIndicator from '../Indicators/FeatureIndicator';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import {IFoalAbility} from 'Common/models/IFoal';
import {IFeatureIndicatorValue} from 'FoalProfile/models/IFeatureIndicatorValue';
import {AbilityType} from 'Common/constants/AbilityType';
import {AbilityState} from 'Common/constants/AbilityState';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {getAbilitiesByType} from 'Common/helpers/getAbilitiesByType';
import styled from 'styled-components';

function convertAbility(clientAbility: number): AbilityState {
  switch (clientAbility) {
    case 0:
      return AbilityState.Low;
    case 1:
      return AbilityState.Medium;
    case 2:
      return AbilityState.High;
    default:
      return AbilityState.High;
  }
}

function fillFeatureValues(values: IFoalAbility[]): IFeatureIndicatorValue {
  const res: IFeatureIndicatorValue = {
    Low: 0,
    Medium: 0,
    High: 0,
    LowDescription: '',
    MediumDescription: '',
    HighDescription: '',
  };
  values.forEach((x) => {
    res[convertAbility(x.state)] = x.probability;
    res[convertAbility(x.state) + 'Description'] = x.description;
  });
  return res;
}

interface IProps {
  abilities: IFoalAbility[];
  abilityDictionary: IAbilities[];
}

const FeaturesScalingWrapper = styled.div`
  scale: 0.8;
  width: 320px;

  @media (min-width: 375px) {
    scale: unset;
    width: auto;
  }
`;

function PriorityFeatures(props: IProps) {
  const {abilities, abilityDictionary} = props;
  const speedAbilities: IFeatureIndicatorValue = fillFeatureValues(
    abilities.filter((x) => x.type === AbilityType.Speed)
  );
  const temperamentAbilities: IFeatureIndicatorValue = fillFeatureValues(
    abilities.filter((x) => x.type === AbilityType.Temperament)
  );
  const gaitAbilities: IFeatureIndicatorValue = fillFeatureValues(abilities.filter((x) => x.type === AbilityType.Gait));

  const speedDictionary = getAbilitiesByType(abilityDictionary, AbilityType.Speed);
  const temperamentDictionary = getAbilitiesByType(abilityDictionary, AbilityType.Temperament);
  const gaitDictionary = getAbilitiesByType(abilityDictionary, AbilityType.Gait);

  return (
    <FeaturesScalingWrapper>
      <FeatureIndicator
        elementPosition={ElementPosition.Left}
        values={speedAbilities}
        title="Speed"
        minValueText={speedDictionary.filter((x) => x.state === AbilityState.Low)[0]?.name}
        middleValueText={speedDictionary.filter((x) => x.state === AbilityState.Medium)[0]?.name}
        maxValueText={speedDictionary.filter((x) => x.state === AbilityState.High)[0]?.name}
      />
      <FeatureIndicator
        elementPosition={ElementPosition.Left}
        values={temperamentAbilities}
        title="Temperament"
        minValueText={temperamentDictionary.filter((x) => x.state === AbilityState.Low)[0]?.name}
        middleValueText={temperamentDictionary.filter((x) => x.state === AbilityState.Medium)[0]?.name}
        maxValueText={temperamentDictionary.filter((x) => x.state === AbilityState.High)[0]?.name}
      />
      <FeatureIndicator
        elementPosition={ElementPosition.Left}
        values={gaitAbilities}
        title="DMRT3"
        minValueText={gaitDictionary.filter((x) => x.state === AbilityState.Low)[0]?.name}
        middleValueText={gaitDictionary.filter((x) => x.state === AbilityState.Medium)[0]?.name}
        maxValueText={gaitDictionary.filter((x) => x.state === AbilityState.High)[0]?.name}
      />
    </FeaturesScalingWrapper>
  );
}

export default memo(PriorityFeatures);
