import React, {memo} from 'react';
import styled from 'styled-components';
import ColorPalette from 'Common/constants/ColorPalette';
import Icon, {IconName} from 'Icon/components/Icon';
import classNames from 'classnames';
import useIsImageExist from 'Common/helpers/hooks/useIsImageExist';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import BlurredImage from '../BlurredImage/BlurredImage';

const AvatarSizes = (avatarSize: number) =>
  `width: ${avatarSize}px;
  height: ${avatarSize}px;`;

const AvatarWrapper = styled.div<IPropsImage>`
  ${(prop) => AvatarSizes(prop.avatarSize)};
  border-radius: 50%;
  background-color: ${ColorPalette.gray6};
`;

const AvatarBlurred = styled(BlurredImage)<IPropsImage>`
  ${(prop) => AvatarSizes(prop.avatarSize)};
  overflow: hidden;
  border-radius: 50%;
`;

const AvatarImage = styled.div<IPropsImage>`
  border-radius: 50%;
  ${(prop) => AvatarSizes(prop.avatarSize)};
  ${(prop) =>
    prop.imageUrl &&
    `
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('${prop.imageUrl}');
  `}
`;

const AvatarBigSize = 176;
const AvatarSmallSize = 80;

const IconUserBigSize = 80;
const IconUserSmallSize = 40;

const IconHorseBigSize = 100;
const IconHorseSmallSize = 60;

export enum AvatarSize {
  Big,
  Small,
  Custom,
}

export enum DefaultAvatarProfile {
  None,
  User,
  Horse,
}

interface IPropsImage {
  avatarSize: number;
  imageUrl?: string | null;
}

interface IProps {
  avatarSize: AvatarSize;
  defaultAvatarProfile?: DefaultAvatarProfile;
  style?: React.CSSProperties | undefined;
  url?: string | null;
  customSizeAvatar?: number;
  customSizeIcon?: number;
  isMarkedIcon?: boolean;
  markedIcon?: IColoredIconProps;
  isOnline?: boolean;
}

const BahAccessMarkWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 2;
  bottom: -3px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
`;

const OnlineMarker = styled.div<{isOnline: boolean}>`
  width: 12px;
  height: 12px;
  border: 2px solid ${ColorPalette.white0};
  border-radius: 50%;
  background-color: ${(props) => (props.isOnline ? ColorPalette.green1 : ColorPalette.red7)};
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

type AllProps = IProps & React.HTMLAttributes<HTMLDivElement>;

function getAvatarSize(bigSize: number, smallSize: number, avatarSize: AvatarSize, customSize?: number): number {
  const res = avatarSize === AvatarSize.Custom ? customSize! : avatarSize === AvatarSize.Big ? bigSize : smallSize;
  return res;
}

const Avatar = (props: AllProps) => {
  const {url, isMarkedIcon, markedIcon, isOnline} = props;
  const isAvatarExists = useIsImageExist(url);

  const avatarSize = getAvatarSize(AvatarBigSize, AvatarSmallSize, props.avatarSize, props.customSizeAvatar);

  const horseIconSize = getAvatarSize(IconHorseBigSize, IconHorseSmallSize, props.avatarSize, props.customSizeIcon);

  const userIconSize = getAvatarSize(IconUserBigSize, IconUserSmallSize, props.avatarSize, props.customSizeIcon);

  return (
    <AvatarWrapper
      style={props.style}
      className={classNames(
        'd-flex',
        'justify-content-center',
        'align-items-center',
        'flex-shrink-0',
        'position-relative',
        props.className
      )}
      avatarSize={avatarSize!}
    >
      {props.url && isAvatarExists ? (
        <>
          <AvatarBlurred className="position-absolute" src={props.url} avatarSize={avatarSize!} />
          <AvatarImage avatarSize={avatarSize!} imageUrl={props.url} className="position-absolute" />
        </>
      ) : (
        <>
          {props.defaultAvatarProfile === DefaultAvatarProfile.Horse && (
            <Icon name={IconName.EtalonHorseLogo} size={horseIconSize} />
          )}
          {props.defaultAvatarProfile === DefaultAvatarProfile.User && (
            <Icon name={IconName.Person} size={userIconSize} />
          )}
        </>
      )}
      {isMarkedIcon && markedIcon && (
        <BahAccessMarkWrapper>
          <ColoredIcon {...markedIcon} />
        </BahAccessMarkWrapper>
      )}

      {isOnline !== undefined && <OnlineMarker isOnline={isOnline} />}

      {props.children}
    </AvatarWrapper>
  );
};

Avatar.defaultProps = {
  customSizeIcon: 28,
  customSizeAvatar: 28,
} as Partial<AllProps>;

export default memo(Avatar);
export type AvatarProps = AllProps;
