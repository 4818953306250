import {ImgHTMLAttributes, useEffect, useState} from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: any;
  placeholder?: any;
}

const Image: React.FC<ImageProps> = ({src, placeholder, ...props}) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    setImageSrc(src || placeholder); // whenever 'src' prop changes, update the state
  }, [src, placeholder]);

  const handleError = () => {
    if (placeholder) setImageSrc(placeholder);
  };

  return <img src={imageSrc} onError={handleError} {...props} />;
};

export default Image;
