import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import styled from 'styled-components';

import {AdminOnlineReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/adminOnlineReportModule';
import {AdminAncestryReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/ancestry/adminAncestryReportModule';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import BreakdownView from 'OnlineReport/components/Ancestry/parts/BreakdownView';
import {SectionContainerContext} from 'OnlineReport/components/Ancestry/parts/common/SectionContainer/useSectionContainer';
import CompositionChart from 'OnlineReport/components/Ancestry/parts/CompositionChart/CompositionChart';
import HorsesLikeMe from 'OnlineReport/components/Ancestry/parts/HorsesLikeMe/HorsesLikeMe';
import MapView from 'OnlineReport/components/Ancestry/parts/MapView';
import PCAPlot from 'OnlineReport/components/Ancestry/parts/PCAPlot/PCAPlot';
import PCAPlotProbabilities from 'OnlineReport/components/Ancestry/parts/PCAPlot/PCAPlotProbabilities';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {AncestryReportActions} from 'OnlineReport/store/ancestry';
import {AncestryReportModule} from 'OnlineReport/store/ancestry/ancestryReportModule';
import {OnlineReportModule} from 'OnlineReport/store/onlineReportModule';
import {ancestryDistributor} from 'OnlineReport/components/Ancestry/distributor';
import {BorderedBlock, PanelLabel} from 'HorseProfile/components/shared/StyledComponents';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {getErrorCode} from 'Common/helpers/ErrorHelper';

const ANCESTRY_ACCESS_DENIED_ERROR_CODE = 'AncestryDataAccessDenied';

const Root = styled.div<{isFullWidth?: boolean}>`
  ${(props) => !props.isFullWidth && 'max-width: 1280px;'}
  display: flex;
  flex-direction: column;

  @media print {
    display: block;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
`;

const Block = styled.div`
  margin-top: 24px;
`;

interface IProps {
  reportType: OnlineReportType;
  horseId: string;
  onHasError(hasError: boolean): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IProps;

const AncestryHorseProfile = (props: AllProps) => {
  const {horseId} = props;
  const {
    getPopulation,
    population,
    populationLoading,
    getPopulationComparison,
    populationComparison,
    populationComparisonLoading,
    ancestryProbabilities,
    ancestryProbabilitiesLoading,
    getAncestryProbabilities,
    getAncestryPcaReferenceData,
    ancestryPcaReferenceData,
    ancestryPcaReferenceDataLoading,
    getHorseInfo,
    horseInfo,
    horseInfoLoading,
    ancestryRelatedHorses,
    ancestryRelatedHorsesLoading,
    getAncestryRelated,
    ancestryKinshipHorses,
    ancestryKinshipHorsesLoading,
    getAncestryKinship,
    onHasError,
  } = props;

  const {isDesktop, isTablet} = useMediaQuery();

  const isLoading = [
    populationLoading,
    populationComparisonLoading,
    ancestryProbabilitiesLoading,
    horseInfoLoading,
    ancestryRelatedHorsesLoading,
    ancestryKinshipHorsesLoading,
    ancestryPcaReferenceDataLoading,
  ].some((x) => x.isRequesting);

  useEffect(() => {
    if (+horseId) {
      getPopulation(+horseId);
      getPopulationComparison(+horseId);
      getAncestryProbabilities(+horseId);
      getAncestryRelated(+horseId);
      getAncestryKinship(+horseId);
      getHorseInfo(+horseId);
    }
    getAncestryPcaReferenceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  const errors =
    populationLoading.error ||
    populationComparisonLoading.error ||
    ancestryProbabilitiesLoading.error ||
    horseInfoLoading.error ||
    ancestryPcaReferenceDataLoading.error;

  const ancestryErrorCodes = [
    populationLoading.error && getErrorCode(populationLoading.error),
    populationComparisonLoading.error && getErrorCode(populationComparisonLoading.error),
    ancestryProbabilitiesLoading.error && getErrorCode(ancestryProbabilitiesLoading.error),
    horseInfoLoading.error && getErrorCode(horseInfoLoading.error),
    ancestryPcaReferenceDataLoading.error && getErrorCode(ancestryPcaReferenceDataLoading.error),
  ];

  const isAccessDenied = ancestryErrorCodes.some((x) => x === ANCESTRY_ACCESS_DENIED_ERROR_CODE);

  useEffect(() => {
    onHasError(!!errors);
  }, [errors, onHasError]);

  const staticSectionsData = {
    horseName: horseInfo?.name || '',
    ownerName: '',
    sampleId: horseInfo?.sampleId || '',
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Root isFullWidth={!!errors}>
      {isLoading && <IconedLoading />}
      {!isAccessDenied && (
        <SectionContainerContext.Provider value={{staticData: staticSectionsData}}>
          {population && population.length > 0 && (
            <>
              <Block>
                <PanelLabel>Composition Map</PanelLabel>
                <BorderedBlock className="d-flex align-items-center justify-content-center">
                  <MapView population={population} isShowTitle={false} withoutPaddings={true} />
                </BorderedBlock>
              </Block>
              <Block>
                <PanelLabel>Ancestry Identified</PanelLabel>
                <BorderedBlock className="d-flex align-items-center justify-content-center">
                  <CompositionChart
                    population={population}
                    avatar={horseInfo?.avatar}
                    isShowTitle={false}
                    isShowDescription={false}
                    withoutPaddings={true}
                  />
                </BorderedBlock>
              </Block>
            </>
          )}

          {horseInfo &&
            population &&
            population.length > 0 &&
            (ancestryRelatedHorses.length > 0 || ancestryKinshipHorses.length > 0) && (
              <>
                <HorsesLikeMe
                  horse={horseInfo}
                  population={population}
                  relatedHorses={ancestryRelatedHorses}
                  kinshipHorses={ancestryKinshipHorses}
                  relatedErrors={ancestryRelatedHorsesLoading.error}
                  kinshipErrors={ancestryKinshipHorsesLoading.error}
                  isPrintable={false}
                  isHorseProfile={true}
                />
              </>
            )}

          {population && population.length > 0 && (
            <Block>
              <PanelLabel>Ancestry Breakdown</PanelLabel>
              <BorderedBlock className="d-flex align-items-center justify-content-center">
                <BreakdownView population={population} withoutPaddings={true} />
              </BorderedBlock>
            </Block>
          )}

          <Block className="d-flex flex-column">
            <PanelLabel>Ancestry Population Comparison</PanelLabel>
            <BorderedBlock className="d-flex flex-column align-items-center justify-content-center">
              {populationComparison && (
                <PCAPlot
                  pcaData={ancestryPcaReferenceData}
                  horseName={horseInfo?.name}
                  data={populationComparison}
                  customChartSize={isDesktop ? 280 : isTablet ? 400 : 300}
                  withoutPaddings={true}
                />
              )}
              {ancestryProbabilities && population && (
                <PCAPlotProbabilities data={ancestryProbabilities} population={population} withoutPaddings={true} />
              )}
            </BorderedBlock>
          </Block>
        </SectionContainerContext.Provider>
      )}
    </Root>
  );
};

const mapStateToProps = (state: IAppState, externalProps: {reportType: OnlineReportType}) => {
  const {reportType} = externalProps;

  const distributor = ancestryDistributor[reportType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, AncestryReportActions>,
  externalProps: {reportType: OnlineReportType}
) => {
  const {reportType} = externalProps;

  const distributor = ancestryDistributor[reportType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(React.memo(AncestryHorseProfile));
export default withDynamicModules(Connected, [
  AncestryReportModule,
  AdminAncestryReportModule,
  OnlineReportModule,
  AdminOnlineReportModule,
]);
