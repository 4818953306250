import VisitorTypeService from 'Common/helpers/visitorType/VisitorTypeService';
import LocalStorageService from 'Common/services/LocalStorageService';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';

export const REPORT_TYPE_KEY = 'onlineReportType';

export const getApiByReportType = <T extends OnlineReportType>(apis: Record<T, string>) => {
  const visitorType = String(VisitorTypeService.getVisitorType()).toLowerCase();
  const typeFromStorage = LocalStorageService.getItem(REPORT_TYPE_KEY) as OnlineReportType;
  return apis[typeFromStorage] || apis[visitorType];
};

export const getApiParamsByReportType = <T extends OnlineReportType>(apis: Record<T, {}>) => {
  const typeFromStorage = LocalStorageService.getItem(REPORT_TYPE_KEY) as OnlineReportType;
  const apiParams = apis[typeFromStorage];
  return apiParams ? {params: apiParams} : undefined;
};
