import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.userToAdminHub;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectConnectionId(state: IAppState) {
  return selectState(state).data.connectionId;
}

export function selectConnectedUsers(state: IAppState) {
  return selectState(state).data.connectedUsers;
}

export function selectIsConnectedAdmin(state: IAppState) {
  return selectState(state).data.isAdminConnected;
}

export function selectMessageQueue(state: IAppState) {
  return selectState(state).data.messageQueue;
}

export function selectAdminMessageRead(state: IAppState) {
  return selectState(state).data.adminMessageRead;
}

export function selectUserMessageRead(state: IAppState) {
  return selectState(state).data.userMessageRead;
}
