import React, {memo} from 'react';
import styled from 'styled-components';

import useIsImageExist from 'Common/helpers/hooks/useIsImageExist';
import Icon, {IconName} from 'Icon/components/Icon';
import PlaceholderImage from 'Common/components/Image/Image';

const Image = styled(PlaceholderImage)`
  width: 100%;
  max-height: 75vh;
`;

interface IProps {
  url: string;
}

function Preview(props: IProps) {
  const {url} = props;

  const isImageExists = useIsImageExist(url);

  if (!isImageExists) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Icon name={IconName.BrokenImage} size={24} />
      </div>
    );
  }

  return <Image src={url} className="d-flex flex-column justify-content-center align-items-center position-relative" />;
}

export default memo(Preview);
