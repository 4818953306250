import {ICommunication, MakeCommunicationActions} from 'Common/store/utils/communication/types';

import {makeCommunicationActionType} from 'Common/store/utils/communication/index';

const STATE_NAME = 'adminShortTandemRepeatUpload';

export interface IAdminShortTandemRepeatUploadState {
  communications: {
    shortTandemRepeatUploading: ICommunication;
  };
}

export const uploadShortTandemRepeatActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_SHORT_TANDEM_REPEAT');

export type uploadShortTandemRepeatActions = MakeCommunicationActions<typeof uploadShortTandemRepeatActionTypes, {}>;

export type AdminShortTandemRepeatUploadActions = uploadShortTandemRepeatActions;
