import {IModule} from 'redux-dynamic-modules';

import {IAdminActivationCodeState} from 'Admin/AdminDashboard/store/adminActivationCodes/types';
import {activationCodesReducer} from './reducer';

export interface IAdminActivationCodesModuleState {
  adminActivationCodes: IAdminActivationCodeState;
}

export const AdminActivationCodesModule: IModule<IAdminActivationCodesModuleState> = {
  id: 'adminActivationCodes',
  reducerMap: {
    adminActivationCodes: activationCodesReducer,
  } as any,
};
