import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IDog} from 'Dogs/models/IDog';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {convertDogToClient} from './converters/dog';
import {IDogTestResultsUploadRequest} from '../../../Dogs/models/IDogTestResultsUploadRequest';

const getDogs = async (params?: IRequestParams): Promise<IListResponse<IDog>> => {
  const response = await axiosWrapper.get<IListResponse<IDog>>(`/DogsAdmin`, {params});
  return {
    ...response.data,
    data: response.data.data.map(convertDogToClient),
  };
};

const getDog = async (id: number): Promise<IDog> => {
  const response = await axiosWrapper.get<IObjectResponse<IDog>>(`/DogsGetAdmin/${id}`);
  return convertDogToClient(response.data.data);
};

const uploadTestResults = async (id: string, request: IDogTestResultsUploadRequest): Promise<void> => {
  await axiosWrapper.post<void>(`/dogsadmin/${id}`, request);
};

export default {
  getDogs,
  getDog,
  uploadTestResults,
};
