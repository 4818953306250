import {Reducer} from 'redux';

import {
  getHorseAbilitiesActionTypes,
  getHorseActionTypes,
  getHorseLastOrderActionTypes,
  getHorseOwnerActionTypes,
  getHorseParantageReportActionTypes,
  getOwnerHorsesAtionTypes,
  HorseActions,
  IHorseState,
  resetHorseActionTypes,
  resetSearchHorseActionTypes,
  searchHorseActionTypes,
  updateHorseAncestryProfileTypeActionTypes,
  updateHorseParentageProfileTypeActionTypes,
  updateHorseProfileTypeActionTypes,
} from '../types';
import {
  addFavoriteHorseActionTypes,
  deleteFavoriteHorseActionTypes,
  FavoriteHorsesActions,
} from 'FavoriteHorse/store/types';

const initialState: IHorseState['data'] = {
  horse: null,
  horseAbilities: [],
  horseParantageReport: null,
  horseLastOrder: null,
  foundHorse: null,
  horseOwner: null,
  ownerHorses: [],
};

//@ts-ignore
export const dataHorseReducer: Reducer<IHorseState['data'], HorseActions> = (
  state = initialState,
  action: HorseActions | FavoriteHorsesActions
) => {
  switch (action.type) {
    case getHorseActionTypes.SUCCESS: {
      return {...state, horse: action.payload};
    }
    case addFavoriteHorseActionTypes.SUCCESS: {
      if (state.horse?.isFavorite !== undefined) {
        return {
          ...state,
          horse: {
            ...state.horse,
            isFavorite: action.payload === state.horse?.id ? true : state.horse?.isFavorite,
          },
        };
      }

      return state;
    }

    case deleteFavoriteHorseActionTypes.SUCCESS: {
      if (state.horse?.isFavorite !== undefined) {
        return {
          ...state,
          horse: {
            ...state.horse,
            isFavorite: action.payload === state.horse?.id ? false : state.horse?.isFavorite,
          },
        };
      }

      return state;
    }
    case updateHorseProfileTypeActionTypes.SUCCESS: {
      return {...state, horse: {...state.horse, profileType: action.payload}};
    }
    case updateHorseAncestryProfileTypeActionTypes.SUCCESS: {
      return {...state, horse: {...state.horse, ancestryProfileType: action.payload}};
    }
    case updateHorseParentageProfileTypeActionTypes.SUCCESS: {
      return {...state, horse: {...state.horse, parentageProfileType: action.payload}};
    }
    case resetHorseActionTypes.RESET: {
      return {...state, horse: initialState.horse, horseLastOrder: initialState.horseLastOrder};
    }
    case getHorseAbilitiesActionTypes.SUCCESS: {
      return {...state, horseAbilities: action.payload};
    }
    case getHorseParantageReportActionTypes.SUCCESS: {
      return {...state, horseParantageReport: action.payload};
    }
    case getHorseLastOrderActionTypes.SUCCESS: {
      return {...state, horseLastOrder: action.payload};
    }
    case searchHorseActionTypes.SUCCESS: {
      return {...state, foundHorse: action.payload};
    }
    case resetSearchHorseActionTypes.RESET: {
      return {...state, foundHorse: initialState.foundHorse};
    }
    case getHorseOwnerActionTypes.SUCCESS: {
      return {...state, horseOwner: action.payload};
    }
    case getOwnerHorsesAtionTypes.SUCCESS: {
      return {...state, ownerHorses: action.payload};
    }

    default: {
      return state;
    }
  }
};
