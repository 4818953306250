import React from 'react';
import styled from 'styled-components';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import Typography from 'Common/constants/Typography';
import {IUserHorsePrivacy} from 'UserProfile/models/IUserPrivacySettings';
import {SwitchWrapper} from 'Common/components/StyledComponents/StyledComponents';
import HintIcon from 'Common/components/HintIcont/HintIcon';
import {
  CHANGE_ANCESTRY_PROFILE_TYPE_TOOLTIP,
  CHANGE_DIAGNOSTICS_PROFILE_TYPE_TOOLTIP,
} from 'Shared/constants/profileTypeTooltip';
import HorsePrivacySwitcher from './HorsePrivacySwitcher';
import {ColumnName} from '../../styled';
import {sortByName} from 'Common/helpers/sortByName';

const HorseName = styled.div`
  font-size: ${Typography.size.size18};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  width: 100%;
`;

interface IProps {
  title: string;
  horses: IUserHorsePrivacy[];
  onUpdateDiagnosticsProfileType(horseId: number, isPublic: boolean): void;
  onUpdateAncestryProfileType(horseId: number, isPublic: boolean): void;
  onUpdateParentageProfileType(horseID: number, isPublic: boolean): void;
}

const UserHorsesPrivacySettings = (props: IProps) => {
  const {horses, onUpdateAncestryProfileType, onUpdateDiagnosticsProfileType, onUpdateParentageProfileType, title} =
    props;

  const sortedHorses = horses.sort(sortByName);

  return (
    <FormControlContainer title={title} style={{marginTop: 10}}>
      {sortedHorses.length > 0 && (
        <Row>
          <ColumnName className="justify-content-start w-100">Horse name</ColumnName>
          <div className="d-flex align-items-center justify-content-end">
            <ColumnName>DIAGNOSTICS</ColumnName>
            <HintIcon tooltip={CHANGE_DIAGNOSTICS_PROFILE_TYPE_TOOLTIP} />
          </div>
          <div className="d-flex  align-items-center justify-content-end">
            <ColumnName>ANCESTRY</ColumnName>
            <HintIcon tooltip={CHANGE_ANCESTRY_PROFILE_TYPE_TOOLTIP} />
          </div>
        </Row>
      )}

      {sortedHorses.map((horse, i) => (
        <SwitchWrapper className="d-flex flex-row" key={i}>
          <Row>
            <HorseName className="w-100">{horse.name}</HorseName>
            <HorsePrivacySwitcher
              horseId={horse.id}
              isAncestryPublic={horse.isAncestryPublic}
              isDiagnosticPublic={horse.isDiagnosticsPublic}
              isParentagePublic={horse.isParentagePublic}
              onUpdateAncestryProfileType={onUpdateAncestryProfileType}
              onUpdateDiagnosticsProfileType={onUpdateDiagnosticsProfileType}
              onUpdateParentageProfileType={onUpdateParentageProfileType}
            />
          </Row>
        </SwitchWrapper>
      ))}
    </FormControlContainer>
  );
};

export default UserHorsesPrivacySettings;
