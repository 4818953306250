import React, {memo} from 'react';
import styled from 'styled-components';

import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import {DropDownMenu} from '../../../../../Common/components/DropDown';
import {FaDownload, FaLink} from 'react-icons/fa';
import axios from 'axios';
import {copyToClipboard} from '../../../../../Common/helpers/copyToClipboard';
import {useToast} from '../../../../../Common/helpers/hooks/useToast';
import {getHorseShareUrls} from '../../../../utils/getHorseShareUrls';

const ActionButtonEdit = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 48px;
  @media ${breakpoints.sm} {
    top: 16px;
    right: 56px;
  }
`;
const ActionButtonShare = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
`;

interface IActionProps {
  editHandler(): void;
  horseId: number;
  horseName: string;
  urlToCopy: string;
}

export const Actions = memo(({editHandler, horseId, horseName}: IActionProps) => {
  const {isMobile} = useMediaQuery();
  const {addToast} = useToast('horse_profile_share_actions');

  const handleOnSelectShareOption = async (value: 'download' | 'link') => {
    if (value === 'link') {
      copyToClipboard(getHorseShareUrls.getHorseShareProfileUrl(horseId));
      addToast('Link has been copied to clipboard', 'success');
    }

    if (value === 'download') {
      addToast('Downloading image', 'info', {
        delay: 3500,
      });
      try {
        const {data} = await axios.get(getHorseShareUrls.getDownloadHorseShareImageUrl(horseId), {
          responseType: 'arraybuffer',
        });

        const blob = new Blob([data], {type: 'image/png'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Share Image - ${horseName}.png`;
        a.click();
      } catch (e) {
        addToast('Error downloading image, please try again', 'error');
      }
    }
  };

  const actionButtonProps = {
    color: ColorPalette.white0,
    fill: true,
    stroke: false,
    size: isMobile ? 16 : 24,
    hoverColor: ColorPalette.gray1,
  };

  return (
    <>
      <ActionButtonEdit name={IconName.Edit} {...actionButtonProps} onClick={editHandler} />

      <ActionButtonShare>
        <DropDownMenu
          items={[
            {label: 'Share Profile Link', value: 'link', iconElement: <FaLink />},
            {label: 'Download Shareable Image', value: 'download', iconElement: <FaDownload />},
          ]}
          onSelect={handleOnSelectShareOption}
          alignRight={true}
        >
          {() => (
            <div>
              <IconButton name={IconName.Share} {...actionButtonProps} />
            </div>
          )}
        </DropDownMenu>
      </ActionButtonShare>
    </>
  );
});
