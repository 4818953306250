import React, {memo} from 'react';
import styled from 'styled-components';

import Scrollbar from 'Common/components/Scrollbar/Scrollbar';

const HEIGHT_ADDITIONAL_COMPONENTS_AT_MODAL = 175;
const MODAL_MARGIN = 64;
const CONTENT_MAX_HEIGHT = `calc(100vh - ${HEIGHT_ADDITIONAL_COMPONENTS_AT_MODAL + MODAL_MARGIN}px)`;

const Root = styled.div`
  padding-top: 24px;
  margin-left: 40px;
`;

interface IProps extends Pick<React.HTMLAttributes<HTMLElement>, 'className' | 'style'> {
  minHeight?: string | number;
  maxHeight?: string | number;
  scrollable?: boolean;
  isScrollToBottom?: boolean;
  renderView?: React.FunctionComponent<any>;
}

function ModalWindowFormContent(props: React.PropsWithChildren<IProps>) {
  const {children, scrollable = true, isScrollToBottom, ...rest} = props;

  return (
    <Root>
      {scrollable ? (
        <Scrollbar maxHeight={rest.maxHeight || CONTENT_MAX_HEIGHT} isScrollToBottom={isScrollToBottom} {...rest}>
          {children}
        </Scrollbar>
      ) : (
        children
      )}
    </Root>
  );
}

export default memo(ModalWindowFormContent);
