import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {StylesConfig, StylesConfigFunction} from 'react-select/dist/declarations/src/styles';
import {CSSObjectWithLabel, GroupBase} from 'react-select/dist/declarations/src/types';

import Theme from 'Common/constants/Theme';
import {IOption} from 'Common/models/IOption';

function setBgColor(state: any) {
  if (state.isFocused) {
    return Theme.color.white;
  }

  if (state.isDisabled) {
    return Theme.color.white;
  }

  return Theme.color.white;
}

function getCustomStyles<Props>(
  styleFunc: StylesConfigFunction<Props> | undefined,
  base: CSSObjectWithLabel,
  state: Props
) {
  return styleFunc ? styleFunc(base, state) : null;
}

export type SelectStyles = {
  [key in keyof StylesConfig]: (base: CSSObjectWithLabel, state: any) => CSSObjectWithLabel;
};

export function getStyles<Option extends IOption, IsMulti extends boolean, Group extends GroupBase<Option>>(
  styles: StylesConfig<Option, IsMulti, Group> = {}
): SelectStyles {
  return {
    container: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      padding: 0,
      border: 'none',
      height: 'auto',
      minHeight: 44,
      opacity: state.isDisabled ? 0.3 : 1,
      fontFamily: Theme.font.primary,
      fontSize: Typography.size.size14,
      ...getCustomStyles(styles.container, base, state),
    }),
    menu: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      ...getCustomStyles(styles.menu, base, state),
    }),
    menuList: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      zIndex: 999,
      boxShadow: `0px 6px 12px ${ColorPalette.gray51}, 0px 0px 6px ${ColorPalette.gray50}`,
      color: Theme.color.black,
      fontSize: Typography.size.size14,
      ...getCustomStyles(styles.menuList, base, state),
    }),
    option: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? ColorPalette.gray49 : state.isFocused ? ColorPalette.gray49 : undefined,
      color: Theme.color.black,
      display: state.data.isInvisible ? 'none' : 'block',
      ...(typeof styles.option === 'function' ? getCustomStyles(styles.option, base, state) : styles.option),
    }),
    control: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      borderRadius: 3,
      border:
        state.isFocused || state.hasValue ? `1px solid ${Theme.color.primary}` : `1px solid ${ColorPalette.gray44}`,
      boxShadow: state.isFocused ? `0px 0px 0px 2px ${Theme.color.control.shadow}` : `none`,
      minHeight: 48,
      maxHeight: state.isMulti ? 'none' : 48,
      background: setBgColor(state),
      ':hover': {
        borderColor: state.isFocused || state.hasValue ? Theme.color.primary : ColorPalette.gray44,
      },
      ...getCustomStyles(styles.control, base, state),
    }),
    indicatorSeparator: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      visibility: 'hidden',
      ...getCustomStyles(styles.indicatorSeparator, base, state),
    }),
    valueContainer: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      border: 'none',
      padding: '6px 0 6px 12px',
      ...getCustomStyles(styles.valueContainer, base, state),
    }),
    input: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      border: 'none',
      overflow: 'hidden',
      ...getCustomStyles(styles.input, base, state),
    }),
    indicatorsContainer: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      border: 'none',
      maxHeight: '48px',
      ...getCustomStyles(styles.indicatorsContainer, base, state),
    }),
    dropdownIndicator: (base: CSSObjectWithLabel, state: any) => {
      return {
        ...base,
        padding: 6,
        paddingRight: 12,
        color: state.isDisabled ? ColorPalette.gray44 : state.hasValue ? Theme.color.black : Theme.color.primary,
        transform: state.selectProps.menuIsOpen ? 'scale(1, -1)' : undefined,
        ':hover': {
          color: state.isDisabled ? undefined : Theme.color.primary,
        },
        ...getCustomStyles(styles.dropdownIndicator, base, state),
      };
    },
    clearIndicator: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      color: Theme.color.primary,
      ':hover': {
        color: Theme.color.primary,
      },
      padding: '0 0 0 6px',
      ...getCustomStyles(styles.clearIndicator, base, state),
    }),
    loadingIndicator: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      padding: 6,
      ...getCustomStyles(styles.loadingIndicator, base, state),
    }),
    singleValue: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      color: ColorPalette.black1,
      ...getCustomStyles(styles.singleValue, base, state),
    }),
    multiValue: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      backgroundColor: Theme.color.primary,
      color: Theme.color.white,
      borderRadius: 15,
      margin: '3px 9px 3px 0',
      ...getCustomStyles(styles.multiValue, base, state),
    }),
    multiValueLabel: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      fontSize: Typography.size.size12,
      letterSpacing: '0.5px',
      lineHeight: '24px',
      padding: '2px 0 2px 15px',
      paddingLeft: 15,
      color: Theme.color.white,
      ...getCustomStyles(styles.multiValueLabel, base, state),
    }),
    multiValueRemove: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      color: ColorPalette.white10,
      borderRadius: 15,
      padding: '0 8px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Theme.color.primary,
        color: Theme.color.white,
      },
      '&>svg': {
        width: 18,
        height: 18,
      },
      ...getCustomStyles(styles.multiValueRemove, base, state),
    }),
    placeholder: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      color: ColorPalette.gray3,
      fontSize: Typography.size.size13,
      ...getCustomStyles(styles.placeholder, base, state),
    }),
    groupHeading: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      padding: '8px 0px 8px 16px',
      fontFamily: Theme.font.primary,
      fontWeight: +Typography.weight.normal400,
      fontSize: Typography.size.size12,
      lineHeight: '12px',
      letterSpacing: '0.5px',
      color: ColorPalette.gray44,
      ...getCustomStyles(styles.groupHeading, base, state),
    }),
  };
}
