import {combineReducers, Reducer} from 'redux';

import {IAdminActivationCodeState} from 'Admin/AdminDashboard/store/adminActivationCodes/types';
import {communicationActivationCodesReducer} from 'Admin/AdminDashboard/store/adminActivationCodes/reducer/communications';
import {dataActivationCodesReducer} from 'Admin/AdminDashboard/store/adminActivationCodes/reducer/data';
import {uiActivationCodesReducer} from 'Admin/AdminDashboard/store/adminActivationCodes/reducer/ui';

export const activationCodesReducer: Reducer<IAdminActivationCodeState> = combineReducers<IAdminActivationCodeState>({
  communications: communicationActivationCodesReducer,
  data: dataActivationCodesReducer,
  ui: uiActivationCodesReducer,
});
