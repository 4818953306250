import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  padding: 13px 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: #cdcdcd;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  line-height: 15px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  margin-top: 12px;
`;

export default function Disclaimer() {
  return (
    <Container>
      Disclaimer: The information displayed may be limited due to various factors such as tests still in processing or
      privacy settings selected by the owner.
    </Container>
  );
}
