import {Reducer} from 'redux';

import {
  AdminAncestryReportActions,
  getAncestryKinshipHorsesActionTypes,
  getAncestryPcaReferenceDataActionTypes,
  getAncestryProbabilitiesActionTypes,
  getAncestryRelatedHorsesActionTypes,
  getPopulationActionTypes,
  getPopulationComparisonActionTypes,
  getReviewAncestryProbabilitiesActionTypes,
  getReviewPopulationActionTypes,
  getReviewPopulationComparisonActionTypes,
  IAdminAncestryReportState,
} from '../types';

const initialState: IAdminAncestryReportState['data'] = {
  population: null,
  populationComparison: null,
  ancestryProbabilities: null,
  ancestryRelatedHorses: [],
  ancestryKinshipHorses: [],
  ancestryPcaReferenceData: [],
};

export const dataReducer: Reducer<IAdminAncestryReportState['data'], AdminAncestryReportActions> = (
  state = initialState,
  action: AdminAncestryReportActions
) => {
  switch (action.type) {
    case getPopulationActionTypes.REQUEST:
    case getReviewPopulationActionTypes.REQUEST: {
      return {...state, population: null};
    }
    case getPopulationActionTypes.SUCCESS:
    case getReviewPopulationActionTypes.SUCCESS: {
      return {...state, population: action.payload};
    }

    case getAncestryPcaReferenceDataActionTypes.REQUEST: {
      return {...state, ancestryPcaReferenceData: initialState.ancestryPcaReferenceData};
    }
    case getAncestryPcaReferenceDataActionTypes.SUCCESS: {
      return {...state, ancestryPcaReferenceData: action.payload};
    }

    case getPopulationComparisonActionTypes.REQUEST:
    case getReviewPopulationComparisonActionTypes.REQUEST: {
      return {...state, populationComparison: null};
    }
    case getPopulationComparisonActionTypes.SUCCESS:
    case getReviewPopulationComparisonActionTypes.SUCCESS: {
      return {...state, populationComparison: action.payload};
    }

    case getAncestryProbabilitiesActionTypes.REQUEST:
    case getReviewAncestryProbabilitiesActionTypes.REQUEST: {
      return {...state, ancestryProbabilities: null};
    }
    case getAncestryProbabilitiesActionTypes.SUCCESS:
    case getReviewAncestryProbabilitiesActionTypes.SUCCESS: {
      return {...state, ancestryProbabilities: action.payload};
    }

    case getAncestryRelatedHorsesActionTypes.REQUEST: {
      return {...state, ancestryRelatedHorses: initialState.ancestryRelatedHorses};
    }
    case getAncestryRelatedHorsesActionTypes.SUCCESS: {
      return {...state, ancestryRelatedHorses: action.payload};
    }

    case getAncestryKinshipHorsesActionTypes.REQUEST: {
      return {...state, ancestryKinshipHorses: initialState.ancestryKinshipHorses};
    }
    case getAncestryKinshipHorsesActionTypes.SUCCESS: {
      return {...state, ancestryKinshipHorses: action.payload};
    }

    default: {
      return state;
    }
  }
};
