import * as Yup from 'yup';
import {ICreateStaticResourceRequest} from 'Admin/AdminSettings/models/ICreateStaticResourceRequest';
import {IUpdateStaticResourceRequest} from 'Admin/AdminSettings/models/IUpdateStaticResourceRequest';
import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';
import {IStaticResource} from 'Admin/AdminSettings/models/IStaticResource';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface IFormValues {
  id?: number;
  name: string;
  description?: string;
  file?: File;
  image?: Nullable<IImage>;
}

export const initialValue: IFormValues = {
  id: 0,
  name: '',
  description: '',
  file: undefined,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number(),
  name: Yup.string().required(REQUIRED_FIELD),
  file: Yup.mixed<File>(),
});

export const convertToClient = (values: IStaticResource): IFormValues => {
  return {
    ...values,
    image: values.url ? {id: 0, url: values.url} : null,
  };
};

export const convertToServerCreating = (values: IFormValues): ICreateStaticResourceRequest => {
  const result = {
    ...values,
    file: values.file!,
  };

  delete result['id'];
  delete result['url'];

  return result;
};

export const convertToServerUpdating = (values: IFormValues): IUpdateStaticResourceRequest => {
  const result = {
    ...values,
    id: values.id!,
  };

  delete result['file'];
  delete result['url'];

  return result;
};
