import {PageBreak} from 'OnlineReportPrintable/common/styled';
import React, {memo} from 'react';

interface IBreakableSection {
  pageBreak?: boolean;
}

const BreakableSection = ({children, pageBreak = true}: React.PropsWithChildren<IBreakableSection>) => {
  return (
    <>
      <table className="w-100">
        <tbody>
          <tr>
            <td>{children}</td>
          </tr>
        </tbody>
      </table>
      {pageBreak && <PageBreak />}
    </>
  );
};

export default memo(BreakableSection);
