import * as Yup from 'yup';

import {Nullable} from 'Common/types';

export interface IFormValues {
  dogTestResultsFile: Nullable<File>;
}

export const initialValue: IFormValues = {
  dogTestResultsFile: null,
};

export const validationSchema = Yup.object().shape<IFormValues>({
  dogTestResultsFile: Yup.mixed<File>().required('Please select a pdf to upload.'),
});

export function convertFormValuesToUploadRequest(dogTestResultsFile: File): FormData {
  const formData = new FormData();
  formData.append('file', dogTestResultsFile);
  return formData;
}
