import {combineReducers} from 'redux';

import {IAdminSupportState} from '../types';
import {communicationAdminSupportReducer} from './communications';
import {dataAdminSupportReducer} from './data';
import {uiAdminSupportReducer} from './ui';

export const reducer = combineReducers<IAdminSupportState>({
  communications: communicationAdminSupportReducer,
  data: dataAdminSupportReducer,
  ui: uiAdminSupportReducer,
});
