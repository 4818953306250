import React, {memo, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {DropDownMenu, IAvailabilityMenuItem} from 'Common/components/DropDown';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';

const Root = styled.div`
  margin-left: auto;

  position: absolute;
  top: 8px;
  right: 8px;
`;

const MoreIcon = styled(ColoredIcon)`
  cursor: pointer;
`;

interface IProps {
  showDeleteMessageMenuItem?: boolean;

  onDeleteMessage?(): void;
}

function MessageActions(props: IProps) {
  const {onDeleteMessage, showDeleteMessageMenuItem = true} = props;

  const menuItems: IAvailabilityMenuItem[] = [
    {
      id: 1,
      value: 'deleteHandler',
      label: 'Delete message',
      icon: {name: IconName.Garbage, color: ColorPalette.red7},
      availabilityKey: showDeleteMessageMenuItem,
    },
  ];

  const shownMenuItems = menuItems.filter((item) => item.availabilityKey).sort((a, b) => sortByStringKey(a, b, 'id'));

  const actionsHandler = useMemo(
    () => ({
      deleteHandler: onDeleteMessage,
    }),
    [onDeleteMessage]
  );

  const onSelectHandler = useCallback((type) => actionsHandler[type](), [actionsHandler]);

  return (
    <Root className="d-flex align-items-center justify-content-end">
      <DropDownMenu items={shownMenuItems} onSelect={onSelectHandler}>
        {({isOpen}) => (
          <MoreIcon
            name={IconName.More}
            color={isOpen ? ColorPalette.black1 : ''}
            hoverColor={ColorPalette.black1}
            fill={true}
            stroke={false}
          />
        )}
      </DropDownMenu>
    </Root>
  );
}

export default memo(MessageActions);
export type MessageActionsProps = IProps;
