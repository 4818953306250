import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import {IImage} from 'Image/models/IImage';
import AttachedImage from './AttachedImage';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Preview from './Preview';

const ImagePanel = styled.div`
  gap: 8px;
`;

interface IProps {
  images: IImage[];
}

function AttachedImages(props: IProps) {
  const {images} = props;

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');

  const openPreviewModal = useCallback(() => setIsPreviewOpen(true), []);
  const closePreviewModal = useCallback(() => {
    setSelectedImageUrl('');
    setIsPreviewOpen(false);
  }, []);

  const onSelectImage = useCallback(
    (url: string) => {
      setSelectedImageUrl(url);
      openPreviewModal();
    },
    [openPreviewModal]
  );

  return (
    <div>
      <ModalWindow isOpen={isPreviewOpen} onClose={closePreviewModal} minWidth="100px" isLightbox={true}>
        <Preview url={selectedImageUrl} />
      </ModalWindow>

      <ImagePanel className="d-flex flex-wrap">
        {images.map((image, i) => (
          <AttachedImage key={i} image={image} onSelect={onSelectImage} />
        ))}
      </ImagePanel>
    </div>
  );
}

export default memo(AttachedImages);
