import {Reducer} from 'redux';

import {IAdminDogsState, DogsActions, getDogsActionTypes} from 'Admin/AdminDashboard/store/adminDogs/types';

const initialState: IAdminDogsState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiDogsReducer: Reducer<IAdminDogsState['ui'], DogsActions> = (
  state = initialState,
  action: DogsActions
) => {
  switch (action.type) {
    case getDogsActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
