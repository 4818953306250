import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IOrderBP} from 'BusinessPortal/models/order/IOrderBP';
import {IServerOrderBP, IServerOrderDetailsBP, IServerOrderStatuses} from './types/order';
import {
  convertOrderDetailsToClient,
  convertOrderPackagesDetailsToClient,
  convertOrderStatusesToClient,
  convertOrderToClient,
  convertReportsToClient,
} from 'BusinessPortal/services/converters/order';
import {IOrderDetailsBP} from 'BusinessPortal/models/order/IOrderDetailsBP';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IOrderReport} from 'BusinessPortal/models/order/IOrderReport';
import {downloadResponse} from 'Common/helpers/downloadHelper';
import {IOrderStatus} from 'BusinessPortal/models/order/IOrderStatus';
import {IOrderPackagesDetails} from 'BusinessPortal/models/order/IOrderPackagesDetails';

const getOrders = async (params?: IRequestParams): Promise<IListResponse<IOrderBP>> => {
  const response = await axiosWrapper.get<IListResponse<IServerOrderBP>>('/AssociationOrders', {params});
  return {...response.data, data: response.data.data.map(convertOrderToClient)};
};

const getOrderDetails = async (orderId: number): Promise<IOrderDetailsBP> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerOrderDetailsBP>>(
    `/AssociationOrderDetails/${orderId}`
  );
  return convertOrderDetailsToClient(response.data.data);
};

const getOrderPackagesDetails = async (orderId: number): Promise<IOrderPackagesDetails> => {
  const response = await axiosWrapper.get<IObjectResponse<IOrderPackagesDetails>>(
    `/AssociationOrderPackageAndTestDetails/${orderId}`
  );
  return convertOrderPackagesDetailsToClient(response.data.data);
};

const getReports = async (orderId: number): Promise<IOrderReport[]> => {
  const response = await axiosWrapper.get<IListResponse<IOrderReport>>(
    `/AssociationOrderHorseReportStatuses/${orderId}`
  );
  return response.data.data.map(convertReportsToClient);
};

const getOrderStatuses = async (orderId: number): Promise<IOrderStatus> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerOrderStatuses>>(
    `/AssociationOrderStatusesDetails/${orderId}`
  );
  return convertOrderStatusesToClient(response.data.data);
};

const downloadSubmissionForm = async (orderId: number): Promise<void> => {
  const response = await axiosWrapper.get(`/AssociationOrderSubmissionForm/${orderId}`, {responseType: 'blob'});
  downloadResponse(response, 'SUBMISSION_FORM');
};

const downloadGroupSubmissionForm = async (token: string): Promise<void> => {
  const response = await axiosWrapper.get(`/AssociationOrderGroupSubmissionForm/${token}`, {responseType: 'blob'});
  downloadResponse(response, 'SUBMISSION_FORM');
};

const deleteOrder = async (id: number): Promise<void> => {
  await axiosWrapper.delete<void>(`/AssociationOrderDelete/${id}`);
};

export default {
  getOrders,
  getOrderDetails,
  getOrderPackagesDetails,
  getReports,
  getOrderStatuses,
  downloadSubmissionForm,
  downloadGroupSubmissionForm,
  deleteOrder,
};
