import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import withoutProps from 'Common/helpers/WithoutProps';
import IconButton from '../Controls/Buttons/IconButton';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';

export enum ShadowType {
  Never,
  OnlyClose,
  OnlyOpen,
  Always,
}

const Root = styled.div<{hasShadow?: boolean}>`
  border-radius: 10px;
  background: ${Theme.color.white};
  ${({hasShadow}) =>
    hasShadow
      ? 'box-shadow: 0px 61px 80px rgba(0, 0, 0, 0.02), ' +
        '0px 16px 24.1177px rgba(0, 0, 0, 0.0130318), ' +
        '0px 7px 10.0172px rgba(0, 0, 0, 0.01), ' +
        '0px 3px 3.62304px rgba(0, 0, 0, 0.00696822);'
      : ''}
  ${({hasShadow}) => (hasShadow ? 'position: relative;' : '')}
`;

const Header = styled.div<{isDisable?: boolean; isVertical?: boolean}>`
  display: flex;
  ${(props) => props.isVertical && 'flex-direction: column;'}
  align-items: center;
  padding: 16px 16px 12px;
  ${(props) => !props.isDisable && 'cursor: pointer;'}
`;

const Title = styled.div`
  font-family: ${Theme.font.secondary};
  color: ${ColorPalette.black6};
  font-size: ${Typography.size.size20};
  line-height: 32px;
`;

const Body = styled.div<{isOpen: boolean}>`
  min-height: 0px;
  position: relative;
  ${(p) =>
    p.isOpen
      ? `
  height: auto;  
  overflow: visible;
  max-height: 10000px;
  transition: opacity 0.2s 0.05s, max-height 0.2s;
  `
      : `
  height: 0px; 
  overflow: hidden;
  opacity: 0; 
  max-height: 0;
  transition: opacity 0.4s, max-height 0.2s;
  `}
`;

const PanelSection = styled.div<{hasSeparator: boolean}>`
  padding: 16px;
  ${({hasSeparator}) => (hasSeparator ? `border-top: 1px solid ${ColorPalette.gray47};` : '')}
`;

const ToggleIcon = styled(withoutProps(['isOpen', 'isVertical'])(ColoredIcon))<{isOpen: boolean; isVertical?: boolean}>`
  transform: rotate(${(p) => (p.isOpen ? '180deg' : '0deg')});
  ${(props) => !props.isVertical && 'margin-left: auto;'}
`;

const RemoveButton = styled(IconButton)`
  margin-left: auto;
  margin-top: 4px;
`;

const ElementsSeparator = styled.div`
  height: 1px;
  background-color: ${ColorPalette.gray2};
`;

type SectionElement = React.ReactElement<React.ComponentProps<typeof Section>>;

type SectionChildren = SectionElement | undefined | false;

interface IProps {
  title?: string | React.ReactElement;
  arrowSide?: ElementPosition;
  hasBodySeparator?: boolean;
  hasElementSeparator?: boolean;
  shadowType?: ShadowType;
  initiallyOpened?: boolean;
  children: SectionChildren | SectionChildren[];
  isFixed?: boolean;
  headerContent?: React.ReactElement | boolean;
  isDisabled?: boolean;
  isShadowOnMouseHover?: boolean;
  style?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  isOpenManually?: boolean;
  onClear?(): void;
  onClick?(isOpen: boolean): void;
}

export function ExpansionPanel(props: IProps) {
  const {
    arrowSide = ElementPosition.Right,
    hasBodySeparator = true,
    hasElementSeparator = false,
    initiallyOpened = false,
    shadowType = ShadowType.Always,
    title,
    children,
    isFixed,
    onClear,
    onClick,
    headerContent,
    isDisabled,
    isShadowOnMouseHover,
    style,
    bodyStyle,
    headerStyle,
    isOpenManually,
  } = props;

  const [isOpen, setIsOpen] = useState(initiallyOpened);
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const isRightArrowSide = arrowSide === ElementPosition.Right;
  const isLeftArrowSide = arrowSide === ElementPosition.Left;
  const isTopArrowSide = arrowSide === ElementPosition.Top;
  const isBottomArrowSide = arrowSide === ElementPosition.Bottom;
  const isVertical = arrowSide === ElementPosition.Bottom || arrowSide === ElementPosition.Top;

  useEffect(() => {
    if (isOpenManually !== undefined) {
      setIsOpen(isOpenManually);
    }
  }, [isOpenManually]);

  const sections = React.Children.toArray(children)
    .filter(Boolean)
    .map((element) => (element as SectionElement).props.children);

  const expandHandler = useCallback(() => {
    if (isDisabled) {
      return;
    }
    !isFixed && setIsOpen(!isOpen);
    onClick && onClick(!isOpen);
  }, [isDisabled, isFixed, isOpen, onClick]);

  const handleMouseEnter = useCallback(() => {
    isShadowOnMouseHover && setIsMouseEnter(true);
  }, [isShadowOnMouseHover]);

  const handleMouseLeave = useCallback(() => {
    isShadowOnMouseHover && setIsMouseEnter(false);
  }, [isShadowOnMouseHover]);

  const isOpenBody = isFixed || isOpen;
  const hasShadow =
    shadowType === ShadowType.Always ||
    (shadowType === ShadowType.OnlyOpen && isOpenBody) ||
    (shadowType === ShadowType.OnlyClose && !isOpenBody) ||
    isMouseEnter;

  const renderArrow = () => {
    return isFixed ? (
      <RemoveButton
        name={IconName.Close}
        size={12}
        color={Theme.color.primary}
        fill={true}
        stroke={false}
        onClick={onClear}
      />
    ) : (
      <ToggleIcon
        isOpen={isOpen}
        name={IconName.ArrowDown}
        size={12}
        color={Theme.color.primary}
        fill={true}
        stroke={false}
        isVertical={isVertical}
      />
    );
  };

  return (
    <>
      <Root hasShadow={hasShadow} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style}>
        <Header onClick={expandHandler} isDisable={isDisabled} isVertical={isVertical} style={headerStyle}>
          {!isDisabled && (isLeftArrowSide || isTopArrowSide) && renderArrow()}
          {title && <Title>{title}</Title>}
          {headerContent}
          {!isDisabled && (isRightArrowSide || isBottomArrowSide) && renderArrow()}
        </Header>
        {!isDisabled && (
          <Body isOpen={isOpenBody}>
            {sections.map((section, i) => (
              <PanelSection key={i} hasSeparator={hasBodySeparator} style={bodyStyle}>
                {section}
              </PanelSection>
            ))}
          </Body>
        )}
      </Root>
      {hasElementSeparator && !isOpenBody && <ElementsSeparator />}
    </>
  );
}

export function Section(_: React.PropsWithChildren<{}>) {
  return null;
}
