import React, {useEffect} from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {FormikProps} from 'formik/dist/types';

import {CheckboxField, DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {Gender} from 'Common/constants/Gender';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {
  IFormValues,
  initialValue,
  validationSchema,
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/validation';
import {actions, selectors} from 'BusinessPortal/store/horse';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {IHorseColor} from 'Dictionaries/models/IHorseColor';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IMarking} from 'Dictionaries/models/IMarking';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {
  withHorseMapFieldPropsToClient,
  withHorseMapFieldPropsToRequest,
} from 'Common/components/FormFields/HorseMapField/withHorseMapFieldProps';
import HorseMapField from 'Common/components/FormFields/HorseMapField/HorseMapField';
import {
  convertToClient,
  convertToServer,
} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/converters';
import {IHorseInfoSectionData} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseInfoSection/models/IHorseInfoSectionData';
import HorseHeightField from 'Common/components/FormFields/HorseHeightField';
import {FieldHint} from 'BusinessPortal/components/common/styled';

const fieldHintStyles = {marginLeft: 32, marginBottom: 16};

const SaveButton = styled(PrimaryButton)`
  margin-top: 16px;
`;

interface IExternalDictionaries {
  colorsDictionary: ISimpleDictionary<IHorseColor>;
  breedDictionary: ISimpleDictionary<IBreed>;
  disciplineDictionary: ISimpleDictionary<IDiscipline>;
  markingDictionary: ISimpleDictionary<IMarking>;
}

type IExternalProps = IExternalDictionaries & IEditableSection<IHorseInfoSectionData>;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const HorseInfoSectionEdit = (props: AllProps) => {
  const {isValid, isSubmitting} = props;
  const {onSuccess, horseDetailsUpdating} = props;
  const {
    colorsDictionary,
    breedDictionary,
    disciplineDictionary,
    markingDictionary,
    markings,
    colors,
    breeds,
    disciplines,
    dictionariesLoading,
  } = props;

  const {
    actions: {getItems: getBreeds},
  } = breedDictionary;
  const {
    actions: {getItems: getDisciplines},
  } = disciplineDictionary;
  const {
    actions: {getItems: getMarkings},
  } = markingDictionary;
  const {
    actions: {getItems: getColors},
  } = colorsDictionary;

  useEffect(() => {
    getBreeds();
    getDisciplines();
    getMarkings();
    getColors();
  }, [getBreeds, getColors, getDisciplines, getMarkings]);

  const isRequesting = horseDetailsUpdating.isRequesting || dictionariesLoading;

  useOnSuccessCommunication(horseDetailsUpdating, () => onSuccess && onSuccess());

  if (isRequesting) {
    return <Loading />;
  }

  return (
    <Form>
      <InputField
        isRequired={true}
        name="name"
        type="text"
        label="Name"
        placeholder="Horse name"
        autoComplete="off"
        memoized={true}
      />
      <SelectField isRequired={true} name="gender" label="Sex" options={getStringKeysOption(Gender)} memoized={true} />
      <InputField
        name="registryNumber"
        type="text"
        label="Registry number"
        placeholder="Registry number"
        autoComplete="off"
        memoized={true}
      />
      <DateTimeField name="dateOfBirth" label="Date of birth" memoized={true} />
      <HorseHeightField
        heightName="height.height"
        heightPercentWidth={70}
        heightUnitName="height.heightUnit"
        heightUnitPercentWidth={30}
      />
      <SelectField
        isMulti={true}
        name="disciplines"
        label="Discipline"
        options={castToOption(disciplines)}
        memoized={true}
      />
      <SelectField
        isMulti={true}
        name="breeds"
        label="Breeds"
        options={castToOption(breeds)}
        memoized={true}
        isRequired={true}
      />
      <SelectField isMulti={true} name="colors" label="Colors" options={castToOption(colors)} memoized={true} />
      <SelectField isMulti={true} name="markings" label="Markings" options={castToOption(markings)} memoized={true} />
      <InputField
        name="microchipNumber"
        type="text"
        label="Microchip number"
        placeholder="Microchip number"
        autoComplete="off"
        memoized={true}
      />
      <InputField
        name="passportNumber"
        type="text"
        label="Passport number"
        placeholder="Passport number"
        autoComplete="off"
        memoized={true}
      />
      <HorseMapField />

      <CheckboxField name="isPublicProfile" label="Enable diagnostic public profile" style={{marginTop: 16}} />
      <FieldHint style={fieldHintStyles}>
        Enabling this option will allow other users to see this horse profile including the variant summary for
        diagnostic, health and coat color data. You may also share it using the link.
      </FieldHint>
      <CheckboxField name="isAncestryPublicProfile" label="Enable ancestry public profile" />
      <FieldHint style={fieldHintStyles}>
        Enabling this option will allow other users to see this horse's Ancestry profile. You may also share it by using
        the link.
      </FieldHint>
      <SaveButton className="w-100" size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Save changes
      </SaveButton>
    </Form>
  );
};

const HorseInfoSectionFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({data}) => (data ? withHorseMapFieldPropsToClient(convertToClient(data)) : initialValue),
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {updateHorseDetails} = formikBag.props;
    await updateHorseDetails(withHorseMapFieldPropsToRequest(convertToServer(values)));
  },
  enableReinitialize: true,
})(HorseInfoSectionEdit);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {colorsDictionary, breedDictionary, disciplineDictionary, markingDictionary} = props;
  const {selectors: horseColorsSelectors} = colorsDictionary;
  const {selectors: breedSelectors} = breedDictionary;
  const {selectors: disciplineSelectors} = disciplineDictionary;
  const {selectors: markingSelectors} = markingDictionary;

  return {
    colors: horseColorsSelectors.selectItems(state),
    breeds: breedSelectors.selectItems(state),
    disciplines: disciplineSelectors.selectItems(state),
    markings: markingSelectors.selectItems(state),
    horseDetailsUpdating: selectors.selectCommunication(state, 'horseDetailsUpdating'),
    dictionariesLoading: [horseColorsSelectors, breedSelectors, disciplineSelectors, markingSelectors].some(
      (i) => i.selectCommunication(state, 'itemsLoading').isRequesting
    ),
  };
};

const mapDispatchToProps = {
  updateHorseDetails: actions.updateHorseDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(HorseInfoSectionFormik);
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {horseColors, breeds, disciplines, markings} = useDictionaries();

  return (
    <Connected
      colorsDictionary={horseColors}
      breedDictionary={breeds}
      disciplineDictionary={disciplines}
      markingDictionary={markings}
      {...externalProps}
    />
  );
};

export default Exported;
