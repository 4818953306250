import React, {memo, useEffect, useRef} from 'react';
import * as R from 'ramda';
import Scrollbars, {ScrollbarProps} from 'react-custom-scrollbars';
import styled from 'styled-components';

const AutoFocusTrigger = styled.button`
  position: absolute;
  border: none;
  cursor: default !important;
  opacity: 0;
`;

interface IOwnProps {
  minHeight?: string | number;
  maxHeight?: string | number;
  isScrollToBottom?: boolean;
}

interface Props extends IOwnProps, ScrollbarProps {
  renderViewStyle?: React.CSSProperties;
}

function Scrollbar(props: Props) {
  const {
    height,
    style,
    renderViewStyle,
    autoHeight = true,
    minHeight,
    maxHeight,
    children,
    isScrollToBottom = false,
    ...rest
  } = props;

  const isHasHeight = !R.isNil(height);

  const scrollRef = useRef<Scrollbars>(null);

  // Scroll to bottom if children is updated and isScrollToBottom props is true
  useEffect(() => {
    if (isScrollToBottom) {
      scrollRef.current?.scrollToBottom();
    }
  }, [isScrollToBottom, children]);

  return (
    <Scrollbars
      autoHeight={autoHeight}
      autoHeightMin={minHeight}
      autoHeightMax={maxHeight}
      style={isHasHeight ? {height, ...style} : style}
      renderView={({style, ...scrollbarProps}: any) => (
        <div style={{...style, ...renderViewStyle, paddingRight: 30}} {...scrollbarProps} tabIndex={-1} />
      )}
      {...rest}
      ref={scrollRef}
    >
      <AutoFocusTrigger autoFocus={true} onClick={(e) => e.preventDefault()} />
      {children}
    </Scrollbars>
  );
}

export default memo(Scrollbar);
