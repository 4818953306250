export enum Gender {
  Stallion = 'Stallion',
  Gelding = 'Gelding',
  Mare = 'Mare',
}

export enum DogGender {
  Male = 'Male',
  Female = 'Female',
}
