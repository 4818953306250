import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {useCallback, useState} from 'react';

import {getEnvParams} from 'Common/helpers/getEnvParams';
import {Hubs} from 'SignalR/const/Hubs';
import {getAccessToken} from 'SignalR/helpers/getAccessToken';
import {INotificationMessage} from 'SignalR/models/INotificationMessage';
import {signalRLogger} from 'SignalR/helpers/signalRLogger';
import {DEFAULT_LOG_LEVEL} from 'SignalR/const/LogLevel';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';

export function useSignalRSystemToUserNotification() {
  const [connection, setConnection] = useState<HubConnection>();
  const [isConnectionInitialized, setIsConnectionInitialized] = useState<boolean>(false);
  const [connectionState, setConnectionState] = useState<HubConnectionState>(HubConnectionState.Disconnected);

  const {baseUrl, signalRLogLevel} = getEnvParams();

  const {emit} = useEventBus();

  const initConnection = useCallback(
    async (hub: Hubs) => {
      const logLevel = LogLevel[signalRLogLevel] !== undefined ? LogLevel[signalRLogLevel] : DEFAULT_LOG_LEVEL;

      const newConnection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}${hub}`, {
          accessTokenFactory: getAccessToken,
        })
        .configureLogging(logLevel)
        .withAutomaticReconnect()
        .build();

      newConnection.on('NotificationReceived', (message: INotificationMessage) => {
        signalRLogger('System Notification Received', message);
        emit(EventName.SystemNotificationReceived, message);
      });

      setConnection(newConnection);
      setIsConnectionInitialized(true);
    },
    [baseUrl, emit, signalRLogLevel]
  );

  const startConnection = useCallback(async () => {
    if (connectionState === HubConnectionState.Disconnected && connection) {
      try {
        setConnectionState(HubConnectionState.Connecting);
        await connection.start();
        setConnectionState(HubConnectionState.Connected);
        signalRLogger('systemToUserNotification connection started. id', connection.connectionId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection, connectionState]);

  const closeConnection = useCallback(async () => {
    if (connection) {
      try {
        signalRLogger('systemToUserNotification connection closed. id', connection.connectionId);
        setConnectionState(HubConnectionState.Disconnecting);
        await connection.stop();
        setConnectionState(HubConnectionState.Disconnected);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection]);

  return {
    initConnection,
    isConnectionInitialized,
    connectionState,
    startConnection,
    closeConnection,
  };
}
