import {IModule} from 'redux-dynamic-modules';

import {dogTestResultsUploadReducer} from './reducer';
import {IAdminDogTestResultsUploadState} from './types';

export interface IAdminDogTestResultsUploadModuleState {
  adminDogTestResultsUpload: IAdminDogTestResultsUploadState;
}

export const AdminDogTestResultsUploadModule: IModule<IAdminDogTestResultsUploadModuleState> = {
  id: 'adminDogTestResultsUpload',
  reducerMap: {
    adminDogTestResultsUpload: dogTestResultsUploadReducer,
  },
};
