import {Form, FormikProps, withFormik} from 'formik';
import {memo, useEffect, useMemo} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminHorseTransfer';
import {AdminCreateUserOrderModule} from 'Admin/AdminDashboard/store/adminOrders/createUserOrder/adminCreateUserOrderModule';
import {
  actions as createOrderActions,
  selectors as createOrderSelectors,
} from 'Admin/AdminDashboard/store/adminOrders/createUserOrder/index';
import {Divider} from 'Admin/shared/components/styled';
import {InputField, SelectField} from 'Common/components/FormFields';
import Nebula from 'Common/components/Layout/Nebula';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, MutableField, MutableFieldsGroup} from 'Common/components/StyledComponents/StyledComponents';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useSuccessCommunicationToToast} from 'Common/helpers/hooks/useSuccessCommunicationToToast';
import {castToOption} from 'Common/helpers/OptionHelper';
import {sortByName} from 'Common/helpers/sortByName';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {IFormValues, initialValue, validationSchema} from './validation';

interface IExternalProps {
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function CreateTransferForm(props: AllProps) {
  const {horseTransferCreating, horses, getHorses, onSuccess} = props;
  const {setErrors, setStatus, status, values, isValid} = props;

  const errorInfo = horseTransferCreating.error;

  useEffect(() => {
    if (values.userId) {
      getHorses(values.userId);
    }
  }, [getHorses, values.userId]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  useOnSuccessCommunication(horseTransferCreating, onSuccess);
  useSuccessCommunicationToToast(horseTransferCreating, 'Horse transfer request has been created');

  const userNotFound = !values.userId || values.userId === 0;

  const horsesOptions = useMemo(() => castToOption(horses.sort(sortByName)), [horses]);

  const isLoading = horseTransferCreating.isRequesting;

  return (
    <>
      <ModalWindowHeader>Create Horse Transfer Request</ModalWindowHeader>
      <Form>
        <ModalWindowFormContent>
          <UserSearchField name="userId" label="Current owner" isRequired={true} />
          {userNotFound && <Divider />}
          <Nebula active={userNotFound} style={{opacity: 0.5, marginTop: -5}}>
            {!userNotFound && <SelectField isRequired={true} name="horseId" options={horsesOptions} label="Horse" />}
          </Nebula>

          <MutableFieldsGroup indent="16px">
            <MutableField width={50}>
              <InputField name="providedFirstName" label="Client First Name" />
            </MutableField>
            <MutableField width={50}>
              <InputField name="providedLastName" label="Client Last Name" />
            </MutableField>
          </MutableFieldsGroup>
          <InputField name="providedEmail" label="Client Email" />
          <InputField name="providedPhone" label="Phone Number" />

          <InputField name="signature" label="Signature" />
        </ModalWindowFormContent>

        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={!isValid} isLoading={isLoading}>
            Submit
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
}

const CreateTransferFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {createHorseTransfer} = formikBag.props;
    formikBag.setSubmitting(true);
    await createHorseTransfer(values);
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(CreateTransferForm);

const mapStateToProps = (state: IAppState) => ({
  horses: createOrderSelectors.selectUserOrderHorses(state),
  horseTransferCreating: selectors.selectCommunication(state, 'horseTransferCreating'),
});

const mapDispatchToProps = {
  createHorseTransfer: actions.createHorseTransfer,
  getHorses: createOrderActions.getUserHorses,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(CreateTransferFormFormik));

export default withDynamicModules(memo(Connected), AdminCreateUserOrderModule);
