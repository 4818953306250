import * as React from 'react';
import Dropdown, {DropdownProps} from 'react-bootstrap/Dropdown';

import {RenderToggle, Toggle} from 'Common/components/DropDown/common/components';
import {Divider, Menu, MenuItem, MenuItemIcon, MenuItemLink} from './common/styles';
import {IMenuItem} from './common/types';
import {Nullable} from 'Common/types';
import Nebula from 'Common/components/Layout/Nebula';
import './common/styles.css';

const defaultIconProps = {size: 18, fill: true, stroke: false};
const nebulaStyle = {opacity: 0.3};

interface IProps {
  items: IMenuItem[];
  children: RenderToggle;
  alignRight?: DropdownProps['alignRight'];
  onSelect(value: Nullable<string>): void;
  itemsStyle?: React.CSSProperties;
}

export function DropDownMenu(props: IProps) {
  const {children, items, itemsStyle, onSelect, alignRight} = props;

  const popperConfig = {modifiers: [{name: 'computeStyle', options: {gpuAcceleration: false}}]};

  return (
    <Dropdown onSelect={onSelect} alignRight={alignRight}>
      <Dropdown.Toggle as={Toggle} id="dropdown-custom-components">
        {children as any}
      </Dropdown.Toggle>
      <Menu popperConfig={popperConfig}>
        {items.map(
          ({value, href, label, divided, style, icon, iconElement, iconStyle, isMenuItemAsLink, isDisabled}) => (
            <React.Fragment key={value}>
              {divided && items.length > 1 && <Divider />}
              <Nebula active={isDisabled} style={nebulaStyle}>
                {isMenuItemAsLink ? (
                  <Dropdown.Item eventKey={value} as="div" className="dropdownItem">
                    <MenuItemLink
                      to={href || value}
                      className="d-flex align-items-center"
                      style={{...itemsStyle, ...style}}
                      {...style}
                    >
                      {icon ? (
                        <MenuItemIcon {...defaultIconProps} {...icon} style={iconStyle} />
                      ) : iconElement ? (
                        <div className="mr-2">{iconElement}</div>
                      ) : null}
                      {label}
                    </MenuItemLink>
                  </Dropdown.Item>
                ) : (
                  <MenuItem
                    className="d-flex align-items-center"
                    eventKey={value}
                    style={{...itemsStyle, ...style}}
                    {...style}
                  >
                    {icon ? (
                      <MenuItemIcon {...defaultIconProps} {...icon} style={iconStyle} />
                    ) : iconElement ? (
                      <div className="mr-2">{iconElement}</div>
                    ) : null}
                    {label}
                  </MenuItem>
                )}
              </Nebula>
            </React.Fragment>
          )
        )}
      </Menu>
    </Dropdown>
  );
}
