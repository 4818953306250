import {Reducer} from 'redux';

import {
  AdminAssociationOrdersActions,
  changeSampleActionTypes,
  getOrderDetailsActionTypes,
  getOrderPaymentDetailsActionTypes,
  getOrderReportDetailsActionTypes,
  getOrderRequiredTestsActionTypes,
  getOrdersActionTypes,
  getOrderSampleDetailsActionTypes,
  getOrderStatusesDetailsActionTypes,
  getOrderTestsDetailsActionTypes,
  IAdminAssociationOrderState,
  resetOrderDetailsActionTypes,
} from '../types';
import {PaymentMethod} from 'Common/constants/PaymentMethod';
import {HorseSampleStatus, OrderStatusField} from 'Order/models/IOrderStatusFields';
import {OrderStatus} from 'Common/constants/OrderStatus';

const initialStateList: IAdminAssociationOrderState['data'] = {
  orders: [],
  orderDetails: {
    id: 0,
    horse: {id: 0, name: ''},
    owner: {
      id: 0,
      name: '',
      address: {city: '', country: '', state: '', street: '', zip: ''},
      avatar: null,
      phone: '',
      email: '',
    },
  },
  orderPaymentDetails: {
    coupon: '',
    paymentDate: null,
    paymentMethod: PaymentMethod.None,
    status: OrderStatusField.Waiting,
    totalDiscount: 0,
    totalPrice: 0,
    transaction: '',
  },
  requiredTests: [],
  orderReportDetails: [],
  orderStatusesDetails: {
    hasNotResults: false,
    hasPayment: false,
    hasRelease: false,
    hasSample: false,
    status: OrderStatus.orderPlaced,
  },
  orderSampleDetails: {id: 0, receivedDate: '', sampleId: '', status: HorseSampleStatus.Waiting},
  orderTestsDetails: {packages: [], tests: [], status: OrderStatusField.Waiting, totalPrice: 0},
};

export const dataOrdersReducer: Reducer<IAdminAssociationOrderState['data'], AdminAssociationOrdersActions> = (
  state = initialStateList,
  action: AdminAssociationOrdersActions
) => {
  switch (action.type) {
    case getOrdersActionTypes.SUCCESS: {
      return {...state, orders: action.payload.data};
    }

    case getOrderDetailsActionTypes.SUCCESS: {
      return {...state, orderDetails: action.payload};
    }

    case getOrderPaymentDetailsActionTypes.REQUEST: {
      return {...state, orderPaymentDetails: initialStateList.orderPaymentDetails};
    }
    case getOrderPaymentDetailsActionTypes.SUCCESS: {
      return {...state, orderPaymentDetails: action.payload};
    }

    case getOrderRequiredTestsActionTypes.REQUEST: {
      return {...state, requiredTests: initialStateList.requiredTests};
    }
    case getOrderRequiredTestsActionTypes.SUCCESS: {
      return {...state, requiredTests: action.payload.data};
    }

    case resetOrderDetailsActionTypes.RESET: {
      return {...state, orderDetails: initialStateList.orderDetails};
    }

    case getOrderReportDetailsActionTypes.REQUEST: {
      return {...state, orderReportDetails: initialStateList.orderReportDetails};
    }
    case getOrderReportDetailsActionTypes.SUCCESS: {
      return {...state, orderReportDetails: action.payload.data};
    }

    case getOrderStatusesDetailsActionTypes.REQUEST: {
      return {...state, orderStatusesDetails: initialStateList.orderStatusesDetails};
    }
    case getOrderStatusesDetailsActionTypes.SUCCESS: {
      return {...state, orderStatusesDetails: action.payload};
    }

    case getOrderSampleDetailsActionTypes.REQUEST: {
      return {...state, orderSampleDetails: initialStateList.orderSampleDetails};
    }
    case getOrderSampleDetailsActionTypes.SUCCESS: {
      return {...state, orderSampleDetails: action.payload};
    }

    case getOrderTestsDetailsActionTypes.REQUEST: {
      return {...state, orderTestsDetails: initialStateList.orderTestsDetails};
    }
    case getOrderTestsDetailsActionTypes.SUCCESS: {
      return {...state, orderTestsDetails: action.payload};
    }
    case changeSampleActionTypes.SUCCESS: {
      return {
        ...state,
        orderSampleDetails: {
          ...action.payload,
          receivedDate: state.orderSampleDetails.receivedDate,
          status: state.orderSampleDetails.status,
        },
      };
    }

    default: {
      return state;
    }
  }
};
