import {NotificationType} from 'Notifications/const/NotificationType';
import {IUserNotification} from 'Notifications/models/IUserNotification';
import {IUserNotificationEvent} from 'Notifications/models/IUserNotificationEvent';
import {IUserNotifactionSettings} from 'UserProfile/models/IUserNotifactionSettings';

export function convertToNotificationSettings(
  userNotifications: IUserNotification[],
  notificationEvents: IUserNotificationEvent[]
): IUserNotifactionSettings[] {
  return notificationEvents.map((notificationEvent) => {
    const findNotifications = userNotifications.filter((x) => x.eventId === notificationEvent.id);
    const isEmailOn = findNotifications.some((x) => x.type === NotificationType.Email);
    const isNotificationOn = findNotifications.some((x) => x.type === NotificationType.Notification);
    const emailTypeId = findNotifications.find((x) => x.type === NotificationType.Email)?.id;
    const notificationTypeId = findNotifications.find((x) => x.type === NotificationType.Notification)?.id;

    return {
      id: notificationEvent.id,
      name: notificationEvent.name,
      isEmailOn: isEmailOn,
      isNotificationOn: isNotificationOn,
      emailTypeId: emailTypeId,
      notificationTypeId: notificationTypeId,
    };
  });
}
