import {Reducer} from 'redux';

import {
  IAdminActivationCodeState,
  ActivationCodesActions,
  getActivationCodesActionTypes,
} from 'Admin/AdminDashboard/store/adminActivationCodes/types';

const initialState: IAdminActivationCodeState['ui'] = {
  pagination: {countPerPage: 0, currentPage: 1, pageCount: 1, totalItemsCount: 1},
};

export const uiActivationCodesReducer: Reducer<IAdminActivationCodeState['ui'], ActivationCodesActions> = (
  state = initialState,
  action: ActivationCodesActions
) => {
  switch (action.type) {
    case getActivationCodesActionTypes.SUCCESS: {
      return {...state, pagination: action.payload.pagination};
    }
    default: {
      return state;
    }
  }
};
