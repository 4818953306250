import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IAncestryPopulationComparison} from 'OnlineReport/models/shared/IAncestryPopulationComparison';
import {convertFilesToFormData, convertToFormData} from 'Common/helpers/convertToFormData';
import {IAncestryUploadDetails} from '../models/Ancestry/IAncestryUploadDetails';
import {
  convertAncestryKinshipHorseToClient,
  convertAncestryRelatedHorseToClient,
  convertPcaReferenceDataDtoToPcaReferencePoints,
  convertUploadedDetailsToClient,
} from './converters/ancestry';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {getApiByReportType} from 'OnlineReport/helpers/getApiByReportType';
import {IAncestryPcaReferencePoint} from '../../../OnlineReport/models/Ancestry/IAncestryPcaReferencePoint';
import {IAncestryPcaReferenceDataDto} from '../../../Common/models/IPcaReferenceDataDto';

type AdminReportType = OnlineReportType.Admin | OnlineReportType.AdminAssociation;
type ReviewReportType = OnlineReportType.ReviewAdmin | OnlineReportType.ReviewAdminAssociation;

type ApiByType = Record<AdminReportType | ReviewReportType, string>;

const getPopulation = async (horseId: number): Promise<IAncestryPopulation[]> => {
  const result = await axiosWrapper.get<IListResponse<IAncestryPopulation>>(`/AncestryBreedsAdmin/${horseId}`);
  return result.data.data;
};

const getReviewPopulation = async (horseId: number): Promise<IAncestryPopulation[]> => {
  const result = await axiosWrapper.get<IListResponse<IAncestryPopulation>>(`/AncestryBreedsReviewAdmin/${horseId}`);
  return result.data.data;
};

const getPopulationComparison = async (horseId: number): Promise<IAncestryPopulationComparison> => {
  const result = await axiosWrapper.get<IObjectResponse<IAncestryPopulationComparison>>(
    `/AncestryPcaPointAdmin/${horseId}`
  );
  return result.data.data;
};

const getReviewPopulationComparison = async (horseId: number): Promise<IAncestryPopulationComparison> => {
  const result = await axiosWrapper.get<IObjectResponse<IAncestryPopulationComparison>>(
    `/AncestryPcaPointReviewAdmin/${horseId}`
  );
  return result.data.data;
};

const getAncestryProbabilities = async (horseId: number): Promise<IAncestryProbabilities> => {
  const result = await axiosWrapper.get<IObjectResponse<IAncestryProbabilities>>(
    `/AncestryProbabilitiesAdmin/${horseId}`
  );
  return result.data.data;
};

const getReviewAncestryProbabilities = async (horseId: number): Promise<IAncestryProbabilities> => {
  const result = await axiosWrapper.get<IObjectResponse<IAncestryProbabilities>>(
    `/AncestryProbabilitiesReviewAdmin/${horseId}`
  );
  return result.data.data;
};

const uploadAncestryFiles = async (files: File[]): Promise<void> => {
  await axiosWrapper.post<void>(`/AncestryUpdate`, convertFilesToFormData(files));
};

const getAncestryUploadDetails = async (): Promise<IAncestryUploadDetails> => {
  const result = await axiosWrapper.get<IObjectResponse<IAncestryUploadDetails>>(`/AncestryUpdateDetails`);
  return convertUploadedDetailsToClient(result.data.data);
};

const uploadAncestryHorseLikeMeFiles = async (file: File): Promise<void> => {
  await axiosWrapper.post<void>(`/AncestryRelativesUpdate`, convertToFormData(file));
};

const getAncestryRelatedHorses = async (horseId: number): Promise<IAncestryRelatedHorse[]> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/AncestryRelatedAdmin`,
    [OnlineReportType.AdminAssociation]: `/AncestryRelatedAdmin`,
    [OnlineReportType.ReviewAdmin]: `/AncestryRelatedReviewAdmin`,
    [OnlineReportType.ReviewAdminAssociation]: `/AncestryRelatedReviewAdmin`,
  };

  const api = getApiByReportType(apis);

  const result = await axiosWrapper.get<IListResponse<IAncestryRelatedHorse>>(`${api}/${horseId}`);
  return result.data.data.map(convertAncestryRelatedHorseToClient);
};

const getAncestryKinshipHorses = async (horseId: number): Promise<IAncestryKinshipHorse[]> => {
  const apis: ApiByType = {
    [OnlineReportType.Admin]: `/AncestryKinshipAdmin`,
    [OnlineReportType.AdminAssociation]: `/AncestryKinshipAdmin`,
    [OnlineReportType.ReviewAdmin]: `/AncestryKinshipReviewAdmin`,
    [OnlineReportType.ReviewAdminAssociation]: `/AncestryKinshipReviewAdmin`,
  };

  const api = getApiByReportType(apis);

  const result = await axiosWrapper.get<IListResponse<IAncestryKinshipHorse>>(`${api}/${horseId}`);
  return result.data.data.map(convertAncestryKinshipHorseToClient);
};

const getAncestryPcaReferenceData = async (): Promise<IAncestryPcaReferencePoint[]> => {
  const api = '/AncestryPcaReferencePointAdmin';
  const result = await axiosWrapper.get<IListResponse<IAncestryPcaReferenceDataDto>>(api);
  return result.data.data.map(convertPcaReferenceDataDtoToPcaReferencePoints);
};

export default {
  getPopulation,
  getReviewPopulation,
  getPopulationComparison,
  getReviewPopulationComparison,
  uploadAncestryFiles,
  getAncestryUploadDetails,
  getAncestryProbabilities,
  getReviewAncestryProbabilities,
  uploadAncestryHorseLikeMeFiles,
  getAncestryRelatedHorses,
  getAncestryKinshipHorses,
  getAncestryPcaReferenceData,
};
