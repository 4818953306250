import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import DogTestResultsUploadForm from '../../../components/DogTestResultsUploadForm/DogTestResultsUploadForm';
import {IDog} from '../../../../../Dogs/models/IDog';

interface IUpdateModalParams {
  onSuccess?(): void;
}

export function useDogTestResultsUploadModal(params: IUpdateModalParams) {
  const {onSuccess} = params;

  const [isOpen, setIsOpen] = useState(false);
  const [dog, setDog] = useState<IDog>();
  const openDogTestResultsUploadModal = useCallback((dog: IDog) => {
    setIsOpen(true);
    setDog(dog);
  }, []);
  const closeDogTestResultsUploadModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeDogTestResultsUploadModal();
    onSuccess && onSuccess();
  }, [closeDogTestResultsUploadModal, onSuccess]);

  const dogTestResultsUploadModal = (
    <ModalWindow isOpen={isOpen} onClose={closeDogTestResultsUploadModal}>
      <DogTestResultsUploadForm onSuccess={handleSuccess} dog={dog} />
    </ModalWindow>
  );

  return {
    dogTestResultsUploadModal,
    openDogTestResultsUploadModal,
  };
}
