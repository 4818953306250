import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  contactRequestActionTypes,
  ContactRequestsActions,
  createUsersChatsRequestActionTypes,
  IContactRequestState,
} from '../types';

export const communicationReducer = combineReducers<IContactRequestState['communications'], ContactRequestsActions>({
  sendContactRequesting: makeCommunicationReducerFromEnum(contactRequestActionTypes),
  usersChatRequestCreating: makeCommunicationReducerFromEnum(createUsersChatsRequestActionTypes),
});
