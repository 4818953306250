import React, {PropsWithChildren, useEffect} from 'react';
import {HubConnectionState} from '@microsoft/signalr';

import {Permission} from 'Permissions/constants/Permission';
import usePermissions from 'Permissions/hooks/usePermissions';
import {useSignalRUserToUserChat} from './useSignalRUserToUserChat';
import {Hubs} from 'SignalR/const/Hubs';
import {useSignalRUserToAdminChat} from './useSignalRUserToAdminChat';
import {useSignalRUserNotification} from './useSignalRUserNotification';
import {useSignalRUserToUserMessaging} from './useSignalRUserToUserMessaging';
import {useSignalRUserToAdminMessaging} from './useSignalRUserToAdminMessaging';
import {useSignalRSystemToUserNotification} from './useSignalRSystemToUserNotification';

interface ISignalRContext {}

interface IProps {
  isAuthorized: boolean;
  currentUserId?: number;
  currentAdminId?: number;
}

const singalRServices = {} as ISignalRContext;

export const SignalRContext = React.createContext<ISignalRContext>({} as ISignalRContext);

export default function SingalRProvider(props: PropsWithChildren<IProps>) {
  const {children, isAuthorized, currentUserId, currentAdminId} = props;

  const {hasPermission} = usePermissions();
  const userToUserChat = useSignalRUserToUserChat();
  const userToUserMessaging = useSignalRUserToUserMessaging();
  const userToAdminChat = useSignalRUserToAdminChat();
  const userToAdminMessaging = useSignalRUserToAdminMessaging();
  const userNotification = useSignalRUserNotification();
  const systemToUserNotification = useSignalRSystemToUserNotification();

  // Initialize signalR hubs
  useEffect(() => {
    // Initialize userToUserChat hub
    if (hasPermission(Permission.UsersChat) && !userToUserChat.isConnectionInitialized) {
      userToUserChat.initConnection(Hubs.UserToUserChat);
    }

    // Initialize userToUserMessaging hub
    if (hasPermission(Permission.UsersChat) && currentUserId && !userToUserMessaging.isConnectionInitialized) {
      userToUserMessaging.initConnection(Hubs.UserToUserMessaging, currentUserId);
    }

    // Initialize userToAdminChat hub for User
    if (hasPermission(Permission.UsersChat) && currentUserId && !userToAdminChat.isConnectionInitialized) {
      userToAdminChat.initConnection(Hubs.UserToAdminChat);
    }

    // Initialize userToAdminChat hub for Admin
    if (currentAdminId && !userToAdminChat.isConnectionInitialized) {
      userToAdminChat.initConnection(Hubs.UserToAdminChat);
    }

    // Initialize userToAdminMessaging hub for User
    if (hasPermission(Permission.UsersChat) && currentUserId && !userToAdminMessaging.isConnectionInitialized) {
      userToAdminMessaging.initConnection(Hubs.UserToAdminMessaging, currentUserId);
    }

    // Initialize userToAdminMessaging hub for Admin
    if (currentAdminId && !userToAdminMessaging.isConnectionInitialized) {
      userToAdminMessaging.initConnection(Hubs.UserToAdminMessaging, currentAdminId);
    }

    // Initialize userNotification hub for User
    if (hasPermission(Permission.UsersChat) && currentUserId && !userNotification.isConnectionInitialized) {
      userNotification.initConnection(Hubs.UserNotification);
    }

    // Initialize systemToUserNotification hub for User
    if (hasPermission(Permission.UsersChat) && currentUserId && !systemToUserNotification.isConnectionInitialized) {
      systemToUserNotification.initConnection(Hubs.SystemToUserNotification);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission, currentUserId, currentAdminId]);

  // Start/close userToUserChat connection
  useEffect(() => {
    if (
      isAuthorized &&
      userToUserChat.isConnectionInitialized &&
      userToUserChat.connectionState === HubConnectionState.Disconnected
    ) {
      userToUserChat.startConnection();
    }

    if (!isAuthorized && userToUserChat.connectionState !== HubConnectionState.Disconnected) {
      userToUserChat.closeConnection();
    }
  }, [isAuthorized, userToUserChat]);

  // Start/close userToUserMessaging connection
  useEffect(() => {
    if (
      isAuthorized &&
      currentUserId &&
      userToUserMessaging.isConnectionInitialized &&
      userToUserMessaging.connectionState === HubConnectionState.Disconnected
    ) {
      userToUserMessaging.startConnection();
    }

    if ((!isAuthorized || !currentUserId) && userToUserMessaging.connectionState !== HubConnectionState.Disconnected) {
      userToUserMessaging.closeConnection();
    }
  }, [isAuthorized, currentUserId, userToUserMessaging]);

  // Start/close userToAdminChat connection
  useEffect(() => {
    if (
      isAuthorized &&
      (currentUserId || currentAdminId) &&
      userToAdminChat.isConnectionInitialized &&
      userToAdminChat.connectionState === HubConnectionState.Disconnected
    ) {
      userToAdminChat.startConnection();
    }

    if (
      (!isAuthorized || !(currentUserId || currentAdminId)) &&
      userToAdminChat.connectionState !== HubConnectionState.Disconnected
    ) {
      userToAdminChat.closeConnection();
    }
  }, [isAuthorized, currentUserId, currentAdminId, userToAdminChat]);

  // Start/close userToAdminMessaging connection
  useEffect(() => {
    if (
      isAuthorized &&
      (currentUserId || currentAdminId) &&
      userToAdminMessaging.isConnectionInitialized &&
      userToAdminMessaging.connectionState === HubConnectionState.Disconnected
    ) {
      userToAdminMessaging.startConnection();
    }

    if (
      (!isAuthorized || !(currentUserId || currentAdminId)) &&
      userToAdminMessaging.connectionState !== HubConnectionState.Disconnected
    ) {
      userToAdminMessaging.closeConnection();
    }
  }, [isAuthorized, currentUserId, currentAdminId, userToAdminMessaging]);

  // Start/close userNotification connection
  useEffect(() => {
    if (
      isAuthorized &&
      currentUserId &&
      userNotification.isConnectionInitialized &&
      userNotification.connectionState === HubConnectionState.Disconnected
    ) {
      userNotification.startConnection();
    }

    if ((!isAuthorized || !currentUserId) && userNotification.connectionState !== HubConnectionState.Disconnected) {
      userNotification.closeConnection();
    }
  }, [userNotification, currentUserId, isAuthorized]);

  // Start/close systemToUserNotification connection
  useEffect(() => {
    if (
      isAuthorized &&
      currentUserId &&
      systemToUserNotification.isConnectionInitialized &&
      systemToUserNotification.connectionState === HubConnectionState.Disconnected
    ) {
      systemToUserNotification.startConnection();
    }

    if (
      (!isAuthorized || !currentUserId) &&
      systemToUserNotification.connectionState !== HubConnectionState.Disconnected
    ) {
      systemToUserNotification.closeConnection();
    }
  }, [currentUserId, isAuthorized, systemToUserNotification]);

  return <SignalRContext.Provider value={singalRServices}>{children}</SignalRContext.Provider>;
}

export function useSignalR() {
  return React.useContext(SignalRContext);
}
