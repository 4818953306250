import React, {memo} from 'react';

import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {SelectField, InputField} from 'Common/components/FormFields/index';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import {Salutation} from 'Common/constants/Salutation';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import {IconName} from 'Icon/components/Icon';
import {FIELD_CONTROL_CONTAINER_STYLE} from 'Common/constants/Styled';

const salutations = getStringKeysOption(Salutation).filter((item) => item.value !== Salutation.Unknown);

const GeneralInformation: React.FC = () => {
  return (
    <FormControlContainer title="General information" style={{marginTop: 24}}>
      <div className="d-flex justify-content-start">
        <FieldControlContainer style={{minWidth: 100, maxWidth: 0, marginBottom: 0}} className="w-25">
          <SelectField
            name="salutation"
            label="Salutation"
            placeholder="Salutation"
            options={salutations}
            isClearable={true}
          />
        </FieldControlContainer>
        <div className="w-75 d-flex">
          <FieldControlContainer style={FIELD_CONTROL_CONTAINER_STYLE} className="w-50">
            <InputField name="firstName" label="First Name" placeholder="First Name" />
          </FieldControlContainer>
          <FieldControlContainer style={FIELD_CONTROL_CONTAINER_STYLE} className="w-50">
            <InputField name="lastName" label="Last Name" placeholder="Last Name" />
          </FieldControlContainer>
        </div>
      </div>
      <FieldControlContainer style={{marginTop: 8}}>
        <ImageUploaderField name="avatar" title="Add profile image" iconName={IconName.Person} />
      </FieldControlContainer>
    </FormControlContainer>
  );
};

export default memo(GeneralInformation);
