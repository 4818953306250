import {debounce} from 'lodash';
import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useStore} from 'react-redux';

import {defaultRequestParams, defaultSortingParams, requestParamsBlank} from 'Common/constants/RequestParams';
import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import {useDebouncedEffect} from 'Common/helpers/hooks/useDebouncedEffect';
import {useQueryParams} from 'Common/helpers/hooks/useQueryParams';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IAppState} from 'Common/store/IAppState';
import FacetSearchBar from 'FacetFilter/components/FacetSearchBar';
import {FilterType} from 'FacetFilter/constants/FilterType';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import {CustomFilterToProperty, DictionaryToProperty, EnumToProperty} from 'FacetFilter/models/types';
import {actions} from 'FacetFilter/store';

interface IProps {
  filterItems: IFilterProperty[];
  getItemsIgnoreParams?: string[];
  mapEnumToProperty?: EnumToProperty;
  mapDictionaryToProperty?: DictionaryToProperty;
  mapCustomFilterToProperty?: CustomFilterToProperty;

  getFilterItems(): void;
  getItems?(params: IRequestParams): void;
}

export function useFacetSearchBar(props: IProps) {
  const {
    filterItems,
    getFilterItems,
    getItems,
    getItemsIgnoreParams = ['selectedId'],
    mapEnumToProperty,
    mapDictionaryToProperty,
    mapCustomFilterToProperty,
  } = props;

  const {getFilterPropertyOperations} = useBindActions({
    getFilterPropertyOperations: actions.getFilterPropertyOperations,
  });
  const store = useStore<IAppState>();
  const facetFilter = store.getState().facetFilter;

  const [facetFilterState, setFacetFilterState] = useState(facetFilter.data.filterPropertyOperations);
  const [defaultFilterRequest, setDefaultFilterRequest] = useState<IFilterRequest[]>([]);

  useEffect(() => {
    getFilterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [requestParams, setRequestParams] = useQueryParams<IRequestParams>(requestParamsBlank, ['filters']);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params: IRequestParams = useMemo(
    () => ({...defaultRequestParams, ...defaultSortingParams, ...requestParams}),
    [requestParams]
  );

  const [itemParams, setItemParams] = useState(params);
  useEffect(() => {
    const res = {...params};
    getItemsIgnoreParams && getItemsIgnoreParams.forEach((ignoreKey) => delete res[ignoreKey]);

    if (JSON.stringify(res) !== JSON.stringify(itemParams)) {
      setItemParams(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const reloadItems = useCallback(() => {
    getItems && getItems(itemParams);
  }, [getItems, itemParams]);

  useDebouncedEffect(reloadItems, 200);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeFilter = useCallback(
    debounce(async (value: string) => setRequestParams({...params, filters: value, currentPage: 1}), 500),
    [getItems, params]
  );

  const handleChangeFilter = useCallback(
    (value) => {
      // TODO: Maybe this should be without JSON.strigify(), but with stringifing of qs lib
      // if so, it should pass only debouncedChangeFilter(value);
      debouncedChangeFilter(value);
    },
    [debouncedChangeFilter]
  );

  const getFilterOperation = useCallback(
    async (type: FilterType) => {
      await getFilterPropertyOperations(type);
    },
    [getFilterPropertyOperations]
  );

  /**
   * Gets FilterOperator if it's not exist in Store
   */
  useEffect(() => {
    if (!R.isNil(filterItems)) {
      const uniqueFilterTypes = filterItems
        .map((x) => x.type)
        .filter((value, idx, self) => self.indexOf(value) === idx);

      uniqueFilterTypes.forEach(async (filter) => {
        await getFilterOperation(filter);
        setFacetFilterState(store.getState().facetFilter.data.filterPropertyOperations);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems, store]);

  useEffect(() => {
    if (!R.isNil(params.filters)) {
      setDefaultFilterRequest(params.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchBar = (
    <FacetSearchBar
      filterProperties={filterItems}
      filterPropertyOperations={facetFilterState}
      defaultFilterRequest={defaultFilterRequest}
      mapEnumToProperty={mapEnumToProperty}
      mapDictionaryToProperty={mapDictionaryToProperty}
      mapCustomFilterToProperty={mapCustomFilterToProperty}
      onChange={handleChangeFilter}
      onReload={reloadItems}
    />
  );

  return {
    searchBar,
  };
}
