import {convertToFormData} from 'Common/helpers/convertToFormData';
import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';

const uploadTestResults = async (file: File): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/HorseTraitsUpdateAdmin`, convertToFormData(file));
};

export default {
  uploadTestResults,
};
