import React, {useState, memo, useEffect} from 'react';
import * as history from 'history';
import styled from 'styled-components';
import {NavLinkProps, useLocation} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';
import ColoredNavLink from 'Common/components/Controls/Links/ColoredNavLink';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';

const defaultActiveStyle: React.CSSProperties = {
  color: ColorPalette.black1,
};

const DetailsLink = styled(ColoredNavLink)<IProps>`
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 16px;
  opacity: 1;
  color: ${ColorPalette.black1};

  @media ${breakpoints.md} {
    font-size: ${Typography.size.size20};
  }

  &:hover {
    color: ${({$hoverStyle}) => $hoverStyle?.color ?? ColorPalette.black1} !important;
    background-color: ${({$hoverStyle}) => $hoverStyle?.backgroundColor} !important;
    opacity: ${({$hoverStyle}) => $hoverStyle?.opacity} !important;
  }
`;

const SelectedBorder = styled.div<{width?: string}>`
  width: ${(props) => props.width ?? '100%'};
  border: 1px solid ${ColorPalette.red7};
  background: ${ColorPalette.red7};
`;

export interface ILinkHoverStyle {
  color?: string;
  backgroundColor?: string;
  opacity?: number;
}

interface IProps {
  to: string;
  disabled?: boolean;
  borderWidth?: string;
  onClickHandle?: () => void;
  checkIsActive?: (location: history.Location) => boolean;
  $hoverStyle?: ILinkHoverStyle;
  activeStyle?: React.CSSProperties;
}

type AllProps = React.PropsWithChildren<IProps> & NavLinkProps;

const HorizontalMenuLink = (props: AllProps) => {
  const [isActive, setIsActive] = useState(false);
  const {to, disabled, onClickHandle, checkIsActive, children, borderWidth, $hoverStyle, activeStyle, ...rest} = props;

  const location = useLocation();

  useEffect(() => {
    let active = location.pathname.startsWith(to);
    if (checkIsActive) {
      active = checkIsActive(location);
    }
    setIsActive(active);
  }, [checkIsActive, location, to]);

  return (
    <div className="d-flex flex-column align-items-center">
      <DetailsLink
        className="d-flex nav-link justify-content-center align-items-center"
        to={to}
        $hoverStyle={$hoverStyle}
        disabled={disabled}
        onClick={onClickHandle}
        activestyle={activeStyle ?? defaultActiveStyle}
        {...rest}
      >
        {children}
      </DetailsLink>
      {!disabled && isActive && <SelectedBorder width={borderWidth} />}
    </div>
  );
};

export default memo(HorizontalMenuLink);
