import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {convertToClientDate, convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IAdminAssociationOrder} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrder';
import {
  IServerAssociationOrderAdmin,
  IServerAssociationOrderDetailsAdmin,
  IServerAssociationOrderPaymentDetailsAdmin,
  IServerAssociationOrderReportDetailsAdmin,
  IServerAssociationOrderStatusesAdmin,
  IServerAssociationOrderTestsDetailsAdmin,
  IServerOrderHorse,
} from '../types/order';
import {IAdminAssociationOrderPaymentDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderPaymentDetails';
import {IAdminAssociationOrderDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderDetails';
import {IAdminAssociationOrderReportDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderReportDetails';
import {HorseSampleStatus, OrderStatusField} from 'Order/models/IOrderStatusFields';
import {IRequestSetSampleStatus} from 'Admin/AdminAssociations/store/adminOrders';
import {IAdminAssociationOrderTestsDetails} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderTestsDetails';
import {ISampleAdmin} from 'Admin/AdminAssociations/models/Order/IAdminAssociationOrderHorseDetails';
import {parseOrderStatusDate} from 'Order/helpers/parseOrderStatusDate';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';
import {IOrderHorse} from 'BusinessPortal/models/order/createOrder/IOrderHorse';
import {convertUserName} from 'Common/helpers/convertUserName';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {withBucketBaseUrl} from 'Common/helpers/withBucketBaseUrl';

export function convertOrderAdminToClient(order: IServerAssociationOrderAdmin): IAdminAssociationOrder {
  return {
    id: order.id,
    createDate: convertUTCToClientDate(order.createDate) || '',
    organization: order.organization,
    horse: withAvatar(order.horse),
    packages: order.packages,
    tests: order.tests,
    coupon: order.coupon,
    finalPrice: convertPriceToClient(order.finalPrice),
    statuses: parseOrderStatus(order.statuses),
    owner: withUserName(withAvatar(order.owner)),
    statusUpdateDates: parseOrderStatusDate(order.statuses),
  };
}

export function convertOrderDetailsToClient(order: IServerAssociationOrderDetailsAdmin): IAdminAssociationOrderDetails {
  return {
    id: order.id,
    horse: order.horse,
    owner: withUserName(withAvatar(order.owner)),
  };
}

export function convertPaymentDetails(
  paymentDetails: IServerAssociationOrderPaymentDetailsAdmin
): IAdminAssociationOrderPaymentDetails {
  return {
    coupon: paymentDetails.coupon,
    paymentMethod: paymentDetails.paymentMethod,
    paymentDate: paymentDetails.paymentDate ? convertUTCToClientDate(paymentDetails.paymentDate) : null,
    totalPrice: convertPriceToClient(paymentDetails.totalPrice),
    totalDiscount: convertPriceToClient(paymentDetails.totalDiscount),
    status: paymentDetails.status,
    transaction: paymentDetails.transaction,
  };
}

export function convertReportDetails(
  reportDetails: IServerAssociationOrderReportDetailsAdmin
): IAdminAssociationOrderReportDetails {
  return {
    ...withBucketBaseUrl(reportDetails),
    uploadDate: reportDetails.uploadDate ? convertToClientDate(reportDetails.uploadDate) || '' : '',
  };
}

function convertSampleStatus(status: OrderStatusField): HorseSampleStatus {
  switch (status) {
    case OrderStatusField.Ready: {
      return HorseSampleStatus.Received;
    }
    case OrderStatusField.Waiting: {
      return HorseSampleStatus.Waiting;
    }
  }
}

export function convertSampleStatusRequest(value: IRequestSetSampleStatus) {
  return {
    ...value,
    status: convertSampleStatus(value.status),
  };
}

export function convertTestsDetails(
  testsDetails: IServerAssociationOrderTestsDetailsAdmin
): IAdminAssociationOrderTestsDetails {
  return {
    totalPrice: convertPriceToClient(testsDetails.totalPrice),
    packages:
      testsDetails.packages?.map((x) => ({
        name: x.name,
        price: convertPriceToClient(x.price),
      })) || [],
    tests:
      testsDetails.tests?.map((x) => ({
        name: x.name,
        price: convertPriceToClient(x.price),
      })) || [],
    status: testsDetails.status,
  };
}

export function convertSampleDetails(sample: ISampleAdmin): ISampleAdmin {
  return {...sample, receivedDate: sample.receivedDate ? convertToClientDate(sample.receivedDate) || '' : ''};
}

export function convertStatusDetails(status: IServerAssociationOrderStatusesAdmin): IOrderStatused {
  return {statusUpdateDates: {...parseOrderStatusDate(status)}, ...parseOrderStatus(status)};
}

export function convertOrderHorseToClient(orderHorse: IServerOrderHorse): IOrderHorse {
  return {
    ...withBirthDate(orderHorse),
    ownerName: convertUserName(orderHorse.ownerFirstName, orderHorse.ownerLastName),
  };
}
