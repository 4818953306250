import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryPcaReferencePoint} from 'OnlineReport/models/Ancestry/IAncestryPcaReferencePoint';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {IAncestryPopulationComparison} from 'OnlineReport/models/shared/IAncestryPopulationComparison';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';

const STATE_NAME = 'adminAncestryReport';

export interface IAdminAncestryReportState {
  data: {
    population: Nullable<IAncestryPopulation[]>;
    populationComparison: Nullable<IAncestryPopulationComparison>;
    ancestryProbabilities: Nullable<IAncestryProbabilities>;
    ancestryRelatedHorses: IAncestryRelatedHorse[];
    ancestryKinshipHorses: IAncestryKinshipHorse[];
    ancestryPcaReferenceData: IAncestryPcaReferencePoint[];
  };
  communications: {
    populationLoading: ICommunication;
    reviewPopulationLoading: ICommunication;
    populationComparisonLoading: ICommunication;
    reviewPopulationComparisonLoading: ICommunication;
    ancestryProbabilitiesLoading: ICommunication;
    reviewAncestryProbabilitiesLoading: ICommunication;
    ancestryRelatedHorsesLoading: ICommunication;
    ancestryKinshipHorsesLoading: ICommunication;
    ancestryPcaReferenceDataLoading: ICommunication;
  };
}

export const getPopulationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_POPULATION');
export type getPopulationActions = MakeCommunicationActions<
  typeof getPopulationActionTypes,
  {success: IAncestryPopulation[]}
>;

export const getReviewPopulationActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_REVIEW_POPULATION');
export type getReviewPopulationActions = MakeCommunicationActions<
  typeof getReviewPopulationActionTypes,
  {success: IAncestryPopulation[]}
>;

export const getPopulationComparisonActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_POPULATION_COMPARISON');
export type getPopulationComparisonActions = MakeCommunicationActions<
  typeof getPopulationComparisonActionTypes,
  {success: IAncestryPopulationComparison}
>;

export const getReviewPopulationComparisonActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_REVIEW_POPULATION_COMPARISON'
);
export type getReviewPopulationComparisonActions = MakeCommunicationActions<
  typeof getReviewPopulationComparisonActionTypes,
  {success: IAncestryPopulationComparison}
>;

export const getAncestryProbabilitiesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_PROBABILITIES'
);
export type getAncestryProbabilitiesActions = MakeCommunicationActions<
  typeof getAncestryProbabilitiesActionTypes,
  {success: IAncestryProbabilities}
>;

export const getReviewAncestryProbabilitiesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_PROBABILITIES'
);
export type getReviewAncestryProbabilitiesActions = MakeCommunicationActions<
  typeof getReviewAncestryProbabilitiesActionTypes,
  {success: IAncestryProbabilities}
>;

export const getAncestryRelatedHorsesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_RELATED_HORSES'
);
export type getAncestryRelatedHorsesActions = MakeCommunicationActions<
  typeof getAncestryRelatedHorsesActionTypes,
  {success: IAncestryRelatedHorse[]}
>;

export const getAncestryKinshipHorsesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_KINSHIP_HORSES'
);
export type getAncestryKinshipHorsesActions = MakeCommunicationActions<
  typeof getAncestryKinshipHorsesActionTypes,
  {success: IAncestryKinshipHorse[]}
>;

export const getAncestryPcaReferenceDataActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ANCESTRY_PCA_REFERENCE_DATA'
);
export type getAncestryPcaReferenceDataActions = MakeCommunicationActions<
  typeof getAncestryPcaReferenceDataActionTypes,
  {success: IAncestryPcaReferencePoint[]}
>;

export type AdminAncestryReportActions =
  | getPopulationActions
  | getReviewPopulationActions
  | getPopulationComparisonActions
  | getReviewPopulationComparisonActions
  | getAncestryProbabilitiesActions
  | getReviewAncestryProbabilitiesActions
  | getAncestryRelatedHorsesActions
  | getAncestryKinshipHorsesActions
  | getAncestryPcaReferenceDataActions;
