import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';

import {
  createUserNotificationActionTypes,
  deleteUserNotificationActionTypes,
  getUserNotificationHistoryActionTypes,
  getUserNotificationsActionTypes,
  IUserNotificationState,
  updateIsReadUserNotificationActionTypes,
  updateIsReadUserNotificationAllActionTypes,
  UserNotificationsActions,
} from '../types';

export const communicationUserNotificationsReducer = combineReducers<
  IUserNotificationState['communications'],
  UserNotificationsActions
>({
  userNotificationHistoryLoading: makeCommunicationReducerFromEnum(getUserNotificationHistoryActionTypes),
  userNotificationsLoading: makeCommunicationReducerFromEnum(getUserNotificationsActionTypes),
  userNotificationsCreating: makeCommunicationReducerFromEnum(createUserNotificationActionTypes),
  userNotificationsDeleting: makeCommunicationReducerFromEnum(deleteUserNotificationActionTypes),
  isReadUserNotificationsUpdating: makeCommunicationReducerFromEnum(updateIsReadUserNotificationActionTypes),
  isReadUserNotificationsAllUpdating: makeCommunicationReducerFromEnum(updateIsReadUserNotificationAllActionTypes),
});
