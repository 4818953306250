import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList} from 'recharts';
import styled from 'styled-components';

type InbreedingCoefficientProps = {
  mean: number;
  thirdDeviationLow: number;
  secondDeviationLow: number;
  secondDeviationHigh: number;
  thirdDeviationHigh: number;
};

const TooltipWrapper = styled.div`
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 10px 15px !important;
  background: white;
  box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
  line-height: 16px;
  color: black;

  & p:last-child {
    margin: 0;
  }
`;

const CustomTooltip = ({active, payload}: any) => {
  if (active && payload?.length && payload[0].payload.visible) {
    return <TooltipWrapper>{payload[0].payload.tooltipContent}</TooltipWrapper>;
  }

  return null;
};

const CustomdDot = (props: any) => {
  const {cx, cy, payload, active} = props;

  if (payload.visible) {
    return (
      <>
        <span>{payload.ic}</span>
        <circle
          r="6"
          stroke="#8C0A0A"
          strokeWidth="1"
          fill={active ? '#8C0A0A' : '#FFF'}
          width="327"
          height="66"
          cx={cx}
          cy={cy}
          className="recharts-dot recharts-line-dot"
        />
      </>
    );
  }
  return null;
};

const CustomLabel = (props: any) => {
  const {x, y, stroke, value} = props;
  if (!value) {
    return null;
  }
  return (
    <text x={x} y={y} dy={-8} fill={stroke} fontSize={14} textAnchor="middle">
      {value}%
    </text>
  );
};

const TOOLTIP_CONTENT = [
  {
    axisLabel: '-3σ',
    tooltipContent: (ic: number) => (
      <>
        <p>
          <strong>-3 Standard Deviations</strong>
        </p>
        <p>
          It is <strong>extremely unlikely</strong> the foal will have a genomic inbreeding value below {ic.toFixed(2)}
          %.
        </p>
        {ic < 0 && (
          <p>
            (<strong>Note:</strong> Value may be negative as an effect of our calculations, such values should be
            treated as '0%'.)
          </p>
        )}
      </>
    ),
  },
  {
    axisLabel: '-2σ',
    tooltipContent: (ic: number) => (
      <>
        <p>
          <strong>-2 Standard Deviations</strong>
        </p>
        <p>
          It is <strong>unlikely</strong> the foal will have a genomic inbreeding value below {ic.toFixed(2)}%.
        </p>
        {ic < 0 && (
          <p>
            (<strong>Note:</strong> Value may be negative as an effect of our calculations, such values should be
            treated as '0%'.)
          </p>
        )}
      </>
    ),
  },
  {
    axisLabel: 'Mean',
    tooltipContent: (ic: number) => (
      <>
        <p>
          <strong>Mean</strong>
        </p>
        <p>Our calculations predict the most likely genomic inbreeding value for this foal to be {ic.toFixed(2)}%.</p>
        {ic < 0 && (
          <p>
            (<strong>Note:</strong> Value may be negative as an effect of our calculations, such values should be
            treated as '0%'.)
          </p>
        )}
      </>
    ),
  },
  {
    axisLabel: '+2σ',
    tooltipContent: (ic: number) => (
      <>
        <p>
          <strong>+2 Standard Deviations</strong>
        </p>
        <p>
          It is <strong>unlikely</strong> the foal will have a genomic inbreeding value above {ic.toFixed(2)}%.
        </p>
        {ic < 0 && (
          <p>
            (<strong>Note:</strong> Value may be negative as an effect of our calculations, such values should be
            treated as '0%'.)
          </p>
        )}
      </>
    ),
  },
  {
    axisLabel: '+3σ',
    tooltipContent: (ic: number) => (
      <>
        <p>
          <strong>+3 Standard Deviations</strong>
        </p>
        <p>
          It is <strong>extremely unlikely</strong> the foal will have a genomic inbreeding value above {ic.toFixed(2)}
          %.
        </p>
        {ic < 0 && (
          <p>
            (<strong>Note:</strong> Value may be negative as an effect of our calculations, such values should be
            treated as '0%'.)
          </p>
        )}
      </>
    ),
  },
];

const getTooltipContent = (axisLabel: string, ic: number) => {
  const item = TOOLTIP_CONTENT.find((item) => item.axisLabel === axisLabel);
  return item?.tooltipContent(ic);
};

function InbreedingCoefficient(props: InbreedingCoefficientProps) {
  const {mean, thirdDeviationLow, secondDeviationLow, secondDeviationHigh, thirdDeviationHigh} = props;

  const data = [
    {
      axisLabel: '-3σ',
      ic: thirdDeviationLow.toFixed(2),
      pv: 0.1,
      visible: true,
      tooltipContent: getTooltipContent('-3σ', thirdDeviationLow),
    },
    {
      axisLabel: '-2σ',
      ic: secondDeviationLow.toFixed(2),
      pv: 1,
      visible: true,
      tooltipContent: getTooltipContent('-2σ', secondDeviationLow),
    },
    {
      ic: '',
      pv: 5,
      visible: false,
    },
    {
      axisLabel: 'Mean',
      ic: mean.toFixed(2),
      visible: true,
      pv: 8,
      tooltipContent: getTooltipContent('Mean', mean),
    },
    {
      ic: '',
      pv: 5,
      visible: false,
      tooltipContent: '',
    },
    {
      axisLabel: '+2σ',
      ic: secondDeviationHigh.toFixed(2),
      pv: 1,
      visible: true,
      tooltipContent: getTooltipContent('+2σ', secondDeviationHigh),
    },
    {
      axisLabel: '+3σ',
      ic: thirdDeviationHigh.toFixed(2),
      pv: 0.1,
      visible: true,
      tooltipContent: getTooltipContent('+3σ', thirdDeviationHigh),
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid horizontal={false} strokeDasharray="3 3" />
        <XAxis domain={[0, 100]} dataKey="axisLabel" />
        <YAxis hide domain={[0, 9]} />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="natural"
          dataKey="pv"
          stroke="#8C0A0A"
          dot={<CustomdDot active={false} />}
          activeDot={<CustomdDot active={true} />}
          isAnimationActive={false}
        >
          <LabelList dataKey={'ic'} content={<CustomLabel />} />
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
}

export default InbreedingCoefficient;
