import {combineReducers} from 'redux';

import {
  activateActivationCodeActionTypes,
  ActivationCodesActions,
  createActivationCodesActionTypes,
  deactivateActivationCodeActionTypes,
  getActivationCodeByIdActionTypes,
  getActivationCodesActionTypes,
  IAdminActivationCodeState,
  resetActivationCodeDetailsActionTypes,
  updateActivationCodeActionTypes,
} from 'Admin/AdminDashboard/store/adminActivationCodes/types';
import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication/index';
import composeReducers from 'Common/store/composeReducers';

export const communicationActivationCodesReducer = combineReducers<
  IAdminActivationCodeState['communications'],
  ActivationCodesActions
>({
  activationCodesLoading: makeCommunicationReducerFromEnum(getActivationCodesActionTypes),
  activationCodesCreating: makeCommunicationReducerFromEnum(createActivationCodesActionTypes),
  activationCodeUpdating: makeCommunicationReducerFromEnum(updateActivationCodeActionTypes),
  activationCodeDeactivating: makeCommunicationReducerFromEnum(deactivateActivationCodeActionTypes),
  activationCodeActiviating: makeCommunicationReducerFromEnum(activateActivationCodeActionTypes),
  activationCodeDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getActivationCodeByIdActionTypes),
    makeResetCommunicationReducer(resetActivationCodeDetailsActionTypes.RESET),
  ]),
});
