import {IModule} from 'redux-dynamic-modules';

import {IAdminSupportState} from './types';
import {reducer} from './reducer';

export interface IAdminSupportModuleState {
  adminSupport: IAdminSupportState;
}

export const AdminSupportModule: IModule<IAdminSupportModuleState> = {
  id: 'adminSupport',
  reducerMap: {
    adminSupport: reducer,
  },
};
