export enum NotificationEventType {
  ChatRequestAccepted = 'ChatRequestAccepted',
  ChatRequestCreated = 'ChatRequestCreated',
  ChatRequestReceived = 'ChatRequestReceived',
  ChatRequestRejected = 'ChatRequestRejected',
  ContactRequested = 'ContactRequested',
  HorsePublicityRequested = 'HorsePublicityRequested',
  HorseTransferRequestCreated = 'HorseTransferRequestCreated',
  HorseTransferRequestRejected = 'HorseTransferRequestRejected',
  MessageFromUserReceived = 'MessageFromUserReceived',
  NewSampleRequested = 'NewSampleRequested',
  OrderPlaced = 'OrderPlaced',
  PaymentConfirmed = 'PaymentConfirmed',
  PaymentMethodChanged = 'PaymentMethodChanged',
  ProductChanged = 'ProductChanged',
  ReportUpdated = 'ReportUpdated',
  ResultsReady = 'ResultsReady',
  ResultsUpdated = 'ResultsUpdated',
  SampleReceived = 'SampleReceived',
  SubscriptionCanceled = 'SubscriptionCanceled',
  SubscriptionInvoicePaid = 'SubscriptionInvoicePaid',
  SubscriptionInvoicePaymentFailed = 'SubscriptionInvoicePaymentFailed',
  SubscriptionRenewed = 'SubscriptionRenewed',
  OperatingModeUpdated = 'OperatingModeUpdated',
  HorseTransferredToUser = 'HorseTransferredToUser',
  HorseTransferredFromUser = 'HorseTransferredFromUser',
}
