import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {actions, AdminSupportModule, selectors} from 'Admin/AdminSupport/store';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {TextArea} from 'Common/components/Controls';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import Nebula from 'Common/components/Layout/Nebula';
import {ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import WarningModal, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {Divider, ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ImageUploader from 'Image/components/ImageUploader';
import {IImage} from 'Image/models/IImage';
import {ImageModule} from 'Image/store/imageModule';
import {actions as imageActions, selectors as imageSelectors} from 'Image/store/index';
import Loading from 'Loading/components/Loading';
import AttachFilePanel from 'MessageCenter/components/parts/ChatSupport/AttachFilePanel';
import IncomingMessage from 'MessageCenter/components/parts/Messages/IncomingMessage';
import OutgoingMessage from 'MessageCenter/components/parts/Messages/OutgoingMessage';
import {MAX_MESSAGE_LENGTH} from 'MessageCenter/constants/MaxMessageLength';
import {IChatUser} from 'MessageCenter/models/IChatUser';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {actions as userToAdminActions, selectors as userToAdminSelectors} from 'SignalR/store/userToAdmin';
import {selectors as userSelectors} from 'UserProfile/store/currentUser';
import {convertUserToAdminChatMesssageToIMessageQueue, filterIncomingMessages} from './converters';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';

const textAreaStyle = {minHeight: 48, maxHeight: 96};
const imageUploaderStyle: React.CSSProperties = {
  background: ColorPalette.transparent0,
};
const HEIGHT_ADDITIONAL_COMPONENTS_AT_MODAL = 280;
const MODAL_MARGIN = 120;
const CONTENT_MAX_HEIGHT = `calc(100vh - ${HEIGHT_ADDITIONAL_COMPONENTS_AT_MODAL + MODAL_MARGIN}px)`;

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;
  margin-top: 16px;
`;

const Footer = styled.div`
  width: 100%;
  padding: 16px 24px 8px 8px;
  gap: 16px;
`;

const InCharge = styled.div`
  min-height: 52px;
  padding: 0 20px 0 40px;
  &:not(:first-child) {
    gap: 16px;
  }
`;

const InputMessage = styled(TextArea)`
  width: 100%;
  margin-right: 16px;
`;

const FieldLabel = styled.div`
  font-size: 18px;
  color: ${ColorPalette.gray18};
`;

const FieldName = styled.div`
  font-size: 18px;
  color: ${ColorPalette.black1};
  gap: 8px;
`;

type IConnected = ConnectedProps<typeof connector>;

interface IProps {
  user: IChatUser;
}

type AllProps = IProps & IConnected;

function AdminSupportChat(props: AllProps) {
  const {
    getUserToAdminChatDetails,
    getUserToAdminChatMessages,
    userToAdminChatDetailsLoading,
    userToAdminChatMessagesLoading,
    userToAdminChatDetails,
    userToAdminChatMessages,
    currentAdmin,
    user,
    sendAdminToUserMessage,
    deleteMessagesFromQueue,
    resetAdminToUserChatDetails,
    messageQueue,
    subscribeToMessages,
    unsubscribeFromMessages,
    takeDuty,
    leaveDuty,
    connectionId,
    uploadImage,
    imageUploading,
    sendUserToAdminMessageReadRequest,
    userMessageRead,
    resetUserToAdminMessageRead,
    userToAdminChatDutyLeaving,
    userToAdminChatDutyTaking,
  } = props;

  const [message, setMessage] = useState<string>('');
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessageQueue[]>([]);
  const [isScrollToBottom, setIsScrollToBottom] = useState<boolean>(false);
  const [fileLoadError, setFileLoadError] = useState<string | null>(null);
  const [images, setImages] = useState<IImage[]>([]);
  const [isReadMessageIds, setIsReadMessageIds] = useState<number[]>([]);
  const [isOnDuty, setIsOnDuty] = useState<boolean>(false);

  const errorInfo = userToAdminChatDetailsLoading.error || userToAdminChatMessagesLoading.error;
  const isMessagesLoading = [userToAdminChatMessagesLoading].some((x) => x.isRequesting);
  const isDetailsLoading = [userToAdminChatDetailsLoading].some((x) => x.isRequesting);

  useEffect(() => {
    subscribeToMessages({userId: user.id, connectionId});
    return () => {
      resetAdminToUserChatDetails();
      unsubscribeFromMessages({userId: user.id, connectionId});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChatDetails = useCallback(() => {
    getUserToAdminChatDetails(user.id);
  }, [getUserToAdminChatDetails, user.id]);

  useEffect(() => {
    getChatDetails();
  }, [getChatDetails]);

  useEffect(() => {
    if (userToAdminChatDetails) {
      getUserToAdminChatMessages({userId: user.id});
      setIsOnDuty(userToAdminChatDetails?.adminOnDuty?.id === currentAdmin?.id);
    }
  }, [currentAdmin?.id, getUserToAdminChatMessages, user.id, userToAdminChatDetails]);

  useEffect(() => {
    // Delete messages from queue, because its are in history
    deleteMessagesFromQueue({userId: user.id});

    const historyMessages = userToAdminChatMessages
      ? convertUserToAdminChatMesssageToIMessageQueue(userToAdminChatMessages, user.id)
      : [];

    setMessages(historyMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToAdminChatMessages]);

  useEffect(() => {
    const newMessages = [...messages, ...filterIncomingMessages(messageQueue, user.id)];
    setMessages(newMessages);
    if (newMessages.length > 0) {
      setIsScrollToBottom(true);
    }
    setIsMessageSending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQueue]);

  useEffect(() => {
    // Delete messages from queue, because chat is opened
    const foundId = messageQueue.findIndex(
      (x) => (x.fromUserId === null && x.toUserId === user.id) || (x.fromUserId === user.id && x.toUserId === null)
    );

    if (foundId !== -1) {
      deleteMessagesFromQueue({userId: user.id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQueue]);

  useEffect(() => {
    if (userMessageRead && userMessageRead?.messageIds.length > 0) {
      setIsReadMessageIds([...isReadMessageIds, ...userMessageRead?.messageIds]);
      resetUserToAdminMessageRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMessageRead, resetUserToAdminMessageRead]);

  useEffect(() => {
    if (isReadMessageIds.length > 0) {
      const newMessages = [...messages].map((x) => {
        if (x.id && isReadMessageIds.includes(x.id)) {
          return {...x, isRead: true};
        }
        return x;
      });

      setMessages(newMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadMessageIds]);

  const onSendImages = useCallback(async () => {
    const ids = [];
    try {
      for (const image of images) {
        if (image.file) {
          const formData = new FormData();
          formData.append('file', image.file);
          formData.append('ownerId', user.id.toString());
          const savedImageId = await uploadImage(formData, true);
          ids.push(savedImageId as unknown as number);
        }
      }
    } finally {
      setImages([]);
    }
    return ids;
  }, [images, uploadImage, user.id]);

  const onSendClick = useCallback(async () => {
    setIsMessageSending(true);
    if (images.length > 0) {
      const imageIds = await onSendImages();
      if (imageIds && imageIds.length > 0) {
        sendAdminToUserMessage({toUserId: user.id, message, imageIds});
      }
    } else {
      sendAdminToUserMessage({toUserId: user.id, message, imageIds: []});
    }
    setMessage('');
  }, [images.length, message, onSendImages, sendAdminToUserMessage, user.id]);

  const onInputChange = useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    setMessage(value);
  }, []);

  const onKeyPressInput = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.shiftKey === false) {
        e.preventDefault();
        onSendClick();
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = `48px`;
      } else {
        e.currentTarget.style.height = 'inherit';
        e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
      }
    },
    [onSendClick]
  );

  const onTakeOnCharge = useCallback(() => {
    unsubscribeFromMessages({userId: user.id, connectionId});
    takeDuty(user.id);
  }, [connectionId, takeDuty, unsubscribeFromMessages, user.id]);

  const onLeaveOnCharge = useCallback(() => {
    leaveDuty();
    subscribeToMessages({userId: user.id, connectionId});
  }, [connectionId, leaveDuty, subscribeToMessages, user.id]);

  useOnSuccessCommunication(userToAdminChatDutyTaking, getChatDetails);
  useOnSuccessCommunication(userToAdminChatDutyLeaving, getChatDetails);

  const closeFileLoadErrorModal = useCallback(() => setFileLoadError(null), []);

  const uploadFile = useCallback(
    async (image: IImage) => {
      if (image.file) {
        setImages([...images, image]);
      }
    },
    [images]
  );

  const onDeleteFile = useCallback(
    (index: number) => {
      const newImages = images.filter((x, ind) => ind !== index);
      setImages(newImages);
    },
    [images]
  );

  const onMessageRead = useCallback(
    (id: number) => {
      // TODO: make sending bunch ids, not only one id
      const isAlreadyRead = !!messages.find((x) => x.id === id)?.isRead;
      if (isOnDuty && !isAlreadyRead) {
        sendUserToAdminMessageReadRequest({messageIds: [id], toUserId: user.id});
      }
    },
    [isOnDuty, messages, sendUserToAdminMessageReadRequest, user.id]
  );

  return (
    <>
      <ModalWindowHeader>{`Messages: ${user.name}`}</ModalWindowHeader>

      <InCharge className="d-flex align-items-center position-relative">
        <WarningModal
          isOpen={!!fileLoadError}
          modalType={ModalTypes.Alert}
          modalTitle="Alert"
          onClose={closeFileLoadErrorModal}
          onSuccess={closeFileLoadErrorModal}
        >
          {fileLoadError}
        </WarningModal>

        {isDetailsLoading && <Loading />}

        <FieldLabel>In charge of the chat:</FieldLabel>
        {userToAdminChatDetails?.adminOnDuty ? (
          <FieldName className="d-flex align-items-center">
            <Avatar
              avatarSize={AvatarSize.Custom}
              customSizeAvatar={40}
              customSizeIcon={24}
              defaultAvatarProfile={DefaultAvatarProfile.User}
              url={userToAdminChatDetails?.adminOnDuty.avatar?.url}
            />
            {userToAdminChatDetails?.adminOnDuty.name}
          </FieldName>
        ) : (
          'None'
        )}

        <div className="ml-auto">
          {!isOnDuty ? (
            <PrimaryButton className="ml-auto" onClick={onTakeOnCharge} size="small">
              Accept
            </PrimaryButton>
          ) : (
            <PrimaryButton className="ml-auto" onClick={onLeaveOnCharge} size="small">
              Leave Chat
            </PrimaryButton>
          )}
        </div>
      </InCharge>

      <Divider />
      <ModalWindowFormContent isScrollToBottom={isScrollToBottom} maxHeight={CONTENT_MAX_HEIGHT}>
        <Root className="d-flex flex-column position-relative">
          {isMessagesLoading && <Loading />}

          {messages.map((message, i) => {
            if (message.fromUserId === user.id && !message.toUserId) {
              return (
                <IncomingMessage
                  key={i}
                  id={message.id}
                  message={message.message}
                  createDate={message.createDate ? convertUTCToClientDate(message.createDate) || '' : ''}
                  url={user.avatar?.url}
                  images={message.images}
                  isRead={message.isRead}
                  onMessageRead={onMessageRead}
                />
              );
            }
            const isRead =
              message.id !== undefined
                ? isReadMessageIds.includes(message.id)
                  ? true
                  : message.isRead
                : !!message.isRead;
            return (
              <OutgoingMessage
                key={i}
                message={message.message}
                createDate={message.createDate ? convertUTCToClientDate(message.createDate) || '' : ''}
                url={message.user?.avatar?.url}
                images={message.images}
                isRead={isRead}
                userName={message.user?.name}
              />
            );
          })}
        </Root>
      </ModalWindowFormContent>

      <Divider />

      <Footer className="ml-auto d-flex">
        <ErrorMessage>{getCommonErrors(errorInfo)}</ErrorMessage>
        <Nebula active={!isOnDuty}>
          <ImageUploader
            width="36px"
            height="36px"
            value={null}
            onChange={uploadFile}
            title="Add image"
            showDeleteButton={false}
            inputStyle={imageUploaderStyle}
            renderEmptyState={() => (
              <ColoredIcon
                name={IconName.AddCircleFilled}
                size={36}
                stroke={false}
                fill={true}
                color={Theme.color.control.bg}
                hoverColor={Theme.color.control.hover}
              />
            )}
          />
        </Nebula>
        <div className="w-100">
          <InputMessage
            maxLength={MAX_MESSAGE_LENGTH}
            value={message}
            onChange={onInputChange}
            onKeyPress={onKeyPressInput}
            disabled={!isOnDuty}
            inputStyle={textAreaStyle}
          />
          {images.length > 0 && <AttachFilePanel images={images} onDelete={onDeleteFile} />}
        </div>
        <PrimaryButton
          onClick={onSendClick}
          disabled={!isOnDuty}
          isLoading={imageUploading.isRequesting || isMessageSending}
        >
          Send
        </PrimaryButton>
      </Footer>
    </>
  );
}

const mapStateToProps = (state: IAppState) => ({
  connectionId: userToAdminSelectors.selectConnectionId(state),
  userToAdminChatDetails: selectors.selectUserToAdminChatDetails(state),
  userToAdminChatMessages: selectors.selectUserToAdminChatMessages(state),
  userToAdminChatDetailsLoading: selectors.selectCommunication(state, 'userToAdminChatDetailsLoading'),
  userToAdminChatMessagesLoading: selectors.selectCommunication(state, 'userToAdminChatMessagesLoading'),
  currentAdmin: userSelectors.selectCurrentAdmin(state),
  messageQueue: userToAdminSelectors.selectMessageQueue(state),
  imageUploading: imageSelectors.selectCommunication(state, 'imageUploading'),
  userMessageRead: userToAdminSelectors.selectUserMessageRead(state),
  userToAdminChatDutyTaking: selectors.selectCommunication(state, 'userToAdminChatDutyTaking'),
  userToAdminChatDutyLeaving: selectors.selectCommunication(state, 'userToAdminChatDutyLeaving'),
});

const mapDispatchToProps = {
  getUserToAdminChatDetails: actions.getUserToAdminChatDetails,
  getUserToAdminChatMessages: actions.getUserToAdminChatMessages,
  sendAdminToUserMessage: userToAdminActions.sendAdminToUserMessage,
  deleteMessagesFromQueue: userToAdminActions.deleteMessagesFromQueue,
  resetAdminToUserChatDetails: actions.resetAdminToUserChatDetails,
  subscribeToMessages: actions.subscribeToMessages,
  unsubscribeFromMessages: actions.unsubscribeFromMessages,
  takeDuty: actions.takeDuty,
  leaveDuty: actions.leaveDuty,
  uploadImage: imageActions.uploadImage,
  sendUserToAdminMessageReadRequest: userToAdminActions.sendUserToAdminMessageReadRequest,
  resetUserToAdminMessageRead: userToAdminActions.resetUserToAdminMessageRead,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(AdminSupportChat));

export default withDynamicModules(memo(Connected), [AdminSupportModule, ImageModule]);
