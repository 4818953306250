import {action} from 'typesafe-actions';

import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  getAncestryKinshipHorsesActions,
  getAncestryKinshipHorsesActionTypes,
  getAncestryPcaReferenceDataActions,
  getAncestryPcaReferenceDataActionTypes,
  getAncestryProbabilitiesActions,
  getAncestryProbabilitiesActionTypes,
  getAncestryRelatedHorsesActions,
  getAncestryRelatedHorsesActionTypes,
  getPopulationActions,
  getPopulationActionTypes,
  getPopulationComparisonActions,
  getPopulationComparisonActionTypes,
  getReviewAncestryProbabilitiesActions,
  getReviewAncestryProbabilitiesActionTypes,
  getReviewPopulationActions,
  getReviewPopulationActionTypes,
  getReviewPopulationComparisonActions,
  getReviewPopulationComparisonActionTypes,
} from './types';
import AdminAncestryReportService from 'Admin/AdminDashboard/services/AdminAncestryReportService';

export const getPopulation =
  (horseId: number): ActionResult<IAppState, void, getPopulationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPopulationActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getPopulation(horseId);
      dispatch(action(getPopulationActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPopulationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getReviewPopulation =
  (horseId: number): ActionResult<IAppState, void, getReviewPopulationActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getReviewPopulationActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getReviewPopulation(horseId);
      dispatch(action(getReviewPopulationActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getReviewPopulationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getPopulationComparison =
  (horseId: number): ActionResult<IAppState, void, getPopulationComparisonActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getPopulationComparisonActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getPopulationComparison(horseId);
      dispatch(action(getPopulationComparisonActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getPopulationComparisonActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getReviewPopulationComparison =
  (horseId: number): ActionResult<IAppState, void, getReviewPopulationComparisonActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getReviewPopulationComparisonActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getReviewPopulationComparison(horseId);
      dispatch(action(getReviewPopulationComparisonActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getReviewPopulationComparisonActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryProbabilities =
  (horseId: number): ActionResult<IAppState, void, getAncestryProbabilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAncestryProbabilitiesActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getAncestryProbabilities(horseId);
      dispatch(action(getAncestryProbabilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryProbabilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getReviewAncestryProbabilities =
  (horseId: number): ActionResult<IAppState, void, getReviewAncestryProbabilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getReviewAncestryProbabilitiesActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getReviewAncestryProbabilities(horseId);
      dispatch(action(getReviewAncestryProbabilitiesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getReviewAncestryProbabilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryRelatedHorses =
  (horseId: number): ActionResult<IAppState, void, getAncestryRelatedHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAncestryRelatedHorsesActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getAncestryRelatedHorses(horseId);
      dispatch(action(getAncestryRelatedHorsesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryRelatedHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryKinshipHorses =
  (horseId: number): ActionResult<IAppState, void, getAncestryKinshipHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAncestryKinshipHorsesActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getAncestryKinshipHorses(horseId);
      dispatch(action(getAncestryKinshipHorsesActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryKinshipHorsesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAncestryPcaReferenceData =
  (): ActionResult<IAppState, void, getAncestryPcaReferenceDataActions> => async (dispatch) => {
    try {
      dispatch(action(getAncestryPcaReferenceDataActionTypes.REQUEST));
      const res = await AdminAncestryReportService.getAncestryPcaReferenceData();
      dispatch(action(getAncestryPcaReferenceDataActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getAncestryPcaReferenceDataActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
