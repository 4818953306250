import {useLocation, useNavigate} from 'react-router-dom';
import {useMemo, useCallback} from 'react';

import {parseUrlParams, stringifyUrlParams} from '../url';

export function useQueryParams<T extends object>(paramsKeys?: Array<keyof T>, ignoreDecodeKeys?: Array<keyof T>) {
  const navigate = useNavigate();
  const location = useLocation();

  const setParams = useCallback(
    (params: object) => {
      navigate({search: stringifyUrlParams(params, {clearEmpty: true})}, {replace: true});
    },
    [navigate]
  );

  const params = useMemo(
    () => {
      let parsed = parseUrlParams<T>(location.search);

      if (ignoreDecodeKeys) {
        const notDecoded = parseUrlParams<T>(location.search, false);
        const notDecodedParams = ignoreDecodeKeys.reduce((acc, currKey) => {
          if (notDecoded[currKey] !== undefined) {
            acc[currKey] = notDecoded[currKey];
          }
          return acc;
        }, {} as T);

        parsed = {...parsed, ...notDecodedParams};
      }

      if (!paramsKeys) {
        return parsed;
      }

      return paramsKeys.reduce((acc, currKey) => {
        if (parsed[currKey] !== undefined) {
          acc[currKey] = parsed[currKey];
        }
        return acc;
      }, {} as T);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [location.search, paramsKeys]
  );

  return [params as T, setParams] as const;
}
