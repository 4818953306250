import {Reducer} from 'redux';

import {
  createUserNotificationActionTypes,
  deleteUserNotificationActionTypes,
  getUserNotificationHistoryActionTypes,
  getUserNotificationsActionTypes,
  IUserNotificationState,
  UserNotificationsActions,
} from '../types';

const initialState: IUserNotificationState['data'] = {
  userNotificationHistory: [],
  userNotifications: [],
};

export const dataUserNotificationsReducer: Reducer<IUserNotificationState['data'], UserNotificationsActions> = (
  state = initialState,
  action: UserNotificationsActions
) => {
  switch (action.type) {
    case getUserNotificationHistoryActionTypes.REQUEST: {
      return {...state, userNotificationHistory: initialState.userNotificationHistory};
    }
    case getUserNotificationHistoryActionTypes.SUCCESS: {
      return {...state, userNotificationHistory: action.payload};
    }
    case getUserNotificationsActionTypes.REQUEST: {
      return {...state, userNotifications: initialState.userNotifications};
    }
    case getUserNotificationsActionTypes.SUCCESS: {
      return {...state, userNotifications: action.payload};
    }
    case createUserNotificationActionTypes.SUCCESS: {
      const newIUserNotification = action.payload;
      const newUserNotifications = [...state.userNotifications].filter(
        (x) => !(x.eventId === newIUserNotification.eventId && x.type === newIUserNotification.type)
      );
      newUserNotifications.push(newIUserNotification);

      return {...state, userNotifications: newUserNotifications};
    }
    case deleteUserNotificationActionTypes.SUCCESS: {
      const newUserNotifications = [...state.userNotifications].filter((x) => x.id !== action.payload);
      return {...state, userNotifications: newUserNotifications};
    }

    default: {
      return state;
    }
  }
};
