import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  Actions,
  getUserToAdminChatDetailsActionTypes,
  getUserToAdminChatMessagesActionTypes,
  getUserToAdminChatsActionTypes,
  getUserToAdminChatsFiltersActionTypes,
  IAdminSupportState,
  leaveUserToAdminOnDutyActionTypes,
  subscribeUserToAdminMessageActionTypes,
  takeUserToAdminOnDutyActionTypes,
  unsubscribeUserToAdminMessageActionTypes,
} from '../types';

export const communicationAdminSupportReducer = combineReducers<IAdminSupportState['communications'], Actions>({
  userToAdminChatsLoading: makeCommunicationReducerFromEnum(getUserToAdminChatsActionTypes),
  userToAdminChatsFiltersLoading: makeCommunicationReducerFromEnum(getUserToAdminChatsFiltersActionTypes),
  userToAdminChatMessagesLoading: makeCommunicationReducerFromEnum(getUserToAdminChatMessagesActionTypes),
  userToAdminChatDetailsLoading: makeCommunicationReducerFromEnum(getUserToAdminChatDetailsActionTypes),
  userToAdminChatDutyLeaving: makeCommunicationReducerFromEnum(subscribeUserToAdminMessageActionTypes),
  userToAdminChatDutyTaking: makeCommunicationReducerFromEnum(unsubscribeUserToAdminMessageActionTypes),
  userToAdminChatMessagesSubscribing: makeCommunicationReducerFromEnum(takeUserToAdminOnDutyActionTypes),
  userToAdminChatMessagesUnsubscribing: makeCommunicationReducerFromEnum(leaveUserToAdminOnDutyActionTypes),
});
