import {ICommunication, MakeCommunicationActions} from 'Common/store/utils/communication/types';

import {IShortTandemRepeatReport} from 'OnlineReport/models/STR/IShortTandemRepeatReport';
import {makeCommunicationActionType} from 'Common/store/utils/communication/index';

const STATE_NAME = 'adminShortTandemRepeatReport';

export interface IAdminShortTandemRepeatReportState {
  data: {
    shortTandemRepeatReport: IShortTandemRepeatReport;
  };
  communications: {
    shortTandemRepeatReportLoading: ICommunication;
    shortTandemRepeatReportReviewLoading: ICommunication;
  };
}

export const getShortTandemRepeateportActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_SHORT_TANDEM_REPEAT_REPORT'
);
export type getShortTandemRepeatReportActions = MakeCommunicationActions<
  typeof getShortTandemRepeateportActionTypes,
  {success: IShortTandemRepeatReport}
>;

export const getShortTandemRepeateportReviewActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_SHORT_TANDEM_REPEAT_REPORT_REVIEW'
);

export type getShortTandemRepeatReportReviewActions = MakeCommunicationActions<
  typeof getShortTandemRepeateportReviewActionTypes,
  {success: IShortTandemRepeatReport}
>;

export type AdminShortTandemRepeatReportActions =
  | getShortTandemRepeatReportActions
  | getShortTandemRepeatReportReviewActions;
