import * as Yup from 'yup';
import {Gender} from 'Common/constants/Gender';
import {ProfileType} from 'Common/constants/ProfileType';
import {HeightLimits, HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {Parentage} from 'HorseProfile/constants/Parentage';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {IHeight} from 'Common/models/IHeight';
import {isValidHeight} from 'Common/helpers/HeightHelper';
import {IImage} from 'Image/models/IImage';
import {IAddressWithNullableField} from 'Common/models/IAddress';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import {IRegistryRequest} from 'Common/models/IRegistryRequest';
import {IUpdateHorseParentageBP, IUpdateHorseParentBP} from 'BusinessPortal/models/horse/IHorseParentageBP';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IUpdateHorseDetailsBP} from 'BusinessPortal/models/horse/IHorseDetailsBP';
import {IWithHorseMapFieldProps} from 'Common/components/FormFields/HorseMapField/HorseMapField';
import {IUpdateHorseMediaBP} from 'BusinessPortal/models/horse/IHorseMediaBP';
import {IUpdateHorseInconclusiveHealthIssuesBP} from 'BusinessPortal/models/horse/IInconclusiveHealthIssuesBP';
import {IUserOption} from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearch';

export interface ISetOwnerFormValues extends Pick<IUserOption, 'firstName' | 'lastName' | 'avatar'> {
  id?: number;
}

export interface IUpdateHorseBPFormValues
  extends Omit<IUpdateHorseDetailsBP, 'simpleOrganization'>,
    Omit<IUpdateHorseInconclusiveHealthIssuesBP, 'horseId'>,
    Omit<IUpdateHorseParentageBP, 'horseId'>,
    Omit<IUpdateHorseMediaBP, 'horseId'>,
    IWithHorseMapFieldProps {
  registries: IRegistryRequest[];
  additionalInformation: string;
  participateInEtalonProjects: boolean;
  profileType: ProfileType;
  owner: ISetOwnerFormValues;
  organizationId: number;
  ownerId: number;
}

export interface IFormValues extends Omit<IUpdateHorseBPFormValues, 'profileType' | 'ancestryProfileType'> {
  isPublicProfile: boolean;
  isAncestryPublicProfile: boolean;
}
export const initialValue: IFormValues = {
  id: 0,
  organizationId: 0,
  ownerId: 0,
  name: '',
  gender: Gender.Stallion,
  height: {
    height: 0,
    heightUnit: HeightUnits.Hands,
  },
  dateOfBirth: '',
  location: null,
  address: {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
  },
  disciplines: [],
  breeds: [],
  colors: [],
  markings: [],
  registries: [{associationId: 0, registrationNumber: null}],
  parents: [
    {name: '', parent: Parentage.Sire, registries: []},
    {name: '', parent: Parentage.Dam, registries: []},
  ],
  inconclusiveHealthIssues: [],
  additionalInformation: '',
  participateInEtalonProjects: false,
  isPublicProfile: false,
  microchipNumber: '',
  passportNumber: '',
  registryNumber: '',
  horseAddress: '',
  isManualAddressMode: false,
  avatar: null,
  gallery: [],
  owner: {
    firstName: '',
    lastName: '',
    avatar: null,
  },
  isAncestryPublicProfile: true,
};

const registryShape = Yup.array().of(
  Yup.object().shape<IRegistryRequest>({
    registrationNumber: Yup.string().nullable(),
    associationId: Yup.number().nullable(),
  })
);

const parentageShape = Yup.array().of(
  Yup.object().shape<IUpdateHorseParentBP>({
    registries: registryShape,
    name: Yup.string(),
    parent: Yup.mixed<Parentage>(),
  })
);

export const validationSchema = Yup.object().shape<IFormValues>({
  id: Yup.number().required(REQUIRED_FIELD),
  organizationId: Yup.number().required(REQUIRED_FIELD),
  ownerId: Yup.number().required(REQUIRED_FIELD),
  owner: Yup.object().shape<ISetOwnerFormValues>({
    id: Yup.number().required(REQUIRED_FIELD),
    firstName: Yup.string(),
    lastName: Yup.string(),
    avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  }),
  name: Yup.string().required(REQUIRED_FIELD),
  dateOfBirth: Yup.string()
    .nullable()
    .test('dateOfBirth', `Please enter a valid date: ${FRONTEND_DATE}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    }),
  breeds: Yup.array().of(Yup.number()).required(REQUIRED_FIELD),
  colors: Yup.array().of(Yup.number()),
  disciplines: Yup.array().of(Yup.number()),
  markings: Yup.array().of(Yup.number()),
  gender: Yup.mixed<Gender>().required(REQUIRED_FIELD),
  height: Yup.object().shape<IHeight>({
    height: Yup.number().test('height checking', '', function (height: number) {
      if (height) {
        const currentUnitLimits = HeightLimits[this.parent.heightUnit];
        const baseErrorMessage = `'Height' must be between ${currentUnitLimits.min} and ${currentUnitLimits.max}. `;
        const additionalErrorMessage = `You entered ${height}. `;
        const additionalHandsErrorMessage = `Also the fractional part must be 1, 2 or 3. ${additionalErrorMessage}. `;

        const isValidHeightUnit = isValidHeight(height, this.parent.heightUnit);
        if (!isValidHeightUnit) {
          if (this.parent.heightUnit === HeightUnits.Hands) {
            return this.createError({message: baseErrorMessage + additionalHandsErrorMessage});
          }

          return this.createError({message: baseErrorMessage + additionalErrorMessage});
        }
      }

      return true;
    }),
    heightUnit: Yup.mixed<HeightUnits>(),
  }),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  microchipNumber: Yup.string().nullable(),
  passportNumber: Yup.string().nullable(),
  registryNumber: Yup.string().nullable(),
  address: Yup.object()
    .shape<IAddressWithNullableField>({
      country: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      street: Yup.string(),
      zip: Yup.string(),
    })
    .nullable(),
  location: Yup.object()
    .shape<ICoordinatedLocation>({
      id: Yup.number(),
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  horseAddress: Yup.string().test('horseAddress', 'Unknown address', function () {
    return this.parent.address;
  }),
  isManualAddressMode: Yup.boolean(),
  registries: registryShape,
  parents: parentageShape,
  additionalInformation: Yup.string(),
  inconclusiveHealthIssues: Yup.array().of(Yup.number()),
  participateInEtalonProjects: Yup.boolean(),
  isPublicProfile: Yup.boolean(),
  gallery: Yup.array().of(Yup.mixed<IMediaResource>()),
  isAncestryPublicProfile: Yup.boolean(),
});
