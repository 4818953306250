import {ICommunication, MakeCommunicationActions} from 'Common/store/utils/communication/types';

import {IShortTandemRepeatReport} from 'OnlineReport/models/STR/IShortTandemRepeatReport';
import {makeCommunicationActionType} from 'Common/store/utils/communication/index';

const STATE_NAME = 'shortTandemRepeatReport';

export interface IShortTandemRepeatReportState {
  data: {
    shortTandemRepeatReport: IShortTandemRepeatReport;
  };
  communications: {
    shortTandemRepeatReportLoading: ICommunication;
  };
}

export const getShortTandemRepeateportActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_SHORT_TANDEM_REPEAT_REPORT'
);
export type getShortTandemRepeatReportActions = MakeCommunicationActions<
  typeof getShortTandemRepeateportActionTypes,
  {success: IShortTandemRepeatReport}
>;

export type ShortTandemRepeatReportActions = getShortTandemRepeatReportActions;
