import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminCreateUserOrder;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUserOrderHorses(state: IAppState) {
  return selectState(state).data.userHorses;
}

export function selectUserOrderDogs(state: IAppState) {
  return selectState(state).data.userDogs;
}

export function selectUserOrderPackages(state: IAppState) {
  return selectState(state).data.userPackages;
}

export function selectUserSummary(state: IAppState) {
  return selectState(state).data.userSummary;
}

export function selectUserCoupon(state: IAppState) {
  return selectState(state).data.userCoupon;
}

export function selectOrderPackages(state: IAppState) {
  return selectState(state).data.orderPackages;
}
