import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminSupport;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectUserToAdminChats(state: IAppState) {
  return selectState(state).data.userToAdminChats;
}

export function selectUserToAdminChatsFilters(state: IAppState) {
  return selectState(state).data.userToAdminChatsFilters;
}

export function selectUserToAdminChatMessages(state: IAppState) {
  return selectState(state).data.userToAdminChatMessages;
}

export function selectUserToAdminChatDetails(state: IAppState) {
  return selectState(state).data.userToAdminChatDetails;
}
