import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {sortByStringKey} from 'Common/helpers/sortByStringKey';

import {ITabPagesData} from './ITab';

interface IProps {
  isSort?: boolean;
}

type Props<T> = ITabPagesData<T> & T & IProps;

function TabsContent<T>(props: Props<T>) {
  const {tabs, basePath, isSort, ...rest} = props;

  const sortedTabs = isSort ? tabs.sort((a, b) => sortByStringKey(a, b, 'title')) : tabs;
  const redirectSection = sortedTabs.find((item) => item.defaultSection)?.section ?? sortedTabs[0].section;

  return (
    <Routes>
      {sortedTabs.map(({section, Component}, i) => {
        const wrapped = (props: any) => <Component {...props} />;
        return <Route key={i} path={`${section}`} element={wrapped(rest)}></Route>;
      })}
      <Route path="*" element={<Navigate to={`${basePath}/${redirectSection}`} />} />
    </Routes>
  );
}

export default TabsContent;
