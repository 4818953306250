import React from 'react';
import {Navigate} from 'react-router-dom';

import {Permission} from 'Permissions/constants/Permission';
import usePermissions from 'Permissions/hooks/usePermissions';
import {isAdminVisitor} from 'Common/helpers/isAdminVisitor';

interface IOwnProps {
  permissions?: Permission | Permission[] | string | string[];
  RedirectComponent?: React.ComponentType;
  Component?: React.ComponentType | null;
  onlyForDevelopmentAdmin?: boolean;
}

type IExternalProps = IOwnProps;

function AppRoute(props: IExternalProps) {
  const {Component, RedirectComponent, onlyForDevelopmentAdmin, permissions} = props;
  const {hasPermission, isDevelopmentAdmin} = usePermissions();

  return (!permissions || hasPermission(permissions)) &&
    ((onlyForDevelopmentAdmin && isDevelopmentAdmin) || !onlyForDevelopmentAdmin) ? (
    Component ? (
      <Component />
    ) : null
  ) : RedirectComponent ? (
    <RedirectComponent />
  ) : !isAdminVisitor() ? (
    <Navigate to={'/'} />
  ) : null;
}

export default AppRoute;
export type IAppRouteProps = IExternalProps;
