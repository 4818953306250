import React, {memo, useCallback} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {ExpansionPanel, Section, ShadowType} from 'Common/components/ExpansionPanel/ExpansionPanel';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import {FRONTEND_DATE_TIME2} from 'Common/constants/Date';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import withDate from 'Common/helpers/withDate';
import {Nullable} from 'Common/types';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {parseEventNotificationDetails} from 'Notifications/helpers/parseEventNotificationDetails';
import {IUserNotificationHistory} from 'Notifications/models/IUserNotificationHistory';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const TextBase = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 24px;
`;

const Label = styled(TextBase)`
  color: ${ColorPalette.gray3};
`;

const Value = styled(TextBase)`
  color: ${ColorPalette.black1};
`;

const Detailsinfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media ${breakpoints.sm} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ElementHeader = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 8px;

  margin-left: 0px;

  @media ${breakpoints.sm} {
    grid-template-columns: 2fr 4fr;
    grid-column-gap: 16px;
  }

  @media ${breakpoints.md} {
    margin-left: 24px;
  }
`;

const ElementHeaderName = styled.div`
  width: 100%;
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.normal400};
  font-size: 18px;
  line-height: 24px;
  color: ${Theme.color.black};

  text-align: left;
  margin-bottom: 0;
`;

const GotoLink = styled(Link)`
  width: fit-content;
  display: block;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
  cursor: pointer;
  color: ${Theme.color.primary} !important;
  margin-top: 8px;
`;

const MarkButton = styled(PrimaryButton)`
  min-width: 100px;

  @media ${breakpoints.sm} {
    min-width: 168px;
  }
`;

interface IProps {
  notificationHistory: IUserNotificationHistory;
  currentUserId: Nullable<number>;
  onMarkAsRead(id: number): void;
}

function NotificationHistoryDetail(props: IProps) {
  const {notificationHistory, onMarkAsRead, currentUserId} = props;
  const {id, createDate, details, eventName, eventCode} = notificationHistory;

  const {isMobile} = useMediaQuery();

  const parsedDetails = parseEventNotificationDetails(details, eventCode, currentUserId);

  const detailsContent = parsedDetails.details;
  const gotoUrl = parsedDetails.url;

  const fakeEvent = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onMarkAsReadClick = useCallback(() => {
    onMarkAsRead(id);
  }, [id, onMarkAsRead]);

  const renderHeader = useCallback(
    (createDate: string, eventName: string) => {
      return (
        <HeaderWrapper>
          <ElementHeader>
            <ElementHeaderName>
              {withDate(convertUTCToClientDate(createDate) || '', FRONTEND_DATE_TIME2)}
            </ElementHeaderName>
            <ElementHeaderName>{eventName}</ElementHeaderName>
          </ElementHeader>

          <div onClick={fakeEvent}>
            <MarkButton onClick={onMarkAsReadClick} size={isMobile ? 'medium' : 'small'}>
              Mark as read
            </MarkButton>
          </div>
        </HeaderWrapper>
      );
    },
    [fakeEvent, isMobile, onMarkAsReadClick]
  );

  return (
    <ExpansionPanel
      headerContent={renderHeader(createDate, eventName)}
      arrowSide={isMobile ? ElementPosition.Bottom : ElementPosition.Left}
      hasBodySeparator={false}
      shadowType={ShadowType.Always}
      isShadowOnMouseHover={true}
      style={{marginBottom: 4, width: '100%'}}
      bodyStyle={!isMobile ? {padding: '0 4px 16px 52px'} : {}}
    >
      <Section>
        <Detailsinfo>
          {Object.keys(detailsContent).map((x, i) => (
            <React.Fragment key={i}>
              <Label>{x}</Label>
              <Value>{detailsContent[x]}</Value>
            </React.Fragment>
          ))}
        </Detailsinfo>
        {gotoUrl && (
          <GotoLink to={gotoUrl}>
            Click here to go to the page <ColoredIcon name={IconName.ArrowRight} color={Theme.color.primary} title="" />
          </GotoLink>
        )}
      </Section>
    </ExpansionPanel>
  );
}

export default memo(NotificationHistoryDetail);
