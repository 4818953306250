import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IImage} from 'Image/models/IImage';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';

const createUrl = (prefix: string, isAdmin?: boolean, ownerId?: number) =>
  `/Images${prefix}${isAdmin ? 'Admin' : ''}/` + (ownerId ? `?ownerId=${ownerId}` : '');

const getImage = async (id: number, isAdmin?: boolean): Promise<IImage> => {
  const res = await axiosWrapper.get<IObjectResponse<IImage>>(`${createUrl('', isAdmin)}${id}`);

  return withImageBaseUrl(res.data.data);
};

const uploadImage = async (uploadedFile: FormData, isAdmin?: boolean): Promise<number> => {
  const res = await axiosWrapper.post<IObjectResponse<number | number[]>>(createUrl('Update', isAdmin), uploadedFile);
  return Array.isArray(res.data.data) ? res.data.data[0] : res.data.data;
};

const deleteImage = async (id: number, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<IImage>>(`${createUrl('Delete', isAdmin)}${id}`);
};

export default {
  getImage,
  uploadImage,
  deleteImage,
};
