import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

interface IProps {
  token?: string;
}

export function useRedirects(props: IProps) {
  const {token} = props;

  const navigate = useNavigate();

  const redirectToResultPage = useCallback(() => {
    navigate(`/business-portal/order-placed/${token}`);
  }, [navigate, token]);

  const redirectToSuccessPayment = useCallback(() => {
    navigate(`/business-portal/order-placed/${token}/payment`);
  }, [navigate, token]);

  return {
    redirectToResultPage,
    redirectToSuccessPayment,
  };
}
