import React, {memo, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import {IReportType} from 'Dictionaries/models/IReportType';
import {Details} from 'Admin/AdminDashboard/components/shared/index';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {downloadByUrl} from 'Common/helpers/downloadHelper';
import {Nullable} from 'Common/types';
import Typography from 'Common/constants/Typography';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {getFileName} from 'Common/helpers/getFileName';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import Loading from 'Loading/components/Loading';
import Theme from 'Common/constants/Theme';
import withDate from 'Common/helpers/withDate';
import {useDeleteModal} from 'Common/helpers/hooks/useDeleteModal';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IReportDetails} from 'Admin/shared/models/IReportDetails';
import {horseReportDistributor, IDispatch, IState} from './distributor';
import withDistributorStrategy from 'Common/helpers/strategy/withDistributorStrategy';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {mapReportStatusToLabel, OrderReportStatus} from 'Common/constants/OrderReportStatus';
import {OrderReportType} from 'Common/constants/OrderReportType';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';

const Button = styled(PrimaryButton)`
  margin: 24px 24px 0 20px;
`;

const CroppedReportName = styled.div`
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
`;

const CellSeveral = styled.div`
  font-family: ${Typography.family.openSans};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size18};
  line-height: 25px;
  color: ${ColorPalette.gray44};
`;

const ReportDate = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  color: ${ColorPalette.gray48};
`;

const IconedButton = styled(IconButton)`
  margin: 0 4px;
`;

const OpenReportButton = styled(LinkButton)`
  width: 168px;
`;

interface IExternalDictionaries {
  reportTypesDictionary: ISimpleDictionary<IReportType>;
}

interface IExternalProps extends IExternalDictionaries {
  horseReports: Nullable<IReportDetails[]>;
  horseId: number;
  enableUploadReport: boolean;

  uploadReportHandler?(): void;
  onSuccess(): void;
}

type IConnected = IState & IDispatch;

type AllProps = IConnected & IExternalProps;

const HorseReportList = (props: AllProps) => {
  const {
    reportTypesDictionary: reportTypeDictionary,
    reportTypes,
    reportTypesLoading,
    onSuccess,
    deleteReport,
    reportDeleting,
    horseReports,
    enableUploadReport,
    uploadReportHandler,
    horseId,
  } = props;

  const {
    actions: {getItems: getReportTypes},
  } = reportTypeDictionary;

  useEffect(() => {
    getReportTypes();
  }, [getReportTypes]);

  const isLoading = reportTypesLoading.isRequesting || reportDeleting.isRequesting;

  const {openDeleteModal, setSelectedId, deleteModal} = useDeleteModal({
    onSuccess,
    confirmDescription: 'Are you sure you want to remove the horse report?',
    deleteCommunication: reportDeleting,
    deleteAction: deleteReport,
  });

  const renderReportUrl = useCallback(
    (report: IReportType) => {
      const horseReport = horseReports?.find((horseReport) => horseReport.reportType === report.name);

      if (!horseReport) {
        return <CellSeveral>No report</CellSeveral>;
      }

      const openReportLink =
        horseReport.reportType === OrderReportType.Ancestry
          ? `/admin/online-report/${horseId}/0/ancestry`
          : `/admin/online-report/${horseId}/0/summary`;

      if (horseReport.status && horseReport.status !== OrderReportStatus.Complete) {
        return <CellSeveral>{mapReportStatusToLabel[horseReport.status]}</CellSeveral>;
      }

      if (
        horseReport.status &&
        (horseReport.reportType === OrderReportType.Diagnostic || horseReport.reportType === OrderReportType.Ancestry)
      ) {
        return (
          <OpenReportButton to={openReportLink} variant="outlined">
            Open Report
          </OpenReportButton>
        );
      }

      const fileExtension = '.pdf';
      const reportName = horseReport.name
        ? getFileName(horseReport.name, fileExtension)
        : horseReport.url && getFileName(horseReport.url, fileExtension);

      const downloadReport = async () => {
        await downloadByUrl(horseReport.url, reportName, horseReport.name ? undefined : fileExtension);
      };

      const deleteReport = () => {
        setSelectedId(horseReport.id);
        openDeleteModal(horseReport.id);
      };

      return (
        <div className="d-flex flex-row w-100 align-items-baseline">
          <Tooltip content={reportName}>
            <CroppedReportName>
              {reportName}
              <ReportDate>{withDate(horseReport?.uploadDate)}</ReportDate>
            </CroppedReportName>
          </Tooltip>
          <IconedButton
            name={IconName.Upload}
            color={ColorPalette.blue2}
            size={16}
            fill={true}
            stroke={false}
            onClick={downloadReport}
          />
          <IconedButton
            name={IconName.Delete}
            color={ColorPalette.gray44}
            size={16}
            fill={true}
            stroke={false}
            onClick={deleteReport}
          />
        </div>
      );
    },
    [horseReports, horseId, setSelectedId, openDeleteModal]
  );

  const reportDetails = reportTypes.map((report) => ({
    title: report.name,
    value: renderReportUrl(report),
  }));

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {deleteModal}
      <Details records={reportDetails} />
      <Button size="medium" onClick={uploadReportHandler} disabled={!enableUploadReport}>
        Upload report
      </Button>
    </>
  );
};

const Distributed = withDistributorStrategy<IState, IDispatch, Pick<IExternalProps, 'reportTypesDictionary'>>(
  memo(HorseReportList),
  horseReportDistributor,
  SelectedStrategy.Admin
);

const Exported = (externalProps: Omit<IExternalProps, 'reportTypesDictionary'>) => {
  const {reportTypes} = useDictionaries();

  return <Distributed {...externalProps} reportTypesDictionary={reportTypes} />;
};

export default Exported;
