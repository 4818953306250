import {IOrderBP} from 'BusinessPortal/models/order/IOrderBP';
import {IServerOrderBP, IServerOrderDetailsBP, IServerOrderStatuses} from 'BusinessPortal/services/types/order';
import {parseOrderStatus} from 'BusinessPortal/helpers/parseOrderStatus';
import {IOrderDetailsBP} from 'BusinessPortal/models/order/IOrderDetailsBP';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {withBirthDate} from 'Common/helpers/withBirthDate';
import {withPrice} from 'Common/helpers/withPrice';
import {IOrderStatus} from 'BusinessPortal/models/order/IOrderStatus';
import {IOrderPackagesDetails} from 'BusinessPortal/models/order/IOrderPackagesDetails';
import {convertPriceToClient} from 'Common/helpers/convertPrices';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';
import {withBucketBaseUrl} from 'Common/helpers/withBucketBaseUrl';
import {IOrderReport} from 'BusinessPortal/models/order/IOrderReport';

export const convertOrderToClient = (order: IServerOrderBP): IOrderBP => {
  return {
    ...withPrice(order),
    ...parseOrderStatus(order.statuses),
    horse: {...withAvatar(order.horse)},
    owner: {...withUserName(withAvatar(order.owner))},
  };
};
export const convertOrderDetailsToClient = (order: IServerOrderDetailsBP): IOrderDetailsBP => {
  return {
    ...order,
    horse: withBirthDate(order.horse),
    sample: {...order.sample, receivedDate: convertUTCToClientDate(order.sample.receivedDate) || ''},
  };
};

export const convertOrderPackagesDetailsToClient = (packagesDetails: IOrderPackagesDetails): IOrderPackagesDetails => {
  return {
    packagesAndTests: packagesDetails.packagesAndTests.map((i) => ({...i, price: convertPriceToClient(i.price)})),
    amount: convertPriceToClient(packagesDetails.amount),
    discount: convertPriceToClient(packagesDetails.discount),
  };
};

export const convertOrderStatusesToClient = (statuses: IServerOrderStatuses): IOrderStatus => {
  return {...parseOrderStatus(statuses)};
};

export const convertReportsToClient = (report: IOrderReport) => {
  return withBucketBaseUrl(report);
};
