import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {Nullable} from 'Common/types';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import {actions as userActions, selectors as userSelectors} from 'OnlineReport/store/ancestry';
import {
  actions as adminActions,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/ancestry/index';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {IAncestryPopulationComparison} from 'OnlineReport/models/shared/IAncestryPopulationComparison';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';
import {actions as onlineReportUserActions, selectors as onlineReportUserSelectors} from 'OnlineReport/store/index';
import {
  actions as onlineReportAdminActions,
  selectors as onlineReportAdminSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import {IAncestryPcaReferencePoint} from 'OnlineReport/models/Ancestry/IAncestryPcaReferencePoint';

export interface IState {
  population: Nullable<IAncestryPopulation[]>;
  populationLoading: ICommunication;
  populationComparison: Nullable<IAncestryPopulationComparison>;
  populationComparisonLoading: ICommunication;
  ancestryProbabilities: Nullable<IAncestryProbabilities>;
  ancestryProbabilitiesLoading: ICommunication;
  horseInfo: Nullable<IOnlineReportHorseInfo>;
  horseOwner: Nullable<string>;
  horseInfoLoading: ICommunication;
  ancestryRelatedHorses: IAncestryRelatedHorse[];
  ancestryRelatedHorsesLoading: ICommunication;
  ancestryKinshipHorses: IAncestryKinshipHorse[];
  ancestryKinshipHorsesLoading: ICommunication;
  ancestryPcaReferenceData: IAncestryPcaReferencePoint[];
  ancestryPcaReferenceDataLoading: ICommunication;
}

export interface IDispatch {
  getPopulation(horseId: number): void;
  getAncestryProbabilities(horseId: number): void;
  getPopulationComparison(horseId: number): void;
  getHorseInfo(horseId: number): void;
  getHorseOwner(horseId: number): void;
  getAncestryRelated(horseId: number): void;
  getAncestryKinship(horseId: number): void;
  getAncestryPcaReferenceData(): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    population: userSelectors.selectPopulation(state),
    populationLoading: userSelectors.selectCommunication(state, 'populationLoading'),
    populationComparison: userSelectors.selectPopulationComparison(state),
    populationComparisonLoading: userSelectors.selectCommunication(state, 'populationComparisonLoading'),
    ancestryProbabilities: userSelectors.selectAncestryProbabilities(state),
    ancestryProbabilitiesLoading: userSelectors.selectCommunication(state, 'ancestryProbabilitiesLoading'),
    horseInfo: onlineReportUserSelectors.selectHorseInfo(state),
    horseOwner: onlineReportUserSelectors.selectHorseOwner(state),
    horseInfoLoading: onlineReportUserSelectors.selectCommunication(state, 'horseInfoLoading'),
    ancestryRelatedHorses: userSelectors.selectAncestryRelatedHorses(state),
    ancestryRelatedHorsesLoading: userSelectors.selectCommunication(state, 'ancestryRelatedHorsesLoading'),
    ancestryKinshipHorses: userSelectors.selectAncestryKinshipHorses(state),
    ancestryKinshipHorsesLoading: userSelectors.selectCommunication(state, 'ancestryKinshipHorsesLoading'),
    ancestryPcaReferenceData: userSelectors.selectAncestryPcaReferenceData(state),
    ancestryPcaReferenceDataLoading: userSelectors.selectCommunication(state, 'ancestryPcaReferenceDataLoading'),
  }),
  dispatch: {
    getPopulation: userActions.getPopulation,
    getAncestryProbabilities: userActions.getAncestryProbabilities,
    getPopulationComparison: userActions.getPopulationComparison,
    getHorseInfo: onlineReportUserActions.getHorseInfo,
    getHorseOwner: onlineReportUserActions.getHorseOwner,
    getAncestryRelated: userActions.getAncestryRelatedHorses,
    getAncestryKinship: userActions.getAncestryKinshipHorses,
    getAncestryPcaReferenceData: userActions.getAncestryPcaReferenceData,
  },
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    population: adminSelectors.selectPopulation(state),
    populationLoading: adminSelectors.selectCommunication(state, 'populationLoading'),
    populationComparison: adminSelectors.selectPopulationComparison(state),
    populationComparisonLoading: adminSelectors.selectCommunication(state, 'populationComparisonLoading'),
    ancestryProbabilities: adminSelectors.selectAncestryProbabilities(state),
    ancestryProbabilitiesLoading: adminSelectors.selectCommunication(state, 'ancestryProbabilitiesLoading'),
    horseInfo: onlineReportAdminSelectors.selectHorseInfo(state),
    horseOwner: onlineReportAdminSelectors.selectHorseOwner(state),
    horseInfoLoading: onlineReportAdminSelectors.selectCommunication(state, 'horseInfoLoading'),
    ancestryRelatedHorses: adminSelectors.selectAncestryRelatedHorses(state),
    ancestryRelatedHorsesLoading: adminSelectors.selectCommunication(state, 'ancestryRelatedHorsesLoading'),
    ancestryKinshipHorses: adminSelectors.selectAncestryKinshipHorses(state),
    ancestryKinshipHorsesLoading: adminSelectors.selectCommunication(state, 'ancestryKinshipHorsesLoading'),
    ancestryPcaReferenceData: adminSelectors.selectAncestryPcaReferenceData(state),
    ancestryPcaReferenceDataLoading: adminSelectors.selectCommunication(state, 'ancestryPcaReferenceDataLoading'),
  }),
  dispatch: {
    getPopulation: adminActions.getPopulation,
    getAncestryProbabilities: adminActions.getAncestryProbabilities,
    getPopulationComparison: adminActions.getPopulationComparison,
    getHorseInfo: onlineReportAdminActions.getHorseInfo,
    getHorseOwner: onlineReportAdminActions.getHorseOwner,
    getAncestryRelated: adminActions.getAncestryRelatedHorses,
    getAncestryKinship: adminActions.getAncestryKinshipHorses,
    getAncestryPcaReferenceData: adminActions.getAncestryPcaReferenceData,
  },
};

const reviewAdminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    population: adminSelectors.selectPopulation(state),
    populationLoading: adminSelectors.selectCommunication(state, 'populationLoading'),
    populationComparison: adminSelectors.selectPopulationComparison(state),
    populationComparisonLoading: adminSelectors.selectCommunication(state, 'populationComparisonLoading'),
    ancestryProbabilities: adminSelectors.selectAncestryProbabilities(state),
    ancestryProbabilitiesLoading: adminSelectors.selectCommunication(state, 'ancestryProbabilitiesLoading'),
    horseInfo: onlineReportAdminSelectors.selectHorseInfo(state),
    horseOwner: onlineReportAdminSelectors.selectHorseOwner(state),
    horseInfoLoading: onlineReportAdminSelectors.selectCommunication(state, 'horseInfoLoading'),
    ancestryRelatedHorses: adminSelectors.selectAncestryRelatedHorses(state),
    ancestryRelatedHorsesLoading: adminSelectors.selectCommunication(state, 'ancestryRelatedHorsesLoading'),
    ancestryKinshipHorses: adminSelectors.selectAncestryKinshipHorses(state),
    ancestryKinshipHorsesLoading: adminSelectors.selectCommunication(state, 'ancestryKinshipHorsesLoading'),
    ancestryPcaReferenceData: adminSelectors.selectAncestryPcaReferenceData(state),
    ancestryPcaReferenceDataLoading: adminSelectors.selectCommunication(state, 'ancestryPcaReferenceDataLoading'),
  }),
  dispatch: {
    getPopulation: adminActions.getReviewPopulation,
    getAncestryProbabilities: adminActions.getReviewAncestryProbabilities,
    getPopulationComparison: adminActions.getReviewPopulationComparison,
    getHorseInfo: onlineReportAdminActions.getHorseInfo,
    getHorseOwner: onlineReportAdminActions.getHorseOwner,
    getAncestryRelated: adminActions.getAncestryRelatedHorses,
    getAncestryKinship: adminActions.getAncestryKinshipHorses,
    getAncestryPcaReferenceData: adminActions.getAncestryPcaReferenceData,
  },
};

export const ancestryDistributor: Record<OnlineReportType, IDistributor<IState, IDispatch>> = {
  [OnlineReportType.User]: userDistributor,
  [OnlineReportType.Association]: userDistributor,
  [OnlineReportType.Admin]: adminDistributor,
  [OnlineReportType.AdminAssociation]: adminDistributor,
  [OnlineReportType.ReviewAdmin]: reviewAdminDistributor,
  [OnlineReportType.ReviewAdminAssociation]: reviewAdminDistributor,
};
