import {action} from 'typesafe-actions';

import AdminShortTandemRepeatReportService from 'Admin/AdminDashboard/services/AdminSTRReportService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {
  getShortTandemRepeatReportActions,
  getShortTandemRepeateportActionTypes,
  getShortTandemRepeateportReviewActionTypes,
  getShortTandemRepeatReportReviewActions,
} from './types';

export const getShortTandemRepeatReport =
  (horseId: number): ActionResult<IAppState, void, getShortTandemRepeatReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getShortTandemRepeateportActionTypes.REQUEST));
      const res = await AdminShortTandemRepeatReportService.getShortTandemRepeatReport(horseId);
      dispatch(action(getShortTandemRepeateportActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getShortTandemRepeateportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getShortTandemRepeatReportReview =
  (horseId: number): ActionResult<IAppState, void, getShortTandemRepeatReportReviewActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getShortTandemRepeateportReviewActionTypes.REQUEST));
      const res = await AdminShortTandemRepeatReportService.getShortTandemRepeatReportReview(horseId);
      dispatch(action(getShortTandemRepeateportReviewActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getShortTandemRepeateportReviewActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
