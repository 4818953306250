import React from 'react';
import styled from 'styled-components';

import {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import {breakpoints} from 'Common/constants/Breakpoints';
import {MainInfoRow, MainNameRow} from 'UserProfile/components/common/styled';
import {Actions} from 'Shared/components/UserProfile/parts/UserHorsesTab/UserHorse/UserHorseActions';
import Typography from 'Common/constants/Typography';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import AvatarHorse from 'UserProfile/components/common/AvatarHorse';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import Icon, {IconName} from 'Icon/components/Icon';
import usePermissions from 'Permissions/hooks/usePermissions';
import {Permission} from 'Permissions/constants/Permission';
import {IHorseOverview} from 'UserProfile/components/TabsData/common/models/IHorseOverview';
import {VerificationStatus} from 'Common/constants/VerificationStatus';
import Tooltip from 'Common/components/Tooltip/Tooltip';

const ETALON_VERIFIED = 'Etalon Tested & Verified';

const Root = styled.div<{isClickable?: boolean}>`
  cursor: ${({isClickable}) => (isClickable ? 'pointer' : 'default')};
  pointer-events: ${({isClickable}) => (isClickable ? 'auto' : 'none')};
`;

const AvatarWrapper = styled.div`
  margin-right: 24px;
  height: 130px;

  @media ${mediumMdSmBreakpoint} {
    margin-right: 10px;
  }

  @media ${breakpoints.md} {
    margin-right: 24px;
  }
`;

const InfoRow = styled(MainInfoRow)`
  font-size: ${Typography.size.size16};
  line-height: 24px;
  word-break: break-all;

  @media ${breakpoints.sm} {
    word-break: normal;
    max-height: 150px;
    overflow: hidden;
  }
`;

const HorseName = styled(MainNameRow)`
  line-height: 1;
  word-break: break-all;
`;

const VerificationIcon = styled(Icon)`
  bottom: 32px;
  left: -16px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.06));
`;

interface IProps {
  horse: IHorseOverview;
  disableActions?: boolean;
  editHandler?(id: number): void;
  deleteHandler?(id: number): void;
  onClickHandler?(): void;
  isClickable?: boolean;
}

const HorseOverview = (props: IProps) => {
  const {
    horse: {horseId, avatar, isArchived, name, breed, gender, availabilityCriteria, dateOfBirth, verificationStatus},
    editHandler,
    deleteHandler,
    disableActions,
    onClickHandler,
    isClickable = true,
  } = props;

  const {hasPermission} = usePermissions();

  const isEtalonVerified = verificationStatus === VerificationStatus.Verified;

  return (
    <Root className="d-flex flex-row" onClick={onClickHandler} isClickable={!!horseId && (!isArchived || !isClickable)}>
      <AvatarWrapper className="position-relative">
        <AvatarHorse
          isArchived={isArchived}
          avatarSize={AvatarSize.Small}
          defaultAvatarProfile={DefaultAvatarProfile.Horse}
          url={avatar?.url}
          isMarkedIcon={
            hasPermission(Permission.BuildHorse) && availabilityCriteria && isAvailableForBah(availabilityCriteria)
          }
          markedIcon={{name: IconName.HorseGrantedWhite, size: 26, fill: false, stroke: false}}
        />
        {!disableActions && horseId && editHandler && deleteHandler && (
          <Actions id={horseId} editHandler={editHandler} deleteHandler={deleteHandler} />
        )}
        {isEtalonVerified && (
          <Tooltip content={ETALON_VERIFIED}>
            <VerificationIcon className="position-absolute" name={IconName.EtalonVerified} size={52} />
          </Tooltip>
        )}
      </AvatarWrapper>
      <div className="d-flex flex-column w-100 h-100">
        <HorseName>{name}</HorseName>
        <InfoRow>{breed}</InfoRow>
        <InfoRow>
          <>{gender}</>
          <>{gender && dateOfBirth && ', '}</>
          <>{dateOfBirth && dateDiffInYearsOrMonth(dateOfBirth)}</>
        </InfoRow>
      </div>
    </Root>
  );
};

export default HorseOverview;
