import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from 'redux-first-history';

export const history = createBrowserHistory();

export const historyContext = () => {
  return createReduxHistoryContext({
    history,
  });
};
