import React, {memo} from 'react';
import {useParams} from 'react-router-dom';

import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import {VisitorType} from 'Common/constants/VisitorType';
import ColorPalette from 'Common/constants/ColorPalette';
import UserProfile, {UserProfileRouteProps} from 'Shared/components/UserProfile/UserProfile';
import {UserProfileType} from 'Shared/models/UserProfileType';

const BusinessPortalOwnerProfileLayout = () => {
  const {userProfileType} = useParams<UserProfileRouteProps>();

  return (
    <BusinessPortalLayout backgroundColor={ColorPalette.gray49} isBackButtonDenied={false}>
      <div className="w-100 h-100 position-relative d-flex">
        <UserProfile
          visitorType={VisitorType.AssociationEmployee}
          userProfileType={userProfileType as UserProfileType}
        />
      </div>
    </BusinessPortalLayout>
  );
};

export default memo(BusinessPortalOwnerProfileLayout);
