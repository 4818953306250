import {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import {Select} from 'Common/components/Controls';
import {castToOption} from 'Common/helpers/OptionHelper';
import {memoWithGeneric} from 'Common/helpers/react/memoWithGeneric';
import {sortByName} from 'Common/helpers/sortByName';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {INamedEntity} from 'Common/models/INamedEntity';
import {IOption} from 'Common/models/IOption';
import {IAppState} from 'Common/store/IAppState';
import {Nullable} from 'Common/types';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {FilterOperatorIgnore} from 'FacetFilter/constants/FilterOperator';
import {IFacetSelectors, makeFacetSelectors} from 'FacetFilter/helpers/makeFacetSelectors';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import Loading from 'Loading/components/Loading';
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select';
import {ActionMeta, PropsValue} from 'react-select/dist/declarations/src/types';
import {IFacetProps} from '../Facet';
import {OperationDropDown} from '../parts/OperationDropDown';
import {Title} from '../parts/styled';
import {selectStyles} from './styled';

const style = {
  marginBottom: 0,
  minWidth: 180,
};

interface IOwnProps<Option, D> {
  dictionary: D;
  formatOptionLabel?: (data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => React.ReactNode;
}

type AllProps<Option, T, D> = IOwnProps<Option, D> & IFacetSelectors<T> & IFacetProps;

function ItemListFacet<Option extends IOption, T extends INamedEntity, D extends ISimpleDictionary<T>>(
  props: AllProps<Option, T, D>
) {
  const {
    filterProperty,
    filterPropertyOperations,
    filterRequest = {
      property: filterProperty.property,
      operation: filterPropertyOperations?.operations[0].operation || '',
      value: '',
    },
    formatOptionLabel,
    onSuccess,
  } = props;
  const {dictionary, items, itemsLoading} = props;

  const [request, setRequest] = useState<IFilterRequest>(filterRequest);
  const [selectedEnumValue, setSelectedEnumValue] = useState<Nullable<PropsValue<IDictionaryOptionType>>>(null);
  const [isDisableSelect, setIsDisabledSelect] = useState(false);

  const {
    actions: {getItems},
  } = dictionary;

  useEffect(() => {
    getItems();
  }, [getItems]);

  const options = useMemo(() => castToOption(items.sort((a, b) => sortByName(a, b))), [items]);

  useEffect(() => {
    setRequest(filterRequest);
    const findOption = options.find((x) => x.value.toString() === filterRequest.value);
    setSelectedEnumValue(findOption || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (selectedEnumValue || (!selectedEnumValue && FilterOperatorIgnore.includes(request.operation))) {
      onSuccess(request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const onSelectOption = useCallback(
    (enumValue: PropsValue<IDictionaryOptionType>, action: ActionMeta<IDictionaryOptionType>) => {
      if (action.action === 'select-option' && enumValue) {
        const {value} = enumValue as IDictionaryOptionType;
        setSelectedEnumValue(enumValue as IDictionaryOptionType);
        setRequest({...request, value: value.toString()});
      }
    },
    [request]
  );

  const onOperationSelect = useCallback(
    (operation: string) => {
      if (FilterOperatorIgnore.includes(operation)) {
        setIsDisabledSelect(true);
        setRequest({...request, operation, value: ''});
      } else {
        setIsDisabledSelect(false);
        setRequest({
          ...request,
          operation,
          value: selectedEnumValue ? (selectedEnumValue as IDictionaryOptionType).value.toString() : '',
        });
      }
    },
    [request, selectedEnumValue]
  );

  const isLoading = itemsLoading.isRequesting;

  return (
    <>
      {isLoading && <Loading />}
      <Title>{filterProperty.title}</Title>
      {filterPropertyOperations && (
        <OperationDropDown
          filterOperations={filterPropertyOperations}
          onSelect={onOperationSelect}
          defaultOperation={filterRequest.operation}
        />
      )}
      {formatOptionLabel ? (
        <Select<Option>
          options={options as Option[]}
          value={selectedEnumValue as Nullable<PropsValue<Option>>}
          onChange={onSelectOption}
          style={style}
          styles={selectStyles}
          disabled={isDisableSelect}
          formatOptionLabel={formatOptionLabel}
        />
      ) : (
        <Select
          options={options}
          value={selectedEnumValue}
          onChange={onSelectOption}
          style={style}
          styles={selectStyles}
          disabled={isDisableSelect}
        />
      )}
    </>
  );
}

const mapStateToProps = <Option extends IOption, T extends INamedEntity, D extends ISimpleDictionary<T>>(
  state: IAppState,
  ownProps: IOwnProps<Option, D>
): IFacetSelectors<T> => {
  return makeFacetSelectors(state, ownProps.dictionary);
};

const Connected = connect(mapStateToProps)(ItemListFacet) as unknown as <Option, D>(
  props: IOwnProps<Option, D>
) => JSX.Element;

const Exported = <
  Option extends IOption,
  T extends INamedEntity = INamedEntity,
  D extends ISimpleDictionary<T> = ISimpleDictionary<T>
>(
  ownProps: IOwnProps<Option, D>
) => {
  return <Connected {...ownProps} />;
};

export default memoWithGeneric(Exported);
