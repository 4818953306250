import React, {memo} from 'react';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {ValidationError} from 'Common/components/FormFields';

interface Props {
  animalType?: string;
  isRequired?: boolean;
}

function SelectOwner(props: Props) {
  return (
    <FormControlContainer isRequired={props.isRequired} title={props.animalType + ' owner'}>
      <UserSearchField isRequired={props.isRequired} name="userId" memoized={true} />
    </FormControlContainer>
  );
}
SelectOwner.defaultProps = {
  animalType: 'Horse',
  isRequired: false,
};

export default memo(SelectOwner);
