// import {convertToFormData} from 'Common/helpers/convertToFormData';
import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IShortTandemRepeatReport} from 'OnlineReport/models/STR/IShortTandemRepeatReport';

const getShortTandemRepeatReport = async (horseId: number): Promise<IShortTandemRepeatReport> => {
  const result = await axiosWrapper.get<IObjectResponse<IShortTandemRepeatReport>>(
    `/ShortTandemRepeatReportAdmin/${horseId}`
  );
  return result.data.data;
};

const getShortTandemRepeatReportReview = async (horseId: number): Promise<IShortTandemRepeatReport> => {
  const result = await axiosWrapper.get<IObjectResponse<IShortTandemRepeatReport>>(
    `/ShortTandemRepeatReportReviewAdmin/${horseId}`
  );
  return result.data.data;
};

const uploadShortTandemRepeat = async (file: File): Promise<void> => {
  const data = await file.text();
  await axiosWrapper.post<IObjectResponse<null>>(`/ShortTandemRepeatReportUpload`, data);
};

export default {getShortTandemRepeatReport, getShortTandemRepeatReportReview, uploadShortTandemRepeat};
