import {IUserToAdminChat} from 'Admin/AdminSupport/models/IUserToAdminChat';
import {IUserToAdminChatDetailsAdmin} from 'Admin/AdminSupport/models/IUserToAdminChatDetailsAdmin';
import {
  IUserToAdminChatAdminMessageAdmin,
  IUserToAdminChatMessageAdmin,
  IUserToAdminChatUserMessageAdmin,
} from 'Admin/AdminSupport/models/IUserToAdminChatMessageAdmin';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';
import {withUserName} from 'Common/helpers/withUserName';
import {
  IServerUserToAdminChat,
  IServerUserToAdminChatAdminMessageAdmin,
  IServerUserToAdminChatMessageAdmin,
  IServerUserToAdminChatUserMessageAdmin,
} from '../types/userToAdminChat';

export function convertUserToAdminChatToClient(value: IServerUserToAdminChat): IUserToAdminChat {
  return {
    ...value,
    user: withUserName(withAvatar(value.user)),
    admin: value.admin ? withAvatar(value.admin) : null,
  };
}

function convertUserToAdminUserMessages(
  value: IServerUserToAdminChatUserMessageAdmin
): IUserToAdminChatUserMessageAdmin {
  return {
    ...value,
    createDate: value.createDate,
    images: value.images.map((image) => withImageBaseUrl(image)),
  };
}

function convertUserToAdminAdminMessages(
  value: IServerUserToAdminChatAdminMessageAdmin
): IUserToAdminChatAdminMessageAdmin {
  return {
    ...value,
    createDate: value.createDate,
    images: value.images.map((image) => withImageBaseUrl(image)),
    admin: withAvatar(value.admin),
  };
}

export function convertUserToAdminChatMessageToClient(
  value: IServerUserToAdminChatMessageAdmin
): IUserToAdminChatMessageAdmin {
  return {
    userMessages: value.userMessages.map(convertUserToAdminUserMessages),
    adminMessages: value.adminMessages.map(convertUserToAdminAdminMessages),
  };
}

export function convertUserToAdminDetailsToClient(value: IUserToAdminChatDetailsAdmin): IUserToAdminChatDetailsAdmin {
  return {
    createDate: convertUTCToClientDate(value.createDate) || '',
    lastMessageDate: convertUTCToClientDate(value.lastMessageDate) || '',
    adminOnDuty: value.adminOnDuty ? withAvatar(value.adminOnDuty) : null,
  };
}
