import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminDogs;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectDogs(state: IAppState) {
  return selectState(state).data.dogs;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}
