import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IBuildHorseRequest} from 'Filters/models/IBuildHorseRequest';
import BuildHorseDataService from 'BuildHorse/services/BuildHorseDataService';
import {IPaginationRequest} from 'Common/models/IPaginationRequest';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {Actions, getFoalsActionTypes} from './types';

export const getFoals = (
  buildHorseRequest: IBuildHorseRequest,
  pagination: IPaginationRequest
): ActionResult<IAppState, void, Actions> => async dispatch => {
  try {
    dispatch(action(getFoalsActionTypes.REQUEST));
    const response = await BuildHorseDataService.getFoals(buildHorseRequest, pagination);
    dispatch(action(getFoalsActionTypes.SUCCESS, response));
  } catch (error) {
    dispatch(action(getFoalsActionTypes.FAILURE, getErrorMessage(error)));
  }
};
