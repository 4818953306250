import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import Carousel from 'Common/components/Carousel/Carousel';
import {IMainPageHorse} from 'Main/models/IMainPageHorses';

import './HorseCarousel.css';
import HorseCard from './HorseCard';
import Icon, {IconName} from 'Icon/components/Icon';
import {size} from 'Common/constants/Breakpoints';
import EmptyHorse from './EmptyHorse';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const arrowStyle = {width: 56, height: 56};

const responsive = {
  desktop: {
    breakpoint: {max: 4000, min: size.lg},
    items: 4,
  },
  smalldesktop: {
    breakpoint: {max: size.lg, min: size.md},
    items: 3,
  },
  tablet: {
    breakpoint: {max: size.md, min: size.sm},
    items: 2,
  },
  mobile: {
    breakpoint: {max: size.sm, min: 0},
    items: 1,
  },
};

const Arrow = styled(Icon)<OwnArrowProps>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  opacity: 0.5;
  ${({direction}) =>
    direction === 'left'
      ? `
    left: -32px;
    transform: translate(0, -50%) rotate(180deg);
    `
      : `
    right: -32px;    
    `};
  :hover {
    opacity: 1;
  }
`;

interface OwnArrowProps {
  direction: 'left' | 'right';
}

interface IProps {
  horses: IMainPageHorse[];
  onEmptyCardClick(): void;
  searchQuery: string;
}

function HorseCarousel(props: IProps) {
  const {horses, onEmptyCardClick, searchQuery} = props;
  const {isMobile} = useMediaQuery();

  const renderLeftArrow = useCallback(
    (onClick: (() => void) | undefined) => (
      <Arrow name={IconName.ArrowNext} onClick={onClick} direction={'left'} {...arrowStyle} />
    ),
    []
  );

  const renderRightArrow = useCallback(
    (onClick: (() => void) | undefined) => (
      <Arrow name={IconName.ArrowNext} onClick={onClick} direction={'right'} {...arrowStyle} />
    ),
    []
  );

  return (
    <Carousel
      customLeftArrowElement={renderLeftArrow}
      customRightArrowElement={renderRightArrow}
      responsive={responsive}
      additionalTransfrom={0}
      centerMode={false}
      removeArrowOnDeviceType=""
      itemClass="multi-carousel-item-mainpage"
      slidesToSlide={isMobile ? 1 : 2}
    >
      {(!isMobile || !searchQuery) && <EmptyHorse onClick={onEmptyCardClick} />}
      {horses.map((horse, i) => (
        <HorseCard key={i} horse={horse} />
      ))}
    </Carousel>
  );
}

export default memo(HorseCarousel);
