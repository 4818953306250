import {combineReducers, Reducer} from 'redux';

import {IAdminDogsState} from 'Admin/AdminDashboard/store/adminDogs/types';
import {communicationDogsReducer} from 'Admin/AdminDashboard/store/adminDogs/reducer/communications';
import {dataDogsReducer} from 'Admin/AdminDashboard/store/adminDogs/reducer/data';
import {uiDogsReducer} from 'Admin/AdminDashboard/store/adminDogs/reducer/ui';

export const dogsReducer: Reducer<IAdminDogsState> = combineReducers<IAdminDogsState>({
  communications: communicationDogsReducer,
  data: dataDogsReducer,
  ui: uiDogsReducer,
});
