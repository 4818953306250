import {combineReducers, Reducer} from 'redux';

import {IUserNotificationState} from '../types';
import {communicationUserNotificationsReducer} from './communications';
import {dataUserNotificationsReducer} from './data';

export const userNotificationsReducer: Reducer<IUserNotificationState> = combineReducers<IUserNotificationState>({
  communications: communicationUserNotificationsReducer,
  data: dataUserNotificationsReducer,
});
