import React, {useCallback, useEffect, useState} from 'react';
import {unstable_useBlocker as useBlocker} from 'react-router-dom';

import WarningModal, {ModalTypes, WarningModalWindowProps} from 'Common/components/Modal/WarningModal';

interface IWarningModalProps extends Omit<WarningModalWindowProps, 'isOpen' | 'onSuccess' | 'onClose'> {}

interface IRouteLeavingGuardProps {
  when: boolean;
  warningModalProps: IWarningModalProps;
}

export function useRouteLeavingGuard(props: IRouteLeavingGuardProps) {
  const {
    when,
    warningModalProps: {
      modalType = ModalTypes.Confirm,
      cancelButtonText,
      hideCloseButton,
      isSuccessLoading,
      maxHeight,
      maxWidth,
      modalTitle,
      successButtonText = 'Leave',
    },
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsOpen(true);
    }
  }, [blocker, when]);

  useEffect(() => {
    if (confirmedNavigation) {
      if (blocker.proceed) {
        blocker.proceed();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation]);

  const handlerOnClose = useCallback(() => {
    setIsOpen(false);
    if (blocker.reset) {
      blocker.reset();
    }
  }, [blocker]);

  const handlerOnSuccess = useCallback(() => {
    setConfirmedNavigation(true);
    setIsOpen(false);
  }, []);

  const RouteLeavingGuard = (props: React.PropsWithChildren) => (
    <WarningModal
      isOpen={isOpen}
      modalTitle={modalTitle}
      modalType={modalType}
      successButtonText={successButtonText}
      cancelButtonText={cancelButtonText}
      hideCloseButton={hideCloseButton}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      onClose={handlerOnClose}
      onSuccess={handlerOnSuccess}
      isSuccessLoading={isSuccessLoading}
    >
      {props.children}
    </WarningModal>
  );

  return {RouteLeavingGuard};
}
