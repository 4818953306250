import * as Yup from 'yup';

import {IChatRequest} from 'UserProfile/models/IChatRequest';

export const initialValue: IChatRequest = {
  targetId: 0,
  message: '',
};

export const validationSchema = Yup.object().shape<IChatRequest>({
  targetId: Yup.number().required('User id is required'),
  message: Yup.string(),
});
