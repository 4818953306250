import {IModule} from 'redux-dynamic-modules';

import {IAdminDogsState} from 'Admin/AdminDashboard/store/adminDogs/types';
import {dogsReducer} from './reducer';

export interface IAdminDogsModuleState {
  adminDogs: IAdminDogsState;
}

export const AdminDogsModule: IModule<IAdminDogsModuleState> = {
  id: 'adminDogs',
  reducerMap: {
    adminDogs: dogsReducer,
  } as any,
};
