import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import DogEditForm from '../../../components/DogEditForm/DogEditForm';
import {IDog} from '../../../../../Dogs/models/IDog';

interface IUpdateModalParams {
  onSuccess?(): void;
}

export function useDogEditModal(params: IUpdateModalParams) {
  const {onSuccess} = params;

  const [isOpen, setIsOpen] = useState(false);
  const [dog, setDog] = useState<IDog>();
  const openDogEditModal = useCallback((dog: IDog) => {
    setIsOpen(true);
    setDog(dog);
  }, []);
  const closeDogEditModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeDogEditModal();
    onSuccess && onSuccess();
  }, [closeDogEditModal, onSuccess]);

  const dogEditModal = (
    <ModalWindow isOpen={isOpen} onClose={closeDogEditModal}>
      <DogEditForm onSuccess={handleSuccess} dog={dog} />
    </ModalWindow>
  );

  return {
    dogEditModal,
    openDogEditModal,
  };
}
