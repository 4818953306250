import React, {memo, useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {IFoalParent} from 'Common/models/IFoal';
import SquareAvatar from 'Common/components/Avatar/SquareAvatar';
import IElementPosition, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import {Gender} from 'Common/constants/Gender';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import ParentNamePanel from './ParentNamePanel';
import FavoriteHorseIconButton from 'FavoriteHorse/components/FavoriteHorseIconButton/FavoriteHorseIconButton';
import {breakpoints} from 'Common/constants/Breakpoints';
import ExcludeHorseButton from 'ExcludeHorse/components/ExcludeHorseIconButton/ExcludeHorseIconButton';
import ShadowContainer from 'Common/components/Containers/ShadowContainer';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import Icon, {IconName} from 'Icon/components/Icon';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {VerificationStatus} from 'Common/constants/VerificationStatus';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';

const ETALON_VERIFIED = 'Etalon Tested & Verified';

function getParentDetails(parent: IFoalParent) {
  return [parent.gender, parent.dateOfBirth ? dateDiffInYearsOrMonth(parent.dateOfBirth) : '']
    .filter(Boolean)
    .join(', ');
}

const HoverPanel = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.1) 100%);
  z-index: 2;

  @media ${breakpoints.md} {
    border-radius: 0px 0px 6px 6px;
  }
`;

const VerificationIcon = styled(Icon)<IElementPosition>`
  z-index: 2;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.6));
  bottom: -22px;
  ${(props) => (props.position === ElementPosition.Left ? 'left: -20px;' : 'right: -20px;')}

  @media ${breakpoints.sm} {
    ${(props) => (props.position === ElementPosition.Left ? 'left: 4px;' : 'right: 4px;')}
  }
`;

interface IProps {
  foalParents: IFoalParent[];
}

function FoalParents(props: IProps) {
  const {foalParents} = props;
  const stallionParent = foalParents.find((x) => x.gender === Gender.Stallion);
  const mareParent = foalParents.find((x) => x.gender === Gender.Mare);
  const navigate = useNavigate();
  const {currentVisitorType} = useVisitorTypeService();

  const stallionDetails = stallionParent && getParentDetails(stallionParent);
  const mareDetails = mareParent && getParentDetails(mareParent);

  const redirectToStallionProfile = useCallback(() => {
    navigate(`${PREFIX_VISITOR_TYPE[currentVisitorType]}/horse/${stallionParent?.id}`);
  }, [currentVisitorType, navigate, stallionParent?.id]);

  const redirectToMareProfile = useCallback(() => {
    navigate(`${PREFIX_VISITOR_TYPE[currentVisitorType]}/horse/${mareParent?.id}`);
  }, [currentVisitorType, mareParent?.id, navigate]);

  const [stallionHover, setStallionHover] = useState<boolean>(false);
  const [mareHover, setMareHover] = useState<boolean>(false);

  const enableStallionHover = useCallback(() => setStallionHover(true), [setStallionHover]);
  const disableStallionHover = useCallback(() => setStallionHover(false), [setStallionHover]);

  const enableMareHover = useCallback(() => setMareHover(true), [setMareHover]);
  const disableMareHover = useCallback(() => setMareHover(false), [setMareHover]);

  const {emit} = useEventBus();
  const handleSuccess = useCallback(() => {
    emit(EventName.GenePoolChanged);
  }, [emit]);

  const isStallionParentEtalonVerified = stallionParent?.verificationStatus === VerificationStatus.Verified;
  const isMareEtalonParentVerified = mareParent?.verificationStatus === VerificationStatus.Verified;

  return (
    <div className="d-flex flex-wrap position-relative h-100">
      <HoverPanel className="position-absolute" />
      {stallionParent && (
        <>
          <SquareAvatar
            width="50%"
            height="100%"
            onClick={redirectToStallionProfile}
            avatar={stallionParent.avatar}
            elementPosition={ElementPosition.Left}
            onMouseEnterHandler={enableStallionHover}
            onMouseLeaveHandler={disableStallionHover}
            isShowHoverShadow={!stallionHover}
          >
            <ShadowContainer
              isShowShadow={stallionHover}
              position="Left"
              className="flex-column position-absolute w-100"
              style={{height: 94}}
            >
              <FavoriteHorseIconButton
                horse={stallionParent}
                position="Left"
                isShowLabel={stallionHover}
                style={{marginTop: 8}}
                onSuccess={handleSuccess}
              />
              <ExcludeHorseButton
                horse={stallionParent}
                position="Left"
                isShowLabel={stallionHover}
                style={{marginTop: 8}}
                onSuccess={handleSuccess}
              />
            </ShadowContainer>
          </SquareAvatar>
          {isStallionParentEtalonVerified && (
            <Tooltip content={ETALON_VERIFIED}>
              <VerificationIcon
                className="position-absolute"
                name={IconName.EtalonVerified}
                size={64}
                position={ElementPosition.Left}
              />
            </Tooltip>
          )}
        </>
      )}
      {mareParent && (
        <>
          <SquareAvatar
            width="50%"
            height="100%"
            onClick={redirectToMareProfile}
            avatar={mareParent.avatar}
            elementPosition={ElementPosition.Right}
            onMouseEnterHandler={enableMareHover}
            onMouseLeaveHandler={disableMareHover}
            isShowHoverShadow={!mareHover}
          >
            <ShadowContainer
              isShowShadow={mareHover}
              position="Right"
              className="flex-column position-absolute w-100"
              style={{height: 94}}
            >
              <FavoriteHorseIconButton
                horse={mareParent}
                position="Right"
                isShowLabel={mareHover}
                style={{marginTop: 8}}
                onSuccess={handleSuccess}
              />
              <ExcludeHorseButton
                horse={mareParent}
                position="Right"
                isShowLabel={mareHover}
                style={{marginTop: 8}}
                onSuccess={handleSuccess}
              />
            </ShadowContainer>
          </SquareAvatar>
          {isMareEtalonParentVerified && (
            <Tooltip content={ETALON_VERIFIED}>
              <VerificationIcon
                className="position-absolute"
                name={IconName.EtalonVerified}
                size={64}
                position={ElementPosition.Right}
              />
            </Tooltip>
          )}
        </>
      )}
      <ParentNamePanel
        stallionName={stallionParent?.name || ''}
        stallionDetails={stallionDetails || ''}
        mareName={mareParent?.name || ''}
        mareDetails={mareDetails || ''}
      />
    </div>
  );
}

export default memo(FoalParents);
