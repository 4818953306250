import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {IBaseControlProps} from 'Common/models/IBaseControlProps';
import FieldControlContainer from '../Layout/FieldControlContainer';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import {inputCss} from './styles';
import {IconName} from 'Icon/components/Icon';
import IconButton from 'Common/components/Controls/Buttons/IconButton';

const eyeButtonProps = {color: ColorPalette.gray13, fill: false, size: 20};

interface IFieldNotEmptyProp {
  isNotEmpty: boolean;
}

const StyledInput = styled.input<IFieldNotEmptyProp>`
  height: 48px;
  width: 100%;
  ${inputCss}
  border: ${(prop) => (prop.isNotEmpty ? `1px solid ${Theme.color.primary}` : `1px solid ${ColorPalette.gray49}`)};
  background-color: ${(prop) => (prop.isNotEmpty ? Theme.color.white : ColorPalette.gray49)};
`;

const EyeButton = styled(IconButton)`
  right: 10px;
`;

interface IProps {
  isShowEye?: boolean;
}

type Props = IProps & IBaseControlProps & React.InputHTMLAttributes<HTMLInputElement>;

function Input(props: Props) {
  const {value, onChange, onBlur, isShowEye, ...rest} = props;

  const [notEmpty, setNotEmpty] = useState(!!value);
  const [isCensorText, setIsCensorText] = useState(true);

  const toggleCensorText = useCallback(() => setIsCensorText(!isCensorText), [isCensorText]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotEmpty(e.target.value.trim().length > 0);
    onChange && onChange(e);
  };
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setNotEmpty(e.target.value.trim().length > 0);
    onBlur && onBlur(e);
  };

  const isPasswordField = props.type === 'password';
  const inputType = isPasswordField ? 'text' : props.type || 'text';

  return (
    <FieldControlContainer {...rest}>
      <div className="d-flex align-items-center position-relative">
        <StyledInput
          {...rest}
          type={isPasswordField && isCensorText ? 'password' : inputType}
          className={classnames('form-control', 'custom-control-input', props.inputClassName)}
          style={props.inputStyle}
          value={value || ''}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          isNotEmpty={notEmpty}
        />
        {isShowEye && (
          <EyeButton
            className="position-absolute"
            name={isCensorText ? IconName.ViewOff : IconName.ViewOn}
            onClick={toggleCensorText}
            {...eyeButtonProps}
          />
        )}
      </div>
    </FieldControlContainer>
  );
}

export default memo(Input);
export type InputProps = Props;
