import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {debounce} from 'lodash';
import {PropsValue, InputActionMeta, ActionMeta} from 'react-select/dist/declarations/src/types';
import * as R from 'ramda';

import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {Select} from 'Common/components/Controls';
import HorseDataService from 'Horse/services/HorseDataService';
import {Gender} from 'Common/constants/Gender';
import IFoundHorse from 'Horse/models/IFoundHorse';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import renderSearchItem, {IHorseOption} from './renderSearchItem';

function convertHorseToOption(horse: IFoundHorse): IHorseOption {
  const {id, name, gender, avatar, dateOfBirth} = horse;
  return {
    value: id,
    label: name,
    gender,
    avatarUrl: avatar?.url,
    dateOfBirth: dateOfBirth ? dateDiffInYearsOrMonth(dateOfBirth) : null,
  };
}
const styles = {
  menuList: () => ({
    boxShadow: 'unset',
  }),
  valueContainer: () => ({
    paddingLeft: '56px',
  }),
};

const style = {
  marginBottom: 0,
};

const SearchIcon = styled(ColoredIcon)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const noHorsesMessage = () => 'No horses';

type HorseGroups = {[key in 'own' | 'another']?: IFoundHorse[]};

interface IHorseOptionGroup {
  label: string;
  options: IHorseOption[];
}

interface IProps {
  gender?: Gender;
  horseNameDefault?: string;
  isClearInputAfterSelect?: boolean;
  onSelectHorse(horse: IFoundHorse): void;
  onInputChange?: (horseName: string) => void;
  onClearHorse?: () => void;
}

function SearchHorseInput(props: IProps) {
  const {gender, onSelectHorse, onClearHorse, horseNameDefault, onInputChange, isClearInputAfterSelect} = props;

  const [horseName, setHorseName] = useState(horseNameDefault || '');
  const onChangeHorseName = useCallback(
    (value: string, {action}: InputActionMeta) => {
      if (action === 'input-change') {
        setHorseName(value);
        onInputChange && onInputChange(value);
      }
    },
    [setHorseName, onInputChange]
  );

  const [foundHorses, setFoundHorses] = useState<IFoundHorse[]>([]);
  const isEmptyValue = useRef(true);

  const [isHorsesLoading, setIsHorsesLoading] = useState(false);
  const getHorses = useCallback(
    async (name: string) => {
      try {
        setIsHorsesLoading(true);
        const horses = await HorseDataService.searchHorseByGenderAndName(name, gender);
        if (isEmptyValue.current) {
          return;
        }
        setFoundHorses(horses);
      } catch (e) {
        console.error(e);
      } finally {
        setIsHorsesLoading(false);
      }
    },
    [setFoundHorses, gender, isEmptyValue]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetHorse = useCallback(
    debounce((name?: string) => name && getHorses(name), 500),
    [getHorses]
  );

  useEffect(() => {
    isEmptyValue.current = !horseName;
    if (!horseName) {
      setFoundHorses([]);
    }
    debouncedGetHorse(horseName);
  }, [horseName, debouncedGetHorse]);

  const [selectedHorse, setSelectedHorse] = useState<IHorseOption | null>();
  const isLoading = !!horseName && isHorsesLoading;
  const horses = useMemo(() => (!!horseName ? foundHorses : []), [foundHorses, horseName]);

  const onSelect = useCallback(
    (horse: PropsValue<IHorseOption>, action: ActionMeta<IHorseOption>) => {
      if (action.action === 'select-option' && horse) {
        const {value} = horse as IHorseOption;
        setSelectedHorse(horse as IHorseOption);

        const foundHorse = horses.find((x) => x.id === Number(value));
        foundHorse && onSelectHorse(foundHorse);
        isClearInputAfterSelect && setSelectedHorse(null);
      }

      if (action.action === 'clear') {
        setSelectedHorse(null);
        setHorseName('');
        onClearHorse && onClearHorse();
      }
    },
    [horses, onSelectHorse, isClearInputAfterSelect, onClearHorse]
  );

  const horsesGroups = R.groupBy(
    (horse: IFoundHorse) => (horse.isCurrentOwner ? 'own' : 'another'),
    horses
  ) as HorseGroups;

  const options = useMemo(
    () =>
      ([] as IHorseOptionGroup[])
        .concat(
          horsesGroups.own
            ? [
                {
                  label: 'my horses',
                  options: horsesGroups.own.map(convertHorseToOption),
                },
              ]
            : []
        )
        .concat(
          horsesGroups.another
            ? [
                {
                  label: 'Overall search',
                  options: horsesGroups.another.map(convertHorseToOption),
                },
              ]
            : []
        ),
    [horsesGroups]
  );

  return (
    <div className="position-relative">
      <SearchIcon name={IconName.Search} size={20} stroke={false} fill={true} color={ColorPalette.gray44} />
      <Select<IHorseOption>
        placeholder="Horse name"
        isSearchable={true}
        inputValue={selectedHorse ? undefined : horseName}
        onInputChange={onChangeHorseName}
        onChange={onSelect}
        options={options}
        styles={styles}
        formatOptionLabel={renderSearchItem}
        noOptionsMessage={noHorsesMessage}
        isLoading={isLoading}
        value={selectedHorse}
        isClearable={true}
        style={style}
        menuPosition="absolute"
      />
    </div>
  );
}

export default SearchHorseInput;
