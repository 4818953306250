import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {IActivationCode} from 'ActivationCode/models/IActivationCode';

const STATE_NAME = 'adminActivationCode';

export interface IAdminActivationCodeState {
  data: {
    activationCodes: IActivationCode[];
    activationCodeDetail: IActivationCode | null;
  };
  communications: {
    activationCodesLoading: ICommunication;
    activationCodesCreating: ICommunication;
    activationCodeUpdating: ICommunication;
    activationCodeDeactivating: ICommunication;
    activationCodeActiviating: ICommunication;
    activationCodeDetailsLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getActivationCodesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ACTIVATION_CODES');
export type getActivationCodesActions = MakeCommunicationActions<
  typeof getActivationCodesActionTypes,
  {success: IListResponse<IActivationCode>}
>;

export const getActivationCodeByIdActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ACTIVATION_CODE_BY_ID');
export type getActivationCodeByIdActions = MakeCommunicationActions<
  typeof getActivationCodeByIdActionTypes,
  {success: IActivationCode}
>;

export const checkActivationCodeActionTypes = makeCommunicationActionType(STATE_NAME, 'CHECK_ACTIVATION_CODE_BY_ID');
export type checkActivationCodeActions = MakeCommunicationActions<
  typeof checkActivationCodeActionTypes,
  {success: IActivationCode}
>;

export const createActivationCodesActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_ACTIVATION_CODES');
export type createActivationCodesActions = MakeCommunicationActions<typeof createActivationCodesActionTypes, {}>;

export const updateActivationCodeActionTypes = makeCommunicationActionType(STATE_NAME, 'UPDATE_ACTIVATION_CODE');
export type updateActivationCodeActions = MakeCommunicationActions<typeof updateActivationCodeActionTypes, {}>;

export const deactivateActivationCodeActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'DEACTIVATE_ACTIVATION_CODE'
);
export type deactivateActivationCodeActions = MakeCommunicationActions<typeof deactivateActivationCodeActionTypes, {}>;

export const activateActivationCodeActionTypes = makeCommunicationActionType(STATE_NAME, 'ACTIVATE_ACTIVATION_CODE');
export type activateActivationCodeActions = MakeCommunicationActions<typeof activateActivationCodeActionTypes, {}>;

export const resetActivationCodeDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ACTIVATION_CODE_DETAIL'
);
export type resetActivationCodeDetailsActions = MakeResetCommunicationActions<
  typeof resetActivationCodeDetailsActionTypes
>;

export type ActivationCodesActions =
  | getActivationCodesActions
  | getActivationCodeByIdActions
  | checkActivationCodeActions
  | createActivationCodesActions
  | updateActivationCodeActions
  | deactivateActivationCodeActions
  | resetActivationCodeDetailsActions;
