import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';

import {actions, selectors} from 'UserProfile/store/changePassword/index';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {IChangePassword} from 'UserProfile/models/IChangePassword';
import {ChangePasswordModule} from 'UserProfile/store/changePassword/changePasswordModule';
import {useRouteLeavingGuard} from 'Common/helpers/hooks/useRouteLeavingGuard';
import {ModalTypes} from 'Common/components/Modal/WarningModal';
import {initialValue, validationSchema} from './validation';
import {useToast} from 'Common/helpers/hooks/useToast';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import PasswordField from 'Common/components/FormFields/PasswordField';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {Button, FormLayout, SettingsTitle} from '../styled';
import {IAppState} from 'Common/store/IAppState';

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected;

type AllProps = FormikProps<IChangePassword> & OuterProps;

const SettingsForm = (props: AllProps) => {
  const {setErrors, setStatus, dirty, isSubmitting} = props;
  const {changePasswordLoading} = props;
  const {addToast} = useToast();

  const errorInfo = changePasswordLoading && changePasswordLoading.error;

  const onSuccess = useCallback(() => {
    addToast('Password has been successfully saved');
  }, [addToast]);
  useOnSuccessCommunication(changePasswordLoading, onSuccess);

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    const commonErrors = getCommonErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }

    if (commonErrors) {
      setStatus(commonErrors);
      addToast(commonErrors, 'error');
    }
  }, [setErrors, setStatus, errorInfo, addToast]);
  useOnErrorCommunication(changePasswordLoading, onError);
  const {RouteLeavingGuard} = useRouteLeavingGuard({
    when: dirty,
    warningModalProps: {
      modalType: ModalTypes.Confirm,
      modalTitle: 'Cancel',
    },
  });

  return (
    <>
      <RouteLeavingGuard>All unsaved data will be lost. Are you sure you want to leave the page?</RouteLeavingGuard>

      <SettingsTitle>Change password</SettingsTitle>

      <FormLayout className="d-flex flex-column">
        <Form>
          <PasswordField name="currentPassword" label="Current password" placeholder="Current password" />
          <PasswordField name="password" label="New password" placeholder="New password" />
          <PasswordField name="passwordConfirm" label="Repeat new password" placeholder="Repeat new password" />

          <div className="d-flex flex-column align-items-start">
            <Button type="submit" isLoading={isSubmitting}>
              Change password
            </Button>
          </div>
        </Form>
      </FormLayout>
    </>
  );
};

const ChangePassword = withFormik<OuterProps, IChangePassword>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    formikBag.setStatus(null);
    await formikBag.props.changePassword(values);
    formikBag.setSubmitting(false);
    formikBag.resetForm();
  },
})(SettingsForm);

const mapStateToProps = (state: IAppState) => ({
  message: selectors.selectMessage,
  changePasswordLoading: selectors.selectCommunication(state, 'changePasswordLoading'),
});

const mapDispatchToProps = {
  changePassword: actions.changePassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(ChangePassword);
export default withDynamicModules(Connected, ChangePasswordModule);
