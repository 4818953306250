import React, {memo, useCallback, useEffect, useMemo} from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';
import styled from 'styled-components';

import {
  CheckboxField,
  CheckboxGroupField,
  DateTimeField,
  GalleryField,
  InputField,
  SelectField,
} from 'Common/components/FormFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {BusinessPortalOwnerModule} from 'BusinessPortal/store/owner';
import {
  actions as adminHorseActions,
  selectors as adminHorseSelectors,
} from 'Admin/AdminAssociations/store/adminHorses';
import {AdminAssociationOwnerModule} from 'Admin/AdminAssociations/store/adminOwners';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {Gender} from 'Common/constants/Gender';
import HorseMapField from 'Common/components/FormFields/HorseMapField/HorseMapField';
import RegistriesList from 'HorseProfile/components/HorseProfileForm/parts/RegistriesList/RegistriesList';
import {Divider, TextBase} from 'BusinessPortal/components/common/styled';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {IFormValues, initialValue, validationSchema} from './validation';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {IHorseColor} from 'Dictionaries/models/IHorseColor';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IMarking} from 'Dictionaries/models/IMarking';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {Checkbox} from 'Common/components/Controls';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import {convertToClient, convertToServer} from './converters';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {
  withHorseMapFieldPropsToClient,
  withHorseMapFieldPropsToRequest,
} from 'Common/components/FormFields/HorseMapField/withHorseMapFieldProps';
import BusinessPortalOwnerService from 'BusinessPortal/services/BusinessPortalOwnerService';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AdminAssociationHorseModule} from 'Admin/AdminAssociations/store/adminHorses/adminAssociationHorseModule';
import {ModalWindowFooter, ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import {IUserOption} from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearch';
import {deleteImagesFromGallery} from 'Gallery/helpers/deleteImagesFromGallery';
import {retrieveImageError} from 'Common/helpers/retrieveImageError';
import HorseHeightField from 'Common/components/FormFields/HorseHeightField';
import OrganizationSearchField from 'Admin/AdminAssociations/components/Employees/EmployeeForm/parts/OrganizationSearchField';
import Nebula from 'Common/components/Layout/Nebula';
import {FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import {convertUserName} from 'Common/helpers/convertUserName';

const defaultUserOptionProps: IUserOption = {
  value: 0,
  label: '',
  lastName: '',
  email: '',
  id: 0,
  avatar: null,
  firstName: '',
};

const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};
const galleryIconProps: IconProps = {name: IconName.InsertPhoto, size: 48};
const fieldHintStyles = {marginLeft: 32, marginBottom: 16};

const Section = styled(TextBase)`
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: 0;
  margin-bottom: 16px;
`;

const ParentRole = styled.div`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
  color: ${Theme.color.black};
  margin-bottom: 16px;
`;

const RegistriesListWrapper = styled.div`
  margin: 14px 0 20px 0;
`;

const Avatar = styled.div`
  margin-right: 16px;
`;

const OwnerSection = styled.div`
  margin-bottom: 16px;
`;

interface IExternalDictionaries {
  colorsDictionary: ISimpleDictionary<IHorseColor>;
  breedDictionary: ISimpleDictionary<IBreed>;
  disciplineDictionary: ISimpleDictionary<IDiscipline>;
  markingDictionary: ISimpleDictionary<IMarking>;
  associationDictionary: ISimpleDictionary<IAssociation>;
  inconclusiveHealthIssueDictionary: ISimpleDictionary<IInconclusiveHealthIssue>;
}

interface IExternalProps extends Omit<IEditableSection<{}>, 'data'>, IExternalDictionaries {
  horseId: number;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const HorseFormCreate = (props: AllProps) => {
  const {setFieldValue, isValid, isSubmitting, values} = props;
  const {
    horseId,
    horseDetailsLoading,
    horseOwnerLoading,
    horseParentageLoading,
    horseRegistriesLoading,
    horseHealthIssuesLoading,
    horseDetailsUpdating,
    horseParentageUpdating,
    horseRegistriesUpdating,
    horseHealthIssuesUpdating,
    horseMediaLoading,
    horseMediaDeleting,
    setHorseOwnerRequesting,
    horseImageMediaCreating,
    horseVideoMediaCreating,
    horseAvatarUpdating,
    horseAvatarDeleting,
    getHorseDetails,
    getHorseMedia,
    getHorseHealthIssues,
    getHorseOwner,
    getHorseParentage,
    getHorseRegistries,
    resetHorseDetails,
    resetHorseMedia,
    resetHorseHealthIssues,
    resetHorseOwner,
    resetHorseParentage,
    resetHorseRegistries,
    horseOwner,
    horseDetails,
  } = props;

  const {
    dictionariesLoading,
    associationDictionary,
    inconclusiveHealthIssueDictionary,
    inconclusiveHealthIssues,
    markingDictionary,
    disciplineDictionary,
    colorsDictionary,
    breedDictionary,
    markings,
    colors,
    breeds,
    disciplines,
    associations,
  } = props;

  const {
    actions: {getItems: getBreeds},
  } = breedDictionary;
  const {
    actions: {getItems: getDisciplines},
  } = disciplineDictionary;
  const {
    actions: {getItems: getMarkings},
  } = markingDictionary;
  const {
    actions: {getItems: getColors},
  } = colorsDictionary;
  const {
    actions: {getItems: getAssociations},
  } = associationDictionary;
  const {
    actions: {getItems: getHealthIssues},
  } = inconclusiveHealthIssueDictionary;

  useEffect(() => {
    getHorseDetails(horseId);
    getHorseMedia(horseId);
    getHorseHealthIssues(horseId);
    getHorseOwner(horseId);
    getHorseParentage(horseId);
    getHorseRegistries(horseId);

    return () => {
      resetHorseDetails();
      resetHorseMedia();
      resetHorseHealthIssues();
      resetHorseOwner();
      resetHorseParentage();
      resetHorseRegistries();
    };
  }, [
    getHorseDetails,
    getHorseHealthIssues,
    getHorseMedia,
    getHorseOwner,
    getHorseParentage,
    getHorseRegistries,
    horseId,
    resetHorseDetails,
    resetHorseHealthIssues,
    resetHorseMedia,
    resetHorseOwner,
    resetHorseParentage,
    resetHorseRegistries,
  ]);

  useEffect(() => {
    getBreeds();
    getDisciplines();
    getMarkings();
    getColors();
    getAssociations();
    getHealthIssues();
  }, [getBreeds, getColors, getDisciplines, getMarkings, getAssociations, getHealthIssues]);

  const isRequesting =
    horseDetailsLoading.isRequesting ||
    horseOwnerLoading.isRequesting ||
    horseParentageLoading.isRequesting ||
    horseRegistriesLoading.isRequesting ||
    horseHealthIssuesLoading.isRequesting ||
    horseDetailsUpdating.isRequesting ||
    horseParentageUpdating.isRequesting ||
    horseRegistriesUpdating.isRequesting ||
    horseHealthIssuesUpdating.isRequesting ||
    horseMediaLoading.isRequesting ||
    horseMediaDeleting.isRequesting ||
    setHorseOwnerRequesting.isRequesting ||
    horseImageMediaCreating.isRequesting ||
    horseVideoMediaCreating.isRequesting ||
    horseAvatarUpdating.isRequesting ||
    horseAvatarDeleting.isRequesting ||
    dictionariesLoading;

  useErrorCommunicationToToast(horseDetailsLoading);
  useErrorCommunicationToToast(horseOwnerLoading);
  useErrorCommunicationToToast(horseParentageLoading);
  useErrorCommunicationToToast(horseRegistriesLoading);
  useErrorCommunicationToToast(horseHealthIssuesLoading);
  useErrorCommunicationToToast(horseDetailsUpdating);
  useErrorCommunicationToToast(horseParentageUpdating);
  useErrorCommunicationToToast(horseRegistriesUpdating);
  useErrorCommunicationToToast(horseHealthIssuesUpdating);
  useErrorCommunicationToToast(horseMediaLoading);
  useErrorCommunicationToToast(horseMediaDeleting);
  useErrorCommunicationToToast(setHorseOwnerRequesting);
  useErrorCommunicationToToast(horseImageMediaCreating);
  useErrorCommunicationToToast(horseVideoMediaCreating);
  useErrorCommunicationToToast(horseAvatarUpdating);
  useErrorCommunicationToToast(horseAvatarDeleting);

  const userSearchFieldAction = useCallback(
    (filter: string) => {
      return BusinessPortalOwnerService.findOwner(filter, values.organizationId);
    },
    [values.organizationId]
  );

  const isEmptyOrganization = useMemo(
    () => !values.organizationId || values.organizationId === 0,
    [values.organizationId]
  );

  useEffect(() => {
    if (isEmptyOrganization) {
      setFieldValue('owner', undefined);
      setFieldValue('ownerId', 0);
    }
  }, [setFieldValue, isEmptyOrganization]);

  return (
    <Form>
      <ModalWindowHeader>Edit horse</ModalWindowHeader>
      {isRequesting && <Loading />}
      <ModalWindowFormContent>
        {horseDetails?.simpleOrganization && (
          <OwnerSection>
            <Section>Organization</Section>
            <OrganizationSearchField
              name="organizationId"
              label=""
              isRequired
              defaultValue={{
                value: horseDetails.simpleOrganization.id,
                label: horseDetails.simpleOrganization.name,
              }}
            />
          </OwnerSection>
        )}

        <Nebula style={{opacity: 0.4}} active={isEmptyOrganization}>
          {horseOwner && (
            <OwnerSection>
              <Section>Owner</Section>
              <UserSearchField
                name="ownerId"
                label=""
                action={userSearchFieldAction}
                isRequired
                defaultValue={
                  values.owner
                    ? {
                        ...defaultUserOptionProps,
                        avatar: values.owner.avatar,
                        id: values.owner.id || 0,
                        value: values.owner.id || 0,
                        firstName: convertUserName(values.owner.firstName, values.owner.lastName),
                      }
                    : undefined
                }
              />
            </OwnerSection>
          )}
        </Nebula>

        <Section>Horse info</Section>
        <InputField
          isRequired={true}
          name="name"
          type="text"
          label="Name"
          placeholder="Horse name"
          autoComplete="off"
          memoized={true}
        />
        <SelectField
          isRequired={true}
          name="gender"
          label="Sex"
          options={getStringKeysOption(Gender)}
          memoized={true}
        />
        <InputField
          name="registryNumber"
          type="text"
          label="Registry number"
          placeholder="Registry number"
          autoComplete="off"
          memoized={true}
        />
        <DateTimeField name="dateOfBirth" label="Date of birth" memoized={true} />
        <HorseHeightField
          heightName="height.height"
          heightPercentWidth={70}
          heightUnitName="height.heightUnit"
          heightUnitPercentWidth={30}
        />
        <SelectField
          isMulti={true}
          name="disciplines"
          label="Discipline"
          options={castToOption(disciplines)}
          memoized={true}
        />
        <SelectField
          isMulti={true}
          name="breeds"
          label="Breeds"
          options={castToOption(breeds)}
          memoized={true}
          isRequired={true}
        />
        <SelectField isMulti={true} name="colors" label="Colors" options={castToOption(colors)} memoized={true} />
        <SelectField isMulti={true} name="markings" label="Markings" options={castToOption(markings)} memoized={true} />
        <HorseMapField />

        <RegistriesListWrapper>
          <Section>Other registries</Section>
          <RegistriesList fieldName="registries" registries={values.registries} associations={associations} />
        </RegistriesListWrapper>
        <InputField
          name="microchipNumber"
          type="text"
          label="Microchip number"
          placeholder="Microchip number"
          autoComplete="off"
          memoized={true}
        />
        <InputField
          name="passportNumber"
          type="text"
          label="Passport number"
          placeholder="Passport number"
          autoComplete="off"
          memoized={true}
        />

        <Section>Parentage</Section>
        <ParentRole>Sire</ParentRole>
        <InputField name={`parents[0].name`} label="Name" placeholder="Name" memoized={true} />
        <RegistriesList
          fieldName={`parents[0].registries`}
          registries={values.parents[0].registries}
          associations={associations}
        />
        <Divider />

        <ParentRole>Dam</ParentRole>
        <InputField name={`parents[1].name`} label="Name" placeholder="Name" memoized={true} />
        <RegistriesList
          fieldName={`parents[1].registries`}
          registries={values.parents[1].registries}
          associations={associations}
        />
        <Divider />

        <Section>Media</Section>
        <div className="d-flex">
          <Avatar>
            <ImageUploaderField
              name="avatar"
              title="Add avatar"
              iconProps={galleryIconProps}
              memoized={true}
              showDownloadButton={true}
            />
          </Avatar>
          <GalleryField
            isEditMode={true}
            name="gallery"
            imageProps={{
              title: 'Add gallery photo',
              iconProps: galleryIconProps,
              showDownloadButton: true,
              showDeleteButton: true,
            }}
            videoProps={{title: 'Add a video', iconProps: galleryVideoIconProps, showDeleteButton: true}}
          />
        </div>

        <Section>Health info</Section>
        <CheckboxGroupField name="inconclusiveHealthIssues">
          {inconclusiveHealthIssues.map((issue1) => {
            const isChecked = !!values.inconclusiveHealthIssues?.find((issue2) => issue1.id === issue2);
            return <Checkbox key={issue1.id} name={String(issue1.id)} checked={isChecked} label={issue1.name} />;
          })}
        </CheckboxGroupField>

        <Section>Privacy</Section>
        <CheckboxField name="isPublicProfile" label="Enable diagnostic public profile" style={{marginBottom: 8}} />
        <FieldHint style={fieldHintStyles}>
          Enabling this option will allow other users to see this horse profile including the variant summary for
          diagnostic, health and coat color data. You may also share it using the link.
        </FieldHint>

        <CheckboxField
          name="isAncestryPublicProfile"
          label="Enable ancestry public profile"
          style={{marginBottom: 8}}
        />
        <FieldHint style={fieldHintStyles}>
          Ancestry profiles are public by default. Enabling this option will allow other users to see this horse's
          Ancestry profile. You may also share it by using the link.
        </FieldHint>
      </ModalWindowFormContent>
      <ModalWindowFooter>
        <PrimaryButton size="medium" type="submit" disabled={!isValid} isLoading={isSubmitting}>
          Save changes
        </PrimaryButton>
      </ModalWindowFooter>
    </Form>
  );
};

const HorseFormCreateFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({horseDetails, horseOwner, horseRegistries, horseHealthIssues, horseParentage, horseMedia}) =>
    horseDetails && horseOwner && horseRegistries && horseHealthIssues && horseParentage && horseMedia
      ? withHorseMapFieldPropsToClient(
          convertToClient({horseDetails, horseOwner, horseRegistries, horseHealthIssues, horseParentage, horseMedia})
        )
      : initialValue,
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {
      setHorseOwner,
      updateHorseDetails,
      updateHorseRegistries,
      updateHorseParentage,
      deleteHorseMedia,
      updateHorseHealthIssues,
      createHorseImageMedia,
      createHorseVideoMedia,
      deleteHorseAvatar,
      updateHorseAvatar,
      onSuccess,
      horseMedia,
    } = formikBag.props;

    const {id, avatar, gallery} = values;

    try {
      await setHorseOwner(values.id, values.ownerId);
      await updateHorseDetails(withHorseMapFieldPropsToRequest(convertToServer(values)));
      await updateHorseRegistries({horseId: values.id, registries: values.registries});
      await updateHorseParentage({horseId: values.id, parents: values.parents});
      await updateHorseHealthIssues({horseId: values.id, inconclusiveHealthIssues: values.inconclusiveHealthIssues});

      const uploadMedia = async (horseId: number, media: IMediaResource) => {
        if (media.type === MediaType.Image) {
          if (media.isLocal && media.file) {
            await createHorseImageMedia(horseId, [media]);
          }
        }
        if (media.type === MediaType.Video) {
          await createHorseVideoMedia(horseId, media);
        }
      };

      try {
        const currentAvatar = avatar;

        if (!currentAvatar) {
          await deleteHorseAvatar(id);
        }

        if (currentAvatar?.isLocal && currentAvatar?.file) {
          await updateHorseAvatar(id, currentAvatar.file);
        }
      } catch (e) {
        formikBag.setFieldError('avatar', retrieveImageError(e));
        return;
      }

      const initialGallery = horseMedia;

      // Upload media item
      try {
        for (const media of gallery || []) {
          if (!initialGallery?.some((x) => x.id === media.id)) {
            await uploadMedia(id, media);
          }
        }

        // Delete media item
        if (initialGallery && initialGallery.length > 0) {
          await deleteImagesFromGallery(id, initialGallery, gallery || [], deleteHorseMedia);
        }
      } catch (e) {
        formikBag.setFieldError('gallery', retrieveImageError(e));
        return;
      }
    } catch (err) {
      return err;
    }

    onSuccess && onSuccess();
  },
  enableReinitialize: true,
})(HorseFormCreate);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {
    colorsDictionary,
    breedDictionary,
    disciplineDictionary,
    markingDictionary,
    associationDictionary,
    inconclusiveHealthIssueDictionary,
  } = props;
  const {selectors: horseColorsSelectors} = colorsDictionary;
  const {selectors: breedSelectors} = breedDictionary;
  const {selectors: disciplineSelectors} = disciplineDictionary;
  const {selectors: markingSelectors} = markingDictionary;
  const {selectors: associationSelectors} = associationDictionary;
  const {selectors: inconclusiveHealthIssueSelectors} = inconclusiveHealthIssueDictionary;

  return {
    horseDetails: adminHorseSelectors.selectHorseDetails(state),
    horseOwner: adminHorseSelectors.selectHorseOwner(state),
    horseMedia: adminHorseSelectors.selectHorseMedia(state),
    horseHealthIssues: adminHorseSelectors.selectHorseHealthIssues(state),
    horseParentage: adminHorseSelectors.selectHorseParentage(state),
    horseRegistries: adminHorseSelectors.selectHorseRegistries(state),
    horseDetailsLoading: adminHorseSelectors.selectCommunication(state, 'horseDetailsLoading'),
    horseOwnerLoading: adminHorseSelectors.selectCommunication(state, 'horseOwnerLoading'),
    horseParentageLoading: adminHorseSelectors.selectCommunication(state, 'horseParentageLoading'),
    horseRegistriesLoading: adminHorseSelectors.selectCommunication(state, 'horseRegistriesLoading'),
    horseHealthIssuesLoading: adminHorseSelectors.selectCommunication(state, 'horseHealthIssuesLoading'),
    horseDetailsUpdating: adminHorseSelectors.selectCommunication(state, 'horseDetailsUpdating'),
    horseParentageUpdating: adminHorseSelectors.selectCommunication(state, 'horseParentageUpdating'),
    horseRegistriesUpdating: adminHorseSelectors.selectCommunication(state, 'horseRegistriesUpdating'),
    horseHealthIssuesUpdating: adminHorseSelectors.selectCommunication(state, 'horseHealthIssuesUpdating'),
    horseMediaLoading: adminHorseSelectors.selectCommunication(state, 'horseMediaLoading'),
    horseMediaDeleting: adminHorseSelectors.selectCommunication(state, 'horseMediaDeleting'),
    setHorseOwnerRequesting: adminHorseSelectors.selectCommunication(state, 'setHorseOwnerRequesting'),
    horseImageMediaCreating: adminHorseSelectors.selectCommunication(state, 'horseImageMediaCreating'),
    horseVideoMediaCreating: adminHorseSelectors.selectCommunication(state, 'horseVideoMediaCreating'),
    horseAvatarUpdating: adminHorseSelectors.selectCommunication(state, 'horseAvatarUpdating'),
    horseAvatarDeleting: adminHorseSelectors.selectCommunication(state, 'horseAvatarDeleting'),
    colors: horseColorsSelectors.selectItems(state),
    breeds: breedSelectors.selectItems(state),
    disciplines: disciplineSelectors.selectItems(state),
    markings: markingSelectors.selectItems(state),
    associations: associationSelectors.selectItems(state),
    inconclusiveHealthIssues: inconclusiveHealthIssueSelectors.selectItems(state),
    dictionariesLoading: [
      horseColorsSelectors,
      breedSelectors,
      disciplineSelectors,
      markingSelectors,
      associationSelectors,
      inconclusiveHealthIssueSelectors,
    ].some((i) => i.selectCommunication(state, 'itemsLoading').isRequesting),
  };
};

const mapDispatchToProps = {
  getHorseDetails: adminHorseActions.getHorseDetails,
  getHorseOwner: adminHorseActions.getHorseOwner,
  getHorseParentage: adminHorseActions.getHorseParentage,
  getHorseRegistries: adminHorseActions.getHorseRegistries,
  getHorseHealthIssues: adminHorseActions.getHorseHealthIssues,
  getHorseMedia: adminHorseActions.getHorseMedia,
  setHorseOwner: adminHorseActions.setHorseOwner,
  updateHorseDetails: adminHorseActions.updateHorseDetails,
  updateHorseRegistries: adminHorseActions.updateHorseRegistries,
  updateHorseParentage: adminHorseActions.updateHorseParentage,
  updateHorseHealthIssues: adminHorseActions.updateHorseHealthIssues,
  createHorseImageMedia: adminHorseActions.createHorseImageMedia,
  createHorseVideoMedia: adminHorseActions.createHorseVideoMedia,
  updateHorseAvatar: adminHorseActions.updateHorseAvatar,
  deleteHorseAvatar: adminHorseActions.deleteHorseAvatar,
  deleteHorseMedia: adminHorseActions.deleteHorseMedia,
  resetHorseDetails: adminHorseActions.resetHorseDetails,
  resetHorseMedia: adminHorseActions.resetHorseMedia,
  resetHorseHealthIssues: adminHorseActions.resetHorseHealthIssues,
  resetHorseOwner: adminHorseActions.resetHorseOwner,
  resetHorseParentage: adminHorseActions.resetHorseParentage,
  resetHorseRegistries: adminHorseActions.resetHorseRegistries,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(HorseFormCreateFormik);
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {horseColors, breeds, disciplines, markings, associations, inconclusiveHealthIssue} = useDictionaries();

  return (
    <Connected
      colorsDictionary={horseColors}
      breedDictionary={breeds}
      disciplineDictionary={disciplines}
      markingDictionary={markings}
      associationDictionary={associations}
      inconclusiveHealthIssueDictionary={inconclusiveHealthIssue}
      {...externalProps}
    />
  );
};

export default withDynamicModules(memo(Exported), [
  BusinessPortalOwnerModule,
  AdminAssociationHorseModule,
  AdminAssociationOwnerModule,
]);
