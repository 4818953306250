import React, {memo, useEffect} from 'react';
import {useFormikContext} from 'formik';

import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import {CheckboxGroupField} from 'Common/components/FormFields';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import Checkbox from 'Common/components/Controls/Checkbox';
import {Gender} from 'Common/constants/Gender';
import Tooltip from 'Common/components/Tooltip/Tooltip';

interface IProps {
  commercialTypes: ICommercialType[];
  values?: number[];
  selectedGender: Gender | null;
}

function Commerciality(props: IProps) {
  const {commercialTypes, selectedGender, values} = props;

  const {setFieldValue} = useFormikContext();

  useEffect(() => {
    const newDisabled: number[] = [];

    commercialTypes.forEach((item) => {
      const isDisabled = !item.genders.some((x) => x === selectedGender) && item.genders.length > 0;
      if (isDisabled) {
        newDisabled.push(item.id);
      }
    });

    setFieldValue('disabledCommercialTypes', newDisabled);
  }, [commercialTypes, selectedGender, setFieldValue]);

  return (
    <FormControlContainer title="Breeding/Sales">
      <CheckboxGroupField name="commercialTypes" showSelectAll={true}>
        {commercialTypes?.map((item) => {
          const isDisabled = !item.genders.some((x) => x === selectedGender) && item.genders.length > 0;
          const isChecked = !!values?.find((value) => value === item.id) && !isDisabled;

          return (
            <Checkbox
              key={item.id}
              name={String(item.id)}
              checked={isChecked}
              disabled={isDisabled}
              label={
                <Tooltip content={item.genders.length > 0 ? `Applies to ${item.genders.join(', ')}` : ''}>
                  <div>{item.name}</div>
                </Tooltip>
              }
            />
          );
        })}
      </CheckboxGroupField>
    </FormControlContainer>
  );
}

export default memo(Commerciality);
