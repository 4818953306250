import {Reducer} from 'redux';

import {
  Actions,
  deleteMessageQueueActionTypes,
  IUserToAdminHubState,
  receiveAdminToUserMessageActionTypes,
  receiveUserToAdminMessageActionTypes,
  resetUserToAdminSendingMessageActionTypes,
  sendAdminToUserMessageActionTypes,
  sendUserToAdminMessageActionTypes,
  setConnectedAdminAtionTypes,
  setConnectedUserAtionTypes,
  setDisconnectedAdminActionTypes,
  setDisconnectedUserActionTypes,
  setSentAdminToUserMessageActionTypes,
  setSentUserToAdminMessageActionTypes,
  resetAdminToUserSendingMessageActionTypes,
  sendUserToAdminMessageReadRequestActionTypes,
  resetUserToAdminMessageReadRequestActionTypes,
  sendAdminToUserMessageReadRequestActionTypes,
  resetAdminToUserMessageReadRequestActionTypes,
  getAdminToUserMessageReadActionTypes,
  getUserToAdminMessageReadActionTypes,
  resetUserToAdminMessageReadActionTypes,
  resetAdminToUserMessageReadActionTypes,
  setConnectionIdActionTypes,
} from '../types';

const initialState: IUserToAdminHubState['data'] = {
  connectionId: '',
  connectedUsers: [],
  isAdminConnected: null,
  messageQueue: [],
  userSendingMessage: null,
  adminSendingMessage: null,
  userMessageReadRequest: null,
  adminMessageReadRequest: null,
  adminMessageRead: null,
  userMessageRead: null,
};

export const dataUserToAdminReducer: Reducer<IUserToAdminHubState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case setConnectionIdActionTypes.SUCCESS: {
      return {...state, connectionId: action.payload};
    }
    case setConnectedUserAtionTypes.SUCCESS: {
      const newConnectedUsers = [...state.connectedUsers];
      const newUserId = action.payload;
      const foundUser = newConnectedUsers.findIndex((x) => x === newUserId);
      if (foundUser === -1) {
        newConnectedUsers.push(newUserId);
      }
      return {...state, connectedUsers: newConnectedUsers};
    }
    case setDisconnectedUserActionTypes.SUCCESS: {
      const newConnectedUsers = [...state.connectedUsers];
      const newUserId = action.payload;
      const foundUser = newConnectedUsers.findIndex((x) => x === newUserId);
      if (foundUser !== -1) {
        newConnectedUsers.splice(foundUser, 1);
      }
      return {...state, connectedUsers: newConnectedUsers};
    }
    case setConnectedAdminAtionTypes.SUCCESS: {
      return {...state, isAdminConnected: true};
    }
    case setDisconnectedAdminActionTypes.SUCCESS: {
      return {...state, isAdminConnected: false};
    }
    case receiveUserToAdminMessageActionTypes.SUCCESS: {
      return {...state, messageQueue: [...state.messageQueue, action.payload]};
    }
    case receiveAdminToUserMessageActionTypes.SUCCESS: {
      return {...state, messageQueue: [...state.messageQueue, action.payload]};
    }
    case setSentUserToAdminMessageActionTypes.SUCCESS: {
      return {...state, messageQueue: [...state.messageQueue, action.payload]};
    }
    case setSentAdminToUserMessageActionTypes.SUCCESS: {
      return {...state, messageQueue: [...state.messageQueue, action.payload]};
    }
    case sendUserToAdminMessageActionTypes.SUCCESS: {
      return {...state, userSendingMessage: action.payload};
    }
    case sendAdminToUserMessageActionTypes.SUCCESS: {
      return {...state, adminSendingMessage: action.payload};
    }
    case deleteMessageQueueActionTypes.SUCCESS: {
      const newMessageQueue = [...state.messageQueue];
      const {userId} = action.payload;

      const filtered = newMessageQueue.filter(
        (x) => !((x.fromUserId === userId && x.toUserId === null) || (x.fromUserId === null && x.toUserId === userId))
      );

      return {...state, messageQueue: filtered};
    }
    case sendUserToAdminMessageReadRequestActionTypes.SUCCESS: {
      return {...state, userMessageReadRequest: action.payload};
    }
    case sendAdminToUserMessageReadRequestActionTypes.SUCCESS: {
      return {...state, adminMessageReadRequest: action.payload};
    }
    case getUserToAdminMessageReadActionTypes.SUCCESS: {
      return {...state, userMessageRead: action.payload};
    }
    case getAdminToUserMessageReadActionTypes.SUCCESS: {
      return {...state, adminMessageRead: action.payload};
    }

    case resetUserToAdminSendingMessageActionTypes.RESET: {
      return {...state, userSendingMessage: null};
    }
    case resetAdminToUserSendingMessageActionTypes.RESET: {
      return {...state, adminSendingMessage: null};
    }
    case resetUserToAdminMessageReadRequestActionTypes.RESET: {
      return {...state, adminMessageReadRequest: null};
    }
    case resetAdminToUserMessageReadRequestActionTypes.RESET: {
      return {...state, userMessageReadRequest: null};
    }
    case resetUserToAdminMessageReadActionTypes.RESET: {
      return {...state, userMessageRead: null};
    }
    case resetAdminToUserMessageReadActionTypes.RESET: {
      return {...state, adminMessageRead: null};
    }
    default: {
      return state;
    }
  }
};
