import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IActivationCode} from 'ActivationCode/models/IActivationCode';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {
  convertActivationCodeToActivationCodeCreateRequest,
  convertActivationCodeToClient,
  convertActivationCodeToRequest,
} from './converters/activationCode';
import {
  IActivationCodeActivateRequest,
  IActivationCodeRequest,
} from 'Admin/AdminDashboard/components/ActivationCodes/ActivationCodeForm/validation';

const getActivationCodes = async (params?: IRequestParams): Promise<IListResponse<IActivationCode>> => {
  const response = await axiosWrapper.get<IListResponse<IActivationCode>>(`/activation-codes-admin`, {params});
  return {
    ...response.data,
    data: response.data.data.map(convertActivationCodeToClient),
  };
};

const getActivationCode = async (id: number): Promise<IActivationCode> => {
  const response = await axiosWrapper.get<IObjectResponse<IActivationCode>>(`/activation-codes-admin/${id}`);
  return convertActivationCodeToClient(response.data.data);
};

const checkActivationCode = async (code: string): Promise<IActivationCode> => {
  const response = await axiosWrapper.post<IObjectResponse<IActivationCode>>(`/activation-codes-admin/validate`, {
    code,
  });
  return convertActivationCodeToClient(response.data.data);
};

const createActivationCodes = async (activationCode: IActivationCodeRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(
    `/activation-codes-admin`,
    convertActivationCodeToActivationCodeCreateRequest(activationCode)
  );
};

const updateActivationCode = async (activationCode: IActivationCodeRequest): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    `/activation-codes-admin/${activationCode.id}`,
    convertActivationCodeToRequest(activationCode)
  );
};

const deactivateActivationCode = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/activation-codes-admin/${id}`, {data: {id}});
};

const activateActivationCode = async (activationCode: IActivationCodeActivateRequest): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/activation-codes-admin`, {
    activationCodeId: activationCode.activationCodeId,
    animalId: activationCode.animalId,
  });
};

export default {
  getActivationCodes,
  getActivationCode,
  checkActivationCode,
  createActivationCodes,
  updateActivationCode,
  deactivateActivationCode,
  activateActivationCode,
};
