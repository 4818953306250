export enum FilterType {
  Boolean = 'Boolean',
  Integer = 'Integer',
  String = 'String',
  Enumeration = 'Enumeration',
  ItemList = 'ItemList',
  NullableItemList = 'NullableItemList',
  Item = 'Item',
  NullableItem = 'NullableItem',
  Date = 'Date',
}
