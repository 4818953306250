import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  AdminInbreedingCoefficientsActions,
  IAdminInbreedingCoefficientsState,
  uploadInbreedingCoefficientsActionTypes,
} from '../types';

export const communicationInbreedingCoefficientsReducer = combineReducers<
  IAdminInbreedingCoefficientsState['communications'],
  AdminInbreedingCoefficientsActions
>({
  inbreedingCoefficientsUploading: makeCommunicationReducerFromEnum(uploadInbreedingCoefficientsActionTypes),
});
