import {Reducer} from 'redux';

import {
  IAdminSupportState,
  Actions,
  getUserToAdminChatsActionTypes,
  getUserToAdminChatsFiltersActionTypes,
  getUserToAdminChatMessagesActionTypes,
  getUserToAdminChatDetailsActionTypes,
  resetAdminToUserChatDetailsActionTypes,
} from '../types';

const initialState: IAdminSupportState['data'] = {
  userToAdminChats: [],
  userToAdminChatsFilters: [],
  userToAdminChatMessages: null,
  userToAdminChatDetails: null,
};

export const dataAdminSupportReducer: Reducer<IAdminSupportState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case getUserToAdminChatsActionTypes.SUCCESS: {
      return {...state, userToAdminChats: action.payload.data};
    }
    case getUserToAdminChatsFiltersActionTypes.SUCCESS: {
      return {...state, userToAdminChatsFilters: action.payload};
    }
    case getUserToAdminChatMessagesActionTypes.SUCCESS: {
      return {...state, userToAdminChatMessages: action.payload};
    }
    case getUserToAdminChatDetailsActionTypes.SUCCESS: {
      return {...state, userToAdminChatDetails: action.payload};
    }

    case resetAdminToUserChatDetailsActionTypes.RESET: {
      return {...state, userToAdminChatDetails: null};
    }

    default: {
      return state;
    }
  }
};
