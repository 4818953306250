import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';

const uploadColorImage = async (uploadedFile: FormData): Promise<IObjectResponse<number>> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(`/ColorAdmin/Image`, uploadedFile);
  return response.data;
};

export default {
  uploadColorImage,
};
