import {HeightUnits} from 'HorseProfile/constants/HeightUnits';
import {
  IEditHorseFields,
  IHorseEditFormFields,
  initialHorse,
} from 'HorseProfile/components/HorseProfileForm/HorseProfileFormInnerValidation';
import {ProfileType} from 'Common/constants/ProfileType';
import {IRegistry} from 'Horse/models/IRegistry';

import {initialParentage} from '../constants/initialParentage';
import {withHorseMapFieldPropsToClient} from 'Common/components/FormFields/HorseMapField/withHorseMapFieldProps';

function convertRegistries(registries: IRegistry[]) {
  return registries.map((item) => {
    return {
      associationId: item.association?.id || null,
      registrationNumber: item.registrationNumber,
    };
  });
}

export default function getHorseEditFields(horse: IEditHorseFields): IHorseEditFormFields {
  const convertedParentage: IHorseEditFormFields['parentage'] = horse.parentage?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.parent.toLowerCase()]: {...curr, registries: curr.registries ? convertRegistries(curr.registries) : []},
    }),
    initialParentage
  );
  const convertedRegistries: IHorseEditFormFields['registries'] = horse.registries
    ? convertRegistries(horse.registries)
    : [];

  return {
    ...withHorseMapFieldPropsToClient(horse),
    name: horse.name,
    dateOfBirth: horse.dateOfBirth,
    avatar: horse.avatar,
    breeds: horse.breeds?.map((item) => item.id) || [],
    colors: horse.colors?.map((item) => item.id),
    comments: horse.comments || '',
    commercialTypes: horse.commercialTypes?.map((item) => item.id),
    disciplines: horse.disciplines?.map((item) => item.id),
    gender: horse.gender,
    healthProfile: horse.healthProfile?.map((item) => item.id),
    markings: horse.markings?.map((item) => item.id),
    parentage: convertedParentage,
    registries: convertedRegistries,
    temperament: horse.temperament || 0,
    height: horse.height || 0,
    heightUnit: horse.heightUnits || HeightUnits.Hands,
    isPublicProfile: horse.profileType === ProfileType.Public,
    microchipNumber: horse.microchipNumber,
    passportNumber: horse.passportNumber,
    location: horse.location || initialHorse.location,
    address: horse.address || initialHorse.address,
    preclinicalNotes: horse.preclinicalNotes,
    isAncestryPublicProfile: horse.ancestryProfileType === ProfileType.Public,
  };
}
