import {Reducer} from 'redux';

import {
  ActivationCodesActions,
  getActivationCodeByIdActionTypes,
  getActivationCodesActionTypes,
  IAdminActivationCodeState,
  resetActivationCodeDetailsActionTypes,
} from 'Admin/AdminDashboard/store/adminActivationCodes/types';

const initialState: IAdminActivationCodeState['data'] = {
  activationCodes: [],
  activationCodeDetail: null,
};

export const dataActivationCodesReducer: Reducer<IAdminActivationCodeState['data'], ActivationCodesActions> = (
  state = initialState,
  action: ActivationCodesActions
) => {
  switch (action.type) {
    case getActivationCodesActionTypes.SUCCESS: {
      return {...state, activationCodes: action.payload.data};
    }
    case getActivationCodeByIdActionTypes.REQUEST: {
      return {...state, activationCodeDetail: null};
    }
    case getActivationCodeByIdActionTypes.SUCCESS: {
      return {...state, activationCodeDetail: action.payload};
    }
    case resetActivationCodeDetailsActionTypes.RESET: {
      return {...state, activationCodeDetail: initialState.activationCodeDetail};
    }
    default: {
      return state;
    }
  }
};
