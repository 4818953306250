import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getEnvParams} from 'Common/helpers/getEnvParams';
import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import {IAppState} from 'Common/store/IAppState';
import {Hubs} from 'SignalR/const/Hubs';
import {DEFAULT_LOG_LEVEL} from 'SignalR/const/LogLevel';
import {getAccessToken} from 'SignalR/helpers/getAccessToken';
import {parseHubError} from 'SignalR/helpers/parseHubError';
import {signalRLogger} from 'SignalR/helpers/signalRLogger';
import {actions} from 'SignalR/store/userToAdmin';

export function useSignalRUserToAdminChat() {
  const [connection, setConnection] = useState<HubConnection>();
  const [isConnectionInitialized, setIsConnectionInitialized] = useState<boolean>(false);
  const [connectionState, setConnectionState] = useState<HubConnectionState>(HubConnectionState.Disconnected);

  const {baseUrl, signalRLogLevel} = getEnvParams();

  const {
    resetAdminToUserSendingMessage,
    resetUserToAdminSendingMessage,
    resetUserToAdminMessageReadRequest,
    resetAdminToUserMessageReadRequest,
  } = useBindActions({
    resetUserToAdminSendingMessage: actions.resetUserToAdminSendingMessage,
    resetAdminToUserSendingMessage: actions.resetAdminToUserSendingMessage,
    resetAdminToUserMessageReadRequest: actions.resetAdminToUserMessageReadRequest,
    resetUserToAdminMessageReadRequest: actions.resetUserToAdminMessageReadRequest,
  });

  const userSendingMessage = useSelector((state: IAppState) => state.userToAdminHub.data.userSendingMessage);
  const adminSendingMessage = useSelector((state: IAppState) => state.userToAdminHub.data.adminSendingMessage);
  const adminMessageReadRequest = useSelector((state: IAppState) => state.userToAdminHub.data.adminMessageReadRequest);
  const userMessageReadRequest = useSelector((state: IAppState) => state.userToAdminHub.data.userMessageReadRequest);

  useEffect(() => {
    if (!userSendingMessage) {
      return;
    }

    const dataToSend = {...userSendingMessage, imageIds: userSendingMessage.imageIds || []};

    if (connection && connectionState) {
      connection
        .invoke('SendUserMessage', dataToSend)
        .then(() => {
          signalRLogger('Send user', dataToSend);
        })
        .catch(parseHubError)
        .finally(() => resetUserToAdminSendingMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSendingMessage]);

  useEffect(() => {
    if (!adminSendingMessage) {
      return;
    }

    if (connection && connectionState) {
      connection
        .invoke('SendAdminMessage', adminSendingMessage)
        .then((x) => {
          signalRLogger('Send admin', adminSendingMessage);
        })
        .catch(parseHubError)
        .finally(() => resetAdminToUserSendingMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminSendingMessage]);

  useEffect(() => {
    if (!adminMessageReadRequest) {
      return;
    }

    if (connection && connectionState) {
      connection
        .invoke('ReadAdminMessage', adminMessageReadRequest)
        .then((x) => {
          signalRLogger('Set admin messages as read', adminMessageReadRequest);
        })
        .catch(parseHubError)
        .finally(() => resetUserToAdminMessageReadRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminMessageReadRequest]);

  useEffect(() => {
    if (!userMessageReadRequest) {
      return;
    }

    if (connection && connectionState) {
      connection
        .invoke('ReadUserMessage', userMessageReadRequest)
        .then((x) => {
          signalRLogger('Set user messages as read', userMessageReadRequest);
        })
        .catch(parseHubError)
        .finally(() => resetAdminToUserMessageReadRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMessageReadRequest]);

  const initConnection = useCallback(
    async (hub: Hubs) => {
      const logLevel = LogLevel[signalRLogLevel] !== undefined ? LogLevel[signalRLogLevel] : DEFAULT_LOG_LEVEL;

      const newConnection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}${hub}`, {
          accessTokenFactory: getAccessToken,
        })
        .configureLogging(logLevel)
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
      setIsConnectionInitialized(true);
    },
    [baseUrl, signalRLogLevel]
  );

  const startConnection = useCallback(async () => {
    if (connectionState === HubConnectionState.Disconnected && connection) {
      try {
        setConnectionState(HubConnectionState.Connecting);
        await connection.start();
        setConnectionState(HubConnectionState.Connected);
        signalRLogger('userToAdminChat connection started. id', connection.connectionId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection, connectionState]);

  const closeConnection = useCallback(async () => {
    if (connection) {
      try {
        signalRLogger('userToAdminChat connection closed. id', connection.connectionId);
        setConnectionState(HubConnectionState.Disconnecting);
        await connection.stop();
        setConnectionState(HubConnectionState.Disconnected);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection]);

  return {
    initConnection,
    isConnectionInitialized,
    connectionState,
    startConnection,
    closeConnection,
  };
}
