import {IModule} from 'redux-dynamic-modules';
import {IContactRequestState} from './types';
import {contactRequestReducer} from './reducer/index';

export interface IContactRequestModuleState {
  contactRequest: IContactRequestState;
}

export const ContactRequestModule: IModule<IContactRequestModuleState> = {
  id: 'contactRequest',
  reducerMap: {
    contactRequest: contactRequestReducer,
  },
};
