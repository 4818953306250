import React, {useRef, useEffect} from 'react';

export interface IProps {
  scroll: boolean;
  children: React.ReactNode;
  paddingTop?: number;
}

export default function ScrollTo({scroll, children, paddingTop = 0}: IProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!scroll) return;

    setTimeout(() => {
      const y = ref.current?.getBoundingClientRect().top;
      if (!y) return;

      // If the target is already visible in the viewport, don't scroll.
      if (y > 0 && y < window.innerHeight) return;

      const targetY = Math.max(window.scrollY + y - paddingTop, 0);

      window.scrollTo({top: targetY, behavior: 'smooth'});
    }, 250);
  }, [scroll]);

  return <div ref={ref}>{children}</div>;
}
