import React, {memo, useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {OrderStatus} from 'Common/constants/OrderStatus';
import OrderStatusDropdownView, {IAdditionalButton} from '../OrderStatusDropdownView';
import {useUpdatePaymentModal} from 'Admin/shared/helpers/hooks/useOrderActions/useUpdatePaymentModal';
import RequiredTestsList from 'Admin/shared/components/RequiredTestsList/RequiredTestsList';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import Loading from 'Loading/components/Loading';
import {useReleaseOrderHorseAction} from 'Admin/shared/helpers/hooks/useOrderActions/useReleaseOrderHorseAction';
import {useSetSampleStatusAction} from 'Admin/shared/helpers/hooks/useOrderActions/useSetSampleStatusAction';
import withDistributorStrategy from 'Common/helpers/strategy/withDistributorStrategy';
import {IDispatch, IState, orderStatusDropdownDistributor} from './distributor';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import {IOrderStatusUpdateDateField} from 'Order/models/IOrderStatusUpdateDateField';

interface IProps {
  status: OrderStatus;
  orderId: number;
  horseId: number;
  horseName?: string;
  ownerName?: string;
  completedStatuses?: OrderStatus | OrderStatus[];
  uncompletedStatuses?: OrderStatus | OrderStatus[];
  statusUpdateDates: IOrderStatusUpdateDateField;
  onChange(): void;
}

type IConnected = IState & IDispatch<any, any>;

type Props = IConnected & IProps;

function OrderStatusDropdown(props: Props) {
  const {
    orderId,
    horseId,
    releaseOrderHorse,
    orderHorseReleaseRequesting,
    getRequiredTests,
    requiredTestsLoading,
    onChange,
    requiredTests,
    setSampleStatus,
    status,
    completedStatuses,
    uncompletedStatuses,
    horseName,
    ownerName,
    statusUpdateDates,
  } = props;

  const navigate = useNavigate();

  const [isRequiredTestsModalOpen, setIsRequiredTestsModalOpen] = useState(false);
  const openRequiredTestsModal = useCallback(() => {
    getRequiredTests(orderId, horseId);
    setIsRequiredTestsModalOpen(true);
  }, [horseId, orderId, getRequiredTests]);
  const closeRequiredTestsModal = useCallback(() => setIsRequiredTestsModalOpen(false), []);

  const {openSampleReceivedModal, setSampleStatusModal} = useSetSampleStatusAction({
    action: setSampleStatus,
    onSuccess: onChange,
  });

  const {updatePaymentModal, openUpdatePaymentModal} = useUpdatePaymentModal({onSuccess: onChange, orderId});
  const {openReleaseOrderHorseModal, releaseOrderHorseModal} = useReleaseOrderHorseAction({
    onSuccess: onChange,
    communication: orderHorseReleaseRequesting,
    action: releaseOrderHorse,
    horseId,
    orderId,
  });

  const {currentStrategy} = useDistributorStrategy();

  const redirectToReviewOnlineReport = useCallback(() => {
    if (currentStrategy === SelectedStrategy.Admin) {
      navigate(`/review-admin/online-report/${horseId}/${orderId}/summary`);
    }
    if (currentStrategy === SelectedStrategy.AdminAssociation) {
      navigate(`/review-admin-associations/online-report/${horseId}/${orderId}/summary`);
    }
  }, [currentStrategy, navigate, horseId, orderId]);

  const onSampleStatusChange = useCallback(
    (orderId: number, horseId?: number) => {
      if (currentStrategy === SelectedStrategy.Admin) {
        return openSampleReceivedModal(orderId, horseId);
      }

      return openSampleReceivedModal(orderId);
    },
    [openSampleReceivedModal, currentStrategy]
  );

  const handleChangeStatus = useCallback(() => {
    const methods: Partial<Record<OrderStatus, () => void>> = {
      [OrderStatus.orderPlaced]: openUpdatePaymentModal,
      [OrderStatus.paymentReceived]: () => onSampleStatusChange(orderId, horseId),
      [OrderStatus.sampleReceived]: redirectToReviewOnlineReport,
      [OrderStatus.resultsReady]: redirectToReviewOnlineReport,
      [OrderStatus.reviewReady]: openReleaseOrderHorseModal,
    };
    return methods[status] && methods[status]!();
  }, [
    openUpdatePaymentModal,
    redirectToReviewOnlineReport,
    openReleaseOrderHorseModal,
    status,
    onSampleStatusChange,
    orderId,
    horseId,
  ]);

  const additionalButtons: Partial<Record<OrderStatus, IAdditionalButton>> = {
    [OrderStatus.sampleReceived]: {name: 'Check results', onClick: openRequiredTestsModal},
  };

  return (
    <div>
      {updatePaymentModal}
      {releaseOrderHorseModal}
      {setSampleStatusModal}

      <ModalWindow isOpen={isRequiredTestsModalOpen} onClose={closeRequiredTestsModal}>
        {requiredTestsLoading.isRequesting && <Loading />}
        <RequiredTestsList requiredTests={requiredTests || []} horseName={horseName} ownerName={ownerName} />
      </ModalWindow>

      <OrderStatusDropdownView
        status={status}
        onChangeOrderStatus={handleChangeStatus}
        completedStatuses={completedStatuses}
        uncompletedStatuses={uncompletedStatuses}
        additionalButtons={additionalButtons}
        statusUpdateDates={statusUpdateDates}
      />
    </div>
  );
}

export default withDistributorStrategy<IState, IDispatch<any, any>>(
  memo(OrderStatusDropdown),
  orderStatusDropdownDistributor,
  SelectedStrategy.Admin
);
