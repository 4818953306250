import {combineReducers, Reducer} from 'redux';

import {communicationDogTestResultsUploadReducer} from './communications';
import {IAdminDogTestResultsUploadState} from '../types';
import {dataDogTestResultsUploadReducer} from './data';

export const dogTestResultsUploadReducer: Reducer<IAdminDogTestResultsUploadState> =
  combineReducers<IAdminDogTestResultsUploadState>({
    communications: communicationDogTestResultsUploadReducer,
    data: dataDogTestResultsUploadReducer,
  });
