import {Reducer} from 'redux';

import {DogsActions, getDogsActionTypes, IAdminDogsState} from 'Admin/AdminDashboard/store/adminDogs/types';

const initialState: IAdminDogsState['data'] = {
  dogs: [],
};

export const dataDogsReducer: Reducer<IAdminDogsState['data'], DogsActions> = (
  state = initialState,
  action: DogsActions
) => {
  switch (action.type) {
    case getDogsActionTypes.SUCCESS: {
      return {...state, dogs: action.payload.data};
    }
    default: {
      return state;
    }
  }
};
