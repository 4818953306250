import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IShortTandemRepeatReport} from 'OnlineReport/models/STR/IShortTandemRepeatReport';

const getShortTandemRepeatReport = async (horseId: number): Promise<IShortTandemRepeatReport> => {
  const result = await axiosWrapper.get<IObjectResponse<IShortTandemRepeatReport>>(
    `/ShortTandemRepeatReport/${horseId}`
  );
  return result.data.data;
};

export default {getShortTandemRepeatReport};
