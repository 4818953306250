import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import ColorPalette from 'Common/constants/ColorPalette';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';
import {IGenotype} from 'Genotype/models/IGenotype';
import {Nullable} from 'Common/types';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import GenotypeList from 'Genotype/component/GenotypeList';
import Icon, {IconName} from 'Icon/components/Icon';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import IElementPosition, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import {VerificationStatus} from 'Common/constants/VerificationStatus';
import {GenotypeType} from 'Genotype/constants/GenotypeType';
import {GenotypeFontSize, GenotypeFontSizeByScreenSize} from 'Genotype/constants/GenotypeFontSize';
import {IGenotypeFontSize} from 'Genotype/models/IGenotypeFontSize';
import PlaceholderImage from 'Common/components/Image/Image';

const ETALON_VERIFIED = 'Etalon Tested & Verified';
const ETALON_UNVERIFIED = 'Outside Data, Other Lab, Owner Reported, Not Etalon Verified';

interface IParentsGenotypes extends Omit<IGenotypeGroup, 'genotypes'> {
  firstParentGenotypes: Nullable<IGenotype[]>;
  secondParentGenotypes: Nullable<IGenotype[]>;
}

const Root = styled.div`
  width: 100%;
  background-color: ${ColorPalette.black1};
  color: ${ColorPalette.gray15};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size15};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size20};
    line-height: 40px;
  }

  @media ${breakpoints.md} {
    font-size: ${Typography.size.size35};
  }
`;

const GenotypesContainer = styled.div<IGenotypeFontSize>`
  display: flex;
  align-items: start;

  ${({size = GenotypeFontSize.Large}) => {
    return `
    font-size: ${GenotypeFontSizeByScreenSize[size].mobile};

    @media ${breakpoints.sm} {
      font-size: ${GenotypeFontSizeByScreenSize[size].tablet};
      line-height: 40px;
    }

    @media ${breakpoints.md} {
      font-size: ${GenotypeFontSizeByScreenSize[size].desktop};
      margin-top: 16px;
    }
    `;
  }};
`;

const Genotypes = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  margin: 0 15px;
`;

const Image = styled(PlaceholderImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const VerificationWrapper = styled(Genotypes)<IElementPosition>`
  ${(prop) =>
    prop.position === ElementPosition.Left
      ? `border-right: 1px solid ${ColorPalette.black3};`
      : `border-left: 1px solid ${ColorPalette.black3};`}

  margin-top: 16px;

  ${(props) => (props.position === ElementPosition.Left ? 'padding-right: 15px;' : 'padding-left: 15px;')}

  @media ${breakpoints.sm} {
    ${(props) => (props.position === ElementPosition.Left ? 'padding-right: 45px;' : 'padding-left: 45px;')}
  }
`;

const VerificationTag = styled(Genotypes)<IElementPosition & {isEtalonVerified: boolean}>`
  height: 36px;
  width: 160px;
  max-width: 160px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.isEtalonVerified ? ColorPalette.red12 : ColorPalette.blue3)};
  color: ${(props) => (props.isEtalonVerified ? ColorPalette.white0 : ColorPalette.blue2)};
  font-family: ${Typography.family.ubuntu};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  border-radius: 8px;
  flex: 1;
  flex-wrap: wrap;
  ${(props) =>
    props.isEtalonVerified &&
    (props.position === ElementPosition.Left ? 'padding-right: 24px;' : 'padding-left: 24px;')}
  @media ${breakpoints.sm} {
    ${(props) =>
      props.isEtalonVerified &&
      (props.position === ElementPosition.Left ? 'padding-right: 12px;' : 'padding-left: 12px;')}
  }
`;

const VerificationIcon = styled(Icon)<IElementPosition>`
  top: -8px;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.6));
  ${(props) => (props.position === ElementPosition.Left ? 'right: -20px;' : 'left: -20px;')}
  @media ${breakpoints.sm} {
    ${(props) => (props.position === ElementPosition.Left ? 'right: -34px;' : 'left: -34px;')}
  }
`;

const TooltipHint = styled(Tooltip)`
  text-align: center;
`;

interface IProps {
  firstParentGenotypeGroups?: IGenotypeGroup[];
  secondParentGenotypeGroups?: IGenotypeGroup[];
}

const FoalParentsGenotypes = (props: IProps) => {
  const {firstParentGenotypeGroups, secondParentGenotypeGroups} = props;

  const [parentsGenotypeGroups, setParentsGenotypeGroups] = useState<IParentsGenotypes[]>();

  const getParentsGroup = useCallback(
    (
      firstParentGroups: IGenotypeGroup[],
      secondParentGroups: IGenotypeGroup[],
      groupName: string,
      isUniqueGroup?: boolean
    ) => {
      const group1 = firstParentGroups.find((i) => i.name === groupName);
      const group2 = secondParentGroups.find((i) => i.name === groupName);
      const newElement: IParentsGenotypes = {
        ...(isUniqueGroup ? group1 || group2 : group1)!,
        firstParentGenotypes: group1?.genotypes || null,
        secondParentGenotypes: group2?.genotypes || null,
      };

      delete newElement['genotypes'];
      return newElement;
    },
    []
  );

  const firstParentGeneticResultsAvailable = firstParentGenotypeGroups && firstParentGenotypeGroups.length > 0;

  const firstParentIsEtalon =
    firstParentGeneticResultsAvailable &&
    firstParentGenotypeGroups?.some((x) =>
      x.genotypes?.some((y) => y.verificationStatus === VerificationStatus.Verified)
    );

  const secondParentGeneticResultsAvailable = secondParentGenotypeGroups && secondParentGenotypeGroups.length > 0;

  const secondParentIsEtalon =
    secondParentGeneticResultsAvailable &&
    secondParentGenotypeGroups?.some((x) =>
      x.genotypes?.some((y) => y.verificationStatus === VerificationStatus.Verified)
    );

  useEffect(() => {
    if (firstParentGenotypeGroups && secondParentGenotypeGroups) {
      const parentsGenotypesHelper: IParentsGenotypes[] = [];

      const uniqueGenotypeGroups = R.symmetricDifference(
        firstParentGenotypeGroups.map((i) => i.name),
        secondParentGenotypeGroups.map((i) => i.name)
      );
      const intersectedGenotypeGroups = R.intersection(
        firstParentGenotypeGroups.map((i) => i.name),
        secondParentGenotypeGroups.map((i) => i.name)
      );

      uniqueGenotypeGroups.forEach((uniqueGroup) => {
        const newElement = getParentsGroup(firstParentGenotypeGroups, secondParentGenotypeGroups, uniqueGroup, true);
        parentsGenotypesHelper.push(newElement);
      });

      intersectedGenotypeGroups.forEach((intersectedGroup) => {
        const newElement = getParentsGroup(firstParentGenotypeGroups, secondParentGenotypeGroups, intersectedGroup);
        parentsGenotypesHelper.push(newElement);
      });

      setParentsGenotypeGroups(parentsGenotypesHelper.sort((a, b) => sortByStringKey(a, b, 'positionIndex')));
    }
  }, [firstParentGenotypeGroups, getParentsGroup, secondParentGenotypeGroups]);

  const etalonVerified = useCallback((isEtalonVerified: boolean, position: ElementPosition) => {
    return (
      <TooltipHint content={isEtalonVerified ? ETALON_VERIFIED : ETALON_UNVERIFIED}>
        <VerificationTag
          className="position-relative d-flex align-items-center justify-content-center"
          isEtalonVerified={isEtalonVerified}
          position={position}
        >
          {isEtalonVerified ? 'Etalon verified' : 'Unverified'}
          {isEtalonVerified && (
            <VerificationIcon
              className="position-absolute"
              name={IconName.EtalonVerified}
              size={64}
              position={position}
            />
          )}
        </VerificationTag>
      </TooltipHint>
    );
  }, []);

  return (
    <Root>
      {parentsGenotypeGroups?.map((group) => {
        const genotypeSize = group.name === GenotypeType.Color ? GenotypeFontSize.Large : GenotypeFontSize.Medium;
        return (
          <GenotypesContainer size={genotypeSize} key={group.id}>
            <Genotypes className="justify-content-end">
              <GenotypeList genotypes={group.firstParentGenotypes || []} />
            </Genotypes>
            <IconContainer className="d-flex align-self-center">
              {group.image && <Image src={group.image?.url} />}
            </IconContainer>
            <Genotypes className="justify-content-start">
              <GenotypeList genotypes={group.secondParentGenotypes || []} />
            </Genotypes>
          </GenotypesContainer>
        );
      })}

      <GenotypesContainer>
        {firstParentGeneticResultsAvailable && (
          <VerificationWrapper className="justify-content-end" position={ElementPosition.Left}>
            {etalonVerified(!!firstParentIsEtalon, ElementPosition.Left)}
          </VerificationWrapper>
        )}
        {secondParentGeneticResultsAvailable && (
          <VerificationWrapper className="justify-content-start" position={ElementPosition.Right}>
            {etalonVerified(!!secondParentIsEtalon, ElementPosition.Right)}
          </VerificationWrapper>
        )}
      </GenotypesContainer>
    </Root>
  );
};

export default memo(FoalParentsGenotypes);
