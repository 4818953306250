import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import HorseProfileForm from 'HorseProfile/components/HorseProfileForm/HorseProfileForm';
import {FormType} from 'Common/constants/FormType';
import HorseOverview from 'UserProfile/components/TabsData/common/HorseOverview';
import {Separator} from 'UserProfile/components/TabsData/common/styled';
import HorseCharacteristics from 'UserProfile/components/shared/UserHorseOverviewLayout/HorseCharacteristics/HorseCharacteristics';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Horse/store/horse/index';
import {HorseModule} from 'Horse/store/horse/horseModule';

import {dictionaryToString, StringDictionaryErrorType} from 'Common/helpers/dictionaryToString';
import {AbilityType} from 'Common/constants/AbilityType';
import {getAbilitiesByType} from 'Common/helpers/getAbilitiesByType';
import {IUserHorse} from 'Horse/models/IUserHorse';
import {useDeleteModal} from 'Common/helpers/hooks/useDeleteModal';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {VisitorType} from 'Common/constants/VisitorType';

const Root = styled.div`
  box-shadow: 0px 6px 16px ${ColorPalette.gray51}, 0px 0px 8px ${ColorPalette.gray50};
  border-radius: 4px;

  flex-direction: column;
  margin-bottom: 27px;
  padding: 16px;
  border-bottom: 1px solid ${ColorPalette.white9};

  @media ${breakpoints.sm} {
    padding-right: 0;
    box-shadow: none;
    border-radius: 0;

    flex-direction: row;
    margin-bottom: 2px;
  }
`;

const Container = styled.div`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 50%;
    min-width: 40%;
    margin-right: 20px;
  }

  @media ${mediumMdSmBreakpoint} {
    min-width: 24%;
  }
`;

const AdditionalContent = styled.div`
  flex-direction: column;

  @media ${mediumMdSmBreakpoint} {
    flex-direction: row;
  }
`;

const AdditionalItemBase = styled.div`
  width: 100%;

  @media ${breakpoints.md} {
    width: 70%;
  }
`;

const ChildrenAdditionalItem = styled(AdditionalItemBase)`
  justify-content: flex-end;

  @media ${breakpoints.sm} {
    width: 168px;
    align-self: end;
    margin-left: 90px;
  }

  @media ${breakpoints.md} {
    justify-content: flex-start;
    align-self: start;
  }
`;

interface IExternalProps {
  horse: IUserHorse;
  isOwnHorse?: boolean;
  visitorType: VisitorType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

const UserHorseOverviewLayout = (props: React.PropsWithChildren<AllProps>) => {
  const {children, visitorType} = props;
  const {horse, isOwnHorse, onSuccess, deleteHorse, horseDeleting} = props;
  const {
    id,
    breeds,
    colors,
    avatar,
    dateOfBirth,
    gender,
    name,
    height,
    heightUnits,
    abilities,
    commercialTypes,
    availabilityCriteria,
    genotypes,
  } = horse;

  const navigate = useNavigate();

  const {deleteModal, openDeleteModal} = useDeleteModal({
    onSuccess,
    deleteCommunication: horseDeleting,
    confirmDescription: 'Are you sure you want to remove the horse?',
    deleteAction: deleteHorse,
  });

  const [isEditHorseOpen, setIsEditHorseOpen] = useState(false);
  const openEditModalHorse = useCallback(() => setIsEditHorseOpen(true), []);
  const closeEditModalHorse = useCallback(() => setIsEditHorseOpen(false), []);
  const onSuccessEditHorse = useCallback(() => {
    closeEditModalHorse();
    onSuccess();
  }, [closeEditModalHorse, onSuccess]);

  const redirectToHorseProfile = useCallback(() => {
    switch (visitorType) {
      case VisitorType.Admin: {
        navigate(`/admin/horse/${id}`);
        break;
      }
      case VisitorType.User: {
        navigate(`/horse/${id}`);
        break;
      }
      case VisitorType.AssociationEmployee: {
        navigate(`/business-portal/horse/${id}`);
        break;
      }
      case VisitorType.AssociationAdmin: {
        navigate(`/admin-associations/horse/${id}`);
        break;
      }
    }
  }, [visitorType, navigate, id]);

  const speedAbilities = getAbilitiesByType(abilities, AbilityType.Speed);
  const temperamentAbilities = getAbilitiesByType(abilities, AbilityType.Temperament);
  const gaitAbilities = getAbilitiesByType(abilities, AbilityType.Gait);

  const horseOverview = {
    horseId: id,
    breed: dictionaryToString(breeds, 'Breeds', StringDictionaryErrorType.Full),
    gender,
    dateOfBirth,
    avatar,
    name,
    availabilityCriteria,
  };

  return (
    <Root id={'' + horse.id} className="d-flex w-100">
      {deleteModal}
      <ModalWindow isOpen={isEditHorseOpen} onClose={closeEditModalHorse}>
        <HorseProfileForm type={FormType.edit} horseId={id} onSuccess={onSuccessEditHorse} isAdmin={false} />
      </ModalWindow>

      <Container>
        <HorseOverview
          horse={horseOverview}
          editHandler={openEditModalHorse}
          deleteHandler={openDeleteModal}
          disableActions={!isOwnHorse}
          onClickHandler={redirectToHorseProfile}
        />
      </Container>

      <Separator />

      <AdditionalContent className="d-flex w-100 align-items-baseline">
        <AdditionalItemBase className="d-flex">
          <HorseCharacteristics
            colors={colors.map((x) => x.name)}
            height={height}
            heightUnit={heightUnits}
            temperament={temperamentAbilities[0]}
            gait={gaitAbilities[0]}
            speed={speedAbilities[0]}
            commercialTypes={commercialTypes || []}
            genotypes={genotypes}
          />
        </AdditionalItemBase>
        <ChildrenAdditionalItem className="d-flex">{children}</ChildrenAdditionalItem>
      </AdditionalContent>
    </Root>
  );
};

const mapStateToProps = (state: IAppState) => ({
  horseDeleting: selectors.selectCommunication(state, 'horseDeleting'),
});

const mapDispatchToProps = {
  deleteHorse: actions.deleteHorse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(UserHorseOverviewLayout));

export default withDynamicModules<React.PropsWithChildren<IExternalProps>>(Connected, HorseModule);

export type UserHorseOverviewProps = IExternalProps;
