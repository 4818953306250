import {Reducer} from 'redux';

import {
  IUserToUserHubState,
  Actions,
  setConnectedUserAtionTypes,
  setDisconnectedUserActionTypes,
  receiveMessageActionTypes,
  setSentMessageActionTypes,
  resetSendingMessageActionTypes,
  sendMessageActionTypes,
  deleteMessageQueueActionTypes,
} from '../types';

const initialState: IUserToUserHubState['data'] = {
  connectedUsers: [],
  messageQueue: [],
  sendingMessage: null,
};

export const dataUserToUserReducer: Reducer<IUserToUserHubState['data'], Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case setConnectedUserAtionTypes.SUCCESS: {
      const newConnectedUsers = [...state.connectedUsers];
      const newUserId = action.payload;
      const foundUser = newConnectedUsers.findIndex((x) => x === newUserId);
      if (foundUser === -1) {
        newConnectedUsers.push(newUserId);
      }
      return {...state, connectedUsers: newConnectedUsers};
    }
    case setDisconnectedUserActionTypes.SUCCESS: {
      const newConnectedUsers = [...state.connectedUsers];
      const newUserId = action.payload;
      const foundUser = newConnectedUsers.findIndex((x) => x === newUserId);
      if (foundUser !== -1) {
        newConnectedUsers.splice(foundUser, 1);
      }
      return {...state, connectedUsers: newConnectedUsers};
    }
    case receiveMessageActionTypes.SUCCESS: {
      return {...state, messageQueue: [...state.messageQueue, action.payload]};
    }
    case setSentMessageActionTypes.SUCCESS: {
      return {...state, messageQueue: [...state.messageQueue, action.payload]};
    }
    case sendMessageActionTypes.SUCCESS: {
      return {...state, sendingMessage: action.payload};
    }
    case deleteMessageQueueActionTypes.SUCCESS: {
      const newMessageQueue = [...state.messageQueue];
      const {chatUserId, currentUserId} = action.payload;

      const filtered = newMessageQueue.filter(
        (x) =>
          !(
            (x.fromUserId === chatUserId && x.toUserId === currentUserId) ||
            (x.fromUserId === currentUserId && x.toUserId === chatUserId)
          )
      );

      return {...state, messageQueue: filtered};
    }

    case resetSendingMessageActionTypes.RESET: {
      return {...state, sendingMessage: null};
    }
    default: {
      return state;
    }
  }
};
