import React, {memo} from 'react';

import {IconName} from 'Icon/components/Icon';
import ColoredIcon, {IColoredIconProps} from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';

const iconProps: IColoredIconProps = {size: 18, stroke: false, fill: true, name: IconName.DoneMark};

interface IProps {
  isRead: boolean;
}

function IsReadMessage(props: IProps) {
  const {isRead} = props;

  if (isRead) {
    return <ColoredIcon color={ColorPalette.green1} {...iconProps} />;
  }

  return <ColoredIcon color={ColorPalette.transparent4} {...iconProps} />;
}

export default memo(IsReadMessage);
