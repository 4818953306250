import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IContactRequest} from 'UserProfile/models/IContactRequest';
import {
  contactRequestActions,
  contactRequestActionTypes,
  createUsersChatRequestActions,
  createUsersChatsRequestActionTypes,
  IContactRequestState,
} from './types';
import UserService from 'UserProfile/services/UserService';
import {IAppState} from 'Common/store/IAppState';
import {IChatRequest} from 'UserProfile/models/IChatRequest';

export const sendContactRequest =
  (data: IContactRequest): ActionResult<IContactRequestState, void, contactRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(contactRequestActionTypes.REQUEST));
      await UserService.sendContactRequest(data);
      dispatch(action(contactRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(contactRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createUsersChatRequest =
  (data: IChatRequest): ActionResult<IAppState, void, createUsersChatRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createUsersChatsRequestActionTypes.REQUEST));
      await UserService.createUsersChatRequest(data);
      dispatch(action(createUsersChatsRequestActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createUsersChatsRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
