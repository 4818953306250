import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';
import {IUserToAdminChat} from '../models/IUserToAdminChat';
import {IUserToAdminChatDetailsAdmin} from '../models/IUserToAdminChatDetailsAdmin';
import {IUserToAdminChatMessageAdmin} from '../models/IUserToAdminChatMessageAdmin';
import {
  convertUserToAdminChatMessageToClient,
  convertUserToAdminChatToClient,
  convertUserToAdminDetailsToClient,
} from './converters/userToAdminChat';
import {
  IServerUserToAdminChat,
  IServerUserToAdminChatMessageAdmin,
  IServerUserToAdminChatMessageRequestAdmin,
  ISubscribeRequest,
} from './types/userToAdminChat';

const getUserToAdminChats = async (params?: IRequestParams): Promise<IListResponse<IUserToAdminChat>> => {
  const response = await axiosWrapper.post<IListResponse<IServerUserToAdminChat>>(`/UserToAdminChatsRead`, params);
  return {
    ...response.data,
    data: response.data.data.map(convertUserToAdminChatToClient),
  };
};

const getUserToAdminChatsFilters = async (): Promise<IFilterProperty[]> => {
  const response = await axiosWrapper.get<IListResponse<IFilterProperty>>(`/UserToAdminChatsTableFiltersAdmin`);
  return response.data.data;
};

const getUserToAdminChatDetails = async (userId: number): Promise<IObjectResponse<IUserToAdminChatDetailsAdmin>> => {
  const response = await axiosWrapper.get<IObjectResponse<IUserToAdminChatDetailsAdmin>>(
    `/UserToAdminChatDetailsReadAdmin/${userId}`
  );
  return {...response.data, data: convertUserToAdminDetailsToClient(response.data.data)};
};

const getUserToAdminChatMessages = async (
  params: IServerUserToAdminChatMessageRequestAdmin
): Promise<IObjectResponse<IUserToAdminChatMessageAdmin>> => {
  const response = await axiosWrapper.get<IObjectResponse<IServerUserToAdminChatMessageAdmin>>(
    `/UserToAdminChatMessagesReadAdmin`,
    {
      params,
    }
  );
  return {...response.data, data: convertUserToAdminChatMessageToClient(response.data.data)};
};

const subscribeToMessages = async (params: ISubscribeRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/UserToAdminChatSubscribeToMessages`, params);
};

const unsubscribeFromMessages = async (params: ISubscribeRequest): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/UserToAdminChatUnsubscribeFromMessages`, params);
};

const takeDuty = async (userId: number): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/UserToAdminChatTakeDuty/${userId}`);
};

const leaveDuty = async (): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<void>>(`/UserToAdminChatLeaveDuty`);
};

export default {
  getUserToAdminChats,
  getUserToAdminChatsFilters,
  getUserToAdminChatDetails,
  getUserToAdminChatMessages,
  subscribeToMessages,
  unsubscribeFromMessages,
  takeDuty,
  leaveDuty,
};
