import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {connect, ConnectedProps} from 'react-redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import styled from 'styled-components';

import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import BackButton from 'Common/components/Controls/Buttons/BackButton';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import {PageError} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import {VisitorType} from 'Common/constants/VisitorType';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {usePrevious} from 'Common/helpers/hooks/usePrevious';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {IAppState} from 'Common/store/IAppState';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import FoalProfileHeader from 'FoalProfile/components/FoalProfileHeader/FoalProfileHeader';
import {IFoalParent} from 'FoalProfile/models/IFoalParent';
import {actions, selectors} from 'FoalProfile/store/buildFoal';
import {BuildFoalModule} from 'FoalProfile/store/buildFoal/buildFoalModule';
import {selectors as userSelectors} from 'UserProfile/store/currentUser';
import FoalProfileDetails from './FoalProfileDetails/FoalProfileDetails';

const Back = styled(BackButton)`
  left: 0;
  top: 24px;
`;

interface IExternalDictionaries {
  abilityDictionary: ISimpleDictionary<IAbilities>;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalDictionaries;

const FoalProfileLayout = (props: AllProps) => {
  const {buildFoalRequest, loadingFoal, foal, abilities, abilityDictionary, currentUserId} = props;

  const {
    actions: {getItems: getAbilities},
  } = abilityDictionary;

  const [firstParent, setFirstParent] = useState<IFoalParent>();
  const [secondParent, setSecondParent] = useState<IFoalParent>();

  const setParents = useCallback((firstParent: IFoalParent, secondParent: IFoalParent) => {
    setFirstParent(firstParent);
    setSecondParent(secondParent);
  }, []);

  const previousFirstParent = usePrevious(firstParent);
  const previousSecondParent = usePrevious(secondParent);

  const isOwnFirstParent = useMemo(() => {
    return firstParent?.owner.id === currentUserId;
  }, [currentUserId, firstParent?.owner.id]);

  const isOwnSecondParent = useMemo(() => {
    return secondParent?.owner.id === currentUserId;
  }, [currentUserId, secondParent?.owner.id]);

  useEffect(() => {
    if (firstParent && secondParent && (previousFirstParent !== firstParent || previousSecondParent !== secondParent)) {
      buildFoalRequest({firstHorseId: firstParent.id, secondHorseId: secondParent.id});
      getAbilities();
    }
  }, [buildFoalRequest, firstParent, getAbilities, secondParent, previousSecondParent, previousFirstParent]);

  const {isVisitorType} = useVisitorTypeService();
  const isAssociation = isVisitorType(VisitorType.AssociationEmployee);

  const foalProfileContent = useMemo(() => {
    if (loadingFoal.isRequesting) {
      return <IconedLoading className="align-self-center" size={100} />;
    }

    if (loadingFoal.error) {
      return <PageError>{getCommonErrors(loadingFoal.error) || 'Error on loading foal'}</PageError>;
    }

    return (
      foal &&
      firstParent &&
      secondParent && (
        <FoalProfileDetails
          foal={foal}
          firstParent={firstParent}
          secondParent={secondParent}
          abilityDictionary={abilities}
          isOwnFirstParent={isOwnFirstParent}
          isOwnSecondParent={isOwnSecondParent}
        />
      )
    );
  }, [
    loadingFoal.isRequesting,
    loadingFoal.error,
    foal,
    firstParent,
    secondParent,
    abilities,
    isOwnFirstParent,
    isOwnSecondParent,
  ]);

  return isAssociation ? (
    <BusinessPortalLayout backgroundColor={ColorPalette.white0} withoutPaddings={true}>
      <Helmet>
        <title>Foal profile</title>
      </Helmet>
      <div className="position-relative">
        <Back className="position-absolute" isDarkBackground={true} />
      </div>

      <div className="position-relative d-flex flex-column">
        <FoalProfileHeader onSuccess={setParents} hasFoal={!!foal} />
        {foalProfileContent}
      </div>
    </BusinessPortalLayout>
  ) : (
    <BaseLayout withoutPaddings={true} backButtonStyle={{marginTop: 20}} isDarkBackground={true}>
      <Helmet>
        <title>Foal profile</title>
      </Helmet>

      <FoalProfileHeader onSuccess={setParents} hasFoal={!!foal} />
      {foalProfileContent}
    </BaseLayout>
  );
};

const mapStateToProps = (state: IAppState, props: IExternalDictionaries) => {
  const {abilityDictionary} = props;
  const {selectors: abilitySelectors} = abilityDictionary;

  return {
    foal: selectors.selectFoal(state),
    loadingFoal: selectors.selectCommunication(state, 'buildFoalRequesting'),
    abilities: abilitySelectors.selectItems(state),
    currentUserId: userSelectors.selectCurrentUserId(state),
  };
};

const mapDispatchToProps = {
  buildFoalRequest: actions.buildFoalRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(FoalProfileLayout));
const Exported = () => {
  const {abilities} = useDictionaries();

  return (
    <DynamicModuleLoader modules={[BuildFoalModule]}>
      <Connected abilityDictionary={abilities} />
    </DynamicModuleLoader>
  );
};

export default Exported;
