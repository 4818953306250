import {IModule} from 'redux-dynamic-modules';

import {IAdminInbreedingCoefficientsState} from './types';
import {inbreedingCoefficientsReducer} from './reducer';

export interface IAdminInbreedingCoefficientsModuleState {
  adminInbreedingCoefficients: IAdminInbreedingCoefficientsState;
}

export const AdminInbreedingCoefficientsModule: IModule<IAdminInbreedingCoefficientsModuleState> = {
  id: 'adminInbreedingCoefficients',
  reducerMap: {
    adminInbreedingCoefficients: inbreedingCoefficientsReducer,
  },
};
