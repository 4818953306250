import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import HeaderContact from 'FoalProfile/components/FoalProfileHeader/FoalParent/parts/shared/ContactBlock';
import Typography from 'Common/constants/Typography';
import {dateDiffInYearsOrMonth} from 'Common/helpers/DateHelper';
import IElementPosition, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import FoalPlaceHolder from 'Icon/foal-placeholder.svg';
import {FixImageName, isImageExists} from 'Common/helpers/ImagesHelper';
import Icon, {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {SearchHorseInput} from 'Horse/components/index';
import {IFoalParent} from 'FoalProfile/models/IFoalParent';
import usePermissions from 'Permissions/hooks/usePermissions';
import {Permission} from 'Permissions/constants/Permission';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import CommonLink from 'Common/components/Controls/Links/CommonLink';
import FavoriteHorseIconButton from 'FavoriteHorse/components/FavoriteHorseIconButton/FavoriteHorseIconButton';
import withFavoriteHorseActions, {
  getWithFavoriteHorseActionsModules,
  IWithFavoriteHorseActionsProps,
} from 'Common/helpers/withFavoriteHorseActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import BlurredImage from 'Common/components/BlurredImage/BlurredImage';
import {Nullable} from 'Common/types';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {Gender} from 'Common/constants/Gender';
import Loading from 'Loading/components/Loading';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import IFoundHorse from 'Horse/models/IFoundHorse';
import ShadowContainer from 'Common/components/Containers/ShadowContainer';

const mapGenderIconToGender: Record<Gender.Stallion | Gender.Mare, number> = {
  [Gender.Stallion]: IconName.Stallion,
  [Gender.Mare]: IconName.Mare,
};

const actionIconCommonProps = {color: ColorPalette.gray39, fill: true, stroke: false};

const getHorseheadNoCrestIconProps = (props: {isMobile: boolean; isTablet: boolean; isDesktop: boolean}) => {
  const desktopSize = {width: 18, height: 24};
  const tabletSize = {width: 16, height: 21};

  let usedSize = null;
  if (props.isTablet) {
    usedSize = tabletSize;
  } else if (props.isDesktop) {
    usedSize = desktopSize;
  }

  return {...actionIconCommonProps, ...usedSize};
};

const getSearchIconProps = (props: {isMobile: boolean; isTablet: boolean; isDesktop: boolean}) => {
  const desktopSize = {size: 24};
  const tabletSize = {size: 18};

  let usedSize = null;
  if (props.isTablet) {
    usedSize = tabletSize;
  } else if (props.isDesktop) {
    usedSize = desktopSize;
  }

  return {...actionIconCommonProps, ...usedSize};
};

const GradientBackground = styled.div`
  background: linear-gradient(180deg, ${ColorPalette.black1} 0%, ${ColorPalette.transparent1} 100%);
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 24px;

  @media ${breakpoints.sm} {
    height: 87px;
  }

  @media ${breakpoints.md} {
    height: 134px;
  }
`;

const GradientBackgroundBottom = styled(GradientBackground)`
  transform: rotate(180deg);
  bottom: 0;

  height: 46px;

  @media ${breakpoints.sm} {
    height: 89px;
  }

  @media ${breakpoints.md} {
    height: 134px;
  }
`;

const TopSection = styled.div`
  width: 100%;
  top: 0;
  z-index: 1;
  position: absolute;
`;

const Root = styled.div<IElementPosition & {isExistHorseImage: boolean}>`
  width: 100%;
  min-height: 150px;
  position: relative;
  background-color: ${({isExistHorseImage}) => (isExistHorseImage ? ColorPalette.black1 : ColorPalette.gray49)};

  &:hover ${TopSection} {
    display: block;
  }

  @media ${breakpoints.sm} {
    min-height: 280px;
  }

  @media ${breakpoints.md} {
    min-height: 488px;
  }
`;

const Content = styled.div<{withOverlay?: boolean}>`
  width: 100%;
  height: 100%;
  position: absolute;
  color: ${ColorPalette.white0};
  background: ${({withOverlay}) => (withOverlay ? ColorPalette.black5 : 'unset')};
`;

const BaseHeaderPortrait = styled.div<IHeaderProps>`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(prop) => prop.url});

  @media ${breakpoints.sm} {
    position: absolute;
  }
`;

const HeaderPortrait = styled(BaseHeaderPortrait)`
  background-size: ${(prop) => (prop.url === FoalPlaceHolder ? 'cover;' : `contain;`)};
  &:hover ${TopSection} {
    display: block;
  }
  position: absolute;
`;

const BlurredHeaderPortrait = styled(BlurredImage)`
  overflow: hidden;
  position: absolute;
`;

const PortraitWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
`;

const DnaLogoImg = styled(Icon)`
  bottom: -45px;
  right: -20px;

  z-index: 2;
  position: absolute;

  @media ${breakpoints.sm} {
    bottom: 35px;
    right: -29px;
  }

  @media ${breakpoints.md} {
    bottom: 36px;
  }
`;

const HeaderHorseName = styled.div<IElementPosition>`
  max-height: 105px;
  min-height: 54px;
  overflow: hidden;
  bottom: 10px;
  font-size: ${Typography.size.size18};
  font-weight: ${Typography.weight.medium500};
  letter-spacing: -1.2px;
  right: ${(prop) => (prop.position === ElementPosition.Left ? '39px' : 'unset')};
  left: ${(prop) => (prop.position === ElementPosition.Right ? '39px' : 'unset')};
  color: ${ColorPalette.white0};
  margin-top: 12px;
  ${(prop) =>
    prop.position === ElementPosition.Left
      ? 'justify-content: flex-end; margin-right: 27px; margin-left: 4px;'
      : 'justify-content: start; margin-left: 27px; margin-right: 4px;'};

  @media ${breakpoints.sm} {
    display: flex;
    flex-direction: ${({position}) => (position === ElementPosition.Left ? 'row-reverse' : 'row')};
    align-items: flex-end;
    font-size: ${Typography.size.size30};
    bottom: 44px;
    position: absolute;
    color: inherit;
    margin: unset;
  }

  @media ${breakpoints.md} {
    font-size: ${Typography.size.size35};
    bottom: 41px;
  }
`;

const HorseInfo = styled.div<IElementPosition>`
  overflow: hidden;
  white-space: nowrap;
  font-size: ${Typography.size.size11};
  right: ${(prop) => (prop.position === ElementPosition.Left ? '39px' : 'unset')};
  left: ${(prop) => (prop.position === ElementPosition.Right ? '39px' : 'unset')};
  line-height: 24px;
  color: ${ColorPalette.white0};
  ${(prop) =>
    prop.position === ElementPosition.Left
      ? 'align-items: flex-end; margin-right: 27px;'
      : 'align-items: start; margin-left: 27px;'};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size13};
    bottom: 18px;
    position: absolute;
    color: inherit;
    align-items: unset;
    padding-bottom: 0;
    margin: unset;
    max-width: 40%;
  }

  @media ${breakpoints.md} {
    font-size: ${Typography.size.size16};
    bottom: 20px;
  }
`;

const HorsePropertiesContainer = styled.div`
  max-width: 80%;

  @media ${breakpoints.sm} {
    max-width: 100%;
  }
`;

const HorseProperties = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HorseCommercialTypeContainer = styled.div<IElementPosition>`
  ${(prop) =>
    prop.position === ElementPosition.Left
      ? `border-right: 1px solid ${ColorPalette.black3};`
      : `border-left: 1px solid ${ColorPalette.black3};`};

  ${(prop) => (prop.position === ElementPosition.Left ? 'padding-right: 27px;' : 'padding-left: 27px;')};
  padding-top: 6px;

  display: flex;
  flex-grow: 1;

  @media ${breakpoints.sm} {
    padding: 15 0 0;
    margin: 0;
  }

  @media ${breakpoints.md} {
    padding: 0;
    border: unset;
  }
`;

const HorseCommercialTypes = styled.div<IElementPosition>`
  display: flex;
  flex-wrap: wrap;
  ${(prop) => (prop.position === ElementPosition.Left ? 'justify-content: flex-end;' : '')};

  opacity: 0.5;

  @media ${breakpoints.sm} {
    opacity: 1;

    ${(prop) => (prop.position === ElementPosition.Left ? 'margin: 0 30px 0 auto;' : 'margin: 0 auto 0 30px;')};
  }

  @media ${breakpoints.md} {
    position: absolute;
    display: flex;
    bottom: 70px;
    margin: 0;

    max-width: 50%;

    ${(prop) =>
      prop.position === ElementPosition.Left
        ? 'left: 80px; justify-content: start;'
        : 'right: 80px; justify-content: flex-end;'};
  }
`;

const HorseCommercialType = styled.div<IElementPosition>`
  padding: 0 6px;
  color: ${ColorPalette.white0};
  line-height: 24px;
  font-size: ${Typography.size.size9};
  background: ${ColorPalette.gray43};
  border-radius: 3px;
  margin: 1px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size11};
    padding: 0 10px;
    color: ${ColorPalette.white0};
    background: ${ColorPalette.black3};
  }

  @media ${breakpoints.md} {
    background: ${ColorPalette.black8};
    font-size: ${Typography.size.size16};
    padding: 6px 8px;
  }
`;

const Actions = styled.div<IElementPosition>`
  display: flex;
  justify-content: ${(props) => (props.position === ElementPosition.Left ? 'flex-end' : 'flex-start')};

  @media ${breakpoints.sm} {
    padding: ${(props) => (props.position === ElementPosition.Left ? '24px 0' : '24px 0 24px 15px')};
  }

  @media ${breakpoints.md} {
    padding: ${(props) => (props.position === ElementPosition.Left ? '40px 0' : '40px 0 40px 25px')};
  }
`;

const Action = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${ColorPalette.gray39};
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  :hover * {
    color: ${ColorPalette.white0};
    fill: ${ColorPalette.white0} !important;
  }

  @media ${breakpoints.sm} {
    margin-left: 20px;
  }

  @media ${breakpoints.md} {
    margin-left: 32px;
  }
`;

const ActionLabel = styled.div`
  max-width: 40px;
  margin-left: 4px;
  font-weight: ${Typography.weight.medium500};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size11};
    max-width: 79px;
    margin-left: 8px;
    line-height: 13px;
  }

  @media ${breakpoints.md} {
    font-size: ${Typography.size.size16};
    max-width: 115px;
    margin-left: 16px;
    line-height: 19px;
  }
`;

const SearchHorse = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

const SearchHorseInputContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: 360px;
`;

const CloseSearchHorseButton = styled(ColoredIcon)`
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
`;

const EmptyHorseIcon = styled(Icon)`
  z-index: 0;
  width: 90%;
  height: 90%;
`;

const SelectHorseButton = styled(PrimaryButton)`
  z-index: 1;
  padding: 12px;
`;

const HorseGender = styled(Icon)`
  margin-right: 5px;
`;

interface IHeaderProps {
  url?: string;
  position?: ElementPosition;
}

interface IProps {
  parent: Nullable<IFoalParent>;
  isLoading?: boolean;
  elementPosition: ElementPosition;
  linkAnotherPartner: string;
  onSelectAnotherPartner(horseId: number): void;
}

type AllProps = IProps & IWithFavoriteHorseActionsProps;

const FoalParentMainInfo = (props: AllProps) => {
  const {
    parent,
    isLoading,
    favoriteHorseAdding,
    favoriteHorseDeleting,
    linkAnotherPartner,
    onSelectAnotherPartner,
    elementPosition,
  } = props;

  const parentGender = elementPosition === ElementPosition.Left ? Gender.Stallion : Gender.Mare;

  const [isSearchHorseOpen, setIsSearchHorseOpen] = useState(false);
  const [isAvatarExists, setIsAvatarExists] = useState(true);
  const {hasPermission} = usePermissions();
  const mediaQuery = useMediaQuery();
  const {isTablet, isDesktop, isMobile} = mediaQuery;

  const favoriteHorseActionsLoading = favoriteHorseAdding.isRequesting || favoriteHorseDeleting.isRequesting;

  useErrorCommunicationToToast(favoriteHorseAdding, undefined, 1);
  useErrorCommunicationToToast(favoriteHorseDeleting, undefined, 2);

  useEffect(() => {
    let isMounted = true;

    if (parent?.avatar?.url) {
      isImageExists(parent.avatar.url)
        .then(() => {
          if (isMounted) {
            setIsAvatarExists(true);
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsAvatarExists(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [parent]);

  const FindHorseAction = (props: {
    desktopStyle?: React.CSSProperties;
    tabletStyle?: React.CSSProperties;
    mobileStyle?: React.CSSProperties;
  }) => {
    let actionStyle: React.CSSProperties | undefined = undefined;
    if (isDesktop) {
      actionStyle = props.desktopStyle;
    } else if (isTablet) {
      actionStyle = props.tabletStyle;
    }

    return linkAnotherPartner && hasPermission(Permission.FindHorse) ? (
      <CommonLink to={linkAnotherPartner}>
        <Action style={actionStyle}>
          <ColoredIcon name={IconName.HorseheadNoCrest} {...getHorseheadNoCrestIconProps(mediaQuery)} />
          <ActionLabel>Find another partner</ActionLabel>
        </Action>
      </CommonLink>
    ) : null;
  };

  const SearchAction = (props: {
    desktopStyle?: React.CSSProperties;
    tabletStyle?: React.CSSProperties;
    mobileStyle?: React.CSSProperties;
  }) => {
    const handleActionClick = () => setIsSearchHorseOpen(true);

    let actionStyle: React.CSSProperties | undefined = undefined;
    if (isDesktop) {
      actionStyle = props.desktopStyle;
    } else if (isTablet) {
      actionStyle = props.tabletStyle;
    }

    return (
      <Action onClick={handleActionClick} style={actionStyle}>
        <ColoredIcon name={IconName.Search} {...getSearchIconProps(mediaQuery)} />
        <ActionLabel>Choose another horse</ActionLabel>
      </Action>
    );
  };

  const onSelectAnotherPartnerHandler = useCallback(
    (horse: IFoundHorse) => {
      setIsSearchHorseOpen(false);
      onSelectAnotherPartner(horse.id);
    },
    [setIsSearchHorseOpen, onSelectAnotherPartner]
  );

  if (!parent) {
    return (
      <>
        <Root
          isExistHorseImage={!!parent}
          position={elementPosition}
          className="d-flex justify-content-center align-items-center"
        >
          <EmptyHorseIcon className="position-absolute" name={IconName.RearedHorse} color={ColorPalette.gray38} />
          {!isSearchHorseOpen && (
            <SelectHorseButton
              size={isMobile ? 'small' : 'medium'}
              isLoading={isLoading}
              onClick={() => setIsSearchHorseOpen(true)}
            >
              <HorseGender name={mapGenderIconToGender[parentGender]} size={isMobile ? 18 : 24} />
              Select {parentGender.toLowerCase()}
            </SelectHorseButton>
          )}
          {isSearchHorseOpen && (
            <SearchHorse>
              <CloseSearchHorseButton
                name={IconName.Close}
                color={ColorPalette.white0}
                fill={true}
                stroke={false}
                onClick={() => setIsSearchHorseOpen(false)}
              />
              <SearchHorseInputContainer>
                <SearchHorseInput onSelectHorse={onSelectAnotherPartnerHandler} gender={parentGender} />
              </SearchHorseInputContainer>
            </SearchHorse>
          )}
          <Content withOverlay={isSearchHorseOpen} />
        </Root>
      </>
    );
  }

  const {avatar, name, gender, dateOfBirth, commercialTypes, breeds, owner} = parent;

  const parentDetails = [gender, dateOfBirth ? dateDiffInYearsOrMonth(dateOfBirth) : ''].filter(Boolean).join(', ');

  const canShowActions = !isSearchHorseOpen;

  const HorseCommercialBlock = () => {
    return (
      <HorseCommercialTypeContainer position={elementPosition}>
        <HorseCommercialTypes position={elementPosition}>
          {commercialTypes.length > 0 &&
            commercialTypes.map((value) => (
              <HorseCommercialType position={elementPosition} key={`${value}_${elementPosition}`}>
                {value}
              </HorseCommercialType>
            ))}
        </HorseCommercialTypes>
      </HorseCommercialTypeContainer>
    );
  };

  const position = elementPosition === ElementPosition.Left ? 'Left' : 'Right';

  const HorseInfoBlock = () => {
    return (
      <>
        <HeaderHorseName position={elementPosition} className="d-flex align-items-end">
          {!isMobile && (
            <ShadowContainer position={position}>
              {props.parent && <FavoriteHorseIconButton horse={props.parent} position={position} isShowLabel={false} />}
            </ShadowContainer>
          )}
          <div className="align-self-start">{name}</div>
        </HeaderHorseName>
        <HorseInfo className="d-flex flex-column" position={elementPosition}>
          <HorsePropertiesContainer className="d-flex">
            {breeds.length > 0 && (
              <HorseProperties>{`${breeds.join(', ')},${Typography.symbols.whitespace}`}</HorseProperties>
            )}
            {parentDetails}
          </HorsePropertiesContainer>
        </HorseInfo>
        {isMobile || isDesktop ? <HorseCommercialBlock /> : null}
        <HeaderContact position={elementPosition} owner={owner} />
      </>
    );
  };

  return (
    <>
      <Root isExistHorseImage={!!parent} position={elementPosition}>
        <PortraitWrapper>
          {isAvatarExists && avatar && <BlurredHeaderPortrait src={FixImageName(avatar.url)} />}
          <HeaderPortrait url={isAvatarExists && avatar ? FixImageName(avatar.url) : FoalPlaceHolder} />
        </PortraitWrapper>
        {isSearchHorseOpen && (
          <SearchHorse>
            <CloseSearchHorseButton
              name={IconName.Close}
              color={ColorPalette.white0}
              fill={true}
              stroke={false}
              onClick={() => setIsSearchHorseOpen(false)}
            />
            <SearchHorseInputContainer>
              <SearchHorseInput onSelectHorse={onSelectAnotherPartnerHandler} gender={gender} />
            </SearchHorseInputContainer>
          </SearchHorse>
        )}
        {canShowActions && (
          <TopSection>
            <GradientBackground />
            {isMobile ? null : (
              <Actions position={elementPosition} className="position-relative">
                {elementPosition === ElementPosition.Left ? (
                  <>
                    <SearchAction />
                    <FindHorseAction />
                  </>
                ) : (
                  <>
                    <FindHorseAction desktopStyle={{marginLeft: 0}} />
                    <SearchAction desktopStyle={{marginLeft: 8}} tabletStyle={{marginLeft: 8}} />
                  </>
                )}
              </Actions>
            )}
          </TopSection>
        )}
        {isLoading && <Loading />}
        <Content withOverlay={isSearchHorseOpen}>
          {isMobile && (
            <ShadowContainer position={position}>
              {props.parent && <FavoriteHorseIconButton horse={props.parent} position={position} isShowLabel={false} />}
            </ShadowContainer>
          )}
          <GradientBackgroundBottom />
          {isMobile ? null : <HorseInfoBlock />}
          {elementPosition === ElementPosition.Left ? (
            <DnaLogoImg name={IconName.DnaIcon} size={isMobile ? 40 : 60} />
          ) : null}
        </Content>
      </Root>
      {favoriteHorseActionsLoading && <IconedLoading />}
      {isMobile ? <HorseInfoBlock /> : null}
      {isTablet ? <HorseCommercialBlock /> : null}
    </>
  );
};

const Connected = withFavoriteHorseActions(memo(FoalParentMainInfo));

export default withDynamicModules(Connected, getWithFavoriteHorseActionsModules());
