import {action} from 'typesafe-actions';

import AdminShortTandemRepeatReportService from 'Admin/AdminDashboard/services/AdminSTRReportService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {uploadShortTandemRepeatActionTypes, uploadShortTandemRepeatActions} from './types';

export const uploadShortTandemRepeat =
  (file: File): ActionResult<IAppState, void, uploadShortTandemRepeatActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadShortTandemRepeatActionTypes.REQUEST));
      await AdminShortTandemRepeatReportService.uploadShortTandemRepeat(file);
      dispatch(action(uploadShortTandemRepeatActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadShortTandemRepeatActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
