import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {IBaseControlProps} from 'Common/models/IBaseControlProps';

import FieldControlContainer from '../Layout/FieldControlContainer';
import {inputCss} from './styles';

const StyledTextArea = styled.textarea`
  min-height: 110px;
  resize: none;
  ${inputCss}
`;

type Props = IBaseControlProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

function TextArea(props: Props) {
  const {value, inputClassName, inputStyle, ...rest} = props;

  return (
    <FieldControlContainer {...rest}>
      <StyledTextArea
        {...rest}
        className={classnames('form-control', inputClassName)}
        style={inputStyle}
        value={value || ''}
      />
    </FieldControlContainer>
  );
}

export default TextArea;
export type TextAreaProps = Props;
