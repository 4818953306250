import {IOrderAdmin, IOrderHorseAdmin} from 'Admin/AdminDashboard/models/Order/IOrderAdmin';
import {IOrderDetails} from 'Admin/AdminDashboard/models/Order/IOrderDetails';
import {IOrderHorseDetailsAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {convertPriceToClient} from 'Common/helpers/convertPrices';

import {
  IServerOrderAdmin,
  IServerOrderAdminHorse,
  IServerOrderDetailsAdmin,
  IServerOrderDetailsAdminHorse,
  IServerOrderStatusAdmin,
} from 'Admin/AdminDashboard/services/types/order';
import {IOrderPriceSummaryAdmin} from 'Admin/AdminDashboard/models/Order/IOrderPriceSummaryAdmin';
import {parseOrderStatus} from 'Order/helpers/parseOrderStatus';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {parseOrderStatusDate} from 'Order/helpers/parseOrderStatusDate';
import {IOrderStatused} from 'Common/models/IOrderStatused';
import {ITestHorseRequest} from 'Order/services/types/order';
import {IHorseTestRequest} from 'Admin/AdminDashboard/models/Order/IHorseTestRequest';
import {IHorseTest} from 'Admin/AdminDashboard/models/Order/IHorseTest';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';
import {withBucketBaseUrl} from 'Common/helpers/withBucketBaseUrl';

export function convertOrderAdminToClient(order: IServerOrderAdmin): IOrderAdmin {
  return {
    id: order.id,
    owner: withUserName(withAvatar(order.owner)),
    date: convertUTCToClientDate(order.createDate) || '',
    price: convertPriceToClient(order.price),
    horses: order.horses.map(convertOrderAdminHorseToClient),
    paymentDetailed: convertPriceDetails(order.paymentDetails),
    activationCodes: order.activationCodes,
  };
}

export function convertOrderAdminHorseToClient(horse: IServerOrderAdminHorse): IOrderHorseAdmin {
  return {
    ...parseOrderStatus(horse),
    id: horse.id,
    name: horse.horseName,
    avatar: horse.avatar && withImageBaseUrl(horse.avatar),
    packages: horse.packageName,
    price: convertPriceToClient(horse.packageTotal),
    availabilityCriteria: horse.availabilityCriteria,
    isArchived: horse.isArchived,
    isDeleted: horse.isDeleted,
    sample: horse.sample,
    isGeneticDataHidden: horse.isGeneticDataHidden,
    statusUpdateDates: parseOrderStatusDate(horse),
    owner: horse.owner,
  };
}

export function convertOrderDetailsToClient(order: IServerOrderDetailsAdmin): IOrderDetails {
  return {
    id: order.id,
    createDate: convertUTCToClientDate(order.createDate) || '',
    owner: withUserName(order.owner),
    paymentDetails: convertPriceDetails(order.paymentDetails),
    horsesDetails: order.horses.map(convertOrderHorseToClient).sort((a, b) => sortByStringKey(a, b, 'sampleId')),
  };
}

export function convertPriceDetails(priceDetails: IOrderPriceSummaryAdmin): IOrderPriceSummaryAdmin {
  return {
    ...priceDetails,
    couponDiscount: convertPriceToClient(priceDetails.couponDiscount),
    giftCard: convertPriceToClient(priceDetails.giftCard),
    total: convertPriceToClient(priceDetails.total),
    paymentDate: priceDetails.paymentDate ? convertUTCToClientDate(priceDetails.paymentDate) : null,
  };
}

export function convertOrderHorseToClient(horse: IServerOrderDetailsAdminHorse): IOrderHorseDetailsAdmin {
  const {packagesDetails} = horse;
  return {
    id: horse.id,
    name: horse.horseName,
    avatar: horse.avatar && {...withImageBaseUrl(horse.avatar)},
    sample: horse.sample
      ? {...horse.sample, receivedDate: convertUTCToClientDate(horse.sample.receivedDate) || ''}
      : null,
    sampleId: horse.sample ? horse.sample.sampleId : null,
    packagesDetails: {
      totalPrice: convertPriceToClient(packagesDetails.packagesTotal),
      packages: packagesDetails.packages.map((p) => ({...p, price: convertPriceToClient(p.price)})),
    },
    reports:
      horse.reports?.map((item) => ({
        ...withBucketBaseUrl(item),
        uploadDate: convertUTCToClientDate(item.uploadDate) || '',
      })) || null,
    availabilityCriteria: horse.availabilityCriteria,
    isArchived: horse.isArchived,
    isDeleted: horse.isDeleted,
    isGeneticDataHidden: horse.isGeneticDataHidden,
    owner: horse.owner,
  };
}

export function convertStatusDetails(status: IServerOrderStatusAdmin): IOrderStatused {
  return {statusUpdateDates: {...parseOrderStatusDate(status)}, ...parseOrderStatus(status)};
}

export function convertHorseTestToServer(horseTests: IHorseTest[]): ITestHorseRequest[] {
  const tests = convertTestsToIHorseTestRequest(horseTests);

  return convertHorseTestRequestToServer(tests);
}

export function convertHorseTestRequestToServer(tests: IHorseTestRequest[]): ITestHorseRequest[] {
  return tests
    .filter((test): test is Omit<IHorseTestRequest, 'horseId'> & {horseId: number} => !!test.horseId)
    .map((test) => ({id: test.horseId, packages: test.packages?.map((id) => ({id})) || []}));
}

export function convertTestsToIHorseTestRequest(tests: IHorseTest[]): IHorseTestRequest[] {
  const uniqueHorses = tests.filter((value, idx, self) => self.indexOf(value) === idx);

  const result: IHorseTestRequest[] = uniqueHorses.map((uniqHorse) => {
    const packages = uniqHorse.packages.map((pack) => pack);
    return {horseId: uniqHorse.horseId, packages};
  });

  return result;
}
