import {memo, useCallback, useEffect, useState} from 'react';

import Switch from 'Common/components/Controls/Switch';
import {SwitchFieldWrapper} from 'Common/components/StyledComponents/StyledComponents';
import {ItemVisibilityText} from '../styled';
import {IUserNotifactionSettings} from 'UserProfile/models/IUserNotifactionSettings';
import {NotificationType} from 'Notifications/const/NotificationType';

interface IProps {
  notificationSetting: IUserNotifactionSettings;

  onNotificationCreate(eventId: number, type: NotificationType): void;
  onNotificationDelete(notificationId: number): void;
}

type AllProps = IProps;

function NotificationEventSwitcher(props: AllProps) {
  const {notificationSetting, onNotificationCreate, onNotificationDelete} = props;
  const {emailTypeId, id, isEmailOn, isNotificationOn, notificationTypeId} = notificationSetting;

  const [isNotificationSwitcherOn, setIsNotificationSwitcherOn] = useState(isNotificationOn);
  const [isEmailSwitcherOn, setIsEmailSwitcherOn] = useState(isEmailOn);

  useEffect(() => {
    setIsNotificationSwitcherOn(isNotificationOn);
    setIsEmailSwitcherOn(isEmailOn);
  }, [isEmailOn, isNotificationOn]);

  const onNotificationSwitchChange = useCallback(() => {
    const newValue = !isNotificationSwitcherOn;
    setIsNotificationSwitcherOn(newValue);

    if (newValue) {
      onNotificationCreate(id, NotificationType.Notification);
    } else {
      notificationTypeId && onNotificationDelete(notificationTypeId);
    }
  }, [id, isNotificationSwitcherOn, notificationTypeId, onNotificationCreate, onNotificationDelete]);

  const onEmailSwitchChange = useCallback(() => {
    const newValue = !isEmailSwitcherOn;
    setIsEmailSwitcherOn(newValue);

    if (newValue) {
      onNotificationCreate(id, NotificationType.Email);
    } else {
      emailTypeId && onNotificationDelete(emailTypeId);
    }
  }, [emailTypeId, id, isEmailSwitcherOn, onNotificationCreate, onNotificationDelete]);

  return (
    <>
      <SwitchFieldWrapper>
        <Switch
          onChange={onNotificationSwitchChange}
          checked={isNotificationSwitcherOn}
          textLeft={<ItemVisibilityText> {isNotificationSwitcherOn ? 'On' : 'Off'}</ItemVisibilityText>}
          labelStyle={{minWidth: 20}}
        />
      </SwitchFieldWrapper>

      <SwitchFieldWrapper>
        <Switch
          onChange={onEmailSwitchChange}
          checked={isEmailSwitcherOn}
          textLeft={<ItemVisibilityText> {isEmailSwitcherOn ? 'On' : 'Off'}</ItemVisibilityText>}
          labelStyle={{minWidth: 20}}
        />
      </SwitchFieldWrapper>
    </>
  );
}

export default memo(NotificationEventSwitcher);

export type IHorsePrivacySwitcherProps = IProps;
