import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.shortTandemRepeatReport;
}

export function selectShortTandemRepeatReport(state: IAppState) {
  return selectState(state).data.shortTandemRepeatReport;
}

export const selectCommunication = makeCommunicationSelector(selectState);
