import {IWithHorseMapFieldProps} from 'Common/components/FormFields/HorseMapField/HorseMapField';

type IRequiredHorseMapFieldPropsServer = Omit<IWithHorseMapFieldProps, 'horseAddress' | 'isManualAddressMode'>;

export function withHorseMapFieldPropsToRequest<T, U extends IWithHorseMapFieldProps & T>(values: U): T {
  const {...rest} = values;

  const model = {
    ...rest,
    location: !values.isManualAddressMode ? values.location : null,
  };

  delete model.horseAddress;
  delete model.isManualAddressMode;

  return model;
}

export function withHorseMapFieldPropsToClient<
  T extends IRequiredHorseMapFieldPropsServer,
  U extends IWithHorseMapFieldProps
>(model: T): U {
  return {
    ...model,
    isManualAddressMode: !model.location,
  } as U;
}
