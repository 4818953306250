import React, {memo, useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, FieldHint} from 'Common/components/StyledComponents/StyledComponents';
import PackageSection from 'Admin/AdminDashboard/components/Orders/CreateUserOrder/parts/PackageSection';
import {
  convertFormValuesToRequest,
  IFormCreateUserOrderValues,
  initialValues,
  validationSchema,
} from 'Admin/AdminDashboard/components/Orders/CreateUserOrder/validation';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminOrders/createUserOrder/index';
import {
  actions as userAdminActions,
  selectors as userAdminSelectors,
} from 'Admin/AdminDashboard/store/adminUsers/users/index';
import {selectors as userSelectors} from 'UserProfile/store/currentUser/index';
import {AdminCreateUserOrderModule} from 'Admin/AdminDashboard/store/adminOrders/createUserOrder/adminCreateUserOrderModule';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {convertHorseTestToServer} from 'Admin/AdminDashboard/services/converters/orders';
import {getCommonErrors, getErrorCode} from 'Common/helpers/ErrorHelper';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {getCouponError} from 'Order/components/CreateOrder/validation';
import {CouponError} from 'Order/services/constants/CouponError';
import DiscountSection from 'Admin/AdminDashboard/components/Orders/CreateUserOrder/parts/DiscountSection';
import {CheckboxField, SelectField} from 'Common/components/FormFields/index';
import Theme from 'Common/constants/Theme';
import {withCurrency} from 'Common/helpers/withCurrency';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import Nebula from 'Common/components/Layout/Nebula';
import Loading from 'Loading/components/Loading';
import {breakpoints} from 'Common/constants/Breakpoints';
import {paymentOptions} from 'Order/constants/PaymentOptions';
import {AdminUsersModule} from 'Admin/AdminDashboard/store/adminUsers/users/adminUsersModule';
import FieldControlContainer from 'Common/components/Layout/FieldControlContainer';
import {useToast} from 'Common/helpers/hooks/useToast';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {Divider} from 'Admin/shared/components/styled';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';

const Summary = styled.div`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size24};
  line-height: 32px;
  color: ${ColorPalette.black1};
`;

const SummaryLabel = styled.div`
  margin-right: 5px;
`;

const SummaryAmount = styled.div`
  font-weight: ${Typography.weight.medium500};
`;

const MutableModalWindowFooter = styled(ModalWindowFooter)`
  flex-direction: column;

  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

const OwnerInfo = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const IsPaidCheckbox = styled.div`
  padding-top: 40px;
  margin-left: 16px;
`;

const SendEmailField = styled.div`
  margin: 12px 0 32px;
`;

interface IExternalProps {
  horseId?: number;
  ownerId?: number;
  onSuccess?(): void;
  packageGroupsDictionary: ISimpleDictionary<IPackageGroup>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormCreateUserOrderValues> & OuterProps;

const CreateUserOrder = (props: AllProps) => {
  const {values, isSubmitting, resetForm, isValid, submitCount, setValues, setFieldValue} = props;
  const {
    onSuccess,
    getHorses,
    getSummary,
    horses,
    summary,
    coupon,
    getCoupon,
    creatingOrder,
    summaryLoading,
    couponLoading,
    removeCoupon,
    removeSummary,
    horsesLoading,
    horseId,
    ownerId,
    userDetails,
    getUserDetails,
    userDetailsLoading,
    packageGroupsDictionary,
    packageGroups,
    packageGroupsLoading,
    getOrderPackages,
    orderPackages,
    orderPackagesLoading,
  } = props;

  const {
    actions: {getItems: getPackageGroups},
  } = packageGroupsDictionary;

  const {addToast} = useToast(1);

  const errorInfo = creatingOrder.error || summaryLoading.error || horsesLoading.error || packageGroupsLoading.error;

  const ownerAlreadyExists = !!userDetails;

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && addToast(commonError, 'error');
  }, [errorInfo, addToast]);

  const [couponError, setCouponError] = useState<string | null>(null);

  const checkCode = useCallback(
    (code: string) => {
      getCoupon({code, horses: convertHorseTestToServer(values.tests)}, values.userId);
    },
    [getCoupon, values.tests, values.userId]
  );

  const applyCode = useCallback(() => {
    if (!values.couponCode) {
      return;
    }
    checkCode(values.couponCode);
  }, [values.couponCode, checkCode]);

  const removeCouponFromOrder = useCallback(() => {
    setFieldValue('couponCode', '');
    removeCoupon();
    setCouponError(null);
  }, [removeCoupon, setFieldValue]);

  const getNecessaryUsersInfoForOrder = useCallback(
    (id: number) => {
      getHorses(id);
      getPackageGroups();
      getOrderPackages();
    },
    [getHorses, getOrderPackages, getPackageGroups]
  );

  useEffect(() => {
    if (ownerId) {
      getNecessaryUsersInfoForOrder(ownerId);
      setFieldValue('userId', ownerId);

      if (!ownerAlreadyExists) {
        getUserDetails(ownerId);
      }
    }

    if (horseId) {
      setFieldValue('tests', [{horseId}]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserDetails, getNecessaryUsersInfoForOrder, values.userId, horseId, ownerId, setFieldValue]);

  useEffect(() => {
    if (ownerId) {
      return;
    }

    setValues({...initialValues, userId: values.userId});
    removeCouponFromOrder();

    if (values.userId) {
      getNecessaryUsersInfoForOrder(values.userId);
    }

    return () => resetForm();
  }, [ownerId, setValues, removeCouponFromOrder, resetForm, values.userId, getNecessaryUsersInfoForOrder]);

  const isTestEmpty = values.tests.length === 0 || values.tests.every((x) => !x.packages);

  useEffect(() => {
    if (coupon && !isTestEmpty) {
      checkCode(coupon.code);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.tests, checkCode, isTestEmpty]);

  useEffect(() => {
    if (isTestEmpty) {
      removeSummary();
      return;
    }

    getSummary(
      {
        horses: convertHorseTestToServer(values.tests),
        couponId: coupon?.id,
      },
      values.userId
    );
  }, [values.tests, coupon, getSummary, isTestEmpty, removeSummary, values.userId]);

  useOnSuccessCommunication(creatingOrder, () => onSuccess && onSuccess());

  const couponLoadingError = useCallback(() => {
    if (couponLoading.error) {
      const couponErrorText = getCouponError(couponLoading.error);
      addToast(couponErrorText, 'error');
      setCouponError(couponErrorText);
    }
    if (coupon && couponLoading.error && getErrorCode(couponLoading.error) === CouponError.CannotBeAppliedCoupon) {
      return;
    }
    removeCoupon();
  }, [coupon, couponLoading.error, removeCoupon, addToast]);

  useOnErrorCommunication(couponLoading, couponLoadingError);
  useOnSuccessCommunication(couponLoading, () => setCouponError(null));

  const hasCouponError = !!coupon && !!couponError;
  const disableSubmitButton =
    (summary?.packagesPrice?.packages?.length || 0) < 1 ||
    !(submitCount === 0 || isValid) ||
    hasCouponError ||
    !isValid;

  const isLoading =
    (ownerId && userDetailsLoading.isRequesting) ||
    horsesLoading.isRequesting ||
    orderPackagesLoading.isRequesting ||
    packageGroupsLoading.isRequesting ||
    isSubmitting;

  const userNotFound = !values.userId || values.userId === 0;

  const isSummaryLoading = summaryLoading.isRequesting || couponLoading.isRequesting;

  return (
    <>
      <ModalWindowHeader>Create order</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        {isLoading && <Loading />}
        <ModalWindowFormContent minHeight={420}>
          {userDetails && (
            <FieldControlContainer label="Owner" isRequired={true}>
              <OwnerInfo>{`${userDetails?.name} (${userDetails?.email})`} </OwnerInfo>
            </FieldControlContainer>
          )}
          {!ownerId && <UserSearchField name="userId" label="Owner" isRequired={true} />}
          {userNotFound && <Divider />}
          <Nebula active={userNotFound} style={{opacity: 0.5, marginTop: -5}}>
            {!userNotFound && (
              <PackageSection
                horses={horses}
                tests={values.tests}
                orderPackages={orderPackages}
                packageGroups={packageGroups}
              />
            )}
            <DiscountSection
              coupon={coupon}
              onApplyCode={applyCode}
              onDeleteCoupon={removeCouponFromOrder}
              isDisabled={!values.couponCode}
              isLoading={couponLoading.isRequesting}
            />

            <div className="d-flex w-100">
              <div className="w-50">
                <SelectField isRequired={true} name="paymentMethod" label="Payment Method" options={paymentOptions} />
              </div>
              <IsPaidCheckbox>
                <CheckboxField name="isPaid" label="Mark as paid" />
              </IsPaidCheckbox>
            </div>

            <SendEmailField>
              <CheckboxField name="sendEmailToUser" label="Send email" style={{marginBottom: 4}} />
              <FieldHint>Enabling this option, will allow you to notify the customer about creating order</FieldHint>
            </SendEmailField>
          </Nebula>
        </ModalWindowFormContent>
        <MutableModalWindowFooter>
          <ErrorMessage>{props.status}</ErrorMessage>
          <Summary className="d-flex align-items-center justify-content-between">
            <SummaryLabel>Summary: </SummaryLabel>
            <SummaryAmount>{withCurrency(summary?.total || 0)}</SummaryAmount>
          </Summary>
          <ModalWindowButton type="submit" disabled={disableSubmitButton} isLoading={isSummaryLoading}>
            Save
          </ModalWindowButton>
        </MutableModalWindowFooter>
      </Form>
    </>
  );
};

const CreateUserOrderWithFormik = withFormik<OuterProps, IFormCreateUserOrderValues>({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.createOrder(convertFormValuesToRequest(values, formikBag.props.coupon?.id));
  },
  enableReinitialize: true,
})(memo(CreateUserOrder));

const mapStateToProps = (state: IAppState, {packageGroupsDictionary}: IExternalProps) => {
  const {selectors: packageGroupSelectors} = packageGroupsDictionary;

  return {
    horses: selectors.selectUserOrderHorses(state),
    summary: selectors.selectUserSummary(state),
    coupon: selectors.selectUserCoupon(state),
    userDetails: userAdminSelectors.selectUserDetails(state),
    userDetailsLoading: userAdminSelectors.selectCommunication(state, 'userDetailsLoading'),
    creatingOrder: selectors.selectCommunication(state, 'creatingUserOrder'),
    summaryLoading: selectors.selectCommunication(state, 'userSummaryLoading'),
    couponLoading: selectors.selectCommunication(state, 'userCouponLoading'),
    horsesLoading: selectors.selectCommunication(state, 'userHorsesLoading'),
    currentUser: userSelectors.selectCurrentUser(state),
    packageGroups: packageGroupSelectors.selectItems(state),
    packageGroupsLoading: packageGroupSelectors.selectCommunication(state, 'itemsLoading'),
    orderPackages: selectors.selectOrderPackages(state),
    orderPackagesLoading: selectors.selectCommunication(state, 'orderPackagesLoading'),
  };
};

const mapDispatchToProps = {
  getUserDetails: userAdminActions.getUserById,
  getHorses: actions.getUserHorses,
  getSummary: actions.getUserSummary,
  getCoupon: actions.getUserCoupon,
  createOrder: actions.createUserOrder,
  removeCoupon: actions.resetUserCoupon,
  removeSummary: actions.resetUserSummary,
  resetUserOrder: actions.resetUserOrder,
  getOrderPackages: actions.getOrderPackages,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(CreateUserOrderWithFormik);
const Dynamic = withDynamicModules(Connected, [AdminCreateUserOrderModule, AdminUsersModule]);
const Exported = (externalProps: Omit<IExternalProps, 'packageGroupsDictionary'>) => {
  const {packageGroups} = useDictionaries();
  return <Dynamic {...externalProps} packageGroupsDictionary={packageGroups} />;
};

export default Exported;
export type CreateUserOrderProps = IExternalProps;
