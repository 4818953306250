import {memo} from 'react';
import styled from 'styled-components';

import {MutableFieldsGroupBase} from 'Common/components/StyledComponents/StyledComponents';
import {breakpoints} from 'Common/constants/Breakpoints';
import {Nullable} from 'Common/types';
import {IImage} from 'Image/models/IImage';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {AncestryReportSection, Description, SummaryTitle} from '../common/styled';
import CompositionPie from './CompositionPie';
import {IAncesryComponentProps} from 'OnlineReport/models/Ancestry/IAncesryComponentProps';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';

const SplitWrapper = styled(MutableFieldsGroupBase)`
  height: 100%;
  margin-bottom: 30px;
  gap: 0px;

  @media ${breakpoints.sm} {
    margin: 0;
  }
`;

interface IProps {
  population: IAncestryPopulation[];
  avatar?: Nullable<IImage>;
  title: string;
  labels?: string[];
  isShowEye?: boolean;
  isShowRank?: boolean;
  rank?: number;
  scale?: number;
}

type AllProps = IProps & IAncesryComponentProps;

const HorseTitle = styled.div<{scale: number}>`
  font-family: ${Theme.font.secondary};
  font-size: ${(props) => props.scale * 24 + 'px'};
  font-weight: ${Typography.weight.semiBold600};
  margin: -${(props) => props.scale * 28 + 'px'} 0px 0px 0px;
  max-width: ${(props) => (props.scale < 0.7 ? props.scale * 180 + 'px' : props.scale * 250 + 'px')};
  line-height: ${(props) => props.scale >= 0.7 && '20px'};
  word-break: break-word;
  text-align: center;
`;

const HorseLabel = styled.div<{scale: number}>`
  font-family: ${Theme.font.secondary};
  font-size: ${(props) => props.scale * 20 + 'px'};
  font-weight: ${Typography.weight.medium500};
`;

const Header = styled.div<{scale: number}>`
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.scale * 180 + 'px'};
  margin-bottom: -${(props) => props.scale * 55 + 'px'};
`;

const RankLabel = styled.div`
  font-size: 12px;
  font-weight: ${Typography.weight.semiBold600};
`;

const IconWrapper = styled.div`
  color: ${ColorPalette.red7};
  font-size: 12px;
  font-weight: ${Typography.weight.semiBold600};
  & svg {
    width: 12px;
  }
`;

const HorseAncestryPie = (props: AllProps) => {
  const {
    avatar,
    population,
    withoutPaddings = true,
    title,
    labels = [],
    isShowEye = false,
    isShowRank = false,
    rank = 0,
    scale = 1,
  } = props;

  return (
    <AncestryReportSection className="d-flex flex-column" withoutPaddings={withoutPaddings}>
      <SplitWrapper pointOfChange="sm" className="d-flex flex-column w-100 justify-content-center align-items-center">
        <Header scale={scale}>
          {isShowRank ? <RankLabel>#{rank}</RankLabel> : <div>&nbsp;</div>}
          {isShowEye ? (
            <IconWrapper>
              <IconButton name={IconName.ViewOn} color={ColorPalette.red1} />
            </IconWrapper>
          ) : (
            <div>&nbsp;</div>
          )}
        </Header>
        <CompositionPie population={population} avatar={avatar} scale={scale} />
        <HorseTitle scale={scale}>{title}</HorseTitle>
        {labels.map((label, index) => (
          <HorseLabel key={index} scale={scale}>
            {label}
          </HorseLabel>
        ))}
      </SplitWrapper>
    </AncestryReportSection>
  );
};

export default memo(HorseAncestryPie);
