import {getEnvParams} from 'Common/helpers/getEnvParams';
import {Nullable} from 'Common/types';

/**
 * This is wrapper for _url_ property, which leads to AWS S3.
 * It uses REACT_APP_IMAGES_BASE_URL, but it can be used for other resources, like pdf files
 * @param model
 * @returns
 */
export function withBucketBaseUrl<T extends {url: Nullable<string>}>(model: T) {
  const {imagesBaseUrl} = getEnvParams();

  if (!model.url) {
    return model;
  }

  return {
    ...model,
    url: `${imagesBaseUrl}/${model.url}`,
  };
}
