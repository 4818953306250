import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum, makeResetCommunicationReducer} from 'Common/store/utils/communication';
import {
  BusinessPortalHorseActions,
  resetHorseDetailsActionTypes,
  resetHorseHealthIssuesActionTypes,
  resetHorseMediaActionTypes,
  resetHorseOwnerActionTypes,
  resetHorseParentageActionTypes,
  resetHorseRegistriesActionTypes,
  createHorseActionTypes,
  createHorseImageMediaActionTypes,
  createHorseVideoMediaActionTypes,
  deleteHorseAvatarActionTypes,
  deleteHorseMediaActionTypes,
  getHorseDetailsActionTypes,
  getHorseHealthIssuesActionTypes,
  getHorseMediaActionTypes,
  getHorseOwnerActionTypes,
  getHorseParentageActionTypes,
  getHorseRegistriesActionTypes,
  getHorsesActionTypes,
  IBusinessPortalHorseState,
  setHorseOwnerActionTypes,
  updateHorseAvatarActionTypes,
  updateHorseDetailsActionTypes,
  updateHorseHealthIssuesActionTypes,
  updateHorseParentageActionTypes,
  updateHorseRegistriesActionTypes,
  deleteHorseActionTypes,
  resetHorseProfileTypeActionTypes,
  updateHorseProfileTypeActionTypes,
  resetHorseAncestryProfileTypeActionTypes,
  updateHorseAncestryProfileTypeActionTypes,
  updateHorseParentageProfileTypeActionTypes,
  resetHorseParentageProfileTypeActionTypes,
} from '../types';
import composeReducers from 'Common/store/composeReducers';

export const communicationBusinessPortalHorseReducer = combineReducers<
  IBusinessPortalHorseState['communications'],
  BusinessPortalHorseActions
>({
  horsesLoading: makeCommunicationReducerFromEnum(getHorsesActionTypes),
  horseCreating: makeCommunicationReducerFromEnum(createHorseActionTypes),
  horseDetailsLoading: composeReducers([
    makeCommunicationReducerFromEnum(getHorseDetailsActionTypes),
    makeResetCommunicationReducer(resetHorseDetailsActionTypes.RESET),
  ]),
  horseDetailsUpdating: makeCommunicationReducerFromEnum(updateHorseDetailsActionTypes),
  horseOwnerLoading: composeReducers([
    makeCommunicationReducerFromEnum(getHorseOwnerActionTypes),
    makeResetCommunicationReducer(resetHorseOwnerActionTypes.RESET),
  ]),
  setHorseOwnerRequesting: makeCommunicationReducerFromEnum(setHorseOwnerActionTypes),
  horseParentageLoading: composeReducers([
    makeCommunicationReducerFromEnum(getHorseParentageActionTypes),
    makeResetCommunicationReducer(resetHorseParentageActionTypes.RESET),
  ]),
  horseParentageUpdating: makeCommunicationReducerFromEnum(updateHorseParentageActionTypes),
  horseRegistriesLoading: composeReducers([
    makeCommunicationReducerFromEnum(getHorseRegistriesActionTypes),
    makeResetCommunicationReducer(resetHorseRegistriesActionTypes.RESET),
  ]),
  horseRegistriesUpdating: makeCommunicationReducerFromEnum(updateHorseRegistriesActionTypes),
  horseHealthIssuesLoading: composeReducers([
    makeCommunicationReducerFromEnum(getHorseHealthIssuesActionTypes),
    makeResetCommunicationReducer(resetHorseHealthIssuesActionTypes.RESET),
  ]),
  horseHealthIssuesUpdating: makeCommunicationReducerFromEnum(updateHorseHealthIssuesActionTypes),
  horseDeleting: makeCommunicationReducerFromEnum(deleteHorseActionTypes),
  horseAvatarUpdating: makeCommunicationReducerFromEnum(updateHorseAvatarActionTypes),
  horseAvatarDeleting: makeCommunicationReducerFromEnum(deleteHorseAvatarActionTypes),
  horseMediaLoading: composeReducers([
    makeCommunicationReducerFromEnum(getHorseMediaActionTypes),
    makeResetCommunicationReducer(resetHorseMediaActionTypes.RESET),
  ]),
  horseMediaDeleting: makeCommunicationReducerFromEnum(deleteHorseMediaActionTypes),
  horseImageMediaCreating: makeCommunicationReducerFromEnum(createHorseImageMediaActionTypes),
  horseVideoMediaCreating: makeCommunicationReducerFromEnum(createHorseVideoMediaActionTypes),
  profileTypeChanging: composeReducers([
    makeCommunicationReducerFromEnum(updateHorseProfileTypeActionTypes),
    makeResetCommunicationReducer(resetHorseProfileTypeActionTypes.RESET),
  ]),
  ancestryProfileTypeUpdating: composeReducers([
    makeCommunicationReducerFromEnum(updateHorseAncestryProfileTypeActionTypes),
    makeResetCommunicationReducer(resetHorseAncestryProfileTypeActionTypes.RESET),
  ]),
  parentageProfileTypeUpdating: composeReducers([
    makeCommunicationReducerFromEnum(updateHorseParentageProfileTypeActionTypes),
    makeResetCommunicationReducer(resetHorseParentageProfileTypeActionTypes.RESET),
  ]),
});
