import {ICommunication, MakeCommunicationActions} from 'Common/store/utils/communication/types';

import {makeCommunicationActionType} from 'Common/store/utils/communication/index';

const STATE_NAME = 'adminInbreedingCoefficients';

export interface IAdminInbreedingCoefficientsState {
  communications: {
    inbreedingCoefficientsUploading: ICommunication;
  };
}

export const uploadInbreedingCoefficientsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPLOAD_INBREEDING_COEFFICIENTS'
);

export type uploadInbreedingCoefficientsActions = MakeCommunicationActions<
  typeof uploadInbreedingCoefficientsActionTypes,
  {}
>;

export type AdminInbreedingCoefficientsActions = uploadInbreedingCoefficientsActions;
