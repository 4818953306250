import {FRONTEND_DATE_TIME} from 'Common/constants/Date';
import {convertUserName} from 'Common/helpers/convertUserName';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {toCamelCase} from 'Common/helpers/toCamelCase';
import {Nullable} from 'Common/types';
import {NotificationEventType} from 'Notifications/const/NotificationEventType';
import {
  HorseTransferRequestCompleteFromUser,
  IChatRequestAccepted,
  IChatRequestCreated,
  IChatRequestReceived,
  IChatRequestRejected,
  IContactRequested,
  IHorsePublicityRequested,
  IHorseTransferRequestCompleteToUser,
  IHorseTransferRequestCreated,
  IHorseTransferRequestRejected,
  IMessageFromUserReceived,
  INewSampleRequested,
  IOperatingModeUpdate,
  IOrderPlaced,
  IPaymentConfirmed,
  IPaymentMethodChanged,
  IProductChanged,
  IReportUpdated,
  IResultsReady,
  IResultsUpdated,
  ISampleReceived,
  ISubscriptionInvoicePaid,
  ISubscriptionInvoicePaymentFailed,
  ISubscriptionRenewed,
} from 'Notifications/models/notificationHistoryDetails';

interface IEventNotificationDetails {
  details: {};
  rawDetails: {};
  url?: string;
}

function parseToModel<T>(data: Object, parseFunc: (data: T) => IEventNotificationDetails): IEventNotificationDetails {
  const convertedData = data as T;

  try {
    return parseFunc(convertedData);
  } catch (e) {
    console.error(e);
    return {details: {}, rawDetails: {}};
  }
}

export function parseEventNotificationDetails(
  eventNotification: string,
  eventType: NotificationEventType,
  currentUserId?: Nullable<number>
): IEventNotificationDetails {
  const parsedData = JSON.parse(eventNotification, toCamelCase);

  switch (eventType) {
    case NotificationEventType.ChatRequestAccepted: {
      return parseToModel<IChatRequestAccepted>(parsedData, (data) => {
        return {
          details: {Name: convertUserName(data.firstName, data.lastName)},
          url: `/message-center/messages/${data.userId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.ChatRequestCreated: {
      return parseToModel<IChatRequestCreated>(parsedData, (data) => {
        return {
          details: {Name: convertUserName(data.firstName, data.lastName)},
          url: `/message-center/outgoing-request/${data.userId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.ChatRequestReceived: {
      return parseToModel<IChatRequestReceived>(parsedData, (data) => {
        return {
          details: {'From user': convertUserName(data.firstName, data.lastName), Message: data.message},
          url: `/message-center/incoming-request/${data.userId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.ChatRequestRejected: {
      return parseToModel<IChatRequestRejected>(parsedData, (data) => {
        return {
          details: {'By user': convertUserName(data.firstName, data.lastName)},
          url: `/message-center/outgoing-request/${data.userId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.ContactRequested: {
      return parseToModel<IContactRequested>(parsedData, (data) => {
        return {
          details: {Email: data.email, 'Phone number': data.phone, Message: data.message, 'User name': data.name},
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.HorsePublicityRequested: {
      return parseToModel<IHorsePublicityRequested>(parsedData, (data) => {
        return {details: {Message: data.message}, url: `/horse/${data.horseId}`, rawDetails: data};
      });
    }

    case NotificationEventType.HorseTransferRequestCreated: {
      return parseToModel<IHorseTransferRequestCreated>(parsedData, (data) => {
        return {
          details: {
            'Request Date': convertUTCToClientDate(data.request.createDate, FRONTEND_DATE_TIME),
            Name: convertUserName(data.request.providedFirstName, data.request.providedLastName),
            Email: data.request.providedEmail,
            'Phone Number': data.request.providedPhone,
          },
          url: `/horse/${data.horseId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.HorseTransferRequestRejected: {
      return parseToModel<IHorseTransferRequestRejected>(parsedData, (data) => {
        return {
          details: {
            'Request Date': convertUTCToClientDate(data.createDate, FRONTEND_DATE_TIME),
            'Horse Name': data.horseName,
            Name: convertUserName(data.providedFirstName, data.providedLastName),
            Email: data.providedEmail,
            'Phone Number': data.providedPhone,
            'Reject Reason': data.reason,
            'Owner Email': data.ownerEmail,
          },
          url: '',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.MessageFromUserReceived: {
      return parseToModel<IMessageFromUserReceived>(parsedData, (data) => {
        return {
          details: {'From User': convertUserName(data.firstName, data.lastName)},
          url: `/message-center/messages/${data.userId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.NewSampleRequested: {
      return parseToModel<INewSampleRequested>(parsedData, (data) => {
        return {
          details: {
            'Order Id': data.orderId,
            'Owner Email': data.ownerEmail,
            'Owner Name': convertUserName(data.ownerFirstName, data.ownerLastName),
            'Horse Name': data.horseName,
            Reason: data.reason,
            Description: data.description,
          },
          url: `/horse/${data.horseId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.OrderPlaced: {
      return parseToModel<IOrderPlaced>(parsedData, (data) => {
        return {
          details: {'Order Id': data.orderId},
          url: currentUserId ? `/user/user-profile/${currentUserId}/tests` : '',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.PaymentConfirmed: {
      return parseToModel<IPaymentConfirmed>(parsedData, (data) => {
        return {
          details: {'Order Id': data.orderId},
          url: currentUserId ? `/user/user-profile/${currentUserId}/tests` : '',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.PaymentMethodChanged: {
      return parseToModel<IPaymentMethodChanged>(parsedData, (data) => {
        return {
          details: {
            'New payment method': `${data.newBrand}...${data.newLast4}`,
            'Last payment method': `${data.oldBrand}...${data.oldLast4}`,
          },
          url: '/account-details/subscriptions',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.ProductChanged: {
      return parseToModel<IProductChanged>(parsedData, (data) => {
        return {
          details: {
            'Next subscription': data.productName,
            'Date of purchase': convertUTCToClientDate(data.startDate, FRONTEND_DATE_TIME),
          },
          url: '/account-details/subscriptions',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.ReportUpdated: {
      return parseToModel<IReportUpdated>(parsedData, (data) => {
        return {details: {}, url: `/horse/${data.horseId}`, rawDetails: data};
      });
    }

    case NotificationEventType.ResultsReady: {
      return parseToModel<IResultsReady>(parsedData, (data) => {
        return {details: {}, url: `/horse/${data.horseId}`, rawDetails: data};
      });
    }

    case NotificationEventType.ResultsUpdated: {
      return parseToModel<IResultsUpdated>(parsedData, (data) => {
        return {details: {}, url: `/horse/${data.horseId}`, rawDetails: data};
      });
    }

    case NotificationEventType.SampleReceived: {
      return parseToModel<ISampleReceived>(parsedData, (data) => {
        return {
          details: {
            Name: convertUserName(data.ownerFirstName, data.ownerLastName),
            Email: data.ownerEmail,
            'Horse Name': data.horseName,
          },
          url: `/horse/${data.horseId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.SubscriptionCanceled: {
      return {details: {}, url: '/account-details/subscriptions', rawDetails: {}};
    }

    case NotificationEventType.SubscriptionInvoicePaid: {
      return parseToModel<ISubscriptionInvoicePaid>(parsedData, (data) => {
        return {
          details: {
            'Stripe Subscription Id': data.stripeSubscriptionId,
            'Payment Intent Id': data.paymentIntentId,
            'Is First Payment': data.isFirstPayment ? 'true' : 'false',
          },
          url: '/account-details/subscriptions',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.SubscriptionInvoicePaymentFailed: {
      return parseToModel<ISubscriptionInvoicePaymentFailed>(parsedData, (data) => {
        return {
          details: {'Stripe Subscription Id': data.stripeSubscriptionId, 'Customer Id': data.customerId},
          url: '/account-details/subscriptions',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.SubscriptionRenewed: {
      return parseToModel<ISubscriptionRenewed>(parsedData, (data) => {
        return {
          details: {
            Subscription: data.productName,
            'Date of purchase': convertUTCToClientDate(data.startDate, FRONTEND_DATE_TIME),
            'Next payment': convertUTCToClientDate(data.currentPeriodEnd, FRONTEND_DATE_TIME),
            'Stripe Subscription Id': data.stripeSubscriptionId,
          },
          url: '/account-details/subscriptions',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.OperatingModeUpdated: {
      return parseToModel<IOperatingModeUpdate>(parsedData, (data) => {
        return {
          details: {},
          url: '',
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.HorseTransferredToUser: {
      return parseToModel<IHorseTransferRequestCompleteToUser>(parsedData, (data) => {
        return {
          details: {
            'Request Date': convertUTCToClientDate(data.request.createDate, FRONTEND_DATE_TIME),
            Name: convertUserName(data.request.providedFirstName, data.request.providedLastName),
            Email: data.request.providedEmail,
            'Phone Number': data.request.providedPhone,
          },
          url: `/horse/${data.horseId}`,
          rawDetails: data,
        };
      });
    }

    case NotificationEventType.HorseTransferredFromUser: {
      return parseToModel<HorseTransferRequestCompleteFromUser>(parsedData, (data) => {
        return {
          details: {
            'Owner name': convertUserName(data.horseInfo.firstName, data.horseInfo.lastName),
            'Horse name': data.horseInfo.horseName,
          },
          url: `/horse/${data.horseId}`,
          rawDetails: data,
        };
      });
    }
  }
}
