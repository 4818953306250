import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import * as testResultsSelectors from 'Admin/AdminDashboard/store/adminTestResults/selectors';
import * as testResultsActions from 'Admin/AdminDashboard/store/adminTestResults/actions';
import * as inbreedingCoefficientsSelectors from 'Admin/AdminDashboard/store/adminInbreedingCoefficients/selectors';
import * as inbreedingCoefficientsActions from 'Admin/AdminDashboard/store/adminInbreedingCoefficients/actions';
import {IAppState} from 'Common/store/IAppState';
import Icon, {IconName} from 'Icon/components/Icon';
import {FileUploader, Pagination} from 'Common/components/Controls/index';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import ColorPalette from 'Common/constants/ColorPalette';
import {withCurrency} from 'Common/helpers/withCurrency';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {AdminOrdersModule} from 'Admin/AdminDashboard/store/adminOrders/adminOrdersModule';
import {actions as ordersAction, selectors as ordersSelectors} from 'Admin/AdminDashboard/store/adminOrders/index';
import Loading from 'Loading/components/Loading';

import {ExpandedRowStyles} from 'Common/components/Table/view/Row';
import {IOrderAdmin} from 'Admin/AdminDashboard/models/Order/IOrderAdmin';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import WarningModal, {ModalTypes} from 'Common/components/Modal/WarningModal';
import {getCommonErrors, getErrorDetails} from 'Common/helpers/ErrorHelper';
import {AdminTestResultsModule} from 'Admin/AdminDashboard/store/adminTestResults/module';
import {AdminInbreedingCoefficientsModule} from 'Admin/AdminDashboard/store/adminInbreedingCoefficients/module';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import withDate from 'Common/helpers/withDate';
import OrdersDetails from 'Admin/AdminDashboard/components/Orders/OrdersDetails/OrdersDetails';
import {CellSeveral} from 'Common/components/StyledComponents/StyledComponents';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import IconedOrderStatus from 'Admin/shared/components/OrderStatus/parts/IconedOrderStatus';
import {OrderStatus} from 'Common/constants/OrderStatus';
import {OrderStatusDropdown} from 'Admin/shared/components/OrderStatus';
import {isAvailableForBah} from 'Common/helpers/isAvailableForBah';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import OrderActions from 'Admin/AdminDashboard/components/Orders/OrderActions';
import {getWorstHorseStatus} from 'Common/helpers/getWorstHorseStatus';
import {useHorseModal} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/parts/useHorseModal';
import {useCreateOrderModal} from 'Admin/AdminDashboard/helpers/hooks/useCreateOrderModal';
import {useUpdateUserModal} from 'Admin/AdminDashboard/helpers/hooks/useUserActions/parts/useUpdateUserModal';

import AvatarCellHorse from './shared/AvatarCellHorse';
import {useOrderActions} from 'Admin/AdminDashboard/helpers/hooks/useOrderActions/useOrderActions';
import {convertOrderAdminToOrderHistory} from 'Admin/AdminDashboard/components/Orders/converters/order';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import withOrderActions, {
  getWithOrderActionsModules,
  IWithOrderActionsProps,
} from 'Admin/AdminDashboard/helpers/withOrderActions';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {
  actions as adminOnlineReportActions,
  selectors as adminOnlineReportSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/index';
import {HORSE_GRANTED_MARKED_ICON, USER_SUBSCRIPTION_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';
import PackageSection from './parts/PackageSection';
import {useAncestryUploadModal} from 'Admin/shared/helpers/hooks/useOrderActions/useAncestryUploadModal';
import {setOnlineReportTypeToStorage} from 'OnlineReport/helpers/setOnlineReportTypeToStorage';
import {AdminShortTandemRepeatUploadModule} from 'Admin/AdminDashboard/store/adminSTRUpload/module';
import {
  actions as shortTandemRepeatActions,
  selectors as shortTandemRepeatSelectors,
} from 'Admin/AdminDashboard/store/adminSTRUpload/index';
import {useCommunicationToToast} from '../../../../Common/helpers/hooks/useCommunicationToToast';
import {acStatusToBadgeColor} from 'Common/helpers/activationStatusToBadgeColor';
import {FaUserGroup} from 'react-icons/fa6';

const expandedRowStyles: ExpandedRowStyles = {content: {padding: 0}};

const AddButton = styled(PrimaryButton)`
  margin-left: 24px;
  position: relative;
`;

const OrderTable = styled.div`
  margin-top: 50px;
`;

const ErrorDetails = styled.div`
  margin-top: 20px;
`;

const CouponTooltip = styled.div`
  margin-right: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const CouponName = styled.div`
  margin: 2px 0;
`;

const fileUploaderSizeStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  opacity: 0,
  height: 50,
  width: 176,
};

const modalResponseHeader: Record<'Alert' | 'Success', string> = {
  Alert: 'Alert',
  Success: 'Success',
};

const modalSuccessResponseMessage = 'File uploaded successfully';

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IWithOrderActionsProps;

const Orders = (props: AllProps) => {
  const {
    getOrderList,
    uploadTestResults,
    orderDeleting,
    deleteOrder,
    downloadFormRequesting,
    testResultsUploading,
    ordersLoading,
    getOrderDetails,
    downloadSubmissionForm,
    setSampleStatusRequesting,
    setSampleStatus,
    newSampleRequesting,
    changeReviewStatus,
    changeReviewStatusRequesting,
    downloadReports,
    downloadReportsRequesting,
    orders,
    shortTandemRepeatUploading,
    uploadShortTandemRepeat,
    uploadInbreedingCoefficients,
    inbreedingCoefficientsUploading,
  } = props;

  const {params, selectedId, setSelectedId, changeSorting, sorting, handleCountPerPage, handlePageSelect, searchBar} =
    useCommonAdminPageData<IOrderAdmin>({
      getItems: getOrderList,
      searchBarPlaceholder: 'Search for orders by any keyword',
    });

  const reloadOrders = useCallback(() => {
    getOrderList(params);
    if (selectedId) {
      getOrderDetails(selectedId);
    }
  }, [getOrderDetails, getOrderList, params, selectedId]);

  useEffect(() => {
    // This necessary for Review status changing
    setOnlineReportTypeToStorage(OnlineReportType.ReviewAdmin);
  }, []);

  useEffect(() => {
    if (selectedId) {
      setSelectedId(selectedId);
    }
  }, [selectedId, setSelectedId]);

  const {
    openDeleteModal,
    orderActionsModal,
    openSampleReceivedModal,
    openSampleNotReceivedModal,
    openRequestNewSampleModal,
    openUpdatePaymentModal,
    dismissReviewConfirmModal,
    openDismissReviewConfirmModal,
  } = useOrderActions({
    data: {
      deleteOrderAction: {action: deleteOrder, communication: orderDeleting},
      downloadSubmissionFormAction: {communication: downloadFormRequesting},
      setSampleStatusAction: {action: setSampleStatus, communication: setSampleStatusRequesting},
      requestNewSampleAction: {communication: newSampleRequesting},
      downloadReportsAction: {communication: downloadReportsRequesting},
      dismissReviewAction: {action: changeReviewStatus, communication: changeReviewStatusRequesting},
    },
    onSuccess: reloadOrders,
  });

  const openLiveReport = useCallback((horseId: number, orderId?: number) => {
    if (orderId) {
      return `/review-admin/online-report/${horseId}/${orderId}/summary`;
    } else {
      return `/admin/online-report/${horseId}/0/summary`;
    }
  }, []);

  const {createOrderModal, openCreateOrderModal} = useCreateOrderModal({onSuccess: reloadOrders});
  const {updateHorseModal, openUpdateHorseModal} = useHorseModal({onSuccess: reloadOrders});
  const {openUpdateUserModal, updateUserModal} = useUpdateUserModal({onSuccess: reloadOrders});
  const {ancestryUploadModal, openAncestryUploadModal} = useAncestryUploadModal({onSuccess: reloadOrders});

  const [isTestResultMessageModalOpen, setIsTestResultMessageModalOpen] = useState(false);
  const openTestResultsMessageModal = useCallback(() => setIsTestResultMessageModalOpen(true), []);
  const closeTestResultsMessageModal = useCallback(() => setIsTestResultMessageModalOpen(false), []);

  const [isInbreedingCoefficientsMessageModalOpen, setIsInbreedingCoefficientsMessageModalOpen] = useState(false);
  const openInbreedingCoefficientsMessageModal = useCallback(
    () => setIsInbreedingCoefficientsMessageModalOpen(true),
    []
  );
  const closeInbreedingCoefficientsMessageModal = useCallback(
    () => setIsInbreedingCoefficientsMessageModalOpen(false),
    []
  );

  const [isStrMessageModalOpen, setIsStrMessageModalOpen] = useState(false);
  const openStrMessageModal = useCallback(() => setIsStrMessageModalOpen(true), []);
  const closeStrMessageModal = useCallback(() => setIsStrMessageModalOpen(false), []);

  const [fileLoadError, setFileLoadError] = useState<string | null>(null);
  const closeFileLoadErrorModal = useCallback(() => setFileLoadError(null), []);

  const [strFileLoadError, setStrFileLoadError] = useState<string | null>(null);
  const closeStrFileLoadErrorModal = useCallback(() => setStrFileLoadError(null), []);

  useOnSuccessCommunication(testResultsUploading, () => {
    reloadOrders();
    openTestResultsMessageModal();
  });
  useOnErrorCommunication(testResultsUploading, openTestResultsMessageModal);
  const isTestResultSuccessUpload = !testResultsUploading.error;
  const messageTestResultUploadEnd = isTestResultSuccessUpload
    ? modalSuccessResponseMessage
    : getCommonErrors(testResultsUploading.error) || 'Error on load test results';

  const errorDetails = !isTestResultSuccessUpload ? getErrorDetails(testResultsUploading.error) : '';

  useOnSuccessCommunication(shortTandemRepeatUploading, () => {
    reloadOrders();
  });

  useCommunicationToToast(shortTandemRepeatUploading, 'File was succesfully uploaded and processed');
  useOnErrorCommunication(shortTandemRepeatUploading, openStrMessageModal);
  const isStrSuccessUpload = !shortTandemRepeatUploading.error;
  const messageStrUploadEnd = isStrSuccessUpload
    ? modalSuccessResponseMessage
    : getCommonErrors(shortTandemRepeatUploading.error) || 'Error on load short tandem repeat results';

  const strErrorDetails = !isStrSuccessUpload ? getErrorDetails(shortTandemRepeatUploading.error) : '';

  useOnSuccessCommunication(inbreedingCoefficientsUploading, () => {
    reloadOrders();
    openInbreedingCoefficientsMessageModal();
  });

  useOnErrorCommunication(inbreedingCoefficientsUploading, openInbreedingCoefficientsMessageModal);
  const isInbreedingCoefficientsSuccessUpload = !inbreedingCoefficientsUploading.error;
  const messageInbreedingCoefficientsUploadEnd = isInbreedingCoefficientsSuccessUpload
    ? modalSuccessResponseMessage
    : getCommonErrors(inbreedingCoefficientsUploading.error) || 'Error on load inbreeding coefficient data.';

  const inbreedingCoefficientsErrorDetails = !isInbreedingCoefficientsSuccessUpload
    ? getErrorDetails(inbreedingCoefficientsUploading.error)
    : '';

  const renderExpandContent = useCallback(
    ({id, owner, date}: IOrderAdmin) => {
      return <OrdersDetails orderId={id} owner={owner.name} createDate={date} onChange={reloadOrders} />;
    },
    [reloadOrders]
  );

  const renderOrderStatus = useCallback(
    ({id, horses, owner}: IOrderAdmin) => {
      if (horses.length === 0) {
        return <CellSeveral>N/A</CellSeveral>;
      }

      if (horses.length > 1) {
        return <IconedOrderStatus status={getWorstHorseStatus(horses) || OrderStatus.orderPlaced} />;
      }

      const {id: horseId, status, hasSample, hasNotResults, name, statusUpdateDates} = horses[0];

      return (
        <OrderStatusDropdown
          status={status}
          horseId={horseId}
          orderId={id}
          horseName={name}
          ownerName={owner.name}
          onChange={reloadOrders}
          completedStatuses={hasSample ? OrderStatus.sampleReceived : undefined}
          uncompletedStatuses={hasNotResults ? OrderStatus.resultsReady : undefined}
          statusUpdateDates={statusUpdateDates}
        />
      );
    },
    [reloadOrders]
  );

  const renderPrice = useCallback(({price, paymentDetailed}: IOrderAdmin) => {
    return (
      <div className="d-flex justify-content-end">
        {paymentDetailed.couponCodes?.length > 0 && (
          <CouponTooltip>
            <Tooltip
              content={paymentDetailed.couponCodes.map((code, i) => (
                <CouponName key={i}>{code}</CouponName>
              ))}
            >
              <div>
                <Icon name={IconName.Coupon} />
              </div>
            </Tooltip>
          </CouponTooltip>
        )}
        {withCurrency(price)}
      </div>
    );
  }, []);

  const setSelectedOrderId = useCallback((id: string | number) => setSelectedId(Number(id)), [setSelectedId]);

  const isLoading = ordersLoading.isRequesting;

  const isShowPagination = orders.length > 0;

  return (
    <div>
      {updateHorseModal}
      {createOrderModal}
      {updateUserModal}
      {orderActionsModal}
      {dismissReviewConfirmModal}
      {ancestryUploadModal}
      <WarningModal
        isOpen={!!fileLoadError}
        modalType={ModalTypes.Alert}
        modalTitle={modalResponseHeader.Alert}
        onClose={closeFileLoadErrorModal}
        onSuccess={closeFileLoadErrorModal}
      >
        {fileLoadError}
      </WarningModal>
      <WarningModal
        isOpen={isTestResultMessageModalOpen}
        modalType={ModalTypes.Alert}
        modalTitle={isTestResultSuccessUpload ? modalResponseHeader.Success : modalResponseHeader.Alert}
        onClose={closeTestResultsMessageModal}
        onSuccess={closeTestResultsMessageModal}
      >
        <Scrollbar maxHeight="40vh">
          {messageTestResultUploadEnd}
          {errorDetails && (
            <ErrorDetails>
              <ul>
                {errorDetails.map((err) => {
                  return <li>{err} </li>;
                })}
              </ul>
            </ErrorDetails>
          )}
        </Scrollbar>
      </WarningModal>

      <WarningModal
        isOpen={!!strFileLoadError}
        modalType={ModalTypes.Alert}
        modalTitle={modalResponseHeader.Alert}
        onClose={closeStrFileLoadErrorModal}
        onSuccess={closeStrFileLoadErrorModal}
      >
        {strFileLoadError}
      </WarningModal>

      <WarningModal
        isOpen={isStrMessageModalOpen}
        modalType={ModalTypes.Alert}
        modalTitle={isStrSuccessUpload ? modalResponseHeader.Success : modalResponseHeader.Alert}
        onClose={closeStrMessageModal}
        onSuccess={closeStrMessageModal}
      >
        <Scrollbar maxHeight="40vh">
          {messageStrUploadEnd}
          {strErrorDetails && (
            <ErrorDetails>
              <ul>
                {strErrorDetails.map((err) => {
                  return <li>{err} </li>;
                })}
              </ul>
            </ErrorDetails>
          )}
        </Scrollbar>
      </WarningModal>
      <WarningModal
        isOpen={isInbreedingCoefficientsMessageModalOpen}
        modalType={ModalTypes.Alert}
        modalTitle={isInbreedingCoefficientsSuccessUpload ? modalResponseHeader.Success : modalResponseHeader.Alert}
        onClose={closeInbreedingCoefficientsMessageModal}
        onSuccess={closeInbreedingCoefficientsMessageModal}
      >
        <Scrollbar maxHeight="40vh">
          {messageInbreedingCoefficientsUploadEnd}
          {inbreedingCoefficientsErrorDetails && (
            <ErrorDetails>
              <ul>
                {inbreedingCoefficientsErrorDetails.map((err) => {
                  return <li>{err} </li>;
                })}
              </ul>
            </ErrorDetails>
          )}
        </Scrollbar>
      </WarningModal>

      <AdminPageLayout className="d-flex flex-column mt-4">
        <div className="d-flex flex-row">
          <AddButton
            isLoading={testResultsUploading.isRequesting}
            icon={{name: IconName.Upload, color: ColorPalette.red7}}
            variant="outlined"
            size="medium"
            style={{marginLeft: '0px'}}
          >
            <FileUploader
              style={fileUploaderSizeStyle}
              onChange={uploadTestResults}
              acceptableTypes={['.xlsx']}
              isShowError={false}
              onError={setFileLoadError}
            />
            Upload Diagnostic
          </AddButton>
          <AddButton
            variant="outlined"
            onClick={openAncestryUploadModal}
            icon={{name: IconName.Upload, color: ColorPalette.red7}}
          >
            Upload Ancestry
          </AddButton>
          <AddButton
            isLoading={testResultsUploading.isRequesting}
            icon={{name: IconName.Upload, color: ColorPalette.red7}}
            variant="outlined"
            size="medium"
          >
            <FileUploader
              style={fileUploaderSizeStyle}
              onChange={uploadShortTandemRepeat}
              acceptableTypes={['.json']}
              isShowError={false}
              onError={setStrFileLoadError}
            />
            Upload STR
          </AddButton>
          <AddButton
            isLoading={inbreedingCoefficientsUploading.isRequesting}
            icon={{name: IconName.Upload, color: ColorPalette.red7}}
            variant="outlined"
            size="medium"
          >
            <FileUploader
              style={fileUploaderSizeStyle}
              onChange={uploadInbreedingCoefficients}
              acceptableTypes={['.csv']}
              isShowError={false}
              onError={setFileLoadError}
              maxSize={150000000}
            />
            Upload Inbreeding Coefficients
          </AddButton>
        </div>
        <div className="d-flex flex-row mt-4">
          <div className="flex-grow-1">{searchBar}</div>
          <div>
            <AddButton size="medium" onClick={openCreateOrderModal}>
              + Create order
            </AddButton>
          </div>
        </div>
      </AdminPageLayout>
      <OrderTable className="position-relative">
        {isLoading && <Loading />}
        <Table<IOrderAdmin>
          data={orders}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
          expandable={true}
          renderExpandContent={renderExpandContent}
          expandedRowStyles={expandedRowStyles}
          onExpand={setSelectedOrderId}
          defaultExpandedRow={String(selectedId)}
        >
          <Cell<IOrderAdmin> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width="100px" />
          <Cell<IOrderAdmin> header="Date" dataKey="date" render={({date}) => withDate(date)} width="5%" />
          <Cell<IOrderAdmin>
            header="Buyer"
            dataKey="owner"
            render={({owner, horses}) => {
              const isOrderOwnerDifferentThanHorseOwner = horses.some(
                (horse) => horse?.owner && horse.owner.id != owner.id
              );
              return (
                <div className="position-relative">
                  <AvatarCell
                    type="owner"
                    avatarUrl={owner.avatar?.url}
                    profileUrl={`/admin/user/user-profile/${owner.id}/horses`}
                    label={owner.name}
                    onLabelClick={() => openUpdateUserModal(owner.id)}
                    isMarkedIcon={owner.hasSubscription}
                    markedIcon={USER_SUBSCRIPTION_MARKED_ICON}
                    hasOnlineReportAccess={owner.hasOnlineReportAccess}
                  />
                  {isOrderOwnerDifferentThanHorseOwner && (
                    <FaUserGroup
                      className="position-absolute"
                      style={{bottom: !owner.hasOnlineReportAccess ? '-10px' : '0px', right: '0px'}}
                      color="orange"
                    />
                  )}
                </div>
              );
            }}
            width="15%"
          />
          <Cell<IOrderAdmin>
            header="Horse"
            render={({horses}) => {
              if (!horses || horses.length === 0) {
                return <CellSeveral>N/A</CellSeveral>;
              }

              return horses.length > 1 ? (
                <AvatarCell type="several" label="Several" tooltip={horses.map((h) => h.name).join(', ')} />
              ) : !horses[0].name ? (
                <AvatarCellHorse
                  label="N/A"
                  type="horse"
                  isArchived={false}
                  isDeleted={false}
                  isGeneticDataHidden={false}
                />
              ) : (
                <AvatarCellHorse
                  type="horse"
                  label={horses[0]?.name}
                  avatarUrl={horses[0]?.avatar?.url}
                  profileUrl={`/admin/horse/${horses[0]?.id}`}
                  onLabelClick={() => openUpdateHorseModal(horses[0]?.id)}
                  isArchived={horses[0]?.isArchived}
                  isAvailableArchivedProfile={true}
                  isDeleted={horses[0]?.isDeleted}
                  isMarkedIcon={isAvailableForBah(horses[0]?.availabilityCriteria)}
                  markedIcon={HORSE_GRANTED_MARKED_ICON}
                  sampleId={horses[0]?.sample?.sampleId}
                  isGeneticDataHidden={horses[0]?.isGeneticDataHidden}
                />
              );
            }}
            width="15%"
          />
          <Cell<IOrderAdmin>
            header="Package"
            render={({horses}) => {
              if (!horses || horses.length === 0) {
                return <CellSeveral>N/A</CellSeveral>;
              }

              return horses.length > 1 ? (
                <CellSeveral>Several</CellSeveral>
              ) : (
                <PackageSection packages={horses[0].packages} />
              );
            }}
            width="10%"
          />
          <Cell<IOrderAdmin>
            header="Codes"
            render={({activationCodes}) => {
              if (!activationCodes || activationCodes.length === 0) {
                return <CellSeveral>N/A</CellSeveral>;
              }

              return activationCodes.length > 1 ? (
                <AvatarCell
                  hideAvatar
                  type="several"
                  label="Several"
                  tooltip={activationCodes.map((ac) => ac.code).join(', ')}
                />
              ) : (
                <div className="">
                  <div className="m-0 mr-2 ">{activationCodes[0]?.code}</div>
                  <div
                    className={`badge rounded-pill ${acStatusToBadgeColor(activationCodes[0]?.status)}`}
                    style={{height: 'fit-content'}}
                  >
                    {activationCodes[0]?.status}
                  </div>
                </div>
              );
            }}
            width="10%"
          />
          <Cell<IOrderAdmin> header="Status" render={renderOrderStatus} width="15%" memoized={false} />
          <Cell<IOrderAdmin> header="Price" dataKey="price" render={renderPrice} width="5%" align={CellAlign.Right} />
          <Cell<IOrderAdmin>
            header="Actions"
            width="100px"
            align={CellAlign.Right}
            render={(order) => (
              <OrderActions
                order={convertOrderAdminToOrderHistory(order)}
                deleteOrderHandler={openDeleteModal}
                updatePaymentHandler={openUpdatePaymentModal}
                openLiveReportHandler={openLiveReport}
                submissionFormHandler={downloadSubmissionForm}
                sampleReceivedHandler={openSampleReceivedModal}
                sampleNotReceivedHandler={openSampleNotReceivedModal}
                requestNewSampleHandler={openRequestNewSampleModal}
                downloadResultsHandler={downloadReports}
                dismissReviewHandler={openDismissReviewConfirmModal}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={props.pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </OrderTable>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  orders: ordersSelectors.selectOrders(state),
  ordersLoading: ordersSelectors.selectCommunication(state, 'ordersLoading'),
  testResultsUploading: testResultsSelectors.selectCommunication(state, 'testResultsUploading'),
  inbreedingCoefficientsUploading: inbreedingCoefficientsSelectors.selectCommunication(
    state,
    'inbreedingCoefficientsUploading'
  ),
  changeReviewStatusRequesting: adminOnlineReportSelectors.selectCommunication(state, 'changeReviewStatusRequesting'),
  shortTandemRepeatUploading: shortTandemRepeatSelectors.selectCommunication(state, 'shortTandemRepeatUploading'),
  pagination: ordersSelectors.selectPagination(state),
});

const mapDispatchToProps = {
  getOrderList: ordersAction.getOrders,
  getOrderDetails: ordersAction.getOrderDetails,
  uploadTestResults: testResultsActions.uploadTestResults,
  uploadInbreedingCoefficients: inbreedingCoefficientsActions.uploadInbreedingCoefficients,
  changeReviewStatus: adminOnlineReportActions.changeReviewStatus,
  uploadShortTandemRepeat: shortTandemRepeatActions.uploadShortTandemRepeat,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(withOrderActions(Orders)), [
  AdminTestResultsModule,
  AdminInbreedingCoefficientsModule,
  AdminOrdersModule,
  AdminShortTandemRepeatUploadModule,
  getWithOrderActionsModules(),
]);
