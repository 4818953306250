import * as React from 'react';
import {Navigate, Route, To} from 'react-router-dom';

import {BUSINESS_PORTAL_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import {getRoutes} from 'Common/helpers/getRoutes';

function AppAuthRoutingAssociationEmployee(from: To) {
  return [
    ...getRoutes(BUSINESS_PORTAL_NAVIGATION_ROUTES),
    <Route key="navigate" path="*" element={<Navigate to={from} />} />,
  ];
}

export default AppAuthRoutingAssociationEmployee;
