import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IStaticResource} from '../models/IStaticResource';
import {IRequestParams} from 'Common/models/IRequestParams';
import {ICreateStaticResourceRequest} from 'Admin/AdminSettings/models/ICreateStaticResourceRequest';
import {IUpdateStaticResourceRequest} from 'Admin/AdminSettings/models/IUpdateStaticResourceRequest';

const getStaticResources = async (params?: IRequestParams): Promise<IListResponse<IStaticResource>> => {
  const response = await axiosWrapper.get<IListResponse<IStaticResource>>(`/StaticResourcesAdmin`, {params});
  return {
    ...response.data,
    data: response.data.data,
  };
};

const getStaticResource = async (id: number): Promise<IStaticResource> => {
  const response = await axiosWrapper.get<IObjectResponse<IStaticResource>>(`/StaticResourcesDetailsAdmin/${id}`);
  return response.data.data;
};

const createStaticResource = async (params: ICreateStaticResourceRequest): Promise<IStaticResource> => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => formData.append(key, value));

  const response = await axiosWrapper.post<IObjectResponse<IStaticResource>>('/StaticResourcesCreateAdmin', formData);
  return response.data.data;
};

const updateStaticResource = async (params: IUpdateStaticResourceRequest): Promise<IStaticResource> => {
  const response = await axiosWrapper.put<IObjectResponse<IStaticResource>>(`/StaticResourcesUpdateAdmin`, params);
  return response.data.data;
};

const deleteStaticResource = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/StaticResourcesDeleteAdmin/${id}`);
};

const uploadStaticResourceFile = async (id: number, uploadedFile: File): Promise<IStaticResource> => {
  const formData = new FormData();
  formData.append('file', uploadedFile);
  formData.append('id', `${id}`);

  const response = await axiosWrapper.post<IObjectResponse<IStaticResource>>(
    `/StaticResourcesResourceUpdateAdmin`,
    formData
  );
  return response.data.data;
};

export default {
  getStaticResources,
  getStaticResource,
  createStaticResource,
  updateStaticResource,
  deleteStaticResource,
  uploadStaticResourceFile,
};
