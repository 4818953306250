import {Reducer} from 'redux';

import {
  ShortTandemRepeatReportActions,
  getShortTandemRepeateportActionTypes,
  IShortTandemRepeatReportState,
} from '../types';

const initialState: IShortTandemRepeatReportState['data'] = {
  shortTandemRepeatReport: [],
};

export const dataReducer: Reducer<IShortTandemRepeatReportState['data'], ShortTandemRepeatReportActions> = (
  state = initialState,
  action: ShortTandemRepeatReportActions
) => {
  switch (action.type) {
    case getShortTandemRepeateportActionTypes.REQUEST: {
      return {...state, shortTandemRepeatReport: initialState.shortTandemRepeatReport};
    }
    case getShortTandemRepeateportActionTypes.SUCCESS: {
      return {...state, shortTandemRepeatReport: action.payload};
    }

    default: {
      return state;
    }
  }
};
