import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  ShortTandemRepeatReportActions,
  getShortTandemRepeateportActionTypes,
  IShortTandemRepeatReportState,
} from '../types';

export const communicationReducer = combineReducers<
  IShortTandemRepeatReportState['communications'],
  ShortTandemRepeatReportActions
>({
  shortTandemRepeatReportLoading: makeCommunicationReducerFromEnum(getShortTandemRepeateportActionTypes),
});
