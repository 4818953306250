import {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Indicator from 'Common/components/Indicator/Indicator';
import {IIndicator} from 'Common/models/IIndicator';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {maxBreakpoints} from 'Common/constants/Breakpoints';

const Container = styled.div`
  font-size: 12px;
  @media ${maxBreakpoints.md} {
    font-size: 18px;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 5px;
`;

const Label = styled.div`
  font-weight: 700;
`;

const Title = styled.div<{isDisabled?: boolean}>`
  font-weight: 400;
  color: ${({isDisabled}) => (isDisabled ? ColorPalette.gray2 : 'unset')};
`;

const IndicatorWrapper = styled.div`
  margin-top: 4px;
`;

interface IProps {
  value: number;
  max: number;
  color: string;
  label: string;
  title: string;
  isEmpty?: boolean;
  description?: string;
}

function MiniCharacteristic(props: IProps) {
  const {value, max, color, label, title, isEmpty, description} = props;
  const {isMobile} = useMediaQuery();

  const indicator: IIndicator = {
    height: isMobile ? 9 : 14,
    segmentColor: color,
    segmentsMaxCount: max,
    value: value,
  };

  return (
    <Container>
      <Header>
        <Label>{label}</Label>
        <Title isDisabled={isEmpty}>{title}</Title>
      </Header>
      <IndicatorWrapper>
        <Indicator indicator={indicator} tooltip={description} />
      </IndicatorWrapper>
    </Container>
  );
}

export default memo(MiniCharacteristic);
