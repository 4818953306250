import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  setConnectedUserActions,
  setConnectedUserAtionTypes,
  setDisconnectedUserActions,
  setDisconnectedUserActionTypes,
  receiveMessageActions,
  receiveMessageActionTypes,
  setSentMessageActions,
  setSentMessageActionTypes,
  sendMessageActions,
  sendMessageActionTypes,
  resetSendingMessageActionTypes,
  deleteMessageQueueActions,
  deleteMessageQueueActionTypes,
  IDeleteProps,
} from './types';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {IUserMessageRequest} from 'SignalR/models/IUserMessageRequest';

export const setConnectedUser =
  (userId: number): ActionResult<IAppState, void, setConnectedUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setConnectedUserAtionTypes.REQUEST));
      dispatch(action(setConnectedUserAtionTypes.SUCCESS, userId));
    } catch (error) {
      dispatch(action(setConnectedUserAtionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setDisconnectedUser =
  (userId: number): ActionResult<IAppState, void, setDisconnectedUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setDisconnectedUserActionTypes.REQUEST));
      dispatch(action(setDisconnectedUserActionTypes.SUCCESS, userId));
    } catch (error) {
      dispatch(action(setDisconnectedUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setReceiveMessage =
  (params: IMessageQueue): ActionResult<IAppState, void, receiveMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(receiveMessageActionTypes.REQUEST));
      dispatch(action(receiveMessageActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(receiveMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setSentMessage =
  (params: IMessageQueue): ActionResult<IAppState, void, setSentMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setSentMessageActionTypes.REQUEST));
      dispatch(action(setSentMessageActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(setSentMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const sendMessage =
  (sendMessage: IUserMessageRequest): ActionResult<IAppState, void, sendMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(sendMessageActionTypes.REQUEST));
      dispatch(action(sendMessageActionTypes.SUCCESS, sendMessage));
    } catch (error) {
      dispatch(action(sendMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteMessagesFromQueue =
  (deleteMessage: IDeleteProps): ActionResult<IAppState, void, deleteMessageQueueActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteMessageQueueActionTypes.REQUEST));
      dispatch(action(deleteMessageQueueActionTypes.SUCCESS, deleteMessage));
    } catch (error) {
      dispatch(action(deleteMessageQueueActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetSendingMessage = () => action(resetSendingMessageActionTypes.RESET);
