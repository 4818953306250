import {EventName} from 'Common/constants/EventName';

export function useEventBus() {
  function emit<T = any>(eventName: EventName, data?: T) {
    const event = new CustomEvent(eventName, {detail: data});
    window.dispatchEvent(event);
  }

  function listen(eventName: EventName, func: any): {stopListening: () => void} {
    const customFunc = (data: any) => func(data.detail);
    window.addEventListener(eventName, customFunc);

    return {
      stopListening: () => removeListener(eventName, customFunc),
    };
  }

  function removeListener(eventName: EventName, func: any) {
    window.removeEventListener(eventName, func);
  }

  return {emit, listen, removeListener};
}
