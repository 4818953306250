import {IListResponse, IPagination} from 'Common/models/IResponse';
import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IFilterProperty} from 'FacetFilter/models/IFilterProperty';
import {IUserToAdminChat} from '../models/IUserToAdminChat';
import {IUserToAdminChatDetailsAdmin} from '../models/IUserToAdminChatDetailsAdmin';
import {IUserToAdminChatMessageAdmin} from '../models/IUserToAdminChatMessageAdmin';

const STATE_NAME = 'adminSupport';

export interface IAdminSupportState {
  data: {
    userToAdminChats: IUserToAdminChat[];
    userToAdminChatsFilters: IFilterProperty[];
    userToAdminChatMessages: Nullable<IUserToAdminChatMessageAdmin>;
    userToAdminChatDetails: Nullable<IUserToAdminChatDetailsAdmin>;
  };
  communications: {
    userToAdminChatsLoading: ICommunication;
    userToAdminChatsFiltersLoading: ICommunication;
    userToAdminChatMessagesLoading: ICommunication;
    userToAdminChatDetailsLoading: ICommunication;
    userToAdminChatMessagesSubscribing: ICommunication;
    userToAdminChatMessagesUnsubscribing: ICommunication;
    userToAdminChatDutyTaking: ICommunication;
    userToAdminChatDutyLeaving: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getUserToAdminChatsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_TO_ADMIN_CHATS');
export type getUserToAdminChatsActions = MakeCommunicationActions<
  typeof getUserToAdminChatsActionTypes,
  {success: IListResponse<IUserToAdminChat>}
>;

export const getUserToAdminChatsFiltersActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_CHATS_FILTERS'
);
export type getUserToAdminChatsFiltersActions = MakeCommunicationActions<
  typeof getUserToAdminChatsFiltersActionTypes,
  {success: IFilterProperty[]}
>;

export const getUserToAdminChatDetailsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_CHAT_DETAILS'
);
export type getUserToAdminChatDetailsActions = MakeCommunicationActions<
  typeof getUserToAdminChatDetailsActionTypes,
  {success: IUserToAdminChatDetailsAdmin}
>;

export const getUserToAdminChatMessagesActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_CHAT_MESSAGES'
);
export type getUserToAdminChatMessagesActions = MakeCommunicationActions<
  typeof getUserToAdminChatMessagesActionTypes,
  {success: IUserToAdminChatMessageAdmin}
>;

export const resetAdminToUserChatDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'SENDING_ADMIN_TO_USER_MESSAGE'
);
export type resetAdminToUserChatDetailsActions = MakeResetCommunicationActions<
  typeof resetAdminToUserChatDetailsActionTypes
>;

export const subscribeUserToAdminMessageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SUBSCRIBE_USER_TO_ADMIN_MESSAGE'
);
export type subscribeUserToAdminMessageActions = MakeCommunicationActions<
  typeof subscribeUserToAdminMessageActionTypes,
  {}
>;

export const unsubscribeUserToAdminMessageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UNSUBSCRIBE_USER_TO_ADMIN_MESSAGE'
);
export type unsubscribeUserToAdminMessageActions = MakeCommunicationActions<
  typeof unsubscribeUserToAdminMessageActionTypes,
  {}
>;

export const takeUserToAdminOnDutyActionTypes = makeCommunicationActionType(STATE_NAME, 'TAKE_ON_DUTY');
export type takeUserToAdminOnDutyActions = MakeCommunicationActions<typeof takeUserToAdminOnDutyActionTypes, {}>;

export const leaveUserToAdminOnDutyActionTypes = makeCommunicationActionType(STATE_NAME, 'LEAVE_ON_DUTY');
export type leaveUserToAdminOnDutyActions = MakeCommunicationActions<typeof leaveUserToAdminOnDutyActionTypes, {}>;

export type Actions =
  | getUserToAdminChatsActions
  | getUserToAdminChatsFiltersActions
  | getUserToAdminChatDetailsActions
  | getUserToAdminChatMessagesActions
  | resetAdminToUserChatDetailsActions
  | subscribeUserToAdminMessageActions
  | unsubscribeUserToAdminMessageActions
  | takeUserToAdminOnDutyActions
  | leaveUserToAdminOnDutyActions;
