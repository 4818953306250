import {IDogTestResultsUploadDetails} from 'Admin/AdminDashboard/models/Dog/IDogTestResultsUploadDetails';
import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';

const STATE_NAME = 'adminDogTestResultsUpload';

export interface IAdminDogTestResultsUploadState {
  data: {
    dogTestResultsUploadDetails: IDogTestResultsUploadDetails;
  };
  communications: {
    dogTestResultsUploading: ICommunication;
    dogTestResultsUploadDetailsLoading: ICommunication;
  };
}

export const uploadDogTestResultsActionTypes = makeCommunicationActionType(STATE_NAME, 'DOG_TEST_RESULTS_UPLOAD_FILES');
export type uploadDogTestResultsActions = MakeCommunicationActions<typeof uploadDogTestResultsActionTypes, {}>;

export const getDogTestResultsUploadDetailsActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_DOG_TEST_RESULTS_UPLOAD_DETAILS'
);
export type getDogTestResultsUploadDetailsActions = MakeCommunicationActions<
  typeof getDogTestResultsUploadDetailsActionTypes,
  {success: IDogTestResultsUploadDetails}
>;

export type AdminDogTestResultsUploadActions = uploadDogTestResultsActions | getDogTestResultsUploadDetailsActions;
