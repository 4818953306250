import * as Yup from 'yup';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';
import {FRONTEND_DATE} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';

export interface IFormValues {
  name: string;
  breed: string;
  gender: string;
  dateOfBirth: string | null;
  discipline: string | null;
  sampleId: string | null;
}

export const validationSchema = Yup.object().shape<IFormValues>({
  name: Yup.string().required(REQUIRED_FIELD),
  breed: Yup.string().required(REQUIRED_FIELD),
  gender: Yup.string().required(REQUIRED_FIELD),
  dateOfBirth: Yup.string()
    .nullable()
    .test('dateOfBirth', `Please enter a valid date: ${FRONTEND_DATE}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE) : true;
    }),
  discipline: Yup.string(),
  sampleId: Yup.string(),
});
