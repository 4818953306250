import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import {Nullable} from 'Common/types';
import Icon, {IconName} from 'Icon/components/Icon';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {Header, Label, Section, SectionContent, SectionHeader} from 'OnlineReportPrintable/common/styled';
import {IPhenotypeProperty} from 'OnlineReport/models/Summary/IPhenotypeProperty';
import {IGeneticNote} from 'OnlineReport/models/Summary/IGeneticNote';
import {IOnlineReportSummary} from 'OnlineReport/models/Summary/IOnlineReportSummary';
import {IOnlineReportGeneticVariants} from 'OnlineReport/models/Summary/IOnlineReportGenotype';
import GenotypeList from 'Genotype/component/GenotypeList';
import {IHorseBreedPanel} from 'BreedPanel/models/IHorseBreedPanel';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import BreedPanelList from 'BreedPanel/components/BreedPanelList';

const GenotypesContainer = styled.div`
  margin-bottom: 18px;
`;

const GroupName = styled.div`
  margin-right: 10px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const Genotypes = styled.div`
  margin-bottom: 15px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.bold700};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const Text = styled.div`
  margin-bottom: 12px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
  page-break-inside: avoid;
`;

const GeneticNote = styled.div`
  white-space: pre-wrap;
`;

interface IExternalProps {
  filter?: {
    variantSummary: boolean;
    abilities: boolean;
    coatColors: boolean;
    healthVariants: boolean;
  };
  summaryAbilities: Nullable<IOnlineReportSummary>;
  summaryColors: Nullable<IOnlineReportSummary>;
  summaryHealthIssues: Nullable<IOnlineReportSummary>;
  geneticVariants: Nullable<IOnlineReportGeneticVariants>;
  breedPanels: IHorseBreedPanel[];
}

const ResultsSummary = (props: IExternalProps) => {
  const {
    filter = {variantSummary: true, abilities: true, coatColors: true, healthVariants: true},
    summaryAbilities,
    summaryColors,
    summaryHealthIssues,
    geneticVariants,
    breedPanels,
  } = props;

  const renderGeneticNote = useCallback((note: Nullable<IGeneticNote>) => {
    if (!note) {
      return '';
    }

    return (
      <div className="d-flex">
        <GroupName>Genetic note:</GroupName>
        <Genotypes className="d-flex">
          <GeneticNote>{note.value}</GeneticNote>
        </Genotypes>
      </div>
    );
  }, []);

  const renderProperties = useCallback(
    (title: string, properties: IPhenotypeProperty[], note: Nullable<IGeneticNote>, isHideDescription?: boolean) => {
      return (
        <Section>
          <Label>{title}:</Label>
          <div>
            {renderGeneticNote(note)}
            {properties.map((item, i) => (
              <Text key={i}>
                <b>{item.name}</b>
                {!isHideDescription && <p>{item.description}</p>}
              </Text>
            ))}
          </div>
        </Section>
      );
    },
    [renderGeneticNote]
  );

  const hasHealthVariant = summaryHealthIssues?.results && summaryHealthIssues?.results?.length > 0;

  const isPropertyRender = useCallback(
    (property: Nullable<IOnlineReportSummary> | Nullable<IOnlineReportGeneticVariants>) => {
      if (!property) {
        return false;
      }
      return (property.results && property.results.length > 0) || property.note;
    },
    []
  );

  const variantSummary = (
    <>
      {isPropertyRender(geneticVariants) && (
        <>
          <Label>Variant summaries:</Label>
          <GenotypesContainer className="d-flex flex-column">
            {renderGeneticNote(geneticVariants?.note || null)}

            {geneticVariants?.results.map((group, index) => (
              <div key={index} className="d-flex">
                <GroupName>{group.name}:</GroupName>
                <Genotypes className="d-flex">
                  <GenotypeList genotypes={group?.genotypes} />
                </Genotypes>
              </div>
            ))}

            {breedPanels
              .sort((a, b) => sortByStringKey(a, b, 'positionIndex'))
              .map((panel, index) => (
                <div className="d-flex" key={index}>
                  <GroupName>{panel.name}:</GroupName>
                  <Genotypes className="d-flex">
                    <BreedPanelList panelTraits={panel.traits} />
                  </Genotypes>
                </div>
              ))}
          </GenotypesContainer>
        </>
      )}
    </>
  );
  const abilities = (
    <>
      {isPropertyRender(summaryAbilities) &&
        renderProperties('Performance and Abilities', summaryAbilities?.results || [], summaryAbilities?.note || null)}
    </>
  );

  const coats = (
    <>
      {isPropertyRender(summaryColors) &&
        renderProperties('Coat color', summaryColors?.results || [], summaryColors?.note || null)}
    </>
  );

  const healthIssues = (
    <>
      {isPropertyRender(summaryHealthIssues) &&
        renderProperties('Health Variants', summaryHealthIssues?.results || [], summaryHealthIssues?.note || null)}
      {!hasHealthVariant && (
        <>
          {!isPropertyRender(summaryHealthIssues) && <Label>Health Variants:</Label>}
          <Text>
            All health tests included on this panel as ordered are negative, unless specified here as positive or
            inconclusive.
          </Text>
        </>
      )}
    </>
  );

  return (
    <Section>
      <SectionHeader className="d-flex w-100 align-items-center">
        <Icon name={IconName.Notebook2} />
        <Header>Results Summary</Header>
      </SectionHeader>
      <SectionContent>
        {filter.variantSummary ? variantSummary : null}
        {filter.abilities ? abilities : null}
        {filter.healthVariants ? healthIssues : null}
        {filter.coatColors ? coats : null}
      </SectionContent>
    </Section>
  );
};

export default memo(ResultsSummary);
