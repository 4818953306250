import {combineReducers} from 'redux';

import {IUserToAdminHubState} from '../types';
import {communicationUserToAdminHubReducer} from './communications';
import {dataUserToAdminReducer} from './data';

export const reducer = combineReducers<IUserToAdminHubState>({
  communications: communicationUserToAdminHubReducer,
  data: dataUserToAdminReducer,
});
