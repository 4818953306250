import React, {CSSProperties, memo, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import * as history from 'history';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';
import HorizontalMenuLink, {ILinkHoverStyle} from 'Common/components/Controls/Links/HorizontalMenuLink';
import Theme from 'Common/constants/Theme';

import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {ITab} from 'Common/components/Tabs/ITab';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import Typography from 'Common/constants/Typography';
import {SubHeaderToggle} from 'Common/components/Controls';

const tabNameStyle: React.CSSProperties = {
  fontFamily: Typography.family.sourceSansPro,
  fontWeight: 500,
  fontSize: Typography.size.size20,
  marginBottom: 12,
};

const Root = styled.div<{isMobile?: boolean}>`
  height: ${(props) => (props.isMobile ? '100%' : '40px')};
  background-color: ${Theme.color.white};
`;

const Tab = styled.div`
  width: 86px;
  text-align: center;
  background: ${Theme.color.white};
`;

export const Menu = styled.div`
  box-shadow: 0px 0px 20px ${ColorPalette.transparent2};
  border-radius: 4px;
  border: none;
  position: absolute;
  z-index: 1;
  top: 40px;
  padding: 8px;
  background: ${Theme.color.white};
`;

const Border = styled.div`
  border: 1px solid ${ColorPalette.white9};
`;

interface IProps {
  tabs: ITab[];
  tabStyle?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
  activeLinkStyle?: React.CSSProperties;
  hoverLinkStyle?: ILinkHoverStyle;
  className?: string;
  style?: CSSProperties;
  isSort?: boolean;
}

function BusinessPortalTabs(props: IProps) {
  const {tabs, linkStyle, activeLinkStyle, hoverLinkStyle, tabStyle, className, style, isSort} = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sortedTabs = isSort ? tabs.sort((a, b) => sortByStringKey(a, b, 'title')) : tabs;

  const {isMobile} = useMediaQuery();
  const location = useLocation();

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const activeTabName = useMemo(() => {
    const splittedLocation = location.pathname.split('/');
    return sortedTabs.find((x) => x.section === splittedLocation[splittedLocation.length - 1])?.title;
  }, [location.pathname, sortedTabs]);

  const renderTabLinks = useMemo(
    () => (
      <Root
        className={classNames('d-flex', 'flex-shrink-0', className, isMobile ? 'flex-column' : 'flex-row')}
        style={style}
        isMobile={isMobile}
      >
        {sortedTabs.map(({title, section, icon}, i) => {
          const onActiveHandle = (location: history.Location) => {
            const splittedLocation = location.pathname.split('/');
            const active = splittedLocation[splittedLocation.length - 1] === section;
            return active;
          };

          return (
            <Tab key={i} style={tabStyle}>
              <HorizontalMenuLink
                to={`./${section}`}
                borderWidth="100%"
                style={linkStyle}
                $activeStyle={activeLinkStyle}
                $hoverStyle={hoverLinkStyle}
                checkIsActive={onActiveHandle}
                replace={true}
                onClick={() => setIsMenuOpen(false)}
              >
                {title}
              </HorizontalMenuLink>
            </Tab>
          );
        })}
      </Root>
    ),
    [activeLinkStyle, className, hoverLinkStyle, isMobile, linkStyle, sortedTabs, style, tabStyle]
  );

  return (
    <>
      {isMobile && (
        <div className="position-relative">
          <SubHeaderToggle onClick={toggleMenu} isHidden={!isMenuOpen} titleStyle={tabNameStyle}>
            {activeTabName}
          </SubHeaderToggle>
          {isMenuOpen && <Menu>{renderTabLinks}</Menu>}
        </div>
      )}

      {!isMobile && renderTabLinks}
      <Border />
    </>
  );
}

BusinessPortalTabs.defaultProps = {
  linkStyle: {
    fontSize: 14,
    color: ColorPalette.gray56,
    backgroundColor: Theme.color.white,
    lineHeight: '24px',
  },
  activeLinkStyle: {backgroundColor: Theme.color.white, color: Theme.color.black},
  hoverLinkStyle: {color: Theme.color.black},
};

export default memo(BusinessPortalTabs);
