import {Reducer} from 'redux';

import {
  AdminDogTestResultsUploadActions,
  getDogTestResultsUploadDetailsActionTypes,
  IAdminDogTestResultsUploadState,
} from '../types';

const initialState: IAdminDogTestResultsUploadState['data'] = {
  dogTestResultsUploadDetails: {uploadDate: ''},
};

export const dataDogTestResultsUploadReducer: Reducer<
  IAdminDogTestResultsUploadState['data'],
  AdminDogTestResultsUploadActions
> = (state = initialState, action: AdminDogTestResultsUploadActions) => {
  switch (action.type) {
    case getDogTestResultsUploadDetailsActionTypes.SUCCESS: {
      return {...state, dogTestResultsUploadDetails: action.payload};
    }

    default: {
      return state;
    }
  }
};
