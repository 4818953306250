import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {IOrderAdmin} from 'Admin/AdminDashboard/models/Order/IOrderAdmin';
import {IOrderDetails} from 'Admin/AdminDashboard/models/Order/IOrderDetails';
import {OrderStatusField} from 'Order/models/IOrderStatusFields';
import {IHorseSample} from 'Admin/AdminDashboard/models/Order/IHorseSample';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';

const STATE_NAME = 'adminOrders';

export interface IAdminOrdersState {
  data: {
    orders: IOrderAdmin[];
    orderDetails: IOrderDetails | null;
    horseSamples: IHorseSample[];
    orderStatusesDetails: IOrderHorseStatuses[];
  };
  communications: {
    ordersLoading: ICommunication;
    orderDetailsLoading: ICommunication;
    orderHorseReleaseRequesting: ICommunication;
    horseSamplesLoading: ICommunication;
    orderDeleting: ICommunication;
    newSampleRequesting: ICommunication;
    setSampleStatusRequesting: ICommunication;
    changeSampleRequesting: ICommunication;
    chooseAnotherSampleRequesting: ICommunication;
    downloadReportsRequesting: ICommunication;
    downloadSubmissionFormRequesting: ICommunication;
    orderStatusesDetailsLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getOrdersActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDERS');
export type getOrdersActions = MakeCommunicationActions<
  typeof getOrdersActionTypes,
  {success: IListResponse<IOrderAdmin>}
>;

export const getOrderDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_DETAILS');
export type getOrderDetailsActions = MakeCommunicationActions<
  typeof getOrderDetailsActionTypes,
  {success: IOrderDetails}
>;

export const releaseOrderHorseActionTypes = makeCommunicationActionType(STATE_NAME, 'RELEASE_ORDER_HORSE');
export type releaseOrderHorseActions = MakeCommunicationActions<typeof releaseOrderHorseActionTypes, {}>;

export const getHorseSamplesActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_HORSE_SAMPLES');
export type getHorseSamplesActions = MakeCommunicationActions<
  typeof getHorseSamplesActionTypes,
  {success: IHorseSample[]}
>;

export const deleteOrderActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_ORDER');
export type deleteOrderActions = MakeCommunicationActions<typeof deleteOrderActionTypes, {}>;

export const requestNewSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'REQUEST_NEW_SAMPLE');
export type requestNewSampleActions = MakeCommunicationActions<typeof requestNewSampleActionTypes, {}>;

export const setSampleStatusActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_SAMPLE_STATUS');
export type setSampleStatusActions = MakeCommunicationActions<typeof setSampleStatusActionTypes, {}>;

export const changeSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'CHANGE_SAMPLE');
export type changeSampleActions = MakeCommunicationActions<
  typeof changeSampleActionTypes,
  {success: IRequestChangeSample}
>;

export const chooseAnotherSampleActionTypes = makeCommunicationActionType(STATE_NAME, 'CHOOSE_ANOTHER_SAMPLE');
export type chooseAnotherSampleActions = MakeCommunicationActions<
  typeof chooseAnotherSampleActionTypes,
  {success: IRequestChooseAnotherSample}
>;

export const downloadReportsActionTypes = makeCommunicationActionType(STATE_NAME, 'DOWNLOAD_REPORTS');
export type downloadReportsActions = MakeCommunicationActions<typeof downloadReportsActionTypes, {}>;

export const downloadSubmissionFormActionTypes = makeCommunicationActionType(STATE_NAME, 'DOWNLOAD_SUBMISSION_FORM');
export type downloadSubmissionFormActions = MakeCommunicationActions<typeof downloadSubmissionFormActionTypes, {}>;

export const getOrderStatusesDetailsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_ORDER_STATUSES_DETAILS');
export type getOrderStatusesDetailsActions = MakeCommunicationActions<
  typeof getOrderStatusesDetailsActionTypes,
  {success: IOrderHorseStatuses}
>;

export const resetChangeSampleCommunicationActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'CHANGE_SAMPLE_COMMUNICATION'
);
export type resetChangeSampleCommunicationActions = MakeResetCommunicationActions<
  typeof resetChangeSampleCommunicationActionTypes
>;

export const resetChooseAnotherSampleCommunicationActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'CHOOSE_ANOTHER_SAMPLE_COMMUNICATION'
);
export type resetChooseAnotherSampleCommunicationActions = MakeResetCommunicationActions<
  typeof resetChooseAnotherSampleCommunicationActionTypes
>;

export interface IReleaseOrderHorse {
  orderId: number;
  horseId: number;
  sendEmailToUser: boolean;
}

export interface IRequestNewSampleParams {
  orderId: number;
  horseId: number;
  reason?: string;
  description?: string;
}

export interface IRequestSetSampleStatus {
  orderId: number;
  horseId: number;
  status: OrderStatusField;
  sendEmail: boolean;
}

export interface IRequestChangeReviewStatus {
  orderId: number;
  horseId?: number;
  reviewStatus: OrderStatusField;
}

export interface IRequestChangeSample {
  id: number;
  sampleId: string;
}

export interface IRequestChooseAnotherSample {
  orderId: number;
  currentSampleId: string;
  targetSampleId: string;
}

export const resetOrderDetailsActionTypes = makeResetCommunicationActionType(STATE_NAME, 'ORDER_DETAILS');
export type resetOrderDetailsActions = MakeResetCommunicationActions<typeof resetOrderDetailsActionTypes>;

export const resetOrderStatusesDetailsActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'ORDER_STATUSES_DETAILS'
);
export type resetOrderStatusesDetailsActions = MakeResetCommunicationActions<
  typeof resetOrderStatusesDetailsActionTypes
>;

export type AdminOrdersActions =
  | getOrdersActions
  | getOrderDetailsActions
  | getHorseSamplesActions
  | releaseOrderHorseActions
  | deleteOrderActions
  | requestNewSampleActions
  | setSampleStatusActions
  | changeSampleActions
  | chooseAnotherSampleActions
  | downloadReportsActions
  | downloadSubmissionFormActions
  | resetChangeSampleCommunicationActions
  | resetChooseAnotherSampleCommunicationActions
  | resetOrderDetailsActions
  | getOrderStatusesDetailsActions
  | resetOrderStatusesDetailsActions;
