import React, {memo} from 'react';
import styled from 'styled-components';

import {Nullable} from 'Common/types';
import {
  getBusinessPortalHeadersNavMenu,
  getHeadersNavLinks,
  getHeadersNavMenu,
  getUserMenuLinks,
} from 'Common/components/Header/common';
import ColoredNavLink from 'Common/components/Controls/Links/ColoredNavLink';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import Typography from 'Common/constants/Typography';
import {size} from 'Common/constants/Breakpoints';
import {disableScrolling, enableScrolling} from 'Common/helpers/operateWithScroll';
import AuthService from 'Auth/services/AuthService';
import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
import ColoredExternalLink from 'Common/components/Controls/Links/ColoredExternalLink';
import {INamedEntity} from 'Common/models/INamedEntity';
import usePermissions from 'Permissions/hooks/usePermissions';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {VisitorType} from 'Common/constants/VisitorType';

const iconProps = {color: ColorPalette.gray35, stroke: false, fill: true, size: 18};

const Root = styled.div`
  width: 100%;
  min-width: ${size.xss}px;
  height: 100%;
  background: ${ColorPalette.black7};
  padding: 70px 20px 50px 25px;
  position: fixed;
  overflow: hidden;
`;

const TextBase = styled.div`
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size24};
  line-height: 24px;
  color: ${ColorPalette.white0};
`;

const UserAvatar = styled(Avatar)`
  margin: 0 25px 0 13px;
`;

const UserName = styled(TextBase)`
  font-family: ${Typography.family.sourceSansPro};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Separator = styled.div`
  width: 64px;
  border: 1px solid ${ColorPalette.red7};
  margin: 27px 0 31px 13px;
`;

const Links = styled.div`
  margin: 50px 0 20px 10px;
`;

const SocialLink = styled.a.attrs({target: '_blank', rel: 'noopener noreferrer'})`
  :not(:last-child) {
    margin-right: 28px;
  }
`;

const NavLink = styled(TextBase)`
  font-family: ${Typography.family.roboto};
  margin-left: -5px;
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

interface IUserMenuVisitor extends INamedEntity {
  avatarUrl?: Nullable<string>;
}

interface IProps {
  visitor: IUserMenuVisitor;
  visitorPermissions: string[];
  onLinkHandler(): void;
}

const UserMenu = (props: IProps) => {
  const {visitor, visitorPermissions, onLinkHandler} = props;

  const {isDevelopmentAdmin} = usePermissions();
  const {currentVisitorType, isVisitorAdmin} = useVisitorTypeService();

  const links = isVisitorAdmin
    ? getHeadersNavMenu(visitorPermissions, isDevelopmentAdmin)
    : currentVisitorType === VisitorType.AssociationEmployee
    ? getBusinessPortalHeadersNavMenu(visitorPermissions)
    : getHeadersNavLinks(visitor.id, visitorPermissions).concat(getUserMenuLinks(visitor.id));

  React.useEffect(() => {
    disableScrolling();

    return () => enableScrolling();
  });

  return (
    <Root>
      <Scrollbar
        autoHeight={false}
        renderView={({style, ...scrollbarProps}) => <div style={{...style, paddingRight: 0}} {...scrollbarProps} />}
      >
        <div>
          <div className="d-flex flex-row">
            <UserAvatar
              avatarSize={AvatarSize.Custom}
              customSizeAvatar={54}
              defaultAvatarProfile={DefaultAvatarProfile.User}
              url={visitor.avatarUrl}
            />
            <UserName className="align-self-center">{visitor.name}</UserName>
          </div>
          <Separator />
          {links.map((item, i) => {
            if (item.isExternalUrl) {
              return (
                <NavLink key={i}>
                  {item.divided && <Separator />}

                  <ColoredExternalLink className="nav-link" href={item.value}>
                    {item.label}
                  </ColoredExternalLink>
                </NavLink>
              );
            }
            return (
              <NavLink key={i}>
                {item.divided && <Separator />}

                <ColoredNavLink className="nav-link" to={item.value} onClick={onLinkHandler}>
                  {item.label}
                </ColoredNavLink>
              </NavLink>
            );
          })}
          <NavLink>
            <Separator />
            <ColoredNavLink className="nav-link" to="/logout" onClick={() => AuthService.logout(true)}>
              Log Out
            </ColoredNavLink>
          </NavLink>
          <Links>
            <SocialLink href="https://www.instagram.com/etalondx/">
              <ColoredIcon name={IconName.Instagram} {...iconProps} />
            </SocialLink>
            <SocialLink href="https://twitter.com/etalondx/">
              <ColoredIcon name={IconName.Twitter} {...iconProps} />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/etalondx">
              <ColoredIcon name={IconName.Facebook} {...iconProps} />
            </SocialLink>
          </Links>
        </div>
      </Scrollbar>
    </Root>
  );
};

export default memo(UserMenu);
export type UserMenuProps = IProps;
