export enum EventName {
  GenePoolChanged = 'ExcludeListChanged',
  OrderStatusChanged = 'OrderStatusChanged',
  UpdatePayment = 'UpdatePayment',
  SampleChanged = 'SampleChanged',
  ReleaseOrder = 'ReleaseOrder',
  ReportUpload = 'ReportUpload',
  AddPackageToCompare = 'AddPackageToCompare',
  NotificationReceived = 'NotificationReceived',
  SystemNotificationReceived = 'SystemNotificationReceived',
}
