import React, {useCallback, useState} from 'react';

import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import Loading from 'Loading/components/Loading';
import OrderInfoModal from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoModal';
import OrdersInfoSection from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/OrdersInfoSection/OrdersInfoSection';
import CreateUserBusinessElementSection from 'Admin/AdminDashboard/components/shared/CreateUserBusinessElementSection/CreateUserBusinessElementSection';
import {useCreateOrderModal} from 'Admin/AdminDashboard/helpers/hooks/useCreateOrderModal';
import {useOrderActions} from 'Admin/AdminDashboard/helpers/hooks/useOrderActions/useOrderActions';
import {IOrderHistory} from 'Admin/AdminDashboard/components/shared/OrderHistory/parts/common';
import withOrderActions, {
  getWithOrderActionsModules,
  IWithOrderActionsProps,
} from 'Admin/AdminDashboard/helpers/withOrderActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';

interface ExternalProps {
  orders: IOrderHistory[];
  horseId?: number;
  userId?: number;
  onSuccess(): void;
}

type AllProps = ExternalProps & IWithOrderActionsProps;

function OrderHistory(props: AllProps) {
  const {orders, onSuccess, horseId, userId} = props;
  const {
    deleteOrder,
    downloadSubmissionForm,
    downloadReports,
    setSampleStatus,
    orderDeleting,
    downloadFormRequesting,
    setSampleStatusRequesting,
    newSampleRequesting,
    downloadReportsRequesting,
    changeReviewStatusRequesting,
    changeReviewStatus,
  } = props;

  const {
    openDeleteModal,
    orderActionsModal,
    openSampleReceivedModal,
    openSampleNotReceivedModal,
    openRequestNewSampleModal,
    openUpdatePaymentModal,
    dismissReviewConfirmModal,
    openDismissReviewConfirmModal,
  } = useOrderActions({
    data: {
      deleteOrderAction: {action: deleteOrder, communication: orderDeleting},
      downloadSubmissionFormAction: {communication: downloadFormRequesting},
      setSampleStatusAction: {action: setSampleStatus, communication: setSampleStatusRequesting},
      requestNewSampleAction: {communication: newSampleRequesting},
      downloadReportsAction: {communication: downloadReportsRequesting},
      dismissReviewAction: {action: changeReviewStatus, communication: changeReviewStatusRequesting},
    },
    onSuccess: onSuccess,
  });

  const openLiveReport = useCallback((horseId: number, orderId?: number) => {
    if (orderId) {
      return `/review-admin/online-report/${horseId}/${orderId}/summary`;
    } else {
      return `/admin/online-report/${horseId}/0/summary`;
    }
  }, []);

  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

  const [isShowInfoModalOpen, setIsShowInfoModalOpen] = useState(false);
  const openShowInfoModal = useCallback((orderId: number) => {
    setSelectedOrderId(orderId);
    setIsShowInfoModalOpen(true);
  }, []);
  const closeShowInfoModal = useCallback(() => setIsShowInfoModalOpen(false), []);

  useOnSuccessCommunication(orderDeleting, onSuccess);
  const {createOrderModal, openCreateOrderModal} = useCreateOrderModal({onSuccess, horseId, userId});

  const isLoading = orderDeleting.isRequesting;
  return (
    <div>
      {isLoading && <Loading />}
      {createOrderModal}
      {orderActionsModal}
      {dismissReviewConfirmModal}
      {selectedOrderId && isShowInfoModalOpen && (
        <OrderInfoModal
          isOpen={isShowInfoModalOpen}
          onClose={closeShowInfoModal}
          onSuccess={onSuccess}
          orderId={selectedOrderId!}
        />
      )}
      <CreateUserBusinessElementSection label="Create order" onClick={openCreateOrderModal} />
      <OrdersInfoSection
        orders={orders}
        onSuccess={onSuccess}
        openOrderHandler={openShowInfoModal}
        deleteOrderHandler={openDeleteModal}
        updatePaymentHandler={openUpdatePaymentModal}
        openLiveReportHandler={openLiveReport}
        submissionFormHandler={downloadSubmissionForm}
        sampleReceivedHandler={openSampleReceivedModal}
        sampleNotReceivedHandler={openSampleNotReceivedModal}
        requestNewSampleHandler={openRequestNewSampleModal}
        downloadResultsHandler={downloadReports}
        dismissReviewHandler={openDismissReviewConfirmModal}
      />
    </div>
  );
}

export default withDynamicModules(withOrderActions(OrderHistory), getWithOrderActionsModules());
