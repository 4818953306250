import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import CreateTransferForm from 'Admin/AdminDashboard/components/HorseTransfer/CreateTransferForm/CreateTransferForm';

interface ICreateTransferModalParams {
  onSuccess?(): void;
}

export function useCreateTransferModal(params: ICreateTransferModalParams) {
  const {onSuccess} = params;

  const [isOpen, setIsOpen] = useState(false);
  const openCreateTransferModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeCreateTransferModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeCreateTransferModal();
    onSuccess && onSuccess();
  }, [closeCreateTransferModal, onSuccess]);

  const createTransferModal = (
    <ModalWindow isOpen={isOpen} onClose={closeCreateTransferModal}>
      <CreateTransferForm onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    createTransferModal,
    openCreateTransferModal,
  };
}
