import {CSSProperties} from 'react';
import styled from 'styled-components';
import {NavLink, NavLinkProps} from 'react-router-dom';

import ColorPalette from 'Common/constants/ColorPalette';

const defaultActiveStyle: CSSProperties = {opacity: 1};

const BaseNavLink = styled(NavLink).attrs((props: Props) => {
  return {style: props.style, activestyle: props.activestyle};
})<Props>`
  color: ${(props) => (props.color ? props.color : ColorPalette.white0)};
  opacity: ${(props) => (props.disabled ? '0.3' : '0.4')};
  :hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : ColorPalette.white0)};
    opacity: 1;
  }
  pointer-events: ${(props) => props.disabled && 'none'};
`;

interface IProps {
  color?: string;
  hoverColor?: string;
  activestyle?: React.CSSProperties;
  disabled?: boolean;
}

type Props = IProps & NavLinkProps;
/**
 * Make sure to pass 'activestyle' all lowercase to this specific component.
 * Using transient-props will cause the active style to not render.
 */
function ColoredNavLink(props: Props) {
  const {activestyle, style} = props;

  const customStyle = (customProps: {isActive: boolean; isPending: boolean}): React.CSSProperties | undefined => {
    const {isActive} = customProps;
    return {
      ...style,
      ...(isActive ? activestyle || defaultActiveStyle : null),
    };
  };

  return <BaseNavLink {...props} style={customStyle} />;
}

export default ColoredNavLink;
