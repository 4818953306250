import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IObjectResponse} from 'Common/models/IResponse';
import {IOrderSummary} from 'Payment/models/IOrderSummary';

import {IPayPalOrder, IPayPalOrderRequest} from './models/payment';
import {IServerOrderSummary} from './models/summary';
import {convertOrderSummaryToClient} from './converters/summary';
import {IOrderDetails} from '../models/IOrderDetails';
import {convertOrderDetailsToClient} from './converters/details';
import {IPaymentIntentResponse, IServerOrderDetails} from './models/details';

const getPaymentIntentId = async (
  token: string,
  captchaToken: string,
  isBusiness?: boolean,
  receiptEmail?: string
): Promise<IPaymentIntentResponse> => {
  const res = await axiosWrapper.post<IObjectResponse<IPaymentIntentResponse>>(
    isBusiness ? `/StripeAssociationOrderPaymentIntent` : `/StripePaymentIntent`,
    {token, captchaToken, receiptEmail}
  );
  return res.data.data;
};

const getOrderSummaryByToken = async (token: string): Promise<IOrderSummary> => {
  const res = await axiosWrapper.get<IObjectResponse<IServerOrderSummary>>(`/OrderSummary/${token}`);
  return convertOrderSummaryToClient(res.data.data);
};

const getOrderDetailsByToken = async (token: string): Promise<IOrderDetails> => {
  const res = await axiosWrapper.get<IObjectResponse<IServerOrderDetails>>(`/OrderDetails/${token}`);
  return convertOrderDetailsToClient(res.data.data);
};

const getPayPalOrder = async (request: IPayPalOrderRequest): Promise<IPayPalOrder> => {
  const res = await axiosWrapper.get<IObjectResponse<IPayPalOrder>>(`/PayPalOrder`, {
    params: request,
  });
  return res.data.data;
};

export default {
  getPaymentIntentId,
  getOrderSummaryByToken,
  getOrderDetailsByToken,
  getPayPalOrder,
};
