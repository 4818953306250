import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';

export const SubTitleContainer = styled.div`
  border-bottom: 1px solid ${ColorPalette.gray48};
  min-height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;

  @media ${breakpoints.sm} {
    padding: 0 24px;
    min-height: 48px;
  }
`;

export const SubTitle = styled.span`
  font-family: ${Typography.family.openSans};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size18};
  line-height: 25px;
  color: ${ColorPalette.black1};
`;

export const MessageBase = styled.div`
  width: 100%;
  border: 1px solid ${ColorPalette.gray48};
  margin-bottom: 16px;
  padding: 8px 8px 24px;
  position: relative;
  word-break: break-word;
  white-space: pre-wrap;

  @media ${breakpoints.sm} {
    width: 75%;
  }
`;

export const ChatText = styled.div`
  padding: 0 30px;
  margin-top: 30px;
  text-align: center;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  margin-bottom: 32px;
`;

export const ChatRoot = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;

  padding: 24px;
  position: relative;
`;

export const MessageFooter = styled.div`
  position: absolute;
  bottom: 2px;
  right: 8px;
  gap: 8px;
`;

export const MessageDate = styled.span`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size12};
  line-height: 16px;
  font-style: italic;
`;
