import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getEnvParams} from 'Common/helpers/getEnvParams';
import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import {IAppState} from 'Common/store/IAppState';
import {Hubs} from 'SignalR/const/Hubs';
import {DEFAULT_LOG_LEVEL} from 'SignalR/const/LogLevel';
import {getAccessToken} from 'SignalR/helpers/getAccessToken';
import {parseHubError} from 'SignalR/helpers/parseHubError';
import {signalRLogger} from 'SignalR/helpers/signalRLogger';
import {actions} from 'SignalR/store/userToUser';

export function useSignalRUserToUserChat() {
  const [connection, setConnection] = useState<HubConnection>();
  const [isConnectionInitialized, setIsConnectionInitialized] = useState<boolean>(false);
  const [connectionState, setConnectionState] = useState<HubConnectionState>(HubConnectionState.Disconnected);

  const {baseUrl, signalRLogLevel} = getEnvParams();

  const {resetSendingMessage} = useBindActions({
    resetSendingMessage: actions.resetSendingMessage,
  });

  const sendingMessage = useSelector((state: IAppState) => state.userToUserHub.data.sendingMessage);

  useEffect(() => {
    if (!sendingMessage) {
      return;
    }

    if (connection && connectionState) {
      connection
        .invoke('Send', sendingMessage)
        .then((x) => {
          signalRLogger('Send', sendingMessage);
          resetSendingMessage();
        })
        .catch(parseHubError)
        .finally(() => resetSendingMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingMessage]);

  const initConnection = useCallback(
    async (hub: Hubs) => {
      const logLevel = LogLevel[signalRLogLevel] !== undefined ? LogLevel[signalRLogLevel] : DEFAULT_LOG_LEVEL;

      const newConnection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}${hub}`, {
          accessTokenFactory: getAccessToken,
        })
        .configureLogging(logLevel)
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
      setIsConnectionInitialized(true);
    },
    [baseUrl, signalRLogLevel]
  );

  const startConnection = useCallback(async () => {
    if (connectionState === HubConnectionState.Disconnected && connection) {
      try {
        setConnectionState(HubConnectionState.Connecting);
        await connection.start();
        setConnectionState(HubConnectionState.Connected);
        signalRLogger('userToUserChat connection started. id', connection.connectionId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection, connectionState]);

  const closeConnection = useCallback(async () => {
    if (connection) {
      try {
        signalRLogger('userToUserChat connection closed. id', connection.connectionId);
        setConnectionState(HubConnectionState.Disconnecting);
        await connection.stop();
        setConnectionState(HubConnectionState.Disconnected);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection]);

  return {
    initConnection,
    isConnectionInitialized,
    connectionState,
    startConnection,
    closeConnection,
  };
}
