import React, {memo} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import {breakpoints, size} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import Icon, {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import NavLinks from '../Header/HeaderButtons/Links/NavLinks';
import {IAppState} from 'Common/store/IAppState';
import {selectors as userSelectors} from 'UserProfile/store/currentUser/index';
import {selectors as currentPermissionsSelectors} from 'Permissions/store/index';

const iconProps = {color: ColorPalette.gray35, stroke: false, fill: true};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${size.xss}px;
  height: 450px;
  padding: 16px;
  background-color: ${ColorPalette.black1};
  color: ${ColorPalette.gray35};
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.sm} {
    width: 100%;
    height: 200px;
    display: grid;
    grid-template-columns: 25% 45% 30%;
  }

  @media ${breakpoints.md} {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 32px 64px;
  }
`;

const SectionBase = styled.div`
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media ${breakpoints.sm} {
    align-items: flex-start;
    width: auto;
  }
`;

const LogoSection = styled(SectionBase)`
  order: 100;

  @media ${breakpoints.sm} {
    order: 0;
  }
`;

const LinksSection = styled(SectionBase)`
  @media ${breakpoints.md} {
    flex-direction: row;
    width: 100%;
  }
`;

const ContactSection = styled(SectionBase)`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 24px;
  color: ${Theme.color.white};
  opacity: 0.3;
  margin: 0 10px 24px;

  @media ${breakpoints.sm} {
    align-self: flex-start;
    margin: 16px 10px;
  }

  @media ${breakpoints.md} {
    margin-top: 0;
  }
`;

const Links = styled.div`
  width: 100%;

  @media ${breakpoints.sm} {
    margin-bottom: 0;
  }

  @media ${breakpoints.md} {
    flex-grow: 1;
  }
`;

const SocialLinks = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  @media ${breakpoints.sm} {
    margin-left: 12px;
    margin-bottom: 0;
  }

  @media ${breakpoints.md} {
    margin-top: 4px;
    margin-right: 64px;
  }
`;

const SocialLink = styled.a.attrs({target: '_blank', rel: 'noopener noreferrer'})`
  :not(:last-child) {
    margin-right: 28px;
  }
`;

const Inc = styled(ContactSection)`
  margin-top: 16px;
  text-align: center;
`;

const LogoIcon = styled(Icon)`
  width: 126px;
  height: 62px;

  @media ${breakpoints.md} {
    width: 160px;
    height: 79px;
  }
`;

const Address = styled.div`
  margin-top: 18px;
`;

type IConnected = ConnectedProps<typeof connector>;

const Footer = (props: IConnected) => {
  const {currentUser, currentUserPermissions} = props;

  return (
    <Root>
      <LogoSection>
        <LogoIcon name={IconName.EtalonHorseLogo} />
        <Inc>© {new Date().getFullYear()} Etalon Inc.</Inc>
      </LogoSection>

      <LinksSection>
        <Links>
          <NavLinks user={currentUser} userPermissions={currentUserPermissions} isFooter={true} />
        </Links>

        <SocialLinks className="d-flex">
          <SocialLink href="https://www.instagram.com/etalondx/">
            <ColoredIcon name={IconName.Instagram} {...iconProps} />
          </SocialLink>
          <SocialLink href="https://twitter.com/etalondx/">
            <ColoredIcon name={IconName.Twitter} {...iconProps} />
          </SocialLink>
          <SocialLink href="https://www.facebook.com/etalondx">
            <ColoredIcon name={IconName.Facebook} {...iconProps} />
          </SocialLink>
        </SocialLinks>
      </LinksSection>

      <ContactSection>
        <div>Phone: 650.380.2995</div>
        <div>Email: info@etalondx.com</div>
        <Address>405 El Camino Real, #234 Menlo Park, CA 94025</Address>
      </ContactSection>
    </Root>
  );
};

const mapStateToProps = (state: IAppState) => ({
  currentUser: userSelectors.selectCurrentUser(state),
  currentUserPermissions: currentPermissionsSelectors.selectCurrentPermissions(state),
});

const connector = connect(mapStateToProps);
export default connector(memo(Footer));
