import {memo, useCallback, useState} from 'react';

import Switch from 'Common/components/Controls/Switch';
import {SwitchFieldWrapper} from 'Common/components/StyledComponents/StyledComponents';
import {ItemVisibilityText} from '../../styled';

interface IProps {
  horseId: number;
  isDiagnosticPublic: boolean;
  isAncestryPublic: boolean;
  onUpdateDiagnosticsProfileType(horseId: number, isPublic: boolean): void;
  onUpdateAncestryProfileType(horseId: number, isPublic: boolean): void;
  onUpdateParentageProfileType(horseId: number, isPublic: boolean): void;
}

type AllProps = IProps;

function HorsePrivacySwitcher(props: AllProps) {
  const {onUpdateAncestryProfileType, onUpdateDiagnosticsProfileType, isDiagnosticPublic, isAncestryPublic, horseId} =
    props;

  const [isDiagnosticsPublic, setIsDiagnosticsPublic] = useState(isDiagnosticPublic);
  const [isAncestryProfilePublic, setIsAncestryProfilePublic] = useState(isAncestryPublic);

  const onDiagnosticsSwitchChange = useCallback(() => {
    setIsDiagnosticsPublic(!isDiagnosticsPublic);
    onUpdateDiagnosticsProfileType(horseId, !isDiagnosticsPublic);
  }, [horseId, isDiagnosticsPublic, onUpdateDiagnosticsProfileType]);

  const onAncestrySwitchChange = useCallback(() => {
    setIsAncestryProfilePublic(!isAncestryProfilePublic);
    onUpdateAncestryProfileType(horseId, !isAncestryProfilePublic);
  }, [horseId, isAncestryProfilePublic, onUpdateAncestryProfileType]);

  return (
    <>
      <SwitchFieldWrapper>
        <Switch
          onChange={onDiagnosticsSwitchChange}
          checked={isDiagnosticsPublic}
          textLeft={<ItemVisibilityText> {isDiagnosticsPublic ? 'Public' : 'Private'}</ItemVisibilityText>}
          labelStyle={{minWidth: 40}}
        />
      </SwitchFieldWrapper>

      <SwitchFieldWrapper>
        <Switch
          onChange={onAncestrySwitchChange}
          checked={isAncestryProfilePublic}
          textLeft={<ItemVisibilityText> {isAncestryProfilePublic ? 'Public' : 'Private'}</ItemVisibilityText>}
          labelStyle={{minWidth: 40}}
        />
      </SwitchFieldWrapper>
    </>
  );
}

export default memo(HorsePrivacySwitcher);

export type IHorsePrivacySwitcherProps = IProps;
