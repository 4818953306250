import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse} from 'Common/models/IResponse';
import {
  avatarGalleryRequestConverter,
  imagesGalleryRequestConverter,
  videoGalleryRequestConverter,
} from './converters/gallery';
import {IMediaResource} from 'Common/models/IMediaResource';
import {IVideo} from 'Video/models/IVideo';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';

const getGallery = async (horseId: number, isAdmin?: boolean): Promise<IMediaResource[]> => {
  const response = await axiosWrapper.get<IListResponse<IMediaResource>>(
    `${isAdmin ? '/HorseMediaAdmin' : '/HorseMedia'}/${horseId}`
  );
  return response.data.data.map(withImageBaseUrl);
};

const addAvatar = async (horseId: number, file: File, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.post<void>(
    isAdmin ? `/HorseAvatarUpdateAdmin` : `/HorseAvatarUpdate`,
    avatarGalleryRequestConverter(horseId, file)
  );
};

const addImages = async (horseId: number, files: File[], isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.post<void>(
    isAdmin ? '/HorseImagesUpdateAdmin' : '/HorseImagesUpdate',
    imagesGalleryRequestConverter(horseId, files)
  );
};

const addVideo = async (horseId: number, video: IVideo, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.post<void>(
    isAdmin ? '/HorseVideosUpdateAdmin' : '/HorseVideosUpdate',
    videoGalleryRequestConverter(horseId, video)
  );
};

const deleteMedia = async (horseId: number, mediaIds: number[], isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<void>(isAdmin ? 'HorseMediaDeleteAdmin' : '/HorseMediaDelete', {data: {horseId, mediaIds}});
};

const deleteAvatar = async (horseId: number, isAdmin?: boolean): Promise<void> => {
  await axiosWrapper.delete<void>(isAdmin ? `/HorseAvatarDeleteAdmin/${horseId}` : `/HorseAvatarDelete/${horseId}`);
};

export default {
  getGallery,
  addAvatar,
  addImages,
  deleteMedia,
  addVideo,
  deleteAvatar,
};
