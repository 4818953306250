import {getEnvParams} from 'Common/helpers/getEnvParams';
import {FrontendLogLevel} from 'SignalR/const/LogLevel';

export function signalRLogger(...args: any) {
  const {signalRFrontendLogLevel} = getEnvParams();

  if (signalRFrontendLogLevel === FrontendLogLevel.Debug) {
    console.log('====================================');
    console.log(args);
    console.log('====================================');
  }
}
