import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {FormikBag, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {useParams} from 'react-router-dom';

import Typography from 'Common/constants/Typography';
import {actions, selectors} from 'BusinessPortal/store/createOrder';
import {BusinessPortalCreateOrderModule} from 'BusinessPortal/store/createOrder/businessPortalCreateOrderModule';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import ColorPalette from 'Common/constants/ColorPalette';
import {ICreatedOrder} from 'BusinessPortal/models/order/createOrder/ICreatedOrder';
import Theme from 'Common/constants/Theme';
import {breakpoints} from 'Common/constants/Breakpoints';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {SummaryBackground, SummaryContainer} from 'BusinessPortal/components/shared/StyledComponents';

import {
  convertFormValuesToRequest,
  convertTestSummaryToServer,
  IFormValues,
  initialValues,
  validationSchema,
} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/validation';
import Summary, {PayNowActions} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/Summary';
import {OrderPaymentModule} from 'Payment/store/orderPayment/orderPaymentModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import CreateOrderMainContent, {
  CreateOrderMainContentProps,
} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/CreateOrderMainContent';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {useDictionaries} from 'Common/store/useDictionaries';
import BusinessPortalLayout from 'BusinessPortal/components/common/BusinessPortalLayout/BusinessPortalLayout';
import {IOrderCoupon} from 'BusinessPortal/models/order/createOrder/IOrderCoupon';
import {useStripePaymentModal} from 'BusinessPortal/helpers/hooks/createOrder/useStripePaymentModal';
import {usePlacedOrderModal} from 'BusinessPortal/helpers/hooks/createOrder/userPlacedOrderModal';
import {INamedEntity} from 'Common/models/INamedEntity';
import {useRedirects} from 'BusinessPortal/helpers/hooks/createOrder/useRedirects';
import {useOrderHandler} from 'BusinessPortal/helpers/hooks/createOrder/useOrderHandler';
import {useAssociationCreateOrderDiscount} from 'Shared/components/AssociationCreateOrderDiscount/hooks/useAssociationCreateOrderDiscount';
import {IHorseOrder} from 'BusinessPortal/models/order/createOrder/IHorseOrder';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Root = styled.div`
  background-color: ${ColorPalette.gray49};
  @media ${breakpoints.md} {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
`;

const Title = styled.div`
  margin: 16px;
`;

const TitleLabel = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size24};
  line-height: 32px;
  color: ${ColorPalette.black1};
`;

const Header = styled.div`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.black};

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
    line-height: 56px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  overflow: hidden;

  @media ${breakpoints.md} {
    width: 65%;
  }
`;

interface IExternalProps {
  packagesDictionary: ISimpleDictionary<INamedEntity>;
  testsDictionary: ISimpleDictionary<INamedEntity>;
  horseIds?: string;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

function CreateOrder(props: AllProps) {
  const {values, isValid, isSubmitting, submitCount, submitForm} = props;
  const {
    isLoadingFormData,
    getHorses,
    packagesDictionary,
    testsDictionary,
    getSummary,
    horses,
    packages,
    tests,
    summary,
    coupon,
    getCoupon,
    creatingOrder,
    createdOrder,
    summaryLoading,
    couponLoading,
    removeCoupon,
    removeSummary,
  } = props;

  const {isMobile} = useMediaQuery();

  const {
    actions: {getItems: getPackages},
  } = packagesDictionary;

  const {
    actions: {getItems: getTests},
  } = testsDictionary;

  const [isReviewOrder, setIsReviewOrder] = useState(false);
  const createdOrderRef = useRef<ICreatedOrder | null>(null);

  const {redirectToResultPage, redirectToSuccessPayment} = useRedirects({
    token: createdOrderRef.current?.token || createdOrder?.token,
  });

  const onCreateOrder = useCallback(async () => {
    if (!createdOrder) {
      await submitForm();
    }
  }, [createdOrder, submitForm]);

  const {closeStripePaymentModal, openStripePaymentModal, stripePaymentModal} = useStripePaymentModal({
    token: createdOrder?.token,
    amount: summary?.totalAmount || 0,
    onSuccess: redirectToSuccessPayment,
    createOrder: onCreateOrder,
    creatingOrder: creatingOrder.isRequesting,
  });

  const {openDiscountForm, discountModal, removeCouponFromOrder, checkCode, couponError} =
    useAssociationCreateOrderDiscount({removeCoupon, horses, coupon, couponLoading, getCoupon});

  const {unexpectedCreatingOrderError, summaryError} = useOrderHandler({
    createdOrderRef,
    createdOrder,
    creatingOrder,
    closeStripePaymentModal,
    redirectToSuccessPayment,
    redirectToResultPage,
    getSummary,
    couponLoading,
    removeSummary,
    coupon,
    summaryLoading,
    summary,
    hasOtherError: !!couponError,
  });

  const hasCouponError = !!coupon && !!couponError;

  const {placedOrderModal, openConfirmPlacedOrderModal} = usePlacedOrderModal({
    creatingOrder: creatingOrder.isRequesting,
    submitForm,
  });

  useEffect(() => {
    getHorses();
    getPackages();
    getTests();
    scrollToTop();
  }, [getHorses, getPackages, getTests]);

  const disablePlaceOrder =
    (summary?.packagesAndTestsPrice?.length || 0) < 1 || !(submitCount === 0 || isValid) || hasCouponError || !isValid;

  const isLoading = isLoadingFormData || isSubmitting;
  const isSummaryLoading = summaryLoading.isRequesting || couponLoading.isRequesting;

  const payNowActions: PayNowActions = {
    Stripe: openStripePaymentModal,
  };

  const openPayButtons = useCallback(() => setIsReviewOrder(true), []);

  const editClick = useCallback(() => setIsReviewOrder(false), []);

  const mainContentProps: CreateOrderMainContentProps = {
    testSelectionProps: {
      isValid: values.orders !== initialValues.orders && !props.errors.orders,
      horses,
      packages,
      tests,
      orders: values.orders,
      onEditClick: editClick,
    },
    discountProps: {
      horses: horses.filter((i) => values.orders.some((j) => j.horseId === i.id)),
      coupons: values.coupons,
      onApplyCode: checkCode,
      onDeleteCoupon: removeCouponFromOrder,
      isLoading: couponLoading.isRequesting,
      onEditHorsesList: (coupon?: IOrderCoupon) => openDiscountForm(coupon, 'edit'),
    },
    paymentProps: {isValid: true, type: values.paymentMethod, onContinueClick: openPayButtons, onEditClick: editClick},
  };

  return (
    <BusinessPortalLayout withoutPaddings={isMobile} isBackButtonDenied={false}>
      <Title className="position-relative align-self-center">
        <TitleLabel>Creating order</TitleLabel>
      </Title>
      {stripePaymentModal}
      {placedOrderModal}
      {discountModal}

      <Root>
        {isLoading && <Loading />}
        <FormContainer>
          <Header>Order tests</Header>
          <CreateOrderMainContent {...mainContentProps} />
        </FormContainer>

        <SummaryContainer>
          <SummaryBackground />
          <Summary
            isDisablePlaceOrder={disablePlaceOrder}
            summary={summary}
            error={unexpectedCreatingOrderError || summaryError}
            paymentMethod={values.paymentMethod}
            isSummaryLoading={isSummaryLoading}
            onPayNow={payNowActions}
            onPayLater={openConfirmPlacedOrderModal}
            isReviewOrder={isReviewOrder}
          />
        </SummaryContainer>
      </Root>
    </BusinessPortalLayout>
  );
}

const formikPropsToValues = ({horseIds, horses}: OuterProps): IFormValues => {
  const horseIdFromUrl = horseIds?.split(',');

  const orders: IHorseOrder[] = horseIdFromUrl
    ? horseIdFromUrl.map((horseId) =>
        horses.find(({id}) => id === Number(horseId))
          ? {horseId: Number(horseId), packages: [], tests: []}
          : {horseId: 0, packages: [], tests: []}
      )
    : initialValues.orders;

  return {
    ...initialValues,
    orders,
  };
};

const handleSubmit = async (values: IFormValues, formikBag: FormikBag<OuterProps, IFormValues>) => {
  try {
    formikBag.setSubmitting(true);
    const {createOrder} = formikBag.props;
    const orders = convertTestSummaryToServer(values.orders, values.coupons).orders;
    const requestModel = convertFormValuesToRequest({...values, orders});
    await createOrder(requestModel);
  } finally {
    formikBag.setSubmitting(false);
  }
};

const CreateOrderWithFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: formikPropsToValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
})(memo(CreateOrder));

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) => {
  const {packagesDictionary, testsDictionary} = externalProps;
  const {selectors: packageSelectors} = packagesDictionary;
  const {selectors: testSelectors} = testsDictionary;

  return {
    isLoadingFormData: selectors.selectIsLoadingFormData(state),
    horses: selectors.selectOrderHorses(state),
    packages: packageSelectors.selectItems(state),
    tests: testSelectors.selectItems(state),
    summary: selectors.selectSummary(state),
    coupon: selectors.selectCoupon(state),
    createdOrder: selectors.selectCreatedOrder(state),
    creatingOrder: selectors.selectCommunication(state, 'creatingOrder'),
    summaryLoading: selectors.selectCommunication(state, 'summaryLoading'),
    couponLoading: selectors.selectCommunication(state, 'couponLoading'),
  };
};

const mapDispatchToProps = {
  getHorses: actions.getHorses,
  getSummary: actions.getSummary,
  getCoupon: actions.getCoupon,
  createOrder: actions.createOrder,
  removeCoupon: actions.resetCoupon,
  removeSummary: actions.resetSummary,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(CreateOrderWithFormik);
const DynamicConnected = withDynamicModules(Connected, [BusinessPortalCreateOrderModule, OrderPaymentModule]);

const Exported = (externalProps: Omit<IExternalProps, 'packagesDictionary' | 'testsDictionary'>) => {
  const {associationActivePackages, associationActivePurchasableTests} = useDictionaries();
  const {horseIds} = useParams();

  return (
    <DynamicConnected
      {...externalProps}
      packagesDictionary={associationActivePackages}
      testsDictionary={associationActivePurchasableTests}
      horseIds={horseIds}
    />
  );
};

export default Exported;
