import React, {memo, useRef, useState, useEffect, ReactNode} from 'react';
import MultiCarousel, {CarouselProps, ArrowProps} from 'react-multi-carousel';
import styled from 'styled-components';

import 'react-multi-carousel/lib/styles.css';
import './Carousel.css';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import {breakpoints, size} from 'Common/constants/Breakpoints';
import ReactDOM from 'react-dom';
import Theme from 'Common/constants/Theme';

const Root = styled.div`
  @media ${breakpoints.md} {
    padding: 0 24px;
  }

  position: relative;
`;

const responsive = {
  desktop_xxl: {
    breakpoint: {max: size.xxl, min: size.xl},
    items: 5,
  },
  desktop: {
    breakpoint: {max: size.xl, min: size.md},
    items: 6,
  },
  tablet: {
    breakpoint: {max: size.md, min: size.sm},
    items: 4,
  },
  mobile: {
    breakpoint: {max: size.sm, min: 0},
    items: 3,
  },
};

interface IProps {
  customLeftArrowElement?(onClick: (() => void) | undefined): ReactNode;
  customRightArrowElement?(onClick: (() => void) | undefined): ReactNode;
}

type Props = IProps & Partial<CarouselProps>;

function Carousel(props: React.PropsWithChildren<Props>) {
  const {children, customLeftArrowElement, customRightArrowElement, ...rest} = props;
  const ref = useRef<null | HTMLDivElement>(null);

  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
  }, []);

  return (
    <Root id="carousel" ref={ref}>
      {isMount && (
        <MultiCarousel
          responsive={responsive}
          swipeable={true}
          removeArrowOnDeviceType="mobile"
          containerClass="carousel-container"
          itemClass="multi-carousel-item"
          additionalTransfrom={-10}
          customLeftArrow={<Arrow direction="left" mountTo={ref.current!} customArrow={customLeftArrowElement} />}
          customRightArrow={<Arrow direction="right" mountTo={ref.current!} customArrow={customRightArrowElement} />}
          {...rest}
        >
          {children}
        </MultiCarousel>
      )}
    </Root>
  );
}
export default memo(Carousel);

const StyledArrow = styled(ColoredIcon)<{direction: OwnArrowProps['direction']}>`
  display: none;
  @media ${breakpoints.md} {
    display: block;
  }
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;

  ${({direction}) =>
    direction === 'left'
      ? `
    left: 0;
    `
      : `
    right: 0;
    transform: translate(0, -50%) rotate(180deg);
    `}
`;

interface OwnArrowProps {
  direction: 'left' | 'right';
  mountTo: HTMLElement;
  customArrow?(onClick: (() => void) | undefined): ReactNode;
}

function Arrow(props: OwnArrowProps & ArrowProps) {
  const {onClick, direction, mountTo, customArrow} = props;

  return ReactDOM.createPortal(
    customArrow ? (
      customArrow(onClick)
    ) : (
      <StyledArrow
        direction={direction}
        onClick={onClick}
        name={IconName.Back}
        size={20}
        color={Theme.color.primary}
        fill={true}
        stroke={false}
      />
    ),
    mountTo
  );
}
