import {combineReducers} from 'redux';

import {IShortTandemRepeatReportState} from '../types';
import {communicationReducer} from './communications';
import {dataReducer} from './data';

export const shortTandemRepeatReportReducer = combineReducers<IShortTandemRepeatReportState>({
  communications: communicationReducer,
  data: dataReducer,
});
