import React, {memo} from 'react';

import OrderSuccessLayout from 'Shared/components/OrderSuccess/common/OrderSuccessLayout';
import OrderSuccessButtons from 'Shared/components/OrderSuccess/common/OrderSuccessButtons';
import {Description, Title} from 'Shared/components/OrderSuccess/common/styled';

function OrderSuccessPlaced() {
  return (
    <OrderSuccessLayout>
      <Title>Your order has been placed - thank you!</Title>
      <Description>
        <div>
          Thank you for your order. Should you wish to pay by check, be sure to include it with your sample submission
          form. If you wish to pay by card, please choose "PAY NOW" below to complete payment. Samples cannot be
          processed until payment has been made.
        </div>
      </Description>

      <OrderSuccessButtons />
    </OrderSuccessLayout>
  );
}

export default memo(OrderSuccessPlaced);
