import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';

import {
  createUserNotificationActionTypes,
  deleteUserNotificationActionTypes,
  getUserNotificationHistoryActionTypes,
  getUserNotificationsActionTypes,
  IUserNotificationState,
  updateIsReadUserNotificationActionTypes,
  updateIsReadUserNotificationAllActionTypes,
  UserNotificationsActions,
} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import NotificationService from 'Notifications/services/NotificationService';
import {NotificationType} from 'Notifications/const/NotificationType';

export const getUserNotificationHistory =
  (): ActionResult<IUserNotificationState, void, UserNotificationsActions> => async (dispatch) => {
    dispatch(action(getUserNotificationHistoryActionTypes.REQUEST));
    try {
      const res = await NotificationService.getUserNotificationHistory();
      dispatch(action(getUserNotificationHistoryActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserNotificationHistoryActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserNotifications =
  (): ActionResult<IUserNotificationState, void, UserNotificationsActions> => async (dispatch) => {
    dispatch(action(getUserNotificationsActionTypes.REQUEST));
    try {
      const res = await NotificationService.getUserNotifications();
      dispatch(action(getUserNotificationsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserNotificationsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createUserNotification =
  (eventId: number, type: NotificationType): ActionResult<IUserNotificationState, void, UserNotificationsActions> =>
  async (dispatch) => {
    dispatch(action(createUserNotificationActionTypes.REQUEST));
    try {
      const res = await NotificationService.createUserNotification(eventId, type);
      dispatch(action(createUserNotificationActionTypes.SUCCESS, {id: res, eventId, type}));
    } catch (error) {
      dispatch(action(createUserNotificationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteUserNotification =
  (notificationId: number): ActionResult<IUserNotificationState, void, UserNotificationsActions> =>
  async (dispatch) => {
    dispatch(action(deleteUserNotificationActionTypes.REQUEST));
    try {
      await NotificationService.deleteUserNotification(notificationId);
      dispatch(action(deleteUserNotificationActionTypes.SUCCESS, notificationId));
    } catch (error) {
      dispatch(action(deleteUserNotificationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateIsReadUserNotification =
  (notificationId: number): ActionResult<IUserNotificationState, void, UserNotificationsActions> =>
  async (dispatch) => {
    dispatch(action(updateIsReadUserNotificationActionTypes.REQUEST));
    try {
      await NotificationService.updateIsReadUserNotification(notificationId);
      dispatch(action(updateIsReadUserNotificationActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateIsReadUserNotificationActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateIsReadUserNotificationAll =
  (): ActionResult<IUserNotificationState, void, UserNotificationsActions> => async (dispatch) => {
    dispatch(action(updateIsReadUserNotificationAllActionTypes.REQUEST));
    try {
      await NotificationService.updateIsReadUserNotificationAll();
      dispatch(action(updateIsReadUserNotificationAllActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateIsReadUserNotificationAllActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
