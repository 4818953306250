import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {useCallback, useEffect, useState} from 'react';
import {ToastOptions} from 'react-toastify';

import {getEnvParams} from 'Common/helpers/getEnvParams';
import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import {useToast} from 'Common/helpers/hooks/useToast';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';
import AdminMessageReceived from 'SignalR/components/AdminMessageReceived';
import {Hubs} from 'SignalR/const/Hubs';
import {DEFAULT_LOG_LEVEL} from 'SignalR/const/LogLevel';
import {getAccessToken} from 'SignalR/helpers/getAccessToken';
import {signalRLogger} from 'SignalR/helpers/signalRLogger';
import {IAdminToUserMessageRead} from 'SignalR/models/IAdminToUserMessageRead';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {IReceivedAdminToUserMessage} from 'SignalR/models/IReceivedAdminToUserMessage';
import {IReceivedUserToAdminMessage} from 'SignalR/models/IReceivedUserToAdminMessage';
import {ISentAdminToUserMessage} from 'SignalR/models/ISentAdminToUserMessage';
import {ISentUserToAdminMessage} from 'SignalR/models/ISentUserToAdminMessage';
import {IUserToAdminMessageRead} from 'SignalR/models/IUserToAdminMessageRead';
import {actions} from 'SignalR/store/userToAdmin';

const toastOptions: ToastOptions = {autoClose: false};

export function useSignalRUserToAdminMessaging() {
  const [connection, setConnection] = useState<HubConnection>();
  const [isConnectionInitialized, setIsConnectionInitialized] = useState<boolean>(false);
  const [connectionState, setConnectionState] = useState<HubConnectionState>(HubConnectionState.Disconnected);

  const {baseUrl, signalRLogLevel} = getEnvParams();

  const {
    setConnectionId,
    setConnectedAdmin,
    setConnectedUser,
    setDisconnectedAdmin,
    setDisconnectedUser,
    setReceiveAdminToUserMessage,
    setReceiveUserToAdminMessage,
    setSentAdminToUserMessage,
    setSentUserToAdminMessage,
    getAdminToUserMessageRead,
    getUserToAdminMessageRead,
  } = useBindActions({
    setConnectionId: actions.setConnectionId,
    setConnectedUser: actions.setConnectedUser,
    setDisconnectedUser: actions.setDisconnectedUser,
    setConnectedAdmin: actions.setConnectedAdmin,
    setDisconnectedAdmin: actions.setDisconnectedAdmin,
    setReceiveUserToAdminMessage: actions.setReceiveUserToAdminMessage,
    setReceiveAdminToUserMessage: actions.setReceiveAdminToUserMessage,
    setSentUserToAdminMessage: actions.setSentUserToAdminMessage,
    setSentAdminToUserMessage: actions.setSentAdminToUserMessage,
    getAdminToUserMessageRead: actions.getAdminToUserMessageRead,
    getUserToAdminMessageRead: actions.getUserToAdminMessageRead,
  });

  const {addToast} = useToast();

  useEffect(() => {
    if (connection && connectionState === HubConnectionState.Connected && connection.connectionId) {
      setConnectionId(connection.connectionId);
    }
  }, [connection, connectionState, setConnectionId]);

  const initConnection = useCallback(
    async (hub: Hubs, currentUserId: number) => {
      const logLevel = LogLevel[signalRLogLevel] !== undefined ? LogLevel[signalRLogLevel] : DEFAULT_LOG_LEVEL;

      const newConnection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}${hub}`, {
          accessTokenFactory: getAccessToken,
        })
        .configureLogging(logLevel)
        .withAutomaticReconnect()
        .build();

      /*
      ------------
      User events
      ------------
      */
      newConnection.on('UserMessageSent', (sentMessage: ISentUserToAdminMessage) => {
        if (currentUserId) {
          const dataToSave: IMessageQueue = {
            id: sentMessage.id,
            fromUserId: currentUserId,
            toUserId: null,
            message: sentMessage.message,
            images: sentMessage.images ? sentMessage.images.map(withImageBaseUrl) : [],
            createDate: sentMessage.createDate,
          };
          setSentUserToAdminMessage(dataToSave);
          signalRLogger('Sent user message', sentMessage);
        } else {
          console.error('CurrentUserId is not set (sent user message)');
        }
      });
      newConnection.on('AdminMessageReceived', (receivedMessage: IReceivedAdminToUserMessage) => {
        if (currentUserId) {
          const dataToSave: IMessageQueue = {
            id: receivedMessage.id,
            fromUserId: null,
            toUserId: currentUserId,
            message: receivedMessage.message,
            images: receivedMessage.images.map(withImageBaseUrl),
            createDate: receivedMessage.createDate,
            isRead: false,
          };
          setReceiveAdminToUserMessage(dataToSave);
          signalRLogger('User received admin message', receivedMessage);
        } else {
          console.error('CurrentUserId is not set (receive message)');
        }
      });
      newConnection.on('AdminConnected', () => {
        setConnectedAdmin();
        signalRLogger('Admin connected');
      });
      newConnection.on('AdminDisconnected', () => {
        setDisconnectedAdmin();
        signalRLogger('Admin disconnected');
      });
      newConnection.on('userMessagesRead', (message: IUserToAdminMessageRead) => {
        getAdminToUserMessageRead(message);
        signalRLogger('Admin read messages', message);
      });

      /*
      ------------
      Admin events
      ------------
      */
      newConnection.on('UserMessageNotificationReceived', (userId: number) => {
        if (currentUserId) {
          signalRLogger('Received user message notification', userId);
          addToast(<AdminMessageReceived userId={userId} />, 'info', toastOptions);
        } else {
          console.error('CurrentUserId is not set (received message notification)');
        }
      });
      newConnection.on('AdminMessageSent', (sentMessage: ISentAdminToUserMessage) => {
        if (currentUserId) {
          const dataToSave: IMessageQueue = {
            id: sentMessage.id,
            fromUserId: null,
            toUserId: sentMessage.toUserId,
            message: sentMessage.message,
            images: sentMessage.images.map(withImageBaseUrl),
            createDate: sentMessage.createDate,
            isRead: false,
          };
          setSentAdminToUserMessage(dataToSave);
          signalRLogger('Sent admin message', sentMessage);
        } else {
          console.error('CurrentUserId is not set (sent admin message)');
        }
      });
      newConnection.on('UserMessageReceived', (receivedMessage: IReceivedUserToAdminMessage) => {
        if (currentUserId) {
          const dataToSave: IMessageQueue = {
            id: receivedMessage.id,
            fromUserId: receivedMessage.fromUserId,
            toUserId: null,
            message: receivedMessage.message,
            images: receivedMessage.images.map(withImageBaseUrl),
            createDate: receivedMessage.createDate,
            isRead: false,
          };
          setReceiveUserToAdminMessage(dataToSave);
          signalRLogger('Admin received user message', receivedMessage);
        } else {
          console.error('CurrentUserId is not set (receive message)');
        }
      });
      newConnection.on('UserConnected', (userId: number) => {
        setConnectedUser(userId);
        signalRLogger('User connected', userId);
      });
      newConnection.on('UserDisconnected', (userId: number) => {
        setDisconnectedUser(userId);
        signalRLogger('User disconnected', userId);
      });
      newConnection.on('adminMessagesRead', (message: IAdminToUserMessageRead) => {
        getUserToAdminMessageRead(message);
        signalRLogger('User read messages', message);
      });

      setConnection(newConnection);
      setIsConnectionInitialized(true);
    },
    [
      addToast,
      baseUrl,
      getAdminToUserMessageRead,
      getUserToAdminMessageRead,
      setConnectedAdmin,
      setConnectedUser,
      setDisconnectedAdmin,
      setDisconnectedUser,
      setReceiveAdminToUserMessage,
      setReceiveUserToAdminMessage,
      setSentAdminToUserMessage,
      setSentUserToAdminMessage,
      signalRLogLevel,
    ]
  );

  const startConnection = useCallback(async () => {
    if (connectionState === HubConnectionState.Disconnected && connection) {
      try {
        setConnectionState(HubConnectionState.Connecting);
        await connection.start();
        setConnectionState(HubConnectionState.Connected);
        signalRLogger('userToAdminMessaging connection started. id', connection.connectionId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection, connectionState]);

  const closeConnection = useCallback(async () => {
    if (connection) {
      try {
        signalRLogger('userToAdminMessaging connection closed. id', connection.connectionId);
        setConnectionState(HubConnectionState.Disconnecting);
        await connection.stop();
        setConnectionState(HubConnectionState.Disconnected);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection]);

  return {
    initConnection,
    isConnectionInitialized,
    connectionState,
    startConnection,
    closeConnection,
  };
}
