import React, {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import styled from 'styled-components';

import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {IDispatch, IState, orderSuccessVisitorDistributor} from 'Shared/components/OrderSuccess/visitorDistributor';
import {IAppState} from 'Common/store/IAppState';
import AuthService from 'Auth/services/AuthService';
import {IVisitorTypeDepended} from 'Common/models/IVisitorTypeDepended';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {VisitorType} from 'Common/constants/VisitorType';

const Actions = styled.div`
  width: 256px;
`;

const Button = styled(PrimaryButton)`
  margin-bottom: 16px;
`;

type RouteParams = {
  token?: string;
};

interface IExternalProps extends IVisitorTypeDepended {
  isPayment?: boolean;
}

type AllProps = IExternalProps & IState & IDispatch;

const OrderSuccessButtons = (props: AllProps) => {
  const {isPayment} = props;
  const {submissionFormRequesting, getSubmissionForm, currentVisitor, toOrdersPageLink, urlPrefix} = props;

  const {token} = useParams<RouteParams>();
  const navigate = useNavigate();

  const downloadSubmissionForm = useCallback(() => {
    token && getSubmissionForm(token);
  }, [getSubmissionForm, token]);

  useErrorCommunicationToToast(submissionFormRequesting);

  const openPaymentForm = useCallback(() => {
    token && navigate({pathname: `${urlPrefix}/payment`, search: `?token=${token}`});
  }, [token, navigate, urlPrefix]);

  const {currentVisitorType} = useVisitorTypeService();

  return (
    <Actions>
      {!isPayment && (
        <Button onClick={openPaymentForm} size="small" fullWidth={true}>
          Pay now
        </Button>
      )}
      {!!currentVisitor && (
        <Button
          disabled={!token}
          isLoading={submissionFormRequesting.isRequesting}
          onClick={downloadSubmissionForm}
          size="small"
          fullWidth={true}
        >
          Download Submission Form
        </Button>
      )}
      <LinkButton to={`${urlPrefix}${toOrdersPageLink}`} size="small" variant="outlined" fullWidth={true}>
        {`To ${currentVisitorType === VisitorType.AssociationEmployee ? 'Dashboard' : 'orders'} page`}
      </LinkButton>
    </Actions>
  );
};

const mapStateToProps = (state: IAppState, externalProps: IExternalProps) =>
  orderSuccessVisitorDistributor[externalProps.type].state;

const mapDispatchToProps = (dispatch: ThunkDispatch<IAppState, undefined, any>, externalProps: IExternalProps) =>
  bindActionCreators(orderSuccessVisitorDistributor[externalProps.type].dispatch as any, dispatch);

const Connected = connect(mapStateToProps, mapDispatchToProps)(OrderSuccessButtons) as unknown as (
  props: IExternalProps
) => JSX.Element;
const Exported = (externalProps: Omit<IExternalProps, 'type'>) => {
  const visitorType = AuthService.takeVisitorType()!;
  const modules = orderSuccessVisitorDistributor[visitorType].modules || [];

  return (
    <DynamicModuleLoader modules={[modules]}>
      <Connected {...externalProps} type={visitorType!} />
    </DynamicModuleLoader>
  );
};

export default Exported;
