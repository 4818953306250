import React, {memo} from 'react';
import styled from 'styled-components';

import ImageUploader from 'Image/components/ImageUploader';
import {IImage} from 'Image/models/IImage';
import ColorPalette from 'Common/constants/ColorPalette';

const Root = styled.div`
  padding: 4px;
  border: 1px solid ${ColorPalette.gray47};
  border-radius: 4px;
  margin-bottom: 16px;
`;

const ImagePanel = styled.div`
  gap: 8px;
`;

interface IProps {
  images: IImage[];
  onDelete(index: number): void;
}

function AttachFilePanel(props: IProps) {
  const {images, onDelete} = props;

  return (
    <Root className="d-flex">
      <ImagePanel className="d-flex flex-wrap">
        {images.map((image, i) => {
          const onDeleteImage = () => {
            onDelete(i);
          };

          return (
            <ImageUploader
              key={i}
              width="80px"
              height="80px"
              actionButtonsContainerHeight="100%"
              value={image}
              title="Add image"
              showEditButton={false}
              onDelete={onDeleteImage}
            />
          );
        })}
      </ImagePanel>
    </Root>
  );
}

export default memo(AttachFilePanel);
