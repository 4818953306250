import React, {useCallback, useEffect, useState} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {isEqual} from 'lodash';

import {
  initialValue,
  IFormValues,
  validationSchema,
} from 'Admin/AdminDashboard/components/Packages/PackageForm/validation';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage, Icon, IconedField} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {ICommunication} from 'Common/store/utils/communication/types';
import {FormType} from 'Common/constants/FormType';
import {CheckboxField, InputField, SelectField, TextAreaField} from 'Common/components/FormFields';
import {IPackage} from 'Dictionaries/models/IPackage';
import {actions as packageActions, selectors as packageSelectors} from 'Admin/AdminDashboard/store/adminPackages';
import Loading from 'Loading/components/Loading';
import {ITest} from 'Dictionaries/models/ITest';
import {castToOption} from 'Common/helpers/OptionHelper';
import {
  convertPackageFormToPackageCreateRequest,
  convertPackageFormToPackageUpdateRequest,
  convertPackageToPackageForm,
} from 'Admin/AdminDashboard/components/Packages/PackageForm/converters';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import Nebula from 'Common/components/Layout/Nebula';
import WarningModalWindow, {ModalTypes} from 'Common/components/Modal/WarningModal';
import capitalizeFirstLetter from 'Common/helpers/capitalizeFirstLetter';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import {useDictionaries} from 'Common/store/useDictionaries';
import {IReportType} from 'Dictionaries/models/IReportType';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import HintIcon from 'Common/components/HintIcont/HintIcon';

const ARCHIVE_PACKAGE_MESSAGE =
  'If you apply changes, package will be archived as old version and we will create a new package with the same name and properties that you specify';

const formHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add package',
  edit: 'Edit item',
  createBased: 'Add package',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit | FormType.createBased, string> = {
  create: 'Add',
  edit: 'Save',
  createBased: 'Add',
};

interface IExternalDictionaries {
  associationDictionary: ISimpleDictionary<IAssociation>;
  testDictionary: ISimpleDictionary<ITest>;
  reportTypeDictionary: ISimpleDictionary<IReportType>;
  packageGroupsDictionary: ISimpleDictionary<IPackageGroup>;
}

interface IExternalProps extends IExternalDictionaries {
  packageInfo: IPackage | null;
  type: FormType;
  externalCommunication: ICommunication;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const PackageFormLayout = (props: AllProps) => {
  const nebulaStyle = {opacity: 0.5};

  const {
    status,
    setStatus,
    setErrors,
    isSubmitting,
    handleSubmit,
    testDictionary,
    associationDictionary,
    reportTypeDictionary,
    packageGroupsDictionary,
  } = props;
  const {tests, associations, reportTypes, type, packageInfo, externalCommunication, packageGroups, onSuccess} = props;
  const {packageCreating, packageUpdating} = props;

  const errorInfo = externalCommunication.error || packageCreating.error || packageUpdating.error;
  const isRequesting = [externalCommunication, packageCreating, packageUpdating].some((i) => i.isRequesting);

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  const isLightLock = packageInfo?.isUsed && type !== FormType.createBased;
  const isCreateForm = type === FormType.create || type === FormType.createBased;

  useOnSuccessCommunication(packageUpdating, onSuccess);
  useOnSuccessCommunication(packageCreating, onSuccess);

  const {
    actions: {getItems: getTests},
  } = testDictionary;
  const {
    actions: {getItems: getAssociations},
  } = associationDictionary;
  const {
    actions: {getItems: getReportTypes},
  } = reportTypeDictionary;
  const {
    actions: {getItems: getPackageGroups},
  } = packageGroupsDictionary;

  const associationsOptions = () =>
    castToOption(associations, {labelFormatter: (value) => capitalizeFirstLetter(value.toLowerCase())}).sort((a, b) =>
      sortByStringKey(a, b, 'label')
    );

  useEffect(() => {
    getTests();
    getAssociations();
    getReportTypes();
    getPackageGroups();
  }, [getAssociations, getPackageGroups, getReportTypes, getTests]);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    const fieldErrors = getFieldErrors(errorInfo);

    if (commonErrors) {
      setStatus(commonErrors);
    }

    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setStatus, setErrors, errorInfo]);

  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false);
  const openArchiveModal = useCallback(() => setIsOpenArchiveModal(true), [setIsOpenArchiveModal]);
  const closeArchiveModal = useCallback(() => setIsOpenArchiveModal(false), [setIsOpenArchiveModal]);
  const successConfirmArchiveModal = useCallback(() => {
    closeArchiveModal();
    handleSubmit();
  }, [closeArchiveModal, handleSubmit]);

  const onSubmitHandler = useCallback(() => {
    const currentPrice = props.values.price || initialValue.price;
    const currentTests = props.values.tests || initialValue.tests;

    const isPriceChanged = currentPrice !== packageInfo?.price;
    const isTestsChanged = !isEqual(currentTests.sort(), packageInfo?.tests.map((i) => i.id).sort());

    if (packageInfo?.isUsed && (isPriceChanged || isTestsChanged)) {
      openArchiveModal();
      return;
    }

    handleSubmit();
  }, [packageInfo, openArchiveModal, handleSubmit, props.values.price, props.values.tests]);

  return (
    <>
      <WarningModalWindow
        modalType={ModalTypes.Confirm}
        isOpen={isOpenArchiveModal}
        onClose={closeArchiveModal}
        onSuccess={successConfirmArchiveModal}
      >
        {ARCHIVE_PACKAGE_MESSAGE}
      </WarningModalWindow>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isRequesting && <Loading />}
          <Nebula active={isLightLock} style={nebulaStyle}>
            <InputField isRequired={true} name="name" type="text" label="Name" placeholder="Name" autoComplete="off" />
            <InputField
              name="abbreviation"
              type="text"
              label="Abbreviation"
              placeholder="Abbreviation"
              className="w-50"
              autoComplete="off"
            />
            <SelectField isClearable={true} name="association" label="Association" options={associationsOptions()} />
          </Nebula>
          <SelectField isMulti={true} name="tests" label="Tests" options={castToOption(tests)} />
          <SelectField
            isMulti={true}
            name="reportTypes"
            label="Report Types"
            options={castToOption(reportTypes)}
            className="w-50"
          />

          <TextAreaField
            name="description"
            label="Description"
            placeholder="Add description to notice some facts about package"
          />
          <IconedField>
            <InputField
              inputStyle={{textIndent: 15}}
              name="price"
              type="text"
              label="Price"
              placeholder="Price"
              className="w-50"
              autoComplete="off"
            />
            <Icon>$</Icon>
          </IconedField>

          {isCreateForm && (
            <FormControlContainer title="Package settings for Shopping cart" style={{marginTop: 24}}>
              <div className="d-flex w-100 align-items-center">
                <div className="w-50">
                  <SelectField
                    isMulti={true}
                    name="groups"
                    label="Package groups"
                    options={castToOption(packageGroups)}
                    menuPosition="fixed"
                  />
                </div>
                <HintIcon tooltip="Which 'Categories' it belongs to in Shopping cart" />
              </div>

              <div className="d-flex w-100 align-items-center">
                <div className="w-50">
                  <InputField name="positionIndex" label="Order in Category" placeholder="Order in Category" />
                </div>
              </div>

              <CheckboxField name="isBundle" label="Included to 'Bundles'" style={{marginBottom: 8}} />
            </FormControlContainer>
          )}
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="button" onClick={onSubmitHandler} isLoading={isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const PackageForm = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({packageInfo, type}) =>
    packageInfo ? convertPackageToPackageForm(packageInfo, type) : initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const isCreateForm = formikBag.props.type === FormType.create || formikBag.props.type === FormType.createBased;

    formikBag.setSubmitting(true);
    if (isCreateForm) {
      formikBag.props.createPackage(convertPackageFormToPackageCreateRequest(values));
    } else {
      formikBag.props.updatePackage(convertPackageFormToPackageUpdateRequest(values));
    }
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(PackageFormLayout);

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {associationDictionary, testDictionary, reportTypeDictionary, packageGroupsDictionary} = props;
  const {selectors: testSelectors} = testDictionary;
  const {selectors: associationSelectors} = associationDictionary;
  const {selectors: reportTypeSelectors} = reportTypeDictionary;
  const {selectors: packageGroupSelectors} = packageGroupsDictionary;

  return {
    packageCreating: packageSelectors.selectCommunication(state, 'packageCreating'),
    packageUpdating: packageSelectors.selectCommunication(state, 'packageUpdating'),
    tests: testSelectors.selectItems(state),
    testsLoading: testSelectors.selectCommunication(state, 'itemsLoading'),
    associations: associationSelectors.selectItems(state),
    associationsLoading: associationSelectors.selectCommunication(state, 'itemsLoading'),
    reportTypes: reportTypeSelectors.selectItems(state),
    reportTypesLoading: reportTypeSelectors.selectCommunication(state, 'itemsLoading'),
    packageGroups: packageGroupSelectors.selectItems(state),
    packageGroupsLoading: packageGroupSelectors.selectCommunication(state, 'itemsLoading'),
  };
};

const mapDispatchToProps = {
  createPackage: packageActions.createPackage,
  updatePackage: packageActions.updatePackage,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(PackageForm);

const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {associations, tests, reportTypes, packageGroups} = useDictionaries();

  return (
    <Connected
      {...externalProps}
      testDictionary={tests}
      associationDictionary={associations}
      reportTypeDictionary={reportTypes}
      packageGroupsDictionary={packageGroups}
    />
  );
};

export default Exported;
