import {IUserToAdminChatMessageAdmin} from 'Admin/AdminSupport/models/IUserToAdminChatMessageAdmin';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';

export function filterIncomingMessages(value: IMessageQueue[], userId: number): IMessageQueue[] {
  return value.filter(
    (x) => (x.fromUserId === userId && x.toUserId === null) || (x.fromUserId === null && x.toUserId === userId)
  );
}

export function convertUserToAdminChatMesssageToIMessageQueue(
  value: IUserToAdminChatMessageAdmin,
  userId: number
): IMessageQueue[] {
  const userMessages: IMessageQueue[] = value.userMessages.map((x) => {
    return {
      id: x.id,
      fromUserId: userId,
      message: x.message,
      toUserId: null,
      createDate: x.createDate,
      images: x.images,
      isRead: x.isRead,
    };
  });

  const adminMessages: IMessageQueue[] = value.adminMessages.map((x) => ({
    id: x.id,
    fromUserId: null,
    message: x.message,
    toUserId: userId,
    createDate: x.createDate,
    images: x.images,
    user: x.admin,
    isRead: x.isRead,
  }));

  const dataToSave = userMessages.concat(adminMessages).sort((a, b) => {
    const aDate = a.createDate ? new Date(a.createDate).getTime() : 0;
    const bDate = b.createDate ? new Date(b.createDate).getTime() : 0;
    return aDate - bDate;
  });

  return dataToSave;
}
