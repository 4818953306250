import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.ancestryReport;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectPopulation(state: IAppState) {
  return selectState(state).data.population;
}

export function selectPopulationComparison(state: IAppState) {
  return selectState(state).data.populationComparison;
}

export function selectAncestryProbabilities(state: IAppState) {
  return selectState(state).data.ancestryProbabilities;
}

export function selectAncestryRelatedHorses(state: IAppState) {
  return selectState(state).data.ancestryRelatedHorses;
}

export function selectAncestryKinshipHorses(state: IAppState) {
  return selectState(state).data.ancestryKinshipHorses;
}

export function selectAncestryPcaReferenceData(state: IAppState) {
  return selectState(state).data.ancestryPcaReferenceData;
}

export function selectAncestryHorsePrivacy(state: IAppState) {
  return selectState(state).data.ancestryHorsePrivacy;
}
