import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  Actions,
  changeHorseOwnerActions,
  changeHorseOwnerActionTypes,
  createHorseTransferActions,
  createHorseTransferActionTypes,
  getHorseTransferDetailsActions,
  getHorseTransferDetailsActionTypes,
  getHorseTransfersActions,
  getHorseTransfersActionTypes,
  regectHorseTransferActions,
  rejectHorseTransferActionTypes,
  resetHorseTransferDetailsActionTypes,
} from './types';
import HorseTransferDataService from 'Admin/AdminDashboard/services/HorseTransferDataService';
import {IRequestParams} from 'Common/models/IRequestParams';
import {
  IServerHorseTransferRejectAdmin,
  IServerHorseTransferRequestAdmin,
} from 'Admin/AdminDashboard/services/types/transfer';
import {IChangeHorseOwner} from 'Admin/AdminDashboard/models/Horse/IChangeHorseOwner';

export const getHorseTransfers =
  (params?: IRequestParams): ActionResult<IAppState, void, getHorseTransfersActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseTransfersActionTypes.REQUEST));
      const res = await HorseTransferDataService.getHorseTransfers(params);
      dispatch(action(getHorseTransfersActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseTransfersActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseTransferDetails =
  (id: number): ActionResult<IAppState, void, getHorseTransferDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseTransferDetailsActionTypes.REQUEST));
      const res = await HorseTransferDataService.getHorseTransferDetails(id);
      dispatch(action(getHorseTransferDetailsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseTransferDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const rejectHorseTransfer =
  (params: IServerHorseTransferRejectAdmin): ActionResult<IAppState, void, regectHorseTransferActions> =>
  async (dispatch) => {
    try {
      dispatch(action(rejectHorseTransferActionTypes.REQUEST));
      await HorseTransferDataService.rejectHorseTransfer(params);
      dispatch(action(rejectHorseTransferActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(rejectHorseTransferActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeHorseOwner =
  (params: IChangeHorseOwner): ActionResult<IAppState, void, changeHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(changeHorseOwnerActionTypes.REQUEST));
      await HorseTransferDataService.changeHorseOwner(params);
      dispatch(action(changeHorseOwnerActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(changeHorseOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createHorseTransfer =
  (params: IServerHorseTransferRequestAdmin): ActionResult<IAppState, void, createHorseTransferActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createHorseTransferActionTypes.REQUEST));
      await HorseTransferDataService.createHorseTransfer(params);
      dispatch(action(createHorseTransferActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createHorseTransferActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseTransferDetails = (): ActionResult<IAppState, void, Actions> => (dispatch) => {
  dispatch(action(resetHorseTransferDetailsActionTypes.RESET));
};
