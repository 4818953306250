import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {useFormikContext} from 'formik';
import {DynamicModuleLoader} from 'redux-dynamic-modules';

import {IColor} from 'Dictionaries/models/IColor';
import {IAppState} from 'Common/store/IAppState';
import {IColorModifier} from 'Dictionaries/models/IColorModifier';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IFormValues} from './formConfig';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';

import {BaseTraits, Colors, GenePools, Health, ParentFeatures, Priorities, SpecificHorse} from './parts';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {HorseFiltersModule} from 'Filters/store/module';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {useDictionaries} from 'Common/store/useDictionaries';
import Loading from 'Loading/components/Loading';
import {IHealthVariantToAvoid} from 'Dictionaries/models/IHealthVariantToAvoid';

const FilterContainer = styled.div`
  :not(:first-child) {
    margin-top: 20px;
  }
`;

function getSelectedCounts(values: Partial<IFormValues>) {
  const {
    sourceTypes,
    commercialTypes,
    isSpeedSelected,
    isGaitSelected,
    isTemperamentSelected,
    abilities,
    colorModifiers,
    color,
    disciplines,
    breeds,
    healthVariantsToAvoid,
  } = values;

  return {
    genPoolCount: (sourceTypes?.length || 0) + (commercialTypes?.length || 0),
    selectedPriorities:
      (isSpeedSelected && abilities?.speed ? 1 : 0) +
      (isGaitSelected && abilities?.gait ? 1 : 0) +
      (isTemperamentSelected && abilities?.temperament ? 1 : 0),
    colorsCount: (colorModifiers?.length || 0) + (color ? 1 : 0),
    parentsFeaturesCount: (disciplines?.length || 0) + (breeds?.length || 0),
    healthVariantsCount: healthVariantsToAvoid?.length || 0,
  };
}

interface IExternalDictionaries {
  colorDictionary: ISimpleDictionary<IColor>;
  colorModifierDictionary: ISimpleDictionary<IColorModifier>;
  breedDictionary: ISimpleDictionary<IBreed>;
  disciplineDictionary: ISimpleDictionary<IDiscipline>;
  healthVariantDictionary: ISimpleDictionary<IHealthVariantToAvoid>;
  commercialTypeDictionary: ISimpleDictionary<ICommercialType>;
  associationDictionary: ISimpleDictionary<IAssociation>;
  abilityDictionary: ISimpleDictionary<IAbilities>;
}

interface IExternalProps extends IExternalDictionaries {
  isLoading?: boolean;
  variant: 'bah' | 'fah';
  onOpenExcludeModal?(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IExternalProps;

function HorseFilters(props: AllProps) {
  const {
    colorDictionary,
    colorModifierDictionary,
    breedDictionary,
    disciplineDictionary,
    healthVariantDictionary,
    commercialTypeDictionary,
    associationDictionary,
    abilityDictionary,
  } = props;
  const {
    colors,
    colorModifiers,
    breeds,
    disciplines,
    healthVariants,
    commercialTypes,
    associations,
    abilities,
    abilitiesLoading,
    colorsLoading,
    healthVariantsLoading,
    associationsLoading,
    breedsLoading,
    colorModifiersLoading,
    commercialTypesLoading,
    disciplinesLoading,
  } = props;
  const {variant, onOpenExcludeModal} = props;

  const {values} = useFormikContext<Partial<IFormValues>>();

  const extendedHealthVariants = healthVariants.map((i) => ({...i, id: `${i.id}_${i.isAggregated}`}));

  const {
    actions: {getItems: getColors},
  } = colorDictionary;
  const {
    actions: {getItems: getColorModifiers},
  } = colorModifierDictionary;
  const {
    actions: {getItems: getBreeds},
  } = breedDictionary;
  const {
    actions: {getItems: getDisciplines},
  } = disciplineDictionary;
  const {
    actions: {getItems: getHealthVariants},
  } = healthVariantDictionary;
  const {
    actions: {getItems: getCommercialTypes},
  } = commercialTypeDictionary;
  const {
    actions: {getItems: getAssociations},
  } = associationDictionary;
  const {
    actions: {getItems: getAbilities},
  } = abilityDictionary;

  useEffect(() => {
    getColors();
    getColorModifiers();
    getBreeds();
    getDisciplines();
    getHealthVariants();
    getCommercialTypes();
    getAssociations();
    getAbilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBah = variant === 'bah';

  const {genPoolCount, selectedPriorities, colorsCount, parentsFeaturesCount, healthVariantsCount} =
    getSelectedCounts(values);

  const isLoading = [
    abilitiesLoading,
    colorsLoading,
    healthVariantsLoading,
    associationsLoading,
    breedsLoading,
    colorModifiersLoading,
    commercialTypesLoading,
    disciplinesLoading,
  ].some((i) => i.isRequesting);

  return (
    <div>
      {isLoading && <Loading />}
      <FilterContainer>
        <BaseTraits variant={variant} />
      </FilterContainer>
      <FilterContainer>
        <GenePools
          selectedGenePools={values.sourceTypes || []}
          distance={values.distance?.value}
          commercialTypes={commercialTypes}
          selectedCommercialTypes={values.commercialTypes || []}
          associations={associations}
          selectedAssociations={values.associations || []}
          selectedCount={genPoolCount}
          selectedGender={values.gender}
          onOpenExcludeModal={onOpenExcludeModal}
        />
      </FilterContainer>
      {isBah && (
        <FilterContainer>
          <SpecificHorse horseId={values.horseId} />
        </FilterContainer>
      )}
      <FilterContainer>
        <Priorities
          abilities={abilities}
          isSpeedSelected={values.isSpeedSelected}
          isTemperamentSelected={values.isTemperamentSelected}
          isGaitSelected={values.isGaitSelected}
          selectedCount={selectedPriorities}
        />
      </FilterContainer>
      <FilterContainer>
        <Colors colors={colors} modifiers={colorModifiers} selectedCount={colorsCount} />
      </FilterContainer>
      <FilterContainer>
        <ParentFeatures breeds={breeds} disciplines={disciplines} selectedCount={parentsFeaturesCount} />
      </FilterContainer>
      <FilterContainer>
        <Health healthVariant={extendedHealthVariants} selectedCount={healthVariantsCount} />
      </FilterContainer>
    </div>
  );
}

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {
    colorDictionary,
    colorModifierDictionary,
    breedDictionary,
    disciplineDictionary,
    healthVariantDictionary,
    commercialTypeDictionary,
    associationDictionary,
    abilityDictionary,
  } = props;

  const {selectors: colorSelectors} = colorDictionary;
  const {selectors: modifierSelectors} = colorModifierDictionary;
  const {selectors: breedSelectors} = breedDictionary;
  const {selectors: disciplineSelectors} = disciplineDictionary;
  const {selectors: healthVariantSelectors} = healthVariantDictionary;
  const {selectors: commercialTypeSelectors} = commercialTypeDictionary;
  const {selectors: associationSelectors} = associationDictionary;
  const {selectors: abilitySelectors} = abilityDictionary;

  return {
    colors: colorSelectors.selectItems(state),
    colorsLoading: colorSelectors.selectCommunication(state, 'itemsLoading'),
    colorModifiers: modifierSelectors.selectItems(state),
    colorModifiersLoading: modifierSelectors.selectCommunication(state, 'itemsLoading'),
    breeds: breedSelectors.selectItems(state),
    breedsLoading: breedSelectors.selectCommunication(state, 'itemsLoading'),
    disciplines: disciplineSelectors.selectItems(state),
    disciplinesLoading: disciplineSelectors.selectCommunication(state, 'itemsLoading'),
    healthVariants: healthVariantSelectors.selectItems(state),
    healthVariantsLoading: healthVariantSelectors.selectCommunication(state, 'itemsLoading'),
    commercialTypes: commercialTypeSelectors.selectItems(state),
    commercialTypesLoading: commercialTypeSelectors.selectCommunication(state, 'itemsLoading'),
    associations: associationSelectors.selectItems(state),
    associationsLoading: associationSelectors.selectCommunication(state, 'itemsLoading'),
    abilities: abilitySelectors.selectItems(state),
    abilitiesLoading: abilitySelectors.selectCommunication(state, 'itemsLoading'),
  };
};

const connector = connect(mapStateToProps);
const Connected = connector(memo(HorseFilters));
const Exported = (externalProps: Omit<React.ComponentProps<typeof Connected>, keyof IExternalDictionaries>) => {
  const {colors, colorModifiers, breeds, disciplines, healthVariantsToAvoid, commercialTypes, associations, abilities} =
    useDictionaries();

  return (
    <DynamicModuleLoader modules={[HorseFiltersModule]}>
      <Connected
        colorDictionary={colors}
        colorModifierDictionary={colorModifiers}
        breedDictionary={breeds}
        disciplineDictionary={disciplines}
        healthVariantDictionary={healthVariantsToAvoid}
        commercialTypeDictionary={commercialTypes}
        associationDictionary={associations}
        abilityDictionary={abilities}
        {...externalProps}
      />
    </DynamicModuleLoader>
  );
};

export default Exported;
