import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {breakpoints} from 'Common/constants/Breakpoints';
import {ButtonWrapper} from './common/styled';

const Text = styled.div`
  width: 100%;
  margin-bottom: 24px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.black};

  @media ${breakpoints.sm} {
    width: 416px;
  }
`;

interface IProps {
  hasBahPermissions: boolean;
}

const EmptySubscriptions = (props: IProps) => {
  const {hasBahPermissions} = props;
  const navigate = useNavigate();

  const redirectToBah = useCallback(() => {
    navigate('/build-a-horse');
  }, [navigate]);

  return (
    <div className="d-flex flex-column w-100 justify-content-center align-items-center">
      {hasBahPermissions && <Text>You don't have subscriptions</Text>}
      {!hasBahPermissions && (
        <>
          <Text>You are not subscribed for Build-a-horse yet. Check what you can get with our subscription.</Text>
          <ButtonWrapper onClick={redirectToBah}>Let's see</ButtonWrapper>
        </>
      )}
    </div>
  );
};

export default EmptySubscriptions;
