import React, {useEffect, useMemo} from 'react';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from '../../../../../Common/components/Modal/shared';
import ReactSelect from 'react-select';
import {DateTime} from '../../../../../Common/components/Controls';
import {ActivationCodeStatus} from '../../../../../ActivationCode/constants/ActivationCodeStatus';
import {AnimalType} from '../../../../../Common/constants/AnimalType';
import {useSelector} from 'react-redux';
import {useDictionaries} from '../../../../../Common/store/useDictionaries';
import {castToOption} from '../../../../../Common/helpers/OptionHelper';
import moment, {isMoment} from 'moment';

interface ActivationCodeFiltersProps {
  filters: Record<string, string | undefined>;
  onChangeFilters: (filterName: string, filterValue: string | undefined) => void;
}

export function ActivationCodeFilters({filters = {}, onChangeFilters}: ActivationCodeFiltersProps) {
  const {activePackages} = useDictionaries();

  const packages = useSelector(activePackages.selectors.selectItems);

  useEffect(() => {
    activePackages.actions.getItems();
  }, []);

  const handleResetFilters = () => {
    onChangeFilters('Status', undefined);
    onChangeFilters('AnimalType', undefined);
    onChangeFilters('PackageId', undefined);
    onChangeFilters('DateCreatedFrom', undefined);
    onChangeFilters('DateCreatedTo', undefined);
    onChangeFilters('DateRedeemedFrom', undefined);
    onChangeFilters('DateRedeemedTo', undefined);
  };

  const packageOptions = useMemo(() => castToOption(packages), [packages]);

  return (
    <>
      <ModalWindowHeader>Filters</ModalWindowHeader>

      <ModalWindowFormContent>
        <div className="d-flex flex-column p-2" style={{gap: '8px'}}>
          <div className="d-flex flex-1">
            <div className="w-100">Status</div>
            <div className="w-100">
              <ReactSelect
                value={
                  filters.Status ? {label: filters.Status, value: filters.Status} : {label: 'All', value: undefined}
                }
                onChange={(option) => onChangeFilters('Status', option?.value)}
                options={[
                  {label: 'All', value: undefined},
                  {
                    label: 'Active',
                    value: ActivationCodeStatus.Active,
                  },
                  {
                    label: 'Inactive',
                    value: ActivationCodeStatus.Inactive,
                  },
                  {
                    label: 'Redeemed',
                    value: ActivationCodeStatus.Redeemed,
                  },
                ]}
              />
            </div>
          </div>

          <div className="d-flex flex-1">
            <div className="w-100">Animal Type</div>
            <div className="w-100">
              <ReactSelect
                value={
                  filters.AnimalType
                    ? {label: filters.AnimalType, value: filters.AnimalType}
                    : {label: 'All', value: undefined}
                }
                onChange={(option) => onChangeFilters('AnimalType', option?.value)}
                options={[
                  {label: 'All', value: undefined},
                  {label: 'Dog', value: AnimalType.Dog},
                  {label: 'Horse', value: AnimalType.Horse},
                ]}
              />
            </div>
          </div>

          <div className="d-flex flex-1">
            <div className="w-100">Product</div>
            <div className="w-100">
              <ReactSelect
                value={
                  filters.PackageId
                    ? {label: filters.PackageId, value: filters.PackageId}
                    : {label: 'All', value: undefined}
                }
                onChange={(option) => onChangeFilters('PackageId', option?.value)}
                options={[{label: 'All', value: undefined}, ...(packageOptions as any)]}
              />
            </div>
          </div>

          <div className="d-flex flex-1">
            <div className="w-100">Date Created From</div>
            <div className="w-100">
              <DateTime
                value={filters.DateCreatedFrom ? moment(filters.DateCreatedFrom) : ''}
                onChange={(date) => onChangeFilters('DateCreatedFrom', isMoment(date) ? date.toISOString() : undefined)}
                futureEnabled={false}
                showTime={false}
              />
            </div>
          </div>

          <div className="d-flex flex-1">
            <div className="w-100">Date Created To</div>
            <div className="w-100">
              <DateTime
                futureEnabled={false}
                value={filters.DateCreatedTo ? moment(filters.DateCreatedTo) : ''}
                onChange={(date) => onChangeFilters('DateCreatedTo', isMoment(date) ? date.toISOString() : undefined)}
                showTime={false}
              />
            </div>
          </div>

          <div className="d-flex flex-1">
            <div className="w-100">Date Redeemed From</div>
            <div className="w-100">
              <DateTime
                futureEnabled={false}
                value={filters.DateRedeemedFrom ? moment(filters.DateRedeemedFrom) : ''}
                onChange={(date) =>
                  onChangeFilters('DateRedeemedFrom', isMoment(date) ? date.toISOString() : undefined)
                }
                showTime={false}
              />
            </div>
          </div>

          <div className="d-flex flex-1">
            <div className="w-100">Date Redeemed To</div>
            <div className="w-100">
              <DateTime
                futureEnabled={false}
                value={filters.DateRedeemedTo ? moment(filters.DateRedeemedTo) : ''}
                onChange={(date) => onChangeFilters('DateRedeemedTo', isMoment(date) ? date.toISOString() : undefined)}
                showTime={false}
              />
            </div>
          </div>
        </div>
      </ModalWindowFormContent>
      <ModalWindowFooter>
        <ModalWindowButton onClick={handleResetFilters} type="button">
          Reset Filters
        </ModalWindowButton>
      </ModalWindowFooter>
    </>
  );
}
