import {action} from 'typesafe-actions';

import InbreedingCoefficientsService from 'Admin/AdminDashboard/services/InbreedingCoefficientsService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {uploadInbreedingCoefficientsActions, uploadInbreedingCoefficientsActionTypes} from './types';

export const uploadInbreedingCoefficients =
  (file: File): ActionResult<IAppState, void, uploadInbreedingCoefficientsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadInbreedingCoefficientsActionTypes.REQUEST));
      await InbreedingCoefficientsService.uploadInbreedingCoefficients(file);
      dispatch(action(uploadInbreedingCoefficientsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadInbreedingCoefficientsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
