import React, {memo} from 'react';
import styled from 'styled-components';

import Typography from 'Common/constants/Typography';
import {IOnlineReportGroup} from 'OnlineReport/models/shared/IOnlineReportGroup';
import {breakpoints} from 'Common/constants/Breakpoints';
import Image from 'Common/components/Image/Image';

const Root = styled.div`
  page-break-inside: auto;
`;

const Header = styled.div`
  margin: 15px 16px 0 0;
`;

const Title = styled.div`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size24};
  line-height: 32px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size30};
    line-height: 48px;
  }

  @media ${breakpoints.md} {
    font-size: ${Typography.size.size32};
    line-height: 56px;
  }

  @media print {
    font-size: ${Typography.size.size32};
    line-height: 56px;
  }
`;

const Circle = styled.div`
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.02);
  margin-right: 16px;

  @media ${breakpoints.sm} {
    width: 53px;
    height: 53px;
  }
`;

const Icon = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;

interface IProps {
  sectionId: number;
  group: IOnlineReportGroup;
  onPrivacyChange(value: boolean, itemId?: number): void;
}

function GroupSection(props: React.PropsWithChildren<IProps>) {
  const {group, children} = props;
  const {name, image} = group;

  return (
    <Root>
      <Header className="d-flex justify-content-between ">
        <div className="d-flex align-items-center">
          {image && (
            <Circle className="position-relative">
              <Icon className="position-absolute" src={image.url} />
            </Circle>
          )}
          <Title>{name}</Title>
        </div>
        {/*TODO not supported by the backend yet*/}
        {/*<SwitchButton*/}
        {/*  itemId={sectionId}*/}
        {/*  checked={!isPrivate}*/}
        {/*  textLeft={switchTitle}*/}
        {/*  onChangeHandler={onPrivacyChange}*/}
        {/*/>*/}
      </Header>
      {children}
    </Root>
  );
}

export default memo(GroupSection);
