import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import {FormatOptionLabelMeta} from 'react-select/dist/declarations/src/Select';
import Highlighter from 'react-highlight-words';

import {ISimpleDictionary} from 'DictionaryFactory/types';
import {IFacetProps} from '../Facet';
import ItemListFacet from '../facetsByType/ItemListFacet';
import {memoWithGeneric} from 'Common/helpers/react/memoWithGeneric';
import {IDictionaryOptionType} from 'Common/models/IDictionaryOptionType';
import {IAvatared} from 'Common/models/IAvatar';
import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {INamedEntity} from 'Common/models/INamedEntity';

const highlightStyle: CSSProperties = {fontWeight: 500, background: 'none', margin: 0, padding: 0};

const AvatarWrapper = styled.div`
  margin-right: 8px;
`;

const optionCustomRenderer = (option: IUserOption, labelMeta: FormatOptionLabelMeta<IUserOption>) => {
  const {avatar, label} = option;
  return (
    <div className="d-flex align-items-center">
      <AvatarWrapper>
        <Avatar
          avatarSize={AvatarSize.Custom}
          customSizeAvatar={28}
          customSizeIcon={16}
          defaultAvatarProfile={DefaultAvatarProfile.User}
          url={avatar?.url}
        />
      </AvatarWrapper>
      <Highlighter searchWords={[labelMeta.inputValue]} textToHighlight={`${label}`} highlightStyle={highlightStyle} />
    </div>
  );
};

export interface IUserOption extends IDictionaryOptionType, IAvatared {}

interface IOwnProps<D> {
  dictionary: D;
}

type AllProps<D> = IOwnProps<D> & IFacetProps;

function UserItemListFacet<T extends INamedEntity, D extends ISimpleDictionary<T>>(props: AllProps<D>) {
  const {...rest} = props;

  return <ItemListFacet<IUserOption> {...rest} formatOptionLabel={optionCustomRenderer} />;
}

export default memoWithGeneric(UserItemListFacet);
