import {combineReducers} from 'redux';

import {IUserToUserHubState} from '../types';
import {communicationUserToUserHubReducer} from './communications';
import {dataUserToUserReducer} from './data';

export const reducer = combineReducers<IUserToUserHubState>({
  communications: communicationUserToUserHubReducer,
  data: dataUserToUserReducer,
});
