import React, {memo, useCallback} from 'react';
import styled from 'styled-components';

import IconButton from 'Common/components/Controls/Buttons/IconButton';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import {IChatUser} from 'MessageCenter/models/IChatUser';

const actionButtonProps = {color: ColorPalette.gray56, fill: true, stroke: false, size: 24};

const ActionButton = styled(IconButton)`
  margin-left: 12px;
`;

interface IActionProps {
  user: IChatUser;
  openChatAction: (user: IChatUser) => void;
}

function AdminSupportActions(props: IActionProps) {
  const {user, openChatAction} = props;

  const handleOpenChatAction = useCallback(() => openChatAction(user), [user, openChatAction]);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <ActionButton name={IconName.Chat} {...actionButtonProps} onClick={handleOpenChatAction} />
    </div>
  );
}

export default memo(AdminSupportActions);
