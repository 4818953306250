import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {IGenotypeGroup} from 'Genotype/models/IGenotypeGroup';
import Typography from 'Common/constants/Typography';
import GenotypeList from 'Genotype/component/GenotypeList';
import {BorderedBlock, PanelLabel} from 'HorseProfile/components/shared/StyledComponents';
import Tooltip from 'Common/components/Tooltip/Tooltip';
import {breakpoints} from 'Common/constants/Breakpoints';
import {sortByStringKey} from 'Common/helpers/sortByStringKey';
import Icon, {IconName} from 'Icon/components/Icon';
import {VerificationStatus} from 'Common/constants/VerificationStatus';
import {GenotypeFontSize, GenotypeFontSizeByScreenSize} from 'Genotype/constants/GenotypeFontSize';
import {GenotypeType} from 'Genotype/constants/GenotypeType';
import {IGenotypeFontSize} from 'Genotype/models/IGenotypeFontSize';
import PlaceholderImage from 'Common/components/Image/Image';

const ETALON_VERIFIED = 'Etalon Tested & Verified';

const Content = styled(BorderedBlock)`
  width: 100%;
  min-height: 88px;
  padding: 6px;
  background-color: ${ColorPalette.white0};

  @media ${breakpoints.sm} {
    padding: 12px;
  }
`;

const LineWrapper = styled.div`
  width: 100%;
  min-height: 40px;
`;

const GensWrapper = styled.div<IGenotypeFontSize>`
  color: ${ColorPalette.black1};
  font-weight: ${Typography.weight.medium500};
  line-height: 50px;
  margin-left: 16px;

  ${({size = GenotypeFontSize.Large}) => {
    return `
    font-size: ${GenotypeFontSizeByScreenSize[size].mobile};

    @media ${breakpoints.sm} {
      font-size: ${GenotypeFontSizeByScreenSize[size].tablet};
      margin-left: 30px;
    }

    @media ${breakpoints.md} {
      font-size: ${GenotypeFontSizeByScreenSize[size].desktop};
      margin-top: 16px;
    }
    `;
  }};
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-left: 18px;

  @media ${breakpoints.sm} {
    margin-left: 20px;
  }
`;

const Image = styled(PlaceholderImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const EmptyContent = styled.div`
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size18};
  color: ${ColorPalette.red2};
`;

const VerificationIcon = styled(Icon)`
  top: -26px;
  right: 0;

  @media ${breakpoints.sm} {
    right: -32px;
  }
`;

interface IProps {
  genotypeGroups: IGenotypeGroup[];
  isAncestryError?: boolean;
}

const AlleleSummary = (props: IProps) => {
  const {genotypeGroups, isAncestryError} = props;

  const hasGenotypes = genotypeGroups && genotypeGroups.some((i) => i.genotypes.length !== 0);
  const isEtalonVerified =
    genotypeGroups?.length > 0 &&
    genotypeGroups?.some((x) => x.genotypes?.some((y) => y.verificationStatus === VerificationStatus.Verified));

  const isAncestryErrorShow = isAncestryError !== undefined && !isAncestryError;

  return (
    <>
      <PanelLabel>Genetic Variants</PanelLabel>
      <Content className="position-relative d-flex flex-column justify-content-center align-items-center">
        {isEtalonVerified && (
          <Tooltip content={ETALON_VERIFIED}>
            <VerificationIcon className="position-absolute" name={IconName.EtalonVerified} size={64} />
          </Tooltip>
        )}
        {hasGenotypes ? (
          genotypeGroups
            .sort((a, b) => sortByStringKey(a, b, 'positionIndex'))
            .map((group, index) => {
              const genotypeSize = group.name === GenotypeType.Color ? GenotypeFontSize.Large : GenotypeFontSize.Medium;
              return (
                <LineWrapper className="d-flex flex-nowrap" key={index}>
                  <Tooltip content={group.name}>
                    <IconContainer className="d-flex align-self-center">
                      {group.image && <Image src={group.image?.url} />}
                    </IconContainer>
                  </Tooltip>
                  <GensWrapper className="d-flex flex-grow-1 flex-wrap" size={genotypeSize}>
                    <GenotypeList genotypes={group.genotypes} />
                  </GensWrapper>
                </LineWrapper>
              );
            })
        ) : (
          <>
            <EmptyContent>No Diagnostics Currently Complete</EmptyContent>
            {isAncestryErrorShow && <EmptyContent>Ancestry Data is Available</EmptyContent>}
          </>
        )}
      </Content>
    </>
  );
};

export default memo(AlleleSummary);
