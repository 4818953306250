import React, {memo, useEffect} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import OrderSuccessLayout from 'Shared/components/OrderSuccess/common/OrderSuccessLayout';
import OrderSuccessButtons from 'Shared/components/OrderSuccess/common/OrderSuccessButtons';
import {Description, Title} from 'Shared/components/OrderSuccess/common/styled';
import TagManager from 'react-gtm-module';
import {useParams} from 'react-router-dom';
import {actions, selectors} from '../../../Payment/store/orderPayment';
import {connect, ConnectedProps} from 'react-redux';
import {IAppState} from '../../../Common/store/IAppState';
import {actions as currentUserActions, selectors as currentUserSelectors} from 'UserProfile/store/currentUser';

const Link = styled.a.attrs({target: '_blank', rel: 'noopener noreferrer'})`
  color: ${ColorPalette.blue0};
`;

type RouteParams = {
  token?: string;
};

type AllProps = ConnectedProps<typeof connector>;

const OrderSuccessPaid = (props: AllProps) => {
  const {orderDetails, getOrderDetailsByToken, currentUser} = props;
  const {token} = useParams<RouteParams>();

  useEffect(() => {
    getOrderDetailsByToken(token as string);

    /* Rewardful Tracker START */
    if ((window as any).rewardful) {
      (window as any).rewardful('convert', {
        email: currentUser?.email,
      });
    }
    /* Rewardful Tracker END */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderSuccessLayout>
      <Title>Order successfully paid</Title>
      <Description>
        <div>
          Ok, time to send in your horse hair sample! Using the sample submission form, please send your pulled hair
          sample in as soon as possible and we’ll get this testing started!
        </div>
        <div>
          Need to see how to do it? Here’s a <Link href="https://www.youtube.com/watch?v=EAqhb8zfk6k">video</Link> to
          help
        </div>
      </Description>

      <OrderSuccessButtons isPayment />
    </OrderSuccessLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  orderDetails: selectors.selectOrderDetails(state),
  currentUser: currentUserSelectors.selectCurrentUser(state),
  currentUserLoading: currentUserSelectors.selectCommunication(state, 'userLoading'),
});

const mapDispatchToProps = {
  getOrderDetailsByToken: actions.getOrderDetailsByToken,
  getCurrentUser: currentUserActions.getCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(OrderSuccessPaid));
export default Connected;
