import React, {memo} from 'react';
import styled from 'styled-components';

import Theme from 'Common/constants/Theme';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';

import GenHelix from './gen-helix.png';
import Image from 'Common/components/Image/Image';

const Root = styled.div`
  position: relative;
  font-family: ${Theme.font.primary};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${Theme.color.black};
`;

const Background = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 80%;
`;

const Description = styled.div`
  margin: auto;
  max-width: 85%;
`;

const Paragraph = styled.div`
  margin-top: 32px;
`;

const MarkedWord = styled.span`
  padding: 10px 16px;
  border-radius: 10px;
  color: ${ColorPalette.red7};
  background: rgba(179, 57, 57, 0.1);
`;

export const BuildHorsePlaceholder = memo(() => {
  return (
    <Root>
      <Background src={GenHelix} />
      <Content>
        <Description>
          <Paragraph>
            To begin, please select your gene pools and click <MarkedWord>Build</MarkedWord> This simple function will
            pair stallions with mares and calculate the probable results!
          </Paragraph>
          <Paragraph>
            To refine your search, select foal or horse attributes (like color, speed or discipline) and the Etalon
            Build-a-Horse platform will select mares and stallions genetically able to produce your dream horse
          </Paragraph>
        </Description>
      </Content>
    </Root>
  );
});

export const FindHorsePlaceholder = memo(() => {
  return (
    <Root>
      <Background src={GenHelix} />
      <Content>
        <Description>
          <Paragraph>
            To begin, you can type horse name or key words in bar above and click <MarkedWord>Search</MarkedWord>
          </Paragraph>
          <Paragraph>
            To refine your search, select horse attributes (like color, speed or discipline) and click{' '}
            <MarkedWord>Filter</MarkedWord> and the Etalon platform will filter horses complied with your request
          </Paragraph>
        </Description>
      </Content>
    </Root>
  );
});
