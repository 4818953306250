import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {IFoal} from 'Common/models/IFoal';
import ProbabilityList from 'BuildHorse/components/PotentialHorse/ProbabilityList';
import usePermissions from 'Permissions/hooks/usePermissions';
import {Permission} from 'Permissions/constants/Permission';
import {breakpoints} from 'Common/constants/Breakpoints';
import LinkButton from 'Common/components/Controls/Buttons/LinkButton';
import {IProbabilityEntity} from 'Common/models/IProbabilityEntity';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';

const MAX_LIST_ITEMS = 2;

function getMaxItemsCount(itemsCount: number) {
  return itemsCount > MAX_LIST_ITEMS + 1 ? MAX_LIST_ITEMS : itemsCount;
}

function filterProbabilities(items: IProbabilityEntity[]) {
  return items.filter((x) => x.isFullInfo);
}

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  min-width: 0;

  @media ${breakpoints.md} {
    padding: 0 16px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 48% 48%;
    grid-gap: 8px 16px;
  }
`;

const ProbabilityListContainer = styled.div`
  :not(:first-child) {
    margin-top: 16px;
  }

  @media ${breakpoints.md} {
    margin: 0 !important;
  }
`;

const MoreDetailsButton = styled(LinkButton)`
  width: 176px;
  align-self: center;
  margin-top: 16px;

  @media ${breakpoints.md} {
    position: absolute;
    right: 0;
    bottom: 20px;
    width: auto;
  }

  @media (min-width: 1220px) {
    width: 176px;
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 16px;
`;

const PlaceholderContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  font-size: ${Typography.size.size16};
  font-weight: ${Typography.weight.medium500};
  line-height: 24px;
  color: ${ColorPalette.gray13};
  text-align: center;
`;

const PlaceholderButton = styled(LinkButton)`
  width: 176px;
  margin-top: 16px;
  align-self: center;
`;

interface IProps {
  foal: IFoal;
}

type AllProps = IProps;

const FoalCard = (props: AllProps) => {
  const {foal} = props;
  const {abilities, coat, healthIssues, dangers} = foal;
  const {hasPermission} = usePermissions();
  const {currentVisitorType} = useVisitorTypeService();

  const filteredAbilities = filterProbabilities(abilities);
  const filteredCoat = filterProbabilities(coat);
  const filteredHealthIssues = filterProbabilities(healthIssues);
  const filteredDangers = filterProbabilities(dangers);

  const moreDetailsButton = hasPermission(Permission.BuildFoal) && (
    <MoreDetailsButton
      to={`${PREFIX_VISITOR_TYPE[currentVisitorType]}/foal/${foal.parents[0]?.id}/${foal.parents[1]?.id}`}
      size="small"
    >
      More details
    </MoreDetailsButton>
  );

  const cardPlaceholder = (
    <Placeholder>
      <PlaceholderContent className="align-self-center">
        {hasPermission(Permission.BuildFoal) ? (
          <>
            <div>
              <div>You didn’t choose any priorities,</div>
              <div>you can review all variants</div>
              <div>by clicking on button below</div>
            </div>
            <PlaceholderButton
              to={`${PREFIX_VISITOR_TYPE[currentVisitorType]}/foal/${foal.parents[0]?.id}/${foal.parents[1]?.id}`}
              size="small"
            >
              More details
            </PlaceholderButton>
          </>
        ) : (
          <div>You didn’t choose any priorities</div>
        )}
      </PlaceholderContent>
    </Placeholder>
  );

  const hasAbilities = filteredAbilities.length > 0;
  const hasIssues = filteredHealthIssues.length > 0 || filteredDangers.length > 0;
  const hasCoat = filteredCoat.length > 0;
  const hasSomething = hasAbilities || hasIssues || hasCoat;

  const issues = [...filteredHealthIssues, ...filteredDangers];

  if (!hasSomething) {
    return cardPlaceholder;
  }

  return (
    <Root>
      {hasAbilities && (
        <ProbabilityListContainer>
          <ProbabilityList
            title="foal features"
            maxLength={getMaxItemsCount(abilities.length)}
            anotherVariationText="More abilities"
            probabilities={filteredAbilities}
            higherIsBetter={true}
          />
        </ProbabilityListContainer>
      )}
      {hasIssues && (
        <ProbabilityListContainer>
          <ProbabilityList
            title="health"
            maxLength={getMaxItemsCount(issues.length)}
            anotherVariationText="More issues"
            probabilities={issues}
            higherIsBetter={false}
          />
        </ProbabilityListContainer>
      )}
      {hasCoat && (
        <ProbabilityListContainer>
          <ProbabilityList
            title="coat"
            maxLength={getMaxItemsCount(coat.length)}
            anotherVariationText="More coat"
            probabilities={filteredCoat}
            higherIsBetter={true}
          />
        </ProbabilityListContainer>
      )}
      {moreDetailsButton}
    </Root>
  );
};

export default memo(FoalCard);
