import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

import {
  setConnectedUserActions,
  setConnectedUserAtionTypes,
  setDisconnectedUserActions,
  setDisconnectedUserActionTypes,
  setSentUserToAdminMessageActions,
  setSentUserToAdminMessageActionTypes,
  sendUserToAdminMessageActions,
  sendUserToAdminMessageActionTypes,
  resetUserToAdminSendingMessageActionTypes,
  deleteMessageQueueActions,
  deleteMessageQueueActionTypes,
  IDeleteProps,
  setConnectedAdminActions,
  setConnectedAdminAtionTypes,
  setDisconnectedAdminActions,
  setDisconnectedAdminActionTypes,
  receiveUserToAdminMessageActionTypes,
  receiveUserToAdminMessageActions,
  receiveAdminToUserMessageActionTypes,
  receiveAdminToUserMessageActions,
  setSentAdminToUserMessageActions,
  setSentAdminToUserMessageActionTypes,
  sendAdminToUserMessageActions,
  sendAdminToUserMessageActionTypes,
  resetAdminToUserSendingMessageActionTypes,
  sendUserToAdminMessageReadRequestActions,
  sendUserToAdminMessageReadRequestActionTypes,
  resetUserToAdminMessageReadRequestActionTypes,
  resetAdminToUserMessageReadRequestActionTypes,
  sendAdminToUserMessageReadRequestActionTypes,
  sendAdminToUserMessageReadRequestActions,
  resetUserToAdminMessageReadActionTypes,
  resetAdminToUserMessageReadActionTypes,
  getUserToAdminMessageReadActions,
  getUserToAdminMessageReadActionTypes,
  getAdminToUserMessageReadActionTypes,
  getAdminToUserMessageReadActions,
  setConnectionIdActions,
  setConnectionIdActionTypes,
} from './types';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {IUserToAdminMessageRequest} from 'SignalR/models/IUserToAdminMessageRequest';
import {IAdminToUserMessageRequest} from 'SignalR/models/IAdminToUserMessageRequest';
import {IAdminToUserMessageReadRequest} from 'SignalR/models/IAdminToUserMessageReadRequest';
import {IUserToAdminMessageReadRequest} from 'SignalR/models/IUserToAdminMessageReadRequest';
import {IUserToAdminMessageRead} from 'SignalR/models/IUserToAdminMessageRead';
import {IAdminToUserMessageRead} from 'SignalR/models/IAdminToUserMessageRead';

export const setConnectionId =
  (connectionId: string): ActionResult<IAppState, void, setConnectionIdActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setConnectionIdActionTypes.REQUEST));
      dispatch(action(setConnectionIdActionTypes.SUCCESS, connectionId));
    } catch (error) {
      dispatch(action(setConnectionIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setConnectedUser =
  (userId: number): ActionResult<IAppState, void, setConnectedUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setConnectedUserAtionTypes.REQUEST));
      dispatch(action(setConnectedUserAtionTypes.SUCCESS, userId));
    } catch (error) {
      dispatch(action(setConnectedUserAtionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setDisconnectedUser =
  (userId: number): ActionResult<IAppState, void, setDisconnectedUserActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setDisconnectedUserActionTypes.REQUEST));
      dispatch(action(setDisconnectedUserActionTypes.SUCCESS, userId));
    } catch (error) {
      dispatch(action(setDisconnectedUserActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setConnectedAdmin = (): ActionResult<IAppState, void, setConnectedAdminActions> => async (dispatch) => {
  try {
    dispatch(action(setConnectedAdminAtionTypes.REQUEST));
    dispatch(action(setConnectedAdminAtionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(setConnectedAdminAtionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const setDisconnectedAdmin =
  (): ActionResult<IAppState, void, setDisconnectedAdminActions> => async (dispatch) => {
    try {
      dispatch(action(setDisconnectedAdminActionTypes.REQUEST));
      dispatch(action(setDisconnectedAdminActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(setDisconnectedAdminActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setReceiveUserToAdminMessage =
  (params: IMessageQueue): ActionResult<IAppState, void, receiveUserToAdminMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(receiveUserToAdminMessageActionTypes.REQUEST));
      dispatch(action(receiveUserToAdminMessageActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(receiveUserToAdminMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setReceiveAdminToUserMessage =
  (params: IMessageQueue): ActionResult<IAppState, void, receiveAdminToUserMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(receiveAdminToUserMessageActionTypes.REQUEST));
      dispatch(action(receiveAdminToUserMessageActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(receiveAdminToUserMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setSentUserToAdminMessage =
  (params: IMessageQueue): ActionResult<IAppState, void, setSentUserToAdminMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setSentUserToAdminMessageActionTypes.REQUEST));
      dispatch(action(setSentUserToAdminMessageActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(setSentUserToAdminMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const setSentAdminToUserMessage =
  (params: IMessageQueue): ActionResult<IAppState, void, setSentAdminToUserMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(setSentAdminToUserMessageActionTypes.REQUEST));
      dispatch(action(setSentAdminToUserMessageActionTypes.SUCCESS, params));
    } catch (error) {
      dispatch(action(setSentAdminToUserMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const sendUserToAdminMessage =
  (sendMessage: IUserToAdminMessageRequest): ActionResult<IAppState, void, sendUserToAdminMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(sendUserToAdminMessageActionTypes.REQUEST));
      dispatch(action(sendUserToAdminMessageActionTypes.SUCCESS, sendMessage));
    } catch (error) {
      dispatch(action(sendUserToAdminMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const sendAdminToUserMessage =
  (sendMessage: IAdminToUserMessageRequest): ActionResult<IAppState, void, sendAdminToUserMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(sendAdminToUserMessageActionTypes.REQUEST));
      dispatch(action(sendAdminToUserMessageActionTypes.SUCCESS, sendMessage));
    } catch (error) {
      dispatch(action(sendAdminToUserMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteMessagesFromQueue =
  (deleteMessage: IDeleteProps): ActionResult<IAppState, void, deleteMessageQueueActions> =>
  async (dispatch) => {
    try {
      dispatch(action(deleteMessageQueueActionTypes.REQUEST));
      dispatch(action(deleteMessageQueueActionTypes.SUCCESS, deleteMessage));
    } catch (error) {
      dispatch(action(deleteMessageQueueActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const sendUserToAdminMessageReadRequest =
  (
    sendMessageRead: IUserToAdminMessageReadRequest
  ): ActionResult<IAppState, void, sendUserToAdminMessageReadRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(sendUserToAdminMessageReadRequestActionTypes.REQUEST));
      dispatch(action(sendUserToAdminMessageReadRequestActionTypes.SUCCESS, sendMessageRead));
    } catch (error) {
      dispatch(action(sendUserToAdminMessageReadRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const sendAdminToUserMessageReadRequest =
  (
    sendMessageRead: IAdminToUserMessageReadRequest
  ): ActionResult<IAppState, void, sendAdminToUserMessageReadRequestActions> =>
  async (dispatch) => {
    try {
      dispatch(action(sendAdminToUserMessageReadRequestActionTypes.REQUEST));
      dispatch(action(sendAdminToUserMessageReadRequestActionTypes.SUCCESS, sendMessageRead));
    } catch (error) {
      dispatch(action(sendAdminToUserMessageReadRequestActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminMessageRead =
  (messageRead: IUserToAdminMessageRead): ActionResult<IAppState, void, getUserToAdminMessageReadActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUserToAdminMessageReadActionTypes.REQUEST));
      dispatch(action(getUserToAdminMessageReadActionTypes.SUCCESS, messageRead));
    } catch (error) {
      dispatch(action(getUserToAdminMessageReadActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getAdminToUserMessageRead =
  (messageRead: IAdminToUserMessageRead): ActionResult<IAppState, void, getAdminToUserMessageReadActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getAdminToUserMessageReadActionTypes.REQUEST));
      dispatch(action(getAdminToUserMessageReadActionTypes.SUCCESS, messageRead));
    } catch (error) {
      dispatch(action(getAdminToUserMessageReadActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetUserToAdminSendingMessage = () => action(resetUserToAdminSendingMessageActionTypes.RESET);

export const resetAdminToUserSendingMessage = () => {
  return action(resetAdminToUserSendingMessageActionTypes.RESET);
};

export const resetUserToAdminMessageReadRequest = () => {
  return action(resetUserToAdminMessageReadRequestActionTypes.RESET);
};

export const resetAdminToUserMessageReadRequest = () => {
  return action(resetAdminToUserMessageReadRequestActionTypes.RESET);
};

export const resetUserToAdminMessageRead = () => {
  return action(resetUserToAdminMessageReadActionTypes.RESET);
};

export const resetAdminToUserMessageRead = () => {
  return action(resetAdminToUserMessageReadActionTypes.RESET);
};
