import {action} from 'typesafe-actions';

import TestResultsService from 'Admin/AdminDashboard/services/TestResultsService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {uploadTestResultsActions, uploadTestResultsActionTypes} from './types';

export const uploadTestResults =
  (file: File): ActionResult<IAppState, void, uploadTestResultsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadTestResultsActionTypes.REQUEST));
      await TestResultsService.uploadTestResults(file);
      dispatch(action(uploadTestResultsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadTestResultsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
