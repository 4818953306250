import React, {memo, useCallback, useState} from 'react';
import {IUser} from 'UserProfile/models/IUser';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import ContactInfo from 'Shared/components/UserProfile/parts/ProfileInfo/parts/ContactSection/ContactInfo';
import styled from 'styled-components';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ModalWindow from 'Common/components/Modal/ModalWindow';
// import ContactRequestForm from 'Shared/components/UserProfile/parts/ProfileInfo/ContactRequest/ContactRequestForm';
import ContactChatRequestForm from '../../ContactChatRequest/ContactChatRequestForm';
import ColoredIcon from 'Icon/components/ColoredIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';

const RequestContactWrapper = styled.div`
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media ${breakpoints.sm} {
    text-align: start;
    align-items: start;
  }

  @media ${breakpoints.lg} {
    text-align: center;
    align-items: center;
  }
`;

const ProfileHiddenMessage = styled.div`
  width: 240px;
  padding: 16px;
  margin: 16px 0;
  border-radius: 4px;
  background-color: ${ColorPalette.gray49};

  @media ${breakpoints.md} {
    width: 100%;
  }
`;

const Label = styled.div`
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size18};
  color: ${ColorPalette.black1};
`;

const Button = styled(PrimaryButton)`
  width: 240px;
`;

interface IProps {
  user: IUser;
}

const ContactSection = (props: IProps) => {
  const {email, phone, userAddress} = props.user;
  const {isMobile} = useMediaQuery();

  const [isContactRequestModalOpen, setIsContactRequestModalOpen] = useState(false);
  const openContactRequestModal = useCallback(() => {
    setIsContactRequestModalOpen(true);
  }, []);
  const closeContactRequestModal = useCallback(() => {
    setIsContactRequestModalOpen(false);
  }, []);

  if (email || phone || userAddress) {
    return <ContactInfo user={props.user} />;
  }

  return (
    <>
      <ModalWindow isOpen={isContactRequestModalOpen} onClose={closeContactRequestModal}>
        {/* <ContactRequestForm targetUserId={props.user.id} onSuccess={closeContactRequestModal} /> */}
        <ContactChatRequestForm targetUserId={props.user.id} onSuccess={closeContactRequestModal} />
      </ModalWindow>

      <RequestContactWrapper className="d-flex">
        <ProfileHiddenMessage className="d-flex flex-column align-items-center">
          <ColoredIcon name={IconName.EyeHide} stroke={false} color={ColorPalette.red0} size={24} />
          <Label>User profile hidden</Label>
        </ProfileHiddenMessage>
        <Button size={isMobile ? 'small' : 'medium'} onClick={openContactRequestModal}>
          Request contact
        </Button>
      </RequestContactWrapper>
    </>
  );
};

export default memo(ContactSection);
