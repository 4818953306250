import {Form, FormikProps, withFormik} from 'formik';
import {memo, useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {TextAreaField} from 'Common/components/FormFields/index';
import {ModalWindowFormContent} from 'Common/components/Modal/shared/index';
import {ModalWindowButton, ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared/StyledComponents';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IChatRequest} from 'UserProfile/models/IChatRequest';
import {ContactRequestModule} from 'UserProfile/store/contactRequest/contactRequestModule';
import {actions, selectors} from 'UserProfile/store/contactRequest/index';
import {initialValue, validationSchema} from './validation';

const Hint = styled.div`
  margin-bottom: 32px;
  align-items: center;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.black0};
`;

interface IExternalProps {
  targetUserId: number;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IChatRequest> & OuterProps;

const ContactChatRequestForm = (props: AllProps) => {
  const {onSuccess, usersChatRequestCreating} = props;
  const {isSubmitting, isValid, status, setErrors} = props;

  const errorInfo = usersChatRequestCreating.error;
  const isLoading = usersChatRequestCreating.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(usersChatRequestCreating, onSuccess);
  useOnErrorCommunication(usersChatRequestCreating, onError);
  useCommunicationToToast(usersChatRequestCreating, 'Your message was successfully sent to the user');

  return (
    <>
      <ModalWindowHeader>Contact information request</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {(isSubmitting || isLoading) && <Loading />}
          <Hint>
            This user's contact information is private. You can submit this request with your information and describe
            in detail your interest and/or purpose using the messaging app. They can then respond so you can connect and
            talk about horses!
          </Hint>
          <TextAreaField name="message" label="Message" />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={isLoading || !isValid} isLoading={isSubmitting}>
            Send request
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const ContactChatRequestFormFormik = withFormik<OuterProps, IChatRequest>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.createUsersChatRequest({targetId: formikBag.props.targetUserId, message: values.message});
  },
  enableReinitialize: true,
})(ContactChatRequestForm);

const mapStateToProps = (state: IAppState) => ({
  usersChatRequestCreating: selectors.selectCommunication(state, 'usersChatRequestCreating'),
});

const mapDispatchToProps = {
  createUsersChatRequest: actions.createUsersChatRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ContactChatRequestFormFormik));

export default withDynamicModules(Connected, ContactRequestModule);
