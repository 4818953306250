import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
} from 'Common/store/utils/communication/index';

const STATE_NAME = 'contactRequest';

export interface IContactRequestState {
  communications: {
    sendContactRequesting: ICommunication;
    usersChatRequestCreating: ICommunication;
  };
}

export const contactRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'CONTACT_REQUEST');
export type contactRequestActions = MakeCommunicationActions<typeof contactRequestActionTypes, {}>;

export const createUsersChatsRequestActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_USERS_CHAT_REQUEST');
export type createUsersChatRequestActions = MakeCommunicationActions<typeof createUsersChatsRequestActionTypes, {}>;

export type ContactRequestsActions = contactRequestActions | createUsersChatRequestActions;
