import React, {memo, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {actions, selectors} from 'MailConfirmation/store/requestNewMailConfirmation';

import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {RequestNewMailConfirmationModule} from 'MailConfirmation/store/requestNewMailConfirmation/RequestNewMailConfirmationModule';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import Icon, {IconName} from 'Icon/components/Icon';
import Loading from 'Loading/components/Loading';
import useServiceMode from 'Maintain/hooks/useServiceMode';

const UNKNOWN_ERROR = 'Unknown error on resending verification email';

const Content = styled.div`
  max-width: 500px;
  color: ${ColorPalette.black0};
  font-family: ${Theme.font.primary};
`;

const Title = styled.div`
  margin-top: 24px;
  font-size: ${Typography.size.size24};
  font-weight: ${Typography.weight.semiBold600};
  font-family: ${Theme.font.secondary};
`;

const Body = styled.div`
  margin-top: 24px;
  text-align: center;
  line-height: 32px;
`;

const SuccessMessage = styled.div`
  margin-top: 48px;
`;

const Actions = styled.div`
  margin-top: 32px;
  width: 232px;

  > :not(:first-child) {
    margin-top: 16px;
  }
`;

const Error = styled(ErrorMessage)`
  margin-top: 16px;
`;

type IConnected = ConnectedProps<typeof connector>;

type RouteParams = {
  email: string;
};

type PageType = 'notConfirmed' | 'expiredLink';

interface IOwnProps {
  type: PageType;
}

type Props = IConnected & IOwnProps;

function MailNotConfirmed(props: Props) {
  const {type, requestNewConfirmation, confirmationRequesting} = props;
  const {email} = useParams<RouteParams>() as RouteParams;

  const navigate = useNavigate();

  const [isEmailSended, setIsEmailSended] = useState(false);

  const {getServiceModeStatus, isServiceModeLoading} = useServiceMode();

  useEffect(() => {
    getServiceModeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const error = confirmationRequesting.error;

  const title: Record<PageType, string> = {
    notConfirmed: 'Email not verified!',
    expiredLink: 'This link has expired!',
  };

  const body: Record<PageType, JSX.Element> = {
    notConfirmed: (
      <div>
        We were unable to verify your email. Please check your inbox on <b>{email}</b> and follow the link inside to
        verify it. If you didn’t receive an email click on the button bellow.
      </div>
    ),
    expiredLink: (
      <div>
        Verification link for <b>{email}</b> no longer works. To receive new verification email click on the button
        below
      </div>
    ),
  };

  const onRequestNewConfirmation = useCallback(() => {
    requestNewConfirmation(email);
  }, [email, requestNewConfirmation]);

  useOnSuccessCommunication(confirmationRequesting, () => {
    setIsEmailSended(true);
  });

  const redirectToLoginPage = useCallback(() => navigate('/login'), [navigate]);

  return (
    <div className="d-flex w-100 h-100vh align-items-center justify-content-center position-relative">
      {isServiceModeLoading && <Loading />}
      <Content className="d-flex h-100 flex-column align-items-center justify-content-center">
        <Icon name={IconName.OpenEnvelope} size={104} />
        <Title>{title[type]}</Title>
        <Body>{body[type]}</Body>
        {isEmailSended && <SuccessMessage>New verification email has been sent to your inbox.</SuccessMessage>}
        <Actions>
          {!isEmailSended && (
            <PrimaryButton
              className="w-100"
              onClick={onRequestNewConfirmation}
              isLoading={confirmationRequesting.isRequesting}
            >
              Resend verification email
            </PrimaryButton>
          )}
          <PrimaryButton className="w-100" onClick={redirectToLoginPage} variant="outlined">
            Return to sign in
          </PrimaryButton>
        </Actions>
        {error && <Error>{getCommonErrors(error) || UNKNOWN_ERROR}</Error>}
      </Content>
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  confirmationRequesting: selectors.selectCommunication(state, 'confirmationRequesting'),
});

const mapDispatchToProps = {
  requestNewConfirmation: actions.requestNewMailConfirmation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(memo(MailNotConfirmed)), RequestNewMailConfirmationModule);
