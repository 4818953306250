import {Form, FormikProps, withFormik} from 'formik';
import {memo, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {ModalWindowFormContent} from 'Common/components/Modal/shared/index';
import {ModalWindowButton, ModalWindowFooter, ModalWindowHeader} from 'Common/components/Modal/shared/StyledComponents';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {getErrorCode, getFieldErrors} from 'Common/helpers/ErrorHelper';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {actions, selectors} from 'OnlineReport/store/ancestry';
import {IFormValues, initialValue, validationSchema} from './validation';
import {TextAreaField} from 'Common/components/FormFields';

const ALREADY_SENT_ERROR_CODE = 'NotificationAlreadySent';
const ALREADY_SENT_MESSAGE = 'Contact request already sent. Please wait replying on your invitation.';

const Hint = styled.div`
  margin-bottom: 32px;
  align-items: center;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  color: ${ColorPalette.black0};
`;

interface IExternalProps {
  animalId: string;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const ContactRequestForm = (props: AllProps) => {
  const {sendHorsePublicityRequesting, animalId, onSuccess} = props;
  const {isSubmitting, isValid, status, setFieldValue, setErrors} = props;

  const errorInfo = sendHorsePublicityRequesting.error;
  const errorCode = errorInfo ? getErrorCode(errorInfo) : '';
  if (errorCode === ALREADY_SENT_ERROR_CODE) {
  }
  const isLoading = sendHorsePublicityRequesting.isRequesting;

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(sendHorsePublicityRequesting, onSuccess);
  useOnErrorCommunication(sendHorsePublicityRequesting, onError);
  useCommunicationToToast(
    sendHorsePublicityRequesting,
    'Your message was successfully sent to the user',
    errorCode === ALREADY_SENT_ERROR_CODE ? ALREADY_SENT_MESSAGE : ''
  );

  useEffect(() => {
    if (animalId) {
      setFieldValue('animalId', animalId);
    }
  }, [animalId, setFieldValue]);

  return (
    <>
      <ModalWindowHeader>Contact information request</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {(isSubmitting || isLoading) && <Loading />}
          <Hint>
            This user's horse information is private. You can submit a request to view the information along with a way
            to contact you (optional). They can then respond so you can connect and further explore your herds!
          </Hint>
          <TextAreaField name="message" label="Message" isRequired />
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" disabled={isLoading || !isValid} isLoading={isSubmitting}>
            Send request
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const ContactRequestFormFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.sendContactRequest(values.animalId, values.message);
  },
  enableReinitialize: true,
})(ContactRequestForm);

const mapStateToProps = (state: IAppState) => ({
  sendHorsePublicityRequesting: selectors.selectCommunication(state, 'sendHorsePublicityRequesting'),
});

const mapDispatchToProps = {
  sendContactRequest: actions.sendHorsePublicityRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(memo(ContactRequestFormFormik));
