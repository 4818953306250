import {
  actions as adminActions,
  selectors as adminSelectors,
} from 'Admin/AdminDashboard/store/adminOnlineReport/shortTandemRepeat';
import {IDistributor} from 'Common/helpers/strategy/IDistributor';
import {IAppState} from 'Common/store/IAppState';
import {ICommunication} from 'Common/store/utils/communication';
import {IShortTandemRepeatReport} from 'OnlineReport/models/STR/IShortTandemRepeatReport';
import {actions as userActions, selectors as userSelectors} from 'OnlineReport/store/shortTandemRepeat';
import {OnlineReportType} from '../shared/OnlineReportType';

export interface IState {
  shortTandemRepeatReport: IShortTandemRepeatReport;
  shortTandemRepeatReportLoading: ICommunication;
}

export interface IDispatch {
  getShortTandemRepeatReport(horseId: number): void;
}

const userDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    shortTandemRepeatReport: userSelectors.selectShortTandemRepeatReport(state),
    shortTandemRepeatReportLoading: userSelectors.selectCommunication(state, 'shortTandemRepeatReportLoading'),
  }),
  dispatch: {
    getShortTandemRepeatReport: userActions.getShortTandemRepeatReport,
  },
  // TODO: add modules here
  // modules: OnlineReportModule,
};

const adminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    shortTandemRepeatReport: adminSelectors.selectShortTandemRepeatReport(state),
    shortTandemRepeatReportLoading: adminSelectors.selectCommunication(state, 'shortTandemRepeatReportLoading'),
  }),
  dispatch: {
    getShortTandemRepeatReport: adminActions.getShortTandemRepeatReport,
  },
  // TODO: add modules here
  // modules: AdminOnlineReportModule,
};

const reviewAdminDistributor: IDistributor<IState, IDispatch> = {
  state: (state: IAppState) => ({
    shortTandemRepeatReport: adminSelectors.selectShortTandemRepeatReport(state),
    shortTandemRepeatReportLoading: adminSelectors.selectCommunication(state, 'shortTandemRepeatReportReviewLoading'),
  }),
  dispatch: {
    getShortTandemRepeatReport: adminActions.getShortTandemRepeatReportReview,
  },
  // TODO: add modules here
  // modules: AdminOnlineReportModule,
};

export const shortTandemRepeatReportDistributor: Record<OnlineReportType, IDistributor<IState, IDispatch>> = {
  [OnlineReportType.User]: userDistributor,
  [OnlineReportType.Association]: userDistributor,
  [OnlineReportType.Admin]: adminDistributor,
  [OnlineReportType.AdminAssociation]: adminDistributor,
  [OnlineReportType.ReviewAdmin]: reviewAdminDistributor,
  [OnlineReportType.ReviewAdminAssociation]: reviewAdminDistributor,
};
