import {action} from 'typesafe-actions';
import {IAppState} from 'Common/store/IAppState';

import {ActionResult} from 'Common/store/store';
import {
  changeCouponStatusActions,
  changeCouponStatusActionTypes,
  createCouponActions,
  createCouponActionTypes,
  deleteCouponActions,
  deleteCouponActionTypes,
  generateNewCodeActions,
  generateNewCodeActionTypes,
  getCouponByIdActions,
  getCouponByIdActionTypes,
  getCouponsActions,
  getCouponsActionTypes,
  resetCouponDetailsActions,
  resetCouponDetailsActionTypes,
  updateCouponActions,
  updateCouponActionTypes,
} from 'Admin/AdminDashboard/store/adminCoupons/types';
import {IRequestParams} from 'Common/models/IRequestParams';
import CouponDataService from 'Admin/AdminDashboard/services/CouponDataService';
import {ICouponRequest} from 'Admin/AdminDashboard/components/Coupons/CouponForm/validation';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getCoupons =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getCouponsActions> =>
  async (dispatch) => {
    dispatch(action(getCouponsActionTypes.REQUEST));
    try {
      const res = await CouponDataService.getCoupons(params);
      dispatch(action(getCouponsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getCouponsActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };

export const getCouponById =
  (couponId: number): ActionResult<IAppState, void, getCouponByIdActions> =>
  async (dispatch) => {
    dispatch(action(getCouponByIdActionTypes.REQUEST));
    try {
      const result = await CouponDataService.getCoupon(couponId);
      console.log(`Received data for ${couponId}`);
      console.log(result);
      dispatch(action(getCouponByIdActionTypes.SUCCESS, result));
    } catch (error) {
      dispatch(action(getCouponByIdActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const createCoupon =
  (params: ICouponRequest): ActionResult<IAppState, void, createCouponActions> =>
  async (dispatch) => {
    try {
      dispatch(action(createCouponActionTypes.REQUEST));
      console.log(`Created`);
      console.log(params);
      await CouponDataService.createCoupon(params);
      dispatch(action(createCouponActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(createCouponActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const updateCoupon =
  (params: ICouponRequest): ActionResult<IAppState, void, updateCouponActions> =>
  async (dispatch) => {
    try {
      console.log(`Update params`);
      console.log(params);
      dispatch(action(updateCouponActionTypes.REQUEST));
      await CouponDataService.updateCoupon(params);
      dispatch(action(updateCouponActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(updateCouponActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const deleteCoupon =
  (id: number): ActionResult<IAppState, void, deleteCouponActions> =>
  async (dispatch) => {
    dispatch(action(deleteCouponActionTypes.REQUEST));
    try {
      await CouponDataService.deleteCoupon(id);
      dispatch(action(deleteCouponActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(deleteCouponActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const changeCouponStatus =
  (couponId: number, isEnabled: boolean): ActionResult<IAppState, void, changeCouponStatusActions> =>
  async (dispatch) => {
    dispatch(action(changeCouponStatusActionTypes.REQUEST));
    try {
      await CouponDataService.changeCouponStatus(couponId, isEnabled);
      dispatch(action(changeCouponStatusActionTypes.SUCCESS, {couponId}));
    } catch (error) {
      dispatch(action(changeCouponStatusActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const generateNewCode =
  (): ActionResult<IAppState, Promise<string>, generateNewCodeActions> => async (dispatch) => {
    dispatch(action(generateNewCodeActionTypes.REQUEST));
    try {
      const res = await CouponDataService.generateNewCode();
      dispatch(action(generateNewCodeActionTypes.SUCCESS, res));
      return res;
    } catch (error) {
      const err = getErrorMessage(error);
      dispatch(action(generateNewCodeActionTypes.FAILURE, err));
      throw err;
    }
  };

export const resetCouponDetailResults =
  (): ActionResult<IAppState, void, resetCouponDetailsActions> => async (dispatch) => {
    dispatch(action(resetCouponDetailsActionTypes.RESET));
  };
