import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import Icon, {IconName} from 'Icon/components/Icon';
import {FilterText} from 'Filters/components/HorseFilters/parts/shared';
import ButtonAsLink from 'Common/components/Controls/Buttons/ButtonAsLink';
import {breakpoints} from 'Common/constants/Breakpoints';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import IconButton from 'Common/components/Controls/Buttons/IconButton';

const Root = styled.div`
  padding: 16px;
  width: 100%;
  max-height: 212px;
  box-shadow: 0px 0px 8px ${hexToRgba(ColorPalette.black0, 0.04)}, 0px 6px 16px ${hexToRgba(ColorPalette.black0, 0.06)};
  border-radius: 8px;
  margin-bottom: 16px;
  flex-direction: column;

  @media ${breakpoints.md} {
    padding: 16px 8px 16px 24px;
    flex-direction: row;
    height: 56px;
  }
`;

const Text = styled(FilterText)`
  margin-left: 16px;

  @media ${breakpoints.md} {
    margin-left: 24px;
  }
`;

const ButtonWrapper = styled.div`
  border-left: 1px solid ${ColorPalette.white9};
  height: 40px;
  padding: 0 16px;
`;

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
`;

const DesktopButtons = styled.div`
  display: none;

  @media ${breakpoints.md} {
    display: flex;
  }
`;

const MobileButtons = styled.div`
  display: flex;
  width: 100%;

  @media ${breakpoints.md} {
    display: none;
  }
`;

const ExpandableZone = styled.div<{$isShow: boolean}>`
  width: 100%;
  ${(props) =>
    props.$isShow
      ? `max-height: 4000px; transition: opacity 0.2s 0.05s, max-height 0.2s;`
      : `max-height: 0; overflow: hidden; opacity: 0; transition: opacity 0.4s, max-height 0.2s; `}
`;

const ExpandButton = styled(IconButton)<{$isShow: boolean}>`
  margin-top: 16px;
  ${(props) => props.$isShow && 'transform: rotate(180deg);'}
`;

const Button = styled(PrimaryButton)`
  width: 100%;
  margin-top: 16px;

  @media ${breakpoints.sm} {
    width: 295px;
  }
`;

interface IProps {
  onOpenExcludeModal(): void;
  onShow(): void;
}

function ExcludedPanel(props: IProps) {
  const {onOpenExcludeModal, onShow} = props;

  const [isShowButtons, setIsShowButtons] = useState(false);

  const toggleShowButtons = useCallback(() => {
    setIsShowButtons(!isShowButtons);
  }, [isShowButtons]);

  return (
    <Root className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <IconWrapper>
          <Icon name={IconName.ExcludedFilter} size={24} />
        </IconWrapper>
        <Text>Some of the results were hidden because they contained excluded horses</Text>
      </div>
      <DesktopButtons className="align-items-center">
        <ButtonWrapper className="d-flex align-items-center">
          <ButtonAsLink onClick={onOpenExcludeModal}>Exclusions list</ButtonAsLink>
        </ButtonWrapper>
        <ButtonWrapper className="d-flex align-items-center">
          <ButtonAsLink onClick={onShow}>Show anyway</ButtonAsLink>
        </ButtonWrapper>
      </DesktopButtons>

      <MobileButtons className="flex-column align-items-center">
        <ExpandableZone className="d-flex flex-column align-items-center" $isShow={isShowButtons}>
          <Button variant="outlined" size="small" onClick={onOpenExcludeModal}>
            Edit exclusions
          </Button>
          <Button size="small" onClick={onShow}>
            Show anyway
          </Button>
        </ExpandableZone>
        <ExpandButton
          name={IconName.ArrowDown}
          fill={false}
          stroke={false}
          size={16}
          onClick={toggleShowButtons}
          $isShow={isShowButtons}
        />
      </MobileButtons>
    </Root>
  );
}

export default memo(ExcludedPanel);
