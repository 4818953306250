import {IDog} from 'Dogs/models/IDog';
import {withAvatar} from 'Common/helpers/withAvatar';
import {withUserName} from 'Common/helpers/withUserName';

export function convertDogToClient(dog: IDog): IDog {
  return {
    ...dog,
    owner: {...withUserName(withAvatar(dog.owner))},
  };
}
