import {IModule} from 'redux-dynamic-modules';

import {shortTandemRepeatReportReducer} from './reducer';
import {IShortTandemRepeatReportState} from './types';

export interface IShortTandemRepeatReportModuleState {
  shortTandemRepeatReport: IShortTandemRepeatReportState;
}

export const ShortTandemRepeatReportModule: IModule<IShortTandemRepeatReportModuleState> = {
  id: 'shortTandemRepeatReport',
  reducerMap: {
    shortTandemRepeatReport: shortTandemRepeatReportReducer,
  },
};
