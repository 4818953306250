import React from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import FunFacts1 from './common/img/fun-facts1.png';
import FunFacts2 from './common/img/fun-facts2.png';
import {breakpoints} from 'Common/constants/Breakpoints';
import {MutableFieldsGroupBase} from 'Common/components/StyledComponents/StyledComponents';
import {AncestryReportSection} from '../parts/common/styled';
import PlceholderImage from 'Common/components/Image/Image';

export const AncestryReportSectionWrapper = styled(AncestryReportSection)`
  max-width: 1024px;
`;

export const Section = styled.section`
  padding: 16px 0;
`;

export const Paragraph = styled.p`
  width: 100%;
  color: ${ColorPalette.gray58};
  margin: 0;
`;

export const Image = styled(PlceholderImage)`
  width: 80%;
  padding-top: 16px;

  @media ${breakpoints.md} {
    padding: 0;
  }
`;

const PaintedHorseImage = styled(Image)`
  width: 90%;

  @media ${breakpoints.sm} {
    width: 70%;
  }

  @media ${breakpoints.md}, print {
    width: 55%;
    padding-left: 16px;
  }
`;

const MutableFieldsGroup = styled(MutableFieldsGroupBase)`
  @media print {
    flex-direction: row;
  }
`;

const FunFactsArticle = () => (
  <AncestryReportSectionWrapper className="d-flex flex-column m-auto">
    <Section>
      <Paragraph>
        Did you know? Some of the horse "breed" category percentage calculations are more accurate than others! While
        certain groups of horses have been closely bred and tracked for centuries ("closed stud book") making their gene
        pool quite limited and easily tracked, other breeds have rapidly or more recently developed for a variety of
        reasons. Many of the newer breeds were selectively bred to yield a specific look, size, conformation or ability
        for different disciplines and emerging trends. While some sports or purposes remain specific with a stable focus
        (i.e., racing or carriage) others are constantly changing with the times, competition demands and styles
        (hunter/jumper, color breeds, reining, etc.). This means a look at your standard Reining Quarter Horse will have
        a dramatically different look today than it may have in 1980, whereas the Racing Harness horses may appear
        largely similar with minor changes in step with each leading sire. Your horse's composition within its own group
        will change over time to reflect larger samples sizes (more horses included in the study) and changing demands
        for the discipline (see winning halter horses today versus 30 years ago - it's dramatic!).
      </Paragraph>
    </Section>
    <Section>
      <MutableFieldsGroup pointOfChange="md" className="d-flex w-100 align-items-center justify-content-center">
        <Paragraph className="d-flex align-self-start">
          Most white markings may be nouveau but they are not new! Although we "discover" variants in the genome for
          white markings constantly, some of the most fashionable have been around for a long time. "Tobiano" (famously
          making a lovely Pinto spotting pattern on the coat) can be found in ancient horses from as far back as
          3000-5000 years ago.
        </Paragraph>
        <PaintedHorseImage src={FunFacts1} />
      </MutableFieldsGroup>
    </Section>
    <Section>
      <Paragraph>
        Finding the first domestic horse has been challenging. To date, the origin of the first domesticated equine
        remains a mystery. Archaeologists and genealogists find clues in the fossilized remains of varying equids
        through time. Those who are suspected of being "domestic" often carry the tell-tale signs of wear on teeth and
        other jaw bony regions indicating this horse wore a bit - clearly placed there by a human.
      </Paragraph>
    </Section>
    <div className="d-flex w-100 justify-content-center">
      <Image src={FunFacts2} />
    </div>
  </AncestryReportSectionWrapper>
);

export default FunFactsArticle;
