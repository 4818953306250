import {action} from 'typesafe-actions';

import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {getShortTandemRepeatReportActions, getShortTandemRepeateportActionTypes} from './types';
import ShortTandemRepeatReportService from 'OnlineReport/services/ShortTandemRepeatReportService';

export const getShortTandemRepeatReport =
  (horseId: number): ActionResult<IAppState, void, getShortTandemRepeatReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getShortTandemRepeateportActionTypes.REQUEST));
      const res = await ShortTandemRepeatReportService.getShortTandemRepeatReport(horseId);
      dispatch(action(getShortTandemRepeateportActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getShortTandemRepeateportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };
