import {IAncestryUploadDetails} from 'Admin/AdminDashboard/models/Ancestry/IAncestryUploadDetails';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {withAvatar} from 'Common/helpers/withAvatar';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryPcaReferenceDataDto} from 'Common/models/IPcaReferenceDataDto';
import {IAncestryPcaReferencePoint} from 'OnlineReport/models/Ancestry/IAncestryPcaReferencePoint';

export function convertUploadedDetailsToClient(data: IAncestryUploadDetails): IAncestryUploadDetails {
  return {
    uploadDate: convertUTCToClientDate(data.uploadDate) || '',
  };
}

export function convertAncestryRelatedHorseToClient(data: IAncestryRelatedHorse): IAncestryRelatedHorse {
  return {...data, horse: data.horse ? withAvatar(data.horse) : null};
}

export function convertAncestryKinshipHorseToClient(data: IAncestryKinshipHorse): IAncestryKinshipHorse {
  return {...data, horse: data.horse ? withAvatar(data.horse) : null};
}

export function convertPcaReferenceDataDtoToPcaReferencePoints(
  data: IAncestryPcaReferenceDataDto
): IAncestryPcaReferencePoint {
  return {sampleId: data.sampleId, breed: data.breedName, pc1: data.x, pc2: data.y};
}
