import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IUserToAdminMessageReadRequest} from 'SignalR/models/IUserToAdminMessageReadRequest';
import {IAdminToUserMessageRequest} from 'SignalR/models/IAdminToUserMessageRequest';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {IAdminToUserMessageReadRequest} from 'SignalR/models/IAdminToUserMessageReadRequest';
import {IUserToAdminMessageRequest} from 'SignalR/models/IUserToAdminMessageRequest';
import {IAdminToUserMessageRead} from 'SignalR/models/IAdminToUserMessageRead';
import {IUserToAdminMessageRead} from 'SignalR/models/IUserToAdminMessageRead';

const STATE_NAME = 'userToAdminHub';

export interface IDeleteProps {
  userId: number;
}

export interface IUserToAdminHubState {
  data: {
    connectionId: string;
    connectedUsers: number[];
    isAdminConnected: Nullable<boolean>;
    messageQueue: IMessageQueue[];
    userSendingMessage: Nullable<IUserToAdminMessageRequest>;
    adminSendingMessage: Nullable<IAdminToUserMessageRequest>;
    adminMessageReadRequest: Nullable<IAdminToUserMessageReadRequest>;
    userMessageReadRequest: Nullable<IUserToAdminMessageReadRequest>;
    adminMessageRead: Nullable<IAdminToUserMessageRead>;
    userMessageRead: Nullable<IUserToAdminMessageRead>;
  };
  communications: {
    userToAdminMessageSending: ICommunication;
    adminToUserMessageSending: ICommunication;
    messageQueueDeleting: ICommunication;
    adminMessageReadRequestSending: ICommunication;
    userMessageReadRequestSending: ICommunication;
  };
}

export const setConnectionIdActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_CONNECTION_ID');
export type setConnectionIdActions = MakeCommunicationActions<typeof setConnectionIdActionTypes, {success: string}>;

export const setConnectedUserAtionTypes = makeCommunicationActionType(STATE_NAME, 'SET_CONNECT_USER');
export type setConnectedUserActions = MakeCommunicationActions<typeof setConnectedUserAtionTypes, {success: number}>;

export const setDisconnectedUserActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_DICONNECT_USER');
export type setDisconnectedUserActions = MakeCommunicationActions<
  typeof setDisconnectedUserActionTypes,
  {success: number}
>;

export const setConnectedAdminAtionTypes = makeCommunicationActionType(STATE_NAME, 'SET_CONNECT_ADMIN');
export type setConnectedAdminActions = MakeCommunicationActions<typeof setConnectedAdminAtionTypes, {}>;

export const setDisconnectedAdminActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_DICONNECT_ADMIN');
export type setDisconnectedAdminActions = MakeCommunicationActions<typeof setDisconnectedAdminActionTypes, {}>;

export const receiveUserToAdminMessageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SET_RECEIVE_USER_TO_ADMIN_MESSAGE'
);
export type receiveUserToAdminMessageActions = MakeCommunicationActions<
  typeof receiveUserToAdminMessageActionTypes,
  {success: IMessageQueue}
>;

export const receiveAdminToUserMessageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SET_RECEIVE_ADMIN_TO_USER_MESSAGE'
);
export type receiveAdminToUserMessageActions = MakeCommunicationActions<
  typeof receiveAdminToUserMessageActionTypes,
  {success: IMessageQueue}
>;

export const setSentUserToAdminMessageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SET_SENT_USER_TO_ADMIN_MESSAGE'
);
export type setSentUserToAdminMessageActions = MakeCommunicationActions<
  typeof setSentUserToAdminMessageActionTypes,
  {success: IMessageQueue}
>;

export const setSentAdminToUserMessageActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SET_SENT_ADMIN_TO_USER_MESSAGE'
);
export type setSentAdminToUserMessageActions = MakeCommunicationActions<
  typeof setSentAdminToUserMessageActionTypes,
  {success: IMessageQueue}
>;

export const sendUserToAdminMessageActionTypes = makeCommunicationActionType(STATE_NAME, 'SEND_USER_TO_ADMIN_MESSAGE');
export type sendUserToAdminMessageActions = MakeCommunicationActions<
  typeof sendUserToAdminMessageActionTypes,
  {success: IUserToAdminMessageRequest}
>;

export const sendAdminToUserMessageActionTypes = makeCommunicationActionType(STATE_NAME, 'SEND_ADMIN_TO_USER_MESSAGE');
export type sendAdminToUserMessageActions = MakeCommunicationActions<
  typeof sendAdminToUserMessageActionTypes,
  {success: IAdminToUserMessageRequest}
>;

export const deleteMessageQueueActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_MESSAGE');
export type deleteMessageQueueActions = MakeCommunicationActions<
  typeof deleteMessageQueueActionTypes,
  {success: IDeleteProps}
>;

export const sendUserToAdminMessageReadRequestActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SEND_USER_TO_ADMIN_MESSAGE_READ_REQUEST'
);
export type sendUserToAdminMessageReadRequestActions = MakeCommunicationActions<
  typeof sendUserToAdminMessageReadRequestActionTypes,
  {success: IUserToAdminMessageReadRequest}
>;

export const sendAdminToUserMessageReadRequestActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'SEND_ADMIN_TO_USER_MESSAGE_READ_REQUEST'
);
export type sendAdminToUserMessageReadRequestActions = MakeCommunicationActions<
  typeof sendAdminToUserMessageReadRequestActionTypes,
  {success: IAdminToUserMessageReadRequest}
>;

export const getUserToAdminMessageReadActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_MESSAGE_READ'
);
export type getUserToAdminMessageReadActions = MakeCommunicationActions<
  typeof getUserToAdminMessageReadActionTypes,
  {success: IUserToAdminMessageRead}
>;

export const getAdminToUserMessageReadActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_TO_USER_MESSAGE_READ'
);
export type getAdminToUserMessageReadActions = MakeCommunicationActions<
  typeof getAdminToUserMessageReadActionTypes,
  {success: IAdminToUserMessageRead}
>;

export const resetUserToAdminSendingMessageActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'SENDING_USER_TO_ADMIN_MESSAGE'
);
export type resetUserToAdminSendingMessageActions = MakeResetCommunicationActions<
  typeof resetUserToAdminSendingMessageActionTypes
>;

export const resetAdminToUserSendingMessageActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'SENDING_ADMIN_TO_USER_MESSAGE'
);
export type resetAdminToUserSendingMessageActions = MakeResetCommunicationActions<
  typeof resetAdminToUserSendingMessageActionTypes
>;

export const resetUserToAdminMessageReadRequestActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'SEND_USER_TO_ADMIN_MESSAGE_READ_REQUEST'
);
export type resetUserToAdminMessageReadRequestActions = MakeResetCommunicationActions<
  typeof resetUserToAdminMessageReadRequestActionTypes
>;

export const resetAdminToUserMessageReadRequestActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'SEND_ADMIN_TO_USER_MESSAGE_READ_REQUEST'
);
export type resetAdminToUserMessageReadRequestActions = MakeResetCommunicationActions<
  typeof resetAdminToUserMessageReadRequestActionTypes
>;

export const resetUserToAdminMessageReadActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'GET_USER_TO_ADMIN_MESSAGE_READ'
);
export type resetUserToAdminMessageReadActions = MakeResetCommunicationActions<
  typeof resetUserToAdminMessageReadActionTypes
>;

export const resetAdminToUserMessageReadActionTypes = makeResetCommunicationActionType(
  STATE_NAME,
  'GET_ADMIN_TO_USER_MESSAGE_READ'
);
export type resetAdminToUserMessageReadActions = MakeResetCommunicationActions<
  typeof resetAdminToUserMessageReadActionTypes
>;

export type Actions =
  | setConnectionIdActions
  | setConnectedUserActions
  | setDisconnectedUserActions
  | setConnectedAdminActions
  | setDisconnectedAdminActions
  | receiveUserToAdminMessageActions
  | receiveAdminToUserMessageActions
  | setSentUserToAdminMessageActions
  | setSentAdminToUserMessageActions
  | sendUserToAdminMessageActions
  | sendAdminToUserMessageActions
  | deleteMessageQueueActions
  | sendUserToAdminMessageReadRequestActions
  | sendAdminToUserMessageReadRequestActions
  | getUserToAdminMessageReadActions
  | getAdminToUserMessageReadActions
  | resetUserToAdminSendingMessageActions
  | resetAdminToUserSendingMessageActions
  | resetUserToAdminMessageReadRequestActions
  | resetAdminToUserMessageReadRequestActions
  | resetUserToAdminMessageReadActions
  | resetAdminToUserMessageReadActions;
