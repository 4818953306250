import React, {memo} from 'react';
import styled from 'styled-components';

import CommonLink from 'Common/components/Controls/Links/CommonLink';
import Typography from 'Common/constants/Typography';
import {INotificationMessage} from 'SignalR/models/INotificationMessage';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import {parseEventNotificationDetails} from 'Notifications/helpers/parseEventNotificationDetails';

const Text = styled.span`
  font-weight: ${Typography.weight.normal400};
  margin-bottom: 8px;
`;

const LinkWrapper = styled.div`
  gap: 8px;
  margin-top: 8px;
`;

const Detailsinfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 4fr;
  border-bottom: 1px solid ${ColorPalette.white0};
`;

const TextBase = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  line-height: 24px;
  text-align: start;
  word-break: break-word;
`;

const Label = styled(TextBase)`
  font-size: ${Typography.size.size12};
`;

const Value = styled(TextBase)`
  font-size: ${Typography.size.size14};
`;

interface IProps {
  message: INotificationMessage;
}

function UserNotificationReceived(props: IProps) {
  const {message} = props;
  const {code, details, name} = message;

  const parsedDetails = parseEventNotificationDetails(details, code);
  const detailsContent = parsedDetails.details;

  const gotoUrl = `/notifications`;

  return (
    <div className="d-flex flex-column">
      <Text>{name}</Text>

      <Detailsinfo>
        {Object.keys(detailsContent).map((x, i) => (
          <React.Fragment key={i}>
            <Label>{x}</Label>
            <Value>{detailsContent[x]}</Value>
          </React.Fragment>
        ))}
      </Detailsinfo>

      <LinkWrapper className="d-flex align-items-center justify-content-center">
        <CommonLink to={gotoUrl}>
          Click here to go to Notifications page <ColoredIcon name={IconName.ArrowRight} color={ColorPalette.white0} />
        </CommonLink>
      </LinkWrapper>
    </div>
  );
}

export default memo(UserNotificationReceived);
