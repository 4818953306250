import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useReactToPrint} from 'react-to-print';

import {IOnlineReportExternalProps} from 'OnlineReport/models/shared/IOnlineReportExternalProps';
import ReportLogo from 'Icon/report-logo.svg';
import {BreedType} from 'OnlineReport/constants/BreedType';
import CompositionPie from './parts/CompositionChart/CompositionPie';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import MapView from './parts/MapView';
import HorseAncestryPie from './parts/CompositionChart/HorseAncestryPie';
import {IAncestryPopulation} from 'OnlineReport/models/shared/IAncestryPopulation';
import {Nullable} from 'Common/types';
import {IHorseProfile} from 'Horse/models/IHorseProfile';
import HorseDataService from 'Horse/services/HorseDataService';
import {IAncestryProbabilities} from 'OnlineReport/models/shared/IAncestryProbabilities';
import Loading from 'Loading/components/Loading';
import {IOnlineReportHorseDetails} from 'OnlineReport/models/shared/IOnlineReportHorseDetails';
import {IOnlineReportHorseInfo} from 'OnlineReport/models/shared/IOnlineReportHorseInfo';
import {IAncestryRelatedHorse} from 'OnlineReport/models/Ancestry/IAncestryRelatedHorse';
import {IAncestryKinshipHorse} from 'OnlineReport/models/Ancestry/IAncestryKinshipHorse';
import IconButton from 'Common/components/Controls/Buttons/IconButton';
import {IconName} from 'Icon/components/Icon';
import AncestryReportService from 'OnlineReport/services/AncestryReportService';
import OnlineReportsService from 'OnlineReport/services/OnlineReportsService';
import Image from 'Common/components/Image/Image';
import {maxBreakpoints, size} from 'Common/constants/Breakpoints';

const Root = styled.div`
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${maxBreakpoints.md} {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${maxBreakpoints.md} {
    align-items: center;
  }
`;

const Title = styled.h1`
  color: #7f0203;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2px;
  display: flex;
  gap: 4px;
  align-items: center;
  @media ${maxBreakpoints.md} {
    flex-direction: column;
  }
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const SubTitleItem = styled.h3`
  font-size: 14px;
  margin-bottom: 0px;
`;

const Logo = styled(Image)`
  width: 110px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const HorizontalLine = styled.hr`
  background-color: #7f0203;
  height: 2px;
  border: none;
  margin: 8px 0;
`;

const SummaryTitle = styled.h3<{align?: string; size?: string}>`
  font-family: ${Theme.font.secondary};
  font-size: ${(props) => (props.size === 'sm' ? Typography.size.size19 : Typography.size.size22)};
  font-weight: ${Typography.weight.semiBold600};
  text-align: ${(props) => props.align || 'left'};
`;

const NotFoundError = styled.h3<{align?: string; size?: string}>`
  font-family: ${Theme.font.secondary};
  font-size: ${(props) => (props.size === 'sm' ? Typography.size.size19 : Typography.size.size22)};
  font-weight: ${Typography.weight.semiBold600};
  text-align: ${(props) => props.align || 'left'};
  color: #aaa;
`;

const AncestrySummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media ${maxBreakpoints.md} {
    flex-direction: column;
  }
`;

const AncestrySummary = styled.div`
  scale: 1;
  transform-origin: left top;
  flex-basis: 60%;
  border: 2px solid ${ColorPalette.gray20};
  border-radius: 6px;
  padding: 16px;
  @media ${maxBreakpoints.md} {
    width: 100%;
  }
`;

const AncestryLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-basis: 40%;
  @media ${maxBreakpoints.md} {
    border: 2px solid ${ColorPalette.gray20};
    border-radius: 6px;
    width: 100%;
    padding: 16px;
  }
`;

const AncestryLabel = styled.div`
  font-family: ${Theme.font.secondary};
  font-size: ${Typography.size.size18};
  font-weight: ${Typography.weight.semiBold600};
  border: 2px solid ${ColorPalette.gray20};
  border-radius: 6px;
  padding: 5px;
  text-align: center;
`;

const HorseField = styled.div`
  display: flex;
  gap: 5px;
  font-size: ${Typography.size.size18};
`;

const FieldLabel = styled.div`
  font-weight: 700;
`;

const FieldValue = styled.div`
  font-weight: 400;
`;

const AncestryDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 10px;
  @media ${maxBreakpoints.md2} {
    flex-direction: column;
  }
`;
const DetailLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  gap: 16px;
`;
const DetailRightWrapper = styled.div`
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  border: 2px solid ${ColorPalette.gray20};
  border-radius: 6px;
  padding: 16px;
`;
const CompositionMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  scale: 1;
  border: 2px solid ${ColorPalette.gray20};
  border-radius: 6px;
  padding: 16px;
`;
const MapViewWrapper = styled.div`
  height: 100%;
  margin-bottom: 10px;
  @media print {
    margin-top: 0px !important;
  }
`;
const HorsesLikeMeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${ColorPalette.gray20};
  border-radius: 6px;
  padding: 16px;
`;
const HorsesLikeMeChildrenWrapper = styled.div`
  display: flex;
  height: 570px;
  @media ${maxBreakpoints.md} {
    flex-direction: column;
    height: unset;
    gap: 30px;
  }
`;
const HorsesLikeMePrimaryChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  @media print {
    @media (max-width: ${size.xl}px) {
      scale: 0.85;
      transform-origin: top left;
    }
  }
  @media ${maxBreakpoints.lg} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 30px;
    transform-origin: unset;
    scale: unset;
  }
`;
const HorsesLikeMeSecondaryChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
`;
const FindMyHerdWrapper = styled.div``;
const PositionWrapper = styled.div<{x: number; y: number}>`
  position: absolute;
  top: ${(props) => props.y + 'px'};
  left: ${(props) => props.x + 'px'};
  @media ${maxBreakpoints.md} {
    position: relative;
    top: unset;
    left: unset;
  }
`;
const MainPositionWrapper = styled.div<{x: number; y: number}>`
  position: absolute;
  top: ${(props) => props.y + 'px'};
  left: ${(props) => props.x + 'px'};
  @media ${maxBreakpoints.md} {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
  }
`;
const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const HerdLevelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
`;
const MidLineWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 10px 0px;
  color: #c0c0c0;
  font-size: 12px;
  font-weight: 500;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;
const RedChar = styled.span`
  color: #c02050;
  width: 14px;
  height: 14px;
  border: 1px solid #c02050;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DownloadButton = styled(IconButton)`
  @media print {
    display: none;
  }
`;

type AllProps = IOnlineReportExternalProps;

const OnePagerAncestry = (props: AllProps) => {
  const horseId = Number(props.horseId);
  const [isLoading, setLoading] = useState(true);
  const [horseProfile, setHorseProfile] = useState<Nullable<IHorseProfile>>(null);
  const [population, setPopulation] = useState<IAncestryPopulation[]>([]);
  const [probabilities, setProbabilities] = useState<Nullable<IAncestryProbabilities>>(null);
  const [horseReportDetails, setHorseReportDetails] = useState<Nullable<IOnlineReportHorseDetails>>(null);
  const [horseInfo, setHorseInfo] = useState<Nullable<IOnlineReportHorseInfo>>(null);
  const [relatedHorses, setRelatedHorses] = useState<IAncestryRelatedHorse[]>([]);
  const [kinshipHorses, setKinshipHorses] = useState<IAncestryKinshipHorse[]>([]);
  const [celebrityHorses, setCelebrityHorses] = useState<IAncestryRelatedHorse[]>([]);
  const reportPageRef = useRef(null);

  useEffect(() => {
    if (props.shouldTriggerPrint) {
      handlePrint();
    }
  }, [props.shouldTriggerPrint]);

  const pdfContent = useCallback(() => {
    return reportPageRef.current;
  }, [reportPageRef.current]);

  const handlePrint = useReactToPrint({
    content: pdfContent,
    documentTitle: 'Etalon Ancestry',
    pageStyle: `@page {
      size: auto;
      margin: 20mm;
    }`,
    onAfterPrint: () => {
      setLoading(false);
    },
    onBeforeGetContent: () => {
      setLoading(true);
    },
  });

  const ancestryMetrics = useMemo(() => {
    const data = probabilities;
    const fValue = data?.f ? data.f * 100 : 0;
    const heterozygosity = data?.ohom && data.nnm ? (1 - data.ohom / data.nnm) * 100 : 0;
    const thoroughbredBlood = population.filter((x) => x.type === BreedType.Thoroughbred)[0]?.percentage || 0;
    return {
      fValue,
      heterozygosity,
      thoroughbredBlood,
    };
  }, [probabilities, population]);

  useEffect(() => {
    const horseProfilePromise = HorseDataService.getHorse(horseId);
    const populationPromise = AncestryReportService.getPopulation(horseId);
    const probabilitiesPromise = AncestryReportService.getAncestryProbabilities(horseId);
    const horseReportPromise = OnlineReportsService.getHorseDetails(horseId);
    const horseInfoPromise = OnlineReportsService.getHorseInfo(horseId);
    const relatedHorsesPromise = AncestryReportService.getAncestryRelatedHorses(horseId);
    const kinshipHorsesPromise = AncestryReportService.getAncestryKinshipHorses(horseId);
    Promise.allSettled([
      horseProfilePromise,
      populationPromise,
      probabilitiesPromise,
      horseReportPromise,
      horseInfoPromise,
      relatedHorsesPromise,
      kinshipHorsesPromise,
    ]).then(([profile, population, probabilities, report, horseInfo, relatedHorses, kinshipHorses]) => {
      if (profile.status === 'fulfilled') setHorseProfile(profile.value);
      if (population.status === 'fulfilled') setPopulation(population.value);
      if (probabilities.status === 'fulfilled') setProbabilities(probabilities.value);
      if (report.status === 'fulfilled') setHorseReportDetails(report.value);
      if (horseInfo.status === 'fulfilled') setHorseInfo(horseInfo.value);
      if (relatedHorses.status === 'fulfilled') {
        setRelatedHorses(relatedHorses.value.slice(0, 5));
        const sortedHorses = [...relatedHorses.value].sort((a, b) => {
          if (a.percent > b.percent) return -1;
          return 1;
        });
        setCelebrityHorses(sortedHorses.filter((horse) => horse.isCelebrity));
      }
      if (kinshipHorses.status === 'fulfilled') setKinshipHorses(kinshipHorses.value);
      setLoading(false);
    });
  }, []);

  const getDegreeKinshipHorses = (degree: number) => {
    const degreeLabels = ['', 'First', 'Second', 'Third'];
    return kinshipHorses.filter((horse) => horse.degree === degreeLabels[degree]);
  };

  const degreeFirstKinshipHorses = useMemo(() => {
    return getDegreeKinshipHorses(1);
  }, [kinshipHorses]);

  const degreeSecondKinshipHorses = useMemo(() => {
    return getDegreeKinshipHorses(2);
  }, [kinshipHorses]);

  const degreeThirdKinshipHorses = useMemo(() => {
    return getDegreeKinshipHorses(3);
  }, [kinshipHorses]);

  const getHorseLabels = (horse: IAncestryRelatedHorse) => {
    return [Math.ceil(horse.percent * 100) + '% similar', ...horse.horseBreeds];
  };

  const relatedHorsesPositions: any[] = [];
  const horseAngles = [72, 43, 0, -43, -72];
  for (let i = 0; i < 5; i++) {
    const radiusX = i === 2 || i === 4 ? 350 : 320,
      radiusY = 200;
    const angle = (horseAngles[i] / 180) * 3.14;
    const x = 0 + radiusX * Math.cos(angle);
    const y = 200 - radiusY * Math.sin(angle);
    relatedHorsesPositions.push({x, y, scale: 0.5});
  }

  return (
    <Root ref={reportPageRef}>
      {isLoading && <Loading />}
      <Header>
        <HeaderLeft>
          <Title className="font-heading">
            <span>One Page Ancestry Report</span>
            <DownloadButton
              onClick={handlePrint}
              name={IconName.ArrowDownload}
              color={Theme.color.primary}
              hoverColor={ColorPalette.red9}
              size={24}
              stroke={false}
              fill={true}
            />
          </Title>
          <SubTitle>
            <SubTitleItem>EtalonDX.com</SubTitleItem>
            <SubTitleItem>LabID: {horseReportDetails?.labId}</SubTitleItem>
          </SubTitle>
        </HeaderLeft>
        <HeaderRight>
          <Logo src={ReportLogo} />
        </HeaderRight>
      </Header>
      <HorizontalLine />
      <MainContent>
        <AncestrySummaryWrapper>
          <AncestrySummary>
            <SummaryTitle>ANCESTRY IDENTIFIED</SummaryTitle>
            <CompositionPie
              isShowLabels
              isShowLegend
              isShowLegendPercentage
              population={population}
              avatar={{
                id: 0,
                url: horseInfo?.avatar?.url || '',
              }}
            />
          </AncestrySummary>
          <AncestryLabelWrapper>
            <HorseField>
              <FieldLabel>Name:</FieldLabel>
              <FieldValue>{horseProfile?.name}</FieldValue>
            </HorseField>
            <HorseField>
              <FieldLabel>Owner:</FieldLabel>
              <FieldValue>{horseProfile?.owner?.firstName + ' ' + horseProfile?.owner?.lastName}</FieldValue>
            </HorseField>
            <AncestryLabel>Genomic Inbreeding: {ancestryMetrics.fValue.toFixed(3)}%</AncestryLabel>
            <AncestryLabel>Thoroughbred "Blood": {ancestryMetrics.thoroughbredBlood.toFixed(2)}%</AncestryLabel>
            <AncestryLabel>Heterozygosity: {ancestryMetrics.heterozygosity.toFixed(3)}%</AncestryLabel>
          </AncestryLabelWrapper>
        </AncestrySummaryWrapper>
        <AncestryDetailWrapper>
          <DetailLeftWrapper>
            <CompositionMapWrapper>
              <SummaryTitle>COMPOSITION MAP</SummaryTitle>
              <MapViewWrapper>
                <MapView population={population} isShowTitle={false} withoutPaddings />
              </MapViewWrapper>
            </CompositionMapWrapper>
            <HorsesLikeMeWrapper>
              <SummaryTitle>HORSES LIKE ME</SummaryTitle>
              <HorsesLikeMeChildrenWrapper>
                <HorsesLikeMePrimaryChildWrapper>
                  <MainPositionWrapper x={0} y={200}>
                    <HorseAncestryPie
                      population={population}
                      title={horseInfo?.name || ''}
                      scale={0.8}
                      avatar={{
                        id: 0,
                        url: horseInfo?.avatar?.url || '',
                      }}
                    />
                  </MainPositionWrapper>
                  {relatedHorses.map((horse, index) => (
                    <PositionWrapper x={relatedHorsesPositions[index].x} y={relatedHorsesPositions[index].y}>
                      <HorseAncestryPie
                        population={population}
                        title={horse.horse?.name || ''}
                        labels={getHorseLabels(horse)}
                        key={horse.animalId}
                        rank={index + 1}
                        isShowRank
                        isShowTitle
                        scale={relatedHorsesPositions[index].scale}
                        avatar={{
                          id: 0,
                          url: horse.horse?.avatar?.url || '',
                        }}
                      />
                    </PositionWrapper>
                  ))}
                  {!relatedHorses.length && (
                    <NotFoundError size="sm">
                      No matching horses found, please check in with us again in the future as more horses participate
                    </NotFoundError>
                  )}
                </HorsesLikeMePrimaryChildWrapper>
                {celebrityHorses.length > 0 && (
                  <HorsesLikeMeSecondaryChildWrapper>
                    <SummaryTitle align="center" size="sm">
                      CELEBRITY HORSE LIKE ME
                    </SummaryTitle>
                    <CenterWrapper>
                      <HorseAncestryPie
                        population={population}
                        title={celebrityHorses[0].horse?.name || ''}
                        labels={getHorseLabels(celebrityHorses[0])}
                        isShowTitle
                        scale={0.65}
                        avatar={{
                          id: 0,
                          url: celebrityHorses[0].horse?.avatar?.url || '',
                        }}
                      />
                    </CenterWrapper>
                  </HorsesLikeMeSecondaryChildWrapper>
                )}
              </HorsesLikeMeChildrenWrapper>
            </HorsesLikeMeWrapper>
          </DetailLeftWrapper>
          <DetailRightWrapper>
            <SummaryTitle>FIND MY HERD</SummaryTitle>
            <FindMyHerdWrapper>
              <HerdLevelWrapper>
                <HorseAncestryPie
                  population={population}
                  title={horseInfo?.name || ''}
                  isShowTitle
                  scale={0.7}
                  avatar={{
                    id: 0,
                    url: horseInfo?.avatar?.url || '',
                  }}
                />
              </HerdLevelWrapper>
              {degreeFirstKinshipHorses.length > 0 && (
                <>
                  <MidLineWrapper>
                    1st Degree Relation&nbsp;&nbsp;<RedChar>?</RedChar>
                  </MidLineWrapper>
                  <HerdLevelWrapper>
                    {degreeFirstKinshipHorses.map((horse) => (
                      <HorseAncestryPie
                        population={population}
                        title={horse.horse?.name || ''}
                        labels={getHorseLabels(horse)}
                        isShowTitle
                        scale={0.55}
                        key={horse.animalId}
                        avatar={{
                          id: 0,
                          url: horse.horse?.avatar?.url || '',
                        }}
                      />
                    ))}
                  </HerdLevelWrapper>
                </>
              )}
              {degreeSecondKinshipHorses.length > 0 && (
                <>
                  <MidLineWrapper>
                    2nd Degree Relation&nbsp;&nbsp;<RedChar>?</RedChar>
                  </MidLineWrapper>
                  <HerdLevelWrapper>
                    {degreeSecondKinshipHorses.map((horse) => (
                      <HorseAncestryPie
                        population={population}
                        title={horse.horse?.name || ''}
                        labels={getHorseLabels(horse)}
                        isShowTitle
                        scale={0.45}
                        key={horse.animalId}
                        avatar={{
                          id: 0,
                          url: horse.horse?.avatar?.url || '',
                        }}
                      />
                    ))}
                  </HerdLevelWrapper>
                </>
              )}
              {degreeThirdKinshipHorses.length > 0 && (
                <>
                  <MidLineWrapper>
                    3rd Degree Relation&nbsp;&nbsp;<RedChar>?</RedChar>
                  </MidLineWrapper>
                  <HerdLevelWrapper>
                    {degreeThirdKinshipHorses.map((horse) => (
                      <HorseAncestryPie
                        population={population}
                        title={horse.horse?.name || ''}
                        labels={getHorseLabels(horse)}
                        isShowTitle
                        scale={0.4}
                        key={horse.animalId}
                        avatar={{
                          id: 0,
                          url: horse.horse?.avatar?.url || '',
                        }}
                      />
                    ))}
                  </HerdLevelWrapper>
                </>
              )}
            </FindMyHerdWrapper>
          </DetailRightWrapper>
        </AncestryDetailWrapper>
      </MainContent>
    </Root>
  );
};

export default OnePagerAncestry;
