import React, {useMemo} from 'react';
import BaseLayout from '../../Common/components/BaseLayout/BaseLayout';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from '../../UserProfile/store/currentUser/selectors';
import {IUser} from '../../UserProfile/models/IUser';
import AuthService from '../../Auth/services/AuthService';
import {getEnvParams} from '../../Common/helpers/getEnvParams';

interface ActivateTestsLayoutProps {}

export function ActivateTestsLayout({}: ActivateTestsLayoutProps) {
  const currentUser = useSelector(selectCurrentUser) as IUser;
  const authToken = useMemo(() => AuthService.getAccessToken(), [currentUser.id]);
  const isTest = getEnvParams().baseUrl.includes('test');

  // scroll to top on mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BaseLayout>
      <iframe
        className="border-0 h-100vh"
        src={`https://${isTest ? 'test.' : ''}activate.etalondx.com?auth_token=${authToken}&embedded=true`}
      />
    </BaseLayout>
  );
}
