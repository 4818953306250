import * as Yup from 'yup';
import moment from 'moment';

import {DiscountType} from 'Coupon/constants/DiscountType';
import {CouponStatus} from 'Coupon/constants/CouponStatus';
import {IEntity} from 'Common/models/IEntity';
import {ICoupon} from 'Coupon/models/ICoupon';
import {FRONTEND_DATE_TIME} from 'Common/constants/Date';
import {isValidDate} from 'Common/helpers/DateHelper';
import {REQUIRED_FIELD} from 'Common/constants/ValidationSchema';

export interface ICouponRequest
  extends Omit<ICoupon, 'userPackages' | 'associationPackages' | 'associationPurchasableTests'> {
  userPackages?: IEntity[];
  associationPackages?: IEntity[];
  associationPurchasableTests?: IEntity[];
}

export interface ICouponForm
  extends Omit<ICoupon, 'userPackages' | 'associationPackages' | 'associationPurchasableTests'> {
  userPackages?: number[];
  associationPackages?: number[];
  associationPurchasableTests?: number[];
}

export const initialValue: ICouponForm = {
  id: 0,
  amount: 0,
  code: '',
  countBeforeExpiry: 0,
  countUsed: 0,
  discountType: DiscountType.Amount,
  maxPerUser: 0,
  startDate: null,
  expiryDate: null,
  status: CouponStatus.New,
  isEnabled: false,
  userPackages: [],
  associationPackages: [],
  associationPurchasableTests: [],
  description: '',
};

export const validationSchema = Yup.object().shape<ICouponForm>({
  id: Yup.number(),
  amount: Yup.number().required(REQUIRED_FIELD),
  code: Yup.string().required(REQUIRED_FIELD),
  countBeforeExpiry: Yup.number().required(REQUIRED_FIELD),
  countUsed: Yup.number(),
  discountType: Yup.mixed<DiscountType>().required(REQUIRED_FIELD),
  maxPerUser: Yup.number().required(REQUIRED_FIELD),
  userPackages: Yup.array(),
  startDate: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD)
    .test('isStartDateValid', `Please enter a valid date: ${FRONTEND_DATE_TIME}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE_TIME, true) : true;
    })
    .test('startDate', "Must be earlier than 'Valid until'", function (value) {
      return moment(value, FRONTEND_DATE_TIME).isBefore(moment(this.parent.expiryDate, FRONTEND_DATE_TIME) || 0);
    }),
  expiryDate: Yup.string()
    .nullable()
    .required(REQUIRED_FIELD)
    .test('isExpiryDateValid', `Please enter a valid date: ${FRONTEND_DATE_TIME}`, function (value: string) {
      return value ? isValidDate(value, FRONTEND_DATE_TIME, true) : true;
    })
    .test('expiryDate', "Must be later than 'Valid from'", function (value) {
      return moment(value, FRONTEND_DATE_TIME).isAfter(moment(this.parent.startDate, FRONTEND_DATE_TIME) || 0);
    }),
  status: Yup.mixed<CouponStatus>(),
  isEnabled: Yup.boolean(),
  description: Yup.string(),
});
