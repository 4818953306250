import {IDistance} from 'Filters/models/Distance';
import {GenePoolsType} from 'Filters/models/GenePools';
import {Gender} from 'Common/constants/Gender';
import {MAX_LOCATION_RADIUS} from './parts/GenePools';
import {IBuildHorseRequestHealthVariant} from 'Filters/models/IBuildHorseRequestHealthVariant';

export type HorseFiltersGender = Gender | 'all';

type ExternalFormValues = Omit<IFormValues, 'isSpeedSelected' | 'isTemperamentSelected' | 'isGaitSelected'>;

type BaHFormValues = Omit<ExternalFormValues, 'gender'>;

interface FaHFormValues extends Omit<ExternalFormValues, 'horseId' | 'gender'> {
  gender: Gender | null;
}

export function convertHorseFiltersFormValues(type: 'bah', values: Partial<Omit<IFormValues, 'gender'>>): BaHFormValues;
export function convertHorseFiltersFormValues(type: 'fah', values: Partial<IFormValues>): FaHFormValues;
export function convertHorseFiltersFormValues(
  type: 'fah' | 'bah',
  values: Partial<IFormValues>
): BaHFormValues | FaHFormValues {
  const {
    sourceTypes,
    commercialTypes,
    breeds,
    disciplines,
    healthVariantsToAvoidExtended,
    colorModifiers,
    abilities,
    isSpeedSelected,
    isTemperamentSelected,
    isGaitSelected,
    associations,
    distance,
    gender,
    color,
    horseId,
    ageFrom,
    ageTo,
    isHideExcludedHorses,
  } = values;

  const filteredAbilities: IFormValues['abilities'] = !abilities
    ? {}
    : {
        speed: isSpeedSelected ? abilities.speed : undefined,
        temperament: isTemperamentSelected ? abilities.temperament : undefined,
        gait: isGaitSelected ? abilities.gait : undefined,
      };

  const sharedFields = {
    sourceTypes: sourceTypes || [GenePoolsType.AllPublic],
    commercialTypes: commercialTypes || [],
    breeds: breeds || [],
    disciplines: disciplines || [],
    healthVariantsToAvoid:
      healthVariantsToAvoidExtended?.map((compositeName) => {
        const compositeNameArray = compositeName.split('_');
        return {
          id: compositeNameArray?.[0],
          isAggregated: compositeNameArray?.[1] === 'true',
        };
      }) || [],
    colorModifiers: colorModifiers || [],
    associations: sourceTypes?.includes(GenePoolsType.Registries) && associations ? associations : [],
    abilities: filteredAbilities,
    distance: distance?.value === 0 ? {} : distance,
    color,
    ageFrom,
    ageTo,
    isHideExcludedHorses: !isHideExcludedHorses,
  };

  if (type === 'bah') {
    return {
      ...sharedFields,
      horseId,
    };
  }

  return {
    ...sharedFields,
    gender: gender === 'all' ? null : gender,
  };
}

export interface IFormValues {
  gender?: HorseFiltersGender;
  breeds: number[];
  disciplines: number[];
  healthVariantsToAvoidExtended?: string[];
  healthVariantsToAvoid: IBuildHorseRequestHealthVariant[];
  color?: number;
  colorModifiers: number[];
  distance?: IDistance;
  abilities: {
    speed?: number;
    gait?: number;
    temperament?: number;
  };
  isSpeedSelected?: boolean;
  isTemperamentSelected?: boolean;
  isGaitSelected?: boolean;
  sourceTypes: GenePoolsType[];
  associations: number[];
  commercialTypes: number[];
  horseId?: number;
  ageFrom?: number;
  ageTo?: number;
  includeTwoYears?: boolean;
  isHideExcludedHorses?: boolean;
}

export const initialHorseFiltersValues: IFormValues = {
  gender: 'all',
  breeds: [],
  disciplines: [],
  healthVariantsToAvoid: [],
  healthVariantsToAvoidExtended: [],
  distance: {value: MAX_LOCATION_RADIUS},
  colorModifiers: [],
  sourceTypes: [GenePoolsType.AllPublic],
  associations: [],
  commercialTypes: [],
  abilities: {},
  isSpeedSelected: false,
  isTemperamentSelected: false,
  isGaitSelected: false,
  includeTwoYears: true,
  isHideExcludedHorses: false,
};

export type IHorseFiltersFormValues = Partial<IFormValues>;
