import {IModule} from 'redux-dynamic-modules';

import {shortTandemRepeatUploadReducer} from './reducer';
import {IAdminShortTandemRepeatUploadState} from './types';

export interface IAdminShortTandemRepeatModuleState {
  adminShortTandemRepeatUpload: IAdminShortTandemRepeatUploadState;
}

export const AdminShortTandemRepeatUploadModule: IModule<IAdminShortTandemRepeatModuleState> = {
  id: 'adminShortTandemRepeatUpload',
  reducerMap: {
    adminShortTandemRepeatUpload: shortTandemRepeatUploadReducer,
  },
};
