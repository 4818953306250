import {IModule} from 'redux-dynamic-modules';

import {IAdminTestResultsState} from './types';
import {testResultsReducer} from './reducer/index';

export interface IAdminTestResultsModuleState {
  adminTestResults: IAdminTestResultsState;
}

export const AdminTestResultsModule: IModule<IAdminTestResultsModuleState> = {
  id: 'adminTestResults',
  reducerMap: {
    adminTestResults: testResultsReducer,
  },
};
