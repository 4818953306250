import React, {memo} from 'react';
import {FieldProps} from 'formik';

import Checkbox, {CheckboxProps} from 'Common/components/Controls/Checkbox';

import withField from './withField';
import ValidationError from './ValidationError';

type Props = FieldProps & CheckboxProps;

function CheckboxField(props: Props) {
  const {field, form, ...other} = props;

  return (
    <div>
      <Checkbox checked={field.value ?? false} {...other} {...field} />
      <ValidationError name={field.name} />
    </div>
  );
}

export default memo(withField(CheckboxField));
