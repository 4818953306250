import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  Actions,
  changeHorseOwnerActionTypes,
  createHorseTransferActionTypes,
  getHorseTransferDetailsActionTypes,
  getHorseTransfersActionTypes,
  IAdminHorseTransferState,
  rejectHorseTransferActionTypes,
} from '../types';

export const communicationHorseTransferReducer = combineReducers<IAdminHorseTransferState['communications'], Actions>({
  horseTransfersLoading: makeCommunicationReducerFromEnum(getHorseTransfersActionTypes),
  horseTransferRejecting: makeCommunicationReducerFromEnum(rejectHorseTransferActionTypes),
  horseOwnerChanging: makeCommunicationReducerFromEnum(changeHorseOwnerActionTypes),
  horseTransferDetailsLoading: makeCommunicationReducerFromEnum(getHorseTransferDetailsActionTypes),
  horseTransferCreating: makeCommunicationReducerFromEnum(createHorseTransferActionTypes),
});
