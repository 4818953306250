import {Reducer} from 'redux';

import {
  AncestryReportActions,
  getAncestryHorsePrivacyActionTypes,
  getAncestryKinshipHorsesActionTypes,
  getAncestryProbabilitiesActionTypes,
  getAncestryRelatedHorsesActionTypes,
  getAncestryPcaReferenceDataActionTypes,
  getPopulationActionTypes,
  getPopulationComparisonActionTypes,
  IAncestryHorsePrivacy,
  IAncestryReportState,
} from '../types';

const initialState: IAncestryReportState['data'] = {
  population: null,
  populationComparison: null,
  ancestryProbabilities: null,
  ancestryRelatedHorses: [],
  ancestryKinshipHorses: [],
  ancestryHorsePrivacy: [],
  ancestryPcaReferenceData: [],
};

export const dataReducer: Reducer<IAncestryReportState['data'], AncestryReportActions> = (
  state = initialState,
  action: AncestryReportActions
) => {
  switch (action.type) {
    case getPopulationActionTypes.REQUEST: {
      return {...state, population: null};
    }
    case getPopulationActionTypes.SUCCESS: {
      return {...state, population: action.payload};
    }

    case getPopulationComparisonActionTypes.REQUEST: {
      return {...state, populationComparison: null};
    }
    case getPopulationComparisonActionTypes.SUCCESS: {
      return {...state, populationComparison: action.payload};
    }

    case getAncestryProbabilitiesActionTypes.REQUEST: {
      return {...state, ancestryProbabilities: null};
    }
    case getAncestryProbabilitiesActionTypes.SUCCESS: {
      return {...state, ancestryProbabilities: action.payload};
    }

    case getAncestryRelatedHorsesActionTypes.REQUEST: {
      return {...state, ancestryRelatedHorses: initialState.ancestryRelatedHorses};
    }
    case getAncestryRelatedHorsesActionTypes.SUCCESS: {
      return {...state, ancestryRelatedHorses: action.payload};
    }

    case getAncestryKinshipHorsesActionTypes.REQUEST: {
      return {...state, ancestryKinshipHorses: initialState.ancestryKinshipHorses};
    }
    case getAncestryKinshipHorsesActionTypes.SUCCESS: {
      return {...state, ancestryKinshipHorses: action.payload};
    }

    case getAncestryPcaReferenceDataActionTypes.REQUEST: {
      return {...state, ancestryPcaReferenceData: initialState.ancestryPcaReferenceData};
    }
    case getAncestryPcaReferenceDataActionTypes.SUCCESS: {
      return {...state, ancestryPcaReferenceData: action.payload};
    }

    case getAncestryHorsePrivacyActionTypes.SUCCESS: {
      if (!state.ancestryHorsePrivacy) {
        return {...state};
      }
      const {ancestryHorsePrivacy} = state;

      const newHorsePrivacy: IAncestryHorsePrivacy[] = ancestryHorsePrivacy;
      const newIndex = newHorsePrivacy.findIndex((x) => x.animalId === action.payload.animalId);

      if (newIndex !== -1) {
        newHorsePrivacy.splice(newIndex, 1);
      }

      newHorsePrivacy.push(action.payload);

      return {...state, ancestryHorsePrivacy: newHorsePrivacy};
    }

    default: {
      return state;
    }
  }
};
