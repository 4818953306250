import React, {useEffect, useState} from 'react';
import * as R from 'ramda';

import DictionaryFactory from 'DictionaryFactory/DictionaryFactory';
import SimpleDictionaryFactory from 'DictionaryFactory/SimpleDictionaryFactory';
import {IDictionary, ISimpleDictionary} from 'DictionaryFactory/types';
import {
  capitalizeNameFirstLetter,
  convertDictionaryToClient,
  convertDictionaryToClientWithImage,
  removeServerDictionaryFields,
} from 'Admin/AdminPhenotypes/service/converters/shared';
import {IUpdateHealthIssueRequest} from 'Admin/AdminPhenotypes/service/models/IHealthIssue';
import {IInconclusiveHealthIssue as AdminInconclusiveHealthIssue} from 'Admin/AdminPhenotypes/models/IInconclusiveHealthIssue';
import {IServerInconclusiveHealthIssue} from 'Admin/AdminPhenotypes/service/models/IInconclusiveHealthIssue';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {IHorseColor} from 'Dictionaries/models/IHorseColor';
import {IServerHorseColor} from 'Dictionaries/serverModel/IServerHorseColor';
import {IAdminGene} from 'Admin/AdminPhenotypes/models/IAdminGene';
import {IServerGene, IUpdateGeneRequest} from 'Admin/AdminPhenotypes/service/models/IGene';
import {IAdminGeneGroup} from 'Admin/AdminPhenotypes/models/IAdminGeneGroup';
import {IServerTrait, IUpdateTraitRequest} from 'Admin/AdminPhenotypes/service/models/IServerTrait';
import {IAdminTrait} from 'Admin/AdminPhenotypes/models/IAdminTrait';
import {
  IServerModifierCombination,
  IUpdateModifierCombinationRequest,
} from 'Admin/AdminPhenotypes/service/models/IModifierCombination';
import {IModifierCombination} from 'Admin/AdminPhenotypes/models/IModifierCombination';
import {IColorCombination} from 'Admin/AdminPhenotypes/models/IColorCombination';
import {
  IServerColorCombination,
  IUpdateColorCombinationRequest,
} from 'Admin/AdminPhenotypes/service/models/IColorCombination';
import {IGeneGroup} from 'Dictionaries/models/IGeneGroup';
import {IGene} from 'Dictionaries/models/IGene';
import {IAdminPartialColorGroup} from 'Admin/AdminPhenotypes/models/IAdminPartialColorGroup';
import {
  IServerPartialColorGroup,
  IUpdatePartialColorGroupRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerPartialColorGroup';
import {IPartialColorGroup} from 'Dictionaries/models/IPartialColorGroup';
import {
  IServerPartialColor,
  IUpdatePartialColorRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerPartialColor';
import {IAdminPartialColor} from 'Admin/AdminPhenotypes/models/IAdminPartialColor';
import {ITrait} from 'Dictionaries/models/ITrait';
import {IAdminHealthVariantGroup} from 'Admin/AdminPhenotypes/models/IAdminHealthVariantGroup';
import {
  IServerHealthVariantGroup,
  IUpdateHealthVariantGroupRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerHealthVariantGroup';
import {IHealthVariantGroupAdvanced} from 'Dictionaries/models/IHealthVariantGroupAdvanced';
import {IAdminHealthVariantRule} from 'Admin/AdminPhenotypes/models/IAdminHealthVariantRule';
import {
  IServerHealthVariantRule,
  IUpdateHealthVariantRuleRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerHealthVariantRule';
import {IDangerGroupAdvanced} from 'Dictionaries/models/IDangerGroupAdvanced';
import {IAdminDangerGroupAdvanced} from 'Admin/AdminPhenotypes/models/IAdminDangerGroupAdvanced';
import {IAdminDangerAdvanced} from 'Admin/AdminPhenotypes/models/IAdminDangerAdvanced';
import {
  IServerDangerGroupAdvanced,
  IUpdateDangerGroupAdvancedRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerDangerGroupAdvanced';
import {
  IServerDangerAdvanced,
  IUpdateDangerAdvancedRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerDangerAdvanced';
import {IAdminAbilityRule} from 'Admin/AdminPhenotypes/models/IAdminAbilityRule';
import {IAdminAbilityGroupAdvanced} from 'Admin/AdminPhenotypes/models/IAdminAbilityGroupAdvanced';
import {
  IServerAbilityGroupAdvanced,
  IUpdateAbilityGroupAdvancedRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerAbilityGroupAdvanced';
import {IServerAbilityRule, IUpdateAbilityRuleRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbilityRule';
import {IAbilityGroupAdvanced} from 'Dictionaries/models/IAbilityGroupAdvanced';
import {IAdminGenotype} from 'Admin/AdminPhenotypes/models/IAdminGenotype';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IColorModifier} from 'Dictionaries/models/IColorModifier';
import {ICommercialType} from 'Dictionaries/models/ICommercialType';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IHealthVariantToAvoid} from 'Dictionaries/models/IHealthVariantToAvoid';
import {IMarking} from 'Dictionaries/models/IMarking';
import {ITest} from 'Dictionaries/models/ITest';
import {sortByName} from 'Common/helpers/sortByName';
import {
  IServerGenotypeCombination,
  IUpdateGenotypeCombinationRequest,
} from 'Admin/AdminPhenotypes/service/models/IGenotypeCombination';
import {IGenotypeCombination} from 'Admin/AdminPhenotypes/models/IGenotypeCombination';
import {IGenotype, IServerGenotypeSimple} from 'Dictionaries/models/IGenotype';
import {IAdminAffectionType} from 'Admin/AdminPhenotypes/models/IAdminAffectionType';
import {IAdminHealthVariant} from 'Admin/AdminPhenotypes/models/IAdminHealthVariant';
import {
  IServerHealthVariant,
  IUpdateHealthVariantRequest,
} from 'Admin/AdminPhenotypes/service/models/IServerHealthVariant';
import {IAggregatedHealthVariant} from 'Dictionaries/models/IAggregatedHealthVariant';
import {IAffectionType} from 'Dictionaries/models/IAffectionType';
import {IAggregatedAbility} from 'Dictionaries/models/IAggregatedAbility';
import {IAdminAbility} from 'Admin/AdminPhenotypes/models/IAdminAbility';
import {IServerAbility, IUpdateAbilityRequest} from 'Admin/AdminPhenotypes/service/models/IServerAbility';
import {INotificationType} from 'Dictionaries/models/INotificationType';
import {IUpdateGenotypeRequest} from 'Admin/AdminPhenotypes/service/models/IServerGenotype';
import {IHealthVariant} from 'Dictionaries/models/IHealthVariant';
import {IBreedPanel} from 'Dictionaries/models/IBreedPanel';
import {IUpdateBreedPanelRequest} from 'Admin/AdminReference/services/models/IServerBreedPanel';
import {IColor, IShortColor} from 'Dictionaries/models/IColor';
import {IServerShortColor, IUpdateShortColorRequest} from 'Admin/AdminReference/services/models/IServerShortColor';
import {IAdminProduct} from 'Admin/AdminDashboard/models/IAdminProduct';
import {IServerProduct, IUpdateProductRequest} from 'Admin/AdminDashboard/services/models/IServerProduct';
import {convertProductToClient} from 'Admin/AdminDashboard/services/converters/product';
import {ISubscriptionProduct, ISubscriptionProductResponse} from 'StripeSubscription/models/ISubscriptionProduct';
import {convertStripeSubscriptionProductToClient} from 'StripeSubscription/service/converters/product';
import {apiParamsConverterByMethod} from 'DictionaryFactory/shared/apiParamsConverter';
import {INamedEntity} from 'Common/models/INamedEntity';
import {IPackageSimple} from 'Dictionaries/models/IPackageSimple';
import {IPermissionDomain} from 'Dictionaries/models/IPermissionDomain';
import {IPermission} from 'Permissions/model/IPermission';
import {IFoundOrganization} from 'Admin/AdminAssociations/models/IFoundOrganization';
import {IReportType} from 'Dictionaries/models/IReportType';
import {IPackageGroup} from 'Dictionaries/models/IPackageGroup';
import {IUpdatePackageGroupRequest} from 'Admin/AdminReference/services/models/IServerPackageGroup';
import {IAdminItem} from 'Admin/shared/models/IAdminItem';
import {convertAdminItemList} from 'Admin/shared/helpers/converters/shared';
import {IUserNotificationEvent} from 'Notifications/models/IUserNotificationEvent';
import {IProductSimple} from '../../Dictionaries/models/IProductSimple';

const dictionaries = {} as IDictionaryContext;

const asyncDictionaries: Promise<IDictionary<any, any>[]> = Promise.all([
  DictionaryFactory.create<IAssociation, IAssociation, IAssociation>({
    rootType: '@@ADMIN_ASSOCIATIONS',
    apiPath: '/AssociationsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminAssociation = d)),
  DictionaryFactory.create<IShortColor, IServerShortColor, IUpdateShortColorRequest>({
    rootType: '@@ADMIN_COLORS',
    apiPath: '/ColorsTable',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminColors = d)),
  DictionaryFactory.create<IBreed, IBreed, IBreed>({
    rootType: '@@ADMIN_BREEDS',
    apiPath: '/BreedsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminBreed = d)),
  DictionaryFactory.create<IBreedPanel, IBreedPanel, IUpdateBreedPanelRequest>({
    rootType: '@@ADMIN_BREED_PANELS',
    apiPath: '/PanelsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminBreedPanels = d)),
  DictionaryFactory.create<IDiscipline, IDiscipline, IDiscipline>({
    rootType: '@@ADMIN_DISCIPLINES',
    apiPath: '/DisciplinesTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminDiscipline = d)),
  DictionaryFactory.create<ICommercialType, ICommercialType, ICommercialType>({
    rootType: '@@ADMIN_COMMERCIAL_TYPES',
    apiPath: '/CommercialTypesTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminCommercialType = d)),
  DictionaryFactory.create<IMarking, IMarking, IMarking>({
    rootType: '@@ADMIN_MARKINGS',
    apiPath: '/MarkingsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminMarking = d)),
  DictionaryFactory.create<ITest, ITest, ITest>({
    rootType: '@@ADMIN_TESTS',
    apiPath: '/TestsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminTest = d)),

  DictionaryFactory.create<IAdminAffectionType, IAdminAffectionType, IAdminAffectionType>({
    rootType: '@@ADMIN_AFFECTION_TYPE',
    apiPath: '/AffectionTypeTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminAffectionType = d)),
  DictionaryFactory.create<IInconclusiveHealthIssue, IServerInconclusiveHealthIssue, IUpdateHealthIssueRequest>({
    rootType: '@@ADMIN_INCONCLUSIVE_HEALTH_ISSUE',
    apiPath: '/InconclusiveHealthIssuesTable',
    convertToClient: removeServerDictionaryFields,
  }).then((d) => (dictionaries.adminInconclusiveHealthIssue = d)),
  DictionaryFactory.create<IAdminGene, IServerGene, IUpdateGeneRequest>({
    rootType: '@@ADMIN_GENE',
    apiPath: '/GenesTable',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminGene = d)),
  DictionaryFactory.create<IAdminGeneGroup, IAdminGeneGroup, IAdminGeneGroup>({
    rootType: '@@ADMIN_GENE_GROUP',
    apiPath: '/GeneGroupsTable',
    convertToClient: convertDictionaryToClientWithImage,
  }).then((d) => (dictionaries.adminGeneGroup = d)),
  DictionaryFactory.create<IAdminGenotype, IAdminGenotype, IUpdateGenotypeRequest>({
    rootType: '@@ADMIN_GENOTYPE',
    apiPath: '/GenotypesTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminGenotype = d)),
  DictionaryFactory.create<IGenotypeCombination, IServerGenotypeCombination, IUpdateGenotypeCombinationRequest>({
    rootType: '@@ADMIN_GENOTYPE_COMBINATION',
    apiPath: '/GenotypesCombinationsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminGenotypeCombination = d)),
  DictionaryFactory.create<IAdminTrait, IServerTrait, IUpdateTraitRequest>({
    rootType: '@@ADMIN_TRAIT',
    apiPath: '/TraitsTable',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminTrait = d)),
  DictionaryFactory.create<IModifierCombination, IServerModifierCombination, IUpdateModifierCombinationRequest>({
    rootType: '@@ADMIN_MODIFIER_COMBINATION',
    apiPath: '/ModifierCombinationsTable',
    convertToClient: R.compose<IServerModifierCombination, IServerModifierCombination, IModifierCombination>(
      convertDictionaryToClient,
      convertDictionaryToClientWithImage
    ),
  }).then((d) => (dictionaries.adminModifierCombination = d)),
  DictionaryFactory.create<IColorCombination, IServerColorCombination, IUpdateColorCombinationRequest>({
    rootType: '@@ADMIN_COLOR_COMBINATION',
    apiPath: '/ColorCombinationsTable',
    convertToClient: R.compose<IServerColorCombination, IServerColorCombination, IColorCombination>(
      convertDictionaryToClient,
      convertDictionaryToClientWithImage
    ),
  }).then((d) => (dictionaries.adminColorCombination = d)),
  DictionaryFactory.create<IAdminPartialColorGroup, IServerPartialColorGroup, IUpdatePartialColorGroupRequest>({
    rootType: '@@ADMIN_PARTIAL_COLOR_GROUP',
    apiPath: '/PartialColorGroupsTable',
    convertToClient: R.compose<IServerPartialColorGroup, IServerPartialColorGroup, IAdminPartialColorGroup>(
      convertDictionaryToClient,
      convertDictionaryToClientWithImage
    ),
  }).then((d) => (dictionaries.adminPartialColorGroup = d)),
  DictionaryFactory.create<IAdminPartialColor, IServerPartialColor, IUpdatePartialColorRequest>({
    rootType: '@@ADMIN_PARTIAL_COLOR',
    apiPath: '/PartialColorsTable',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminPartialColor = d)),
  DictionaryFactory.create<IAdminHealthVariant, IServerHealthVariant, IUpdateHealthVariantRequest>({
    rootType: '@@ADMIN_HEALTH_VARIANTS',
    apiPath: '/AggregatedHealthIssue',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminHealthVariants = d)),
  DictionaryFactory.create<IAdminHealthVariantGroup, IServerHealthVariantGroup, IUpdateHealthVariantGroupRequest>({
    rootType: '@@ADMIN_HEALTH_VARIANT_GROUP',
    apiPath: '/HealthIssueGroupAdvanced',
    convertToClient: R.compose<IServerHealthVariantGroup, IServerHealthVariantGroup, IAdminHealthVariantGroup>(
      convertDictionaryToClient,
      convertDictionaryToClientWithImage
    ),
  }).then((d) => (dictionaries.adminHealthVariantGroup = d)),
  DictionaryFactory.create<IAdminHealthVariantRule, IServerHealthVariantRule, IUpdateHealthVariantRuleRequest>({
    rootType: '@@ADMIN_HEALTH_VARIANT_RULES',
    apiPath: '/AdvancedHealthIssue',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminHealthVariantRules = d)),
  DictionaryFactory.create<IAdminDangerGroupAdvanced, IServerDangerGroupAdvanced, IUpdateDangerGroupAdvancedRequest>({
    rootType: '@@ADMIN_DANGER_GROUP_ADVANCED',
    apiPath: '/DangerGroupAdvanced',
    convertToClient: R.compose<IServerDangerGroupAdvanced, IServerDangerGroupAdvanced, IAdminDangerGroupAdvanced>(
      convertDictionaryToClient,
      convertDictionaryToClientWithImage
    ),
  }).then((d) => (dictionaries.adminDangerGroupAdvanced = d)),
  DictionaryFactory.create<IAdminDangerAdvanced, IServerDangerAdvanced, IUpdateDangerAdvancedRequest>({
    rootType: '@@ADMIN_ADVANCED_DANGER',
    apiPath: '/AdvancedDanger',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminDangerAdvanced = d)),
  DictionaryFactory.create<IAdminAbility, IServerAbility, IUpdateAbilityRequest>({
    rootType: '@@ADMIN_ABILITIES',
    apiPath: '/AggregatedAbility',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminAbilities = d)),
  DictionaryFactory.create<IAdminAbilityGroupAdvanced, IServerAbilityGroupAdvanced, IUpdateAbilityGroupAdvancedRequest>(
    {
      rootType: '@@ADMIN_ABILITY_GROUP_ADVANCED',
      apiPath: '/AbilityGroupAdvanced',
      convertToClient: R.compose<IServerAbilityGroupAdvanced, IServerAbilityGroupAdvanced, IAdminAbilityGroupAdvanced>(
        convertDictionaryToClient,
        convertDictionaryToClientWithImage
      ),
    }
  ).then((d) => (dictionaries.adminAbilityGroupAdvanced = d)),
  DictionaryFactory.create<IAdminAbilityRule, IServerAbilityRule, IUpdateAbilityRuleRequest>({
    rootType: '@@ADMIN_ABILITY_RULES',
    apiPath: '/AdvancedAbility',
    convertToClient: convertDictionaryToClient,
  }).then((d) => (dictionaries.adminAbilityRules = d)),
  DictionaryFactory.create<IAdminProduct, IServerProduct, IUpdateProductRequest>({
    rootType: '@@ADMIN_STRIPE_SUBSCRIPTION_PRODUCTS',
    apiPath: '/StripeProductAdmin',
    convertToClient: convertProductToClient,
    customApiParamsConverter: apiParamsConverterByMethod,
  }).then((d) => (dictionaries.adminProducts = d)),
  DictionaryFactory.create<IPackageGroup, IPackageGroup, IUpdatePackageGroupRequest>({
    rootType: '@@ADMIN_PACKAGE_GROUPS',
    apiPath: '/PackageGroupsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminPackageGroups = d)),
  DictionaryFactory.create<IPackageGroup, IPackageGroup, IUpdatePackageGroupRequest>({
    rootType: '@@ADMIN_ASSOCIATION_PACKAGE_GROUPS',
    apiPath: '/AssociationPackageGroupsTable',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminAssociationPackageGroups = d)),
]);

const asyncSimpleDictionaries = Promise.all([
  SimpleDictionaryFactory.create<IAggregatedAbility, IAggregatedAbility>({
    rootType: '@@AGGREGATED_ABILITIES',
    apiPath: '/AggregatedAbilitieItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.aggregatedAbilities = d)),
  SimpleDictionaryFactory.create<IAbilities, IAbilities>({
    rootType: '@@ABILITIES',
    apiPath: '/AbilityItemList',
    convertToClient: capitalizeNameFirstLetter,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.abilities = d)),
  SimpleDictionaryFactory.create<IAssociation, IAssociation>({
    rootType: '@@ASSOCIATIONS',
    apiPath: '/AssociationItemList',
    convertToClient: (r) => r,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.associations = d)),
  SimpleDictionaryFactory.create<IBreed, IBreed>({
    rootType: '@@BREEDS',
    apiPath: '/BreedItemList',
    convertToClient: capitalizeNameFirstLetter,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.breeds = d)),
  SimpleDictionaryFactory.create<IColor, IColor>({
    rootType: '@@COLORS',
    apiPath: '/ColorCombinationItemList',
    convertToClient: capitalizeNameFirstLetter,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.colors = d)),
  SimpleDictionaryFactory.create<IColorModifier, IColorModifier>({
    rootType: '@@COLOR_MODIFIERS',
    apiPath: '/ModifierCombinationItemList',
    convertToClient: (r) => r,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.colorModifiers = d)),
  SimpleDictionaryFactory.create<ICommercialType, ICommercialType>({
    rootType: '@@COMMERCIAL_TYPES',
    apiPath: '/CommercialTypeItemList',
    convertToClient: capitalizeNameFirstLetter,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.commercialTypes = d)),
  SimpleDictionaryFactory.create<IDiscipline, IDiscipline>({
    rootType: '@@DISCIPLINES',
    apiPath: '/DisciplineItemList',
    convertToClient: capitalizeNameFirstLetter,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.disciplines = d)),
  SimpleDictionaryFactory.create<IMarking, IMarking>({
    rootType: '@@MARKINGS',
    apiPath: '/MarkingItemList',
    convertToClient: capitalizeNameFirstLetter,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.markings = d)),
  SimpleDictionaryFactory.create<ITest, ITest>({
    rootType: '@@TESTS',
    apiPath: '/TestItemList',
    convertToClient: capitalizeNameFirstLetter,
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.tests = d)),

  SimpleDictionaryFactory.create<IAffectionType, IAffectionType>({
    rootType: '@@AFFECTION_TYPE',
    apiPath: '/AffectionTypeItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.affectionTypes = d)),
  SimpleDictionaryFactory.create<IInconclusiveHealthIssue, IServerInconclusiveHealthIssue>({
    rootType: '@@INCONCLUSIVE_HEALTH_ISSUE',
    apiPath: '/InconclusiveHealthIssueItemList',
    convertToClient: removeServerDictionaryFields,
  }).then((d) => (dictionaries.inconclusiveHealthIssue = d)),
  SimpleDictionaryFactory.create<IHorseColor, IServerHorseColor>({
    rootType: '@@HORSE_COLORS',
    apiPath: '/ColorItemList',
    convertToClient: (r) => capitalizeNameFirstLetter(removeServerDictionaryFields(r)),
    convertListToClient: (list) => list.sort(sortByName),
  }).then((d) => (dictionaries.horseColors = d)),
  SimpleDictionaryFactory.create<IReportType, IReportType>({
    rootType: '@@REPORT_TYPES',
    apiPath: '/ReportTypeItemList',
    convertToClient: (r) => r,
    isRememberValues: true,
  }).then((d) => (dictionaries.reportTypes = d)),
  SimpleDictionaryFactory.create<IGeneGroup, IGeneGroup>({
    rootType: '@@GENE_GROUP',
    apiPath: '/GeneGroupItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.geneGroups = d)),
  SimpleDictionaryFactory.create<IGene, IGene>({
    rootType: '@@GENE',
    apiPath: '/GeneItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.genes = d)),
  SimpleDictionaryFactory.create<IGenotype, IServerGenotypeSimple>({
    rootType: '@@GENOTYPE',
    apiPath: '/GenotypeItemList',
    convertToClient: (r) => ({...r, name: `${r.testName} (${r.alleleNames})`}),
  }).then((d) => (dictionaries.genotypes = d)),
  SimpleDictionaryFactory.create<IPartialColorGroup, IPartialColorGroup>({
    rootType: '@@PARTIAL_COLOR_GROUP',
    apiPath: '/PartialColorGroupItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.partialColorGroups = d)),
  SimpleDictionaryFactory.create<ITrait, ITrait>({
    rootType: '@@TRAIT',
    apiPath: '/TraitItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.traits = d)),
  SimpleDictionaryFactory.create<IAggregatedHealthVariant, IAggregatedHealthVariant>({
    rootType: '@@AGGREGATED_HEALTH_VARIANTS',
    apiPath: '/AggregatedHealthIssueItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.aggregatedHealthVariants = d)),
  SimpleDictionaryFactory.create<IHealthVariant, IHealthVariant>({
    rootType: '@@HEALTH_VARIANTS',
    apiPath: '/HealthIssueItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.healthVariants = d)),
  SimpleDictionaryFactory.create<IHealthVariantToAvoid, IHealthVariantToAvoid>({
    rootType: '@@HEALTH_VARIANTS',
    apiPath: '/HealthIssuesToAvoidItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.healthVariantsToAvoid = d)),
  SimpleDictionaryFactory.create<IDangerGroupAdvanced, IDangerGroupAdvanced>({
    rootType: '@@DANGER_GROUP_ADVANCED',
    apiPath: '/DangerGroupItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.dangerGroupsAdvanced = d)),
  SimpleDictionaryFactory.create<IHealthVariantGroupAdvanced, IHealthVariantGroupAdvanced>({
    rootType: '@@HEALTH_ISSUE_GROUP_ADVANCED',
    apiPath: '/HealthIssueGroupItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.healthIssueGroupsAdvanced = d)),
  SimpleDictionaryFactory.create<IDangerGroupAdvanced, IDangerGroupAdvanced>({
    rootType: '@@ABILITY_GROUP_ADVANCED',
    apiPath: '/AbilityGroupItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.abilityGroupsAdvanced = d)),
  SimpleDictionaryFactory.create<INotificationType, INotificationType>({
    rootType: '@@NOTIFICATION_TYPE',
    apiPath: '/NotificationTypeItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.notificationTypes = d)),
  SimpleDictionaryFactory.create<ISubscriptionProduct, ISubscriptionProductResponse>({
    rootType: '@@STRIPE_SUBSCRIPTION_PRODUCTS',
    apiPath: '/StripeSubscriptionProductItemList',
    convertToClient: convertStripeSubscriptionProductToClient,
  }).then((d) => (dictionaries.stripeSubscriptionProducts = d)),
  SimpleDictionaryFactory.create<IPackageSimple, IPackageSimple>({
    rootType: '@@PACKAGES',
    apiPath: '/PackageItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.packages = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@PACKAGES',
    apiPath: '/ActivePackageItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.activePackages = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@PRODUCTS',
    apiPath: '/ProductList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.products = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@PRODUCTS',
    apiPath: '/ActiveProductList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.activeProducts = d)),
  SimpleDictionaryFactory.create<IPermissionDomain, IPermissionDomain>({
    rootType: '@@PERMISSION_DOMAINS',
    apiPath: '/AdminPermissionDomainItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.permissionDomains = d)),
  SimpleDictionaryFactory.create<IPermission, IPermission>({
    rootType: '@@PERMISSIONS',
    apiPath: '/PermissionItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.permissions = d)),
  SimpleDictionaryFactory.create<IFoundOrganization, IFoundOrganization>({
    rootType: '@@ADMIN_ORGANIZATIONS',
    apiPath: '/OrganizationByNameOrAbbreviationAdmin',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.adminOrganizations = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@ASSOCIATION_ALL_ACTIVE_PACKAGES_ADMIN',
    apiPath: '/AssociationAllActivePackagesAdmin',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.associationAllActivePackagesAdmin = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@ASSOCIATION_ACTIVE_PACKAGES_BY_ORGANIZATION_ADMIN',
    apiPath: '/AssociationActivePackagesAdmin',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.associationActivePackagesAdmin = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@ASSOCIATION_ACTIVE_TESTS_ADMIN',
    apiPath: '/AssociationAllActivePurchasableTestsAdmin',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.associationAllActivePurchasableTestsAdmin = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@ASSOCIATION_ACTIVE_TESTS_BY_ORGANIZATION_ADMIN',
    apiPath: '/AssociationActivePurchasableTestsAdmin',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.associationActivePurchasableTestsAdmin = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@ASSOCIATION_ACTIVE_PACKAGES',
    apiPath: '/AssociationActivePackages',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.associationActivePackages = d)),
  SimpleDictionaryFactory.create<INamedEntity, INamedEntity>({
    rootType: '@@ASSOCIATION_ACTIVE_TESTS',
    apiPath: '/AssociationActivePurchasableTests',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.associationActivePurchasableTests = d)),
  SimpleDictionaryFactory.create<IPackageGroup, IPackageGroup>({
    rootType: '@@PACKAGE_GROUPS',
    apiPath: '/PackageGroupItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.packageGroups = d)),
  SimpleDictionaryFactory.create<IPackageGroup, IPackageGroup>({
    rootType: '@@ASSOCAITION_PACKAGE_GROUPS',
    apiPath: '/AssociationPackageGroupItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.associationPackageGroups = d)),
  SimpleDictionaryFactory.create<IAdminItem, IAdminItem>({
    rootType: '@@ADMIN_ITEM_LIST',
    apiPath: '/AdminItemListRead',
    convertToClient: convertAdminItemList,
  }).then((d) => (dictionaries.adminItemList = d)),
  SimpleDictionaryFactory.create<IUserNotificationEvent, IUserNotificationEvent>({
    rootType: '@@USER_NOTIFICATION_EVENT_ITEM_LIST',
    apiPath: '/UserNotificationEventItemList',
    convertToClient: (r) => r,
  }).then((d) => (dictionaries.userNotificationEvents = d)),
]);

interface IDictionaryContext {
  adminAffectionType: IDictionary<IAdminAffectionType, IAdminAffectionType>;
  adminAssociation: IDictionary<IAssociation, IAssociation>;
  adminColors: IDictionary<IShortColor, IUpdateShortColorRequest>;
  adminBreed: IDictionary<IBreed, IBreed>;
  adminDiscipline: IDictionary<IDiscipline, IDiscipline>;
  adminCommercialType: IDictionary<ICommercialType, ICommercialType>;
  adminMarking: IDictionary<IMarking, IMarking>;
  adminTest: IDictionary<ITest, ITest>;

  adminInconclusiveHealthIssue: IDictionary<AdminInconclusiveHealthIssue, IUpdateHealthIssueRequest>;
  adminGene: IDictionary<IAdminGene, IUpdateGeneRequest>;
  adminGeneGroup: IDictionary<IAdminGeneGroup, IAdminGeneGroup>;
  adminGenotype: IDictionary<IAdminGenotype, IUpdateGenotypeRequest>;
  adminGenotypeCombination: IDictionary<IGenotypeCombination, IUpdateGenotypeCombinationRequest>;
  adminTrait: IDictionary<IAdminTrait, IUpdateTraitRequest>;
  adminModifierCombination: IDictionary<IModifierCombination, IUpdateModifierCombinationRequest>;
  adminColorCombination: IDictionary<IColorCombination, IUpdateColorCombinationRequest>;
  adminPartialColorGroup: IDictionary<IAdminPartialColorGroup, IUpdatePartialColorGroupRequest>;
  adminPartialColor: IDictionary<IAdminPartialColor, IUpdatePartialColorRequest>;
  adminHealthVariants: IDictionary<IAdminHealthVariant, IUpdateHealthVariantRequest>;
  adminHealthVariantGroup: IDictionary<IAdminHealthVariantGroup, IUpdateHealthVariantGroupRequest>;
  adminHealthVariantRules: IDictionary<IAdminHealthVariantRule, IUpdateHealthVariantRuleRequest>;
  adminDangerGroupAdvanced: IDictionary<IAdminDangerGroupAdvanced, IUpdateDangerGroupAdvancedRequest>;
  adminDangerAdvanced: IDictionary<IAdminDangerAdvanced, IUpdateDangerAdvancedRequest>;
  adminAbilities: IDictionary<IAdminAbility, IUpdateAbilityRequest>;
  adminAbilityGroupAdvanced: IDictionary<IAdminAbilityGroupAdvanced, IUpdateAbilityGroupAdvancedRequest>;
  adminAbilityRules: IDictionary<IAdminAbilityRule, IUpdateAbilityRuleRequest>;
  adminBreedPanels: IDictionary<IBreedPanel, IUpdateBreedPanelRequest>;
  adminProducts: IDictionary<IAdminProduct, IUpdateProductRequest>;
  adminPackageGroups: IDictionary<IPackageGroup, IUpdatePackageGroupRequest>;
  adminAssociationPackageGroups: IDictionary<IPackageGroup, IUpdatePackageGroupRequest>;

  abilities: ISimpleDictionary<IAbilities>;
  affectionTypes: ISimpleDictionary<IAffectionType>;
  associations: ISimpleDictionary<IAssociation>;
  breeds: ISimpleDictionary<IBreed>;
  colors: ISimpleDictionary<IColor>;
  colorModifiers: ISimpleDictionary<IColorModifier>;
  commercialTypes: ISimpleDictionary<ICommercialType>;
  disciplines: ISimpleDictionary<IDiscipline>;
  healthVariants: ISimpleDictionary<IHealthVariant>;
  healthVariantsToAvoid: ISimpleDictionary<IHealthVariantToAvoid>;
  aggregatedHealthVariants: ISimpleDictionary<IAggregatedHealthVariant>;
  aggregatedAbilities: ISimpleDictionary<IAggregatedAbility>;
  markings: ISimpleDictionary<IMarking>;
  tests: ISimpleDictionary<ITest>;
  inconclusiveHealthIssue: ISimpleDictionary<IInconclusiveHealthIssue>;
  horseColors: ISimpleDictionary<IHorseColor>;
  reportTypes: ISimpleDictionary<IReportType>;
  geneGroups: ISimpleDictionary<IGeneGroup>;
  genes: ISimpleDictionary<IGene>;
  genotypes: ISimpleDictionary<IGenotype>;
  partialColorGroups: ISimpleDictionary<IPartialColorGroup>;
  traits: ISimpleDictionary<ITrait>;
  healthIssueGroupsAdvanced: ISimpleDictionary<IHealthVariantGroupAdvanced>;
  dangerGroupsAdvanced: ISimpleDictionary<IDangerGroupAdvanced>;
  abilityGroupsAdvanced: ISimpleDictionary<IAbilityGroupAdvanced>;
  notificationTypes: ISimpleDictionary<INotificationType>;
  stripeSubscriptionProducts: ISimpleDictionary<ISubscriptionProduct>;
  packages: ISimpleDictionary<IPackageSimple>;
  activePackages: ISimpleDictionary<IPackageSimple>;
  products: ISimpleDictionary<IProductSimple>;
  activeProducts: ISimpleDictionary<IProductSimple>;
  permissionDomains: ISimpleDictionary<IPermissionDomain>;
  permissions: ISimpleDictionary<IPermission>;
  adminOrganizations: ISimpleDictionary<IFoundOrganization>;
  associationActivePackagesAdmin: ISimpleDictionary<INamedEntity>;
  associationAllActivePackagesAdmin: ISimpleDictionary<INamedEntity>;
  associationActivePurchasableTestsAdmin: ISimpleDictionary<INamedEntity>;
  associationAllActivePurchasableTestsAdmin: ISimpleDictionary<INamedEntity>;
  packageGroups: ISimpleDictionary<IPackageGroup>;
  adminItemList: ISimpleDictionary<IAdminItem>;
  userNotificationEvents: ISimpleDictionary<IUserNotificationEvent>;

  //business portal
  associationActivePackages: ISimpleDictionary<INamedEntity>;
  associationActivePurchasableTests: ISimpleDictionary<INamedEntity>;
  associationPackageGroups: ISimpleDictionary<IPackageGroup>;
}

export const DictionaryContext = React.createContext<IDictionaryContext>({} as IDictionaryContext);

export default function DictionariesProvider({children}: React.PropsWithChildren<{}>) {
  const [, updateState] = useState<{}>();

  useEffect(() => {
    asyncDictionaries.then(() => asyncSimpleDictionaries.then(() => updateState({})));
  }, []);

  return <DictionaryContext.Provider value={dictionaries}>{children}</DictionaryContext.Provider>;
}

export function useDictionaries() {
  return React.useContext(DictionaryContext);
}
