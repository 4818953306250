import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import ImageService from 'Image/services/ImageService';
import {IAppState} from 'Common/store/IAppState';
import {selectors as permissionsSelectors} from 'Permissions/store';

import {uploadImageActionTypes, deleteImageActionTypes, ImageActions, getImageActionTypes} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getImage =
  (imageId: number): ActionResult<IAppState, void, ImageActions> =>
  async (dispatch) => {
    dispatch(action(getImageActionTypes.REQUEST));
    try {
      const image = await ImageService.getImage(imageId);
      dispatch(action(getImageActionTypes.SUCCESS, image));
    } catch (err) {
      const error = getErrorMessage(err);
      dispatch(action(getImageActionTypes.FAILURE, error));
      throw error;
    }
  };

export const uploadImage =
  (uploadedFile: FormData, isAdmin?: boolean): ActionResult<IAppState, Promise<number>, ImageActions> =>
  async (dispatch, getState) => {
    dispatch(action(uploadImageActionTypes.REQUEST));
    try {
      const imageId = await ImageService.uploadImage(uploadedFile, isAdmin);
      dispatch(action(uploadImageActionTypes.SUCCESS, imageId));
      return imageId;
    } catch (err) {
      const error = getErrorMessage(err);
      dispatch(action(uploadImageActionTypes.FAILURE, error));
      throw error;
    }
  };

export const deleteImage =
  (imageId: number): ActionResult<IAppState, void, ImageActions> =>
  async (dispatch, getState) => {
    dispatch(action(deleteImageActionTypes.REQUEST));
    try {
      const isAdmin = permissionsSelectors.selectIsUserAdmin(getState());
      await ImageService.deleteImage(imageId, isAdmin);
      dispatch(action(deleteImageActionTypes.SUCCESS, {imageId}));
    } catch (err) {
      const error = getErrorMessage(err);
      dispatch(action(deleteImageActionTypes.FAILURE, error));
      throw error;
    }
  };
