import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import {actions, selectors} from 'MailConfirmation/store/confirmMail';
import {IMailConfirmation} from 'MailConfirmation/models/IMailConfirmation';
import {MailConfirmationModule} from 'MailConfirmation/store/confirmMail/mailConfirmationModule';
import ContainerWrapper from 'Common/components/Layout/ContainerWrapper';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';

import IconedLoading from 'Common/components/IconedLoading/IconedLoading';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {parseUrlParams} from 'Common/helpers/url';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {isEmailConfirmationExpired} from 'MailConfirmation/helpers/mailConfirmationErrors';
import useServiceMode from 'Maintain/hooks/useServiceMode';
import {ServiceMode} from 'Maintain/models/IServiceMode';
import withDynamicModules from 'Common/helpers/withDynamicModules';

const UNKNOWN_ERROR = 'There is an error on email confirmation';
const EMAIL_CONFIRMED = 'Email successfully confirmed';

interface IQueryParams extends IMailConfirmation {
  email: string;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

function MailConfirmation(props: AllProps) {
  const {confirmEmail, emailConfirming, successConfirmMessage} = props;
  const confirmationError = emailConfirming.error;

  const successMessage = successConfirmMessage || EMAIL_CONFIRMED;

  const location = useLocation();
  const navigate = useNavigate();

  const {getServiceModeStatus, isServiceModeLoading} = useServiceMode();

  const isLoading = emailConfirming.isRequesting || isServiceModeLoading;

  const {userId, code, email} = parseUrlParams<IQueryParams>(location.search);

  useEffect(() => {
    const fetch = async () => {
      const status = await getServiceModeStatus();
      if (status === ServiceMode.UsersOff) {
        return;
      }
      const confirmationRequest = {userId, code};
      confirmEmail(confirmationRequest);
    };

    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnErrorCommunication(emailConfirming, () => {
    if (confirmationError && isEmailConfirmationExpired(confirmationError)) {
      navigate(`/email-link-expired/${email}`);
    }
  });

  const error = confirmationError ? getCommonErrors(confirmationError) || UNKNOWN_ERROR : null;

  return (
    <BaseLayout isBackButtonDenied={true}>
      <ContainerWrapper className="d-flex flex-column justify-content-center align-items-center">
        {isLoading && (
          <div className="d-flex flex-column">
            <IconedLoading />
          </div>
        )}
        {!error && !isLoading && (
          <div className="d-flex flex-column">
            <h1>{successMessage}</h1>
          </div>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ContainerWrapper>
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  successConfirmMessage: selectors.selectSuccessConfirmEmailMessage(state),
  emailConfirming: selectors.selectCommunication(state, 'messageLoading'),
});

const mapDispatchToProps = {
  confirmEmail: actions.mailConfirm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(MailConfirmation));

export default withDynamicModules(Connected, MailConfirmationModule);
