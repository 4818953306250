import {Moment} from 'moment';
import {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {DateTime} from 'Common/components/Controls';
import {DateTimeProps} from 'Common/components/Controls/DateTime';
import {FRONTEND_DATE, FRONTEND_DATE_TIME} from 'Common/constants/Date';
import {convertToClientDate, convertToServerDate, convertUTCToClientDate, isValidDate} from 'Common/helpers/DateHelper';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import {IFacetProps} from '../Facet';
import {OperationDropDown} from '../parts/OperationDropDown';
import {Title} from '../parts/styled';

const INRANGE_OPERATION = 'InRange';

const dateTimeStyle = {height: 28};

const FilterDateTime = styled(DateTime)`
  width: 160px;
  margin-bottom: 0;

  input {
    height: 28px;
  }

  svg {
    top: 2px;
  }
`;

interface IDateRange {
  fromDate: string;
  toDate: string;
}

interface IProps extends IFacetProps, Omit<DateTimeProps, 'value' | 'onChange'> {}

function DateTimeFacet(props: IProps) {
  const {
    filterProperty,
    filterPropertyOperations,
    filterRequest = {
      property: filterProperty.property,
      operation: filterPropertyOperations?.operations[0].operation || '',
      value: '',
    },
    onSuccess,
    showTime,
    dateFormat,
    futureEnabled,
  } = props;

  const [dateTimeValue, setDateTimeValue] = useState<string | Moment>('');
  const [fromValue, setFromValue] = useState<string | Moment>('');
  const [toValue, setToValue] = useState<string | Moment>('');
  const [request, setRequest] = useState<IFilterRequest>(filterRequest);
  const [isRange, setIsRange] = useState(false);

  const convertDate = useCallback(
    (date: Moment | string) => {
      const dateFormatConvertTo = showTime ? FRONTEND_DATE_TIME : dateFormat ? String(dateFormat) : FRONTEND_DATE;
      const value =
        date && isValidDate(date, dateFormatConvertTo, futureEnabled) ? convertToClientDate(date) || '' : '';

      return value;
    },
    [dateFormat, futureEnabled, showTime]
  );

  useEffect(() => {
    setRequest(filterRequest);
    if (filterRequest.operation === INRANGE_OPERATION) {
      const value = JSON.parse(filterRequest?.value) as IDateRange;

      const newFromValue = value.fromDate ? convertUTCToClientDate(value.fromDate) || '' : '';
      const newToValue = value.toDate ? convertUTCToClientDate(value.toDate) || '' : '';

      setFromValue(newFromValue);
      setToValue(newToValue);
    } else {
      const value = filterRequest?.value ? convertUTCToClientDate(filterRequest?.value) || '' : '';
      setDateTimeValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (request.value) {
      onSuccess(request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const onChange = useCallback(
    (date: Moment | string) => {
      const value = convertDate(date);
      setDateTimeValue(value);

      const requestValue = convertToServerDate(value) || '';
      setRequest({...request, value: requestValue});
    },
    [convertDate, request]
  );

  const onFromChange = useCallback(
    (date: Moment | string) => {
      const value = convertDate(date);
      setFromValue(value);

      const requestFromValue = convertToServerDate(value) || '';
      const requestToValue = convertToServerDate(toValue) || '';

      const newValue: IDateRange = {
        fromDate: requestFromValue,
        toDate: requestToValue,
      };

      setRequest({...request, operation: INRANGE_OPERATION, value: JSON.stringify(newValue)});
    },
    [convertDate, request, toValue]
  );

  const onToChange = useCallback(
    (date: Moment | string) => {
      const value = convertDate(date);
      setToValue(value);

      const requestToValue = convertToServerDate(value) || '';
      const requestFromValue = convertToServerDate(fromValue) || '';

      const newValue: IDateRange = {
        fromDate: requestFromValue,
        toDate: requestToValue,
      };

      setRequest({...request, operation: INRANGE_OPERATION, value: JSON.stringify(newValue)});
    },
    [convertDate, fromValue, request]
  );

  const onOperationSelect = useCallback(
    (operation: string) => {
      const isInRange = operation === INRANGE_OPERATION;
      if (!isInRange) {
        const requestValue = convertToServerDate(dateTimeValue) || '';
        setRequest({...request, operation, value: requestValue});
      } else {
        if (fromValue && toValue) {
          const requestToValue = convertToServerDate(toValue) || '';
          const requestFromValue = convertToServerDate(fromValue) || '';

          const newValue: IDateRange = {
            fromDate: requestFromValue,
            toDate: requestToValue,
          };

          setRequest({...request, operation: INRANGE_OPERATION, value: JSON.stringify(newValue)});
        }
      }
      setIsRange(isInRange);
    },
    [dateTimeValue, fromValue, request, toValue]
  );

  return (
    <>
      <Title>{filterProperty.title}</Title>
      {filterPropertyOperations && (
        <OperationDropDown
          filterOperations={filterPropertyOperations}
          onSelect={onOperationSelect}
          defaultOperation={filterRequest.operation}
        />
      )}

      {isRange && (
        <>
          from
          <FilterDateTime value={fromValue} onChange={onFromChange} inputStyle={dateTimeStyle} />
          to
          <FilterDateTime value={toValue} onChange={onToChange} inputStyle={dateTimeStyle} />
        </>
      )}

      {!isRange && <FilterDateTime value={dateTimeValue} onChange={onChange} inputStyle={dateTimeStyle} />}
    </>
  );
}

export default memo(DateTimeFacet);
