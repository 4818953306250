import React, {memo} from 'react';
import styled from 'styled-components';

import CheckboxField from 'Common/components/FormFields/CheckboxField';
import {FieldHint, TextBase} from 'BusinessPortal/components/common/styled';
import Typography from 'Common/constants/Typography';

const fieldHintStyles = {marginLeft: 32, marginBottom: 16};

const Section = styled(TextBase)`
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: 0;
  margin-bottom: 16px;
`;

function Privacy() {
  return (
    <>
      <Section>Privacy</Section>
      <CheckboxField name="isPublicProfile" label="Enable diagnostic public profile" style={{marginBottom: 8}} />
      <FieldHint style={fieldHintStyles}>
        Enabling this option will allow other users to see this horse profile including the variant summary for
        diagnostic, health and coat color data. You may also share it using the link.
      </FieldHint>
      <CheckboxField name="isAncestryPublicProfile" label="Enable ancestry public profile" style={{marginBottom: 8}} />
      <FieldHint style={fieldHintStyles}>
        Ancestry profiles are public by default. Enabling this option will allow other users to see this horse's
        Ancestry profile. You may also share it by using the link.
      </FieldHint>
    </>
  );
}

export default memo(Privacy);
