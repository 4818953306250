import React, {useState, useCallback} from 'react';

import ModalWindow from 'Common/components/Modal/ModalWindow';
import DogCreateForm from '../../../components/DogCreateForm/DogCreateForm';

interface IUpdateModalParams {
  onSuccess?(): void;
}

export function useDogCreateModal(params: IUpdateModalParams) {
  const {onSuccess} = params;

  const [isOpen, setIsOpen] = useState(false);

  const openDogCreateModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeDogEditModal = useCallback(() => setIsOpen(false), []);

  const handleSuccess = useCallback(() => {
    closeDogEditModal();
    onSuccess && onSuccess();
  }, [closeDogEditModal, onSuccess]);

  const dogCreateModal = (
    <ModalWindow isOpen={isOpen} onClose={closeDogEditModal}>
      <DogCreateForm onSuccess={handleSuccess} />
    </ModalWindow>
  );

  return {
    dogCreateModal,
    openDogCreateModal,
  };
}
