import {memo, useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {connect, ConnectedProps} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {actions, selectors} from 'Notifications/store';
import {selectors as userSelectors} from 'UserProfile/store/currentUser/index';
import {UserNotificationsModule} from 'Notifications/store/module';
import Theme from 'Common/constants/Theme';
import NotificationHistoryDetail from './NotificationHistoryDetail/NotificationHistoryDetail';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';

const Root = styled.div`
  width: 100%;
  padding: 8px 8px;

  @media ${breakpoints.md} {
    padding: 48px 48px 0;
  }

  @media ${breakpoints.lg} {
    width: 50%;
  }
`;

const PageHeader = styled.div`
  margin-bottom: 16px;
  padding-right: 16px;
  @media ${breakpoints.md} {
    margin-bottom: 32px;
  }
`;

export const PageTitle = styled.div`
  color: ${ColorPalette.black0};
  font-family: ${Typography.family.openSans};
  font-size: ${Typography.size.size24};
  line-height: 33px;
`;

const SettingsLink = styled(Link)`
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.black};
  cursor: pointer;
  color: ${Theme.color.primary} !important;
`;

const ButtonWrapper = styled.div`
  padding-right: 16px;
  margin-bottom: 8px;
`;

interface IProps {}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IProps;

function NotificationHistory(props: AllProps) {
  const {
    getUserNotificationHistory,
    notificationHistory,
    userNotificationHistoryLoading,
    isReadUserNotificationsAllUpdating,
    isReadUserNotificationsUpdating,
    updateIsReadUserNotification,
    updateIsReadUserNotificationAll,
    currentUserId,
  } = props;

  const {listen} = useEventBus();
  const {isMobile} = useMediaQuery();

  const reload = useCallback(() => {
    getUserNotificationHistory();
  }, [getUserNotificationHistory]);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const notificationReceived = listen(EventName.NotificationReceived, reload);

    return () => notificationReceived.stopListening();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMarkAsReadClick = useCallback(
    async (id: number) => {
      await updateIsReadUserNotification(id);
      reload();
    },
    [reload, updateIsReadUserNotification]
  );

  const onMarkAllAsReadClick = useCallback(async () => {
    await updateIsReadUserNotificationAll();
    reload();
  }, [reload, updateIsReadUserNotificationAll]);

  const sortedNotificationHistory = notificationHistory.sort((a, b) => {
    const aDate = a.createDate ? new Date(a.createDate).getTime() : 0;
    const bDate = b.createDate ? new Date(b.createDate).getTime() : 0;
    return bDate - aDate;
  });

  const isLoading = [
    userNotificationHistoryLoading,
    isReadUserNotificationsAllUpdating,
    isReadUserNotificationsUpdating,
  ].some((x) => x.isRequesting);

  return (
    <BaseLayout withoutPaddings={isMobile}>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      {isLoading && <Loading />}

      <Root className="d-flex flex-column flex-grow-1 ">
        <PageHeader className="d-flex align-items-center justify-content-between">
          <PageTitle>Notifications</PageTitle>
          <SettingsLink to="/account-details/notification-settings">Notification settings</SettingsLink>
        </PageHeader>

        <div>
          {notificationHistory.length > 0 && (
            <ButtonWrapper className="d-flex justify-content-end">
              <PrimaryButton onClick={onMarkAllAsReadClick} size="medium">
                Mark all as read
              </PrimaryButton>
            </ButtonWrapper>
          )}

          {sortedNotificationHistory.map((notificationHistory, i) => (
            <div key={i} className="d-flex">
              <NotificationHistoryDetail
                notificationHistory={notificationHistory}
                onMarkAsRead={onMarkAsReadClick}
                currentUserId={currentUserId}
              />
            </div>
          ))}
        </div>
      </Root>
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  notificationHistory: selectors.selectUserNotificationHistory(state),
  userNotificationHistoryLoading: selectors.selectCommunication(state, 'userNotificationHistoryLoading'),
  isReadUserNotificationsUpdating: selectors.selectCommunication(state, 'isReadUserNotificationsUpdating'),
  isReadUserNotificationsAllUpdating: selectors.selectCommunication(state, 'isReadUserNotificationsAllUpdating'),
  currentUserId: userSelectors.selectCurrentUserId(state),
});

const mapDispatchToProps = {
  getUserNotificationHistory: actions.getUserNotificationHistory,
  updateIsReadUserNotification: actions.updateIsReadUserNotification,
  updateIsReadUserNotificationAll: actions.updateIsReadUserNotificationAll,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(NotificationHistory));

export default withDynamicModules(Connected, [UserNotificationsModule]);
