import {IRequestParams} from 'Common/models/IRequestParams';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IImage} from 'Image/models/IImage';
import {
  IAdministrator,
  ICreateAdministrator,
  IUpdateAdministrator,
  IUpdateAdministratorPassword,
} from '../models/Admins/IAdministrator';
import {IStaticResource} from 'Admin/AdminSettings/models/IStaticResource';
import {IEntity} from 'Common/models/IEntity';
import {withImageBaseUrl} from 'Common/helpers/withImageBaseUrl';
import {withAvatar} from 'Common/helpers/withAvatar';

const getAdministrators = async (params?: IRequestParams): Promise<IListResponse<IAdministrator>> => {
  const response = await axiosWrapper.get<IListResponse<IAdministrator>>(`/AdminsAdmin`, {params});
  return {...response.data, data: response.data.data.map(withAvatar)};
};

const getAdministrator = async (id: number): Promise<IAdministrator> => {
  const response = await axiosWrapper.get<IObjectResponse<IAdministrator>>(`/AdminsDetailsAdmin/${id}`);
  return withAvatar(response.data.data);
};

const createAdministrator = async (data: ICreateAdministrator): Promise<IEntity> => {
  const response = await axiosWrapper.post<IObjectResponse<IEntity>>(`/AdminsCreateAdmin`, data);
  return response.data.data;
};

const updateAdministrator = async (data: IUpdateAdministrator): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(`/AdminsUpdateAdmin`, data);
};

const getAdministratorAvatar = async (id: number): Promise<IImage> => {
  const response = await axiosWrapper.get<IObjectResponse<IImage>>(`/AdminAvatarAdmin/${id}`);
  return withImageBaseUrl(response.data.data);
};

const uploadAdministratorAvatar = async (adminId: number, uploadedFile: File): Promise<void> => {
  const formData = new FormData();
  formData.append('file', uploadedFile);
  formData.append('adminId', `${adminId}`);

  await axiosWrapper.post<IObjectResponse<IStaticResource>>(`/AdminAvatarUpdateAdmin`, formData);
};

const deleteAdministratorAvatar = async (id: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/AdminAvatarDeleteAdmin/${id}`);
};

const setAdministratorPassword = async (data: IUpdateAdministratorPassword): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AdminsPasswordUpdateAdmin`, data);
};

const changeAdministratorStatus = async (id: number, isActive: boolean): Promise<void> => {
  await axiosWrapper.post<IObjectResponse<null>>(`/AdminsStatusUpdateAdmin`, {id, status: isActive});
};

export default {
  getAdministrators,
  getAdministrator,
  createAdministrator,
  updateAdministrator,
  getAdministratorAvatar,
  uploadAdministratorAvatar,
  deleteAdministratorAvatar,
  setAdministratorPassword,
  changeAdministratorStatus,
};
