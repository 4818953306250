import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.userNotifications;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectUserNotificationHistory(state: IAppState) {
  return selectState(state).data.userNotificationHistory;
}

export function selectUserNotifications(state: IAppState) {
  return selectState(state).data.userNotifications;
}
