import {Reducer} from 'redux';

import {
  AdminShortTandemRepeatReportActions,
  getShortTandemRepeateportActionTypes,
  getShortTandemRepeateportReviewActionTypes,
  IAdminShortTandemRepeatReportState,
} from '../types';

const initialState: IAdminShortTandemRepeatReportState['data'] = {
  shortTandemRepeatReport: [],
};

export const dataReducer: Reducer<IAdminShortTandemRepeatReportState['data'], AdminShortTandemRepeatReportActions> = (
  state = initialState,
  action: AdminShortTandemRepeatReportActions
) => {
  switch (action.type) {
    case getShortTandemRepeateportActionTypes.REQUEST:
    case getShortTandemRepeateportReviewActionTypes.REQUEST: {
      return {...state, shortTandemRepeatReport: initialState.shortTandemRepeatReport};
    }
    case getShortTandemRepeateportActionTypes.SUCCESS:
    case getShortTandemRepeateportReviewActionTypes.SUCCESS: {
      return {...state, shortTandemRepeatReport: action.payload};
    }

    default: {
      return state;
    }
  }
};
