import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Form, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import {FormikProps} from 'formik/dist/types';
import {ThunkDispatch} from 'redux-thunk';
import {bindActionCreators} from 'redux';
import styled from 'styled-components';

import {CheckboxGroupField, DateTimeField, GalleryField, InputField, SelectField} from 'Common/components/FormFields';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {actions, BusinessPortalHorseActions, selectors} from 'BusinessPortal/store/horse';
import {
  actions as ownerActions,
  BusinessPortalOwnerModule,
  selectors as ownerSelectors,
} from 'BusinessPortal/store/owner';
import {
  actions as adminHorseActions,
  AdminAssociationHorseActions,
  selectors as adminHorseSelectors,
} from 'Admin/AdminAssociations/store/adminHorses';
import {
  actions as adminOwnerActions,
  AdminAssociationOwnerActions,
  AdminAssociationOwnerModule,
  selectors as adminOwnerSelectors,
} from 'Admin/AdminAssociations/store/adminOwners';
import {IAppState} from 'Common/store/IAppState';
import Loading from 'Loading/components/Loading';
import {IEditableSection} from 'BusinessPortal/components/common/EditableSection/EditableSection';
import {castToOption, getStringKeysOption} from 'Common/helpers/OptionHelper';
import {Gender} from 'Common/constants/Gender';
import HorseMapField from 'Common/components/FormFields/HorseMapField/HorseMapField';
import RegistriesList from 'HorseProfile/components/HorseProfileForm/parts/RegistriesList/RegistriesList';
import {Divider, TextBase} from 'BusinessPortal/components/common/styled';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {IFormValues, initialValue, validationSchema} from 'Shared/components/Association/validation';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types/simpleDictionary';
import {IHorseColor} from 'Dictionaries/models/IHorseColor';
import {IBreed} from 'Dictionaries/models/IBreed';
import {IDiscipline} from 'Dictionaries/models/IDiscipline';
import {IMarking} from 'Dictionaries/models/IMarking';
import {IAssociation} from 'Dictionaries/models/IAssociation';
import UserSearchField from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearchField';
import {Checkbox} from 'Common/components/Controls';
import {IInconclusiveHealthIssue} from 'Dictionaries/models/InconclusiveHealthIssues';
import {IconName, IProps as IconProps} from 'Icon/components/Icon';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import {convertToServer} from 'Shared/components/Association/converters';
import {IMediaResource, MediaType} from 'Common/models/IMediaResource';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {withHorseMapFieldPropsToRequest} from 'Common/components/FormFields/HorseMapField/withHorseMapFieldProps';
import BusinessPortalOwnerService from 'BusinessPortal/services/BusinessPortalOwnerService';
import {ChangeOwnerFormButton} from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseForm/parts/HorseOwnerSection/HorseOwnerSectionEdit/common/styled';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {AdminAssociationHorseModule} from 'Admin/AdminAssociations/store/adminHorses/adminAssociationHorseModule';
import {ModalWindowFooter, ModalWindowFormContent, ModalWindowHeader} from 'Common/components/Modal/shared';
import OrganizationSearchField from 'Admin/AdminAssociations/components/Employees/EmployeeForm/parts/OrganizationSearchField';
import {retrieveImageError} from 'Common/helpers/retrieveImageError';
import HorseHeightField from 'Common/components/FormFields/HorseHeightField';
import RequiredMark from 'Common/helpers/RequiredMark';
import Privacy from './parts/Privacy';
import {selectors as userSelectors} from 'UserProfile/store/currentUser';

const galleryVideoIconProps: IconProps = {name: IconName.InsertVideo, size: 48};
const galleryIconProps: IconProps = {name: IconName.InsertPhoto, size: 48};

const Section = styled(TextBase)`
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: 0;
  margin-bottom: 16px;
`;

const ParentRole = styled.div`
  font-family: ${Theme.font.secondary};
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
  color: ${Theme.color.black};
  margin-bottom: 16px;
`;

const RegistriesListWrapper = styled.div`
  margin: 14px 0 20px 0;
`;

const Avatar = styled.div`
  margin-right: 16px;
`;

const OwnerSection = styled.div`
  margin-bottom: 16px;
`;

interface IExternalDictionaries {
  colorsDictionary: ISimpleDictionary<IHorseColor>;
  breedDictionary: ISimpleDictionary<IBreed>;
  disciplineDictionary: ISimpleDictionary<IDiscipline>;
  markingDictionary: ISimpleDictionary<IMarking>;
  associationDictionary: ISimpleDictionary<IAssociation>;
  inconclusiveHealthIssueDictionary: ISimpleDictionary<IInconclusiveHealthIssue>;
}

interface IExternalProps extends Omit<IEditableSection<{}>, 'data'>, IExternalDictionaries {
  ownerId?: number;
  isAdmin?: boolean;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const AssociationHorseFormCreate = (props: AllProps) => {
  const {isAdmin, isValid, isSubmitting, values, setFieldValue} = props;
  const {ownerId, horseCreating, horseAvatarUpdating, horseImageMediaCreating, horseVideoMediaCreating, ownerCreating} =
    props;

  const {
    dictionariesLoading,
    associationDictionary,
    inconclusiveHealthIssueDictionary,
    inconclusiveHealthIssues,
    markingDictionary,
    disciplineDictionary,
    colorsDictionary,
    breedDictionary,
    markings,
    colors,
    breeds,
    disciplines,
    associations,
  } = props;

  const {
    actions: {getItems: getBreeds},
  } = breedDictionary;
  const {
    actions: {getItems: getDisciplines},
  } = disciplineDictionary;
  const {
    actions: {getItems: getMarkings},
  } = markingDictionary;
  const {
    actions: {getItems: getColors},
  } = colorsDictionary;
  const {
    actions: {getItems: getAssociations},
  } = associationDictionary;
  const {
    actions: {getItems: getHealthIssues},
  } = inconclusiveHealthIssueDictionary;

  useEffect(() => {
    if (ownerId) {
      setFieldValue('ownerId', ownerId);
    }
  }, [ownerId, setFieldValue]);

  useEffect(() => {
    getBreeds();
    getDisciplines();
    getMarkings();
    getColors();
    getAssociations();
    getHealthIssues();
  }, [getBreeds, getColors, getDisciplines, getMarkings, getAssociations, getHealthIssues]);

  const isRequesting =
    ownerCreating.isRequesting ||
    horseCreating.isRequesting ||
    horseImageMediaCreating.isRequesting ||
    horseVideoMediaCreating.isRequesting ||
    horseAvatarUpdating.isRequesting ||
    dictionariesLoading;

  useErrorCommunicationToToast(ownerCreating);
  useErrorCommunicationToToast(horseCreating);
  useErrorCommunicationToToast(horseAvatarUpdating);
  useErrorCommunicationToToast(horseImageMediaCreating);
  useErrorCommunicationToToast(horseVideoMediaCreating);

  const [isUserSearchFieldForm, setIsUserSearchFieldForm] = useState(true);
  const changeOwnerForm = useCallback(() => {
    if (isUserSearchFieldForm) {
      setFieldValue('ownerId', undefined);
    } else {
      setFieldValue('ownerName', undefined);
      setFieldValue('ownerEmail', undefined);
    }
    setIsUserSearchFieldForm(!isUserSearchFieldForm);
  }, [isUserSearchFieldForm, setFieldValue]);

  const ContentContainer = isAdmin ? ModalWindowFormContent : React.Fragment;
  const FooterContainer = isAdmin ? ModalWindowFooter : React.Fragment;

  const userSearchFieldAction = useCallback(
    (filter: string) => {
      return BusinessPortalOwnerService.findOwner(filter, isAdmin ? values.organizationId : undefined);
    },
    [isAdmin, values.organizationId]
  );

  const isEmptyOrganization = useMemo(
    () => !values.organizationId || values.organizationId === 0,
    [values.organizationId]
  );

  useEffect(() => {
    if (isAdmin && isEmptyOrganization) {
      setFieldValue('owner', undefined);
      setFieldValue('ownerId', 0);
      setFieldValue('ownerName', undefined);
      setFieldValue('ownerEmail', undefined);
    }
  }, [setFieldValue, isEmptyOrganization, isAdmin]);

  return (
    <Form>
      <ModalWindowHeader>Add horse</ModalWindowHeader>
      {isRequesting && <Loading />}
      <ContentContainer>
        {isAdmin && (
          <OwnerSection>
            <div className="d-flex">
              <Section>Organization</Section>
              <RequiredMark isRequired />
            </div>
            <OrganizationSearchField name="organizationId" label="" isRequired />
          </OwnerSection>
        )}

        {(!isEmptyOrganization || !isAdmin) && (
          <>
            {!ownerId && isUserSearchFieldForm && (
              <OwnerSection>
                <Section>Owner</Section>
                <UserSearchField name="ownerId" label="" action={userSearchFieldAction} isRequired />
                <ChangeOwnerFormButton onClick={changeOwnerForm}>Want to create a new user?</ChangeOwnerFormButton>
              </OwnerSection>
            )}

            {!ownerId && !isUserSearchFieldForm && (
              <OwnerSection>
                <Section>Owner</Section>
                <InputField
                  isRequired={true}
                  name="ownerFirstName"
                  type="text"
                  label="First Name"
                  placeholder="Owner first name"
                  autoComplete="off"
                  memoized={true}
                />
                <InputField
                  isRequired={true}
                  name="ownerLastName"
                  type="text"
                  label="Last Name"
                  placeholder="Owner last name"
                  autoComplete="off"
                  memoized={true}
                />
                <InputField
                  name="ownerEmail"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  autoComplete="off"
                  memoized={true}
                  isRequired
                />
                <ChangeOwnerFormButton onClick={changeOwnerForm}>Find an existing user?</ChangeOwnerFormButton>
              </OwnerSection>
            )}
          </>
        )}

        <Section>Horse info</Section>
        <InputField
          isRequired={true}
          name="name"
          type="text"
          label="Name"
          placeholder="Horse name"
          autoComplete="off"
          memoized={true}
        />
        <SelectField
          isRequired={true}
          name="gender"
          label="Sex"
          options={getStringKeysOption(Gender)}
          memoized={true}
        />
        <InputField
          name="registryNumber"
          type="text"
          label="Registry number"
          placeholder="Registry number"
          autoComplete="off"
          memoized={true}
        />
        <DateTimeField name="dateOfBirth" label="Date of birth" memoized={true} />

        <HorseHeightField
          heightName="height.height"
          heightPercentWidth={70}
          heightUnitName="height.heightUnit"
          heightUnitPercentWidth={30}
        />

        <SelectField
          isMulti={true}
          name="disciplines"
          label="Discipline"
          options={castToOption(disciplines)}
          memoized={true}
        />
        <SelectField
          isMulti={true}
          name="breeds"
          label="Breeds"
          options={castToOption(breeds)}
          memoized={true}
          isRequired={true}
        />
        <SelectField isMulti={true} name="colors" label="Colors" options={castToOption(colors)} memoized={true} />
        <SelectField isMulti={true} name="markings" label="Markings" options={castToOption(markings)} memoized={true} />
        <HorseMapField />

        <RegistriesListWrapper>
          <Section>Other registries</Section>
          <RegistriesList fieldName="registries" registries={values.registries} associations={associations} />
        </RegistriesListWrapper>
        <InputField
          name="microchipNumber"
          type="text"
          label="Microchip number"
          placeholder="Microchip number"
          autoComplete="off"
          memoized={true}
        />
        <InputField
          name="passportNumber"
          type="text"
          label="Passport number"
          placeholder="Passport number"
          autoComplete="off"
          memoized={true}
        />

        <Section>Parentage</Section>
        <ParentRole>Sire</ParentRole>
        <InputField name={`parentage[0].name`} label="Name" placeholder="Name" memoized={true} />
        <RegistriesList
          fieldName={`parentage[0].registries`}
          registries={values.parentage[0].registries}
          associations={associations}
        />
        <Divider />

        <ParentRole>Dam</ParentRole>
        <InputField name={`parentage[1].name`} label="Name" placeholder="Name" memoized={true} />
        <RegistriesList
          fieldName={`parentage[1].registries`}
          registries={values.parentage[1].registries}
          associations={associations}
        />
        <Divider />

        <Section>Media</Section>
        <div className="d-flex">
          <Avatar>
            <ImageUploaderField
              name="avatar"
              title="Add avatar"
              iconProps={galleryIconProps}
              memoized={true}
              showDownloadButton={true}
            />
          </Avatar>
          <GalleryField
            isEditMode={true}
            name="gallery"
            imageProps={{
              title: 'Add gallery photo',
              iconProps: galleryIconProps,
              showDownloadButton: true,
              showDeleteButton: true,
            }}
            videoProps={{title: 'Add a video', iconProps: galleryVideoIconProps, showDeleteButton: true}}
          />
        </div>

        <Section>Health info</Section>
        <CheckboxGroupField name="healthIssues">
          {inconclusiveHealthIssues.map((issue1) => {
            const isChecked = !!values.healthIssues?.find((issue2) => issue1.id === issue2);
            return <Checkbox key={issue1.id} name={String(issue1.id)} checked={isChecked} label={issue1.name} />;
          })}
        </CheckboxGroupField>

        <Privacy />
      </ContentContainer>
      <FooterContainer>
        <PrimaryButton
          className={!isAdmin ? 'w-100' : ''}
          size="medium"
          type="submit"
          disabled={!isValid}
          isLoading={isSubmitting}
        >
          Create a Horse
        </PrimaryButton>
      </FooterContainer>
    </Form>
  );
};

const AssociationHorseFormCreateFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => initialValue,
  validationSchema: validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {createOwner, createHorse, updateHorseAvatar, createHorseImageMedia, createHorseVideoMedia, onSuccess} =
      formikBag.props;
    const {ownerId, ownerFirstName, ownerLastName, ownerEmail, organizationId} = values;
    try {
      let createdOwnerId;
      if ((ownerFirstName || ownerLastName) && ownerEmail) {
        createdOwnerId = await createOwner({
          organizationId,
          firstName: ownerFirstName || '',
          lastName: ownerLastName || '',
          email: ownerEmail,
          address: null,
          phone: '',
        });
      } else {
        createdOwnerId = ownerId;
      }

      const createdHorseId = await createHorse(
        withHorseMapFieldPropsToRequest(convertToServer(values, createdOwnerId!))
      );

      const uploadMedia = async (horseId: number, media: IMediaResource) => {
        if (media.type === MediaType.Image) {
          if (media.isLocal && media.file) {
            await createHorseImageMedia(horseId, [media]);
          }
        }
        if (media.type === MediaType.Video) {
          await createHorseVideoMedia(horseId, media);
        }
      };

      if (createdHorseId) {
        // Create avatar
        try {
          values.avatar?.file && (await updateHorseAvatar(createdHorseId, values.avatar.file));
        } catch (e) {
          formikBag.setFieldError('avatar', retrieveImageError(e));
          return;
        }

        // Upload media item
        try {
          for (const media of values.gallery || []) {
            await uploadMedia(createdHorseId, media);
          }
        } catch (e) {
          formikBag.setFieldError('gallery', retrieveImageError(e));
          return;
        }
      }
    } catch (e) {
      return;
    }

    onSuccess && onSuccess();
  },
  enableReinitialize: true,
})(AssociationHorseFormCreate);

const adminMapStateToProps = (state: IAppState) => ({
  horseCreating: adminHorseSelectors.selectCommunication(state, 'horseCreating'),
  horseImageMediaCreating: adminHorseSelectors.selectCommunication(state, 'horseImageMediaCreating'),
  horseVideoMediaCreating: adminHorseSelectors.selectCommunication(state, 'horseVideoMediaCreating'),
  horseAvatarUpdating: adminHorseSelectors.selectCommunication(state, 'horseAvatarUpdating'),
  ownerCreating: adminOwnerSelectors.selectCommunication(state, 'ownerCreating'),
  currentAssociationEmployee: userSelectors.selectCurrentAssociationEmployee(state),
});

const userMapStateToProp = (state: IAppState) => ({
  horseCreating: selectors.selectCommunication(state, 'horseCreating'),
  horseImageMediaCreating: selectors.selectCommunication(state, 'horseImageMediaCreating'),
  horseVideoMediaCreating: selectors.selectCommunication(state, 'horseVideoMediaCreating'),
  horseAvatarUpdating: selectors.selectCommunication(state, 'horseAvatarUpdating'),
  ownerCreating: ownerSelectors.selectCommunication(state, 'ownerCreating'),
  currentAssociationEmployee: userSelectors.selectCurrentAssociationEmployee(state),
});

const mapStateToProps = (state: IAppState, props: IExternalProps) => {
  const {
    colorsDictionary,
    breedDictionary,
    disciplineDictionary,
    markingDictionary,
    associationDictionary,
    inconclusiveHealthIssueDictionary,
  } = props;
  const {selectors: horseColorsSelectors} = colorsDictionary;
  const {selectors: breedSelectors} = breedDictionary;
  const {selectors: disciplineSelectors} = disciplineDictionary;
  const {selectors: markingSelectors} = markingDictionary;
  const {selectors: associationSelectors} = associationDictionary;
  const {selectors: inconclusiveHealthIssueSelectors} = inconclusiveHealthIssueDictionary;

  const defaultMapStateToProps = props.isAdmin ? adminMapStateToProps : userMapStateToProp;

  return {
    ...defaultMapStateToProps(state),
    colors: horseColorsSelectors.selectItems(state),
    breeds: breedSelectors.selectItems(state),
    disciplines: disciplineSelectors.selectItems(state),
    markings: markingSelectors.selectItems(state),
    associations: associationSelectors.selectItems(state),
    inconclusiveHealthIssues: inconclusiveHealthIssueSelectors.selectItems(state),
    dictionariesLoading: [
      horseColorsSelectors,
      breedSelectors,
      disciplineSelectors,
      markingSelectors,
      associationSelectors,
      inconclusiveHealthIssueSelectors,
    ].some((i) => i.selectCommunication(state, 'itemsLoading').isRequesting),
  };
};

const adminMapDispatchToProps = {
  createOwner: adminOwnerActions.createOwner,
  createHorse: adminHorseActions.createHorse,
  createHorseImageMedia: adminHorseActions.createHorseImageMedia,
  createHorseVideoMedia: adminHorseActions.createHorseVideoMedia,
  updateHorseAvatar: adminHorseActions.updateHorseAvatar,
};

const userMapDispatchToProps = {
  createOwner: ownerActions.createOwner,
  createHorse: actions.createHorse,
  createHorseImageMedia: actions.createHorseImageMedia,
  createHorseVideoMedia: actions.createHorseVideoMedia,
  updateHorseAvatar: actions.updateHorseAvatar,
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    IAppState,
    undefined,
    AdminAssociationOwnerActions | AdminAssociationHorseActions | BusinessPortalHorseActions
  >,
  externalProps: IExternalProps
) =>
  externalProps.isAdmin
    ? bindActionCreators(adminMapDispatchToProps, dispatch)
    : bindActionCreators(userMapDispatchToProps, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(AssociationHorseFormCreateFormik);
const Exported = (externalProps: Omit<IExternalProps, keyof IExternalDictionaries>) => {
  const {horseColors, breeds, disciplines, markings, associations, inconclusiveHealthIssue} = useDictionaries();

  return (
    <Connected
      colorsDictionary={horseColors}
      breedDictionary={breeds}
      disciplineDictionary={disciplines}
      markingDictionary={markings}
      associationDictionary={associations}
      inconclusiveHealthIssueDictionary={inconclusiveHealthIssue}
      {...externalProps}
    />
  );
};

export default withDynamicModules(memo(Exported), [
  BusinessPortalOwnerModule,
  AdminAssociationHorseModule,
  AdminAssociationOwnerModule,
]);
