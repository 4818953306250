import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {Form, FormikProps, withFormik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import SelectField from 'Common/components/FormFields/SelectField';
import {InputField} from 'Common/components/FormFields';
import {getStringKeysOption} from 'Common/helpers/OptionHelper';
import {CurrentUrl} from 'Common/constants/CurrentUrl';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import {actions, selectors} from 'Signup/store/index';
import {ISignupForm} from 'Signup/models/ISignupForm';
import {Salutation} from 'Common/constants/Salutation';
import PasswordField from 'Common/components/FormFields/PasswordField';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {IAppState} from 'Common/store/IAppState';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {SignupModule} from 'Signup/store/signupModule';
import StartPageLayout from 'Common/components/StartPageLayout/StartPageLayout';
import {Button, SignUpLink, Text, Title} from 'Common/components/StartPageLayout/shared/StyledComponents';
import ColorPalette from 'Common/constants/ColorPalette';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import TocAgreement from 'Auth/components/Agreement/TocAgreement';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import SuccessSignupPage from 'Signup/components/SuccessSignupPage';
import {breakpoints} from 'Common/constants/Breakpoints';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';

import Scrollbar from 'Common/components/Scrollbar/Scrollbar';
// import Loading from 'Loading/components/Loading';
import useServiceMode from 'Maintain/hooks/useServiceMode';
import TagManager from 'react-gtm-module';

const ADDITIONAL_CONTENT_HEIGHT_DESKTOP = 320;
const ADDITIONAL_CONTENT_HEIGHT_MOBILE = 320;

const phoneDescriptionTooltip =
  'We will only use this number to reach out to you in an emergency case related to your order';

const salutations = getStringKeysOption(Salutation).filter((item) => item.value !== Salutation.Unknown);

const FormLayout = styled.div`
  height: 100%;
  width: 95%;
  max-width: 500px;
  padding: 0 9px;

  align-self: start;

  @media ${breakpoints.sm} {
    height: auto;
    padding: 0;
  }

  @media ${breakpoints.md} {
    align-self: center;
  }
`;

const FieldWrapper = styled.div`
  min-width: 100px;
  margin-right: 16px;
`;

const PostFormMessage = styled.div`
  max-width: 241px;
  margin: 16px 0;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size14};
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${Theme.color.gray};
`;

const AgreementLink = styled.span`
  color: ${ColorPalette.red7};
  cursor: pointer;
  text-decoration: underline;
`;

const AlreadyHasAccountMobileText = styled(Text)`
  font-size: ${Typography.size.size16};
  line-height: 24px;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  padding-right: 30px;
  @media ${breakpoints.sm} {
    margin-top: 40px;
    padding-right: 0px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 240px;
  }
`;

const SignupTitle = styled(Title)`
  line-height: 36px;
  margin-top: 8px;

  @media ${breakpoints.sm} {
    margin-top: 16px;
  }
  @media ${breakpoints.md} {
    margin-top: 0px;
  }
`;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected;

type AllProps = FormikProps<ISignupForm> & OuterProps;

const SignupForm = (props: AllProps) => {
  const {signupCommunication, setErrors} = props;

  const {isDesktop, isMobile} = useMediaQuery();

  const errorInfo = signupCommunication.error;

  const {getServiceModeStatus /*isServiceModeLoading*/} = useServiceMode();

  useEffect(() => {
    /**  REFACTORING:
     * getServiceModeStatus() will not update 'isServiceModeLoading' when it is
     * done fetching, this causes the 'Loading' overlay to never disappear.
     * We also do nothing with the returned status in this component.
     */
    getServiceModeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSuccess, setIsSuccess] = useState(false);
  const onSuccess = useCallback(() => setIsSuccess(true), []);

  const onError = useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnSuccessCommunication(signupCommunication, onSuccess);
  useOnErrorCommunication(signupCommunication, onError);
  useCommunicationToToast(signupCommunication);

  const [isShowAgreementModal, setIsShowAgreementModal] = useState(false);
  const openAgreementModal = useCallback(() => setIsShowAgreementModal(true), []);
  const closeAgreementModal = useCallback(() => setIsShowAgreementModal(false), []);

  const SCROLL_CONTENT_MAX_HEIGHT = `calc(100vh - ${
    isMobile ? ADDITIONAL_CONTENT_HEIGHT_MOBILE : ADDITIONAL_CONTENT_HEIGHT_DESKTOP
  }px`;

  const alreadyHasAccountLink = useMemo(() => {
    const Root = isMobile ? AlreadyHasAccountMobileText : Text;

    return (
      <Root className="d-flex align-items-center">
        <>Already have an account?</>
        <SignUpLink color={ColorPalette.red7} to="/login">
          Sign in
        </SignUpLink>
      </Root>
    );
  }, [isMobile]);

  if (isSuccess) {
    console.log('Event: CompleteRegistration');
    // Google Tag Manager tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'CompleteRegistration',
      },
    });
    return <SuccessSignupPage email={props.values.email} />;
  }

  return (
    <StartPageLayout isDesktop={isDesktop} isMobile={isMobile}>
      {/* {isServiceModeLoading && <Loading />} */}
      <ModalWindow isOpen={isShowAgreementModal} onClose={closeAgreementModal}>
        <TocAgreement isShowOnly={true} />
      </ModalWindow>

      <FormLayout className="d-flex flex-column">
        <SignupTitle>
          Sign up for a free <strong>Etalon</strong> account to order testing
        </SignupTitle>
        {alreadyHasAccountLink}
        <div className="d-flex flex-column justify-content-between flex-grow-1">
          <Form>
            <Scrollbar maxHeight={SCROLL_CONTENT_MAX_HEIGHT}>
              <div className="d-flex justify-content-between">
                <FieldWrapper className="w-25">
                  <SelectField
                    name="salutation"
                    label="Salutation"
                    placeholder="Salutation"
                    options={salutations}
                    isClearable={true}
                  />
                </FieldWrapper>
                <div className="w-75">
                  <InputField id="firstName" name="firstName" label="First name" placeholder="First name" isRequired />
                </div>
              </div>
              <InputField name="lastName" label="Last name" placeholder="Last name" isRequired />
              <InputField type="email" name="email" label="Your email" placeholder="Email" isRequired />
              <div className="d-flex justify-content-between">
                <FieldWrapper className="w-50">
                  <PasswordField name="password" label="Password" placeholder="Password" isRequired />
                </FieldWrapper>
                <FieldWrapper className="w-50 mr-0">
                  <PasswordField
                    name="passwordConfirm"
                    label="Repeat password"
                    placeholder="Repeat password"
                    isRequired
                  />
                </FieldWrapper>
              </div>
              <InputField
                name="mobilePhone"
                label="Mobile number"
                placeholder="Mobile number"
                isRequired
                descriptionTooltip={phoneDescriptionTooltip}
              />
            </Scrollbar>
            <ButtonWrapper className="d-flex justify-content-between align-items-center">
              <SubmitButton type="submit" isLoading={props.isSubmitting}>
                Sign up
              </SubmitButton>
            </ButtonWrapper>
            <PostFormMessage className="justify-content-start">
              By creating account you agree to our{' '}
              <AgreementLink onClick={openAgreementModal}>Privacy Policy</AgreementLink>
            </PostFormMessage>
          </Form>
        </div>
      </FormLayout>
    </StartPageLayout>
  );
};

const initialValue: ISignupForm = {
  salutation: Salutation.Unknown,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  url: `${CurrentUrl}/confirm`,
  mobilePhone: '',
};

const validationSchema = Yup.object().shape<ISignupForm>({
  salutation: Yup.mixed<Salutation>(),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Email not valid').required('Email is required'),
  password: Yup.string().required('Password is required'),
  passwordConfirm: Yup.string()
    .required('Repeat password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  url: Yup.string().required(),
  mobilePhone: Yup.string()
    .required('Phone number is required')
    .test('Digits only', 'The field should have digits only', (value) => /^\d+$/.test(value))
    .test(
      'Check length',
      'The length of the phone number must be 5 to 16 digits',
      (value) => value?.length >= 5 && value?.length <= 16
    ),
});

const SignupFormWithFormik = withFormik<OuterProps, ISignupForm>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    formikBag.setSubmitting(true);
    await formikBag.props.signup(values);
    formikBag.setSubmitting(false);
  },
})(SignupForm);

const mapStateToProps = (state: IAppState) => ({
  signupCommunication: selectors.selectCommunication(state, 'signUpLoading'),
});

const mapDispatchToProps = {
  signup: actions.signup,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(SignupFormWithFormik), SignupModule);
