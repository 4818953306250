import {ICommunication, makeCommunicationActionType, MakeCommunicationActions} from 'Common/store/utils/communication';
import {IImage} from 'Image/models/IImage';

type ImageId = number;
type ImagesMap = Record<ImageId, IImage>;

const STATE_NAME = 'image';

export interface IImageState {
  data: {
    images: ImagesMap;
  };
  communications: {
    imageLoading: ICommunication;
    imageUploading: ICommunication;
  };
}

export const getImageActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_IMAGE');
export type getImageActions = MakeCommunicationActions<typeof getImageActionTypes, {success: IImage}>;

export const uploadImageActionTypes = makeCommunicationActionType(STATE_NAME, 'UPLOAD_IMAGE');
export type uploadImageActions = MakeCommunicationActions<typeof uploadImageActionTypes, {success: number}>;

export const deleteImageActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_IMAGE');
export type deleteImageActions = MakeCommunicationActions<typeof deleteImageActionTypes, {success: {imageId: number}}>;

export type ImageActions = getImageActions | uploadImageActions | deleteImageActions;
