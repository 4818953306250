import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
  MakeResetCommunicationActions,
  makeResetCommunicationActionType,
} from 'Common/store/utils/communication';
import {Nullable} from 'Common/types';
import {IMessageQueue} from 'SignalR/models/IMessageQueue';
import {IUserMessageRequest} from 'SignalR/models/IUserMessageRequest';

const STATE_NAME = 'userToUserHub';

export interface IDeleteProps {
  currentUserId: number;
  chatUserId: number;
}

export interface IUserToUserHubState {
  data: {
    connectedUsers: number[];
    messageQueue: IMessageQueue[];
    sendingMessage: Nullable<IUserMessageRequest>;
  };
  communications: {
    messageSending: ICommunication;
    messageQueueDeleting: ICommunication;
  };
}

export const setConnectedUserAtionTypes = makeCommunicationActionType(STATE_NAME, 'SET_CONNECT_USER');
export type setConnectedUserActions = MakeCommunicationActions<typeof setConnectedUserAtionTypes, {success: number}>;

export const setDisconnectedUserActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_DICONNECT_USER');
export type setDisconnectedUserActions = MakeCommunicationActions<
  typeof setDisconnectedUserActionTypes,
  {success: number}
>;

export const receiveMessageActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_RECEIVE_MESSAGE');
export type receiveMessageActions = MakeCommunicationActions<
  typeof receiveMessageActionTypes,
  {success: IMessageQueue}
>;

export const setSentMessageActionTypes = makeCommunicationActionType(STATE_NAME, 'SET_SENT_MESSAGE');
export type setSentMessageActions = MakeCommunicationActions<
  typeof setSentMessageActionTypes,
  {success: IMessageQueue}
>;

export const sendMessageActionTypes = makeCommunicationActionType(STATE_NAME, 'SEND_MESSAGE');
export type sendMessageActions = MakeCommunicationActions<
  typeof sendMessageActionTypes,
  {success: IUserMessageRequest}
>;

export const deleteMessageQueueActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_MESSAGE');
export type deleteMessageQueueActions = MakeCommunicationActions<
  typeof deleteMessageQueueActionTypes,
  {success: IDeleteProps}
>;

export const resetSendingMessageActionTypes = makeResetCommunicationActionType(STATE_NAME, 'SENDING_MESSAGE');
export type resetSendingMessageActions = MakeResetCommunicationActions<typeof resetSendingMessageActionTypes>;

export type Actions =
  | setConnectedUserActions
  | setDisconnectedUserActions
  | receiveMessageActions
  | setSentMessageActions
  | sendMessageActions
  | deleteMessageQueueActions
  | resetSendingMessageActions;
