export function parseHubError(error: any) {
  if (!error) {
    return null;
  }

  const errorResponse = error.message;
  const matcher = 'HubException:';

  const errorJson = JSON.parse(errorResponse.split(matcher)[1]);

  if (!errorJson) {
    return null;
  }

  console.error(errorJson);

  return errorJson;
}
