import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';
import {Form, FormikProps, withFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';

import {ForgotPasswordModule} from 'ForgotPassword/store/forgotPasswordModule';
import {actions, selectors} from 'ForgotPassword/store';
import {IForgotPassword} from 'ForgotPassword/models/IForgotPassword';
import {getFieldErrors} from 'Common/helpers/ErrorHelper';
import {InputField} from 'Common/components/FormFields';
import Typography from 'Common/constants/Typography';
import {CurrentUrl} from 'Common/constants/CurrentUrl';
import {IAppState} from 'Common/store/IAppState';
import {isEmailNotConfirmed} from 'MailConfirmation/helpers/mailConfirmationErrors';
import {useOnErrorCommunication} from 'Common/helpers/hooks/useOnErrorCommunication';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Theme from 'Common/constants/Theme';
import {Button, StartPageLink, Title} from 'Common/components/StartPageLayout/shared/StyledComponents';
import StartPageLayout from 'Common/components/StartPageLayout/StartPageLayout';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import {breakpoints} from 'Common/constants/Breakpoints';
import ColorPalette from 'Common/constants/ColorPalette';
import Loading from 'Loading/components/Loading';
import useServiceMode from 'Maintain/hooks/useServiceMode';

const FormLayout = styled.div`
  height: 100%;
  width: 95%;
  max-width: 500px;
  padding: 0 9px;

  align-self: start;

  @media ${breakpoints.sm} {
    height: auto;
    padding: 0;
  }

  @media ${breakpoints.md} {
    align-self: center;
  }
`;

const SuccessMessage = styled.h1`
  margin: auto;
  font-weight: ${Typography.weight.medium500};
  text-align: center;
  max-width: 960px;
`;

const Hint = styled.div`
  max-width: 405px;
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.gray};
  margin-bottom: 30px;
`;

const LoginPageLinkWrapper = styled.div`
  justify-content: center;

  @media ${breakpoints.sm} {
    margin-top: 0;
    justify-content: flex-start;
  }
`;

const LoginPageLink = styled(StartPageLink)`
  padding: 0;
  margin: 16px 0;
  font-size: ${Typography.size.size16};
  line-height: 24px;
  @media ${breakpoints.sm} {
    margin: 8px 0 40px;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;

  @media ${breakpoints.sm} {
    width: 240px;
  }
`;

const ButtonsFooter = styled.div`
  flex-direction: column-reverse;
  align-items: center;

  @media ${breakpoints.sm} {
    flex-direction: column;
    align-items: start;
  }
`;

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected;

type AllProps = FormikProps<IForgotPassword> & OuterProps;

const ForgotPasswordFormik = (props: AllProps) => {
  const {changingPassword, setErrors, isSubmitting, resultMessage, values} = props;
  const changingPasswordError = changingPassword.error;

  const navigate = useNavigate();
  const {isDesktop, isMobile} = useMediaQuery();

  const {getServiceModeStatus, isServiceModeLoading} = useServiceMode();

  useEffect(() => {
    getServiceModeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorInfo = changingPassword.error;

  const onError = React.useCallback(() => {
    const fieldErrors = getFieldErrors(errorInfo);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, errorInfo]);

  useOnErrorCommunication(changingPassword, onError);
  useCommunicationToToast(changingPassword, 'Please check your email for reset password');

  useEffect(() => {
    const fieldErrors = getFieldErrors(changingPasswordError);
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [setErrors, changingPasswordError]);

  useOnErrorCommunication(changingPassword, () => {
    if (changingPasswordError && isEmailNotConfirmed(changingPasswordError)) {
      navigate(`/email-not-confirmed/${values.email}`);
    }
  });

  if (resultMessage) {
    return <SuccessMessage>{resultMessage}</SuccessMessage>;
  }

  return (
    <StartPageLayout isDesktop={isDesktop} isMobile={isMobile}>
      {isServiceModeLoading && <Loading />}
      <FormLayout className="d-flex flex-column">
        <Title>Reset password</Title>
        <Hint>
          Enter the email associated with your account and we’ll send an email with the link to reset your password
        </Hint>
        <Form>
          <InputField type="email" name="email" label="Email" placeholder="Email" />
          <ButtonsFooter className="d-flex w-100">
            <LoginPageLinkWrapper className="d-flex w-100 ">
              <LoginPageLink className="nav-link" color={ColorPalette.red7} to="/login">
                Wait, I remember my password
              </LoginPageLink>
            </LoginPageLinkWrapper>
            <SubmitButton type="submit" isLoading={isSubmitting}>
              Reset password
            </SubmitButton>
          </ButtonsFooter>
        </Form>
      </FormLayout>
    </StartPageLayout>
  );
};

const initialValue: IForgotPassword = {
  email: '',
  url: `${CurrentUrl}/reset-password`,
};

const validationSchema = Yup.object().shape<IForgotPassword>({
  email: Yup.string().email('Email not valid').required('Email is required'),
  url: Yup.string().required(),
});

const ForgotPassword = withFormik<OuterProps, IForgotPassword>({
  mapPropsToValues: () => initialValue,
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    await formikBag.props.forgotPassword(values);
  },
})(ForgotPasswordFormik);

const mapStateToProps = (state: IAppState) => ({
  resultMessage: selectors.selectResultMessage(state),
  changingPassword: selectors.selectCommunication(state, 'messageLoading'),
});

const mapDispatchToProps = {
  forgotPassword: actions.forgotPassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(ForgotPassword);

export default withDynamicModules(Connected, ForgotPasswordModule);
