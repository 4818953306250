export function base64ToFile(base64: string, filename: string): File {
  // Extract the MIME type and base64 data from the data URI
  const matches = base64.match(/^data:(.*?);base64,(.*)$/);

  if (!matches) {
    throw new Error('Invalid base64 data URI');
  }

  const mimeType = matches[1]; // MIME type (e.g., 'image/png', 'image/jpeg', 'image/heic')
  const base64Data = matches[2]; // Base64 data

  // Decode base64 to binary string
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob with the binary data
  const blob = new Blob([byteArray], {type: mimeType});

  // Extract the file extension from the MIME type
  const extension = mimeType.split('/')[1]; // e.g., 'png', 'jpeg', 'heic'

  // Construct the File object
  return new File([blob], `avatar.${extension}`, {type: mimeType});
}
