import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {uploadDogTestResultsActions, uploadDogTestResultsActionTypes} from './types';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import DogDataService from 'Admin/AdminDashboard/services/DogDataService';
import {IAppState} from 'Common/store/IAppState';
import {IDogTestResultsUploadRequest} from '../../../../Dogs/models/IDogTestResultsUploadRequest';

export const uploadDogTestResultsFiles =
  (id: string, request: IDogTestResultsUploadRequest): ActionResult<IAppState, void, uploadDogTestResultsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(uploadDogTestResultsActionTypes.REQUEST));
      await DogDataService.uploadTestResults(id, request);
      dispatch(action(uploadDogTestResultsActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(uploadDogTestResultsActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };
