import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {DynamicModuleLoader} from 'redux-dynamic-modules';
import styled from 'styled-components';

import {SwitchWrapper} from 'Common/components/StyledComponents/StyledComponents';
import Typography from 'Common/constants/Typography';
import {useErrorCommunicationToToast} from 'Common/helpers/hooks/useErrorCommunicationToToast';
import {sortByName} from 'Common/helpers/sortByName';
import {IAppState} from 'Common/store/IAppState';
import {useDictionaries} from 'Common/store/useDictionaries';
import {ISimpleDictionary} from 'DictionaryFactory/types';
import Loading from 'Loading/components/Loading';
import {NotificationType} from 'Notifications/const/NotificationType';
import {IUserNotificationEvent} from 'Notifications/models/IUserNotificationEvent';
import {actions, selectors} from 'Notifications/store';
import {UserNotificationsModule} from 'Notifications/store/module';
import {IUserNotifactionSettings} from 'UserProfile/models/IUserNotifactionSettings';
import {ColumnName, SettingsTitle} from '../styled';
import {convertToNotificationSettings} from './converter';
import NotificationEventSwitcher from './NotificationEventSwitcher';

const Root = styled.div`
  max-width: 800px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  width: 100%;
`;

const EventName = styled.div`
  font-size: ${Typography.size.size18};
`;

interface IExternalProps {
  userNotificationEventDictionary: ISimpleDictionary<IUserNotificationEvent>;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = OuterProps;

function NotificationSettings(props: AllProps) {
  const {
    notificationEvents,
    notificationEventsLoading,
    userNotificationEventDictionary,
    getUserNotifications,
    userNotifications,
    userNotificationsLoading,
    createUserNotification,
    deleteUserNotification,
    userNotificationsCreating,
    userNotificationsDeleting,
  } = props;

  const {
    actions: {getItems: getNotificationEvents},
  } = userNotificationEventDictionary;

  const [notificationSettings, setNotificationSettings] = useState<IUserNotifactionSettings[]>([]);

  useEffect(() => {
    getNotificationEvents();
    getUserNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useErrorCommunicationToToast(userNotificationsCreating);
  useErrorCommunicationToToast(userNotificationsDeleting);

  const sortedNotificationEvents = useMemo(() => {
    return notificationEvents.sort(sortByName);
  }, [notificationEvents]);

  useEffect(() => {
    if (sortedNotificationEvents.length > 0 && userNotifications) {
      const newValues = convertToNotificationSettings(userNotifications, sortedNotificationEvents);
      setNotificationSettings(newValues);
    }
  }, [sortedNotificationEvents, userNotifications]);

  const onNotificationCreate = useCallback(
    async (eventId: number, type: NotificationType) => {
      await createUserNotification(eventId, type);
    },
    [createUserNotification]
  );

  const onNotificationDelete = useCallback(
    async (notificationId: number) => {
      await deleteUserNotification(notificationId);
    },
    [deleteUserNotification]
  );

  const isLoading = [notificationEventsLoading, userNotificationsLoading].some((x) => x.isRequesting);

  return (
    <Root>
      {isLoading && <Loading />}
      <SettingsTitle>Notification settings</SettingsTitle>

      <Row>
        <ColumnName className="justify-content-start w-100">Event name</ColumnName>
        <div className="d-flex align-items-center justify-content-end">
          <ColumnName>NOTIFICATION</ColumnName>
        </div>
        <div className="d-flex  align-items-center justify-content-end">
          <ColumnName>EMAIL</ColumnName>
        </div>
      </Row>

      {notificationSettings.map((notificationSetting, i) => (
        <SwitchWrapper className="d-flex flex-row" key={i}>
          <Row>
            <EventName className="w-100">{notificationSetting.name}</EventName>

            <NotificationEventSwitcher
              notificationSetting={notificationSetting}
              onNotificationCreate={onNotificationCreate}
              onNotificationDelete={onNotificationDelete}
            />
          </Row>
        </SwitchWrapper>
      ))}
    </Root>
  );
}

const mapStateToProps = (state: IAppState, {userNotificationEventDictionary}: IExternalProps) => {
  const {selectors: notificationEventsSelectors} = userNotificationEventDictionary;

  return {
    notificationEvents: notificationEventsSelectors.selectItems(state),
    notificationEventsLoading: notificationEventsSelectors.selectCommunication(state, 'itemsLoading'),
    userNotifications: selectors.selectUserNotifications(state),
    userNotificationsLoading: selectors.selectCommunication(state, 'userNotificationsLoading'),
    userNotificationsCreating: selectors.selectCommunication(state, 'userNotificationsCreating'),
    userNotificationsDeleting: selectors.selectCommunication(state, 'userNotificationsDeleting'),
  };
};

const mapDispatchToProps = {
  getUserNotifications: actions.getUserNotifications,
  createUserNotification: actions.createUserNotification,
  deleteUserNotification: actions.deleteUserNotification,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(NotificationSettings));
const Exported = (externalProps: Omit<IExternalProps, 'userNotificationEventDictionary'>) => {
  const {userNotificationEvents} = useDictionaries();

  return (
    <DynamicModuleLoader modules={[UserNotificationsModule]}>
      <Connected userNotificationEventDictionary={userNotificationEvents} {...externalProps} />
    </DynamicModuleLoader>
  );
};

export default Exported;
