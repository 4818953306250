export const BACKEND_DATE = 'YYYY-MM-DD';
export const BACKEND_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss';

export const FRONTEND_DATE = 'MM-DD-YYYY'; // This is incompatible date format on Safari browser. Needs to be removed after careful consideration in the future.
export const FRONTEND_DATE2 = 'MMM DD, YYYY';
export const FRONTEND_DATE3 = 'MM/DD/YYYY';
export const FRONTEND_TIME = 'hh:mm A';
export const FRONTEND_DATE_TIME = `${FRONTEND_DATE} ${FRONTEND_TIME}`;
export const FRONTEND_DATE_TIME2 = `${FRONTEND_DATE2} ${FRONTEND_TIME}`;
export const FRONTEND_DATE_TIME_24H = 'DD/MM/YYYY HH:mm:ss';
