import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.userToUserHub;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectConnectedUsers(state: IAppState) {
  return selectState(state).data.connectedUsers;
}

export function selectMessageQueue(state: IAppState) {
  return selectState(state).data.messageQueue;
}
