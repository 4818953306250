import {useCallback} from 'react';
import styled from 'styled-components';

import OrderActions from 'Admin/AdminDashboard/components/Orders/OrderActions';
import {
  OrderInfoHeaderContent,
  OrderInfoHeaderProps,
} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/common/models';
import {convertOrderDetailsToOrderHistory} from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/converters/order';
import HorseName from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/parts/HorseName';
import OrderPackages from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/parts/OrderPackages';
import OwnerName from 'Admin/AdminDashboard/components/Orders/OrderInfoModal/OrderInfoHeader/OrderInfoHeader/parts/OwnerName';
import {useHorseModal} from 'Admin/AdminDashboard/helpers/hooks/useHorseActions/parts/useHorseModal';
import {useOrderActions} from 'Admin/AdminDashboard/helpers/hooks/useOrderActions/useOrderActions';
import {useUpdateUserModal} from 'Admin/AdminDashboard/helpers/hooks/useUserActions/parts/useUpdateUserModal';
import withOrderActions, {
  getWithOrderActionsModules,
  IWithOrderActionsProps,
} from 'Admin/AdminDashboard/helpers/withOrderActions';
import {IOrderHorseStatuses} from 'Admin/AdminDashboard/models/Order/IOrderHorseStatuses';
import ColorPalette from 'Common/constants/ColorPalette';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';
import {withCurrency} from 'Common/helpers/withCurrency';
import withDate from 'Common/helpers/withDate';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {Cell} from './common/styled';
import HorseStatus from './parts/HorseStatus';

const Label = styled.div`
  margin-bottom: 24px;
  font-family: ${Theme.font.secondary};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  text-transform: uppercase;
  color: ${ColorPalette.gray44};
`;

const Value = styled.div`
  height: 32px;
  font-family: ${Theme.font.primary};
  font-style: normal;
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

const OrderPrice = styled(Value)`
  font-size: 20px;
  line-height: 32px;
`;

const HeaderWrapper = styled.div`
  margin: 16px 32px 32px;
`;

interface IProps {
  horseStatuses: IOrderHorseStatuses[];
}

type ExternalProps = OrderInfoHeaderProps;

type AllProps = ExternalProps & IProps & IWithOrderActionsProps;

const OrderInfoHeader = (props: AllProps) => {
  const {
    orderDetails,
    horsesDetails,
    deleteOrder,
    orderDeleting,
    downloadFormRequesting,
    setSampleStatus,
    setSampleStatusRequesting,
    newSampleRequesting,
    downloadSubmissionForm,
    downloadReportsRequesting,
    downloadReports,
    changeReviewStatus,
    changeReviewStatusRequesting,
    onSuccess,
    horseStatuses,
    statuses,
  } = props;

  const {id: orderId, createDate, owner, paymentDetails} = orderDetails;

  const {updateHorseModal, openUpdateHorseModal} = useHorseModal({horseId: horsesDetails[0].id, onSuccess});
  const {openUpdateUserModal, updateUserModal} = useUpdateUserModal({onSuccess, userId: owner.id});

  const {
    openDeleteModal,
    orderActionsModal,
    openSampleReceivedModal,
    openSampleNotReceivedModal,
    openRequestNewSampleModal,
    openUpdatePaymentModal,
    openDismissReviewConfirmModal,
    dismissReviewConfirmModal,
  } = useOrderActions({
    data: {
      deleteOrderAction: {action: deleteOrder, communication: orderDeleting},
      downloadSubmissionFormAction: {communication: downloadFormRequesting},
      setSampleStatusAction: {action: setSampleStatus, communication: setSampleStatusRequesting},
      requestNewSampleAction: {communication: newSampleRequesting},
      downloadReportsAction: {communication: downloadReportsRequesting},
      dismissReviewAction: {action: changeReviewStatus, communication: changeReviewStatusRequesting},
    },
    onSuccess: onSuccess,
  });

  const openLiveReport = useCallback((horseId: number, orderId?: number) => {
    if (orderId) {
      return `/review-admin/online-report/${horseId}/${orderId}/summary`;
    } else {
      return `/admin/online-report/${horseId}/0/summary`;
    }
  }, []);

  const headerContent: OrderInfoHeaderContent[] = [
    {width: '7%', label: 'ID', value: `#${orderId}`},
    {width: '12%', label: 'DATE', value: withDate(createDate) || 'invalid date'},
    {width: '19%', label: 'OWNER', value: <OwnerName {...props} onEdit={openUpdateUserModal} />},
    {width: '19%', label: 'HORSE', value: <HorseName {...props} onEdit={openUpdateHorseModal} />},
    {
      width: '22%',
      label: 'STATUS',
      value: <HorseStatus {...props} statuses={statuses} />,
    },
    {width: '10%', label: 'PACKAGE', value: <OrderPackages {...props} />},
    {width: '5%', label: 'PRICE', value: <OrderPrice>{withCurrency(paymentDetails.total)}</OrderPrice>},
    {
      width: '6%',
      label: 'ACTIONS',
      value: (
        <div className="d-flex w-100 justify-content-end">
          <OrderActions
            order={convertOrderDetailsToOrderHistory(orderDetails, horseStatuses)}
            deleteOrderHandler={openDeleteModal}
            updatePaymentHandler={openUpdatePaymentModal}
            openLiveReportHandler={openLiveReport}
            submissionFormHandler={downloadSubmissionForm}
            sampleReceivedHandler={openSampleReceivedModal}
            sampleNotReceivedHandler={openSampleNotReceivedModal}
            requestNewSampleHandler={openRequestNewSampleModal}
            downloadResultsHandler={downloadReports}
            dismissReviewHandler={openDismissReviewConfirmModal}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {updateHorseModal}
      {updateUserModal}
      {orderActionsModal}
      {dismissReviewConfirmModal}
      <HeaderWrapper className="d-flex">
        {headerContent.map((item, i) => {
          return (
            <Cell key={i} width={item.width} className="d-flex flex-column">
              <Label>{item.label}</Label>
              <Value className="d-flex h-100 align-items-start">{item.value}</Value>
            </Cell>
          );
        })}
      </HeaderWrapper>
    </>
  );
};

export default withDynamicModules(withOrderActions(OrderInfoHeader), getWithOrderActionsModules());
