import {convertToFormData} from 'Common/helpers/convertToFormData';
import {IObjectResponse} from 'Common/models/IResponse';
import {axiosWrapper} from 'Common/services/AxiosWrapper';

const uploadInbreedingCoefficients = async (file: File): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<null>>(
    `/UpdateInbreedingCoefficientAdmin`,
    convertToFormData(file, 'fPredictFile')
  );
};

export default {
  uploadInbreedingCoefficients,
};
