import React, {useCallback, useEffect, useState} from 'react';
import {GoogleMap, Marker, MarkerProps} from 'react-google-maps';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';

import {withMap} from 'Common/helpers/Map/withMap';
import {InputField} from 'Common/components/FormFields/index';
import {InputFieldProps} from 'Common/components/FormFields/InputField';
import withField from 'Common/components/FormFields/withField';
import {IFieldProps} from 'Common/models/IFieldProps';
import {usePrevious} from 'Common/helpers/hooks/usePrevious';
import {Nullable} from 'Common/types';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import {parseGoogleAddress} from 'Common/helpers/parseGoogleAddress';
import {IAddress} from 'Common/models/IAddress';

const defaultComponentsAddress: IAddress = {
  country: '',
  city: '',
  zip: '',
  state: '',
  street: '',
};

type GoogleAddress = {
  divided: google.maps.GeocoderAddressComponent[];
  formatted: string;
};

const defaultGoogleMapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: true,
  fullscreenControl: false,
};

interface IProps {
  mapStyle?: React.CSSProperties;
  mapName: string;
  componentsName?: string;
}

type AllProps = IProps & InputFieldProps & IFieldProps;

const MapField = (props: AllProps) => {
  const {
    field: {name, value: address},
    mapName,
    componentsName,
    form,
  } = props;
  const {setFieldValue, values} = form;

  const mapLocation: Nullable<ICoordinatedLocation> = values[mapName];

  const currentObjectPosition = mapLocation && new google.maps.LatLng(mapLocation?.latitude, mapLocation?.longitude);

  const [map, setMap] = useState<google.maps.Map>();
  const [searchBox, setSearchBox] = useState<SearchBox>();
  const [marker, setMarker] = useState<MarkerProps | undefined>();
  const [zoom, setZoom] = useState(3);

  const onMapMounted = useCallback((ref) => setMap(ref), [setMap]);
  const onSearchBoxMounted = useCallback((ref) => setSearchBox(ref), [setSearchBox]);

  const isExistingAddress = useCallback((address: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({address}, (results, status) => {
        const result = results?.[0];
        const isPartialSearch = result?.partial_match || result?.types.includes('premise');
        if (status === google.maps.GeocoderStatus.OK && !!result && !isPartialSearch) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }, []);

  const getAddressByPosition = useCallback((position: google.maps.LatLng): Promise<GoogleAddress> => {
    return new Promise((resolve) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({location: position}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && !!results?.[0]) {
          resolve({divided: results[0].address_components, formatted: results[0].formatted_address});
        }
      });
    });
  }, []);

  const setMarkerByPosition = useCallback(
    (position: google.maps.LatLng) => {
      if (!map) {
        return;
      }

      const fetch = async (): Promise<GoogleAddress> => {
        return await getAddressByPosition(position);
      };

      fetch().then((foundAddress) => {
        if (foundAddress) {
          componentsName && setFieldValue(componentsName, parseGoogleAddress(foundAddress.divided));
          setFieldValue(name, foundAddress.formatted);
          setFieldValue(mapName, {...position, latitude: position.lat(), longitude: position.lng()});
          map.panTo(position);
          setMarker({position});
          setZoom(15);
        }
      });
    },
    [map, getAddressByPosition, mapName, setFieldValue, componentsName, name]
  );

  const onChangePlace = useCallback(() => {
    const place = searchBox?.getPlaces()?.[0];

    if (place?.geometry?.location) {
      setMarkerByPosition(place.geometry?.location);
    }
  }, [setMarkerByPosition, searchBox]);

  const onGoogleMapClickHandler = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        setMarkerByPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
      }
    },
    [setMarkerByPosition]
  );

  const clearMarker = useCallback(() => {
    setFieldValue(mapName, null);
    setMarker({});
  }, [setFieldValue, mapName]);

  const prevCurrentObjectPosition = usePrevious(map ? currentObjectPosition : undefined);
  useEffect(() => {
    if (
      currentObjectPosition &&
      !prevCurrentObjectPosition?.equals(currentObjectPosition) &&
      !currentObjectPosition?.equals(new google.maps.LatLng(0, 0))
    ) {
      setMarkerByPosition(currentObjectPosition);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [prevCurrentObjectPosition, setMarkerByPosition]);

  const onInputField = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      e.persist();
      if (e.currentTarget?.value === '') {
        componentsName && setFieldValue(componentsName, defaultComponentsAddress);
        clearMarker();
      }

      isExistingAddress(address).then((isCorrectCurrentAddress) => {
        if (!isCorrectCurrentAddress) {
          clearMarker();
          componentsName && setFieldValue(componentsName, null);
        }
      });
    },
    [clearMarker, isExistingAddress, address, componentsName]
  );

  return (
    <>
      <StandaloneSearchBox ref={onSearchBoxMounted} onPlacesChanged={onChangePlace}>
        <InputField {...props} name={name} onInput={onInputField} />
      </StandaloneSearchBox>

      <GoogleMap
        onClick={onGoogleMapClickHandler}
        zoom={zoom}
        ref={onMapMounted}
        options={defaultGoogleMapOptions}
        defaultCenter={new google.maps.LatLng(39, -101)}
      >
        <Marker position={marker?.position} />
      </GoogleMap>
    </>
  );
};

export default withMap(withField(MapField));
