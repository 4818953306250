import React, {memo, useCallback, useEffect, useState} from 'react';

import {Form, Formik, FormikProps, useFormik, withFormik} from 'formik';
import {IFormValues, initialValues, validationSchema} from './validation';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {DateTimeField, InputField, SelectField} from 'Common/components/FormFields';
import {ErrorMessage, MutableField} from 'Common/components/StyledComponents/StyledComponents';

import styled from 'styled-components';
import Theme from '../../../../Common/constants/Theme';
import Typography from '../../../../Common/constants/Typography';

import ModalWindow from '../../../../Common/components/Modal/ModalWindow';

import {castToOption, getStringKeysOption} from '../../../../Common/helpers/OptionHelper';
import {SelectOwner} from '../../../../HorseProfile/components/HorseProfileForm/parts';
import {DogGender, Gender} from '../../../../Common/constants/Gender';
import {useDogCreate} from '../../helpers/hooks/useDogActions/useDogCreate';

import {dogBreeds} from 'Common/constants/DogBreeds';
import {dogDisciplines} from 'Common/constants/DogDisciplines';
import {FaCloudUploadAlt} from 'react-icons/fa';
import {base64ToFile} from 'Admin/shared/helpers/converters/base64-to-file';
import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {convertToISO} from 'Common/helpers/DateHelper';

const TitleContainer = styled.div`
  padding: 12px 18px 18px 0;
`;

interface ExternalProps {
  onSuccess(): void;
}

type OuterProps = ExternalProps;
type AllProps = FormikProps<IFormValues> & OuterProps;

export default function DogCreateForm(props: AllProps) {
  const {onSuccess} = props;
  // const {dogTestResultsUploading} = props;
  const createDog = useDogCreate();
  const [duplicateElements, setDuplicateElements] = useState<string[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<string>('');

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSelectFile = (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageUrl = reader.result?.toString() || '';
      const imageElement = new Image();
      imageElement.src = imageUrl;

      imageElement.addEventListener('load', (e) => {
        // if (error) setError('')
        const {naturalWidth, naturalHeight} = e.currentTarget as HTMLImageElement;
        if (naturalWidth < 216 || naturalHeight < 144) {
          // setError('Image resolution must be at least 216x144 pixels.')
          setAvatarPreview('');
          return;
        }
        setAvatarPreview(imageUrl);
      });
    });
    reader.readAsDataURL(file);
  };

  return (
    <>
      <ModalWindow isOpen={isOpen} onClose={closeModal}>
        <ModalWindowHeader>Duplicate Sample IDs</ModalWindowHeader>
        <ModalWindowFormContent>
          {duplicateElements?.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </ModalWindowFormContent>
      </ModalWindow>
      <ModalWindowHeader>Create New Dog</ModalWindowHeader>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setIsSubmitting(true);
          const createDogResponse = await createDog({
            name: values.name,
            ownerId: values.userId,
            breed: values.breed,
            gender: values.gender,
            dateOfBirth: values.dateOfBirth ? convertToISO(values.dateOfBirth) : undefined,
            discipline: values.discipline,
          });

          const dogId = createDogResponse?.data?.data;

          if (avatarPreview && dogId) {
            const convertedAvatar = base64ToFile(avatarPreview, 'avatar');
            const formData = new FormData();
            formData.append('DogId', dogId + '');
            formData.append('File', convertedAvatar);

            await axiosWrapper
              .post(`/dogsadmin-avatar/${dogId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .catch((error) => {
                console.error(error);
              });
          }

          setIsSubmitting(false);
          onSuccess();
        }}
      >
        <Form className="d-flex flex-column justify-content-center">
          <ModalWindowFormContent>
            <TitleContainer>
              <SelectOwner isRequired={true} animalType={'Dog'} />
              <MutableField width={50}>
                <InputField
                  isRequired={true}
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Dogs name"
                  autoComplete="off"
                  memoized={true}
                />
              </MutableField>
              <div className="d-flex" style={{gap: '8px'}}>
                <MutableField width={50}>
                  <DateTimeField isRequired={false} name="dateOfBirth" label="Date of birth" memoized={true} />
                </MutableField>
                <MutableField width={50}>
                  <SelectField
                    isRequired={true}
                    name="gender"
                    label="Sex"
                    options={getStringKeysOption(DogGender)}
                    memoized={true}
                  />
                </MutableField>
              </div>
              <div className="d-flex" style={{gap: '8px'}}>
                <MutableField width={50}>
                  <SelectField
                    isMulti={false}
                    name="breed"
                    label="Breeds"
                    options={getStringKeysOption(dogBreeds)}
                    memoized={false}
                    isRequired={true}
                  />
                </MutableField>
                <MutableField width={50}>
                  <SelectField
                    isMulti={false}
                    name="discipline"
                    label="Disciplines"
                    options={getStringKeysOption(dogDisciplines)}
                    memoized={false}
                    isRequired={false}
                  />
                </MutableField>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                  style={{width: '200px', maxHeight: '200px'}}
                  className="rounded"
                  src={!avatarPreview ? 'https://placehold.co/216x144?text=Dog+Photo' : avatarPreview}
                  alt="dog"
                />
                <label htmlFor="avatar-upload" style={{cursor: 'pointer'}}>
                  <span className="d-flex align-items-center" style={{gap: '4px'}}>
                    <FaCloudUploadAlt size={20} /> Choose new image
                  </span>
                </label>
                <input
                  id="avatar-upload"
                  type="file"
                  accept="image/*"
                  onChange={onSelectFile}
                  style={{display: 'none'}}
                />
              </div>
            </TitleContainer>
          </ModalWindowFormContent>
          <ModalWindowFooter>
            <ModalWindowButton type="submit" isLoading={isSubmitting}>
              Save
            </ModalWindowButton>
          </ModalWindowFooter>
        </Form>
      </Formik>
    </>
  );
}
