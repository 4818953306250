import {memo, useCallback, useEffect, useMemo, useState} from 'react';

import UserSearch from 'Admin/AdminDashboard/components/shared/UserSearch/UserSearch';
import {Nullable} from 'Common/types';
import {IFilterRequest} from 'FacetFilter/models/IFilterRequest';
import {IFacetProps} from '../Facet';
import {OperationDropDown} from '../parts/OperationDropDown';
import {Title} from '../parts/styled';
import {SelectStyles} from 'Common/components/Controls/Select/SelectStyles';

const selectStyles: SelectStyles = {
  container: () => ({minHeight: 30}),
  control: () => ({minHeight: 32, maxHeight: 32}),
  indicatorsContainer: () => ({maxHeight: 32}),
  valueContainer: () => ({padding: '0 0 0 56px'}),
  menu: () => ({minWidth: '100%', width: 'auto'}),
};

type AllProps = IFacetProps;

function UserSearchFaset(props: AllProps) {
  const {
    filterProperty,
    filterPropertyOperations,
    filterRequest = {
      property: filterProperty.property,
      operation: filterPropertyOperations?.operations[0].operation || '',
      value: '',
    },
    onSuccess,
  } = props;

  const [request, setRequest] = useState<IFilterRequest>(filterRequest);
  const [selectedValue, setSelectedValue] = useState<Nullable<number>>(null);
  const [defaultUserId, setDefaultUserId] = useState<Nullable<number>>(null);

  useEffect(() => {
    setRequest(filterRequest);
    setSelectedValue(filterRequest.value ? +filterRequest.value : null);
    if (filterRequest.value) {
      setDefaultUserId(+filterRequest.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (request.value) {
      onSuccess(request);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const onSelectOption = useCallback(
    (userId: number) => {
      setRequest({...request, value: userId.toString()});
      setSelectedValue(userId);
    },
    [request]
  );

  const onRemove = useCallback(() => {
    setRequest({...request, value: ''});
  }, [request]);

  const onOperationSelect = useCallback(
    (operation: string) => {
      setRequest({...request, operation});
    },
    [request]
  );

  const hasValue = useMemo(() => !!selectedValue && selectedValue !== 0, [selectedValue]);

  return (
    <>
      <Title>{filterProperty.title}</Title>
      {filterPropertyOperations && (
        <OperationDropDown
          filterOperations={filterPropertyOperations}
          onSelect={onOperationSelect}
          defaultOperation={filterRequest.operation}
        />
      )}
      <div>
        <UserSearch
          defualtUserId={defaultUserId || undefined}
          hasValue={hasValue}
          onSelect={onSelectOption}
          onClear={onRemove}
          selectStyles={selectStyles}
          controlSize="small"
        />
      </div>
    </>
  );
}

export default memo(UserSearchFaset);
