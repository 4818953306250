import {ICommunication, MakeCommunicationActions, makeCommunicationActionType} from 'Common/store/utils/communication';
import {IUserNotification} from 'Notifications/models/IUserNotification';

import {IUserNotificationHistory} from 'Notifications/models/IUserNotificationHistory';

const STATE_NAME = 'userNotification';

export interface IUserNotificationState {
  data: {
    userNotificationHistory: IUserNotificationHistory[];
    userNotifications: IUserNotification[];
  };
  communications: {
    userNotificationHistoryLoading: ICommunication;
    userNotificationsLoading: ICommunication;
    userNotificationsCreating: ICommunication;
    userNotificationsDeleting: ICommunication;
    isReadUserNotificationsUpdating: ICommunication;
    isReadUserNotificationsAllUpdating: ICommunication;
  };
}

export const getUserNotificationHistoryActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'GET_USER_NOTIFICATION_HISTORY'
);
export type getUserNotificationHistoryActions = MakeCommunicationActions<
  typeof getUserNotificationHistoryActionTypes,
  {success: IUserNotificationHistory[]}
>;

export const getUserNotificationsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_USER_NOTIFICATIONS');
export type getUserNotificationsActions = MakeCommunicationActions<
  typeof getUserNotificationsActionTypes,
  {success: IUserNotification[]}
>;

export const createUserNotificationActionTypes = makeCommunicationActionType(STATE_NAME, 'CREATE_USER_NOTIFICATION');
export type createUserNotificationActions = MakeCommunicationActions<
  typeof createUserNotificationActionTypes,
  {success: IUserNotification}
>;

export const deleteUserNotificationActionTypes = makeCommunicationActionType(STATE_NAME, 'DELETE_USER_NOTIFICATION');
export type deleteUserNotificationActions = MakeCommunicationActions<
  typeof deleteUserNotificationActionTypes,
  {success: number}
>;

export const updateIsReadUserNotificationActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_IS_READ_USER_NOTIFICATION'
);
export type upadteIsReadUserNotificationActions = MakeCommunicationActions<
  typeof updateIsReadUserNotificationActionTypes,
  {}
>;

export const updateIsReadUserNotificationAllActionTypes = makeCommunicationActionType(
  STATE_NAME,
  'UPDATE_IS_READ_USER_NOTIFICATION_ALL'
);
export type upadteIsReadUserNotificationAllActions = MakeCommunicationActions<
  typeof updateIsReadUserNotificationAllActionTypes,
  {}
>;

export type UserNotificationsActions =
  | getUserNotificationHistoryActions
  | getUserNotificationsActions
  | createUserNotificationActions
  | deleteUserNotificationActions
  | upadteIsReadUserNotificationActions
  | upadteIsReadUserNotificationAllActions;
