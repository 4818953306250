import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import IElementPosition, {ElementPosition} from 'FoalProfile/models/IElementPosition';
import FoalParentMainInfo from 'FoalProfile/components/FoalProfileHeader/FoalParent/parts/FoalParentMainInfo';
import FoalParentAbilities from 'FoalProfile/components/FoalProfileHeader/FoalParent/parts/FoalParentAbilities';
import {IFoalParent} from 'FoalProfile/models/IFoalParent';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'FoalProfile/store/buildFoal/index';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {BuildFoalModule} from 'FoalProfile/store/buildFoal/buildFoalModule';
import {Nullable} from 'Common/types';
import styled from 'styled-components';
import {breakpoints} from 'Common/constants/Breakpoints';

interface IExternalProps {
  parentId?: string;
  hasPartner: boolean;
  position: ElementPosition;
  changePartnerLink: Nullable<string>;
  onSuccess(parent: IFoalParent): void;
}

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IExternalProps & IConnected;

const GenomicInbreedingWrapper = styled.div<IElementPosition>`
  padding-bottom: 35px;

  ${(prop) =>
    prop.position === ElementPosition.Left
      ? `padding-right: 25px; padding-left: 4px;`
      : `padding-left: 25px; padding-right: 4px;`};

  @media ${breakpoints.sm} {
    padding-top: 15px;
    padding-bottom: 20px;
    ${(prop) => (prop.position === ElementPosition.Left ? `padding-right: 35px;` : `padding-left: 35px;`)};
  }
`;

const FoalParentGenomicInbreeding = styled.div<IElementPosition>`
  display: inline-block;
  width: auto;
  border-radius: 9999px;
  padding: 0.25rem 1rem 0.25rem 1rem;
  background-color: #fff;
  font-size: 11px;

  ${(prop) => (prop.position === ElementPosition.Left ? `float: right;` : ``)}

  @media ${breakpoints.sm} {
    font-size: 16px;
  }
`;

const FoalParent = (props: AllProps) => {
  const {position, parentId, hasPartner, changePartnerLink, onSuccess} = props;
  const {parent, parentLoading, getParent} = props;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (parentId && parentId !== '0') {
      getParent(+parentId, position);
    }
  }, [parentId, position, getParent]);

  useEffect(() => {
    if (parent) {
      onSuccess(parent);
    }
  }, [parent, onSuccess]);

  const path = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;

  const onSelectHorse = (horseId: number) => {
    if (parentId !== '0' && location.pathname.includes(`${parentId}`)) {
      navigate(`${path.replace(`${parentId}`, `${horseId}`)}`);
      return;
    }

    if (hasPartner) {
      navigate(`${path.replace('/0', `/${horseId}`)}`);
      return;
    }

    if (position === ElementPosition.Left) {
      navigate(`${path}/${horseId}/0`);
    } else {
      navigate(`${path}/0/${horseId}`);
    }
  };

  return (
    <div className="d-flex flex-column w-50">
      <FoalParentMainInfo
        isLoading={parentLoading.isRequesting}
        parent={parent}
        elementPosition={position}
        linkAnotherPartner={changePartnerLink || ''}
        onSelectAnotherPartner={onSelectHorse}
      />
      <FoalParentAbilities abilities={parent?.abilities} position={position} />
      {parent?.fValue && (
        <GenomicInbreedingWrapper position={position}>
          <FoalParentGenomicInbreeding position={position}>
            Genomic Inbreeding Value: <strong>{(parent.fValue * 100).toFixed(2)}</strong>%
          </FoalParentGenomicInbreeding>
        </GenomicInbreedingWrapper>
      )}
    </div>
  );
};

const firstParentMapStateToProps = (state: IAppState) => ({
  parent: selectors.selectFirstParent(state),
  parentLoading: selectors.selectCommunication(state, 'firstParentLoading'),
});

const secondParentMapStateToProps = (state: IAppState) => ({
  parent: selectors.selectSecondParent(state),
  parentLoading: selectors.selectCommunication(state, 'secondParentLoading'),
});

const mapStateToProps = (state: IAppState, {position}: IExternalProps) =>
  position === ElementPosition.Left ? firstParentMapStateToProps : secondParentMapStateToProps;

const mapDispatchToProps = {
  getParent: actions.getParent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(FoalParent), BuildFoalModule);
