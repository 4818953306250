import React, {memo, useCallback, useEffect, useState} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import Loading from 'Loading/components/Loading';
import {FileUploaderField} from 'Common/components/FormFields';
import {convertFormValuesToUploadRequest, IFormValues, initialValue, validationSchema} from './validation';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminDogTestResultsUpload';
import {useCommunicationToToast} from 'Common/helpers/hooks/useCommunicationToToast';
import {getErrorCode, getErrorDetails} from 'Common/helpers/ErrorHelper';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {AdminDogTestResultsUploadModule} from 'Admin/AdminDashboard/store/adminDogTestResultsUpload/adminDogTestResultsUploadModule';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import Typography from 'Common/constants/Typography';
import Theme from 'Common/constants/Theme';
import {IDog} from '../../../../Dogs/models/IDog';

const TitleContainer = styled.div`
  padding: 12px 18px 18px 0;
`;

const Value = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.normal400};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  color: ${Theme.color.black};
`;

interface ExternalProps {
  dog: IDog;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & ExternalProps;
type AllProps = FormikProps<IFormValues> & OuterProps;

function DogTestResultsUploadForm(props: AllProps) {
  const {isSubmitting} = props;
  const {dogTestResultsUploading} = props;

  const [duplicateElements, setDuplicateElements] = useState<string[]>();
  const [isOpen, setIsOpen] = useState(false);

  const isRequesting = dogTestResultsUploading.isRequesting;
  const errorDetails = getErrorDetails(dogTestResultsUploading.error);
  const errorCode = dogTestResultsUploading.error && getErrorCode(dogTestResultsUploading.error);

  useEffect(() => {
    if (errorCode === 'DuplicateElement' && errorDetails && errorDetails?.length > 0) {
      const duplicates: string[] = [];
      errorDetails?.forEach((x) => Object.keys(x).forEach((key) => duplicates?.push(key)));
      setDuplicateElements(duplicates);
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode]);

  useCommunicationToToast(dogTestResultsUploading, 'Files was succesfully uploaded and processed');

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <ModalWindow isOpen={isOpen} onClose={closeModal}>
        <ModalWindowHeader>Duplicate Sample IDs</ModalWindowHeader>
        <ModalWindowFormContent>
          {duplicateElements?.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </ModalWindowFormContent>
      </ModalWindow>
      <ModalWindowHeader>
        Upload files for {props.dog.name}(SID: {props.dog.sampleId})
      </ModalWindowHeader>
      <div className="d-flex flex-column justify-content-center">
        {isRequesting && <Loading />}
        <Form>
          <ModalWindowFormContent>
            <TitleContainer>
              <div className="d-flex">
                <Value>Dog Test Results files</Value>
              </div>
            </TitleContainer>

            <FileUploaderField isRequired={true} name="dogTestResultsFile" placeholder="Select a file to upload" />
          </ModalWindowFormContent>
          <ModalWindowFooter>
            <ModalWindowButton type="submit" isLoading={isSubmitting}>
              Upload
            </ModalWindowButton>
          </ModalWindowFooter>
        </Form>
      </div>
    </>
  );
}

const DogTestResultsUploadFormik = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: () => ({...initialValue}),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {onSuccess} = formikBag.props;
    try {
      formikBag.setSubmitting(true);
      await formikBag.props.uploadDogTestResultsFiles(
        formikBag.props.dog.id.toString(),
        convertFormValuesToUploadRequest(values.dogTestResultsFile!)
      );
      formikBag.setSubmitting(false);
      onSuccess && onSuccess();
    } catch (err) {}
  },
  enableReinitialize: true,
})(DogTestResultsUploadForm);

const mapStateToProps = (state: IAppState) => ({
  dogTestResultsUploading: selectors.selectCommunication(state, 'dogTestResultsUploading'),
});

const mapDispatchToProps = {
  uploadDogTestResultsFiles: actions.uploadDogTestResultsFiles,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(DogTestResultsUploadFormik));

export default withDynamicModules(memo(Connected), AdminDogTestResultsUploadModule);
