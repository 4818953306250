import React, {memo} from 'react';
import styled from 'styled-components';

import {breakpoints} from 'Common/constants/Breakpoints';
import {IPotentialPartner} from 'FindHorse/models/PotentialPartners';
import ColorPalette from 'Common/constants/ColorPalette';

import PotentialPartner from './PotentialPartner/PotentialPartner';

const Root = styled.div<{showOneColumn?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${p => (p.showOneColumn ? '100%' : '50% 50%')};
  grid-gap: 20px;
`;

const PotentialPartnerContainer = styled.div`
  height: auto;

  @media ${breakpoints.md} {
    min-height: 184px;
    border-bottom: 1px solid ${ColorPalette.white9};
  }
`;

interface IProps {
  horses: IPotentialPartner[];
  showOneColumn?: boolean;
}

function PotentialPartners(props: IProps) {
  const {horses, showOneColumn = true} = props;

  return (
    <Root showOneColumn={showOneColumn}>
      {horses.map((horse, index) => (
        <PotentialPartnerContainer key={index}>
          <PotentialPartner horse={horse} />
        </PotentialPartnerContainer>
      ))}
    </Root>
  );
}

export default memo(PotentialPartners);
