import {useCallback, useEffect, useState} from 'react';

import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {NotificationEventType} from 'Notifications/const/NotificationEventType';
import {INotificationMessage} from 'SignalR/models/INotificationMessage';
import {EventName} from 'Common/constants/EventName';
import {parseEventNotificationDetails} from 'Notifications/helpers/parseEventNotificationDetails';

interface ISystemNotificationHookProps {
  systemNotificationTypes: NotificationEventType | NotificationEventType[];
  onSuccess(): void;
}

export function useSystemNotification(props: ISystemNotificationHookProps) {
  const {onSuccess, systemNotificationTypes} = props;

  const [details, setDetails] = useState<any>();

  const {listen} = useEventBus();

  const onSuccessNotification = useCallback(
    (message: INotificationMessage) => {
      const {code, details} = message;

      const parsedDetails = parseEventNotificationDetails(details, code);
      const detailsContent = parsedDetails.rawDetails;

      setDetails(detailsContent);

      const systemNotificationTypesArray = Array.isArray(systemNotificationTypes)
        ? systemNotificationTypes
        : [systemNotificationTypes];

      if (systemNotificationTypesArray.includes(code)) {
        onSuccess();
      }
    },
    [onSuccess, systemNotificationTypes]
  );

  useEffect(() => {
    const notificationReceived = listen(EventName.SystemNotificationReceived, onSuccessNotification);

    return () => notificationReceived.stopListening();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {details};
}
