import {action} from 'typesafe-actions';
import {IAppState} from 'Common/store/IAppState';

import {ActionResult} from 'Common/store/store';
import {getDogsActions, getDogsActionTypes} from 'Admin/AdminDashboard/store/adminDogs/types';
import {IRequestParams} from 'Common/models/IRequestParams';
import DogDataService from 'Admin/AdminDashboard/services/DogDataService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';

export const getDogs =
  (params?: IRequestParams): ActionResult<IAppState, Promise<void>, getDogsActions> =>
  async (dispatch) => {
    dispatch(action(getDogsActionTypes.REQUEST));
    try {
      const res = await DogDataService.getDogs(params);
      dispatch(action(getDogsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getDogsActionTypes.FAILURE, getErrorMessage(error)));
      throw error;
    }
  };
