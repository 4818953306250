import React, {useEffect, useMemo} from 'react';
import {Form, FormikProps, withFormik} from 'formik';
import {connect, ConnectedProps} from 'react-redux';

import {RequiredBy} from 'Common/types';

import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {IAppState} from 'Common/store/IAppState';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {FormType} from 'Common/constants/FormType';
import {InputField, TextAreaField} from 'Common/components/FormFields/index';
import Loading from 'Loading/components/Loading';
import {useOnSuccessCommunication} from 'Common/helpers/hooks/useOnSuccessCommunication';
import {actions, selectors} from 'Admin/AdminSettings/stores/adminStaticResources/index';
import FileUploaderField from 'Common/components/FormFields/FileUploaderField';
import {
  convertToClient,
  convertToServerCreating,
  convertToServerUpdating,
  IFormValues,
  initialValue,
  validationSchema,
} from 'Admin/AdminSettings/components/StaticResources/validation';
import {imageUrlRegex} from 'Common/constants/imageUrlRegex';
import ImageUploaderField from 'Common/components/FormFields/ImageUploaderField';
import {IconName} from 'Icon/components/Icon';

const formHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add item',
  edit: 'Edit item',
};

const buttonHeaderByType: Record<FormType.create | FormType.edit, string> = {
  create: 'Add',
  edit: 'Save',
};

interface IExternalProps {
  staticResourceId?: number;
  type: FormType;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type OuterProps = IConnected & IExternalProps;

type AllProps = FormikProps<IFormValues> & OuterProps;

const StaticResourcesFormLayout = (props: AllProps) => {
  const {status, setStatus, values} = props;
  const {staticResourceId, type, onSuccess} = props;
  const {
    getStaticResource,
    resetStaticResource,
    staticResourceLoading,
    staticResourceCreating,
    staticResourceUpdating,
    staticResourceFileUploading,
  } = props;

  const errorInfo = staticResourceLoading.error || staticResourceUpdating.error || staticResourceFileUploading.error;
  const isLoading =
    staticResourceLoading.isRequesting ||
    staticResourceUpdating.isRequesting ||
    staticResourceFileUploading.isRequesting;

  const header = formHeaderByType[type];
  const saveButtonHeader = buttonHeaderByType[type];

  useOnSuccessCommunication(staticResourceCreating, onSuccess);
  useOnSuccessCommunication(staticResourceUpdating, onSuccess);

  useEffect(() => {
    const commonError = getCommonErrors(errorInfo);
    commonError && setStatus(commonError);
  }, [errorInfo, setStatus]);

  useEffect(() => {
    if (staticResourceId) {
      getStaticResource(staticResourceId);
    }

    return resetStaticResource;
  }, [staticResourceId, getStaticResource, resetStaticResource]);

  const isImage = values.image?.url && imageUrlRegex.test(values.image.url);

  const fileSection = useMemo(() => {
    if (type === FormType.create || !isImage) {
      return (
        <FileUploaderField
          isRequired={true}
          name="file"
          label="Static resource file"
          placeholder="Select a file to upload"
        />
      );
    }

    return (
      <ImageUploaderField
        name="image"
        showEditButton={false}
        showDownloadButton={true}
        iconProps={{name: IconName.RearedHorse, width: 48, height: 60}}
        title="Add image"
        isShowBlurredBackground={false}
      />
    );
  }, [isImage, type]);

  return (
    <>
      <ModalWindowHeader>{header}</ModalWindowHeader>
      <Form className="d-flex flex-column justify-content-center">
        <ModalWindowFormContent>
          {isLoading && <Loading />}
          <InputField isRequired={true} name="name" type="text" label="Name" placeholder="Name" className="w-50" />
          <TextAreaField name="description" label="Description" placeholder="Description" />
          {fileSection}
        </ModalWindowFormContent>
        <ModalWindowFooter>
          <ErrorMessage>{status}</ErrorMessage>
          <ModalWindowButton type="submit" isLoading={props.isSubmitting}>
            {saveButtonHeader}
          </ModalWindowButton>
        </ModalWindowFooter>
      </Form>
    </>
  );
};

const StaticResourcesForm = withFormik<OuterProps, IFormValues>({
  mapPropsToValues: ({staticResource}) => (staticResource ? convertToClient(staticResource) : initialValue),
  validationSchema,
  handleSubmit: async (values, formikBag) => {
    const {type, createStaticResource, updateStaticResource, uploadStaticResourceFile} = formikBag.props;

    formikBag.setSubmitting(true);

    if (type === FormType.create) {
      await createStaticResource(convertToServerCreating(values));
    }

    if (type === FormType.edit) {
      try {
        !!values.file && (await uploadStaticResourceFile(values.id!, values.file));
      } catch (err) {
        formikBag.setStatus(err);
        return;
      }

      await updateStaticResource(convertToServerUpdating(values as RequiredBy<IFormValues, 'id'>));
    }
    formikBag.setSubmitting(false);
  },
  enableReinitialize: true,
})(StaticResourcesFormLayout);

const mapStateToProps = (state: IAppState) => ({
  staticResource: selectors.selectStaticResource(state),
  staticResourceLoading: selectors.selectCommunication(state, 'staticResourceLoading'),
  staticResourceCreating: selectors.selectCommunication(state, 'staticResourceCreating'),
  staticResourceUpdating: selectors.selectCommunication(state, 'staticResourceUpdating'),
  staticResourceFileUploading: selectors.selectCommunication(state, 'staticResourceFileUploading'),
});

const mapDispatchToProps = {
  getStaticResource: actions.getStaticResource,
  createStaticResource: actions.createStaticResource,
  updateStaticResource: actions.updateStaticResource,
  uploadStaticResourceFile: actions.uploadStaticResourceFile,
  resetStaticResource: actions.resetStaticResourceResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(React.memo(StaticResourcesForm));
