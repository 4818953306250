import * as R from 'ramda';

import {IHorseFiltersFormValues} from 'Filters/components/HorseFilters/formConfig';
import {GenePoolsType} from 'Filters/models/GenePools';
import {mapValueToAbilities} from 'Common/helpers/abilities';
import {AbilityType} from 'Common/constants/AbilityType';
import {IColor} from 'Dictionaries/models/IColor';
import {IAbilities} from 'Dictionaries/models/IAbilities';
import {getAbilitiesByType} from 'Common/helpers/getAbilitiesByType';
import {IChip} from 'FindHorse/components/Chips/Chips';

const getAgePeriod = (value: number) => `${value} year${value > 1 ? 's' : ''}`;

interface IFormValues extends IHorseFiltersFormValues {
  horseName?: string;
  currentPage?: number;
}

const getSelectedFilters = (name: SelectedFilterName, label: string, length: number): SelectedFilter => ({
  name,
  label: `${label}: ${length} selected`,
});

const isExist = (value: unknown) => !R.isEmpty(value) && !!value;

export function convertSelectedFiltersToChips(
  values: IFormValues,
  initialValues: IFormValues,
  colors: IColor[],
  abilitiesDictionary: IAbilities[],
  pinnedChips?: PinnedSelectedFilter[]
): IChip[] {
  const {
    gender,
    ageFrom,
    ageTo,
    color,
    distance,
    sourceTypes,
    abilities,
    isGaitSelected,
    isSpeedSelected,
    isTemperamentSelected,
  } = values;

  const age = ((): SelectedFilter | undefined => {
    if (!R.isNil(ageFrom) && !R.isNil(ageTo) && !R.equals(ageFrom, ageTo)) {
      return {name: 'ages', label: `Age: from ${getAgePeriod(ageFrom)} to ${getAgePeriod(ageTo)}`};
    }
    if (!R.isNil(ageFrom) && !R.isNil(ageTo)) {
      return {name: 'ages', label: `Age: ${getAgePeriod(ageFrom)}`};
    }
    if (!R.isNil(ageFrom)) {
      return {
        name: 'ageFrom',
        label: `Age: older than ${getAgePeriod(ageFrom)}`,
        pinnedChip: pinnedChips && pinnedChips?.find((x) => x.name === 'ageFrom')?.pinnedChip,
      };
    }
    if (!R.isNil(ageTo)) {
      return {name: 'ageTo', label: `Age: younger than ${getAgePeriod(ageTo)}`};
    }
    return undefined;
  })();

  const convertToSelectedValue = ({key, label}: {key: keyof IFormValues; label: string}) =>
    isExist(values[key]) &&
    !R.equals(values[key], initialValues[key]) &&
    getSelectedFilters(key, label, (values[key] as number[])?.length || 0);

  const radius =
    sourceTypes?.includes(GenePoolsType.LocationRadius) &&
    distance?.value &&
    distance.value !== initialValues.distance?.value &&
    ({name: 'distance', label: `Radius less then ${distance.value} km`} as SelectedFilter);

  const multipleValues: Array<{key: keyof IFormValues; label: string}> = [
    {key: 'sourceTypes', label: 'Gene pools'},
    {key: 'commercialTypes', label: 'Available for'},
    {key: 'colorModifiers', label: 'Modifiers'},
    {key: 'breeds', label: 'Breeds'},
    {key: 'disciplines', label: 'Disciplines'},
    {key: 'healthVariantsToAvoid', label: 'Issues to avoid'},
  ];
  const multipleValuesFilters: Array<SelectedFilter | false> = multipleValues.map(convertToSelectedValue);

  const speedAbilities = getAbilitiesByType(abilitiesDictionary, AbilityType.Speed);
  const temperamentAbilities = getAbilitiesByType(abilitiesDictionary, AbilityType.Temperament);
  const gaitAbilities = getAbilitiesByType(abilitiesDictionary, AbilityType.Gait);

  const abilitiesFilters = [
    isSpeedSelected &&
      abilities?.speed &&
      ({
        name: 'speed',
        label: `Speed: ${speedAbilities.filter((x) => x.state === mapValueToAbilities[abilities.speed!])[0]?.name}`,
      } as SelectedFilter),
    isTemperamentSelected &&
      abilities?.temperament &&
      ({
        name: 'temperament',
        label: `Temperament: ${
          temperamentAbilities.filter((x) => x.state === mapValueToAbilities[abilities.temperament!])[0]?.name
        }`,
      } as SelectedFilter),
    isGaitSelected &&
      abilities?.gait &&
      ({
        name: 'gait',
        label: `DMRT3: ${gaitAbilities.filter((x) => x.state === mapValueToAbilities[abilities.gait!])[0]?.name}`,
      } as SelectedFilter),
  ] as SelectedFilter[];

  const selectedColor = color && colors.find((c) => c.id === color);
  const colorFilter = selectedColor && ({name: 'color', label: `Color: ${selectedColor.name}`} as SelectedFilter);

  const associations =
    sourceTypes?.includes(GenePoolsType.Registries) &&
    convertToSelectedValue({key: 'associations', label: 'Associations'});
  return [
    gender && initialValues.gender !== gender && ({name: 'gender', label: `Gender: ${gender}`} as SelectedFilter),
    age,
    radius,
    colorFilter,
    associations,
    ...abilitiesFilters,
    ...multipleValuesFilters,
  ].filter(Boolean) as SelectedFilter[];
}

export type SelectedFilterName = keyof IFormValues | 'ages' | 'radius' | 'speed' | 'gait' | 'temperament';
export type SelectedFilter = {name: SelectedFilterName; label: string; pinnedChip?: boolean};
export type PinnedSelectedFilter = {name: SelectedFilterName; pinnedChip: boolean};
