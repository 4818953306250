import {connect, ConnectedProps} from 'react-redux';
import React, {useEffect} from 'react';

import {actions, selectors} from 'Admin/AdminDashboard/store/adminOrders/index';
import {IAppState} from 'Common/store/IAppState';

import Loading from 'Loading/components/Loading';

import OrderHorses from 'Admin/AdminDashboard/components/Orders/OrderHorses/OrderHorses';
import {UpdateOrderContext} from 'Admin/AdminDashboard/components/Orders/OrderHorses/useOnUpdateOrder';
import {ITableProps} from 'Common/components/Table/Table';
import Theme from 'Common/constants/Theme';
import {IOrderHorseDetailsAdmin} from 'Admin/AdminDashboard/models/Order/IOrderHorseDetailsAdmin';
import {IOrderActivationCode} from 'ActivationCode/models/IOrderActivationCode';

const tableProps: Partial<ITableProps<IOrderHorseDetailsAdmin>> = {
  expandedRowStyles: {
    toggle: {backgroundColor: 'transparent', marginLeft: 68},
    toggleIcon: {color: Theme.color.black},
    expandedRow: {boxShadow: 'none'},
    content: {paddingLeft: '85px'},
  },
};

interface IOwnProps {
  orderId: number;
  createDate: string;
  owner: string;
  onChange(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function OrderDetails(props: Props) {
  const {createDate, owner, orderDetails, getOrderDetails, resetOrderDetails, orderId, loadingOrderDetails, onChange} =
    props;

  useEffect(() => {
    getOrderDetails(orderId);
  }, [orderId, getOrderDetails]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetOrderDetails, []);

  if (loadingOrderDetails.isRequesting) {
    return <Loading />;
  }

  if (loadingOrderDetails.error) {
    return <span>Error on order details loading</span>;
  }

  if (!orderDetails || orderDetails.id !== orderId) {
    return null;
  }

  const {horsesDetails, paymentDetails} = orderDetails;

  return (
    <UpdateOrderContext.Provider value={{onUpdateOrder: onChange}}>
      <OrderHorses
        orderId={orderId}
        createDate={createDate}
        horsesDetails={horsesDetails}
        priceDetails={paymentDetails}
        withRightPadding={false}
        tableProps={tableProps}
        isClearStatuses={true}
      />
    </UpdateOrderContext.Provider>
  );
}

const mapStateToProps = (state: IAppState) => ({
  orderDetails: selectors.selectOrderDetails(state),
  loadingOrderDetails: selectors.selectCommunication(state, 'orderDetailsLoading'),
});

const mapDispatchToProps = {
  getOrderDetails: actions.getOrderDetails,
  resetOrderDetails: actions.resetOrderDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default React.memo(connector(OrderDetails));
