import {axiosWrapper} from 'Common/services/AxiosWrapper';
import {IListResponse, IObjectResponse} from 'Common/models/IResponse';
import {IUserNotificationHistory} from 'Notifications/models/IUserNotificationHistory';
import {IUserNotification} from 'Notifications/models/IUserNotification';
import {NotificationType} from 'Notifications/const/NotificationType';

const getUserNotificationHistory = async (): Promise<IUserNotificationHistory[]> => {
  const response = await axiosWrapper.get<IListResponse<IUserNotificationHistory>>(`/UserNotificationHistoryRead`);
  return response.data.data;
};

const getUserNotifications = async (): Promise<IUserNotification[]> => {
  const response = await axiosWrapper.get<IListResponse<IUserNotification>>(`/UserNotificationRead`);
  return response.data.data;
};

const createUserNotification = async (eventId: number, type: NotificationType): Promise<number> => {
  const response = await axiosWrapper.post<IObjectResponse<number>>(`/UserNotificationCreate`, {eventId, type});
  return response.data.data;
};

const deleteUserNotification = async (notificationId: number): Promise<void> => {
  await axiosWrapper.delete<IObjectResponse<null>>(`/UserNotificationDelete/${notificationId}`);
};

const updateIsReadUserNotification = async (notificationId: number): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<void>>(`/UserNotificationHistoryIsReadUpdate/`, notificationId);
};

const updateIsReadUserNotificationAll = async (): Promise<void> => {
  await axiosWrapper.put<IObjectResponse<void>>(`/UserNotificationHistoryIsReadUpdateAll`);
};

export default {
  getUserNotificationHistory,
  getUserNotifications,
  createUserNotification,
  deleteUserNotification,
  updateIsReadUserNotification,
  updateIsReadUserNotificationAll,
};
