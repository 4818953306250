export interface IOrderStatusFields {
  paymentStatus: OrderStatusField;
  sampleStatus: HorseSampleStatus;
  resultsStatus: OrderStatusField;
  reviewStatus: OrderStatusField;
  releaseStatus: OrderStatusField;
}

export enum OrderStatusField {
  Waiting = 'Waiting',
  Ready = 'Ready',
}

export enum HorseSampleStatus {
  Undefined = 'Undefined',
  Waiting = 'Waiting',
  Received = 'Received',
  Broken = 'Broken',
}
