import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import {hexToRgba} from 'Common/helpers/hexToRgba';
import Theme from 'Common/constants/Theme';
import {AncestryReportSection, Description} from '../parts/common/styled';
import Exmoor from './common/img/exmoor.png';
import CarriageHorse from './common/img/carriage-horse.jpg';
import HeavyHorse from './common/img/heavy-horse.jpg';
import NearEast from './common/img/near-east.png';
import Iberian from './common/img/iberian.jpg';
import NorthSea from './common/img/north-sea.jpg';
import Thoroughbred from './common/img/thoroughbred.png';
import {breakpoints} from 'Common/constants/Breakpoints';
import Image from 'Common/components/Image/Image';

const horses = [
  {src: Exmoor, color: ColorPalette.orange0, label: 'British Isles Native/Exmoor', description: 'Exmoor'},
  {
    src: CarriageHorse,
    color: ColorPalette.brown1,
    label: 'Carriage Horse',
    description: 'French Trotter, Morgan, Saddlebred, Standardbred, Tennessee Walking Horse',
  },
  {
    src: HeavyHorse,
    color: ColorPalette.violet0,
    label: 'Heavy Horse',
    description: 'Belgian, Clydesdale, Fell Pony, Franches Montagnes, Percheron',
  },
  {
    src: Iberian,
    color: ColorPalette.yellow14,
    label: 'Iberian',
    description: 'Andalusian, Lusitano, Mangalara Paulista, Pura Raza Espanola',
  },
  {src: NearEast, color: ColorPalette.red14, label: 'Near East', description: 'Arabian, Akhal Teke, Caspian'},
  {
    src: NorthSea,
    color: ColorPalette.green4,
    label: 'North Sea',
    description: 'Fjord, Icelandic, North Swedish, Shetland',
  },
  {src: Thoroughbred, color: ColorPalette.blue4, label: 'Thoroughbred', description: 'Thoroughbred'},
];

export const Container = styled.div`
  padding: 16px 0;

  @media ${breakpoints.md} {
    padding: 16px 60px;
  }
`;

export const HorseWrapper = styled.div`
  width: 100%;
  padding: 16px 0 10px 0;

  @media ${breakpoints.sm} {
    width: 50%;
  }

  @media ${breakpoints.md}, print {
    width: 33%;
  }
`;

export const Horse = styled.div<{borderColor: string}>`
  width: 230px;
  height: 230px;
  border: 16px solid ${({borderColor}) => borderColor};
  border-radius: 50%;
  background: ${Theme.color.white} no-repeat center;
  color: ${Theme.color.white};
  margin-bottom: 16px;

  @media ${breakpoints.sm} {
    width: 210px;
    height: 210px;
  }

  @media ${breakpoints.md} {
    width: 230px;
    height: 230px;
  }
`;

export const LabelWrapper = styled.div`
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size12};
  background-color: ${Theme.color.white};
  color: ${ColorPalette.black0};
  box-shadow: 0 2px 4px ${hexToRgba(ColorPalette.black0, 0.1)};
  left: 0;
  bottom: -24px;
  padding: 8px 14px;
  border-radius: 20px;
`;

export const DescriptionWrapper = styled.div`
  max-width: 230px;
  font-size: ${Typography.size.size12};
  color: ${ColorPalette.red15};
`;

const GeneticCompositionKey = () => {
  return (
    <AncestryReportSection>
      <div className="d-flex w-100 flex-column">
        <Container className="d-flex w-100 justify-content-center align-items-start flex-wrap">
          {horses.map(({src, label, color, description}, i) => (
            <HorseWrapper key={i} className="d-flex flex-column align-items-center">
              <Horse
                className="d-flex position-relative align-items-center justify-content-center text-center"
                borderColor={color}
              >
                <Image src={src} style={{width: '73%'}} alt={label} />
                <LabelWrapper className="w-100 position-absolute">{label}</LabelWrapper>
              </Horse>
              <DescriptionWrapper className="text-center">{description}</DescriptionWrapper>
            </HorseWrapper>
          ))}
        </Container>
        <Description>
          Equine ancestry is analyzed based on regional breeding practices and evolutionary drift. Horses from each
          region of the world, group or "breed" will have genes in common and overlapping regions that can be
          identified, compared and graphed. Where horses have been closely bred (due to close proximity) or purposely
          bred by man (to suit a discipline or need) the genetic content of those horses will "cluster" together on a
          graph and may correlate with "breed" labels. Registries, breeds or closed groups of horses bred through longer
          periods of history will have more clearly defined gene clusters. Often, these clusters will be entirely
          separated from most horse samples. Take a look at the scatter plot on the following page to see an
          illustration of the reference horse population and where your horse falls on the graph.
        </Description>
      </div>
    </AncestryReportSection>
  );
};

export default memo(GeneticCompositionKey);
