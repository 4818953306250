import * as React from 'react';

import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';
import ColoredIcon from 'Icon/components/ColoredIcon';

export interface IProps {
  probability: number;
  higherIsBetter?: boolean;
}

const TriangleProbabilityIndicator = (props: IProps) => {
  const triangleProps = {
    fill: true,
    width: 10,
    height: 8,
    size: undefined,
    name: IconName.PossibilityTriangle
  };
  const commonStyle = {marginTop: 1};

  if (props.probability > 75 && props.probability <= 100) {
    return (
      <ColoredIcon
        color={props.higherIsBetter ? ColorPalette.green0 : ColorPalette.red1}
        {...triangleProps}
        style={{transform: props.higherIsBetter ? undefined : 'rotate(-180deg)', ...commonStyle}}
      />
    );
  }
  if (props.probability > 25 && props.probability <= 75) {
    return (
      <ColoredIcon
        color={ColorPalette.yellow1}
        {...triangleProps}
        style={{transform: props.higherIsBetter ? 'rotate(-180deg)' : undefined, ...commonStyle}}
      />
    );
  }
  if (props.probability > 0 && props.probability <= 25) {
    return (
      <ColoredIcon
        color={props.higherIsBetter ? ColorPalette.red1 : ColorPalette.green0}
        {...triangleProps}
        style={{transform: props.higherIsBetter ? 'rotate(-180deg)' : undefined, ...commonStyle}}
      />
    );
  }
  return <div />;
};

export default TriangleProbabilityIndicator;
