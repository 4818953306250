import {action} from 'typesafe-actions';

import {ActionResult} from 'Common/store/store';
import {
  BusinessPortalHorseProfileActions,
  getHorseAbilitiesActions,
  getHorseAbilitiesActionTypes,
  getHorseBreedPanelActions,
  getHorseBreedPanelActionTypes,
  getHorseGenotypeGroupsActions,
  getHorseGenotypeGroupsActionTypes,
  getHorseLastOrderActions,
  getHorseLastOrderActionTypes,
  getHorseOwnerActions,
  getHorseOwnerActionTypes,
  getHorseParantageReportActions,
  getHorseParantageReportActionTypes,
  getOwnerHorsesActions,
  getOwnerHorsesAtionTypes,
  resetHorseAbilitiesActionTypes,
  resetHorseBreedPanelActionTypes,
  resetHorseGenotypeGroupsActionTypes,
  resetHorseLastOrderActionTypes,
  resetHorseParantageReportActionTypes,
} from './types';
import {IAppState} from 'Common/store/IAppState';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import BusinessPortalHorseProfileService from 'BusinessPortal/services/BusinessPortalHorseProfileService';

export const getHorseReports =
  (horseId: number): ActionResult<IAppState, void, getHorseParantageReportActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseParantageReportActionTypes.REQUEST));
      const res = await BusinessPortalHorseProfileService.getHorseParantageReport(horseId);
      dispatch(action(getHorseParantageReportActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseParantageReportActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseAbilities =
  (horseId: number): ActionResult<IAppState, void, getHorseAbilitiesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseAbilitiesActionTypes.REQUEST));
      const res = await BusinessPortalHorseProfileService.getHorseAbilities(horseId);
      dispatch(action(getHorseAbilitiesActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorseAbilitiesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseGenotypeGroups =
  (horseId: number): ActionResult<IAppState, void, getHorseGenotypeGroupsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseGenotypeGroupsActionTypes.REQUEST));
      const res = await BusinessPortalHorseProfileService.getHorseGenotypeGroups(horseId);
      dispatch(action(getHorseGenotypeGroupsActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorseGenotypeGroupsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseBreedPanel =
  (horseId: number): ActionResult<IAppState, void, getHorseBreedPanelActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseBreedPanelActionTypes.REQUEST));
      const res = await BusinessPortalHorseProfileService.getHorseBreedPanel(horseId);
      dispatch(action(getHorseBreedPanelActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getHorseBreedPanelActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseLastOrder =
  (horseId: number): ActionResult<IAppState, void, getHorseLastOrderActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseLastOrderActionTypes.REQUEST));
      const res = await BusinessPortalHorseProfileService.getHorseLastOrder(horseId);
      dispatch(action(getHorseLastOrderActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getHorseLastOrderActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getHorseOwner =
  (horseId: number): ActionResult<IAppState, Promise<void>, getHorseOwnerActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getHorseOwnerActionTypes.REQUEST));
      const data = await BusinessPortalHorseProfileService.getHorseOwner(horseId);
      dispatch(action(getHorseOwnerActionTypes.SUCCESS, data));
    } catch (error) {
      dispatch(action(getHorseOwnerActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getOwnerHorses =
  (horseId: number): ActionResult<IAppState, Promise<void>, getOwnerHorsesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getOwnerHorsesAtionTypes.REQUEST));
      const res = await BusinessPortalHorseProfileService.getOwnerHorses(horseId);
      dispatch(action(getOwnerHorsesAtionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getOwnerHorsesAtionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const resetHorseParantageReport =
  (): ActionResult<IAppState, void, BusinessPortalHorseProfileActions> => (dispatch) => {
    dispatch(action(resetHorseParantageReportActionTypes.RESET));
  };

export const resetHorseAbilities =
  (): ActionResult<IAppState, void, BusinessPortalHorseProfileActions> => (dispatch) => {
    dispatch(action(resetHorseAbilitiesActionTypes.RESET));
  };

export const resetHorseGenotypeGroups =
  (): ActionResult<IAppState, void, BusinessPortalHorseProfileActions> => (dispatch) => {
    dispatch(action(resetHorseGenotypeGroupsActionTypes.RESET));
  };

export const resetHorseBreedPanel =
  (): ActionResult<IAppState, void, BusinessPortalHorseProfileActions> => (dispatch) => {
    dispatch(action(resetHorseBreedPanelActionTypes.RESET));
  };

export const resetHorseLastOrder =
  (): ActionResult<IAppState, void, BusinessPortalHorseProfileActions> => (dispatch) => {
    dispatch(action(resetHorseLastOrderActionTypes.RESET));
  };
