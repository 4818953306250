export enum HtmlTemplateType {
  SampleForm = 'SampleForm',
  ConfirmEmail = 'ConfirmEmail',
  ResetPassword = 'ResetPassword',
  OrderPlaced = 'OrderPlaced',
  OrderPart = 'OrderPart',
  PaymentConfirmed = 'PaymentConfirmed',
  ResultsReady = 'ResultsReady',
  ResultsUpdated = 'ResultsUpdated',
  SetPassword = 'SetPassword',
  RequestNewSample = 'RequestNewSample',
  ContactRequest = 'ContactRequest',
  DiagnosticReport = 'DiagnosticReport',
  DiagnosticReportPart = 'DiagnosticReportPart',
  NewSampleReceived = 'NewSampleReceived',
  ReportUploaded = 'ReportUploaded',
  SubscriptionInvoicePaymentFailed = 'SubscriptionInvoicePaymentFailed',
  FirstSubscriptionInvoicePaid = 'FirstSubscriptionInvoicePaid',
  SubscriptionInvoicePaid = 'SubscriptionInvoicePaid',
  SubscriptionCanceled = 'SubscriptionCanceled',
  ChangeProduct = 'ChangeProduct',
  ChangePaymentMethod = 'ChangePaymentMethod',
  RenewSubscription = 'RenewSubscription',
  NotificationRepeatedOrder = 'NotificationRepeatedOrder',
  NotificationRepeatedOrderPart = 'NotificationRepeatedOrderPart',
  InvoicePaymentMethodNotification = 'InvoicePaymentMethodNotification',
  InvoicePaymentMethodNotificationPart = 'InvoicePaymentMethodNotificationPart',
  ChangeHorseDemographicDataNotification = 'ChangeHorseDemographicDataNotification',
  AssociationPaymentConfirmed = 'AssociationPaymentConfirmed',
  AssociationResultsReady = 'AssociationResultsReady',
  AssociationOrdersPlaced = 'AssociationOrdersPlaced',
  AssociationRequestNewSample = 'AssociationRequestNewSample',
  AssociationRepeatedOrder = 'AssociationRepeatedOrder',
  AssociationNotificationRepeatedOrderPart = 'AssociationNotificationRepeatedOrderPart',
  AssociationChangeHorseOwnerNotification = 'AssociationChangeHorseOwnerNotification',
  AssociationChangeHorseDemographicDataNotification = 'AssociationChangeHorseDemographicDataNotification',
  AssociationNewSampleReceived = 'AssociationNewSampleReceived ',
  HorsePublicityRequest = 'HorsePublicityRequest',
  HorsePublicityAssociationRequest = 'HorsePublicityAssociationRequest',
  HorseTransferRequestReceived = 'HorseTransferRequestReceived',
  HorseTransferRequest = 'HorseTransferRequest',
  HorseTransferRequestRejected = 'HorseTransferRequestRejected ',
  ChatRequestCreated = 'ChatRequestCreated',
  ChatRequestReceived = 'ChatRequestReceived',
  ChatRequestAccepted = 'ChatRequestAccepted',
  ChatRequestRejected = 'ChatRequestRejected',
  MessageFromUserReceived = 'MessageFromUserReceived',
  HorseTransferredToUser = 'HorseTransferredToUser',
  HorseTransferredFromUser = 'HorseTransferredFromUser',
}
