import * as React from 'react';
import {Navigate, Route, To} from 'react-router-dom';

import {ADMIN_NAVIGATION_ROUTES, COMMON_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import AdminWelcomePage from 'Admin/common/AdminWelcomePage';
import {getRoutes} from 'Common/helpers/getRoutes';

function AppAuthRoutingAdmin(from: To) {
  return [
    ...getRoutes(COMMON_NAVIGATION_ROUTES),
    ...getRoutes(ADMIN_NAVIGATION_ROUTES),
    <Route key="admin-welcome" path="/admin-welcome" Component={AdminWelcomePage} />,
    <Route key="navigate" path="*" element={<Navigate to={from} />} />,
  ];
}

export default AppAuthRoutingAdmin;
