import {Reducer} from 'redux';
import * as R from 'ramda';

import {uploadImageActionTypes, IImageState, ImageActions, getImageActionTypes, deleteImageActionTypes} from '../types';

const initialImageState: IImageState['data'] = {images: {}};

export const dataImageReducer: Reducer<IImageState['data'], ImageActions> = (
  state = initialImageState,
  action: ImageActions
) => {
  switch (action.type) {
    case getImageActionTypes.SUCCESS: {
      return {...state, images: {...state.images, [action.payload.id]: action.payload}};
    }
    case uploadImageActionTypes.SUCCESS: {
      return {...state, images: {...state.images, [action.payload]: {id: action.payload, url: ''}}};
    }
    case deleteImageActionTypes.SUCCESS: {
      return {...state, images: R.filter(({id}) => id !== action.payload.imageId, state.images)};
    }
    default: {
      return state;
    }
  }
};
