import {memo, useEffect} from 'react';
import styled from 'styled-components';
import {useInView} from 'react-intersection-observer';

import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {IImage} from 'Image/models/IImage';
import {MessageBase, MessageDate, MessageFooter} from '../../styled';
import AttachedImages from '../AttachedImage/AttachedImages';
import support from '../img/support.svg';
import MessageActions, {MessageActionsProps} from '../Actions/MessageActions';

const Root = styled.div`
  width: 100%;
  gap: 8px;
`;

export const Message = styled(MessageBase)`
  border-radius: 0 8px 8px 8px;
`;

interface IProps {
  id?: number;
  message: string;
  url?: string | null;
  createDate?: string;
  isSupport?: boolean;
  images?: IImage[];
  isShowActions?: boolean;
  isRead?: boolean;

  onMessageRead?(id: number): void;
}

type Props = MessageActionsProps & IProps;

function IncomingMessage(props: Props) {
  const {
    id,
    createDate,
    isSupport,
    message,
    url,
    images,
    isShowActions,
    onDeleteMessage,
    showDeleteMessageMenuItem,
    isRead,
    onMessageRead,
  } = props;

  const {ref, inView} = useInView({
    threshold: 0.9,
  });

  useEffect(() => {
    if (inView && id && isRead !== undefined && !isRead) {
      onMessageRead && onMessageRead(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, isRead]);

  return (
    <Root className="d-flex justify-content-start" ref={ref}>
      <Avatar
        avatarSize={AvatarSize.Custom}
        customSizeAvatar={40}
        customSizeIcon={24}
        defaultAvatarProfile={DefaultAvatarProfile.User}
        url={isSupport ? support : url}
      />
      <Message>
        {message}
        {images && images?.length > 0 && <AttachedImages images={images} />}
        <MessageFooter className="d-flex">
          <MessageDate>{createDate}</MessageDate>
        </MessageFooter>
        {isShowActions && (
          <MessageActions onDeleteMessage={onDeleteMessage} showDeleteMessageMenuItem={showDeleteMessageMenuItem} />
        )}
      </Message>
    </Root>
  );
}

export default memo(IncomingMessage);
