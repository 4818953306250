import {IModule} from 'redux-dynamic-modules';
import {createLogger} from 'redux-logger';

const failureType = '_FAILURE';

export const DevModule: IModule<{}> = {
  id: 'development',
  middlewares: [
    createLogger({
      level: 'info',
      collapsed: true,
      colors: {
        title: (action: any) => {
          const isFailureContains = (action.type as string).includes(failureType);
          if (isFailureContains) {
            return 'red';
          }
          return 'inherit';
        },
        prevState: function () {
          return '#9E9E9E';
        },
        action: function () {
          return '#03A9F4';
        },
        nextState: function () {
          return '#4CAF50';
        },
        error: function () {
          return '#F20404';
        },
      },
    }),
  ],
};
