import {action} from 'typesafe-actions';

import AdminSupportService from 'Admin/AdminSupport/services/AdminSupportService';
import {getErrorMessage} from 'Common/helpers/ErrorHelper';
import {IRequestParams} from 'Common/models/IRequestParams';
import {IAppState} from 'Common/store/IAppState';
import {ActionResult} from 'Common/store/store';
import {
  getUserToAdminChatDetailsActions,
  getUserToAdminChatDetailsActionTypes,
  getUserToAdminChatMessagesActions,
  getUserToAdminChatMessagesActionTypes,
  getUserToAdminChatsActions,
  getUserToAdminChatsActionTypes,
  getUserToAdminChatsFiltersActions,
  getUserToAdminChatsFiltersActionTypes,
  leaveUserToAdminOnDutyActions,
  leaveUserToAdminOnDutyActionTypes,
  resetAdminToUserChatDetailsActionTypes,
  subscribeUserToAdminMessageActions,
  subscribeUserToAdminMessageActionTypes,
  takeUserToAdminOnDutyActions,
  takeUserToAdminOnDutyActionTypes,
  unsubscribeUserToAdminMessageActions,
  unsubscribeUserToAdminMessageActionTypes,
} from './types';
import {IServerUserToAdminChatMessageRequestAdmin, ISubscribeRequest} from '../services/types/userToAdminChat';

export const getUserToAdminChats =
  (params?: IRequestParams): ActionResult<IAppState, void, getUserToAdminChatsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatsActionTypes.REQUEST));
      const res = await AdminSupportService.getUserToAdminChats(params);
      dispatch(action(getUserToAdminChatsActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserToAdminChatsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminChatsFilters =
  (): ActionResult<IAppState, void, getUserToAdminChatsFiltersActions> => async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatsFiltersActionTypes.REQUEST));
      const res = await AdminSupportService.getUserToAdminChatsFilters();
      dispatch(action(getUserToAdminChatsFiltersActionTypes.SUCCESS, res));
    } catch (error) {
      dispatch(action(getUserToAdminChatsFiltersActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminChatDetails =
  (userId: number): ActionResult<IAppState, void, getUserToAdminChatDetailsActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatDetailsActionTypes.REQUEST));
      const res = await AdminSupportService.getUserToAdminChatDetails(userId);
      dispatch(action(getUserToAdminChatDetailsActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getUserToAdminChatDetailsActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const getUserToAdminChatMessages =
  (
    params: IServerUserToAdminChatMessageRequestAdmin
  ): ActionResult<IAppState, void, getUserToAdminChatMessagesActions> =>
  async (dispatch) => {
    try {
      dispatch(action(getUserToAdminChatMessagesActionTypes.REQUEST));
      const res = await AdminSupportService.getUserToAdminChatMessages(params);
      dispatch(action(getUserToAdminChatMessagesActionTypes.SUCCESS, res.data));
    } catch (error) {
      dispatch(action(getUserToAdminChatMessagesActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const subscribeToMessages =
  (params: ISubscribeRequest): ActionResult<IAppState, void, subscribeUserToAdminMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(subscribeUserToAdminMessageActionTypes.REQUEST));
      await AdminSupportService.subscribeToMessages(params);
      dispatch(action(subscribeUserToAdminMessageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(subscribeUserToAdminMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const unsubscribeFromMessages =
  (params: ISubscribeRequest): ActionResult<IAppState, void, unsubscribeUserToAdminMessageActions> =>
  async (dispatch) => {
    try {
      dispatch(action(unsubscribeUserToAdminMessageActionTypes.REQUEST));
      await AdminSupportService.unsubscribeFromMessages(params);
      dispatch(action(unsubscribeUserToAdminMessageActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(unsubscribeUserToAdminMessageActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const takeDuty =
  (userId: number): ActionResult<IAppState, void, takeUserToAdminOnDutyActions> =>
  async (dispatch) => {
    try {
      dispatch(action(takeUserToAdminOnDutyActionTypes.REQUEST));
      await AdminSupportService.takeDuty(userId);
      dispatch(action(takeUserToAdminOnDutyActionTypes.SUCCESS));
    } catch (error) {
      dispatch(action(takeUserToAdminOnDutyActionTypes.FAILURE, getErrorMessage(error)));
    }
  };

export const leaveDuty = (): ActionResult<IAppState, void, leaveUserToAdminOnDutyActions> => async (dispatch) => {
  try {
    dispatch(action(leaveUserToAdminOnDutyActionTypes.REQUEST));
    await AdminSupportService.leaveDuty();
    dispatch(action(leaveUserToAdminOnDutyActionTypes.SUCCESS));
  } catch (error) {
    dispatch(action(leaveUserToAdminOnDutyActionTypes.FAILURE, getErrorMessage(error)));
  }
};

export const resetAdminToUserChatDetails = () => {
  return action(resetAdminToUserChatDetailsActionTypes.RESET);
};
