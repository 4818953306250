import React, {memo} from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import classNames from 'classnames';
import classes from 'Common/constants/classes';
import {zIndex} from 'Common/constants/ZIndex';
import {ToastContainer} from 'Common/components/Toast/ToastContainer';

import Header from '../Header/Header';
import PageWrapper from '../Layout/PageWrapper';
import BackButton from '../Controls/Buttons/BackButton';
import Footer from '../Footer/Footer';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import IconedLoading from 'Common/components/IconedLoading/IconedLoading';

const Body = styled.div<{widthByContent?: boolean}>`
  height: 100vh;
  min-width: 100%;
  width: ${(p) => (p.widthByContent ? 'fit-content' : 'unset')};
`;

const PageHeader = styled.div`
  z-index: ${zIndex.header};
`;

const PageFooter = styled.div`
  z-index: ${zIndex.footer};
`;

const FullLoading = styled.div<{isActive?: boolean}>`
  display: ${({isActive}) => (isActive ? 'flex' : 'none')};
  z-index: 1;
  height: 65vw;
`;

interface IProps {
  isBackButtonDenied?: boolean;
  backButtonStyle?: React.CSSProperties;
  backTargetUrl?: string;
  withoutPaddings?: boolean;
  header?: React.ReactElement;
  hideFooter?: boolean;
  hideHeader?: boolean;
  fixedHeight?: boolean;
  widthByContent?: boolean;
  isEmptyHeader?: boolean;
  isLoading?: boolean;
  isDarkBackground?: boolean;
}

function BaseLayout(props: React.PropsWithChildren<IProps>) {
  const {
    children,
    isBackButtonDenied,
    backTargetUrl,
    backButtonStyle,
    withoutPaddings,
    header,
    hideFooter,
    hideHeader,
    fixedHeight,
    widthByContent,
    isEmptyHeader,
    isLoading,
    isDarkBackground,
  } = props;
  const {isMobile} = useMediaQuery();

  const renderHeader = () => {
    if (hideHeader) {
      return null;
    }

    if (isEmptyHeader) {
      return <Header isBackButtonDenied={isBackButtonDenied} hideUserSection={true} />;
    }
    return header || <Header isBackButtonDenied={isBackButtonDenied} />;
  };

  return (
    <Body className="d-flex flex-column" widthByContent={widthByContent}>
      <Helmet titleTemplate="Etalon Diagnostics - %s" defaultTitle="Etalon Diagnostics" />
      <PageHeader className={classNames('flex-shrink-0', classes.header)}>{renderHeader()}</PageHeader>
      <PageWrapper
        className={classNames('position-relative d-flex flex-column flex-grow-1', {
          'overflow-auto': fixedHeight,
          'flex-shrink-0': !fixedHeight,
        })}
        withoutPaddings={withoutPaddings}
      >
        {!isBackButtonDenied && !isMobile && (
          <BackButton
            backTargetUrl={backTargetUrl}
            className="position-absolute"
            style={backButtonStyle}
            isDarkBackground={isDarkBackground}
          />
        )}
        <ToastContainer className="position-absolute" />
        <FullLoading className="w-100 position-absolute justify-content-center align-items-center" isActive={isLoading}>
          <IconedLoading size={60} />
        </FullLoading>
        {children}
      </PageWrapper>
      {!hideFooter && (
        <PageFooter className={classNames('flex-shrink-0', classes.footer)}>
          <Footer />
        </PageFooter>
      )}
    </Body>
  );
}

export default memo(BaseLayout);
