import React, {memo, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import UserOrder from 'UserProfile/components/TabsData/UserOrders/UserOrder/UserOrder';
import Typography from 'Common/constants/Typography';
import ColorPalette from 'Common/constants/ColorPalette';
import {breakpoints} from 'Common/constants/Breakpoints';
import {Nullable} from 'Common/types';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors as userOrderSelectors} from 'UserProfile/store/userOrder/index';
import {UserOrdersModule} from 'UserProfile/store/userOrder/horseListModule';
import {SubmissionFormModule} from 'Order/store/submissionForm/submissionFormModule';
import Loading from 'Loading/components/Loading';
import {getCommonErrors} from 'Common/helpers/ErrorHelper';
import {ErrorMessage} from 'Common/components/StyledComponents/StyledComponents';
import {selectors as userSelectors} from 'UserProfile/store/currentUser/index';
import CommonLink from 'Common/components/Controls/Links/CommonLink';
import withDynamicModules from 'Common/helpers/withDynamicModules';

const Root = styled.div`
  min-width: fit-content;
  padding: 0 20px;

  @media ${breakpoints.sm} {
    padding: 0;
  }
`;

const OrderLabel = styled.div`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.semiBold600};
  font-size: ${Typography.size.size20};
  line-height: 32px;
  letter-spacing: -1px;
  color: ${ColorPalette.black0};
  margin-bottom: 12px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size32};
    margin-bottom: 16px;
  }
`;

const AllOrdersButton = styled(CommonLink)`
  display: none;
  cursor: pointer;
  margin-top: 24px;
  font-family: ${Typography.family.roboto};
  font-style: normal;
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size13};
  line-height: 15px;
  color: ${ColorPalette.red7} !important;
  justify-content: center;

  @media ${breakpoints.sm} {
    display: flex;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

const RecentOrders = ({userOrders, currentUserId, userOrdersLoading, getUserOrders}: AllProps) => {
  useEffect(() => {
    currentUserId && getUserOrders({userId: currentUserId, currentPage: 1, countPerPage: 3});
  }, [currentUserId, getUserOrders]);

  const isLoading = userOrdersLoading.isRequesting;
  const errorInfo = userOrdersLoading.error;

  const [error, setError] = useState<Nullable<string>>(null);

  useEffect(() => {
    const commonErrors = getCommonErrors(errorInfo);
    commonErrors && setError(commonErrors);
  }, [errorInfo]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (!userOrders || !userOrders.length) {
    return <OrderLabel>You have no recent orders</OrderLabel>;
  }

  return (
    <Root className="w-100">
      <OrderLabel>Recent orders and codes</OrderLabel>
      <ContentWrapper className="position-relative">
        {isLoading ? <Loading /> : userOrders.map((order, i) => <UserOrder key={i} userOrder={order} />)}
      </ContentWrapper>
      <AllOrdersButton className="justify-content-center" to={`/user/user-profile/${currentUserId}/tests`}>
        All my orders
      </AllOrdersButton>
    </Root>
  );
};

const mapStateToProps = (state: IAppState) => ({
  userOrders: userOrderSelectors.selectUserOrders(state),
  userOrdersLoading: userOrderSelectors.selectCommunication(state, 'userOrdersLoading'),
  currentUserId: userSelectors.selectCurrentUserId(state),
});

const mapDispatchToProps = {
  getUserOrders: actions.getUserOrders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(RecentOrders));

export default withDynamicModules(Connected, [UserOrdersModule, SubmissionFormModule]);
