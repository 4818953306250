import React, {memo, useCallback, useMemo, useState} from 'react';
import {CartesianGrid, Scatter, ScatterChart, Tooltip, TooltipProps, XAxis, YAxis} from 'recharts';
import {ValueType, NameType} from 'recharts/types/component/DefaultTooltipContent';

import {IAncestryPopulationComparison} from 'OnlineReport/models/shared/IAncestryPopulationComparison';
import {calculate} from './hueRotate';
import {useMatchMediaQuery, useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import styled from 'styled-components';
import {AncestryReportSection} from 'OnlineReport/components/Ancestry/parts/common/styled';
import {breakpoints, size} from 'Common/constants/Breakpoints';
import Typography from 'Common/constants/Typography';
import {IAncesryComponentProps} from 'OnlineReport/models/Ancestry/IAncesryComponentProps';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import './PCAPlot.css';
import {IAncestryPcaReferencePoint} from '../../../../models/Ancestry/IAncestryPcaReferencePoint';

const AncestryReportSectionWrapper = styled(AncestryReportSection)`
  user-select: none;
  align-items: center;

  @media print {
    align-items: start;
  }
`;

const FlexSplit = styled.div<{direction?: 'column' | 'row'}>`
  display: flex;
  ${(prop) => (prop.direction ? `flex-direction: ${prop.direction};` : 'flex-direction: column;')}
  padding: 16px 0;

  @media ${breakpoints.md} {
    ${(props) => (props.direction ? `flex-direction: ${props.direction};` : 'flex-direction: row;')}
  }

  @media print {
    ${(props) => (props.direction ? `flex-direction: ${props.direction};` : 'flex-direction: row;')}
  }
`;

const KeysWrapper = styled.div`
  width: 100%;
  height: 512px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${breakpoints.md} {
    width: 300px;
  }
`;

const KeyWrapper = styled.div`
  min-width: 147px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${breakpoints.sm} {
    margin-left: 80px;
  }

  @media ${breakpoints.md} {
    margin-left: 0;
  }
`;

const KeyCircle = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
`;

const KeyText = styled.span`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-size: ${Typography.size.size12};
`;

const BigKeyWrapper = styled(KeyWrapper)`
  padding-bottom: 1rem;
`;

const BigKeyCircle = styled(KeyCircle)`
  width: 10px;
  height: 10px;
  margin-right: 8px;
`;

const BigKeyText = styled(KeyText)`
  font-size: ${Typography.size.size20};
  font-weight: ${Typography.weight.bold700};
`;

const IndicatorDot = styled.circle`
  background: #000000;
  fill: rgb(179, 57, 57);
  z-index: 10;
`;

const KeysContainer = styled.div`
  @media ${breakpoints.md} {
    padding-left: 24px;
  }
`;

const ClearFilterButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-right: auto;
  min-width: 190px;
  margin-top: 20px;

  @media print {
    display: none !important;
  }
`;

const ZoomControlWrapper = styled.div`
  left: 30px;
  top: 45px;
  height: auto;
  width: auto;
  z-index: 1;
  margin-left: 40px;

  @media ${breakpoints.sm} {
    margin-left: 65px;
    left: 85px;
  }

  @media print {
    display: none !important;
  }
`;

const ZoomButton = styled(PrimaryButton)`
  display: inline-block;
  min-width: auto;
  width: 48px;
  height: 48px;
  font-size: 24px;
  font-family: sans-serif;
  border-radius: 50%;
  margin-right: 3px;
`;

const TooltipContent = styled.p`
  margin-top: -32px;
  margin-left: 0px;
  padding: 2px 10px 2px 10px;
  background-color: #850000;
  color: ghostwhite;
  border-radius: 14px;
`;

interface IPcaData {
  breed: string;
  sampleId?: string;
  pc1: number;
  pc2: number;
}

interface IProps {
  pcaData: IAncestryPcaReferencePoint[];
  horseName?: string;
  data: IAncestryPopulationComparison;
  direction?: 'column' | 'row';
  customChartSize?: number;
  isPrintable?: boolean;
}

type AllProps = IProps & IAncesryComponentProps;

const PCAPlot = (props: AllProps) => {
  const {pcaData, horseName, data, direction, withoutPaddings = false, customChartSize, isPrintable} = props;

  const groups = (data: IPcaData[]) => {
    const map = {};

    data.forEach((item: any) => {
      if (item['breed'] === 'Etalon') return;
      map[item['breed']] = map[item['breed']] || [];
      map[item['breed']].push({pc1: item['pc1'], pc2: item['pc2']});
    });

    return map;
  };

  const mappedData = pcaData.sort((a, b) => a.breed.localeCompare(b.breed));

  const items = groups(mappedData);
  // TODO: Here coordinates are multiplied by -1, but it can be error in raw data. Can be fixed by Gabriel in raw files.
  const currentHorse: IPcaData = useMemo(() => {
    return {
      breed: horseName || '',
      pc1: data.x,
      pc2: data.y,
    };
  }, [horseName, data]);

  const {isDesktop, isTablet, isMobile} = useMediaQuery();
  const md2 = useMatchMediaQuery(`(min-width: ${size.md2}px)`);
  const chartSize =
    customChartSize && customChartSize > 0
      ? customChartSize
      : isPrintable
      ? 600
      : md2
      ? 600
      : isDesktop
      ? 500
      : isTablet
      ? 400
      : 300;

  const CustomizedShape = (props: any) => {
    const {cx, cy} = props;
    return (
      <g>
        <IndicatorDot cx={cx} cy={cy} r={8} />
        <g transform={`translate(${cx},${cy})`}>
          <text
            style={{
              stroke: '#ffffff',
              strokeWidth: 8,
              fontSize: 18,
              paintOrder: 'stroke fill',
              fontWeight: 'bold',
            }}
            x={-16}
            y={0}
            dy={3}
            textAnchor="end"
          >
            {horseName}
          </text>
        </g>
      </g>
    );
  };

  const CustomTooltip = ({active}: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return <TooltipContent>{highlightBreed}</TooltipContent>;
    }

    return null;
  };

  const X_AXIS_MAX_DOMAIN = useMemo(() => [-0.16, 0.08], []);
  const Y_AXIS_MAX_DOMAIN = useMemo(() => [-0.08, 0.1], []);
  const MAX_ZOOM_STEPS = 3;

  const handleMouseLeaveChart = () => {
    setCloseUp(false);
    setHighlightBreed('');
  };

  const handleMouseEnterChart = () => {
    setCloseUp(true);
    setHighlightBreed('');
  };

  const handleBreedHighlight = (breedName: any) => {
    setHighlightBreed(breedName);
  };

  function toggleBreedFilter(breed: string) {
    setBreedFilter((prevState) => {
      if (prevState.includes(breed)) {
        const clone = [...prevState];
        clone.splice(prevState.indexOf(breed), 1);
        return clone;
      } else {
        return [...prevState, breed];
      }
    });
  }

  function resetBreedFilter() {
    setBreedFilter([]);
  }

  const [highlightBreed, setHighlightBreed] = useState<string>('');
  const [closeUp, setCloseUp] = useState(false);
  const [breedFilter, setBreedFilter] = useState<string[]>([]);
  const [xAxisDomain, setXAxisDomain] = useState([X_AXIS_MAX_DOMAIN[0], X_AXIS_MAX_DOMAIN[1]]);
  const [yAxisDomain, setYAxisDomain] = useState([Y_AXIS_MAX_DOMAIN[0], Y_AXIS_MAX_DOMAIN[1]]);
  const [zoomStepCounter, setZoomStepCounter] = useState(0);

  const zoom = useCallback(
    (zoomOut = false) => {
      if ((zoomStepCounter === 0 && zoomOut) || (zoomStepCounter === MAX_ZOOM_STEPS && !zoomOut)) {
        return;
      }
      if (zoomStepCounter === 1 && zoomOut) {
        setXAxisDomain([X_AXIS_MAX_DOMAIN[0], X_AXIS_MAX_DOMAIN[1]]);
        setYAxisDomain([Y_AXIS_MAX_DOMAIN[0], Y_AXIS_MAX_DOMAIN[1]]);
        setZoomStepCounter(0);
        return;
      }
      const currentZoomStep = zoomStepCounter;
      setZoomStepCounter(zoomOut ? currentZoomStep - 1 : currentZoomStep + 1);

      const xRange = xAxisDomain[1] - xAxisDomain[0];
      const offset = zoomOut ? xRange : xRange / 4;
      const xZoomValues = [
        Math.round((currentHorse.pc2 - offset) * 100) / 100,
        Math.round((currentHorse.pc2 + offset) * 100) / 100,
      ];
      setXAxisDomain(xZoomValues);

      const yZoomValues = [
        Math.round((currentHorse.pc1 - offset) * 100) / 100,
        Math.round((currentHorse.pc1 + offset) * 100) / 100,
      ];
      setYAxisDomain(yZoomValues);
    },
    [X_AXIS_MAX_DOMAIN, Y_AXIS_MAX_DOMAIN, currentHorse, xAxisDomain, zoomStepCounter]
  );
  return (
    <AncestryReportSectionWrapper className="d-flex w-100 flex-column" withoutPaddings={withoutPaddings}>
      <FlexSplit direction={direction} className="position-relative">
        <ZoomControlWrapper>
          <ZoomButton disabled={zoomStepCounter === 0} onClick={() => zoom(true)}>
            -
          </ZoomButton>
          <ZoomButton disabled={zoomStepCounter === 2} onClick={() => zoom(false)}>
            +
          </ZoomButton>
        </ZoomControlWrapper>
        <ScatterChart
          style={{marginLeft: isMobile ? -25 : 0}}
          width={chartSize}
          height={chartSize}
          onMouseEnter={() => handleMouseEnterChart()}
          onMouseLeave={() => handleMouseLeaveChart()}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey="pc2" allowDataOverflow={true} tickCount={5} domain={xAxisDomain} />
          <YAxis type="number" dataKey="pc1" allowDataOverflow={true} tickCount={5} domain={yAxisDomain} />
          <Tooltip isAnimationActive={true} cursor={false} content={<CustomTooltip />} />
          {currentHorse ? (
            <Scatter
              className="close-up-bg"
              name={currentHorse.breed}
              data={[currentHorse]}
              onMouseEnter={() => setHighlightBreed('')}
              shape={<CustomizedShape />}
            />
          ) : null}
          {Object.keys(items).map((item, i) => (
            <Scatter
              key={i}
              name={item}
              data={items[item]}
              fill={calculate(255, 44, 44, i * 7)}
              onMouseEnter={() => handleBreedHighlight(item)}
              onMouseLeave={() => handleBreedHighlight('')}
              shape={
                <circle
                  r={3}
                  style={{
                    opacity: 0.6,
                    display: breedFilter.includes(item) || breedFilter.length === 0 ? 'block' : 'none',
                  }}
                />
              }
            />
          ))}
          {currentHorse ? (
            <Scatter
              className={closeUp ? 'close-up' : ''}
              name={currentHorse.breed}
              data={[currentHorse]}
              shape={<CustomizedShape />}
            />
          ) : null}
        </ScatterChart>

        <KeysContainer className="d-flex flex-column align-items-start">
          <BigKeyWrapper>
            <BigKeyCircle style={{backgroundColor: 'rgb(179,57,57)'}} />
            <BigKeyText>{horseName}</BigKeyText>
          </BigKeyWrapper>
          <KeysWrapper>
            {Object.keys(items).map((item, i) => (
              <KeyWrapper
                key={i}
                style={{
                  backgroundColor: breedFilter.includes(item) ? '#B33939' : 'white',
                  color: breedFilter.includes(item) ? 'white' : 'black',
                  borderRadius: '25px',
                  paddingLeft: '4px',
                  paddingRight: '12px',
                  marginBottom: '1px',
                  fontWeight: breedFilter.includes(item) ? 'bold' : 'normal',
                  cursor: 'pointer',
                }}
                onClick={() => toggleBreedFilter(item)}
              >
                <KeyCircle
                  style={{
                    backgroundColor: calculate(255, 44, 44, i * 7),
                  }}
                />
                <KeyText key={i}>{item}</KeyText>
              </KeyWrapper>
            ))}
          </KeysWrapper>
          <ClearFilterButton onClick={resetBreedFilter}>Clear Filter</ClearFilterButton>
        </KeysContainer>
      </FlexSplit>
    </AncestryReportSectionWrapper>
  );
};

export default memo(PCAPlot);
