import React, {memo, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import UserOrders from 'UserProfile/components/TabsData/UserOrders/UserOrders';
import UserProfile, {UserProfileRouteProps} from 'Shared/components/UserProfile/UserProfile';
import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import UserFavoriteHorses from './TabsData/UserFavoriteHorses/UserFavoriteHorses';
import {ITab} from 'Common/components/Tabs/ITab';
import UserExcludeHorses from 'UserProfile/components/TabsData/UserExcludeHorses/UserExcludeHorses';
import {IUserProfileSectionProps} from 'Shared/models/IUserProfileSectionProps';
import {VisitorType} from 'Common/constants/VisitorType';
import {IAppState} from 'Common/store/IAppState';
import {actions as currentUserActions, selectors as currentUserSelectors} from 'UserProfile/store/currentUser';
import {UserProfileType} from 'Shared/models/UserProfileType';
import {PREFIX_USER_PROFILE_TYPE} from 'Common/components/Navigation';

const ownProfileTabs: ITab<IUserProfileSectionProps>[] = [
  {section: 'tests', title: 'Orders', Component: UserOrders},
  {section: 'favorites', title: 'Favorites', Component: UserFavoriteHorses},
  {section: 'excluded', title: 'Excluded', Component: UserExcludeHorses},
];

type RouteParams = {id: string};

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected;

function UserProfileLayout(props: Props) {
  const {currentUser} = props;

  const {id, userProfileType} = useParams<RouteParams & UserProfileRouteProps>();

  const [isUserProfileTypeChecked, setIsUserProfileTypeChecked] = useState(false);

  const userId = Number(id);
  const isOwnProfile = currentUser?.id === userId;
  const tabs = isOwnProfile ? ownProfileTabs : [];
  const navigate = useNavigate();

  useEffect(() => {
    // If /user-profile is missing, but id is present redirect to correct url
    if (userProfileType && userProfileType !== '' && !isNaN(+userProfileType)) {
      navigate(`/user/user-profile/${userProfileType}/horses`, {replace: true});
    } else if (userProfileType !== PREFIX_USER_PROFILE_TYPE[UserProfileType.User]) {
      navigate('/');
    }
    setIsUserProfileTypeChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout withoutPaddings={true} backButtonStyle={{marginTop: 20}}>
      {isUserProfileTypeChecked && (
        <UserProfile
          additionalTabs={tabs}
          visitorType={VisitorType.User}
          userProfileType={userProfileType as UserProfileType}
        />
      )}
    </BaseLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  currentUser: currentUserSelectors.selectCurrentUser(state),
  currentUserLoading: currentUserSelectors.selectCommunication(state, 'userLoading'),
});

const mapDispatchToProps = {
  getCurrentUser: currentUserActions.getCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(UserProfileLayout));
export default Connected;
