import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  AdminShortTandemRepeatReportActions,
  getShortTandemRepeateportActionTypes,
  getShortTandemRepeateportReviewActionTypes,
  IAdminShortTandemRepeatReportState,
} from '../types';

export const communicationReducer = combineReducers<
  IAdminShortTandemRepeatReportState['communications'],
  AdminShortTandemRepeatReportActions
>({
  shortTandemRepeatReportLoading: makeCommunicationReducerFromEnum(getShortTandemRepeateportActionTypes),
  shortTandemRepeatReportReviewLoading: makeCommunicationReducerFromEnum(getShortTandemRepeateportReviewActionTypes),
});
