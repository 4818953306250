import {IModule} from 'redux-dynamic-modules';

import {IAdminCouponState} from 'Admin/AdminDashboard/store/adminCoupons/types';
import {couponsReducer} from './reducer';

export interface IAdminCouponsModuleState {
  adminCoupons: IAdminCouponState;
}

export const AdminCouponsModule: IModule<IAdminCouponsModuleState> = {
  id: 'adminCoupons',
  reducerMap: {
    adminCoupons: couponsReducer,
  } as any,
};
