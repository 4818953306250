import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';
import {IAppState} from 'Common/store/IAppState';

function selectState(state: IAppState) {
  return state.adminDogTestResultsUpload;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectDogTestResultsUploadDetails(state: IAppState) {
  return selectState(state).data.dogTestResultsUploadDetails;
}
