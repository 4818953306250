import React, {memo} from 'react';
import styled from 'styled-components';

import Avatar, {AvatarSize, DefaultAvatarProfile} from 'Common/components/Avatar/Avatar';
import {MessageBase, MessageDate, MessageFooter} from '../../styled';
import ColorPalette from 'Common/constants/ColorPalette';
import {IImage} from 'Image/models/IImage';
import AttachedImages from '../AttachedImage/AttachedImages';
import MessageActions, {MessageActionsProps} from '../Actions/MessageActions';
import IsReadMessage from './IsReadMessage';
import Theme from 'Common/constants/Theme';
import Typography from 'Common/constants/Typography';

const Root = styled.div`
  width: 100%;
  gap: 8px;
`;

export const Message = styled(MessageBase)`
  background-color: ${ColorPalette.gray53};
  border-radius: 8px 0 8px 8px;
  padding-right: 28px;
`;

export const UserName = styled.div`
  font-family: ${Theme.font.primary};
  font-weight: ${Typography.weight.medium500};
  font-size: ${Typography.size.size14};
  color: ${ColorPalette.black5};
  line-height: 16px;
  text-align: end;
  margin-right: -20px;
`;

interface IProps {
  message: string;
  url?: string | null;
  createDate?: string;
  isShowAvatar?: boolean;
  images?: IImage[];
  isShowActions?: boolean;
  isRead?: boolean;
  userName?: string;
}

type Props = MessageActionsProps & IProps;

function OutgoingMessage(props: Props) {
  const {
    createDate,
    isShowAvatar = true,
    message,
    url,
    images,
    isShowActions,
    onDeleteMessage,
    showDeleteMessageMenuItem,
    isRead,
    userName,
  } = props;

  return (
    <Root className="d-flex justify-content-end">
      <Message>
        {userName && <UserName>{userName}</UserName>}

        {message}
        {images && images?.length > 0 && <AttachedImages images={images} />}

        <MessageFooter className="d-flex">
          <MessageDate>{createDate}</MessageDate>
          {isRead !== undefined && <IsReadMessage isRead={isRead} />}
        </MessageFooter>
        {isShowActions && (
          <MessageActions onDeleteMessage={onDeleteMessage} showDeleteMessageMenuItem={showDeleteMessageMenuItem} />
        )}
      </Message>
      {isShowAvatar && (
        <Avatar
          avatarSize={AvatarSize.Custom}
          customSizeAvatar={40}
          customSizeIcon={24}
          defaultAvatarProfile={DefaultAvatarProfile.User}
          url={url}
        />
      )}
    </Root>
  );
}

export default memo(OutgoingMessage);
