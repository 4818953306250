import React, {memo, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import styled from 'styled-components';
import './ShortTandemRepeat.css';

import {AdminShortTandemRepeatReportActions} from 'Admin/AdminDashboard/store/adminOnlineReport/shortTandemRepeat';
import {AdminShortTandemRepeatReportModule} from 'Admin/AdminDashboard/store/adminOnlineReport/shortTandemRepeat/module';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {IAppState} from 'Common/store/IAppState';
import {ShortTandemRepeatReportActions} from 'OnlineReport/store/shortTandemRepeat';
import {ShortTandemRepeatReportModule} from 'OnlineReport/store/shortTandemRepeat/module';
import {OnlineReportType} from '../shared/OnlineReportType';
import {shortTandemRepeatReportDistributor} from './distributor';
import {IOnlineReportExternalProps} from 'OnlineReport/models/shared/IOnlineReportExternalProps';
import Loading from 'Loading/components/Loading';
import {scrollToTop} from 'Common/helpers/scrollToTop';
import {Comparison, IShortTandemRepeatReport} from '../../models/STR/IShortTandemRepeatReport';
import {useOnlineReportErrors} from 'OnlineReport/hooks/useOnlineReportErrors';
import {OrderReportType} from 'Common/constants/OrderReportType';

const Root = styled.div<{isFullWidth?: boolean}>`
  ${(props) => !props.isFullWidth && 'max-width: 1280px;'}
  display: flex;
  flex-direction: column;

  @media print {
    display: block;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IOnlineReportExternalProps;

const TopCaption = styled.caption`caption-side:top; !important`;

const twoDecimals = (value: any) => {
  if (value == null) return;
  const strValue = '' + value;
  const splitVal = strValue.split('.');
  if (splitVal.length === 1) return splitVal[0];
  const resultVal = splitVal[0] + '.' + splitVal[1].substring(0, 2);
  return resultVal;
};

const renderTable = (comparison: Comparison) => {
  const results = comparison;
  const showSnp = comparison.snpResult != 'Unable to Analyze';
  const isSingleReport = comparison.horses.horseId1 == comparison.horses.horseId2;
  return (
    <>
      <table>
        <TopCaption>
          {isSingleReport
            ? `STR Markers for ${comparison.horses.horse1Name}`
            : `Comparison with ${comparison.horses.horse2Name}`}
        </TopCaption>
        <colgroup>
          <col style={{width: '83px'}} />
          <col span={isSingleReport ? 1 : 2} style={{width: '84px'}} />
          {!isSingleReport && <col style={{width: '63px'}} />}
        </colgroup>
        <thead>
          <tr>
            <th>Marker</th>
            <th className="p-0" title={comparison.horses.horse1Name} style={{width: '96px', maxWidth: '96px'}}>
              <span className="clampLh2">{comparison.horses.horse1Name}</span>
            </th>
            {!isSingleReport && (
              <>
                <th className="p-0" title={comparison.horses.horse2Name} style={{width: '84px', maxWidth: '84px'}}>
                  <span className="clampLh2">{comparison.horses.horse2Name}</span>
                </th>
                <th>PI</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {results &&
            results.markers
              .sort((a, b) => (a.markerName > b.markerName ? 1 : -1))
              .map((data: any) => {
                // A row is considered a 'no call' when at least one of the markers is missing
                const noCall = !(data.marker1 && data.marker2);
                return (
                  <tr>
                    <th>{data.markerName}</th>
                    <td>{data.marker1 || '-'}</td>
                    {!isSingleReport && (
                      <>
                        <td
                          className={
                            data.match === 'false' && !noCall
                              ? 'mismatch'
                              : data.match === 'gendermismatch' && !noCall
                              ? 'gendermismatch'
                              : ''
                          }
                        >
                          {data.marker2 || '-'}
                        </td>
                        <td>{noCall ? 'NA' : data.pi}</td>
                      </>
                    )}
                  </tr>
                );
              })}
        </tbody>
      </table>
      {!isSingleReport && (
        <>
          <table id="str-data" className="str-table">
            <colgroup>
              <col></col>
              <col style={{width: '165px'}}></col>
            </colgroup>
            <tr>
              <th colSpan={2}>STR</th>
            </tr>
            <tr>
              <td>CPI: {comparison.cpi}</td>
              <td>Likelihood: {twoDecimals(comparison.likelihood) + '%'}</td>
            </tr>
          </table>
          <table id="snp-data" className="str-table">
            <colgroup>
              <col style={{width: '149px'}}></col>
              <col style={{width: '165px'}}></col>
            </colgroup>
            <tr>
              <th colSpan={2}>SNP</th>
            </tr>
            <tr>
              <td title={`CPI: ${comparison.snpCpi}`} style={{maxWidth: '149px'}}>
                CPI: {showSnp ? comparison.snpCpi : 'NA'}
              </td>
              <td title={`Likelihood: ${comparison.snpLikelihood}%`}>
                Likelihood: {showSnp ? twoDecimals(comparison.snpLikelihood) + '%' : 'NA'}
              </td>
            </tr>
            <tr>
              <td style={{maxWidth: '149px'}}>Compatibility: {showSnp ? comparison.snpCompatibility : 'NA'}</td>
              <td>Result: {comparison.snpResult}</td>
            </tr>
          </table>
        </>
      )}
    </>
  );
};

const ShortTandemRepeat = (props: AllProps) => {
  const {getShortTandemRepeatReport, horseId, shortTandemRepeatReport, shortTandemRepeatReportLoading} = props;

  const {onlineReportErrors} = useOnlineReportErrors({
    horseId: +horseId,
    error: shortTandemRepeatReportLoading.error,
    reportType: OrderReportType.Parentage,
  });

  useEffect(() => {
    getShortTandemRepeatReport(+horseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {}, [shortTandemRepeatReport]);

  const isLoading =
    (shortTandemRepeatReportLoading.isRequesting || Object.keys(shortTandemRepeatReport).length === 0) &&
    !shortTandemRepeatReportLoading.error;

  if (isLoading) {
    return <Loading />;
  }

  const renderResultDescription = (comparisons: Comparison[]) => {
    const isSingleReport = comparisons.length == 1 && comparisons[0].horses.horseId1 == comparisons[0].horses.horseId2;

    const headers = comparisons.map((comparison) => {
      if (comparison.horses.horseId1 == comparison.horses.horseId2) {
        return null;
      }
      if (comparison.cpi > 1) {
        return (
          <h4>
            Likely Sample Match was found between {comparison.horses.horse1Name} and {comparison.horses.horse2Name}
          </h4>
        );
      } else {
        return (
          <h4>
            Unlikely Sample Match was found between {comparison.horses.horse1Name} and {comparison.horses.horse2Name}
          </h4>
        );
      }
    });
    return (
      <>
        <div className="result-description">
          {headers}
          <h5>{`Etalon STR Parentage Report`}</h5>
          <p>
            {`The Etalon STR Parentage testing panel uses a standardized, ISAG approved set of selected genetic markers to test for parentage between horses. This is the summary results for the tested horse(s) in accordance with the requested testing.  Etalon retains on file the additional "Etalon 101 SNP" result markers for this horse for future testing.`}
          </p>
          {!isSingleReport && (
            <>
              <p>
                {`  
            CPI = Combined Parental Index, a measure of evidence that the
            horse is a parent of the child. A value < 1 indicates evidence is
            against being a parent, a value of 1 indicates the test has no value,
                a value > 1 to infinity indicates the evidence points towards
            parentage.`}
              </p>
              <p>
                {`  
            Likelihood = Probability of Parentage, based on CPI (CPI/CPI+1)
            `}
              </p>
              <p>
                {`  
            LEX3 = As this marker is on the X chromosome, a mismatch is
            possible if comparing offspring and parent that are both male. In
            those cases this assay is not used in the CPI calculation.`}
              </p>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Root className="str-root">
      <div>{onlineReportErrors}</div>
      {!shortTandemRepeatReportLoading.error && (
        <div className="parentage-results-container">
          <>{renderResultDescription(shortTandemRepeatReport.comparisons)}</>
          {shortTandemRepeatReport.comparisons.map((comparison: Comparison) => {
            return <div>{renderTable(comparison)}</div>;
          })}
        </div>
      )}
    </Root>
  );
};

const mapStateToProps = (state: IAppState, externalProps: {reportType: OnlineReportType}) => {
  const {reportType} = externalProps;

  const distributor = shortTandemRepeatReportDistributor[reportType];
  return {...distributor?.state(state, externalProps)};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IAppState, undefined, ShortTandemRepeatReportActions | AdminShortTandemRepeatReportActions>,
  externalProps: {reportType: OnlineReportType}
) => {
  const {reportType} = externalProps;

  const distributor = shortTandemRepeatReportDistributor[reportType];
  const dispatchToProps = {...distributor.dispatch};

  return bindActionCreators(dispatchToProps, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(memo(ShortTandemRepeat));
export default withDynamicModules(Connected, [ShortTandemRepeatReportModule, AdminShortTandemRepeatReportModule]);
