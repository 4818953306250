import React, {useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import {FacetFilterModule} from 'FacetFilter/store/module';
import {AdminSupportModule} from 'Admin/AdminSupport/store';
import {actions, selectors} from 'Admin/AdminSupport/store';
import {IAppState} from 'Common/store/IAppState';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {IUserToAdminChat} from 'Admin/AdminSupport/models/IUserToAdminChat';
import {useFacetSearchBar} from 'FacetFilter/hooks/useFacetSearchBar';
import Loading from 'Loading/components/Loading';
import {Cell, Table} from 'Common/components/Table/Table';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared';
import {Pagination} from 'Common/components/Controls';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import AdminSupportActions from './parts/AdminSupportActions';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import AdminSupportChat from './AdminSupportChat/AdminSupportChat';
import {Nullable} from 'Common/types';
import {IChatUser} from 'MessageCenter/models/IChatUser';
import {useDictionaries} from 'Common/store/useDictionaries';
import {CustomFilterToProperty, DictionaryToProperty} from 'FacetFilter/models/types';
import {CustomFilterType} from 'FacetFilter/constants/CustomFilterType';

const SupportTable = styled.div`
  margin-top: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected;

function AdminSupport(props: AllProps) {
  const {
    getUserToAdminChats,
    getUserToAdminChatsFilters,
    pagination,
    userToAdminChats,
    userToAdminChatsFilters,
    userToAdminChatsLoading,
    userToAdminChatsFiltersLoading,
  } = props;

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Nullable<IChatUser>>(null);

  const {changeSorting, sorting, handlePageSelect, handleCountPerPage} = useCommonAdminPageData<IUserToAdminChat>({});

  const {adminItemList} = useDictionaries();

  const mapDictionaryToProperty: DictionaryToProperty = {
    'UserToAdminChat.AdminInCharge': adminItemList,
  };

  const mapCustomFilterToProperty: CustomFilterToProperty = {
    'UserToAdminChat.AdminInCharge': CustomFilterType.UserItemList,
    'UserToAdminChat.User': CustomFilterType.UserSearch,
  };

  const {searchBar: facetSearchBar} = useFacetSearchBar({
    filterItems: userToAdminChatsFilters,
    mapDictionaryToProperty: mapDictionaryToProperty,
    mapCustomFilterToProperty: mapCustomFilterToProperty,
    getFilterItems: getUserToAdminChatsFilters,
    getItems: getUserToAdminChats,
  });

  const onOpenChatModal = useCallback((user: IChatUser) => {
    setSelectedUser(user);
    setIsChatOpen(true);
  }, []);

  const onCloseChatModal = useCallback(() => {
    setSelectedUser(null);
    setIsChatOpen(false);
  }, []);

  const isLoading = [userToAdminChatsLoading, userToAdminChatsFiltersLoading].some((i) => i.isRequesting);
  const isShowPagination = userToAdminChats.length > 0;

  return (
    <AdminPageLayout>
      <ModalWindow isOpen={isChatOpen} onClose={onCloseChatModal} maxWidth="900px">
        {selectedUser && <AdminSupportChat user={selectedUser} />}
      </ModalWindow>

      <div className="d-flex align-items-center">
        <div className="flex-grow-1">{facetSearchBar}</div>
      </div>
      <SupportTable className="position-relative">
        {isLoading && <Loading />}
        <Table<IUserToAdminChat> data={userToAdminChats} rowKey="id" sorting={sorting} onChangeSorting={changeSorting}>
          <Cell<IUserToAdminChat> header="ID" dataKey="id" render={({id}) => `#${id}`} width={100} />
          <Cell<IUserToAdminChat>
            header="User"
            dataKey="user"
            render={({user}) => (
              <AvatarCell
                type="owner"
                label={user.name}
                avatarUrl={user.avatar?.url}
                profileUrl={`/admin/user/user-profile/${user.id}`}
              />
            )}
            width="40%"
          />
          <Cell<IUserToAdminChat>
            header="Admin"
            dataKey="admin"
            render={({admin}) =>
              admin ? <AvatarCell type="owner" label={admin.name} avatarUrl={admin.avatar?.url} /> : 'None'
            }
            width="40%"
          />
          <Cell<IUserToAdminChat>
            header="Unread messages"
            dataKey="unreadUserMessagesCount"
            align={CellAlign.Center}
            render={({unreadUserMessagesCount}) => unreadUserMessagesCount}
            width="10%"
          />

          <Cell<IUserToAdminChat>
            header="Actions"
            align={CellAlign.Right}
            width={100}
            render={(item) => <AdminSupportActions user={item.user} openChatAction={onOpenChatModal} />}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </SupportTable>
    </AdminPageLayout>
  );
}

const mapStateToProps = (state: IAppState) => ({
  userToAdminChats: selectors.selectUserToAdminChats(state),
  userToAdminChatsLoading: selectors.selectCommunication(state, 'userToAdminChatsLoading'),
  userToAdminChatsFilters: selectors.selectUserToAdminChatsFilters(state),
  userToAdminChatsFiltersLoading: selectors.selectCommunication(state, 'userToAdminChatsFiltersLoading'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getUserToAdminChats: actions.getUserToAdminChats,
  getUserToAdminChatsFilters: actions.getUserToAdminChatsFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const Connected = connector(AdminSupport);

export default withDynamicModules(Connected, [AdminSupportModule, FacetFilterModule]);
