import {
  ICommunication,
  MakeCommunicationActions,
  makeCommunicationActionType,
} from 'Common/store/utils/communication/index';

import {IListResponse, IPagination} from 'Common/models/IResponse';
import {IDog} from 'Dogs/models/IDog';

const STATE_NAME = 'adminDog';

export interface IAdminDogsState {
  data: {
    dogs: IDog[];
  };
  communications: {
    dogsLoading: ICommunication;
  };
  ui: {
    pagination: IPagination;
  };
}

export const getDogsActionTypes = makeCommunicationActionType(STATE_NAME, 'GET_DOGS');
export type getDogsActions = MakeCommunicationActions<typeof getDogsActionTypes, {success: IListResponse<IDog>}>;

export type DogsActions = getDogsActions;
