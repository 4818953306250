export const dogDisciplines = [
  'Companion',
  'Assistance (Hearing Ear Guide)',
  'Assistance (Seeing Eye Guide)',
  'Assistance (Emotional Support Animal)',
  'IPG/Schutzhund',
  'Search & Rescue',
  'Agility',
  'Field',
  'Hunting',
  'Dock Diving',
  'Flyball',
  'Lure Coursing',
  'Show Dog',
  'Breeding Stock',
  'Herding',
  'Rallying',
  'Field Trials',
  'Disc/Frisbee',
  'Barn Hunt',
  'Canicross',
  'Lure Coarsing',
  'Sled Dog',
  'Cart Pulling',
  'Obedience Trials',
  'Scent Work',
  'Canine Freestyle',
  'Racing',
  'Earthdog',
  'Surfing',
  'Other',
];
