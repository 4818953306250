import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import {IFoalParent} from 'FoalProfile/models/IFoalParent';
import {ElementPosition} from 'FoalProfile/models/IElementPosition';
import {breakpoints} from 'Common/constants/Breakpoints';
import {ReactComponent as FoalProfileHeaderTriangle} from 'Icon/foal-profile-header-triangle.svg';
import FoalParent from 'FoalProfile/components/FoalProfileHeader/FoalParent/FoalParent';
import Theme from 'Common/constants/Theme';
import FoalParentsGenotypes from 'FoalProfile/components/FoalProfileHeader/FoalParentsGenotypes';
import {useMediaQuery} from 'Common/helpers/hooks/useMediaQuery';
import Icon, {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import useVisitorTypeService from 'Common/helpers/visitorType/useVisitorTypeService';
import {PREFIX_VISITOR_TYPE} from 'Common/components/Navigation';

// const PARENT_ZINDEX = 4; // need for overflow search horse by name list

type RouteParams = {firstHorseId: string; secondHorseId: string};

const Root = styled.div`
  background-color: ${Theme.color.black};
`;

const FooterCorner = styled.div<{isRight?: boolean}>`
  height: 38px;

  @media ${breakpoints.md} {
    height: 48px;
  }
`;

const GenderIcon = styled(Icon)`
  z-index: 1;
  overflow: hidden;
  top: 20%;
  left: 45%;
  width: 100px;
  height: 100px;

  @media ${breakpoints.md} {
    top: 25%;
    left: 46%;
    width: 130px;
    height: 130px;
  }

  @media ${breakpoints.lg} {
    top: 25%;
    left: 47%;
    width: 150px;
    height: 150px;
  }
`;

const FoalProfileHeaderTriangleImage = styled(FoalProfileHeaderTriangle)`
  fill: ${ColorPalette.gray38};
  @media ${breakpoints.sm} {
    fill: ${ColorPalette.white0};
  }
`;

interface IProps {
  hasFoal: boolean;
  onSuccess(firstParent: IFoalParent, secondParent: IFoalParent): void;
}

const FoalProfileHeader = (props: IProps) => {
  const {hasFoal, onSuccess} = props;

  const {isMobile} = useMediaQuery();

  const {firstHorseId, secondHorseId} = useParams<RouteParams>();

  const [leftParent, setLeftParent] = useState<IFoalParent>();
  const [rightParent, setRightParent] = useState<IFoalParent>();

  const {currentVisitorType} = useVisitorTypeService();

  useEffect(() => {
    if (leftParent && rightParent) {
      onSuccess(leftParent, rightParent);
    }
  }, [leftParent, rightParent, onSuccess]);

  return (
    <Root className="d-flex w-100 flex-column overflow-hidden">
      <div className="d-flex w-100 position-relative">
        <FoalParent
          position={ElementPosition.Left}
          parentId={firstHorseId}
          onSuccess={setLeftParent}
          hasPartner={!!rightParent}
          changePartnerLink={
            secondHorseId && secondHorseId !== '0'
              ? `${PREFIX_VISITOR_TYPE[currentVisitorType]}/build-a-horse/${secondHorseId}`
              : null
          }
        />
        {!isMobile && !leftParent && !rightParent && (
          <GenderIcon className="position-absolute" color={ColorPalette.gray6} name={IconName.GenderSymbol} />
        )}
        <FoalParent
          position={ElementPosition.Right}
          parentId={secondHorseId}
          onSuccess={setRightParent}
          hasPartner={!!leftParent}
          changePartnerLink={
            firstHorseId && firstHorseId !== '0'
              ? `${PREFIX_VISITOR_TYPE[currentVisitorType]}/build-a-horse/${firstHorseId}`
              : null
          }
        />
      </div>
      <FoalParentsGenotypes
        firstParentGenotypeGroups={leftParent?.genotypeGroups}
        secondParentGenotypeGroups={rightParent?.genotypeGroups}
      />
      {hasFoal && (
        <FooterCorner>
          <FoalProfileHeaderTriangleImage />
        </FooterCorner>
      )}
    </Root>
  );
};

export default FoalProfileHeader;
