import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import styled from 'styled-components';

import {IAppState} from 'Common/store/IAppState';
import {Cell, Table} from 'Common/components/Table/Table';
import {CellAlign} from 'Common/components/Table/constants/CellAlign';
import {IUserAdmin} from 'Admin/AdminDashboard/models/User/IUserAdmin';

import {AdminUsersModule} from 'Admin/AdminDashboard/store/adminUsers/users/adminUsersModule';
import {Pagination} from 'Common/components/Controls/index';
import Loading from 'Loading/components/Loading';
import UserDetails from 'Admin/AdminDashboard/components/Users/UserDetails';
import {AdminPageLayout} from 'Admin/common/styled/StyledComponents';
import {actions, selectors} from 'Admin/AdminDashboard/store/adminUsers/users/index';
import {useCommonAdminPageData} from 'Admin/AdminDashboard/helpers/hooks/useCommonAdminPageData';
import {useUserActions} from 'Admin/AdminDashboard/helpers/hooks/useUserActions/useUserActions';
import withDynamicModules from 'Common/helpers/withDynamicModules';
import withUserActions, {
  getWithUserActionsModules,
  IWithUserActionsProps,
} from 'Admin/AdminDashboard/helpers/withUserActions';
import {getWithHorseActionsModules} from 'Admin/AdminDashboard/helpers/withHorseActions';
import {AvatarCell} from 'Admin/AdminDashboard/components/shared/index';
import {USER_SUBSCRIPTION_MARKED_ICON} from 'Common/constants/avatarMarkedIcon';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {useToast} from 'Common/helpers/hooks/useToast';
import {copyToClipboard} from 'Common/helpers/copyToClipboard';
import {getBaseUrl} from 'Common/helpers/getBaseUrl';
import {IMenuItemValued} from 'Common/models/IMenuItemValued';
import Actions from 'Common/components/Actions/Actions';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';

const iconProps = {color: ColorPalette.red7};
const deleteIconProps = {name: IconName.Garbage, color: ColorPalette.gray44};
const deleteTextProps = {color: ColorPalette.gray44, hoverColor: ColorPalette.red9, activeColor: ColorPalette.red11};

enum UserActionValue {
  CopyLink = 'copyLink',
  UpdateUser = 'updateUser',
  UpdateUserPermissions = 'updateUserPermissions',
  ChangeUserEmail = 'changeUserEmail',
  SendNewVerification = 'sendNewVerification',
  ChangeUserPassword = 'changeUserPassword',
  DeleteUser = 'deleteUser',
}

const menuItems: IMenuItemValued<UserActionValue>[] = [
  {value: UserActionValue.CopyLink, label: 'Copy profile link', icon: {name: IconName.FileCopy, ...iconProps}},
  {value: UserActionValue.UpdateUser, label: 'Edit user', icon: {name: IconName.Edit, ...iconProps}},
  {value: UserActionValue.UpdateUserPermissions, label: 'Edit permissions', icon: {name: IconName.Eye, ...iconProps}},
  {value: UserActionValue.ChangeUserEmail, label: 'Change email', icon: {name: IconName.Email, ...iconProps}},
  {
    value: UserActionValue.SendNewVerification,
    label: 'Send new verification',
    icon: {name: IconName.Reload, ...iconProps},
  },
  {value: UserActionValue.ChangeUserPassword, label: 'Change password', icon: {name: IconName.Lock, ...iconProps}},
  {
    value: UserActionValue.DeleteUser,
    label: 'Delete user',
    icon: {...deleteIconProps},
    style: {...deleteTextProps},
    divided: true,
  },
];

const UsersTable = styled.div`
  margin-top: 50px;
`;

const SearchSection = styled.div`
  display: grid;
  grid-template-columns: auto 168px;
  grid-gap: 50px;
`;

type IConnected = ConnectedProps<typeof connector>;

type AllProps = IConnected & IWithUserActionsProps;

const Users = (props: AllProps) => {
  const {
    users,
    usersLoading,
    getUsers,
    pagination,
    getUserById,
    userUpdating,
    userPermissionsUpdating,
    userDeleting,
    sendingNewVerification,
    userCreating,
  } = props;

  const {
    params,
    selectedId,
    setSelectedId,
    changeSorting,
    sorting,
    handlePageSelect,
    handleCountPerPage,
    deleteModal,
    searchBar,
  } = useCommonAdminPageData<IUserAdmin>({
    getItems: getUsers,
    searchBarPlaceholder: 'Search for users by any keyword',
  });

  const reloadUsers = useCallback(() => {
    getUsers(params);
    if (selectedId) {
      getUserById(selectedId);
    }
  }, [getUsers, getUserById, selectedId, params]);

  const {
    userActionsModal,
    openUpdateUserModal,
    openChangeUserPasswordModal,
    openChangeUserEmailModal,
    openModalUserPermissionsModal,
    openDeleteModal,
    openSendVerificationModal,
    openCreateUserModal,
  } = useUserActions({
    onSuccess: reloadUsers,
    data: {
      createHorseHandler: {communication: userCreating},
      updateUserInfoHandler: {communication: userUpdating},
      updateUserPermissionsHandler: {communication: userPermissionsUpdating},
      deleteUserHandler: {communication: userDeleting},
      sendingVerificationHandler: {communication: sendingNewVerification},
    },
  });

  const {addToast} = useToast();

  const copyProfileLinkHandler = (userId: number) => {
    copyToClipboard(`${getBaseUrl()}/admin/user/user-profile/${userId}/horses`);
    addToast('User profile link has been copied to your clipboard');
  };

  const renderExpandContent = useCallback(
    ({id}: IUserAdmin) => <UserDetails userId={id} onEditUser={openUpdateUserModal} onChange={reloadUsers} />,
    [openUpdateUserModal, reloadUsers]
  );

  const setSelectedOrderId = useCallback((id: string | number) => setSelectedId(Number(id)), [setSelectedId]);

  const formBlockers = usersLoading.isRequesting || userDeleting.isRequesting;

  const isShowPagination = users.length > 0;

  return (
    <AdminPageLayout>
      {deleteModal}
      {userActionsModal}
      <SearchSection>
        {searchBar}
        <PrimaryButton onClick={openCreateUserModal}>+ Create user</PrimaryButton>
      </SearchSection>
      <UsersTable className="position-relative">
        {formBlockers && <Loading />}
        <Table<IUserAdmin>
          data={users}
          rowKey="id"
          sorting={sorting}
          onChangeSorting={changeSorting}
          renderExpandContent={renderExpandContent}
          expandable={true}
          onExpand={setSelectedOrderId}
        >
          <Cell<IUserAdmin> header="ID" dataKey="id" render={({id}) => `#${id}`} expandOnClick={true} width="10%" />
          <Cell<IUserAdmin>
            header="User"
            dataKey="name"
            render={({id, name, avatar, hasSubscription, hasOnlineReportAccess}) => (
              <AvatarCell
                type="owner"
                avatarUrl={avatar?.url}
                profileUrl={`/admin/user/user-profile/${id}/horses`}
                label={name}
                isMarkedIcon={hasSubscription}
                markedIcon={USER_SUBSCRIPTION_MARKED_ICON}
                onLabelClick={() => openUpdateUserModal(id)}
                hasOnlineReportAccess={hasOnlineReportAccess}
              />
            )}
            width="20%"
          />
          <Cell<IUserAdmin> header="Email" dataKey="email" render={({email}) => email} width="30%" />
          <Cell<IUserAdmin> header="Address" dataKey="address" render={({address}) => address} width="20%" />
          <Cell<IUserAdmin>
            header="Animals"
            dataKey="animalCount"
            align={CellAlign.Center}
            render={({animalCount}) => animalCount}
            width="5%"
          />
          <Cell<IUserAdmin>
            header="Orders"
            dataKey="ordersCount"
            align={CellAlign.Center}
            render={({ordersCount}) => ordersCount}
            width="5%"
          />
          <Cell<IUserAdmin>
            header="Actions"
            align={CellAlign.Right}
            width="10%"
            render={({id, name, email}) => (
              <Actions<UserActionValue>
                menuItems={menuItems}
                copyLink={() => copyProfileLinkHandler(id)}
                updateUser={() => openUpdateUserModal(id)}
                updateUserPermissions={() => openModalUserPermissionsModal(id, name)}
                changeUserEmail={() => openChangeUserEmailModal(id, name, email)}
                changeUserPassword={() => openChangeUserPasswordModal(id, name, email)}
                deleteUser={() => openDeleteModal(id)}
                sendNewVerification={() => openSendVerificationModal(email)}
              />
            )}
          />
        </Table>
        {isShowPagination && (
          <Pagination
            pagination={pagination}
            onPageSelect={handlePageSelect}
            onChangeCountPerPage={handleCountPerPage}
          />
        )}
      </UsersTable>
    </AdminPageLayout>
  );
};

const mapStateToProps = (state: IAppState) => ({
  users: selectors.selectUsers(state),
  usersLoading: selectors.selectCommunication(state, 'usersLoading'),
  pagination: selectors.selectPagination(state),
});

const mapDispatchToProps = {
  getUsers: actions.getUsers,
  getUserById: actions.getUserById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withDynamicModules(connector(withUserActions(Users)), [
  AdminUsersModule,
  getWithUserActionsModules(),
  getWithHorseActionsModules(),
]);
