import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {AdminTestResultsActions, IAdminTestResultsState, uploadTestResultsActionTypes} from '../types';

export const communicationTestResultsReducer = combineReducers<
  IAdminTestResultsState['communications'],
  AdminTestResultsActions
>({
  testResultsUploading: makeCommunicationReducerFromEnum(uploadTestResultsActionTypes),
});
