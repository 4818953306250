import {IModule} from 'redux-dynamic-modules';

import {IUserToUserHubState} from './types';
import {reducer} from './reducer';

export interface IUserToUserHubModuleState {
  userToUserHub: IUserToUserHubState;
}

export const UserToUserHubModule: IModule<IUserToUserHubModuleState> = {
  id: 'userToUserHub',
  reducerMap: {
    userToUserHub: reducer,
  },
};
