import React from 'react';
import {Route} from 'react-router-dom';

import MailNotConfirmed from 'MailConfirmation/components/MailNotConfirmed/MailNotConfirmed';
import MailConfirmation from 'MailConfirmation/components/MailConfirmation';

export default [
  <Route key="/confirm" path="/confirm" Component={MailConfirmation} />,
  <Route
    key="/email-not-confirmed"
    path="/email-not-confirmed/:email"
    element={<MailNotConfirmed type="notConfirmed" />}
  />,
  <Route
    key="/email-link-expired"
    path="/email-link-expired/:email"
    element={<MailNotConfirmed type="expiredLink" />}
  />,
];
