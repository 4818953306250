import {IServerOrderDetails} from '../models/details';
import {IOrderDetails} from '../../models/IOrderDetails';

export function convertOrderDetailsToClient(details: IServerOrderDetails): IOrderDetails {
  return {
    orderId: details.orderId,
    paymentAmount: details.paymentAmount,
    isPaid: details.isPaid,
  };
}
