import {IModule} from 'redux-dynamic-modules';

import {IUserToAdminHubState} from './types';
import {reducer} from './reducer';

export interface IUserToAdminHubModuleState {
  userToAdminHub: IUserToAdminHubState;
}

export const UserToAdminHubModule: IModule<IUserToAdminHubModuleState> = {
  id: 'userToAdminHub',
  reducerMap: {
    userToAdminHub: reducer,
  },
};
