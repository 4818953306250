import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  IAdminDogTestResultsUploadState,
  uploadDogTestResultsActionTypes,
  getDogTestResultsUploadDetailsActionTypes,
  AdminDogTestResultsUploadActions,
} from '../types';

export const communicationDogTestResultsUploadReducer = combineReducers<
  IAdminDogTestResultsUploadState['communications'],
  AdminDogTestResultsUploadActions
>({
  dogTestResultsUploading: makeCommunicationReducerFromEnum(uploadDogTestResultsActionTypes),
  dogTestResultsUploadDetailsLoading: makeCommunicationReducerFromEnum(getDogTestResultsUploadDetailsActionTypes),
});
