import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Form, Formik} from 'formik';

import Nebula from 'Common/components/Layout/Nebula';
import {
  ModalWindowButton,
  ModalWindowFooter,
  ModalWindowFormContent,
  ModalWindowHeader,
} from 'Common/components/Modal/shared';
import {CheckboxField} from 'Common/components/FormFields';
import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import Loading from 'Loading/components/Loading';
import {OnlineReportType} from 'OnlineReport/components/shared/OnlineReportType';

import OnlineReportsService from 'OnlineReport/services/OnlineReportsService';
import {IFormValues} from '../PrintSelectionForm/validation';
import {Nullable} from 'Common/types';
import {IOnlineReportCoatColor} from 'OnlineReport/models/CoatColor/IOnlineReportCoatColor';
import {IOnlineReportAbility} from 'OnlineReport/models/PerformanceAndAbilities/IOnlineReportAbility';
import {IOnlineReportHealthVariants} from 'OnlineReport/models/HealthVariants/IOnlineReportHealthVariants';
import PrintSelectionCheckboxGroup from '../PrintSelectionForm/PrintSelectionCheckboxGroup';
import {GeneType} from 'Admin/AdminPhenotypes/constants/GeneType';
import {formikPropsToValues} from '../PrintSelectionForm/converters';
import {IUserPrintableReportPreferences} from 'OnlineReportPrintable/models/IUserPrintableReportPreferences';

const FormWrapper = styled.div`
  max-height: 500px;
`;

export const FormHint = styled.p`
  color: ${ColorPalette.gray3};
  font-size: ${Typography.size.size16};
  line-height: 24px;
  padding-bottom: 16px;
`;

export const PaddedCheckbox = styled(CheckboxField)`
  margin-left: 8px;
`;

interface IExternalProps {
  horseId: number;
  reportType: OnlineReportType;
  isLoading?: boolean;
  onSubmit(fields: IFormValues): Promise<void>;
}

type OuterProps = IExternalProps;

type InitialValueProps = {
  initialValue: IFormValues;
  shouldSelectAll: boolean;
};

type AllProps = OuterProps & InitialValueProps;

const PrintSelectionFormik = (props: AllProps) => {
  const {horseId} = props;
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting] = useState(false);
  const [coatColors, setCoatColors] = useState<Nullable<IOnlineReportCoatColor[]>>([]);
  const [healthVariants, setHealthVariants] = useState<Nullable<IOnlineReportHealthVariants[]>>([]);
  const [abilities, setAbilities] = useState<Nullable<IOnlineReportAbility[]>>([]);
  const [printableReportPreferences, setPrintableReportPreferences] =
    useState<Nullable<IUserPrintableReportPreferences>>(null);
  const [initialFormikValue, setInitialFormikValue] = useState<IFormValues>(props.initialValue);
  // TODO: adding `getAllTraits` or `getPrintableReportPreferences` in dependencies causes
  // infinite render glitch. not sure why but maybe something to do with the distributor?
  // @ts-ignore
  useEffect(() => {
    getAllTraits().then((res) => {
      setCoatColors(res['coatColors']);
      setHealthVariants(res['healthVariants']);
      setAbilities(res['abilities']);
      setPrintableReportPreferences(res['printableReportPreferences'] as IUserPrintableReportPreferences);
    });
  }, [horseId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllTraits = async () => {
    setLoading(true);
    const promises = [
      OnlineReportsService.getCoatColor(+horseId),
      OnlineReportsService.getHealthVariants(+horseId),
      OnlineReportsService.getAbilities(+horseId),
    ];
    const results = await Promise.all(promises);
    const coatColors = results[0] as IOnlineReportCoatColor[];
    const healthVariants = results[1] as IOnlineReportHealthVariants[];
    const abilities = results[2] as IOnlineReportAbility[];
    setLoading(false);
    const traits = {
      coatColors,
      healthVariants,
      abilities,
      printableReportPreferences,
    };
    if (props.shouldSelectAll) {
      const newFormikValue = formikPropsToValues(traits);
      setInitialFormikValue(newFormikValue);
    }
    return traits;
  };

  return (
    <>
      <ModalWindowHeader>Customize printed report</ModalWindowHeader>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormikValue}
        onSubmit={(values: IFormValues, {setSubmitting}) => {
          props.onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form className="d-flex flex-column justify-content-center">
          <ModalWindowFormContent>
            <FormWrapper>
              {isLoading && <Loading />}
              <FormHint>
                Choose which parts of your horse's report you would like to make visible on the printed report. Expand a
                section to change the visibility for individual traits. This will only temporarily affect the printed
                report format.
              </FormHint>
              <Nebula active={isLoading || isSubmitting}>
                <PrintSelectionCheckboxGroup label={'Coat Color'}>
                  {coatColors
                    ?.filter((group) => group.partialColors.length > 0)
                    .map((group, i) => (
                      <PrintSelectionCheckboxGroup key={i} label={group.name}>
                        {group.partialColors
                          .filter((group) => group.traits.length > 0 && String(group.state) !== GeneType.NotTested)
                          .map((group, i) => (
                            <PaddedCheckbox
                              name={`partialColors.traits.${group.id.toString()}.isVisible`}
                              label={group.name}
                            />
                          ))}
                      </PrintSelectionCheckboxGroup>
                    ))}
                </PrintSelectionCheckboxGroup>
                <PrintSelectionCheckboxGroup label={'Health Variants'}>
                  {healthVariants
                    ?.filter((group) => group.healthVariants.length > 0)
                    .map((group, i) => (
                      <PrintSelectionCheckboxGroup key={i} label={group.name}>
                        {group.healthVariants
                          .filter((group) => group.traits.length > 0 && String(group.state) !== GeneType.NotTested)
                          .map((group, i) => (
                            <PaddedCheckbox
                              name={
                                group.isAggregated && group.aggregatorId
                                  ? `aggregatedHealthIssues.traits.${group.aggregatorId.toString()}.isVisible`
                                  : `advancedHealthIssues.traits.${group.id.toString()}.isVisible`
                              }
                              label={group.name}
                            />
                          ))}
                      </PrintSelectionCheckboxGroup>
                    ))}
                </PrintSelectionCheckboxGroup>
                <PrintSelectionCheckboxGroup label={'Performance and Abilities'}>
                  {abilities
                    ?.filter((group) => group.abilities.length > 0)
                    .map((group, i) => (
                      <PrintSelectionCheckboxGroup key={i} label={group.name}>
                        {group.abilities
                          .filter((group) => group.traits.length > 0 && !group.isAggregated)
                          .map((group, i) => (
                            <PaddedCheckbox
                              name={
                                group.isAggregated && group.aggregatorId
                                  ? `aggregatedAbilities.traits.${group.aggregatorId.toString()}.isVisible`
                                  : `advancedAbilities.traits.${group.id.toString()}.isVisible`
                              }
                              label={group.name}
                            />
                          ))}
                      </PrintSelectionCheckboxGroup>
                    ))}
                </PrintSelectionCheckboxGroup>
              </Nebula>
            </FormWrapper>
          </ModalWindowFormContent>
          <ModalWindowFooter>
            <PaddedCheckbox name="rememberChoices" label="Remember my choices" />
            <ModalWindowButton type="submit" hidden={false} disabled={false} isLoading={false}>
              Save selection
            </ModalWindowButton>
          </ModalWindowFooter>
        </Form>
      </Formik>
    </>
  );
};

export default PrintSelectionFormik;
