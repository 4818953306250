import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {getThunkExtension} from 'redux-dynamic-modules-thunk';
import {createStore} from 'redux-dynamic-modules';
import {datadogLogs} from '@datadog/browser-logs';
import {Datacenter} from '@datadog/browser-core/cjs/init';
import {HandlerType} from '@datadog/browser-logs/cjs/logger';
import {init} from '@fullstory/browser';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import {getEnvParams} from 'Common/helpers/getEnvParams';
import App from 'Common/components/App';
import {RouterModule} from 'Common/store/commonModules';
import {DevModule} from 'Common/store/developmentModule';
import {CurrentUserModule} from 'UserProfile/store/currentUser/currentUserModule';
import {PermissionsModule} from 'Permissions/store/PermissionsModule';
import 'index.css';
import DictionaryFactory from 'DictionaryFactory/DictionaryFactory';
import SimpleDictionaryFactory from 'DictionaryFactory/SimpleDictionaryFactory';
import StripeProviderLoader from 'Payment/components/Stripe/StripeProviderLoader';
import {convertToLoggerContext} from 'Common/helpers/convertToLoggerContext';
import {createActivityLogger} from 'Common/helpers/LoggerHelper';
import {ServiceModeModule} from 'Maintain/store/serviceModeModule';

const {
  stripeApiKey,
  dataDogClientToken,
  dataDogDestination,
  dataDogTags,
  fullstoryOrgId,
  recaptchaKey,
  googleTagManagerId,
} = getEnvParams();

if (!stripeApiKey) {
  console.error('Empty stripe api key. Please provide public key for stripe. Now order`s payment doesn`t work!');
}

if (!dataDogClientToken) {
  console.error('Empty DataDog client token.');
}

const ddTags = {};

if (dataDogTags) {
  const properties = dataDogTags.split(',');

  properties.forEach(function (property) {
    const tup = property.split(':');
    ddTags[tup[0]] = tup[1];
  });
}

datadogLogs.init({
  clientToken: dataDogClientToken || 'empty',
  datacenter: Datacenter.US,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: ddTags['env'],
  service: ddTags['service'],
});

datadogLogs.setLoggerGlobalContext(convertToLoggerContext(ddTags, false));

if (dataDogDestination) {
  datadogLogs.logger.setHandler(HandlerType[dataDogDestination]);
}

if (!recaptchaKey) {
  console.error('Empty Google reCAPTCHA api key. Please provide key for reCAPTCHA.');
}

if (googleTagManagerId) {
  const tagManagerArgs = {
    gtmId: googleTagManagerId,
  };

  TagManager.initialize(tagManagerArgs);
} else {
  console.error('Empty Google Tag Manager ID');
}

if (fullstoryOrgId) {
  init({orgId: fullstoryOrgId, cookieDomain: window.location.hostname});
} else {
  init({orgId: '0000'});
}

const store = createStore(
  {
    extensions: [getThunkExtension()],
  },
  ...[RouterModule(), CurrentUserModule, PermissionsModule, ServiceModeModule]
);

if (process.env.NODE_ENV === 'development') {
  store.addModule(DevModule);
}

DictionaryFactory.init(store);
SimpleDictionaryFactory.init(store);
createActivityLogger(store);
// const {createReduxHistory} = historyContext();
// const historyStore = createReduxHistory(store);

const container = document.getElementById('etalon-diagnostics');

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
  },
]);

createRoot(container!).render(
  <Provider store={store}>
    <StripeProviderLoader stripeApiKey={stripeApiKey || 'empty'}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        <RouterProvider router={router} />
      </GoogleReCaptchaProvider>
    </StripeProviderLoader>
  </Provider>
);
