import {IModule} from 'redux-dynamic-modules';

import {IUserNotificationState} from './types';
import {userNotificationsReducer} from './reducer';

export interface IUserNotificationsModuleState {
  userNotifications: IUserNotificationState;
}

export const UserNotificationsModule: IModule<IUserNotificationsModuleState> = {
  id: 'userNotifications',
  reducerMap: {
    userNotifications: userNotificationsReducer,
  },
};
