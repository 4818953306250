import React, {memo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, ConnectedProps} from 'react-redux';

import SampleId from 'Admin/AdminDashboard/components/shared/SampleId/SampleId';
import {FRONTEND_DATE3} from 'Common/constants/Date';
import withDate from 'Common/helpers/withDate';
import {Details} from 'Admin/AdminDashboard/components/shared';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import {IAppState} from 'Common/store/IAppState';
import {actions, selectors} from 'Admin/AdminAssociations/store/adminOrders/index';
import Loading from 'Loading/components/Loading';
import ModalWindow from 'Common/components/Modal/ModalWindow';
import {useRequestNewSampleAction} from 'Admin/shared/helpers/hooks/useOrderActions/useRequestNewSampleAction';
import {HorseSampleStatus} from 'Order/models/IOrderStatusFields';
import {useEventBus} from 'Common/helpers/hooks/useEvenBus';
import {EventName} from 'Common/constants/EventName';
import ChangeSampleForm from 'Admin/shared/components/ChangeSampleForm/ChangeSampleForm';
import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';

const Button = styled(PrimaryButton)`
  width: 200px;
  margin-top: 24px;
  margin-left: 20px;
  padding: 0 4px;
`;

interface IOwnProps {
  orderId: number;
  hasSample?: boolean;
  onSuccess(): void;
}

type IConnected = ConnectedProps<typeof connector>;

type Props = IConnected & IOwnProps;

function SampleDetails(props: Props) {
  const {orderId, orderSampleDetails, orderSampleDetailsLoading, getOrderSampleDetails, onSuccess} = props;

  const [isEditSampleModalOpen, setIsEditSampleModalOpen] = useState(false);
  const openEditSampleModal = useCallback(() => setIsEditSampleModalOpen(true), []);
  const closeEditSampleModal = useCallback(() => setIsEditSampleModalOpen(false), []);

  const {emit, listen} = useEventBus();

  const {requestNewSampleModal, openRequestNewSampleModal} = useRequestNewSampleAction({
    onSuccess: () => {
      emit(EventName.SampleChanged);
      onSuccess();
    },
  });
  const openRequestNewSampleModalHandler = useCallback(
    () => openRequestNewSampleModal(orderId),
    [openRequestNewSampleModal, orderId]
  );

  const getDetails = useCallback(() => {
    if (orderId) {
      getOrderSampleDetails(orderId);
    }
  }, [getOrderSampleDetails, orderId]);

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const eventSampleChanged = listen(EventName.SampleChanged, getDetails);

    return () => eventSampleChanged.stopListening();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = orderSampleDetailsLoading.isRequesting;
  const hasSample = orderSampleDetails.status === HorseSampleStatus.Received;

  const sampleDetails = [
    {
      title: 'Sample ID',
      value: (
        <SampleId sampleId={orderSampleDetails?.sampleId} hasSample={hasSample} onEditClick={openEditSampleModal} />
      ),
    },
    {
      title: 'Sample Date',
      value:
        (hasSample && orderSampleDetails?.receivedDate && withDate(orderSampleDetails.receivedDate, FRONTEND_DATE3)) ||
        'Not received',
    },
  ];

  return (
    <div className="position-relative">
      {requestNewSampleModal}

      {orderSampleDetails && (
        <ModalWindow isOpen={isEditSampleModalOpen} onClose={closeEditSampleModal}>
          <ChangeSampleForm
            adminVisitorType={SelectedStrategy.AdminAssociation}
            sample={orderSampleDetails}
            onSuccess={closeEditSampleModal}
          />
        </ModalWindow>
      )}

      {isLoading && <Loading />}
      <Details records={sampleDetails} />
      <Button onClick={openRequestNewSampleModalHandler}>Resubmit sample</Button>
    </div>
  );
}

const mapStateToProps = (state: IAppState) => ({
  orderSampleDetails: selectors.selectOrderSampleDetails(state),
  orderSampleDetailsLoading: selectors.selectCommunication(state, 'orderSampleDetailsLoading'),
});

const mapDispatchToProps = {
  getOrderSampleDetails: actions.getOrderSampleDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default memo(connector(SampleDetails));
