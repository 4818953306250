import {IModule} from 'redux-dynamic-modules';

import {shortTandemRepeatReportReducer} from './reducer';
import {IAdminShortTandemRepeatReportState} from './types';

export interface IAdminShortTandemRepeatReportModuleState {
  adminShortTandemRepeatReport: IAdminShortTandemRepeatReportState;
}

export const AdminShortTandemRepeatReportModule: IModule<IAdminShortTandemRepeatReportModuleState> = {
  id: 'adminShortTandemRepeatReport',
  reducerMap: {
    adminShortTandemRepeatReport: shortTandemRepeatReportReducer,
  },
};
