import {IModule} from 'redux-dynamic-modules';
import {RouterState} from 'redux-first-history';

import {historyContext} from './historyContext';

export const RouterModule = (): IModule<RouterState> => {
  const {routerMiddleware, routerReducer} = historyContext();

  return {
    id: 'router',
    reducerMap: {
      router: routerReducer,
    } as any,
    middlewares: [routerMiddleware],
  };
};
