import * as React from 'react';
import {Navigate, Route, To} from 'react-router-dom';

import Main from 'Main/components/Main';
import {COMMON_NAVIGATION_ROUTES, USER_NAVIGATION_ROUTES} from 'Common/components/Navigation';
import {getRoutes} from 'Common/helpers/getRoutes';

function AppAuthRouting(from: To) {
  return [
    ...getRoutes(COMMON_NAVIGATION_ROUTES),
    ...getRoutes(USER_NAVIGATION_ROUTES),
    <Route key="main" path="/" Component={Main} />,
    <Route key="navigate" path="*" element={<Navigate to={from} />} />,
  ];
}

export default AppAuthRouting;
