import * as Yup from 'yup';

import {ActivationCodeStatus} from 'ActivationCode/constants/ActivationCodeStatus';
import {IActivationCode, IActivationCodeRedemption} from 'ActivationCode/models/IActivationCode';
import {AnimalType} from 'Common/constants/AnimalType';
import {IPackageSimple, PackageStatus} from '../../../../../Dictionaries/models/IPackageSimple';

export interface IActivationCodeProductDetails {
  packageId: number;
  animalType: AnimalType;
  amount: number;
}

export interface IActivationCodeCreateRequest {
  productsDetails: IActivationCodeProductDetails[];
  description: string;
  source: string;
}

export interface IActivationCodeRequest extends IActivationCode {
  packageId: number;
  animalType: AnimalType;
  description: string;
  amount?: number;
}

export interface IActivationCodeActivateRequest {
  activationCodeId: number;
  animalId: string;
}

export interface IActivationCodeForm extends IActivationCode {
  packageId: number;
  animalType: AnimalType;
  amount?: number;
}

export const initialValue: IActivationCodeForm = {
  id: 0,
  code: '',
  packageId: 0,
  animalType: AnimalType.Horse,
  amount: 0,
  pkg: {id: 0, name: '', status: PackageStatus.Disabled},
  status: ActivationCodeStatus.Inactive,
  description: '',
  productSource: '',
  createdAt: null,
  activationCodeRedemption: {redeemer: {id: 0, firstName: '', lastName: ''}, animal: {id: 0, name: ''}},
};

export const validationSchema = Yup.object().shape<IActivationCodeForm>({
  id: Yup.number(),
  code: Yup.string(),
  packageId: Yup.number(),
  animalType: Yup.mixed<AnimalType>(),
  amount: Yup.number(),
  pkg: Yup.mixed<IPackageSimple>(),
  status: Yup.mixed<ActivationCodeStatus>(),
  description: Yup.string(),
  productSource: Yup.string(),
  createdAt: Yup.string().nullable(),
  activationCodeRedemption: Yup.mixed<IActivationCodeRedemption>(),
});
