import * as Yup from 'yup';

import {IUser} from 'UserProfile/models/IUser';
import {Salutation} from 'Common/constants/Salutation';
import {IImage} from 'Image/models/IImage';
import {ICoordinatedLocation} from 'Common/models/ICoordinatedLocation';
import {IAddress} from 'Common/models/IAddress';
import {IWithUserMapFieldProps} from 'Common/components/FormFields/UserMapField/UserMapField';

export type IUserPersonalDetailFormValues = IWithUserMapFieldProps &
  Omit<
    IUser,
    'profileType' | 'id' | 'countHorses' | 'countVerifiedTests' | 'isAgreement' | 'agreementBah' | 'ancestryAgreement'
  >;

export const initialValue: IUserPersonalDetailFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  phone: '',
  salutation: Salutation.Unknown,
  avatar: null,
  location: null,
  userAddress: null,
  address: '',
  isManualAddressMode: false,
};

export const validationSchema = Yup.object().shape<IUserPersonalDetailFormValues>({
  email: Yup.string().email('Email not valid').required('Email is required'),
  firstName: Yup.string().required('First name is required').max(100, 'First name must be at most 100 characters'),
  lastName: Yup.string().required('Last name is required').max(100, 'Last name must be at most 100 characters'),
  mobile: Yup.string(),
  phone: Yup.string(),
  salutation: Yup.mixed<Salutation>(),
  avatar: Yup.object().shape<IImage>({id: Yup.number(), url: Yup.string()}).nullable(),
  location: Yup.object()
    .shape<ICoordinatedLocation>({
      id: Yup.number(),
      latitude: Yup.number(),
      longitude: Yup.number(),
    })
    .nullable(),
  userAddress: Yup.mixed<IAddress>(),
  address: Yup.string().test('address', 'Unknown address', function () {
    return this.parent.userAddress;
  }),
  isManualAddressMode: Yup.boolean(),
});
