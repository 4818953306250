import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication/index';
import {
  IAdminShortTandemRepeatUploadState,
  AdminShortTandemRepeatUploadActions,
  uploadShortTandemRepeatActionTypes,
} from '../types';

export const communicationShortTandemRepeatUploadReducer = combineReducers<
  IAdminShortTandemRepeatUploadState['communications'],
  AdminShortTandemRepeatUploadActions
>({
  shortTandemRepeatUploading: makeCommunicationReducerFromEnum(uploadShortTandemRepeatActionTypes),
});
