import React, {memo} from 'react';
import styled from 'styled-components';

import CommonLink from 'Common/components/Controls/Links/CommonLink';
import Typography from 'Common/constants/Typography';
import ColoredIcon from 'Icon/components/ColoredIcon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IconName} from 'Icon/components/Icon';

const Text = styled.span`
  font-weight: ${Typography.weight.normal400};
`;

const LinkWrapper = styled.div`
  gap: 8px;
  margin-top: 8px;
`;

interface IProps {
  userId: number;
}

function AdminMessageReceived(props: IProps) {
  const {userId} = props;
  const supportUrl = `/admin-support/support?filters[0][property]=UserToAdminChat.User&filters[0][operation]=Equal&filters[0][value]=${userId}`;

  return (
    <div className="d-flex flex-column">
      <Text>User message received.</Text>

      <LinkWrapper className="d-flex align-items-center justify-content-center">
        <CommonLink to={supportUrl}>
          Click here to go to Support page <ColoredIcon name={IconName.ArrowRight} color={ColorPalette.white0} />
        </CommonLink>
      </LinkWrapper>
    </div>
  );
}

export default memo(AdminMessageReceived);
