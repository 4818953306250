import {FullStory} from '@fullstory/browser';
import {IUser} from '../../UserProfile/models/IUser';

/**
 * This method is used to update the FullStory user with the current user's information.
 * It should be called when any changes in auth state change.
 */
export function updateFullStoryUser(details?: {
  isAdmin?: true;
  horseCount?: number;
  verifiedTestCount?: number;
  id: any;
  email: string;
  phone: string;
  name: string;
}) {
  FullStory(
    'setIdentity',
    details
      ? {
          uid: details.id.toString(),
          consent: true,
          properties: {
            displayName: details.name,
            email: details.email,
            horseCount: details.horseCount,
            isAdmin: details.isAdmin,
            phone: details.phone,
            verifiedTestCount: details.verifiedTestCount,
          },
        }
      : {}
  );
}
