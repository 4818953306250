import React, {memo} from 'react';
import {useParams} from 'react-router-dom';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {VisitorType} from 'Common/constants/VisitorType';
import UserProfile, {UserProfileRouteProps} from 'Shared/components/UserProfile/UserProfile';
import {UserProfileType} from 'Shared/models/UserProfileType';

const AdminUserProfileLayout = () => {
  const {userProfileType} = useParams<UserProfileRouteProps>();

  return (
    <BaseLayout withoutPaddings={true} backButtonStyle={{marginTop: 20}}>
      <UserProfile visitorType={VisitorType.Admin} userProfileType={userProfileType as UserProfileType} />
    </BaseLayout>
  );
};

export default memo(AdminUserProfileLayout);
