import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import Icon, {IconName} from 'Icon/components/Icon';
import FormControlContainer from 'Common/components/Layout/FormControlContainer';
import Typography from 'Common/constants/Typography';
import {IPersonalPrivacySettings} from 'UserProfile/models/IUserPrivacySettings';
import {Nullable} from 'Common/types';
import {SwitchFieldWrapper, SwitchWrapper} from 'Common/components/StyledComponents/StyledComponents';
import Switch from 'Common/components/Controls/Switch';
import {SettingType} from 'UserProfile/services/UserService';
import {ItemVisibilityText} from '../../styled';

const iconProps = {size: 22, style: {marginRight: 10}};

const PrivacyText = styled.div`
  font-size: ${Typography.size.size14};
`;

interface IData {
  label: string;
  type: SettingType;
  icon: IconName;
  value: boolean;
}

interface IProps {
  title: string;
  personalPrivacySettings: Nullable<IPersonalPrivacySettings>;
  onSuccess(isPublic: boolean, settings: SettingType): void;
}

const PersonalPrivacySettings = (props: IProps) => {
  const {title, personalPrivacySettings, onSuccess} = props;

  const [isPublicEmail, setIsPublicEmail] = useState(personalPrivacySettings?.isPublicEmail || false);
  const [isPublicPhone, setIsPublicPhone] = useState(personalPrivacySettings?.isPublicPhone || false);
  const [isPublicLocation, setIsPublicLocation] = useState(personalPrivacySettings?.isPublicLocation || false);

  const data: IData[] = useMemo(
    () => [
      {label: 'My E-mail', type: 'Email', icon: IconName.Mail, value: isPublicEmail},
      {
        label: 'My Phone Number',
        type: 'Phone',
        icon: IconName.CallPhone,
        value: isPublicPhone,
      },
      {
        label: 'Address',
        type: 'Location',
        icon: IconName.Pin,
        value: isPublicLocation,
      },
    ],
    [isPublicEmail, isPublicLocation, isPublicPhone]
  );

  const setValue = useCallback((value: boolean, type: SettingType) => {
    switch (type) {
      case 'Email':
        setIsPublicEmail(value);
        break;
      case 'Location':
        setIsPublicLocation(value);
        break;
      case 'Phone':
        setIsPublicPhone(value);
        break;
    }
  }, []);

  return (
    <FormControlContainer title={title} style={{marginBottom: 10}}>
      {data.map((item, i) => {
        const onChange = () => {
          setValue(!item.value, item.type);
          onSuccess(!item.value, item.type);
        };
        return (
          <SwitchWrapper className="d-flex flex-row" key={i}>
            <div className="d-flex flex-row align-items-center w-100">
              <Icon name={item.icon} {...iconProps} />
              <PrivacyText>{item.label}</PrivacyText>
            </div>
            <SwitchFieldWrapper>
              <Switch
                onChange={onChange}
                checked={item.value}
                textLeft={<ItemVisibilityText>{item.value ? 'Public' : 'Private'}</ItemVisibilityText>}
                labelStyle={{minWidth: 40}}
              />
            </SwitchFieldWrapper>
          </SwitchWrapper>
        );
      })}
    </FormControlContainer>
  );
};

export default PersonalPrivacySettings;
