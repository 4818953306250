import React, {memo} from 'react';
import styled from 'styled-components';

import {Link} from 'react-router-dom';
import PrimaryButton, {ButtonProps} from './PrimaryButton';
import withoutProps from 'Common/helpers/WithoutProps';

// eslint-disable-next-line no-unexpected-multiline
const Root = styled(withoutProps(['fullWidth'])(Link))<{
  size: ButtonProps['size'];
  fullWidth: ButtonProps['fullWidth'];
}>`
  display: block;
  text-decoration: none !important;
  min-width: 168px;
  width: ${(p) => (p.fullWidth ? '100%' : 'unset')};

  ${(p) =>
    p.size === 'small'
      ? `
    height: 32px;
    min-height: 32px;
    `
      : `
    height: 48px;
    min-height: 48px;
    `};
`;

const Button = styled(PrimaryButton)`
  min-width: unset;
  width: 100%;
  height: 100%;
`;

interface IOwnProps {
  to: string;
}

type Props = IOwnProps & ButtonProps;

function LinkButton(props: Props) {
  const {className, to, children, ...rest} = props;
  return (
    <Root to={to} size={rest.size} className={className} fullWidth={rest.fullWidth}>
      <Button {...rest} type="button">
        {children}
      </Button>
    </Root>
  );
}

export default memo(LinkButton);
