import {convertServerOrderHistoryToClient} from 'Admin/AdminDashboard/services/converters/common/orderHistory';
import {IUserDetails} from 'Admin/AdminDashboard/models/User/IUserDetails';
import {IServerUserDetailsAdmin} from 'Admin/AdminDashboard/services/types/user';
import {IAdminUserPermissions} from 'Admin/AdminDashboard/models/User/IUserPermissions';
import {convertUTCToClientDate} from 'Common/helpers/DateHelper';
import {IUserFullInfo} from 'Admin/AdminDashboard/models/User/IUserFullInfo';
import {withUserName} from 'Common/helpers/withUserName';
import {withAvatar} from 'Common/helpers/withAvatar';

export function convertUserDetailsToClient(userDetails: IServerUserDetailsAdmin): IUserDetails {
  return {
    ...withUserName(userDetails),
    orders: userDetails.orders.map(convertServerOrderHistoryToClient),
    horses: userDetails.horses.map(withAvatar),
  };
}

export function convertUserPermissions(permissions: IAdminUserPermissions): IAdminUserPermissions {
  return {
    permissions: permissions.permissions.map((item) => ({
      ...item,
      expiryDate: item.expiryDate ? convertUTCToClientDate(item.expiryDate) : '',
    })),
    userId: permissions.userId,
  };
}

export function convertUserFullInfoToClient(user: IUserFullInfo): IUserFullInfo {
  return {
    ...withAvatar(user),
    privacySettings: user.privacySettings || {isPublicEmail: false, isPublicLocation: false, isPublicPhone: false},
  };
}
