import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {queryParamsToObject} from 'Common/helpers/objectToQueryParams';

interface IFilterReportQueryRaw {
  summary: {
    variantSummary?: boolean;
    abilities?: boolean;
    coatColors?: boolean;
    healthVariants?: boolean;
  };
  partialColors?: number[] | false;
  aggregatedHealthIssues?: number[] | false;
  advancedHealthIssues?: number[] | false;
  aggregatedAbilities?: number[] | false;
  advancedAbilities?: number[] | false;
}

/**
 * Query object extracted from URL query which specifies
 * which parts of the report the user wants to generate.
 */
export interface IFilterReportQuery {
  summary: {
    variantSummary?: boolean;
    abilities?: boolean;
    coatColors?: boolean;
    healthVariants?: boolean;
  };
  partialColors?: number[];
  aggregatedHealthIssues?: number[];
  advancedHealthIssues?: number[];
  aggregatedAbilities?: number[];
  advancedAbilities?: number[];
}

/**
 * Converts an optional value of `Array<T> | any to `Array<T>?`.
 */
const parseArray = (input?: string[] | string | any): number[] | undefined => {
  if (input === undefined) return undefined;

  if (!Array.isArray(input)) {
    if (Number.isInteger(input)) return [input];

    return input.length > 0 ? [parseInt(input)] : [];
  }

  return input.map((x: string) => parseInt(x));
};

/**
 * Converts parsed query params to an `IFilterReportQuery`.
 */
const processFilterReportQuery = (parsed: IFilterReportQueryRaw): IFilterReportQuery => ({
  summary: parsed.summary || {
    variantSummary: true,
    abilities: true,
    coatColors: true,
    healthVariants: true,
  },
  partialColors: parseArray(parsed.partialColors),
  aggregatedHealthIssues: parseArray(parsed.aggregatedHealthIssues),
  advancedHealthIssues: parseArray(parsed.advancedHealthIssues),
  aggregatedAbilities: parseArray(parsed.aggregatedAbilities),
  advancedAbilities: parseArray(parsed.advancedAbilities),
});

/**
 * Hooks onto URL query parameters and parses out report filtering options
 * into an `IFilterReportQuery`.
 */
export const useFilterReportQuery = (): IFilterReportQuery => {
  const [filterReportQuery, setFilterReportQuery] = useState<IFilterReportQuery>({
    summary: {},
  });

  const location = useLocation();

  useEffect(() => {
    const parsed = queryParamsToObject<IFilterReportQueryRaw>(location.search);

    const query = processFilterReportQuery(parsed);

    setFilterReportQuery(query);
  }, [location]);

  return filterReportQuery;
};
