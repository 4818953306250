import {combineReducers} from 'redux';

import {makeCommunicationReducerFromEnum} from 'Common/store/utils/communication';
import {
  Actions,
  deleteMessageQueueActionTypes,
  IUserToAdminHubState,
  sendAdminToUserMessageActionTypes,
  sendAdminToUserMessageReadRequestActionTypes,
  sendUserToAdminMessageActionTypes,
  sendUserToAdminMessageReadRequestActionTypes,
} from '../types';

export const communicationUserToAdminHubReducer = combineReducers<IUserToAdminHubState['communications'], Actions>({
  userToAdminMessageSending: makeCommunicationReducerFromEnum(sendUserToAdminMessageActionTypes),
  adminToUserMessageSending: makeCommunicationReducerFromEnum(sendAdminToUserMessageActionTypes),
  messageQueueDeleting: makeCommunicationReducerFromEnum(deleteMessageQueueActionTypes),
  adminMessageReadRequestSending: makeCommunicationReducerFromEnum(sendUserToAdminMessageReadRequestActionTypes),
  userMessageReadRequestSending: makeCommunicationReducerFromEnum(sendAdminToUserMessageReadRequestActionTypes),
});
