import {IAppState} from 'Common/store/IAppState';
import makeCommunicationSelector from 'Common/helpers/redux/makeCommunicationSelector';

function selectState(state: IAppState) {
  return state.adminActivationCodes;
}

export const selectCommunication = makeCommunicationSelector(selectState);

export function selectActivationCodes(state: IAppState) {
  return selectState(state).data.activationCodes;
}

export function selectPagination(state: IAppState) {
  return selectState(state).ui.pagination;
}

export function selectActivationCodeDetails(state: IAppState) {
  return selectState(state).data.activationCodeDetail;
}
