import React, {memo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {breakpoints} from 'Common/constants/Breakpoints';
import {mediumMdSmBreakpoint} from 'UserProfile/components/TabsData/common/constants';
import {ContainerBase} from 'Shared/components/UserProfile/parts/UserHorsesTab/UserHorse/UserHorseButtons/styled';
import PrimaryButton from 'Common/components/Controls/Buttons/PrimaryButton';
import withFavoriteHorseActions, {IWithFavoriteHorseActionsProps} from 'Common/helpers/withFavoriteHorseActions';
import {IUserHorse} from 'Horse/models/IUserHorse';
import FavoriteHorseButtons from 'FavoriteHorse/components/FavoriteHorseButtons';

const ButtonsWrapper = styled(ContainerBase)`
  width: 100%;
  flex-direction: column;

  @media ${breakpoints.sm} {
    justify-content: flex-start;
  }

  @media ${mediumMdSmBreakpoint} {
    width: 168px;
    flex-direction: column;
    align-items: center;
  }

  @media ${breakpoints.md} {
    align-self: start;
  }
`;

const Button = styled(PrimaryButton)`
  margin-bottom: 16px;

  @media ${breakpoints.sm} {
    margin-bottom: 24px;
  }
`;

interface IProps {
  horse: IUserHorse;
  isAvailableToBah?: boolean;
}

type AllProps = IProps & IWithFavoriteHorseActionsProps;

const UserFavoriteHorseButtons = (props: AllProps) => {
  const {
    horse: {id, isFavorite},
    isAvailableToBah,
    deleteFavoriteHorse,
    addFavoriteHorse,
  } = props;

  const navigate = useNavigate();
  const redirectToBuildHorse = useCallback(() => navigate(`/build-a-horse/${id}`), [id, navigate]);

  const onAddFavoriteHorse = useCallback(async () => {
    await addFavoriteHorse(id);
  }, [id, addFavoriteHorse]);

  const onDeleteFavoriteHorse = useCallback(async () => {
    await deleteFavoriteHorse(id);
  }, [id, deleteFavoriteHorse]);

  return (
    <ButtonsWrapper className="d-flex flex-column">
      {isAvailableToBah && (
        <Button className="w-100" variant="contained" size="small" onClick={redirectToBuildHorse}>
          Start building
        </Button>
      )}
      <FavoriteHorseButtons
        isOwnFavorite={!!isFavorite}
        addFavoriteHorseHandler={onAddFavoriteHorse}
        deleteFavoriteHorseHandler={onDeleteFavoriteHorse}
      />
    </ButtonsWrapper>
  );
};

export default memo(withFavoriteHorseActions(UserFavoriteHorseButtons));
