import React, {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import Typography from 'Common/constants/Typography';
import BuildImg from 'Main/components/img/build-a-horse.png';
import FindImg from 'Main/components/img/find-a-horse.png';
import {breakpoints} from 'Common/constants/Breakpoints';
import Theme from 'Common/constants/Theme';
import CommonLink from 'Common/components/Controls/Links/CommonLink';
import Image from 'Common/components/Image/Image';

const Img = styled(Image)`
  display: block;
  width: 88px;
  height: 88px;

  @media ${breakpoints.sm} {
    width: 133px;
    height: 133px;
  }
`;

const MainButtonRoot = styled(CommonLink)`
  cursor: pointer;
  border-radius: 4px;
  background: ${ColorPalette.white0};
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 88px;
  color: ${Theme.color.black};

  @media ${breakpoints.sm} {
    height: 133px;
  }
`;

const TextWrapper = styled.div`
  margin: 5px 13px 15px 16px;

  @media ${breakpoints.sm} {
    margin: 20px 119px 0 24px;
  }

  @media ${breakpoints.md} {
    margin: 20px 59px 0 24px;
  }
`;

const Title = styled.div`
  font-family: ${Typography.family.sourceSansPro};
  font-style: normal;
  font-weight: ${Typography.weight.bold700};
  line-height: 32px;
  letter-spacing: -1px;
  font-size: ${Typography.size.size20};
  margin-bottom: 0;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size30};
    margin-bottom: 8px;
  }
`;

const Description = styled.div`
  font-family: ${Typography.family.ubuntu};
  font-style: normal;
  color: ${ColorPalette.black6};
  font-size: ${Typography.size.size11};
  line-height: 18px;

  @media ${breakpoints.sm} {
    font-size: ${Typography.size.size13};
    line-height: 24px;
  }
`;

export enum MainPageButtonActionType {
  BAH,
  FAH,
}

const actions = {
  [MainPageButtonActionType.BAH]: {
    title: 'Build a horse',
    description: 'Select the desired traits for your foal and find the potential parents',
    src: BuildImg,
    url: '/build-a-horse',
  },
  [MainPageButtonActionType.FAH]: {
    title: 'Find a horse',
    description: 'Select the desired traits to build your dream horse then click "find" to search the database',
    src: FindImg,
    url: '/find-a-horse',
  },
};

interface IExternalProps {
  type: MainPageButtonActionType;
}

type AllProps = IExternalProps;

const MainButtonLayout = ({type}: AllProps) => {
  return (
    <MainButtonRoot className="d-flex flex-row" to={actions[type].url}>
      <Img className="flex-shrink-0" src={actions[type].src} />

      <TextWrapper className="d-flex flex-column">
        <Title>{actions[type].title}</Title>
        <Description>{actions[type].description}</Description>
      </TextWrapper>
    </MainButtonRoot>
  );
};

export default memo(MainButtonLayout);
