import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {useCallback, useState} from 'react';

import {getEnvParams} from 'Common/helpers/getEnvParams';
import {useBindActions} from 'Common/helpers/hooks/useBindActions';
import {Hubs} from 'SignalR/const/Hubs';
import {DEFAULT_LOG_LEVEL} from 'SignalR/const/LogLevel';
import {getAccessToken} from 'SignalR/helpers/getAccessToken';
import {signalRLogger} from 'SignalR/helpers/signalRLogger';
import {IReceivedUserMessage} from 'SignalR/models/IReceivedUserMessage';
import {ISentUserMessage} from 'SignalR/models/ISentUserMessage';
import {actions} from 'SignalR/store/userToUser';

export function useSignalRUserToUserMessaging() {
  const [connection, setConnection] = useState<HubConnection>();
  const [isConnectionInitialized, setIsConnectionInitialized] = useState<boolean>(false);
  const [connectionState, setConnectionState] = useState<HubConnectionState>(HubConnectionState.Disconnected);

  const {baseUrl, signalRLogLevel} = getEnvParams();

  const {setConnectedUser, setDisconnectedUser, setReceiveMessage, setSentMessage} = useBindActions({
    setConnectedUser: actions.setConnectedUser,
    setDisconnectedUser: actions.setDisconnectedUser,
    setReceiveMessage: actions.setReceiveMessage,
    setSentMessage: actions.setSentMessage,
  });

  const initConnection = useCallback(
    async (hub: Hubs, currentUserId: number) => {
      const logLevel = LogLevel[signalRLogLevel] !== undefined ? LogLevel[signalRLogLevel] : DEFAULT_LOG_LEVEL;

      const newConnection = new HubConnectionBuilder()
        .withUrl(`${baseUrl}${hub}`, {
          accessTokenFactory: getAccessToken,
        })
        .configureLogging(logLevel)
        .withAutomaticReconnect()
        .build();

      newConnection.on('MessageSent', (sentMessage: ISentUserMessage) => {
        if (currentUserId) {
          setSentMessage({...sentMessage, fromUserId: currentUserId});
          signalRLogger('Sent message', sentMessage);
        } else {
          console.error('CurrentUserId is not set (sent message)');
        }
      });
      newConnection.on('MessageReceived', (receivedMessage: IReceivedUserMessage) => {
        if (currentUserId) {
          setReceiveMessage({...receivedMessage, toUserId: currentUserId});
          signalRLogger('Receive message', receivedMessage);
        } else {
          console.error('CurrentUserId is not set (receive message)');
        }
      });
      newConnection.on('UserConnected', (userId: number) => {
        setConnectedUser(userId);
        signalRLogger('User connected', userId);
      });
      newConnection.on('UserDisconnected', (userId: number) => {
        setDisconnectedUser(userId);
        signalRLogger('User disconnected', userId);
      });

      setConnection(newConnection);
      setIsConnectionInitialized(true);
    },
    [baseUrl, setConnectedUser, setDisconnectedUser, setReceiveMessage, setSentMessage, signalRLogLevel]
  );

  const startConnection = useCallback(async () => {
    if (connectionState === HubConnectionState.Disconnected && connection) {
      try {
        setConnectionState(HubConnectionState.Connecting);
        await connection.start();
        setConnectionState(HubConnectionState.Connected);
        signalRLogger('userToUserMessaging connection started. id', connection.connectionId);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection, connectionState]);

  const closeConnection = useCallback(async () => {
    if (connection) {
      try {
        signalRLogger('userToUserMessaging connection closed. id', connection.connectionId);
        setConnectionState(HubConnectionState.Disconnecting);
        await connection.stop();
        setConnectionState(HubConnectionState.Disconnected);
      } catch (error) {
        console.error(error);
      }
    }
  }, [connection]);

  return {
    initConnection,
    isConnectionInitialized,
    connectionState,
    startConnection,
    closeConnection,
  };
}
