import {memo} from 'react';
import styled from 'styled-components';

import ColorPalette from 'Common/constants/ColorPalette';
import useIsImageExist from 'Common/helpers/hooks/useIsImageExist';
import Icon, {IconName} from 'Icon/components/Icon';
import {IImage} from 'Image/models/IImage';

const BorderWrapper = styled.div`
  width: 100px;
  height: 80px;
  border: 1px solid ${ColorPalette.gray37};
  border-radius: 5px;
`;

const Image = styled(BorderWrapper)<{url: string}>`
  background-image: url(${({url}) => `'${url}'`});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

interface IProps {
  image: IImage;
  onSelect(url: string): void;
}

function AttachedImage(props: IProps) {
  const {image, onSelect} = props;

  const isImageExists = useIsImageExist(image.url);

  if (!isImageExists) {
    return (
      <BorderWrapper className="d-flex justify-content-center align-items-center">
        <Icon name={IconName.BrokenImage} size={24} />
      </BorderWrapper>
    );
  }

  return (
    <Image
      className="d-flex flex-column justify-content-center align-items-center position-relative"
      onClick={() => onSelect(image.url)}
      url={image.url}
    />
  );
}

export default memo(AttachedImage);
